<template>
  <div class="dialog-mask" @mousedown.self="$emit('toggle')">
    <div class="dialog-container">
      <div class="dialog-block">
        <main>
          <ul class="detail-ul">
            <li class="detail-ul-li" v-for="(item, index) of list" :key="index">
              <div class="detail-ul-li-title">
                {{ item.name }}
              </div>
              <div class="detail-ul-li-value">
                {{ item.value }}
              </div>
            </li>
          </ul>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BetReportDetail",
  props: ["data", "getUserinfo", "i18nPrefix", "i18nReportPrefix"],
  data() {
    return {
      notShow: [1, 11, 12],
    };
  },
  components: {
  },
  computed: {
    list() {
      return [
        {
          name: this.$t(`${this.i18nPrefix}gameOrderNumber`),
          value: this.data.gameOrderNumber
        },
        {
          name: this.$t("default.member"),
          value: this.data.memberAccount
        },
        {
          name: this.$t("default.agent"),
          value: this.data.agentId
        },
        {
          name: this.$t(`${this.i18nPrefix}gamePlatform`),
          value: this.data.gamePlatform
        },
        {
          name: this.$t(`${this.i18nPrefix}gamesCategory`),
          value: this.data.gamesCategory
        },
        {
          name: this.$t(`${this.i18nPrefix}betAmount`),
          value: this.data.betAmount
        },
        {
          name: this.$t(`${this.i18nPrefix}validBetAmount`),
          value: this.data.validBetAmount
        },
        {
          name: this.$t(`${this.i18nPrefix}winLose`),
          value: this.data.winLose
        },
        {
          name: this.$t("default.status"),
          value: this.data.betType
        },
        {
          name: this.$t(`${this.i18nReportPrefix}gameResult`),
          value: this.getUserinfo.koreanStyleInterface ? 
          this.notShow.includes(this.data.gamesCategoryId) ? "" : this.data.gameResult : this.data.gameResult
        },
        {
          name: this.$t(`${this.i18nReportPrefix}betInfo`),
          value: this.data.betInfo
        },
        {
          name: this.$t(`${this.i18nReportPrefix}betDate`),
          value: this.data.betTime
        },
        {
          name: this.$t(`${this.i18nReportPrefix}tableId`),
          value: this.data.tableId
        },
        {
          name: this.$t(`${this.i18nReportPrefix}roundId`),
          value: this.data.roundId
        },
        {
          name: this.$t(`${this.i18nReportPrefix}settleDate`),
          value: this.data.settleTime
        },

      ];
    }
  },
  watch: {
  },
  created() {

  },
  methods: {

  },
};
</script>
<style lang="scss" scoped>
.dialog-container {
  width: 20%;

  .input-block {
    label {
      width: 40%;
    }
  }

  .detail-ul {
    font-size: 14px;

    &-li {
      padding: 5px;
      border-bottom: 1px solid #c6c6c6;
      display: flex;

      &-title {
        flex-basis: 25%;
      }

      &-value {
        flex-basis: 75%;
      }
    }
  }
}

.opacity-5 {
  opacity: 0.5;
}
</style>