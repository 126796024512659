var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "container-view" }, [
      _c(
        "button",
        {
          staticClass: "search-btn main-style-btn",
          on: {
            click: function ($event) {
              return _vm.search()
            },
          },
        },
        [_vm._v(_vm._s(_vm.$t("default.search")))]
      ),
      _c("div", { staticClass: "header" }, [
        _c("h5", [
          _vm._v(
            _vm._s(_vm.$t("Permission." + _vm.$route.meta.permissionName))
          ),
        ]),
      ]),
      _c("div", { staticClass: "main" }, [
        _c("div", { staticClass: "input-block mb-5" }, [
          _c("label", [
            _vm._v(" " + _vm._s(_vm.$t("default.gamePlatform")) + " "),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.searchForm.gamePlatform,
                    expression: "searchForm.gamePlatform",
                  },
                ],
                on: {
                  change: function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.$set(
                      _vm.searchForm,
                      "gamePlatform",
                      $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                    )
                  },
                },
              },
              [
                _c("option", { domProps: { value: 0 * 1 } }, [
                  _vm._v(_vm._s(_vm.$t("default.all"))),
                ]),
                _vm._l(_vm.getGamePlatforms, function (list) {
                  return _c(
                    "option",
                    { key: list.id, domProps: { value: list.id } },
                    [_vm._v(" " + _vm._s(_vm.$t(list.name)) + " ")]
                  )
                }),
              ],
              2
            ),
          ]),
          _c(
            "label",
            [
              _vm._v(" " + _vm._s(_vm.$t("default.startTime")) + " "),
              _c("date-picker", {
                attrs: {
                  format: "YYYY-MM-DD HH:mm",
                  "value-type": "format",
                  type: "datetime",
                },
                model: {
                  value: _vm.startTime,
                  callback: function ($$v) {
                    _vm.startTime = $$v
                  },
                  expression: "startTime",
                },
              }),
            ],
            1
          ),
          _c(
            "label",
            [
              _vm._v(" " + _vm._s(_vm.$t("default.endTime")) + " "),
              _c("date-picker", {
                attrs: {
                  format: "YYYY-MM-DD HH:mm",
                  "value-type": "format",
                  type: "datetime",
                },
                model: {
                  value: _vm.endTime,
                  callback: function ($$v) {
                    _vm.endTime = $$v
                  },
                  expression: "endTime",
                },
              }),
            ],
            1
          ),
        ]),
        _c(
          "div",
          { staticClass: "advance-block" },
          [
            _c("QuickSearch", { on: { changeTime: _vm.changeTime } }),
            _c(
              "button",
              {
                staticClass: "search-pc-btn main-style-btn px-5",
                on: {
                  click: function ($event) {
                    return _vm.search()
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("default.search")))]
            ),
          ],
          1
        ),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "container-view" },
      [
        _c("div", { staticClass: "table-block" }, [
          _c("table", [
            _c("thead", [
              _c("tr", [
                _c("th", [_vm._v(_vm._s(_vm.$t("default.gamePlatform")))]),
                _c("th", [_vm._v(_vm._s(_vm.$t("default.casinoChips")))]),
                _c("th", [_vm._v(_vm._s(_vm.$t("default.processAccount")))]),
                _c("th", [_vm._v(_vm._s(_vm.$t("default.createTime")))]),
              ]),
            ]),
            _c(
              "tbody",
              [
                !_vm.allData || !_vm.allData.length
                  ? _c("tr", [
                      _c("td", { attrs: { colspan: "4" } }, [
                        _vm._v(_vm._s(_vm.$t("default.noData"))),
                      ]),
                    ])
                  : _vm._e(),
                _vm._l(_vm.allData, function (list) {
                  return _c("tr", { key: list.id }, [
                    _c("td", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.GLOBAL.i18nCheckName(
                              _vm.getGamePlatforms.find(
                                (data) => list.gamePlatform === data.id
                              )
                            )
                          ) +
                          " "
                      ),
                    ]),
                    _c("td", [_vm._v(_vm._s(list.amount))]),
                    _c("td", [_vm._v(_vm._s(list.updateAccount))]),
                    _c("td", [_vm._v(_vm._s(list.createTime))]),
                  ])
                }),
              ],
              2
            ),
          ]),
        ]),
        _vm.allData
          ? _c("serverPagination", {
              attrs: { page: _vm.page },
              on: { ServerPageUtils: _vm.search, changeTake: _vm.changeTake },
            })
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }