var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "container-view" }, [
      _c(
        "button",
        {
          staticClass: "search-btn main-style-btn",
          on: {
            click: function ($event) {
              return _vm.search()
            },
          },
        },
        [_vm._v(_vm._s(_vm.$t("default.search")))]
      ),
      _c("div", { staticClass: "header" }, [
        _c("h5", [_vm._v(_vm._s(_vm.$t("title." + _vm.$route.meta.title)))]),
      ]),
      _c("div", { staticClass: "main" }, [
        _c("div", { staticClass: "input-block" }, [
          _c(
            "label",
            [
              _vm._v(" " + _vm._s(_vm.$t(`${_vm.i18nPrefix}startTime`)) + " "),
              _c("date-picker", {
                attrs: {
                  format: "YYYY-MM-DD HH:mm",
                  "value-type": "format",
                  type: "datetime",
                },
                model: {
                  value: _vm.startTime,
                  callback: function ($$v) {
                    _vm.startTime = $$v
                  },
                  expression: "startTime",
                },
              }),
            ],
            1
          ),
          _c(
            "label",
            [
              _vm._v(" " + _vm._s(_vm.$t(`${_vm.i18nPrefix}endTime`)) + " "),
              _c("date-picker", {
                attrs: {
                  format: "YYYY-MM-DD HH:mm",
                  "value-type": "format",
                  type: "datetime",
                },
                model: {
                  value: _vm.endTime,
                  callback: function ($$v) {
                    _vm.endTime = $$v
                  },
                  expression: "endTime",
                },
              }),
            ],
            1
          ),
        ]),
        _c(
          "div",
          { staticClass: "advance-block" },
          [
            _c("QuickSearch", { on: { changeTime: _vm.changeTime } }),
            _c(
              "button",
              {
                staticClass: "search-pc-btn main-style-btn px-5",
                on: {
                  click: function ($event) {
                    return _vm.search()
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("default.search")))]
            ),
          ],
          1
        ),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "container-view" },
      [
        _c("div", { staticClass: "table-block" }, [
          _c("table", [
            _c("thead", [
              _c("tr", [
                _c("th", { staticStyle: { width: "20%" } }),
                _c("th", [_vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}mailType`)))]),
                _c("th", { staticStyle: { width: "20%" } }, [
                  _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}orderId`))),
                ]),
                _c("th", { staticStyle: { width: "20%" } }, [
                  _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}createTime`))),
                ]),
                _c("th", { staticStyle: { width: "20%" } }, [
                  _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}remark`))),
                ]),
              ]),
            ]),
            _c(
              "tbody",
              [
                !_vm.tableList
                  ? _c("tr", [
                      _c("td", { attrs: { colspan: "5" } }, [
                        _vm._v(_vm._s(_vm.$t("default.noData"))),
                      ]),
                    ])
                  : _vm._e(),
                _vm._l(_vm.tableList, function (mail) {
                  return _c("tr", { key: mail.id }, [
                    _c("td", [
                      _c(
                        "button",
                        {
                          staticClass: "table-btn main-style-btn",
                          on: {
                            click: function ($event) {
                              return _vm.readMail(mail)
                            },
                          },
                        },
                        [_vm._v("Go")]
                      ),
                    ]),
                    _c("td", [
                      _vm._v(
                        _vm._s(_vm.$t("Permission." + mail.permissionName))
                      ),
                    ]),
                    _c("td", [_vm._v("No. " + _vm._s(mail.orderId))]),
                    _c("td", [_vm._v(_vm._s(mail.createTime))]),
                    _c("td", [
                      _vm._v(
                        _vm._s(
                          mail.remark
                            ? mail.remark
                            : _vm.$t(`${_vm.i18nPrefix}notRead`)
                        )
                      ),
                    ]),
                  ])
                }),
              ],
              2
            ),
          ]),
        ]),
        _vm.totalCount > 0
          ? _c("Pagination", {
              attrs: { totalCount: _vm.totalCount },
              on: { handlePage: _vm.handlePage },
            })
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }