var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "d-flex form-column align-items-center h-full",
      staticStyle: { width: "90%" },
    },
    [
      _c("div", { staticClass: "table-block-white-v2" }, [
        _c("table", [
          _c("thead", [
            _c("tr", [
              _c(
                "th",
                { staticStyle: { width: "100%" }, attrs: { colspan: "3" } },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$t(`${_vm.i18nPrefix}dialog.detail.memberMonetary`)
                    )
                  ),
                ]
              ),
            ]),
          ]),
          _c("tbody", [
            _c("tr", [
              _c("td", [
                _vm._v(" " + _vm._s(_vm.$t(`${_vm.i18nPrefix}wallet`)) + " "),
              ]),
              _c("td", [
                _vm._v(
                  " " + _vm._s(_vm.digital.format(_vm.list.balance)) + " "
                ),
              ]),
              _c("td", [
                _c(
                  "button",
                  {
                    staticClass: "edit-btn",
                    attrs: {
                      disabled:
                        _vm.editChooice !== undefined &&
                        _vm.getUserinfo.permissions.includes(10114),
                    },
                    on: {
                      click: function ($event) {
                        return _vm.handleDialog(
                          _vm.memberData,
                          "BalanceAdjustment"
                        )
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("default.edit")))]
                ),
              ]),
            ]),
            _c(
              "tr",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.getUserinfo.pointWallet,
                    expression: "getUserinfo.pointWallet",
                  },
                ],
              },
              [
                _c("td", [
                  _vm._v(
                    " " + _vm._s(_vm.$t(`${_vm.i18nPrefix}pointWallet`)) + " "
                  ),
                ]),
                _c("td", [
                  _vm._v(
                    " " + _vm._s(_vm.digital.format(_vm.list.point)) + " "
                  ),
                ]),
                _c("td", [
                  _c(
                    "button",
                    {
                      staticClass: "edit-btn",
                      attrs: {
                        disabled:
                          _vm.editChooice !== undefined &&
                          _vm.getUserinfo.permissions.includes(10118),
                      },
                      on: {
                        click: function ($event) {
                          return _vm.handleDialog(_vm.memberData, "pointWallet")
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("default.edit")))]
                  ),
                ]),
              ]
            ),
            _c("tr", [
              _c("td", [
                _vm._v(
                  " " + _vm._s(_vm.$t(`${_vm.i18nPrefix}gameWallet`)) + " "
                ),
              ]),
              _c("td", [
                _vm._v(
                  " " + _vm._s(_vm.digital.format(_vm.totalGameBalance)) + " "
                ),
              ]),
              _c("td", [
                _c(
                  "button",
                  {
                    staticClass: "edit-btn",
                    attrs: {
                      disabled:
                        _vm.editChooice !== undefined &&
                        _vm.getUserinfo.permissions.includes(10115),
                    },
                    on: {
                      click: function ($event) {
                        return _vm.handleDialog(_vm.memberData, "GameWallet")
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("default.edit")))]
                ),
              ]),
            ]),
            _c("tr", [
              _c("td", [
                _vm._v(" " + _vm._s(_vm.$t(`${_vm.i18nPrefix}seamless`)) + " "),
              ]),
              _c("td", [
                _c(
                  "div",
                  {
                    staticClass: "slide-block",
                    class: { "slide-block-on": _vm.list.enableSeamless },
                    staticStyle: { margin: "auto" },
                    on: {
                      click: function ($event) {
                        _vm.list.enableSeamless =
                          _vm.editChooice !== "enableSeamless"
                            ? _vm.list.enableSeamless
                            : !_vm.list.enableSeamless
                      },
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "slide",
                        class: { "slide-on": _vm.list.enableSeamless },
                      },
                      [
                        _c("label", { staticStyle: { display: "none" } }),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.list.enableSeamless,
                              expression: "list.enableSeamless",
                            },
                          ],
                          staticStyle: { display: "none" },
                          attrs: { type: "checkbox" },
                          domProps: {
                            checked: Array.isArray(_vm.list.enableSeamless)
                              ? _vm._i(_vm.list.enableSeamless, null) > -1
                              : _vm.list.enableSeamless,
                          },
                          on: {
                            change: function ($event) {
                              var $$a = _vm.list.enableSeamless,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.list,
                                      "enableSeamless",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.list,
                                      "enableSeamless",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(_vm.list, "enableSeamless", $$c)
                              }
                            },
                          },
                        }),
                      ]
                    ),
                  ]
                ),
              ]),
              _c("td", [
                _c(
                  "button",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value:
                          _vm.editChooice !== "enableSeamless" &&
                          _vm.getUserinfo.permissions.includes(10102),
                        expression:
                          "editChooice !== 'enableSeamless' && getUserinfo.permissions.includes(10102)",
                      },
                    ],
                    staticClass: "edit-btn",
                    attrs: { disabled: _vm.editChooice !== undefined },
                    on: {
                      click: function ($event) {
                        return _vm.edit("enableSeamless")
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("default.edit")))]
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.editChooice == "enableSeamless",
                        expression: "editChooice == 'enableSeamless'",
                      },
                    ],
                  },
                  [
                    _c(
                      "button",
                      {
                        staticClass: "normal-btn",
                        on: {
                          click: function ($event) {
                            return _vm.confirm("enableSeamless")
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("default.confirm")) + " ")]
                    ),
                    _c(
                      "button",
                      {
                        staticClass: "delete-btn",
                        on: {
                          click: function ($event) {
                            return _vm.cancel("enableSeamless")
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("default.cancel")) + " ")]
                    ),
                  ]
                ),
              ]),
            ]),
            _c("tr", [
              _c("td", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(`${_vm.i18nPrefix}dialog.status.depositChannel`)
                    ) +
                    " "
                ),
              ]),
              _c(
                "td",
                _vm._l(_vm.list.paymentGateways, function (gateways) {
                  return _c("div", {}, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("DepositTypeList." + gateways.paymentGatewayId)
                        ) +
                        " "
                    ),
                    _c(
                      "div",
                      {
                        key: gateways.paymentGatewayId,
                        staticClass: "slide-block",
                        class: { "slide-block-on": gateways.enable },
                        staticStyle: { margin: "auto" },
                        on: {
                          click: function ($event) {
                            gateways.enable =
                              _vm.editChooice !== "paymentGateways"
                                ? gateways.enable
                                : !gateways.enable
                          },
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "slide",
                            class: { "slide-on": gateways.enable },
                          },
                          [
                            _c("label", { staticStyle: { display: "none" } }),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: gateways.enable,
                                  expression: "gateways.enable",
                                },
                              ],
                              staticStyle: { display: "none" },
                              attrs: { type: "checkbox" },
                              domProps: {
                                checked: Array.isArray(gateways.enable)
                                  ? _vm._i(gateways.enable, null) > -1
                                  : gateways.enable,
                              },
                              on: {
                                change: function ($event) {
                                  var $$a = gateways.enable,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          gateways,
                                          "enable",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          gateways,
                                          "enable",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(gateways, "enable", $$c)
                                  }
                                },
                              },
                            }),
                          ]
                        ),
                      ]
                    ),
                  ])
                }),
                0
              ),
              _c("td", [
                _c(
                  "button",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value:
                          _vm.editChooice !== "paymentGateways" &&
                          _vm.getUserinfo.permissions.includes(10102),
                        expression:
                          "editChooice !== 'paymentGateways' && getUserinfo.permissions.includes(10102)",
                      },
                    ],
                    staticClass: "edit-btn",
                    attrs: { disabled: _vm.editChooice !== undefined },
                    on: {
                      click: function ($event) {
                        return _vm.edit("paymentGateways")
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("default.edit")))]
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.editChooice == "paymentGateways",
                        expression: "editChooice == 'paymentGateways'",
                      },
                    ],
                  },
                  [
                    _c(
                      "button",
                      {
                        staticClass: "normal-btn",
                        on: {
                          click: function ($event) {
                            return _vm.confirm("paymentGateways")
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("default.confirm")) + " ")]
                    ),
                    _c(
                      "button",
                      {
                        staticClass: "delete-btn",
                        on: {
                          click: function ($event) {
                            return _vm.cancel("paymentGateways")
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("default.cancel")) + " ")]
                    ),
                  ]
                ),
              ]),
            ]),
            _c("tr", [
              _c("td", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(`${_vm.i18nPrefix}dialog.detail.totalVaildBet`)
                    ) +
                    " "
                ),
              ]),
              _c("td", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        `${_vm.i18nPrefix}dialog.detail.totalVaildBetAmount`
                      ) +
                        "： " +
                        _vm.digital.format(_vm.list.totalValidBetAmount)
                    ) +
                    " "
                ),
              ]),
              _c(
                "td",
                [
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to: {
                          path: "/statistical-report/bet-report",
                          query: { memberAccount: _vm.memberData.account },
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("title.bet-report")) + " ")]
                  ),
                ],
                1
              ),
            ]),
            _c("tr", [
              _c("td", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(`${_vm.i18nPrefix}dialog.detail.totalDepositCount`)
                    ) +
                    " "
                ),
              ]),
              _c("td", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("default.count") +
                        " / " +
                        _vm.$t("default.amount") +
                        "：" +
                        _vm.list.totalDepositCount +
                        " / " +
                        _vm.digital.format(_vm.list.totalDepositAmount)
                    ) +
                    " "
                ),
              ]),
              _c(
                "td",
                [
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to: {
                          path: "/report/member-deposit",
                          query: { memberAccount: _vm.memberData.account },
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("title.member-deposit")) + " ")]
                  ),
                ],
                1
              ),
            ]),
            _c("tr", [
              _c("td", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        `${_vm.i18nPrefix}dialog.detail.totalWithdrawalCount`
                      )
                    ) +
                    " "
                ),
              ]),
              _c("td", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("default.count") +
                        " / " +
                        _vm.$t("default.amount") +
                        "：" +
                        _vm.list.totalWithdrawalCount +
                        " / " +
                        _vm.digital.format(_vm.list.totalWithdrawalAmount)
                    ) +
                    " "
                ),
              ]),
              _c(
                "td",
                [
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to: {
                          path: "/report/member-withdrawal-report",
                          query: { memberAccount: _vm.memberData.account },
                        },
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("title.member-withdrawal-report")) +
                          " "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]),
            _c("tr", [
              _c("td", [
                _vm._v(" " + _vm._s(_vm.$t("default.totalWinLose")) + " "),
              ]),
              _c("td", [
                _vm._v(
                  " " + _vm._s(_vm.digital.format(_vm.list.totalWinLose)) + " "
                ),
              ]),
              _c("td"),
            ]),
            _c("tr", [
              _c("td", [
                _vm._v(" " + _vm._s(_vm.$t("default.totalProfit")) + " "),
              ]),
              _c("td", [_vm._v(" " + _vm._s(_vm.list.totalProfit) + " ")]),
              _c("td"),
            ]),
            _c("tr", [
              _c("td", [
                _vm._v(" " + _vm._s(_vm.$t("title.betAmountLimit")) + " "),
              ]),
              _c("td", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(`${_vm.i18nPrefix}dialog.detail.unsettledAmount`) +
                        " / " +
                        _vm.$t(
                          `${_vm.i18nPrefix}dialog.detail.accumulatedAmount`
                        ) +
                        "：" +
                        _vm.digital.format(_vm.list.unsettledAmount) +
                        " / " +
                        _vm.digital.format(_vm.list.accumulatedAmount)
                    ) +
                    " "
                ),
              ]),
              _c(
                "td",
                [
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to: {
                          path: "/member/betAmountLimit",
                          query: { memberAccount: _vm.memberData.account },
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("title.betAmountLimit")) + " ")]
                  ),
                ],
                1
              ),
            ]),
            _c("tr", [
              _c("td", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        `${_vm.i18nPrefix}dialog.detail.totalDiscountAmount`
                      )
                    ) +
                    " "
                ),
              ]),
              _c("td", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("default.count") +
                        " / " +
                        _vm.$t("default.amount") +
                        "：" +
                        _vm.list.totalDiscountCount +
                        " / " +
                        _vm.digital.format(_vm.list.totalDiscountAmount)
                    ) +
                    " "
                ),
              ]),
              _c(
                "td",
                [
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to: {
                          path: "/member/member-discount",
                          query: { memberAccount: _vm.memberData.account },
                        },
                      },
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.$t("title.member-discount")) + " "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]),
            _c("tr", [
              _c("td", [
                _vm._v(
                  " " + _vm._s(_vm.$t(`${_vm.i18nPrefix}lastDepositTime`)) + " "
                ),
              ]),
              _c("td", [_vm._v(" " + _vm._s(_vm.list.lastDepositTime) + " ")]),
              _c("td"),
            ]),
            _c("tr", [
              _c("td", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(`${_vm.i18nPrefix}dialog.detail.depositPeriod`)
                    ) +
                    " "
                ),
              ]),
              _c("td", [_vm._v(" " + _vm._s(_vm.list.depositPeriod) + " ")]),
              _c("td"),
            ]),
          ]),
        ]),
      ]),
      _c(
        "transition",
        { attrs: { name: "fade", mode: "out-in" } },
        [
          _c(
            _vm.currDialog,
            _vm._b(
              {
                tag: "component",
                attrs: {
                  getUserinfo: _vm.getUserinfo,
                  gamePlatforms: _vm.getGamePlatforms,
                  i18nPrefix: _vm.i18nPrefix,
                },
                on: {
                  balanceAdjust: _vm.balanceAdjust,
                  point: _vm.point,
                  updateWallet: _vm.init,
                  toggle: function ($event) {
                    _vm.currDialog = undefined
                  },
                },
              },
              "component",
              _vm.dialogData,
              false
            )
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }