<template>
  <div>
    <div class="container-view">
      <div class="header">
        <h4>{{ $t('Permission.' + $route.meta.permissionName) }}</h4>
        <button class="create-btn main-style-btn" @click="handleDialog('', 'create')"
          :disabled="!$store.getters.getUserinfo.permissions.includes(200202)">{{ $t("default.create") }}</button>
      </div>
    </div>

    <div class="container-view">
      <div class="table-block">
        <table>
          <thead>
            <tr>
              <th></th>
              <th>{{ $t("default.title") }}</th>
              <th>{{ $t("default.createTime") }}</th>
              <th>{{ $t("default.creator") }}</th>
              <th>{{ $t("default.processTime") }}</th>
              <th>{{ $t("default.processAccount") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="!allData || allData.length === 0">
              <td colspan="999">{{ $t("default.noData") }}</td>
            </tr>
            <tr v-for="list of allData">
              <td>
                <button class="table-btn main-style-btn" @click="handleDialog(list, 'edit')"
                  :disabled="!$store.getters.getUserinfo.permissions.includes(200203)">{{ $t("default.edit") }}</button>
                <button class="delete-btn" @click="handleDeleteTemplate(list.id)"
                  :disabled="!$store.getters.getUserinfo.permissions.includes(200204)">
                  {{ $t("default.delete") }}
                </button>
              </td>
              <td>
                <div class="tdColor" :style="{ backgroundColor: list.backgroundColor, color: list.titleColor }">
                  {{ list.title }}
                </div>
              </td>
              <td>{{ list.createdDateTime }}</td>
              <td>{{ list.creatorName }}</td>
              <td>{{ list.updatedDateTime }}</td>
              <td>{{ list.updaterName }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <serverPagination v-if="allData" :page="page" @ServerPageUtils="search" @changeTake="changeTake" />
    </div>
    <Alert v-if="alertMsg" :message="alertMsg" @toggle="alertMsg = undefined" @doIt="deleteFunction" />
    <transition name="fade" mode="out-in">
      <component :is="currDialog" :i18nPrefix="i18nPrefix" v-bind="dialogData" @toggle="currDialog = undefined"
        @edit="editTemplate" @create="createTemplate"></component>
    </transition>
  </div>
</template>

<script>
import { messageTemplatePage, createMessageTemplate, updateMessageTemplate, deleteMessageTemplate } from "@/api/customService/customServiceMessageTemplate";
import serverPagination from "@/layout/components/serverPagination";
import handleServerPageUtils from "@/layout/mixins/handleServerPageUtils";
import handleDialog from "@/layout/mixins/handleDialog";
import Edit from "./dialog/edit.vue";
import Create from "./dialog/create.vue";
import { searchModel } from "./model";
import Alert from "@/layout/components/Alert";

export default {
  name: "CustomServiceMessageTemplate",
  data() {
    return {
      searchForm: _.cloneDeep(searchModel),
      alertMsg: undefined,
      deleteData: undefined,
      i18nPrefix: "customService.customServiceMessageTemplate."
    };
  },
  components: {
    Create,
    Edit,
    Alert,
    serverPagination
  },
  mixins: [handleServerPageUtils, handleDialog],
  created() {
    this.search();
  },
  methods: {
    search(page = false) {
      this.searchForm.page.take = this.take * 1;
      this.searchForm.page.skip = this.take * (page ? page - 1 : 0);

      this.closeDialog();
      messageTemplatePage(this.searchForm).then(res => {
        this.allData = res.data.list;
        this.page = res.data.page;
      });
    },
    createTemplate(data) {
      createMessageTemplate(data).then(res => {
        if (res) this.search();
      });
    },
    editTemplate(data) {
      updateMessageTemplate(data).then(res => {
        if (res) this.search();
      });
    },
    handleDeleteTemplate(id) {
      this.alertMsg = this.$t("default.delMsg");
      this.deleteData = { id };
    },
    deleteFunction() {
      this.alertMsg = "";
      deleteMessageTemplate(this.deleteData).then(res => {
        if (res) this.search();
      });
    },
  }
};
</script>

<style lang="scss" scoped>
.color {
  padding: 0 10px !important;
}

label {
  align-items: center !important;
}

.tdColor {
  padding: 5%;
  border-style: solid;
  border-radius: 10px;
}
</style>