<template>
  <div>
    <div class="container-view">
      <button class="search-btn main-style-btn" @click="search()">{{ $t("default.search") }}</button>
      <div class="header">
        <h5>{{ $t("title." + $route.meta.title) }}</h5>
        <div class="float-right">
          <button class="create-btn main-style-btn" @click="handleDialog(undefined, 'Create')" 
          :disabled="!getUserinfo.permissions.includes(190101)
        ">{{ $t("default.create") }}</button>
        </div>
      </div>
      <div class="main">
        <div class="input-block">
          <label>
            <div class="input-block-check">
              <span style="margin-right: 10px;">{{ $t("default.member") }}</span>
              <input id="isFuzzySearch" type="checkbox" v-model="searchForm.isFuzzySearch" />
              <label for="isFuzzySearch">{{ $t("default.isFuzzySearch") }}</label>
            </div>
            <input type="text" v-model="searchForm.memberAccount" />
          </label>
        </div>
        <div class="advance-block">
          <button class="search-pc-btn main-style-btn px-5" @click="search()">{{ $t("default.search") }}</button>
        </div>
      </div>
    </div>
    <div class="container-view">
      <div class="table-block">
        <table>
          <thead>
            <tr>
              <th>{{ $t("default.member") }}</th>
              <th>{{ $t(`${i18nPrefix}minBetAmount`) }}</th>
              <th>{{ $t(`${i18nPrefix}maxBetAmount`) }}</th>
              <th>{{ $t("default.remark") }}</th>
              <th>{{ $t("default.processAccount") }}</th>
              <th>{{ $t("default.processTime") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="!allData || !allData.length">
              <td colspan="6">{{ $t("default.noData") }}</td>
            </tr>
            <tr v-for="list of allData" :key="list.id">
              <td>{{ list.memberAccount }}</td>
              <td>{{ list.minBetAmount }}</td>
              <td>{{ list.maxBetAmount }}</td>
              <td>{{ list.remark }}</td>
              <td>{{ list.auditAccount }}</td>
              <td>{{ list.updateTime }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <serverPagination
        v-if="allData"
        :page="page"
        @ServerPageUtils="search"
        @changeTake="changeTake"
      ></serverPagination>
    </div>
    <transition name="fade" mode="out-in">
      <component :is="currDialog" :i18nPrefix="i18nPrefix" v-bind="dialogData" @toggle="currDialog = undefined"
        @create="create"></component>
    </transition>
  </div>
</template>

<script>
import {
  getT9GamingBetLimitList,
  createT9GamingLimit
} from "@/api/T9Gaming/T9GamingBetLimit";
import Create from "./dialog/create";
import handleDialog from "@/layout/mixins/handleDialog";
import serverPagination from "@/layout/components/serverPagination";
import handleServerPageUtils from "@/layout/mixins/handleServerPageUtils";
import { mapGetters } from "vuex";
import { searchModel } from "./model";

export default {
  name: "T9GamingBetLimit",
  data() {
    return {
      searchForm: _.cloneDeep(searchModel),
      i18nPrefix: "T9Gaming.T9GamingBetLimit."
    };
  },
  components: {
    Create,
    serverPagination
  },
  mixins: [handleServerPageUtils, handleDialog],
  computed: {
    ...mapGetters(["getUserinfo"])
  },
  async created() {
    // await this.search();
  },
  methods: {
    search(page = false) {
      this.searchForm.page.take = this.take * 1;
      this.searchForm.page.skip = this.take * (page ? page - 1 : 0);
      getT9GamingBetLimitList(this.searchForm).then(res => {
        this.allData = res.data.list;
        this.page = res.data.page;
        this.closeDialog();
      });
    },
    create(data) {
      createT9GamingLimit(data).then(res => {
        if (res) this.search();
      });
    }
  }
};
</script>

<style lang="scss" scoped></style>