<template>
  <div>
    <div class="container-view">
      <button class="search-btn main-style-btn" @click="search()">{{ $t("default.search") }}</button>
      <div class="header">
        <h5>{{ $t("title." + $route.meta.title) }}</h5>
      </div>
      <div class="divider"></div>
      <div class="btn-list main">
        <button
          v-for="list of blacklistType"
          :key="list.id"
          @click="searchForm.blackType = list.id"
          :class="{ active: searchForm.blackType === list.id }"
        >{{ $t(`${i18nPrefix}${list.value}`) }}</button>
      </div>
      <div class="advance-block">
        <label class="search">
          {{ $t(`${i18nPrefix}${blacklistType.find(data => data.id === searchForm.blackType).value}`) }}
          <input
            type="text"
            v-model="searchForm.value"
          />
        </label>
        <button class="search-pc-btn main-style-btn px-5" @click="search()">{{ $t("default.search") }}</button>
      </div>
    </div>
    <div class="container-view">
      <label>
        {{ $t("default.system") }}
        <select v-model="device">
          <option v-for="list of deviceType" :key="list.id" :value="list.id">{{ $t(list.name) }}</option>
        </select>
        {{ $t(`${i18nPrefix}${blacklistType.find(data => data.id === searchForm.blackType).value}`) }}
        <input
          type="text"
          v-model="createValue"
        />
        <button
          class="confirm-btn main-style-btn ml-2"
          @click="create()"
        >{{ $t("default.new") + " " + $t(i18nPrefix + blacklistType.find(data => data.id === searchForm.blackType).value) }}</button>
      </label>
      <main>
        <div class="table-block">
          <TreeTable ref="treeTable" :list="allData" :column="tableColumn">
            <template v-slot:column_action="props">
              <button class="delete-btn" @click="handleDelete(props.id)">{{ $t("default.delete") }}</button>
            </template>
            <template
              v-slot:column_deviceType="props"
            >{{ GLOBAL.i18nCheckName(deviceType.find( data => data.id == props.deviceType))}}</template>
          </TreeTable>
          <serverPagination
            v-if="allData"
            :page="page"
            @ServerPageUtils="search"
            @changeTake="changeTake"
          ></serverPagination>
        </div>
      </main>
    </div>
    <Alert
      v-if="alertMsg"
      :message="alertMsg"
      @toggle="alertMsg = undefined"
      @doIt="deleteBlacklistAppSetting"
    />
  </div>
</template>

<script>
import {
  getBlacklistAppSetting,
  createBlacklistAppSetting,
  deleteBlacklistAppSetting
} from "@/api/blacklist/appBlackList";
import Message from "@/layout/components/Message";
import Alert from "@/layout/components/Alert";
import serverPagination from "@/layout/components/serverPagination";
import handleServerPageUtils from "@/layout/mixins/handleServerPageUtils";
import TreeTable from "@/layout/components/TreeTable";
import { searchModel } from "./model";

export default {
  name: "AppBlackList",
  data() {
    return {
      searchForm: _.cloneDeep(searchModel),
      blacklistType: [
        {
          id: 1,
          value: "ip"
        },
        {
          id: 2,
          value: "device"
        }
      ],
      createValue: "",
      device: 3,
      alertMsg: "",
      deleteData: {},
      i18nPrefix: "blackList.appBlackList."
    };
  },
  watch: {
    "searchForm.blackType": function() {
      this.allData = undefined;
    }
  },
  components: {
    Alert,
    serverPagination,
    TreeTable
  },
  mixins: [handleServerPageUtils],
  computed: {
    tableColumn() {
      return [
        {
          key: "action",
          name: "",
          width: 80
        },
        {
          key: "createTime",
          name: this.$t("default.createTime")
        },
        {
          key: "deviceType",
          name: this.$t("default.system")
        },
        {
          key: "value",
          name: this.$t(
            `${this.i18nPrefix}${
              this.blacklistType.find(
                data => data.id === this.searchForm.blackType
              ).value
            }`
          )
        }
      ];
    },
    deviceType() {
      return [
        {
          id: 3,
          name: "IOS"
        },
        {
          id: 4,
          name: "Android"
        },
        {
          id: 5,
          name: "Oppo"
        },
        {
          id: 6,
          name: "Vivo"
        },
        {
          id: 7,
          name: "Apkpure"
        }
      ];
    }
  },
  methods: {
    search(page = false) {
      this.searchForm.page.take = this.take * 1;
      this.searchForm.page.skip = this.take * (page ? page - 1 : 0);
      getBlacklistAppSetting(this.searchForm).then(res => {
        this.allData = res.data?.list;
        this.page = res.data.page;
      });
    },
    create() {
      if (!this.createValue) {
        Message.error("請填入資料", 1000);
        return;
      }
      const obj = {
        deviceType: this.device,
        blackType: this.searchForm.blackType,
        value: this.createValue
      };
      createBlacklistAppSetting(obj).then(res => {
        if (res) this.search();
      });
    },
    handleDelete(id) {
      this.alertMsg = this.$t("default.delMsg");
      this.deleteData = { id };
    },
    deleteBlacklistAppSetting() {
      this.alertMsg = "";
      deleteBlacklistAppSetting(this.deleteData).then(res => {
        if (res) this.search();
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.search {
  margin-top: 40px;
}
.active {
  background-image: linear-gradient(180deg, #FFCC81 0%, #CD7B00 100%) !important;
  color: white;
  border: 1px solid #9F6000;
}
.btn-list {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-gap: 1em;

  button {
    margin: auto;
    width: 100%;
    height: 40px;
    border-radius: 8px;
    border: 1px solid #7A7A7A;
    background-color: #949494;
    color: white;
    font-size: 16px;

    &:hover {
      opacity: 0.8;
    }
  }
}
@media screen and (max-width: 1280px) {
  .btn-list {
    grid-template-columns: repeat(4, minmax(0, 1fr));
    font-size: 10px !important;
  }
}
</style>