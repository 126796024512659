var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "container-view" }, [
        _c(
          "button",
          {
            staticClass: "search-btn main-style-btn",
            on: {
              click: function ($event) {
                return _vm.search(false, true)
              },
            },
          },
          [_vm._v(_vm._s(_vm.$t("default.search")))]
        ),
        _c(
          "div",
          {
            staticClass: "header",
            staticStyle: { "justify-content": "flex-start" },
          },
          [
            _c("h5", { staticStyle: { "margin-right": "5px" } }, [
              _vm._v(_vm._s(_vm.$t("title." + _vm.$route.meta.title))),
            ]),
            !_vm.searchBar
              ? _c("img", {
                  staticClass: "switch-btn memberSpan",
                  attrs: { src: require("@/assets/switchOn.png") },
                  on: {
                    click: function ($event) {
                      _vm.searchBar = true
                    },
                  },
                })
              : _c("img", {
                  staticClass: "switch-btn memberSpan",
                  attrs: { src: require("@/assets/switchOff.png") },
                  on: {
                    click: function ($event) {
                      _vm.searchBar = false
                    },
                  },
                }),
            _c("div", { staticClass: "float-right" }, [
              !(
                _vm.getUserinfo.koreanStyleInterface &&
                _vm.getUserinfo.role === 2
              )
                ? _c(
                    "button",
                    {
                      staticClass: "download-btn main-style-btn",
                      attrs: {
                        disabled: !_vm.getUserinfo.permissions.includes(10220),
                      },
                      on: {
                        click: function ($event) {
                          return _vm.handleDialog(
                            {
                              fileName:
                                _vm.startCreateTime + "MemberReport.xlsx",
                              data: _vm.searchForm,
                              toogle:
                                _vm.getUserinfo
                                  .exportReportPasswordFeatureToggle,
                              downloadApi: _vm.getMemberExcel,
                            },
                            "Download"
                          )
                        },
                      },
                    },
                    [
                      _c("img", {
                        staticClass: "icon",
                        attrs: { src: require("@/assets/download.svg") },
                      }),
                      _vm._v(" " + _vm._s(_vm.$t("default.download")) + " "),
                    ]
                  )
                : _vm._e(),
              !(
                _vm.getUserinfo.koreanStyleInterface &&
                _vm.getUserinfo.role === 2
              )
                ? _c(
                    "button",
                    {
                      staticClass: "create-btn main-style-btn",
                      attrs: {
                        disabled: !_vm.getUserinfo.permissions.includes(10101),
                      },
                      on: {
                        click: function ($event) {
                          return _vm.handleDialog(undefined, "Create")
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("default.create")) + " ")]
                  )
                : _vm._e(),
            ]),
          ]
        ),
        _c("div", { staticClass: "main" }, [
          _c(
            "div",
            { staticClass: "input-block" },
            [
              _c("label", [
                _c("div", { staticClass: "input-block-check" }, [
                  _c("span", { staticStyle: { "margin-right": "10px" } }, [
                    _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}account`))),
                  ]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.searchForm.isFuzzySearch,
                        expression: "searchForm.isFuzzySearch",
                      },
                    ],
                    attrs: { id: "isFuzzySearch", type: "checkbox" },
                    domProps: {
                      checked: Array.isArray(_vm.searchForm.isFuzzySearch)
                        ? _vm._i(_vm.searchForm.isFuzzySearch, null) > -1
                        : _vm.searchForm.isFuzzySearch,
                    },
                    on: {
                      change: function ($event) {
                        var $$a = _vm.searchForm.isFuzzySearch,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(
                                _vm.searchForm,
                                "isFuzzySearch",
                                $$a.concat([$$v])
                              )
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.searchForm,
                                "isFuzzySearch",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(_vm.searchForm, "isFuzzySearch", $$c)
                        }
                      },
                    },
                  }),
                  _c("label", { attrs: { for: "isFuzzySearch" } }, [
                    _vm._v(_vm._s(_vm.$t("default.isFuzzySearch"))),
                  ]),
                ]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchForm.account,
                      expression: "searchForm.account",
                    },
                  ],
                  attrs: { type: "text" },
                  domProps: { value: _vm.searchForm.account },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.searchForm, "account", $event.target.value)
                    },
                  },
                }),
              ]),
              _c(
                "label",
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t(`${_vm.i18nPrefix}startRegisterTime`)) +
                      " "
                  ),
                  _c("date-picker", {
                    attrs: {
                      format: "YYYY-MM-DD HH:mm",
                      "value-type": "format",
                      type: "datetime",
                    },
                    model: {
                      value: _vm.startCreateTime,
                      callback: function ($$v) {
                        _vm.startCreateTime = $$v
                      },
                      expression: "startCreateTime",
                    },
                  }),
                ],
                1
              ),
              _c(
                "label",
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t(`${_vm.i18nPrefix}endRegisterTime`)) +
                      " "
                  ),
                  _c("date-picker", {
                    attrs: {
                      format: "YYYY-MM-DD HH:mm",
                      "value-type": "format",
                      type: "datetime",
                    },
                    model: {
                      value: _vm.endCreateTime,
                      callback: function ($$v) {
                        _vm.endCreateTime = $$v
                      },
                      expression: "endCreateTime",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "input-block-multiselect" },
                [
                  _vm._v(" " + _vm._s(_vm.$t("default.agent")) + " "),
                  _c("multiselect", {
                    attrs: {
                      label: "name",
                      "track-by": "id",
                      placeholder: _vm.$t(
                        `${_vm.i18nPrefix}dialog.bank.choose`
                      ),
                      options: _vm.getAgentListAddDefault,
                      "allow-empty": false,
                      preselectFirst: true,
                      "clear-on-select": false,
                      "preserve-search": true,
                      "custom-label": _vm.nameWithLang,
                      "option-height": 36,
                    },
                    model: {
                      value: _vm.agentData,
                      callback: function ($$v) {
                        _vm.agentData = $$v
                      },
                      expression: "agentData",
                    },
                  }),
                ],
                1
              ),
              _vm.searchBar
                ? [
                    _vm.getUserinfo.koreanStyleInterface
                      ? _c("label", [
                          _vm._v(
                            " " + _vm._s(_vm.$t("default.nickName")) + " "
                          ),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.searchForm.nickName,
                                expression: "searchForm.nickName",
                              },
                            ],
                            attrs: { type: "text" },
                            domProps: { value: _vm.searchForm.nickName },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.searchForm,
                                  "nickName",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ])
                      : _vm._e(),
                    _c("label", [
                      _vm._v(
                        " " + _vm._s(_vm.$t(`${_vm.i18nPrefix}bank`)) + " "
                      ),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.searchForm.bankId,
                              expression: "searchForm.bankId",
                            },
                          ],
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.searchForm,
                                "bankId",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                          },
                        },
                        _vm._l(_vm.getBankListAddDefault, function (list) {
                          return _c(
                            "option",
                            { key: list.id, domProps: { value: list.id } },
                            [_vm._v(_vm._s(_vm.$t(list.name)) + " ")]
                          )
                        }),
                        0
                      ),
                    ]),
                    _c("label", [
                      _vm._v(
                        " " + _vm._s(_vm.$t(`${_vm.i18nPrefix}bankName`)) + " "
                      ),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.searchForm.bankAccountName,
                            expression: "searchForm.bankAccountName",
                          },
                        ],
                        attrs: { type: "text" },
                        domProps: { value: _vm.searchForm.bankAccountName },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.searchForm,
                              "bankAccountName",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                    _c("label", [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t(`${_vm.i18nPrefix}bankAccount`)) +
                          " "
                      ),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.searchForm.bankAccountNumber,
                            expression: "searchForm.bankAccountNumber",
                          },
                        ],
                        attrs: { type: "text" },
                        domProps: { value: _vm.searchForm.bankAccountNumber },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.searchForm,
                              "bankAccountNumber",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                    _c("label", [
                      _vm._v(
                        " " + _vm._s(_vm.$t(`${_vm.i18nPrefix}recommend`)) + " "
                      ),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.searchForm.referralCode,
                            expression: "searchForm.referralCode",
                          },
                        ],
                        attrs: { type: "text" },
                        domProps: { value: _vm.searchForm.referralCode },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.searchForm,
                              "referralCode",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                    _c("label", [
                      _vm._v(" " + _vm._s(_vm.$t("default.tag")) + " "),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.searchForm.tagId,
                              expression: "searchForm.tagId",
                            },
                          ],
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.searchForm,
                                "tagId",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                          },
                        },
                        [
                          _c("option", { domProps: { value: 0 * 1 } }, [
                            _vm._v(_vm._s(_vm.$t("default.all"))),
                          ]),
                          _vm._l(_vm.tagList, function (list) {
                            return _c(
                              "option",
                              {
                                key: list.id,
                                domProps: { value: list.id * 1 },
                              },
                              [_vm._v(_vm._s(list.name))]
                            )
                          }),
                        ],
                        2
                      ),
                    ]),
                    !_vm.getUserinfo.koreanStyleInterface
                      ? [
                          _c("label", [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t(`${_vm.i18nPrefix}group`)) +
                                " "
                            ),
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.searchForm.bankGroupId,
                                    expression: "searchForm.bankGroupId",
                                  },
                                ],
                                on: {
                                  change: function ($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call(
                                        $event.target.options,
                                        function (o) {
                                          return o.selected
                                        }
                                      )
                                      .map(function (o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.$set(
                                      _vm.searchForm,
                                      "bankGroupId",
                                      $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    )
                                  },
                                },
                              },
                              _vm._l(
                                _vm.getBankGroupListAddDefault,
                                function (list) {
                                  return _c(
                                    "option",
                                    {
                                      key: list.id,
                                      domProps: { value: list.id },
                                    },
                                    [_vm._v(_vm._s(_vm.$t(list.name)) + " ")]
                                  )
                                }
                              ),
                              0
                            ),
                          ]),
                          _c("label", [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t(`${_vm.i18nPrefix}mail`)) +
                                " "
                            ),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.searchForm.email,
                                  expression: "searchForm.email",
                                },
                              ],
                              attrs: { type: "text" },
                              domProps: { value: _vm.searchForm.email },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.searchForm,
                                    "email",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]),
                        ]
                      : _vm._e(),
                    _c("label", [
                      _vm._v(" " + _vm._s(_vm.$t(`${_vm.i18nPrefix}ip`)) + " "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.searchForm.ip,
                            expression: "searchForm.ip",
                          },
                        ],
                        attrs: { type: "text" },
                        domProps: { value: _vm.searchForm.ip },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.searchForm, "ip", $event.target.value)
                          },
                        },
                      }),
                    ]),
                    _vm.getUserinfo.koreanStyleInterface
                      ? [
                          _c("label", [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t(`${_vm.i18nPrefix}wallet`)) +
                                " "
                            ),
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.searchForm.mainWallet,
                                    expression: "searchForm.mainWallet",
                                  },
                                ],
                                on: {
                                  change: function ($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call(
                                        $event.target.options,
                                        function (o) {
                                          return o.selected
                                        }
                                      )
                                      .map(function (o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.$set(
                                      _vm.searchForm,
                                      "mainWallet",
                                      $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    )
                                  },
                                },
                              },
                              [
                                _c("option", { attrs: { value: "0" } }, [
                                  _vm._v(_vm._s(_vm.$t("default.none"))),
                                ]),
                                _c("option", { attrs: { value: "1" } }, [
                                  _vm._v(_vm._s(_vm.$t("default.pointBig"))),
                                ]),
                                _c("option", { attrs: { value: "2" } }, [
                                  _vm._v(_vm._s(_vm.$t("default.pointSmall"))),
                                ]),
                              ]
                            ),
                          ]),
                          _c("label", [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t(`${_vm.i18nPrefix}pointWallet`)) +
                                " "
                            ),
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.searchForm.pointsWallet,
                                    expression: "searchForm.pointsWallet",
                                  },
                                ],
                                on: {
                                  change: function ($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call(
                                        $event.target.options,
                                        function (o) {
                                          return o.selected
                                        }
                                      )
                                      .map(function (o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.$set(
                                      _vm.searchForm,
                                      "pointsWallet",
                                      $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    )
                                  },
                                },
                              },
                              [
                                _c("option", { attrs: { value: "0" } }, [
                                  _vm._v(_vm._s(_vm.$t("default.none"))),
                                ]),
                                _c("option", { attrs: { value: "1" } }, [
                                  _vm._v(_vm._s(_vm.$t("default.pointBig"))),
                                ]),
                                _c("option", { attrs: { value: "2" } }, [
                                  _vm._v(_vm._s(_vm.$t("default.pointSmall"))),
                                ]),
                                _vm._v("> "),
                              ]
                            ),
                          ]),
                          _c("label", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    `${this.i18nPrefix}dialog.historyList.totalWinLose`
                                  )
                                ) +
                                " "
                            ),
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.searchForm.totalWinning,
                                    expression: "searchForm.totalWinning",
                                  },
                                ],
                                on: {
                                  change: function ($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call(
                                        $event.target.options,
                                        function (o) {
                                          return o.selected
                                        }
                                      )
                                      .map(function (o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.$set(
                                      _vm.searchForm,
                                      "totalWinning",
                                      $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    )
                                  },
                                },
                              },
                              [
                                _c("option", { attrs: { value: "0" } }, [
                                  _vm._v(_vm._s(_vm.$t("default.none"))),
                                ]),
                                _c("option", { attrs: { value: "1" } }, [
                                  _vm._v(_vm._s(_vm.$t("default.pointBig"))),
                                ]),
                                _c("option", { attrs: { value: "2" } }, [
                                  _vm._v(_vm._s(_vm.$t("default.pointSmall"))),
                                ]),
                              ]
                            ),
                          ]),
                        ]
                      : _vm._e(),
                  ]
                : _vm._e(),
              _vm.getUserinfo.permissions.includes(10107) && _vm.searchBar
                ? [
                    _c("label", [
                      _vm._v(
                        " " + _vm._s(_vm.$t(`${_vm.i18nPrefix}phone`)) + " "
                      ),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.searchForm.phoneNumber,
                            expression: "searchForm.phoneNumber",
                          },
                        ],
                        attrs: { type: "text" },
                        domProps: { value: _vm.searchForm.phoneNumber },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.searchForm,
                              "phoneNumber",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                    _c("label", [
                      _vm._v(
                        " " + _vm._s(_vm.$t(`${_vm.i18nPrefix}name`)) + " "
                      ),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.searchForm.name,
                            expression: "searchForm.name",
                          },
                        ],
                        attrs: { type: "text" },
                        domProps: { value: _vm.searchForm.name },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.searchForm,
                              "name",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                    !_vm.getUserinfo.koreanStyleInterface
                      ? _c("label", [
                          _vm._v(
                            " " + _vm._s(_vm.$t(`${_vm.i18nPrefix}line`)) + " "
                          ),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.searchForm.socialId,
                                expression: "searchForm.socialId",
                              },
                            ],
                            attrs: { type: "text" },
                            domProps: { value: _vm.searchForm.socialId },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.searchForm,
                                  "socialId",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ])
                      : _vm._e(),
                    _c("label", [
                      _vm._v(
                        " " + _vm._s(_vm.$t(`${_vm.i18nPrefix}starLevel`)) + " "
                      ),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.searchForm.star,
                            expression: "searchForm.star",
                          },
                        ],
                        attrs: { type: "text" },
                        domProps: { value: _vm.searchForm.star },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.searchForm,
                              "star",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                  ]
                : _vm._e(),
            ],
            2
          ),
          _c(
            "div",
            { staticClass: "advance-block" },
            [
              !_vm.getUserinfo.koreanStyleInterface
                ? _c("QuickSearch", { on: { changeTime: _vm.changeTime } })
                : _vm._e(),
              _c(
                "button",
                {
                  staticClass: "search-pc-btn main-style-btn px-5",
                  on: {
                    click: function ($event) {
                      return _vm.search(false, true)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("default.search")))]
              ),
            ],
            1
          ),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "container-view" },
        [
          _c(
            "div",
            { staticClass: "total-block-v2" },
            [
              !_vm.getUserinfo.koreanStyleInterface
                ? _c("div", { staticClass: "total-block-v2-item" }, [
                    _c("div", { staticClass: "total-block-v2-item-left" }, [
                      _vm._v(_vm._s(_vm.$t("default.totalDataCount")) + ":"),
                    ]),
                    _c("div", { staticClass: "total-block-v2-item-right" }, [
                      _vm._v(_vm._s(_vm.page.total ?? 0)),
                    ]),
                  ])
                : _vm._e(),
              false
                ? [
                    _c("div", { staticClass: "total-block-v2-item" }, [
                      _c("div", { staticClass: "total-block-v2-item-left" }, [
                        _vm._v(
                          _vm._s(_vm.$t(`${_vm.i18nPrefix}inReviewCount`)) + ":"
                        ),
                      ]),
                      _c("div", { staticClass: "total-block-v2-item-right" }, [
                        _vm._v(_vm._s(_vm.statusCountData.inReviewCount ?? 0)),
                      ]),
                    ]),
                    _c("div", { staticClass: "total-block-v2-item" }, [
                      _c("div", { staticClass: "total-block-v2-item-left" }, [
                        _vm._v(
                          _vm._s(_vm.$t(`${_vm.i18nPrefix}approvedCount`)) + ":"
                        ),
                      ]),
                      _c("div", { staticClass: "total-block-v2-item-right" }, [
                        _vm._v(_vm._s(_vm.statusCountData.approvedCount ?? 0)),
                      ]),
                    ]),
                    _c("div", { staticClass: "total-block-v2-item" }, [
                      _c("div", { staticClass: "total-block-v2-item-left" }, [
                        _vm._v(
                          _vm._s(_vm.$t(`${_vm.i18nPrefix}rejectedCount`)) + ":"
                        ),
                      ]),
                      _c("div", { staticClass: "total-block-v2-item-right" }, [
                        _vm._v(_vm._s(_vm.statusCountData.rejectedCount ?? 0)),
                      ]),
                    ]),
                  ]
                : _vm._e(),
            ],
            2
          ),
          _c("div", { staticClass: "table-block" }, [
            _c(
              "table",
              { class: { KoreanStyle: _vm.getUserinfo.koreanStyleInterface } },
              [
                _c("thead", [
                  _c("tr", [
                    _vm.getUserinfo.koreanStyleInterface
                      ? _c("th", [_vm._v(_vm._s(_vm.$t("default.sort")))])
                      : _vm._e(),
                    _c("th", { staticClass: "mw-3words" }, [
                      _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}agentFrom`))),
                    ]),
                    _c("th", [_vm._v(_vm._s(_vm.$t("default.tag")))]),
                    _c("th", { staticClass: "mw-2words" }, [
                      _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}account`))),
                    ]),
                    _vm.getUserinfo.koreanStyleInterface
                      ? _c("th", { staticClass: "mw-2words" }, [
                          _vm._v(_vm._s(_vm.$t("default.nickName"))),
                        ])
                      : _vm._e(),
                    _c("th", [
                      _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}accountBank`))),
                    ]),
                    _vm.getUserinfo.koreanStyleInterface
                      ? _c("th", [
                          _vm._v(_vm._s(_vm.$t("default.bankAccountNumber"))),
                        ])
                      : _vm._e(),
                    _vm.getUserinfo.bindingCvsCard
                      ? _c("th", [_vm._v(_vm._s(_vm.$t("default.store")))])
                      : _vm._e(),
                    _c("th", [_vm._v(_vm._s(_vm.$t("default.detail")))]),
                    _c("th", { staticClass: "mw-3words" }, [
                      _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}wallet`))),
                    ]),
                    _vm.getUserinfo.pointWallet
                      ? _c("th", { staticClass: "mw-3words" }, [
                          _vm._v(
                            _vm._s(_vm.$t(`${_vm.i18nPrefix}pointWallet`))
                          ),
                        ])
                      : _vm._e(),
                    _c(
                      "th",
                      { staticStyle: { "min-width": "150px !important" } },
                      [_vm._v(_vm._s(_vm.$t("default.status")))]
                    ),
                    _vm.getUserinfo.koreanStyleInterface
                      ? _c("th", [
                          _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}ip`))),
                        ])
                      : _vm._e(),
                    _vm.getUserinfo.koreanStyleInterface
                      ? _c("th", [
                          _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}device`))),
                        ])
                      : _vm._e(),
                    !_vm.getUserinfo.koreanStyleInterface
                      ? _c("th", [
                          _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}recommend`))),
                        ])
                      : _vm._e(),
                    !_vm.getUserinfo.koreanStyleInterface
                      ? _c("th", [
                          _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}createFrom`))),
                        ])
                      : _vm._e(),
                    _c("th", [
                      _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}createTime`))),
                    ]),
                    !_vm.getUserinfo.pointWallet
                      ? _c("th", { staticClass: "mw-3words" }, [
                          _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}history`))),
                        ])
                      : _vm._e(),
                    _vm.getUserinfo.koreanStyleInterface
                      ? _c("th", [
                          _vm._v(_vm._s(_vm.$t("default.total_deposit"))),
                        ])
                      : _vm._e(),
                    _vm.getUserinfo.koreanStyleInterface
                      ? _c("th", [
                          _vm._v(_vm._s(_vm.$t("default.total_withdrawl"))),
                        ])
                      : _vm._e(),
                    _vm.getUserinfo.koreanStyleInterface
                      ? _c("th", [
                          _vm._v(_vm._s(_vm.$t("default.total_profit"))),
                        ])
                      : _vm._e(),
                  ]),
                ]),
                _c(
                  "tbody",
                  [
                    !_vm.allData || !_vm.allData.length
                      ? _c("tr", [
                          _c("td", { attrs: { colspan: "999" } }, [
                            _vm._v(_vm._s(_vm.$t("default.noData"))),
                          ]),
                        ])
                      : _vm._e(),
                    _vm._l(_vm.allData, function (list, index) {
                      return _c("tr", { key: index }, [
                        _vm.getUserinfo.koreanStyleInterface
                          ? _c("td", [_vm._v(_vm._s(list.id))])
                          : _vm._e(),
                        _c("td", [
                          _c("div", { staticClass: "block-between" }, [
                            _c("div", { staticClass: "block-between-box" }, [
                              _c("div", { staticClass: "d-flex flex-column" }, [
                                _c("span", [_vm._v(_vm._s(list.agentAccount))]),
                                _c("span", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.GLOBAL.i18nCheckName(
                                          _vm.getAgentListAddDefault.find(
                                            (data) =>
                                              data.account == list.agentAccount
                                          )
                                        )
                                      ) +
                                      " "
                                  ),
                                ]),
                              ]),
                              _c(
                                "div",
                                {
                                  staticClass: "table-icon",
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleDialog(list, "Agent")
                                    },
                                  },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: require("@/assets/icon/edit.svg"),
                                    },
                                  }),
                                ]
                              ),
                            ]),
                          ]),
                        ]),
                        _c("td", [
                          _c(
                            "div",
                            [
                              _c(
                                "button",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        _vm.getUserinfo.permissions.includes(
                                          10113
                                        ),
                                      expression:
                                        "getUserinfo.permissions.includes(10113)",
                                    },
                                  ],
                                  staticClass: "table-btn main-style-btn w-100",
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleDialog(
                                        {
                                          id: list.id,
                                          Tags: _vm.tagList,
                                          haveTag: list.tags,
                                        },
                                        "EditTag"
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(`${_vm.i18nPrefix}editTag`)
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                              list.tags
                                ? _c("Tags", {
                                    attrs: {
                                      tags: list.tags,
                                      tagList: _vm.tagList,
                                      isColumn: true,
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]),
                        _c("td", [
                          _c("div", { staticClass: "block-between" }, [
                            _c("div", { staticClass: "block-between-box" }, [
                              _vm._v(
                                " " +
                                  _vm._s(list.star) +
                                  " " +
                                  _vm._s(_vm.$t("default.star")) +
                                  " "
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "table-icon",
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleDialog(list, "level")
                                    },
                                  },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: require("@/assets/icon/edit.svg"),
                                    },
                                  }),
                                ]
                              ),
                            ]),
                            _vm._v(" " + _vm._s(list.memberName) + " "),
                            _c("div", { staticClass: "block-between-box" }, [
                              _vm._v(" " + _vm._s(list.account) + " "),
                              _c("div", { staticClass: "d-flex" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "table-icon mr-1",
                                    on: {
                                      click: function ($event) {
                                        return _vm.copyAccount(list.account)
                                      },
                                    },
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: require("@/assets/icon/copy.svg"),
                                      },
                                    }),
                                  ]
                                ),
                                !(
                                  !_vm.getUserinfo.permissions.includes(
                                    10102
                                  ) ||
                                  !_vm.getUserinfo.permissions.includes(10107)
                                )
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "table-icon",
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleDialog(
                                              list,
                                              "Edit"
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("img", {
                                          attrs: {
                                            src: require("@/assets/icon/edit.svg"),
                                          },
                                        }),
                                      ]
                                    )
                                  : _vm._e(),
                              ]),
                            ]),
                            _c(
                              "button",
                              {
                                staticClass: "table-btn main-style-btn w-100",
                                on: {
                                  click: function ($event) {
                                    return _vm.handleDialog(list, "Remark")
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("default.remark")))]
                            ),
                          ]),
                        ]),
                        _vm.getUserinfo.koreanStyleInterface
                          ? _c("td", [_vm._v(_vm._s(list.nickName))])
                          : _vm._e(),
                        _c("td", { staticClass: "banks-td" }, [
                          !_vm.getUserinfo.koreanStyleInterface
                            ? _c("div", [
                                _c(
                                  "button",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value:
                                          _vm.getUserinfo.permissions.includes(
                                            10104
                                          ),
                                        expression:
                                          "getUserinfo.permissions.includes(10104)",
                                      },
                                    ],
                                    staticClass: "table-btn main-style-btn",
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleDialog(
                                          list,
                                          "CreateBank"
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(`${_vm.i18nPrefix}createBank`)
                                      )
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "banks-div" },
                                  _vm._l(
                                    list.banks,
                                    function (bankDetail, index) {
                                      return _c(
                                        "div",
                                        {
                                          key: index,
                                          staticClass: "banks-div-item",
                                        },
                                        [
                                          _c(
                                            "button",
                                            {
                                              staticClass: "bank-btn",
                                              class:
                                                bankDetail.verifyStatus === 1
                                                  ? "bank-btn-verify"
                                                  : "bank-btn-close",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.handleDialog(
                                                    {
                                                      bankDetail: bankDetail,
                                                      id: list.id,
                                                    },
                                                    "EditBank"
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    bankDetail.bankType !== 9
                                                      ? bankDetail.bankName
                                                        ? bankDetail.bankName
                                                        : _vm.GLOBAL.i18nCheckName(
                                                            _vm.getBankTypeList.find(
                                                              (data) => {
                                                                return (
                                                                  data.id ===
                                                                  bankDetail.bankType
                                                                )
                                                              }
                                                            )
                                                          )
                                                      : bankDetail.walletName
                                                  ) +
                                                  " " +
                                                  _vm._s(
                                                    (bankDetail.bankBranch
                                                      ? "(" +
                                                        bankDetail.bankBranch +
                                                        ")"
                                                      : "") + "\n"
                                                  ) +
                                                  " "
                                              ),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    (bankDetail.bankType !== 9
                                                      ? bankDetail.bankAccountName
                                                      : bankDetail.currencyName) +
                                                      "\n"
                                                  ) +
                                                  " " +
                                                  _vm._s(
                                                    bankDetail.bankAccountNumber
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "table-icon mr-1",
                                              on: {
                                                click: function ($event) {
                                                  _vm.copyBankInfo(
                                                    bankDetail.bankName
                                                      ? bankDetail.bankName
                                                      : _vm.GLOBAL.i18nCheckName(
                                                          _vm.getBankTypeList.find(
                                                            (data) => {
                                                              return (
                                                                data.id ===
                                                                bankDetail.bankType
                                                              )
                                                            }
                                                          )
                                                        ),
                                                    bankDetail.bankAccountName,
                                                    bankDetail.bankAccountNumber
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("img", {
                                                attrs: {
                                                  src: require("@/assets/icon/copy.svg"),
                                                },
                                              }),
                                            ]
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                ),
                              ])
                            : _c("div", [
                                _c(
                                  "button",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value:
                                          _vm.getUserinfo.permissions.includes(
                                            10104
                                          ) && list.banks?.length === 0,
                                        expression:
                                          "getUserinfo.permissions.includes(10104) && list.banks?.length === 0",
                                      },
                                    ],
                                    staticClass: "table-btn main-style-btn",
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleDialog(
                                          list,
                                          "CreateBank"
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(`${_vm.i18nPrefix}createBank`)
                                      )
                                    ),
                                  ]
                                ),
                                _c(
                                  "span",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleDialog(
                                          {
                                            bankDetail: list.banks[0],
                                            id: list.id,
                                          },
                                          "EditBank"
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(list.banks[0]?.bankName) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]),
                        ]),
                        _vm.getUserinfo.koreanStyleInterface
                          ? _c("td", [
                              _vm._v(
                                " " +
                                  _vm._s(list.banks[0]?.bankAccountNumber) +
                                  " "
                              ),
                            ])
                          : _vm._e(),
                        _c(
                          "td",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.getUserinfo.bindingCvsCard,
                                expression: "getUserinfo.bindingCvsCard",
                              },
                            ],
                            staticClass: "banks-td",
                          },
                          [
                            _c("div", { attrs: { clas: "text-left" } }, [
                              _c(
                                "button",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        _vm.getUserinfo.permissions.includes(
                                          10119
                                        ),
                                      expression:
                                        "getUserinfo.permissions.includes(10119)",
                                    },
                                  ],
                                  staticClass: "table-btn main-style-btn",
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleDialog(list, "CreateCvs")
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.$t("default.create")))]
                              ),
                              _c(
                                "div",
                                { staticClass: "banks-div" },
                                _vm._l(
                                  list.cvsInfo,
                                  function (CvsDetail, index) {
                                    return _c(
                                      "div",
                                      {
                                        key: index,
                                        staticClass: "banks-div-item",
                                      },
                                      [
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "bank-btn bank-btn-verify",
                                            attrs: {
                                              disabled:
                                                !_vm.getUserinfo.permissions.includes(
                                                  10120
                                                ),
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleDialog(
                                                  { CvsDetail: CvsDetail },
                                                  "EditCvs"
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  CvsDetail.name +
                                                    " " +
                                                    CvsDetail.city +
                                                    "(" +
                                                    CvsDetail.district +
                                                    ")" +
                                                    "\n"
                                                ) +
                                                " " +
                                                _vm._s(
                                                  CvsDetail.code +
                                                    " " +
                                                    CvsDetail.storeName
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  }
                                ),
                                0
                              ),
                            ]),
                          ]
                        ),
                        _c("td", [
                          _c(
                            "button",
                            {
                              staticClass: "table-btn main-style-btn",
                              on: {
                                click: function ($event) {
                                  return _vm.handleDialog(
                                    { list: list },
                                    "Detail"
                                  )
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("default.detail")))]
                          ),
                        ]),
                        _c("td", { staticStyle: { cursor: "pointer" } }, [
                          _c(
                            "div",
                            {
                              on: {
                                click: function ($event) {
                                  _vm.getUserinfo.permissions.includes(10114)
                                    ? _vm.handleDialog(
                                        list,
                                        "BalanceAdjustment"
                                      )
                                    : ""
                                },
                              },
                            },
                            [_vm._v(_vm._s(list.balance))]
                          ),
                          _c(
                            "button",
                            {
                              staticClass: "table-btn main-style-btn",
                              attrs: {
                                disabled:
                                  !_vm.getUserinfo.permissions.includes(10115),
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.handleDialog(list, "GameWallet")
                                },
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t(`${_vm.i18nPrefix}gameWallet`))
                              ),
                            ]
                          ),
                          _c("br"),
                          _c(
                            "button",
                            {
                              staticClass: "table-btn main-style-btn",
                              attrs: {
                                disabled:
                                  !_vm.getUserinfo.permissions.includes(10117),
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.handleDialog(list, "RedPacket")
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      `${_vm.i18nPrefix}dialog.balance-adjustment.adjustmentType4`
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ]),
                        _c(
                          "td",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.getUserinfo.pointWallet,
                                expression: "getUserinfo.pointWallet",
                              },
                            ],
                            staticStyle: { cursor: "pointer" },
                          },
                          [
                            _c(
                              "div",
                              {
                                on: {
                                  click: function ($event) {
                                    _vm.getUserinfo.permissions.includes(10118)
                                      ? _vm.handleDialog(list, "pointWallet")
                                      : ""
                                  },
                                },
                              },
                              [_vm._v(_vm._s(list.point))]
                            ),
                          ]
                        ),
                        _c("td", { staticClass: "status-td" }, [
                          _vm.getUserinfo.koreanStyleInterface
                            ? _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.GLOBAL.i18nCheckName(
                                      _vm.auditStatuses.find(
                                        (data) =>
                                          list.memberRegistrationReviewStatus ==
                                          data.id
                                      )
                                    )
                                  )
                                ),
                              ])
                            : _vm._e(),
                          _c(
                            "div",
                            {
                              on: {
                                click: function ($event) {
                                  _vm.getUserinfo.permissions.includes(10102)
                                    ? _vm.handleDialog(list, "status")
                                    : ""
                                },
                              },
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    list.status === 1
                                      ? _vm.$t("default.active")
                                      : _vm.$t("default.inactive")
                                  )
                                ),
                              ]),
                              list.isNew === 1
                                ? _c("span", [
                                    _vm._v(
                                      _vm._s(_vm.$t(`${_vm.i18nPrefix}new`))
                                    ),
                                  ])
                                : _vm._e(),
                              list.enableSeamless === 1
                                ? _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(`${_vm.i18nPrefix}seamless`)
                                      )
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._l(list.paymentGateways, function (gateWays) {
                                return _c(
                                  "span",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: gateWays.enable,
                                        expression: "gateWays.enable",
                                      },
                                    ],
                                    key: gateWays.paymentGatewayId,
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "DepositTypeList." +
                                            gateWays.paymentGatewayId
                                        )
                                      )
                                    ),
                                  ]
                                )
                              }),
                            ],
                            2
                          ),
                          list.isLogin
                            ? _c("span", { staticClass: "islogin" }, [
                                _vm._v(
                                  _vm._s(_vm.$t(`${_vm.i18nPrefix}islogin`))
                                ),
                              ])
                            : _vm._e(),
                          list.isLogin
                            ? _c(
                                "button",
                                {
                                  staticClass: "kick-btn",
                                  on: {
                                    click: function ($event) {
                                      _vm.getUserinfo.permissions.includes(
                                        10102
                                      )
                                        ? _vm.kick(list.id)
                                        : ""
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t(`${_vm.i18nPrefix}kick`))
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]),
                        !_vm.getUserinfo.koreanStyleInterface
                          ? _c("td", [_vm._v(_vm._s(list.referralCode))])
                          : _vm._e(),
                        !_vm.getUserinfo.koreanStyleInterface
                          ? _c("td", [_vm._v(_vm._s(list.creator))])
                          : _vm._e(),
                        _vm.getUserinfo.koreanStyleInterface
                          ? _c("td", [_vm._v(_vm._s(list.lastLoginIp))])
                          : _vm._e(),
                        _vm.getUserinfo.koreanStyleInterface
                          ? _c("td", [_vm._v(_vm._s(list.device))])
                          : _vm._e(),
                        _c("td", [_vm._v(" " + _vm._s(list.createTime) + " ")]),
                        !_vm.getUserinfo.koreanStyleInterface
                          ? _c("td", [
                              _c(
                                "button",
                                {
                                  staticClass: "table-btn main-style-btn w-100",
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleDialog(
                                        { history: list.history },
                                        "HistoryList"
                                      )
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.$t("default.detail")))]
                              ),
                              _c("br"),
                            ])
                          : _vm._e(),
                        _vm.getUserinfo.koreanStyleInterface
                          ? _c("td", [
                              _vm._v(_vm._s(list.totalAtmTotalPayment)),
                            ])
                          : _vm._e(),
                        _vm.getUserinfo.koreanStyleInterface
                          ? _c("td", [
                              _vm._v(_vm._s(list.memberWithdrawalListAccount)),
                            ])
                          : _vm._e(),
                        _vm.getUserinfo.koreanStyleInterface
                          ? _c("td", [_vm._v(_vm._s(list.totalProfit))])
                          : _vm._e(),
                        list.memberRegistrationReviewStatus !== 2 &&
                        _vm.getUserinfo.koreanStyleInterface
                          ? _c("span", { staticClass: "blockDiv" }, [
                              _vm._v(" " + _vm._s(_vm.noticeMsg)),
                            ])
                          : _vm._e(),
                      ])
                    }),
                  ],
                  2
                ),
              ]
            ),
          ]),
          _vm.allData
            ? _c("serverPagination", {
                attrs: { page: _vm.page },
                on: { ServerPageUtils: _vm.search, changeTake: _vm.changeTake },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "transition",
        { attrs: { name: "fade", mode: "out-in" } },
        [
          _c(
            _vm.currDialog,
            _vm._b(
              {
                tag: "component",
                attrs: {
                  stars: _vm.getStars,
                  gamePlatforms: _vm.getGamePlatforms,
                  getUserinfo: _vm.getUserinfo,
                  i18nPrefix: _vm.i18nPrefix,
                },
                on: {
                  toggle: function ($event) {
                    _vm.currDialog = undefined
                  },
                  editBank: _vm.editBank,
                  createBank: _vm.createBank,
                  createCvs: _vm.createCvs,
                  editCvs: _vm.editCvs,
                  deleteCvs: _vm.deleteCvs,
                  create: _vm.create,
                  edit: _vm.edit,
                  status: _vm.status,
                  agent: _vm.agent,
                  balanceAdjust: _vm.balanceAdjust,
                  redPacket: _vm.redPacket,
                  deleteBank: _vm.deleteBank,
                  updateWallet: _vm.init,
                  editTag: _vm.editTag,
                  updateRemark: _vm.updateRemark,
                  point: _vm.point,
                  updateLevel: _vm.updateLevel,
                  detailToggle: _vm.init,
                },
              },
              "component",
              _vm.dialogData,
              false
            )
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }