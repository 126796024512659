<template>
  <div>
    <div class="container-view">
      <button class="search-btn main-style-btn" @click="search()">{{ $t("default.search") }}</button>
      <div class="header">
        <h5>{{ $t("title." + $route.meta.title) }}</h5>
      </div>
      <div class="main">
        <div class="input-block">
          <label>
            {{ $t("default.member") }}
            <input type="text" v-model="searchForm.memberAccount" />
          </label>
          <div class="input-block-multiselect">
            {{ $t("default.agent") }}
            <multiselect v-model="agentData" label="name" track-by="id" :options="getAgentListAddDefault"
              :allow-empty="false" :preselectFirst="true" :clear-on-select="false" :preserve-search="true"
              :custom-label="GLOBAL.nameWithLang">
            </multiselect>
          </div>
          <label>
            {{ $t("default.amount") }}
            <input type="text" v-model="searchForm.amount" />
          </label>
          <label>
            {{ $t("default.amount") }}
            <select v-model="amountType">
              <option value="0">{{ $t("default.none") }}</option>
              <option value="1">{{ $t("default.pointBig") }}</option>
              <option value="2">{{ $t("default.pointSmall") }}</option>
            </select>
          </label>
          <label>
            {{ $t(`${i18nPrefix}submitStartTime`) }}
            <date-picker v-model="submitStartTime" format="YYYY-MM-DD HH:mm" value-type="format"
              type="datetime"></date-picker>
          </label>
          <label>
            {{ $t(`${i18nPrefix}submitEndTime`) }}
            <date-picker v-model="submitEndTime" format="YYYY-MM-DD HH:mm" value-type="format"
              type="datetime"></date-picker>
          </label>
          <label>
            {{ $t("default.auditTimeFrom") }}
            <date-picker v-model="auditStartTime" format="YYYY-MM-DD HH:mm" value-type="format"
              type="datetime"></date-picker>
          </label>
          <label>
            {{ $t("default.auditTimeTo") }}
            <date-picker v-model="auditEndTime" format="YYYY-MM-DD HH:mm" value-type="format"
              type="datetime"></date-picker>
          </label>
        </div>
        <div class="divider-h-2"></div>
        <MultiSelectBox :selectData.sync="auditStatuses" :list="getAuditStatusList" :multiple="true" />
        <div class="advance-block">
          <QuickSearch @changeTime="changeTime"></QuickSearch>
          <button class="search-pc-btn main-style-btn px-5" @click="search()">{{ $t("default.search") }}</button>
        </div>
      </div>
    </div>
    <div class="container-view">
      <div class="total-block-v2" v-if="!getUserinfo.koreanStyleInterface">
        <div class="total-block-v2-item">
          <div class="total-block-v2-item-left">{{ $t("default.totalDataCount") }}:</div>
          <div class="total-block-v2-item-right">{{ digital.format(total.totalDataCount) }}</div>
        </div>
        <div class="total-block-v2-item">
          <div class="total-block-v2-item-left">{{ $t("default.totalMemberCount") }}:</div>
          <div class="total-block-v2-item-right">{{ digital.format(total.totalMemberCount) }}</div>
        </div>
        <div class="total-block-v2-item">
          <div class="total-block-v2-item-left">{{ $t("default.totalAmount") }}:</div>
          <div class="total-block-v2-item-right">{{ digital.format(total.totalAmount) }}</div>
        </div>
      </div>
      <div v-if="!getUserinfo.koreanStyleInterface" class="table-block">
        <table>
          <thead>
            <tr>
              <th></th>
              <th>{{ $t("default.member") }}</th>
              <th>{{ $t("default.tag") }}</th>
              <th>{{ $t(`${i18nPrefix}bankName`) }}</th>
              <th>{{ $t(`${i18nPrefix}bankAccountName`) }}</th>
              <th>{{ $t(`${i18nPrefix}bankAccountNumber`) }}</th>
              <th v-show="getUserinfo.cardlessDepositFeatureToggle">{{ $t(`${i18nPrefix}transferInformation`) }}</th>
              <th>{{ $t(`${i18nPrefix}submitTime`) }}</th>
              <th>{{ $t(`${i18nPrefix}beneficiaryBankInfo`) }}</th>
              <th>{{ $t(`${i18nPrefix}amount`) }}</th>
              <th>{{ $t("default.status") }}</th>
              <th>{{ $t("default.remark") }}</th>
              <th>{{ $t("default.admin") }}</th>
              <th>{{ $t(`${i18nPrefix}auditTime`) }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="!allData || !allData.length">
              <td colspan="13">{{ $t("default.noData") }}</td>
            </tr>
            <tr v-for="(list, index) of allData" :key="index">
              <td>
                <button class="table-btn main-style-btn" @click="handleDialog(list, 'Audit')" :disabled="!getUserinfo.permissions.includes(10201)
                  ">{{ (list.auditStatus === 1 || list.auditStatus === 5) ? $t(`${i18nPrefix}audit`) :
    $t(`${i18nPrefix}editRemark`) }}</button>
              </td>
              <td>{{ list.memberAccount }}</td>
              <td :class="{ 'tags-td': Array.isArray(list.tags) }">
                <Tags v-if="list.tags" :tags="list.tags" :tagList="tagList"></Tags>
              </td>
              <td>{{ list.bank.bankName }}</td>
              <td>{{ list.bank.bankAccountName }}</td>
              <td>{{ list.bank.bankAccountNumber }}</td>
              <td v-show="getUserinfo.cardlessDepositFeatureToggle">{{ list.transferInformation }}</td>
              <td>{{ list.submitTime }}</td>
              <td>
                {{ list.companyBankName }}
                <br />
                {{ list.companyBankAccountNumber }}
              </td>
              <td>{{ list.amount }}</td>
              <td>
                {{
                  GLOBAL.i18nCheckName(getAuditStatusList.find(
                    (data) => data.id === list.auditStatus
                  ))
                }}
              </td>
              <td>{{ list.remark }}</td>
              <td>{{ list.auditAccount }}</td>
              <td>{{ list.auditTime }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-else class="table-block ">
        <table class="KoreanStyle">
          <thead>
            <tr>
              <th>{{ $t("default.sort") }}</th>
              <th></th>
              <th>{{ $t("default.agent") }}</th>
              <th>{{ $t("default.member") }}</th>
              <th>{{ $t("default.nickName") }}</th>
              <th>{{ $t("default.star") }}</th>
              <th>{{ $t("member.member-management.name") }}</th>
              <th>{{ $t("default.amount") }}</th>
              <th>{{ $t("default.status") }}</th>
              <th>{{ $t(`${i18nPrefix}dailyDepositAmount`) }}</th>
              <th>{{ $t(`${i18nPrefix}dailyWithdrawalAmount`) }}</th>
              <th>{{ $t("member.member-management.ip") }}</th>
              <th>{{ $t(`${i18nPrefix}bonusReceived`) }}</th>
              <th>{{ $t("default.processAccount") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="!allData || !allData.length">
              <td colspan="999">{{ $t("default.noData") }}</td>
            </tr>
            <tr v-for="(list, index) of allData" :key="index">
              <td>{{ list.id }}</td>
              <td>
                <button class="table-btn main-style-btn" @click="handleDialog(list, 'Audit')" :disabled="!getUserinfo.permissions.includes(10201)
                  ">{{ (list.auditStatus === 1 || list.auditStatus === 5) ? $t(`${i18nPrefix}audit`) :
    $t(`${i18nPrefix}editRemark`) }}</button>
              </td>
              <td>{{ list.agentAccount }}</td>
              <td>
                <span class="memberSpan"
                  @click="handleDialog({ list: { id: list.member.id, account: list.member.account, toggle: true } }, 'Detail')">{{
                    list.memberAccount }}
                </span>
              </td>
              <td>{{ list.nickName }}</td>
              <td>{{ list.star + $t("default.star") }}</td>
              <td>{{ list.name }}</td>
              <td>{{ list.amount }}</td>
              <td>
                {{
                  GLOBAL.i18nCheckName(getAuditStatusList.find(
                    (data) => data.id === list.auditStatus
                  ))
                }}
              </td>
              <td>{{ list.dailyDepositAmount }}</td>
              <td>{{ list.dailyWithdrawalAmount }}</td>
              <td>{{ list.lastLoginIp }}</td>
              <td>{{ list.bonusReceived ? $t("default.yes") : $t("default.no") }}</td>
              <td>{{ list.auditAccount }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <serverPagination v-if="allData" :page="page" @ServerPageUtils="search" @changeTake="changeTake" />
    </div>

    <transition name="fade" mode="out-in">
      <component :i18nPrefix="i18nPrefix" :is="currDialog" v-bind="dialogData" @toggle="currDialog = undefined"
        @audit="audit"></component>
    </transition>
  </div>
</template>

<script>
import Audit from "./dialog/audit";
import {
  depositList,
  auditDeposit,
  getDepositTotal
} from "@/api/member/deposit";
import { searchModel } from "./model";
import { mapGetters } from "vuex";
import Tags from "@/layout/components/tags";
import Detail from "../member-management/dialog/detail";
import serverPagination from "@/layout/components/serverPagination";
import handleServerPageUtils from "@/layout/mixins/handleServerPageUtils";
import handleDialog from "@/layout/mixins/handleDialog";
import QuickSearch from "@/layout/components/QuickSearch";
import MultiSelectBox from "@/layout/components/MultiSelectBox";
import { checkData } from "@/utils/checkData";
import { joinT, transToday } from "@/utils/transDateUtils";
import Multiselect from "vue-multiselect";

export default {
  name: "MemberDeposit",
  data() {
    return {
      searchForm: _.cloneDeep(searchModel),
      amountType: 0,
      submitStartTime: undefined,
      submitEndTime: undefined,
      auditStartTime: undefined,
      auditEndTime: undefined,
      bankList: undefined,
      auditStatuses: [],
      agentData: [],
      total: {
        totalDataCount: 0,
        totalMemberCount: 0,
        totalAmount: 0
      },
      i18nPrefix: "member.member-deposit."
    };
  },
  components: {
    Audit,
    Tags,
    Detail,
    serverPagination,
    QuickSearch,
    Multiselect,
    MultiSelectBox
  },
  mixins: [handleServerPageUtils, handleDialog],
  computed: {
    ...mapGetters([
      "getBankListAddDefault",
      "getAuditStatusList",
      "tagList",
      "getUserinfo",
      "getAgentListAddDefault"
    ])
  },
  async created() {
    this.auditStatuses = [
      this.getAuditStatusList[0],
      this.getAuditStatusList[4]
    ];
    // if (this.$route.query?.permissionId) {
    //   await readPrivateMessageByType({ permission: this.$route.query.permissionId * 1 });
    // }else {
    //   this.checkOrderID();
    //   return
    // }
    if (this.getUserinfo.koreanStyleInterface) {
      this.auditStatuses = [...this.getAuditStatusList];
      this.submitStartTime = transToday('from');
      this.submitEndTime = transToday('to');
    }
    this.search();
  },
  methods: {
    search(page = false) {
      this.searchForm.submitStartTime = this.submitStartTime
        ? joinT(
          this.submitStartTime +
          this.GLOBAL.startSecond +
          this.GLOBAL.timeZone
        )
        : undefined;
      this.searchForm.submitEndTime = this.submitEndTime
        ? joinT(
          this.submitEndTime + this.GLOBAL.endSecond + this.GLOBAL.timeZone
        )
        : undefined;
      this.searchForm.auditStartTime = this.auditStartTime
        ? joinT(
          this.auditStartTime + this.GLOBAL.startSecond + this.GLOBAL.timeZone
        )
        : undefined;
      this.searchForm.auditEndTime = this.auditEndTime
        ? joinT(
          this.auditEndTime + this.GLOBAL.endSecond + this.GLOBAL.timeZone
        )
        : undefined;
      this.searchForm.auditStatuses = this.auditStatuses.map(
        data => data.id * 1
      );

      this.searchForm.amount = this.searchForm.amount * 1;
      this.searchForm.agentId = this.agentData.id;

      if (this.amountType == 0) {
        this.searchForm.sort = undefined
      } else {
        let sort = [{
          column: "amount",
          order: this.amountType == 2 ? 1 : 2,
        }]
        this.searchForm.sort = sort;
      }

      this.searchForm.page.take = this.take * 1;
      this.searchForm.page.skip = this.take * (page ? page - 1 : 0);
      if (checkData(this.searchForm)) {
        depositList(this.searchForm).then(res => {
          this.allData = res.data.list;
          this.page = res.data.page;
          this.closeDialog();
          this.searchForm.sort[0].column = undefined;
          this.searchForm.sort[0].order = undefined;
        });
        getDepositTotal(this.searchForm).then(res => (this.total = res.data));
      }
    },
    audit(data) {
      const obj = {
        id: data.id,
        auditStatus: data.auditStatus,
        remark: data.remark
      };
      auditDeposit(obj).then(res => {
        if (res) this.search();
      });
    },
    changeTime(time) {
      this.submitStartTime = time.startTime;
      this.submitEndTime = time.endTime;
      this.search();
    },
    checkOrderID() {
      if (this.$route.query?.orderId) {
        depositList({ id: this.$route.query?.orderId * 1 }).then(res => {
          this.allData = res.data;
          this.currDialog = undefined;
        });
      }
    }
  }
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped></style>