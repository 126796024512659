var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "container-view" }, [
        _c("div", { staticClass: "head-box" }, [
          _c("div", { staticClass: "header justify-content-start m-0" }, [
            _c("h5", { staticClass: "mr-3" }, [
              _vm._v(_vm._s(_vm.$t("title." + _vm.$route.meta.title))),
            ]),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.total.totalOnlineCount,
                    expression: "total.totalOnlineCount",
                  },
                ],
                staticClass: "onlinePeople",
                attrs: {
                  disabled: !_vm.getUserinfo.permissions.includes(160300),
                },
                on: {
                  click: function ($event) {
                    return _vm.handleDialog(undefined, "onlineMemberList")
                  },
                },
              },
              [
                _vm._v(
                  _vm._s(
                    _vm.$t("default.onlineMemberAmount") +
                      ":" +
                      _vm.total.totalOnlineCount +
                      _vm.$t("default.people")
                  )
                ),
              ]
            ),
          ]),
          _c(
            "div",
            { staticClass: "advance-block-v2" },
            [
              _c("QuickSearch", {
                ref: "quickSearch",
                on: { changeTime: _vm.changeTime },
              }),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "main" }, [
          _c("div", { staticClass: "form-box mt-3 gap-20" }, [
            _c(
              "div",
              { staticClass: "total totalTopBox form-box-3x1 bg-white" },
              [
                _c(
                  "div",
                  { staticClass: "form-box-half form-column total-top" },
                  [
                    _c("div", { staticClass: "total-center" }, [
                      _c("div", { staticClass: "total-top-title" }, [
                        _vm._v(
                          _vm._s(_vm.$t(`${_vm.i18nPrefix}totalRegistryCount`))
                        ),
                      ]),
                      _c("div", { staticClass: "total-top-num" }, [
                        _vm._v(
                          _vm._s(
                            _vm.digital.format(_vm.total.totalRegistryCount)
                          )
                        ),
                      ]),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "form-box-half form-column total-top" },
                  [
                    _c("div", { staticClass: "total-center" }, [
                      _c("div", { staticClass: "total-top-title" }, [
                        _vm._v(
                          _vm._s(_vm.$t(`${_vm.i18nPrefix}totalActiveCount`))
                        ),
                      ]),
                      _c("div", { staticClass: "total-top-num" }, [
                        _vm._v(
                          _vm._s(_vm.digital.format(_vm.total.totalActiveCount))
                        ),
                      ]),
                    ]),
                  ]
                ),
              ]
            ),
            _c(
              "div",
              { staticClass: "total totalTopBox form-box-max bg-white" },
              [
                _c(
                  "div",
                  { staticClass: "form-box-3x1 form-column total-top" },
                  [
                    _c("div", { staticClass: "total-center" }, [
                      _c("div", { staticClass: "total-top-title" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t(`${_vm.i18nPrefix}totalFirstDepositAmount`)
                          )
                        ),
                      ]),
                      _c("div", { staticClass: "total-top-num" }, [
                        _vm._v(
                          _vm._s(
                            _vm.digital.format(
                              _vm.total.totalFirstDepositAmount
                            )
                          )
                        ),
                      ]),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "form-box-3x1 form-column total-top" },
                  [
                    _c("div", { staticClass: "total-center" }, [
                      _c("div", { staticClass: "total-top-title" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t(`${_vm.i18nPrefix}totalFirstDepositCount`)
                          )
                        ),
                      ]),
                      _c("div", { staticClass: "total-top-num" }, [
                        _vm._v(
                          _vm._s(
                            _vm.digital.format(
                              _vm.total.totalFirstDepositMemberCount
                            )
                          )
                        ),
                      ]),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "form-box-3x1 form-column total-top" },
                  [
                    _c("div", { staticClass: "total-center" }, [
                      _c("div", { staticClass: "total-top-title" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t(`${_vm.i18nPrefix}totalRepeatDepositCount`)
                          )
                        ),
                      ]),
                      _c("div", { staticClass: "total-top-num" }, [
                        _vm._v(
                          _vm._s(
                            _vm.digital.format(
                              _vm.total.totalRepeatedDepositMemberCount
                            )
                          )
                        ),
                      ]),
                    ]),
                  ]
                ),
              ]
            ),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "container-view" }, [
        _c("div", { staticClass: "main" }, [
          _c(
            "div",
            {
              staticClass: "form-box gap-10",
              staticStyle: { "align-items": "stretch" },
            },
            [
              _c(
                "div",
                { staticClass: "total form-box-max form-column gap-10" },
                [
                  _c("div", { staticClass: "form-box gap-10" }, [
                    _c("div", { staticClass: "card-blue form-box-3x1 px-3" }, [
                      _c("div", { staticClass: "total-card" }, [
                        _c("div", { staticClass: "total-card-title" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t(`${_vm.i18nPrefix}totalDepositAmount`)
                            )
                          ),
                        ]),
                        _c("div", { staticClass: "total-card-num" }, [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.total.totalDepositMemberCount +
                                  _vm.$t("default.people") +
                                  "｜" +
                                  _vm.total.totalDepositCount +
                                  _vm.$t("default.records")
                              )
                            ),
                          ]),
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.digital.format(_vm.total.totalDepositAmount)
                              ) +
                              " "
                          ),
                        ]),
                      ]),
                    ]),
                    !_vm.getUserinfo.koreanStyleInterface
                      ? _c(
                          "div",
                          { staticClass: "card-purple form-box-3x1 px-3" },
                          [
                            _c("div", { staticClass: "total-card" }, [
                              _c("div", { staticClass: "total-card-title" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      `${_vm.i18nPrefix}totalDiscountAmount`
                                    )
                                  )
                                ),
                              ]),
                              _c("div", { staticClass: "total-card-num" }, [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.total.totalDiscountMemberCount +
                                        _vm.$t("default.people") +
                                        "｜" +
                                        _vm.total.totalDiscountCount +
                                        _vm.$t("default.records")
                                    )
                                  ),
                                ]),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.digital.format(
                                        _vm.total.totalDiscountAmount
                                      )
                                    ) +
                                    " "
                                ),
                              ]),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    _c("div", { staticClass: "card-green form-box-3x1 px-3" }, [
                      _c("div", { staticClass: "total-card" }, [
                        _c("div", { staticClass: "total-card-title" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                `${_vm.i18nPrefix}totalAdjustmentAddAmount`
                              )
                            )
                          ),
                        ]),
                        _c("div", { staticClass: "total-card-num" }, [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.total.totalAdjustmentAddMemberCount +
                                  _vm.$t("default.people") +
                                  "｜" +
                                  _vm.total.totalAdjustmentAddCount +
                                  _vm.$t("default.records")
                              )
                            ),
                          ]),
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.digital.format(
                                  _vm.total.totalAdjustmentAddAmount
                                )
                              ) +
                              " "
                          ),
                        ]),
                      ]),
                    ]),
                    _vm.getUserinfo.koreanStyleInterface
                      ? _c(
                          "div",
                          { staticClass: "card-purple form-box-3x1 px-3" },
                          [
                            _c("div", { staticClass: "total-card" }, [
                              _c("div", { staticClass: "total-card-title" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(`${_vm.i18nPrefix}pointsEarned`)
                                  )
                                ),
                              ]),
                              _c("div", { staticClass: "total-card-num" }, [
                                _c("span"),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.digital.format(_vm.total.pointsEarned)
                                    ) +
                                    " "
                                ),
                              ]),
                            ]),
                          ]
                        )
                      : _vm._e(),
                  ]),
                  _c("div", { staticClass: "form-box gap-10" }, [
                    _c("div", { staticClass: "card-red form-box-3x1 px-3" }, [
                      _c("div", { staticClass: "total-card" }, [
                        _c("div", { staticClass: "total-card-title" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t(`${_vm.i18nPrefix}totalWithdrawalAmount`)
                            )
                          ),
                        ]),
                        _c("div", { staticClass: "total-card-num" }, [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.total.totalWithdrawalMemberCount +
                                  _vm.$t("default.people") +
                                  "｜" +
                                  _vm.total.totalWithdrawalCount +
                                  _vm.$t("default.records")
                              )
                            ),
                          ]),
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.digital.format(
                                  _vm.total.totalWithdrawalAmount
                                )
                              ) +
                              " "
                          ),
                        ]),
                      ]),
                    ]),
                    !_vm.getUserinfo.koreanStyleInterface
                      ? _c(
                          "div",
                          { staticClass: "card-grey form-box-3x1 px-3" },
                          [
                            _c("div", { staticClass: "total-card" }, [
                              _c("div", { staticClass: "total-card-title" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(`${_vm.i18nPrefix}totalFeeAmount`)
                                  )
                                ),
                              ]),
                              _c("div", { staticClass: "total-card-num" }, [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.total.totalFeeCount +
                                        _vm.$t("default.records")
                                    )
                                  ),
                                ]),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.digital.format(
                                        _vm.total.totalFeeAmount
                                      )
                                    ) +
                                    " "
                                ),
                              ]),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    _c("div", { staticClass: "card-pink form-box-3x1 px-3" }, [
                      _c("div", { staticClass: "total-card" }, [
                        _c("div", { staticClass: "total-card-title" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                `${_vm.i18nPrefix}totalAdjustmentReduceAmount`
                              )
                            )
                          ),
                        ]),
                        _c("div", { staticClass: "total-card-num" }, [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.total.totalAdjustmentReduceMemberCount +
                                  _vm.$t("default.people") +
                                  "｜" +
                                  _vm.total.totalAdjustmentReduceCount +
                                  _vm.$t("default.records")
                              )
                            ),
                          ]),
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.digital.format(
                                  _vm.total.totalAdjustmentReduceAmount
                                )
                              ) +
                              " "
                          ),
                        ]),
                      ]),
                    ]),
                    _vm.getUserinfo.koreanStyleInterface
                      ? _c(
                          "div",
                          { staticClass: "card-pink form-box-3x1 px-3" },
                          [
                            _c("div", { staticClass: "total-card" }, [
                              _c("div", { staticClass: "total-card-title" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(`${_vm.i18nPrefix}pointsDeducted`)
                                  )
                                ),
                              ]),
                              _c("div", { staticClass: "total-card-num" }, [
                                _c("span"),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.digital.format(
                                        _vm.total.pointsDeducted
                                      )
                                    ) +
                                    " "
                                ),
                              ]),
                            ]),
                          ]
                        )
                      : _vm._e(),
                  ]),
                ]
              ),
              _c("div", { staticClass: "form-box-3x1" }, [
                _c(
                  "div",
                  { staticClass: "total form-box form-column total-game-box" },
                  [
                    _c("div", { staticClass: "total-game total-game-head" }, [
                      _c("div", { staticClass: "total-game-title" }, [
                        _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}totalWinLose`))),
                      ]),
                      _c("div", { staticClass: "total-game-num" }, [
                        _vm._v(
                          _vm._s(_vm.digital.format(_vm.total.totalWinLose))
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "total-game-divider" }),
                    _c("div", { staticClass: "total-game total-game-row" }, [
                      _c("div", { staticClass: "total-game-title" }, [
                        _vm._v(
                          _vm._s(_vm.$t(`${_vm.i18nPrefix}memberBetCount`))
                        ),
                      ]),
                      _c("div", { staticClass: "total-game-num" }, [
                        _vm._v(
                          _vm._s(_vm.digital.format(_vm.total.memberBetCount))
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "total-game total-game-row" }, [
                      _c("div", { staticClass: "total-game-title" }, [
                        _vm._v(
                          _vm._s(_vm.$t(`${_vm.i18nPrefix}totalBetCount`))
                        ),
                      ]),
                      _c("div", { staticClass: "total-game-num" }, [
                        _vm._v(
                          _vm._s(_vm.digital.format(_vm.total.totalBetCount))
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "total-game total-game-row" }, [
                      _c("div", { staticClass: "total-game-title" }, [
                        _vm._v(
                          _vm._s(_vm.$t(`${_vm.i18nPrefix}totalBetAmount`))
                        ),
                      ]),
                      _c("div", { staticClass: "total-game-num" }, [
                        _vm._v(
                          _vm._s(_vm.digital.format(_vm.total.totalBetAmount))
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "total-game total-game-row" }, [
                      _c("div", { staticClass: "total-game-title" }, [
                        _vm._v(
                          _vm._s(_vm.$t(`${_vm.i18nPrefix}totalValidBetAmount`))
                        ),
                      ]),
                      _c("div", { staticClass: "total-game-num" }, [
                        _vm._v(
                          _vm._s(
                            _vm.digital.format(_vm.total.totalValidBetAmount)
                          )
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "total-game total-game-row" }, [
                      _c("div", { staticClass: "total-game-title" }, [
                        _vm._v(
                          _vm._s(_vm.$t(`${_vm.i18nPrefix}totalPayoutAmount`))
                        ),
                      ]),
                      _c("div", { staticClass: "total-game-num" }, [
                        _vm._v(
                          _vm._s(
                            _vm.digital.format(_vm.total.totalPayoutAmount)
                          )
                        ),
                      ]),
                    ]),
                  ]
                ),
              ]),
            ]
          ),
          _c("div", { staticClass: "overview-table mt-4 gap-10" }, [
            _c(
              "div",
              { staticClass: "profitGames overview-table-border" },
              [
                _c("ProfitGames", {
                  attrs: {
                    list: _vm.list.profitGames,
                    i18nPrefix: _vm.i18nPrefix,
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "lossGames overview-table-border" },
              [
                _c("LossGames", {
                  attrs: {
                    list: _vm.list.lossGames,
                    i18nPrefix: _vm.i18nPrefix,
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "profitMembers overview-table-border" },
              [
                _c("ProfitMembers", {
                  attrs: {
                    list: _vm.list.profitMembers,
                    i18nPrefix: _vm.i18nPrefix,
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "lossMembers overview-table-border" },
              [
                _c("LossMembers", {
                  attrs: {
                    list: _vm.list.lossMembers,
                    i18nPrefix: _vm.i18nPrefix,
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _c(
        "transition",
        { attrs: { name: "fade", mode: "out-in" } },
        [
          _c(
            _vm.currDialog,
            _vm._b(
              {
                tag: "component",
                attrs: { i18nPrefix: _vm.i18nPrefix, tagList: _vm.tagList },
                on: { toggle: _vm.closeDialog },
              },
              "component",
              _vm.dialogData,
              false
            )
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }