<template>
  <div>
    <div class="container-view">
      <button class="search-btn main-style-btn" @click="search()">{{ $t("default.search") }}</button>
      <div class="header">
        <h5>{{ $t("title." + $route.meta.title) }}</h5>
      </div>
      <div class="main">
        <div class="input-block">
          <div class="input-block-multiselect">
            {{ $t("default.agent") }}
            <multiselect v-model="agentData" label="name" track-by="id" :options="getAgentListAddDefault"
              :allow-empty="false" :preselectFirst="true" :clear-on-select="false" :preserve-search="true"
              :custom-label="GLOBAL.nameWithLang"></multiselect>
          </div>
        </div>
        <div class="advance-block">
          <QuickSearch @changeTime="changeTime"></QuickSearch>
          <button class="search-pc-btn main-style-btn px-5" @click="search()">{{ $t("default.search") }}</button>
        </div>
      </div>
    </div>
    <div class="container-view">
      <div class="main" style="display: flex; flex-direction: column;">
        <div class="form-box w-100" style="justify-content: center !important;">
          <div class="total totalTopBox form-box-half bg-white">
            <div class="form-box-half form-column total-top">
              <div class="total-center">
                <div class="total-top-title">{{ $t(`${i18nPrefix}totalProfitAmount`) }}</div>
                <div class="total-top-num">{{ digital.format(data.totalProfitAmount) }}</div>
              </div>
            </div>
            <div class="form-box-half form-column total-top">
              <div class="total-center">
                <div class="total-top-title">{{ $t(`${i18nPrefix}totalRebateAmount`) }}</div>
                <div class="total-top-num">{{ digital.format(data.totalRebateAmount) }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-box mt-2">
          <div class="form-box-half gap-20">
            <div class="total totalTopBox form-box bg-white">
              <div class="form-box-half form-column total-top">
                <div class="total-center">
                  <div class="total-top-title">{{ $t(`${i18nPrefix}totalCommisionAmount`) }}</div>
                  <div class="total-top-num">{{ digital.format(data.totalCommisionAmount) }}</div>
                </div>
              </div>
              <div class="form-box-half form-column total-top">
                <div class="total-center">
                  <div class="total-top-title">{{ $t(`${i18nPrefix}totalDiscountAmount`) }}</div>
                  <div class="total-top-num">{{ digital.format(data.totalDiscountAmount) }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="form-box-half gap-20">
            <div class="total totalTopBox form-box bg-white">
              <div class="form-box-half form-column total-top">
                <div class="total-center">
                  <div class="total-top-title">{{ $t(`${i18nPrefix}totalRegisterCount`) }}</div>
                  <div class="total-top-num">{{ digital.format(data.totalRegisterCount) }}</div>
                </div>
              </div>
              <div class="form-box-half form-column total-top">
                <div class="total-center">
                  <div class="total-top-title">{{ $t(`${i18nPrefix}totalActiveCount`) }}</div>
                  <div class="total-top-num">{{ digital.format(data.totalActiveCount) }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { searchModel } from "./model";
import {
  getList,
} from "@/api/agent-management/agentDataOverview";
import { mapGetters } from "vuex";
import QuickSearch from "@/layout/components/QuickSearch";
import { joinT, transToday } from "@/utils/transDateUtils";
import Multiselect from "vue-multiselect";

export default {
  name: "AgentDataOverview",
  components: {
    QuickSearch,
    Multiselect,
  },
  data() {
    return {
      searchForm: _.cloneDeep(searchModel),
      startTime: transToday("from"),
      endTime: transToday(),
      agentData: undefined,
      digital: new Intl.NumberFormat(),
      data: {
        "totalProfitAmount": 0,
        "totalRebateAmount": 0,
        "totalCommisionAmount": 0,
        "totalDiscountAmount": 0,
        "totalRegisterCount": 0,
        "totalActiveCount": 0
      },
      i18nPrefix: "agent.agentDataOverview."
    };
  },
  mixins: [],
  computed: {
    ...mapGetters(["getUserinfo", "getAgentListAddDefault"]),
  },
  created() {
    this.search()
  },
  methods: {
    search() {
      this.searchForm.timeFrom = this.startTime ? joinT(this.startTime + this.GLOBAL.startSecond + this.GLOBAL.timeZone) : undefined;
      this.searchForm.timeTo = this.endTime ? joinT(this.endTime + this.GLOBAL.endSecond + this.GLOBAL.timeZone) : undefined;

      this.searchForm.agentId = this.agentData?.id;
      getList(this.searchForm).then((res) => {
        this.data = res.data;
      });
    },
    changeTime(time) {
      this.startTime = time.startTime;
      this.endTime = time.endTime;
      this.search();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/form.scss";
.gap-20 {
  gap: 20px;
}

.total {
  .total-top-title {
    color: rgb(0 0 0 / 50%);
    font-size: 14px;
    font-weight: 700;
  }

  .total-top-num {
    color: rgb(0 0 0 / 50%);
    font-size: 20px;
  }

  .total-top {
    border-right: 1.5px solid #7E96A3;
  }

  .total-top:last-child {
    border-right: 0;
  }

  .total-center {
    padding: 16px 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
}

.totalTopBox {
  border: 1.5px solid #7E96A3;
  border-radius: 8px;
}

@media screen and (max-width: 768px) {
  .head-box {
    flex-direction: column;
    gap: 5px;
  }

  .total-center {
    margin-bottom: 10px;
  }

  .total-top {
    border-right: 0 !important;
    border-bottom: 1.5px solid #7E96A3;
  }

  .total-top:last-child {
    border-bottom: 0;
  }
}
</style>