<template>
  <div>
    <div class="bookmark-box">
      <div class="bookmark-view">
        <div v-for="list of bookmarkColumn" :key="list.id" @click="bookmark(list.id)"
          :class="{ 'chooice': bookmarkNum == list.id }">{{ list.name }}</div>
      </div>
    </div>
    <div>
      <div class="container-view">
        <button v-if="bookmarkNum == 3" class="search-btn main-style-btn" @click="search()">{{
          $t("default.search") }}</button>
        <div class="header">
          <h5>{{ $t(`${i18nPrefix}redeemCode`) }}</h5>
          <div class="float-right">
            <button v-if="bookmarkNum == 1" class="create-btn main-style-btn" @click="handleDialog(undefined, 'Edit')">{{
              $t("default.create") + " " }}{{ $t(`${i18nPrefix}template`) }}</button>
          </div>
        </div>
        <div class="main">
          <div class="input-block" v-show="bookmarkNum == 3">
            <label>
              {{ $t(`${i18nPrefix}date`) }}
              <select v-model="searchForm.id">
                <!-- <option :value="0 * 1">{{ $t('default.none') }}</option> -->
                <option v-for="d of periodList" :value="d.id" :key="d.id">{{ d.name }}</option>
              </select>
            </label>
          </div>
          <div v-if="bookmarkNum == 3" class="advance-block">
            <button
              class="search-pc-btn main-style-btn px-5" 
              @click="search()"
            >
              {{ $t("default.search") }}
            </button>
          </div>
        </div>
      </div>
      <div class="container-view">
        <div class="total-block-v2" v-show="bookmarkNum == 3">
          <div class="total-block-v2-item">
            <div class="total-block-v2-item-left">{{ $t(`${i18nPrefix}totalMemberCount`) }}:</div>
            <div class="total-block-v2-item-right">{{ total.totalMemberCount }}</div>
          </div>
          <div class="total-block-v2-item">
            <div class="total-block-v2-item-left">{{ $t(`${i18nPrefix}totalReceiveCount`) }}:</div>
            <div class="total-block-v2-item-right">{{ total.totalReceiveCount }}</div>
          </div>
        </div>
        <div class="table-block">
          <TreeTable ref="treeTable" :list="allData" :column="tableColumn">
            <!-- 操作按鈕 -->
            <template v-slot:column_CampaignSetting="props">
              <button class="normal-btn" @click="handleDialog({ editData: props }, 'Edit')">{{ $t("default.edit")
              }}</button>
              <button class="delete-btn" @click="handleDeleteSetting(props.id)">{{ $t("default.delete") }}</button>
            </template>
            <template v-slot:column_CampaignRedeemCode="props">
              <button class="normal-btn" @click="handleDialog({
                isCampaign: true, detail: (props.status == 1 || props.status == 2)
                  && getUserinfo.permissions.includes(171402) ? undefined : true, editData: props,
                  padding: props.status == 1 ? true : false
              }, 'Edit')">
                {{
                  (props.status == 1 || props.status == 2)
                  && getUserinfo.permissions.includes(171402) ? $t("default.edit") : $t("default.detail")
                }}</button>
              <button v-show="props.status !== 3"
                :class="{ 'btn-orange': props.status == 1, 'delete-btn': props.status == 2 }"
                @click="props.status == 1 ? release(props, true) : handleDeleteRedeemCode(props)">{{ props.status == 1 ?
                  $t(`${i18nPrefix}release`) : $t('default.inactive') }}</button>
            </template>
            <!-- 活動期別 -->
            <template v-slot:column_date="props">{{ `${props.id}- ${props.startTime}-${props.endTime}` }}</template>
            <!-- 狀態 -->
            <template v-slot:column_status="props">
              {{ GLOBAL.i18nCheckName(redeemCodeStatus.find(d => d.id == props.status)) }}
            </template>
            <!-- 狀態 -->
            <template v-slot:column_star="props">
              {{ props.star + $t("default.star") }}
            </template>
            <!-- 操作記錄 -->
            <!-- <template v-slot:column_updateUser="props">
              {{ bookmarkNum !== 2 ? props.updateUser : '' }}
              <button
                @click="getProcess(props.id)"
                class="normal-btn"
                v-show="bookmarkNum == 2"
              >{{ $t("default.processAccount") }}</button>
            </template> -->
            <!-- 簽到類型 -->
            <template v-slot:column_signInType="props">{{ GLOBAL.i18nCheckName(signInType.find(d => d.id ==
              props.signInType)) }}</template>
            <!-- 獎勵類型 -->
            <template v-slot:column_rewardType="props">{{ GLOBAL.i18nCheckName(rewardType.find(d => d.id ==
              props.rewardType)) }}</template>
          </TreeTable>
          <serverPagination v-if="bookmarkNum !== 1" :page="page" @ServerPageUtils="search" @changeTake="changeTake">
          </serverPagination>
        </div>
      </div>
    </div>
    <transition name="fade" mode="out-in">
      <component :is="currDialog" :i18nPrefix="i18nPrefix" :starList="getStars" :tagList="tagList"
        :agentList="getAgentList" v-bind="dialogData" @toggle="currDialog = undefined" @search="search">
      </component>
    </transition>
    <Alert v-if="alertMsg" :message="alertMsg" @toggle="alertMsg = undefined" @doIt="deleteFunction" />
    <Process v-if="processData" :processTitle="processTitle" :processData="processData" @toggle="processData = undefined">
      <template v-slot:oldValue_Status="props">
        {{ GLOBAL.i18nCheckName(
          luckyDrawStatus.find(
            (data) => props.Status === data.id
          )) }}
      </template>
      <template v-slot:newValue_Status="props">
        {{ GLOBAL.i18nCheckName(
          luckyDrawStatus.find(
            (data) => props.Status === data.id
          )) }}
      </template>
    </Process>
  </div>
</template>

<script>
import {
  selectMenu,
  getCampaignSetting,
  deleteCampaignSetting,
  publishRedeemCode,
  closeRedeemCode,
  getRedeemCodeProcess,
  getRedeemCodeList,
  getRedeemCodeRecord,
  getRedeemCodeRecordTotal
} from "@/api/campaign/redeemCode";
import { searchModel } from "./model";
import Edit from "./dialog/edit";
import Alert from "@/layout/components/Alert";
import Process from "@/layout/components/Process";
import handleDialog from "@/layout/mixins/handleDialog";
import TreeTable from "@/layout/components/TreeTable";
import { mapGetters } from "vuex";
import serverPagination from "@/layout/components/serverPagination";
import handleServerPageUtils from "@/layout/mixins/handleServerPageUtils";

export default {
  name: "RedeemCode",
  data() {
    return {
      searchForm: _.cloneDeep(searchModel),
      allData: [],
      bookmarkNum: 1,
      redeemCodeStatus: [],
      alertMsg: "",
      processData: undefined,
      periodList: [],
      redeemCodeList: [],
      deleteData: undefined,
      startTime: undefined,
      endTime: undefined,
      total: {
        totalMemberCount: 0,
        totalReceiveCount: 0
      },
      i18nPrefix: "campaign.redeemCode."
    };
  },
  components: {
    Edit,
    Alert,
    Process,
    TreeTable,
    serverPagination
  },
  mixins: [handleDialog, handleServerPageUtils],
  computed: {
    ...mapGetters([
      "getUserinfo",
      "getStars",
      "tagList",
      "getAgentList",
      "getStatusList",
    ]),
    tableColumn() {
      switch (this.bookmarkNum) {
        case 1:
          return [
            {
              key: "CampaignSetting",
              name: "",
              width: 80
            },
            {
              key: "name",
              name: this.$t(`${this.i18nPrefix}template`)
            },
            {
              key: "createUser",
              name: this.$t(`${this.i18nPrefix}createUser`),
              isText: true
            },
            {
              key: "createTime",
              name: this.$t(`${this.i18nPrefix}createTime`)
            },
            {
              key: "updateUser",
              name: this.$t(`${this.i18nPrefix}updateUser`),
              isText: true
            },
            {
              key: "updateTime",
              name: this.$t(`${this.i18nPrefix}updateTime`)
            }
          ];
        case 2:
          return [
            {
              key: "CampaignRedeemCode",
              name: "",
              width: 80
            },
            {
              key: "name",
              name: this.$t(`${this.i18nPrefix}campaignName`)
            },
            {
              key: "date",
              name: this.$t(`${this.i18nPrefix}date`)
            },
            {
              key: "status",
              name: this.$t("default.status")
            },
            {
              key: "updateUser",
              name: this.$t(`${this.i18nPrefix}updateUser`),
              isText: true
            },
            {
              key: "updateTime",
              name: this.$t(`${this.i18nPrefix}updateTime`)
            }
          ];
        case 3:
          return [
            {
              key: "account",
              name: this.$t("default.member"),
              isText: true
            },
            {
              key: "amount",
              name: this.$t("default.amount"),
            },
            {
              key: "receiveTime",
              name: this.$t(`${this.i18nPrefix}receiveTime`),
            }
          ];
      }
    },
    bookmarkColumn() {
      return [
        {
          id: 1,
          name: this.$t(`${this.i18nPrefix}template`)
        },
        {
          id: 2,
          name: this.$t("default.search")
        },
        {
          id: 3,
          name: this.$t(`${this.i18nPrefix}record`)
        }
      ];
    },
    processTitle() {
      return [
        {
          key: "Status",
          name: this.$t("default.status")
        }
      ];
    }
  },
  async created() {
    await selectMenu({}).then(res => {
      this.redeemCodeStatus = res.data.status.map(data => {
        return { id: data.id, name: "MemberSignInStatus." + data.name };
      });

      this.searchForm.id = res.data.periodList[0]?.id;
      this.periodList = res.data.periodList.map(d => {
        return {
          id: d.id,
          name: `${d.id} - ${d.startTime} - ${d.endTime}`
        };
      });
    });

    await this.search();
  },
  methods: {
    async search(page = false) {
      this.closeDialog();
      this.searchForm.page.take = this.take * 1;
      this.searchForm.page.skip = this.take * (page ? page - 1 : 0);
      this.allData = [];
      switch (this.bookmarkNum) {
        case 1:
          return getCampaignSetting({
            discountType: 38,
            discountCategory: 7
          }).then(res => {
            this.allData = res.data;
          });
        case 2:
          return getRedeemCodeList(this.searchForm).then(res => {
            this.allData = res.data.list;
            this.page = res.data.page;
          });
        case 3:
          await getRedeemCodeRecord(this.searchForm).then(res => {
            this.allData = res.data.list;
            this.page = res.data.page;
          });
          await getRedeemCodeRecordTotal(this.searchForm).then(res => {
            this.total = res.data;
          });
          return;
      }
    },
    // 發布
    async release(data, status) {
      if (status) {
        await publishRedeemCode({ id: data.id }).then(res => {
          if (res) this.search();
        });
        return
      }

      await closeRedeemCode({ id: data.id }).then(res => {
        if (res) this.search();
      });
    },
    // 分頁
    bookmark(data) {
      this.allData = undefined;
      this.bookmarkNum = data;
      this.search();
    },
    // 刪除範本
    handleDeleteSetting(id) {
      this.alertMsg = this.$t("default.delMsg");
      this.deleteData = { id };
    },
    handleDeleteRedeemCode(data) {
      this.alertMsg = this.$t(`${this.i18nPrefix}alertMsg`);
      this.deleteData = data;
    },
    deleteFunction() {
      this.alertMsg = "";
      if (this.bookmarkNum == 1) {
        deleteCampaignSetting(this.deleteData).then(res => {
          if (res) this.search();
        });
      } else {
        this.release(this.deleteData, false);
      }
    },
    // 取得操作紀錄
    getProcess(id) {
      getRedeemCodeProcess({ id }).then(res => {
        this.processData = res.data;
      });
    }
  }
};
</script>
