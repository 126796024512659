
export let searchModel = {
    memberAccount: undefined,
    title: undefined,
    startTime: undefined,
    endTime: undefined,
    isRead: undefined,
    memberPrivateMessageStatus: 0,
    attachmentStatus: 3,
    page:  {
        "take": 10,
        "skip": 0
    },
}