import request from "@/utils/request";
import { getToken } from "@/utils/auth";

export function questioningList(data) {
    return request({
        url: "/GuestQuestioning/List",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function updateQuestioning(data) {
    return request({
        url: "/GuestQuestioning/Update",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}