<template>
  <!-- 會員帳戶資訊 -->
  <div class="d-flex form-column align-items-center h-full" style="width: 90%;">
    <div class="table-block-white-v2">
      <table>
        <thead>
          <tr>
            <th colspan="3" style="width: 100%;">{{ $t(`${i18nPrefix}dialog.detail.memberMonetary`) }}</th>
          </tr>
        </thead>
        <tbody>
          <!-- 錢包 -->
          <tr>
            <td>
              {{ $t(`${i18nPrefix}wallet`) }}
            </td>
            <td>
              {{ digital.format(list.balance) }}
            </td>
            <td>
              <button class="edit-btn" :disabled="editChooice !== undefined && getUserinfo.permissions.includes(10114)"
                @click="handleDialog(memberData, 'BalanceAdjustment')">{{
              $t("default.edit") }}</button>
            </td>
          </tr>
          <!-- 點數錢包 -->
          <tr v-show="getUserinfo.pointWallet">
            <td>
              {{ $t(`${i18nPrefix}pointWallet`) }}
            </td>
            <td>
              {{ digital.format(list.point) }}
            </td>
            <td>
              <button class="edit-btn" :disabled="editChooice !== undefined && getUserinfo.permissions.includes(10118)"
                @click="handleDialog(memberData, 'pointWallet')">{{
              $t("default.edit") }}</button>
            </td>
          </tr>
          <!-- 遊戲錢包 -->
          <tr>
            <td>
              {{ $t(`${i18nPrefix}gameWallet`) }}
            </td>
            <td>
              {{ digital.format(totalGameBalance) }}
            </td>
            <td>
              <button class="edit-btn" :disabled="editChooice !== undefined && getUserinfo.permissions.includes(10115)"
                @click="handleDialog(memberData, 'GameWallet')">{{
              $t("default.edit") }}</button>
            </td>
          </tr>
          <!-- 免轉通道 -->
          <tr>
            <td>
              {{ $t(`${i18nPrefix}seamless`) }}
            </td>
            <td>
              <div class="slide-block" style="margin: auto"
                @click="list.enableSeamless = (editChooice !== 'enableSeamless') ? list.enableSeamless : !list.enableSeamless"
                :class="{ 'slide-block-on': list.enableSeamless }">
                <div class="slide" :class="{ 'slide-on': list.enableSeamless }">
                  <label style="display: none"></label>
                  <input type="checkbox" v-model="list.enableSeamless" style="display: none" />
                </div>
              </div>
            </td>
            <td>
              <button class="edit-btn" :disabled="editChooice !== undefined" @click="edit('enableSeamless')"
                v-show="editChooice !== 'enableSeamless' && getUserinfo.permissions.includes(10102)">{{ $t("default.edit")
                }}</button>
              <div v-show="editChooice == 'enableSeamless'">
                <button class="normal-btn" @click="confirm('enableSeamless')">
                  {{ $t("default.confirm") }}
                </button>
                <button class="delete-btn" @click="cancel('enableSeamless')">
                  {{ $t("default.cancel") }}
                </button>
              </div>
            </td>
          </tr>
          <!-- 存款渠道設置 -->
          <tr>
            <td>
              {{ $t(`${i18nPrefix}dialog.status.depositChannel`) }}
            </td>
            <td>
              <div class="" v-for="gateways of list.paymentGateways">
                {{ $t("DepositTypeList." + gateways.paymentGatewayId) }}
                <div class="slide-block" style="margin: auto" :key="gateways.paymentGatewayId"
                  @click="gateways.enable = (editChooice !== 'paymentGateways') ? gateways.enable : !gateways.enable"
                  :class="{ 'slide-block-on': gateways.enable }">
                  <div class="slide" :class="{ 'slide-on': gateways.enable }">
                    <label style="display: none"></label>
                    <input type="checkbox" v-model="gateways.enable" style="display: none" />
                  </div>
                </div>
              </div>
            </td>
            <td>
              <button class="edit-btn" :disabled="editChooice !== undefined" @click="edit('paymentGateways')"
                v-show="editChooice !== 'paymentGateways' && getUserinfo.permissions.includes(10102)">{{ $t("default.edit")
                }}</button>
              <div v-show="editChooice == 'paymentGateways'">
                <button class="normal-btn" @click="confirm('paymentGateways')">
                  {{ $t("default.confirm") }}
                </button>
                <button class="delete-btn" @click="cancel('paymentGateways')">
                  {{ $t("default.cancel") }}
                </button>
              </div>
            </td>
          </tr>
          <!-- 投注狀況 -->
          <tr>
            <td>
              {{ $t(`${i18nPrefix}dialog.detail.totalVaildBet`) }}
            </td>
            <td>
              {{ $t(`${i18nPrefix}dialog.detail.totalVaildBetAmount`) + "： " + digital.format(list.totalValidBetAmount)
              }}
            </td>
            <td>
              <router-link :to="{
              path: '/statistical-report/bet-report',
              query: { memberAccount: memberData.account }
            }">
                {{ $t("title.bet-report") }}
              </router-link>
            </td>
          </tr>
          <!-- 存款次數 -->
          <tr>
            <td>
              {{ $t(`${i18nPrefix}dialog.detail.totalDepositCount`) }}
            </td>
            <td>
              {{ $t("default.count") + " / " + $t("default.amount") + "：" + list.totalDepositCount + " / " +
              digital.format(list.totalDepositAmount) }}
            </td>
            <td>
              <router-link :to="{
              path: '/report/member-deposit',
              query: { memberAccount: memberData.account }
            }">
                {{ $t("title.member-deposit") }}
              </router-link>
            </td>
          </tr>
          <!-- 提款次數 -->
          <tr>
            <td>
              {{ $t(`${i18nPrefix}dialog.detail.totalWithdrawalCount`) }}
            </td>
            <td>
              {{ $t("default.count") + " / " + $t("default.amount") + "：" + list.totalWithdrawalCount + " / " +
              digital.format(list.totalWithdrawalAmount) }}
            </td>
            <td>
              <router-link :to="{
              path: '/report/member-withdrawal-report',
              query: { memberAccount: memberData.account }
            }">
                {{ $t("title.member-withdrawal-report") }}
              </router-link>
            </td>
          </tr>
          <!-- 總輸贏 -->
          <tr>
            <td>
              {{ $t("default.totalWinLose") }}
            </td>
            <td>
              {{ digital.format(list.totalWinLose) }}
            </td>
            <td>
            </td>
          </tr>
          <!-- 存取差 -->
          <tr>
            <td>
              {{ $t("default.totalProfit") }}
            </td>
            <td>
              {{ list.totalProfit }}
            </td>
            <td>
            </td>
          </tr>
          <!-- 出款流水限制 -->
          <tr>
            <td>
              {{ $t("title.betAmountLimit") }}
            </td>
            <td>
              {{ $t(`${i18nPrefix}dialog.detail.unsettledAmount`) + " / " +
              $t(`${i18nPrefix}dialog.detail.accumulatedAmount`) + "：" + digital.format(list.unsettledAmount) + " / "
              + digital.format(list.accumulatedAmount)
              }}
            </td>
            <td>
              <router-link :to="{
              path: '/member/betAmountLimit',
              query: { memberAccount: memberData.account }
            }">
                {{ $t("title.betAmountLimit") }}
              </router-link>
            </td>
          </tr>
          <!-- 紅利領取 -->
          <tr>
            <td>
              {{ $t(`${i18nPrefix}dialog.detail.totalDiscountAmount`) }}
            </td>
            <td>
              {{ $t("default.count") + " / " + $t("default.amount") + "：" + list.totalDiscountCount + " / " +
              digital.format(list.totalDiscountAmount) }}
            </td>
            <td>
              <router-link :to="{
              path: '/member/member-discount',
              query: { memberAccount: memberData.account }
            }">
                {{ $t("title.member-discount") }}
              </router-link>
            </td>
          </tr>
          <!-- 最後儲值日 -->
          <tr>
            <td>
              {{ $t(`${i18nPrefix}lastDepositTime`) }}
            </td>
            <td>
              {{ list.lastDepositTime }}
            </td>
            <td>
            </td>
          </tr>
          <!-- 距今未儲值天數 -->
          <tr>
            <td>
              {{ $t(`${i18nPrefix}dialog.detail.depositPeriod`) }}
            </td>
            <td>
              {{ list.depositPeriod }}
            </td>
            <td>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <transition name="fade" mode="out-in">
      <component :getUserinfo="getUserinfo" :gamePlatforms="getGamePlatforms" :i18nPrefix="i18nPrefix" :is="currDialog"
        v-bind="dialogData" @balanceAdjust="balanceAdjust" @point="point" @updateWallet="init"
        @toggle="currDialog = undefined"></component>
    </transition>
  </div>
</template>

<script>
import GameWallet from "../game-wallet/game-wallet.vue";
import PointWallet from "../pointWallet.vue";
import BalanceAdjustment from "../balance-adjustment.vue";
import {
  addMemberPoint,
  reduceMemberPoint,
  updateEnableSeamless,
  updatePaymentGateways
} from "@/api/member/member";
import { createMemberBalanceAdjustment } from "@/api/member/member-balance-adjustment";
import { mapGetters } from "vuex";

export default {
  name: "MemberMonetary",
  props: ['list', 'memberData', 'totalGameBalance', 'chooice', 'i18nPrefix'],
  data() {
    return {
      digital: new Intl.NumberFormat(),
      enableSeamless: undefined,
      currDialog: undefined,
      dialogData: undefined,
      editChooice: undefined,
      paymentGateways: _.cloneDeep(this.memberData.paymentGateways)
    };
  },
  watch: {
    'chooice'(v) {
      this.editChooice = v;
    }
  },
  components: {
    GameWallet,
    PointWallet,
    BalanceAdjustment,
  },
  computed: {
    ...mapGetters(["getUserinfo", "getGamePlatforms"]),
  },
  created() {
  },
  methods: {
    edit(k) {
      this.editChooice = k;

      switch (k) {
        case 'enableSeamless':
          this.enableSeamless = this.list.enableSeamless;
          break;
        case 'paymentGateways':
          this.paymentGateways = _.cloneDeep(this.list.paymentGateways);
          break;
      }

      this.$emit('editting', k);
    },
    async confirm(k) {
      let result = false;
      switch (k) {
        case 'enableSeamless':
          await updateEnableSeamless({ id: this.memberData.id, enableSeamless: this.list.enableSeamless ? 1 : 2 }).then(res => {
            if (res) result = true;
          });
          break;
        case 'paymentGateways':
          await updatePaymentGateways({ id: this.memberData.id, paymentGateways: this.list.paymentGateways }).then(res => {
            if (res) result = true;
          });
          break;
      }
      if (result) this.init();
    },
    cancel(k) {
      this.editChooice = undefined;

      switch (k) {
        case 'enableSeamless':
          this.list.enableSeamless = this.enableSeamless;
          break;
        case 'paymentGateways':
          this.list.paymentGateways = this.paymentGateways;
          break;
      }

      this.$emit('editting', undefined);
    },
    init() {
      this.editChooice = undefined;
      this.currDialog = undefined;
      this.dialogData = undefined;

      this.$emit('editting', undefined);
      this.$emit('reload');
    },
    handleDialog(list, dialog) {
      this.currDialog = dialog;
      this.dialogData = list;
    },
    balanceAdjust(data) {
      createMemberBalanceAdjustment(data).then(res => {
        if (res) this.init();
      });
    },
    point(data) {
      if (data.type == 1) {
        addMemberPoint(data).then(res => {
          if (res) this.init();
        });
        return;
      }
      reduceMemberPoint(data).then(res => {
        if (res) this.init();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/form.scss";

tr {
  td:first-of-type {
    width: 25%;
  }

  td:nth-of-type(2) {
    width: 50%;
  }

  td:last-of-type {
    width: 25%;
  }
}

.gateway {
  display: inline-block;
  margin: 1px 3px;
}

.h-full {
  height: 100%;
}
</style>