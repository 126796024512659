var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "container-view" }, [
      _c("div", { staticClass: "header" }, [
        _c("h5", [_vm._v(_vm._s(_vm.$t("title." + _vm.$route.meta.title)))]),
      ]),
      _c("div", { staticClass: "wrap" }, [
        _c("div", { staticClass: "section" }, [
          _c("div", { staticClass: "header" }, [
            _c("h3", [
              _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}submitAutoSettleAmount`))),
            ]),
            _c(
              "button",
              {
                staticClass: "table-btn main-style-btn",
                attrs: {
                  disabled: !_vm.getUserinfo.permissions.includes(60806),
                },
                on: { click: _vm.submitAutoSettleAmount },
              },
              [_vm._v(" " + _vm._s(_vm.$t("default.edit")) + " ")]
            ),
          ]),
          _c("label", [
            _vm._v(" " + _vm._s(_vm.$t(`${_vm.i18nPrefix}balance`)) + " "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.number",
                  value: _vm.dataModel.autoSettleAmount,
                  expression: "dataModel.autoSettleAmount",
                  modifiers: { number: true },
                },
              ],
              attrs: { type: "number" },
              domProps: { value: _vm.dataModel.autoSettleAmount },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(
                    _vm.dataModel,
                    "autoSettleAmount",
                    _vm._n($event.target.value)
                  )
                },
                blur: function ($event) {
                  return _vm.$forceUpdate()
                },
              },
            }),
          ]),
        ]),
        _c("div", { staticClass: "section" }, [
          _c("div", { staticClass: "header" }, [
            _c("h3", [
              _vm._v(
                _vm._s(
                  _vm.$t(
                    `${_vm.i18nPrefix}memberWithdrawalPasswordFeatureToggle`
                  )
                )
              ),
            ]),
            _c("div", { staticClass: "header-flex" }, [
              _c("label", [
                _c(
                  "div",
                  {
                    staticClass: "slide-block",
                    class: {
                      "slide-block-on":
                        _vm.dataModel.memberWithdrawalPasswordFeatureToggle,
                    },
                    staticStyle: { margin: "auto" },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "slide",
                        class: {
                          "slide-on":
                            _vm.dataModel.memberWithdrawalPasswordFeatureToggle,
                        },
                      },
                      [
                        _c("label", {
                          staticStyle: { display: "none" },
                          attrs: {
                            for: "memberWithdrawalPasswordFeatureToggle",
                          },
                        }),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value:
                                _vm.dataModel
                                  .memberWithdrawalPasswordFeatureToggle,
                              expression:
                                "dataModel.memberWithdrawalPasswordFeatureToggle",
                            },
                          ],
                          staticStyle: { display: "none" },
                          attrs: {
                            type: "checkbox",
                            id: "memberWithdrawalPasswordFeatureToggle",
                          },
                          domProps: {
                            checked: Array.isArray(
                              _vm.dataModel
                                .memberWithdrawalPasswordFeatureToggle
                            )
                              ? _vm._i(
                                  _vm.dataModel
                                    .memberWithdrawalPasswordFeatureToggle,
                                  null
                                ) > -1
                              : _vm.dataModel
                                  .memberWithdrawalPasswordFeatureToggle,
                          },
                          on: {
                            change: function ($event) {
                              var $$a =
                                  _vm.dataModel
                                    .memberWithdrawalPasswordFeatureToggle,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.dataModel,
                                      "memberWithdrawalPasswordFeatureToggle",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.dataModel,
                                      "memberWithdrawalPasswordFeatureToggle",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(
                                  _vm.dataModel,
                                  "memberWithdrawalPasswordFeatureToggle",
                                  $$c
                                )
                              }
                            },
                          },
                        }),
                      ]
                    ),
                  ]
                ),
              ]),
              _c(
                "button",
                {
                  staticClass: "table-btn main-style-btn",
                  attrs: {
                    disabled: !_vm.getUserinfo.permissions.includes(60809),
                  },
                  on: {
                    click: _vm.submitMemberWithdrawalPasswordFeatureToggle,
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("default.edit")) + " ")]
              ),
            ]),
          ]),
          _c("label", { staticClass: "pc-only" }, [
            _c(
              "div",
              {
                staticClass: "slide-block",
                class: {
                  "slide-block-on":
                    _vm.dataModel.memberWithdrawalPasswordFeatureToggle,
                },
                staticStyle: { margin: "auto" },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "slide",
                    class: {
                      "slide-on":
                        _vm.dataModel.memberWithdrawalPasswordFeatureToggle,
                    },
                  },
                  [
                    _c("label", {
                      staticStyle: { display: "none" },
                      attrs: { for: "memberWithdrawalPasswordFeatureToggle" },
                    }),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value:
                            _vm.dataModel.memberWithdrawalPasswordFeatureToggle,
                          expression:
                            "dataModel.memberWithdrawalPasswordFeatureToggle",
                        },
                      ],
                      staticStyle: { display: "none" },
                      attrs: {
                        type: "checkbox",
                        id: "memberWithdrawalPasswordFeatureToggle",
                      },
                      domProps: {
                        checked: Array.isArray(
                          _vm.dataModel.memberWithdrawalPasswordFeatureToggle
                        )
                          ? _vm._i(
                              _vm.dataModel
                                .memberWithdrawalPasswordFeatureToggle,
                              null
                            ) > -1
                          : _vm.dataModel.memberWithdrawalPasswordFeatureToggle,
                      },
                      on: {
                        change: function ($event) {
                          var $$a =
                              _vm.dataModel
                                .memberWithdrawalPasswordFeatureToggle,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.dataModel,
                                  "memberWithdrawalPasswordFeatureToggle",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.dataModel,
                                  "memberWithdrawalPasswordFeatureToggle",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(
                              _vm.dataModel,
                              "memberWithdrawalPasswordFeatureToggle",
                              $$c
                            )
                          }
                        },
                      },
                    }),
                  ]
                ),
              ]
            ),
          ]),
        ]),
        _c("div", { staticClass: "section DepositLimit" }, [
          _c("div", { staticClass: "header" }, [
            _c("h3", [
              _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}submitDepositLimit`))),
            ]),
            _c(
              "button",
              {
                staticClass: "table-btn main-style-btn",
                attrs: {
                  disabled: !_vm.getUserinfo.permissions.includes(60810),
                },
                on: { click: _vm.submitDepositLimit },
              },
              [_vm._v(" " + _vm._s(_vm.$t("default.edit")) + " ")]
            ),
          ]),
          _c("label", [
            _vm._v(
              " " +
                _vm._s(_vm.$t(`${_vm.i18nPrefix}depositLimitElapsedMinutes`)) +
                " "
            ),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.number",
                  value: _vm.dataModel.depositLimitElapsedMinutes,
                  expression: "dataModel.depositLimitElapsedMinutes",
                  modifiers: { number: true },
                },
              ],
              attrs: { type: "number" },
              domProps: { value: _vm.dataModel.depositLimitElapsedMinutes },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(
                    _vm.dataModel,
                    "depositLimitElapsedMinutes",
                    _vm._n($event.target.value)
                  )
                },
                blur: function ($event) {
                  return _vm.$forceUpdate()
                },
              },
            }),
          ]),
          _c("label", [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t(`${_vm.i18nPrefix}depositLimitElapsedMinutesCount`)
                ) +
                " "
            ),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.number",
                  value: _vm.dataModel.depositLimitElapsedMinutesCount,
                  expression: "dataModel.depositLimitElapsedMinutesCount",
                  modifiers: { number: true },
                },
              ],
              attrs: { type: "number" },
              domProps: {
                value: _vm.dataModel.depositLimitElapsedMinutesCount,
              },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(
                    _vm.dataModel,
                    "depositLimitElapsedMinutesCount",
                    _vm._n($event.target.value)
                  )
                },
                blur: function ($event) {
                  return _vm.$forceUpdate()
                },
              },
            }),
          ]),
        ]),
        _c("div", { staticClass: "section" }, [
          _c("div", { staticClass: "header" }, [
            _c("h3", [
              _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}pointExchangeRate`))),
            ]),
            _c(
              "button",
              {
                staticClass: "table-btn main-style-btn",
                attrs: {
                  disabled: !_vm.getUserinfo.permissions.includes(60811),
                },
                on: { click: _vm.pointExchangeRate },
              },
              [_vm._v(" " + _vm._s(_vm.$t("default.edit")) + " ")]
            ),
          ]),
          _c("label", [
            _c("span", [_vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}point`)))]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.number",
                  value: _vm.dataModel.pointExchangeRate,
                  expression: "dataModel.pointExchangeRate",
                  modifiers: { number: true },
                },
              ],
              attrs: { type: "number" },
              domProps: { value: _vm.dataModel.pointExchangeRate },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(
                    _vm.dataModel,
                    "pointExchangeRate",
                    _vm._n($event.target.value)
                  )
                },
                blur: function ($event) {
                  return _vm.$forceUpdate()
                },
              },
            }),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "header mt-5" }, [
        _c("h5", [_vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}other`)))]),
      ]),
      _c("div", { staticClass: "wrap" }, [
        _c("div", { staticClass: "section" }, [
          _c("div", { staticClass: "header" }, [
            _c("h3", [
              _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}submitDepositMultiple`))),
            ]),
            _c(
              "button",
              {
                staticClass: "table-btn main-style-btn",
                attrs: {
                  disabled: !_vm.getUserinfo.permissions.includes(60807),
                },
                on: { click: _vm.submitDepositMultiple },
              },
              [_vm._v(" " + _vm._s(_vm.$t("default.edit")) + " ")]
            ),
          ]),
          _c("label", [
            _vm._v(
              " " + _vm._s(_vm.$t(`${_vm.i18nPrefix}depositMultiple`)) + " "
            ),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.number",
                  value: _vm.dataModel.depositMultiple,
                  expression: "dataModel.depositMultiple",
                  modifiers: { number: true },
                },
              ],
              attrs: { type: "number" },
              domProps: { value: _vm.dataModel.depositMultiple },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(
                    _vm.dataModel,
                    "depositMultiple",
                    _vm._n($event.target.value)
                  )
                },
                blur: function ($event) {
                  return _vm.$forceUpdate()
                },
              },
            }),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }