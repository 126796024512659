import request from "@/utils/request";
import { getToken } from "@/utils/auth";

export function getMemberRegistrationList(data) {
    return request({
        url: "/MemberRegistration/List",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function updateMemberRegistration(data) {
    return request({
        url: "/MemberRegistration/Update",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}