<template>
  <div class="dialog-mask" @mousedown.self="$emit('toggle')">
    <div class="dialog-container edit-member">
      <div class="dialog-block">
        <div class="header">
          <h4>{{ $t(`${i18nPrefix}pointWallet`) }}</h4>
        </div>
        <div class="divider"></div>
        <main>
          <div class="input-block">
            <label>
              {{ $t(`${i18nPrefix}account`) }}
              <input type="text" v-model="editModel.memberAccount" disabled />
            </label>
            <div>
              <label>
                {{  $t(`${i18nPrefix}dialog.balance-adjustment.bonusReceive`) }}
                <input type="text" v-model="point" disabled />
              </label>
              <label>
                {{  $t("default.point") }}
                <input type="number" v-model.number="editModel.point" />
              </label>
              <label>
                {{ $t("default.remark") }}
                <textarea cols="30" rows="6" v-model="editModel.remark"></textarea>
              </label>
              <label>
              {{ $t(`${i18nPrefix}dialog.point.adjustment`) }}
              <select v-model="editModel.type">
                <option :value="1 * 1">{{ $t(`${i18nPrefix}dialog.point.adjustmentType1`) }}</option>
                <option :value="2 * 1">{{ $t(`${i18nPrefix}dialog.point.adjustmentType2`) }}</option>
              </select>
            </label>
            </div>
          </div>
          <div class="advance-block">
            <button class="confirm-btn main-style-btn" @click="$emit('point', editModel);">{{ $t("default.confirm") }}</button>
            <button class="normal-btn-v2 main-style-btn" @click="$emit('toggle')">{{ $t("default.cancel")}}</button>
          </div>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PointWallet",
  props: ["id", "point", "account", "i18nPrefix"],
  data() {
    return {
      editModel: {
        memberId: this.id,
        type: 1,
        point: 0,
        memberAccount: this.account,
        remark: undefined
      }
    };
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
.dialog-container {
  width: auto !important;

  .input-block {
    div {
      width: 100%;
    }

    label {
      width: 100%;
    }
  }
}
</style>