<template>
  <div>
    <div class="container-view">
      <button class="search-btn main-style-btn" @click="search()">{{ $t("default.search") }}</button>
      <div class="header">
        <h5>{{ $t("title." + $route.meta.title) }}</h5>
        <div class="float-right">
          <button class="download-btn main-style-btn" @click="download">
            <img class="icon" src="@/assets/download.svg" />
            {{ $t('default.download') }}
          </button>
        </div>
      </div>
      <div class="main">
        <div class="input-block">
          <label class="input-block-multiselect">
            <div class="input-block-check">
              <span style="margin-right: 10px;">{{ $t("default.agent") }}</span>
              <input
                class="pc-block"
                id="isIncludeSubAgent"
                type="checkbox"
                v-model="searchForm.isIncludeSubAgent"
              />
              <label class="pc-block" for="isIncludeSubAgent">{{ $t("default.isIncludeSubAgent") }}</label>
            </div>
            <multiselect
              v-model="agentData"
              label="name"
              track-by="id"
              :options="getAgentListAddDefault"
              :allow-empty="false"
              :preselectFirst="true"
              :clear-on-select="false"
              :preserve-search="true"
              :custom-label="GLOBAL.nameWithLang"
            ></multiselect>
          </label>
          <div class="mobile-block">
            <div class="input-block-check">
              <input id="isIncludeSubAgent" type="checkbox" v-model="searchForm.isIncludeSubAgent" />
              <label for="isIncludeSubAgent">{{ $t("default.isIncludeSubAgent") }}</label>
            </div>
          </div>
          <label>
            {{ $t(`${i18nPrefix}memberStatus`) }}
            <select v-model="searchForm.memberStatus">
              <option :value="1 * 1">{{ $t("default.active" )}}</option>
              <option :value="2 * 1">{{ $t("default.inactive" )}}</option>
            </select>
          </label>
          <label>
            {{ $t("default.startTime") }}
            <date-picker
              v-model="startTime"
              format="YYYY-MM-DD"
              value-type="format"
              type="datetime"
              :disabled-date="GLOBAL.ontSeason"
            ></date-picker>
          </label>
          <label>
            {{ $t("default.endTime") }}
            <date-picker
              v-model="endTime"
              format="YYYY-MM-DD"
              value-type="format"
              type="datetime"
              :disabled-date="GLOBAL.ontSeason"
            ></date-picker>
          </label>
          <label>
            {{ $t("default.sort" )}}
            <select v-model="searchForm.sortType">
              <option
                v-for="list of sortTypeList"
                :key="list.id"
                :value="list.id * 1"
                v-if="getUserinfo.koreanStyleInterface ? list.id !== 3 : list.id !== 6"
              >{{ $t(list.name) }}</option>
            </select>
          </label>
        </div>
        <div class="advance-block">
          <QuickSearch ref="quickSearch" @changeTime="changeTime" :controlType="true"></QuickSearch>
          <button class="search-pc-btn main-style-btn px-5" @click="search()">{{ $t("default.search") }}</button>
        </div>
      </div>
    </div>
    <div class="container-view">
      <div class="table-block">
        <TreeTable ref="treeTable" :list="list" :column="tableColumn">
          <template v-slot:column_rank="props">{{ props.key + 1 }}</template>
          <template v-slot:column_account="props">
            <span class="memberSpan"
              @click="handleDialog({ list: { id: props.member.id, account: props.member.account, toggle: true } }, 'Detail')">{{
              props.member.account }}
            </span>
          </template>
          <template
            v-slot:column_agent="props"
          >{{ props.agentAccount + " / " + props.generalAgentAccount }}</template>
          <template v-slot:column_nickname="props">{{ props.name }}</template>
          <template v-slot:column_name="props">{{ props.name }}</template>
          <template
            v-slot:column_totalDepositAmount="props"
          >{{ digital.format(props.totalDepositAmount) }}</template>
          <template
            v-slot:column_totalWithdrawalAmount="props"
          >{{ digital.format(props.totalWithdrawalAmount) }}</template>
          <template
            v-slot:column_totalValidBetAmount="props"
          >{{ digital.format(props.totalValidBetAmount) }}</template>
          <template v-slot:column_totalWinLose="props">{{ digital.format(props.totalWinLose) }}</template>
        </TreeTable>
      </div>
    </div>
    <transition name="fade" mode="out-in">
      <component :is="currDialog" v-bind="dialogData" @toggle="currDialog = undefined"></component>
    </transition>
  </div>
</template>

<script>
import {
  getList,
  getMemberRankExcel
} from "@/api/statistical-report/memberRank";
import { searchModel, sortTypeList } from "./model";
import { transTodayDate, joinT } from "@/utils/transDateUtils";
import { mapGetters } from "vuex";
import QuickSearch from "@/layout/components/QuickSearch";
import TreeTable from "@/layout/components/TreeTable";
import Multiselect from "vue-multiselect";
import { download } from "@/utils/download";
import handleDialog from "@/layout/mixins/handleDialog";
import Detail from "@/views/member/member-management/dialog/detail";

export default {
  name: "MemberRank",
  data() {
    return {
      sortTypeList,
      list: [],
      agentData: [],
      searchForm: _.cloneDeep(searchModel),
      startTime: transTodayDate(),
      endTime: transTodayDate(),
      i18nPrefix: "statistical-report.memberRank.",
      digital: new Intl.NumberFormat()
    };
  },
  watch: {},
  components: {
    Detail,
    QuickSearch,
    TreeTable,
    Multiselect
  },
  computed: {
    ...mapGetters([
      "getAgentListAddDefault",
      "getGamePlatforms",
      "getGamesCategorys",
      "getUserinfo"
    ]),
    tableColumn() {
      const kr = [
        {
          key: "rank",
          name: this.$t('default.sort')
        },
        {
          key: "agent",
          name: this.$t("default.agent")
        },
        {
          key: "account",
          name: this.$t("default.member"),
          isText: true
        },
        {
          key: "member.nickName",
          name: this.$t("default.nickName"),
        },
        {
          key: "totalDepositAmount",
          name: this.$t("default.total_deposit"),
        },
        {
          key: "totalWithdrawalAmount",
          name: this.$t("default.total_withdrawl"),
        },
        {
          key: "totalWinLose",
          name: this.$t("default.total_profit"),
        },
        {
          key: "totalBetCount",
          name: this.$t("default.totalBetCount")
        },
        {
          key: "member.createTime",
          name: this.$t("default.createTime")
        }
      ];
      const other = [
        {
          key: "rank",
          name: ""
        },
        {
          key: "account",
          name: this.$t("default.member"),
          isText: true
        },
        {
          key: "agent",
          name: this.$t("default.agent"),
        },
        {
          key: "name",
          name: this.$t(`${this.i18nPrefix}name`)
        },
        {
          key: "totalDepositAmount",
          name: this.$t(`${this.i18nPrefix}totalDepositAmount`)
        },
        {
          key: "totalWithdrawalAmount",
          name: this.$t(`${this.i18nPrefix}totalWithdrawalAmount`)
        },
        {
          key: "totalValidBetAmount",
          name: this.$t(`${this.i18nPrefix}totalValidBetAmount`)
        },
        {
          key: "totalWinLose",
          name: this.$t(`${this.i18nPrefix}totalWinLose`)
        }
      ];
      return this.getUserinfo.koreanStyleInterface ? kr : other;
    }
  },
  mixins: [handleDialog],
  created() {
    this.$nextTick(function() {
      setTimeout(() => {
        this.$refs.quickSearch.changeDate("today");
      }, 200);
    });
  },
  methods: {
    async search() {
      //時間參數
      this.searchForm.startTime = this.startTime
        ? joinT(this.startTime + this.GLOBAL.startHour + this.GLOBAL.timeZone)
        : undefined;
      this.searchForm.endTime = this.endTime
        ? joinT(this.endTime + this.GLOBAL.endHour + this.GLOBAL.timeZone)
        : undefined;
      this.searchForm.agentId = this.agentData.id;

      getList(this.searchForm).then(res => {
        this.list = res?.data;
      });
    },
    changeTime(time) {
      this.startTime = time.startTime;
      this.endTime = time.endTime;
      this.search();
    },
    getGameName(data) {
      let name =
        (data.gamePlatform ? this.getGamePlatformName(data.gamePlatform) : "") +
        (data.gamesCategory && data.gamePlatform ? " + " : "") +
        (data.gamesCategory
          ? this.getGamesCategoryName(data.gamesCategory)
          : "");

      return name;
    },
    getGamePlatformName(id) {
      const index = this.getGamePlatforms.findIndex(e => e.id === id);
      return this.$t(this.getGamePlatforms[index].name);
    },
    getGamesCategoryName(id) {
      const index = this.getGamesCategorys.findIndex(e => e.id === id);
      return this.$t(this.getGamesCategorys[index].name);
    },
    download() {
      getMemberRankExcel(this.searchForm).then(res =>
        download(res, this.startTime + "MemberRankExcel.xlsx")
      );
    }
  }
};
</script>
