<template>
  <div>
    <div class="container-view">
      <button class="search-btn main-style-btn" @click="search()">
        {{ $t("default.search") }}
      </button>
      <div class="header">
        <h5>{{ $t("title." + $route.meta.title) }}</h5>
      </div>
      <div class="divider"></div>
      <div class="btn-list main">
        <button
          v-for="list of blacklistType"
          :key="list.id"
          @click="searchForm.type = list.id"
          :class="{ active: searchForm.type === list.id }"
        >
          {{ $t(`${i18nPrefix}${list.value}`) }}
        </button>
      </div>
      <div class="advance-block">
        <label class="search">
          {{ $t(i18nPrefix + blacklistType.find((data) => data.id === searchForm.type).value) }}
          <input type="text" v-model="searchForm.value" />
        </label>
        <button class="search-pc-btn main-style-btn px-5" @click="search()">{{ $t("default.search") }}</button>
      </div>
    </div>
    <div class="container-view">
      <main>
        <div class="table-block">
          <table class="mt-3">
            <thead>
              <tr>
                <th>{{ $t(`${i18nPrefix}memberWarning`) }}</th>
                <th>{{ $t(`${i18nPrefix}typeWarning`) }}</th>
                <th>{{ $t(`${i18nPrefix}contentWarning`) }}</th>
                <th>{{ $t(`${i18nPrefix}memberInfo`) }}</th>
                <th>{{ $t(`${i18nPrefix}timeWarning`) }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(list, index) of allData" :key="index">
                <td>{{ list.memberAccount }}</td>
                <td>{{ readType(list.types) }}</td>
                <td>{{ list.message }}</td>
                <td>{{ list.memberInfo }}</td>
                <td>{{ list.createTime }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import { getBlacklistWarning } from "@/api/blacklist/blacklistWarning";
import handlePageUtils from "@/layout/mixins/handlePageUtils";
import { searchModel } from "./model";

export default {
  name: "BlacklistWarnings",
  mixins: [handlePageUtils],
  data() {
    return {
      searchForm: _.cloneDeep(searchModel),
      blacklistType: [
        {
          id: 1,
          name: "銀行卡號",
          value: "bankNumber",
        },
        {
          id: 2,
          name: "銀行卡姓名",
          value: "bankMemberName",
        },
        {
          id: 3,
          name: "登入IP",
          value: "loginIP",
        },
        {
          id: 4,
          name: "註冊IP",
          value: "registerIP",
        },
        {
          id: 5,
          name: "手機號碼",
          value: "phoneNumber",
        },
        {
          id: 6,
          name: "lineId",
          value: "lineId",
        },
        {
          id: 8,
          name: "身分證字號",
          value: "idNumber"
        },
      ],
      i18nPrefix: "blackList.blacklistWarnings.",
    };
  },
  created() {
    if (this.$route.query?.orderId) {
      getBlacklistWarning({ id: this.$route.query?.orderId * 1 }).then(
        (res) => {
          this.allData = res.data.list;
          this.dataSort("createTime", "time", true);
        }
      );
    }
  },
  methods: {
    search() {
      // if(!checkData(this.searchForm, true)) return;
      getBlacklistWarning(this.searchForm).then((res) => {
        this.allData = res.data.list;
      });
    },
    readType(types) {
      return types
        .map(
          (type) => this.blacklistType.find((data) => data.id === type)?.name
        )
        .join(",");
    },
  },
};
</script>

<style lang="scss" scoped>
.search {
  margin-top: 40px;
}
.active {
  background-image: linear-gradient(180deg, #FFCC81 0%, #CD7B00 100%) !important;
  color: white;
  border: 1px solid #9F6000;
}
.btn-list {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-gap: 1em;

  button {
    margin: auto;
    width: 100%;
    height: 40px;
    border-radius: 8px;
    border: 1px solid #7A7A7A;
    background-color: #949494;
    color: white;
    font-size: 16px;

    &:hover {
      opacity: 0.8;
    }
  }
}
@media screen and (max-width: 1280px) {
  .btn-list {
    grid-template-columns: repeat(4, minmax(0, 1fr));
    font-size: 10px !important;
  }
}
</style>