import request from "@/utils/request";
import { getToken } from "@/utils/auth";

export function getselectMenu() {
    return request({
        url: "/BatchSend/select_menu",
        method: "get",
        headers: { "Authorization": `token ${getToken()}` },
    });
}

export function getMessageList(data) {
    return request({
        url: "/BatchSend/page",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function createMemberMessage(data) {
    return request({
        url: "/BatchSend/create",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function getmemberMessageList(data) {
    return request({
        url: "/BatchSend/message_page",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function recycleMessage(data) {
    return request({
        url: "/BatchSend/recycle_message",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function getBatchSendMemberCount(data) {
    return request({
        url: "/BatchSend/count",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function getWelcomeMessage(data) {
    return request({
        url: "/BatchSend/welcome_message",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function updateWelcomeMessage(data) {
    return request({
        url: "/BatchSend/welcome_message_upsert",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}