<template>
  <div class="sidebar" :class="{ hide: sidebar }">
    <ul class="sidebar-nav">
      <li class="parent-li" v-for="route of routes" :key="route.path">
        <template v-if="route.children.length == 0">
          <router-link :to="route.path">{{ route.meta.permissionName ? $t('Permission.' + route.meta.permissionName) : $t(`title.${route.meta.title}`) }} {{ route.meta.ver || ''  }}</router-link>
        </template>
        <template v-else>
          <a
            :href="`#${route.name}`"
            :id="route.path"
            data-toggle="collapse"
            @click="setActive"
            >{{ route.meta.permissionName ? $t('Permission.' + route.meta.permissionName) : $t("title." + route.meta.title) }}  {{ route.meta.ver || ''  }}</a
          >
          <i class="fa fa-angle-down"></i>
          <div class="collapse" :id="route.name" style="width: 100%; cursor: pointer;">
            <ul>
              <li v-for="childRoute of route.children" :key="childRoute.path">
                <router-link
                    :to="`${route.path}/${childRoute.path}`"
                    @click.native="hideSideBarWhenClick(route.path + '/' + childRoute.path)"
                >
                  {{ childRoute.meta.permissionName ? $t('Permission.' + childRoute.meta.permissionName) : $t("title." + childRoute.meta.title) }} {{ childRoute.meta.ver || ''  }}
                </router-link>
              </li>
            </ul>
          </div>
        </template>
      </li>
      <li class="mb-5">
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Sidebar",
  data() {
    return {
      windowWidth: 0,
      beforePath: this.$route.path,
    };
  },
  watch: {
    windowWidth(value) {
      if (value <= 1280) {
        if (this.sidebar === false) {
          this.$store.commit("app/TOGGLE_SIDEBAR", true);
        }
      }
    },
  },
  computed: {
    ...mapGetters(["sidebar", "getUserinfo", "navHeight"]),
    routes() {
      let routesForAdmin = this.$router.options.routes.filter(
        (route) => !route.meta.hidden
      );
      if (this.getUserinfo.permissions[0] === 99999) {
        return routesForAdmin;
      } else {
        let constRoutes = [];
        routesForAdmin.map((d) => {
          if (
            (d.meta.permission &&
            this.getUserinfo.permissions.includes(d.meta.permission))
          ) {
            constRoutes.push(d);
            constRoutes.map((constRoute) => {
              constRoute.children = constRoute.children.filter((cR) => {
                return this.getUserinfo.permissions.includes(
                  cR.meta.permission
                );
              });
            });
          }
        });
        return constRoutes;
      }
    },
    paddingTop() {
      return this.navHeight + 5 + 'px'
    }
  },
  mounted() {
    const colActive = document.getElementById(this.$route.matched[0].path);
    const colShow = document.getElementById(this.$route.matched[0].name);
    if (colActive) {
      if (!colActive.classList.contains("collapseActive"))
        colActive.classList.add("collapseActive");
      if (!colShow.classList.contains("show")) colShow.classList.add("show");
    }

    this.$nextTick(function () {
      window.addEventListener("resize", this.getWindowWidth);
      //Init
      this.getWindowWidth();
    });
  },
  methods: {
    getWindowWidth() {
      this.windowWidth = document.documentElement.clientWidth;
    },
    setActive(event) {
      let classList = event.target.classList;
      let collapseActive =
        !event.target.nextSibling.nextSibling.classList.contains("show");
      if (collapseActive) classList.add("collapseActive");
      else classList.remove("collapseActive");
    },
    hideSideBarWhenClick(path) {
      if(path === this.beforePath) {
        this.$router.push({
          path: '/userInfo/empty',
          query: { page: this.beforePath },
        });
      }
      this.beforePath = path;
      if(this.windowWidth < 1280) this.$store.commit("app/TOGGLE_SIDEBAR", true);
    },
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.getWindowWidth);
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/scrollbar.scss";

@media screen and (max-width: 1280px) {
  .sidebar {
    z-index: 2;
  }
}

@media screen and (max-width: 500px) {
  .sidebar {
    // padding-top: 128px !important;
  }
}
.sidebar {
  position: fixed;
  overflow: auto;
  padding-top: v-bind(paddingTop);
  min-width: 200px;
  max-width: 200px;
  height: 100%;
  background-color: #1a2226;
  // box-shadow: 0px 3px 10px 0 rgba(0, 0, 0, 0.4) inset;
  transition-duration: 0.3s;
  transform: translateX(0);
  z-index: 6;
  font-size: 14px;
  .sidebar-nav {
    .parent-li {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      transition-duration: 0.3s;
      border-bottom: 1px solid #c4c4c4;
      &:hover > a {
        //background-color: #d9d9d9;
      }
      .fa-angle-down {
        position: absolute;
        top: 10px;
        right: 15px;
        cursor: pointer;
        transition: 0.2s;
        color: #fff;
        z-index: -1;
      }
      a {
        display: block;
        width: 100%;
        padding: 6px 20px;
        color: #fff;
        font-weight: bold;
      }
      .collapse,
      .collapsing {
        ul {
          background-color: #333;
          li {
            margin: 1% 5%;
            a {
              padding-left: 2.5em !important;
              color: #8aa4af;
              border-radius: 0.35rem;
            }
          }
          li:hover {
            background-color: #3c9ed6;
            border-radius: 0.35rem;
            transition: 0.2s;
            a {
              color: #fff !important;
            }
          }
        }
      }
    }
  }
}
.hide {
  transform: translateX(-100%);
}
.collapseActive {
  // box-shadow: 0px 5px 5px 0 rgba(0, 0, 0) inset;
  //background-color: #333;
  & + .fa-angle-down {
    transform: rotate(180deg);
  }
}
.router-link-active {
  background-color: #3c9ed6;
  color: #fff !important;
}
@keyframes shake {
  from {
    transform: rotate(10deg);
  }
  to {
    transform: rotate(-10deg);
  }
}

.ag {
  transform: translateX(-100%) !important;
}
</style>