var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "d-flex form-column align-items-center h-full",
      staticStyle: { width: "90%" },
    },
    [
      _c("div", { staticClass: "table-block-white-v2" }, [
        _c("table", [
          _c("thead", [
            _c("tr", [
              _c(
                "th",
                { staticStyle: { width: "100%" }, attrs: { colspan: "2" } },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$t(`${_vm.i18nPrefix}dialog.detail.memberFeature`)
                    ) + " "
                  ),
                ]
              ),
            ]),
          ]),
          _c(
            "tbody",
            [
              _vm._l(_vm.list, function (item, index) {
                return _c("tr", [
                  _c("td", [
                    _vm._v(
                      " " + _vm._s(_vm.$t("MemberFeature." + item.name)) + " "
                    ),
                  ]),
                  _c("td", [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "justify-content": "center",
                          "align-items": "center",
                        },
                      },
                      [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.$t(`${_vm.i18nPrefix}dialog.detail.ban`))
                          ),
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "slide-block",
                            class: { "slide-block-on": item.isEnable },
                            staticStyle: { margin: "2px 10px" },
                            on: {
                              click: function ($event) {
                                item.isEnable =
                                  _vm.editChooice !== "disableFeature"
                                    ? item.isEnable
                                    : !item.isEnable
                              },
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "slide",
                                class: { "slide-on": item.isEnable },
                              },
                              [
                                _c("label", {
                                  staticStyle: { display: "none" },
                                }),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: item.isEnable,
                                      expression: "item.isEnable",
                                    },
                                  ],
                                  staticStyle: { display: "none" },
                                  attrs: { type: "checkbox" },
                                  domProps: {
                                    checked: Array.isArray(item.isEnable)
                                      ? _vm._i(item.isEnable, null) > -1
                                      : item.isEnable,
                                  },
                                  on: {
                                    change: function ($event) {
                                      var $$a = item.isEnable,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              item,
                                              "isEnable",
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              item,
                                              "isEnable",
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(item, "isEnable", $$c)
                                      }
                                    },
                                  },
                                }),
                              ]
                            ),
                          ]
                        ),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(`${_vm.i18nPrefix}dialog.detail.allow`)
                            )
                          ),
                        ]),
                      ]
                    ),
                  ]),
                ])
              }),
              _c("tr", [
                _c(
                  "td",
                  { staticStyle: { width: "100%" }, attrs: { colspan: "2" } },
                  [
                    _c(
                      "button",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.editChooice !== "disableFeature",
                            expression: "editChooice !== 'disableFeature'",
                          },
                        ],
                        staticClass: "edit-btn",
                        attrs: { disabled: _vm.editChooice !== undefined },
                        on: {
                          click: function ($event) {
                            return _vm.edit("disableFeature")
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("default.edit")) + " ")]
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.editChooice == "disableFeature",
                            expression: "editChooice == 'disableFeature'",
                          },
                        ],
                      },
                      [
                        _c(
                          "button",
                          {
                            staticClass: "normal-btn",
                            on: {
                              click: function ($event) {
                                return _vm.confirm("disableFeature")
                              },
                            },
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.$t("default.confirm")) + " "
                            ),
                          ]
                        ),
                        _c(
                          "button",
                          {
                            staticClass: "delete-btn",
                            on: {
                              click: function ($event) {
                                return _vm.cancel("disableFeature")
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("default.cancel")) + " ")]
                        ),
                      ]
                    ),
                  ]
                ),
              ]),
            ],
            2
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }