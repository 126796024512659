var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "container-view" }, [
        _c("div", { staticClass: "header" }, [
          _c("h5", [_vm._v(_vm._s(_vm.$t("title." + _vm.$route.meta.title)))]),
          _c("div", { staticClass: "float-right" }, [
            _c(
              "button",
              {
                staticClass: "create-btn main-style-btn",
                attrs: {
                  disabled: !_vm.getUserinfo.permissions.includes(62601),
                },
                on: {
                  click: function ($event) {
                    return _vm.handleDialog(undefined, "Create")
                  },
                },
              },
              [_vm._v(" " + _vm._s(_vm.$t(`${_vm.i18nPrefix}sendMail`)) + " ")]
            ),
          ]),
        ]),
        _c("div", { staticClass: "main" }),
      ]),
      _c(
        "div",
        { staticClass: "container-view" },
        [
          _c("div", { staticClass: "table-block" }, [
            _c("table", [
              _c("thead", [
                _c("tr", [
                  _c("th", [_vm._v(_vm._s(_vm.$t("default.detail")))]),
                  _c("th", [_vm._v(_vm._s(_vm.$t("default.createTime")))]),
                  _c("th", [_vm._v(_vm._s(_vm.$t("default.admin")))]),
                ]),
              ]),
              _c(
                "tbody",
                [
                  !_vm.allData || !_vm.allData.length
                    ? _c("tr", [
                        _c("td", { attrs: { colspan: "3" } }, [
                          _vm._v(_vm._s(_vm.$t("default.noData"))),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._l(_vm.allData, function (list) {
                    return _c("tr", { key: list.id }, [
                      _c("td", [
                        _c(
                          "button",
                          {
                            staticClass: "table-btn main-style-btn",
                            on: {
                              click: function ($event) {
                                return _vm.handleDialog(list, "Count")
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("default.detail")))]
                        ),
                      ]),
                      _c("td", [_vm._v(_vm._s(list.createTime))]),
                      _c("td", [_vm._v(_vm._s(list.userAccount))]),
                    ])
                  }),
                ],
                2
              ),
            ]),
          ]),
          _vm.allData
            ? _c("serverPagination", {
                attrs: { page: _vm.page },
                on: { ServerPageUtils: _vm.search, changeTake: _vm.changeTake },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "transition",
        { attrs: { name: "fade", mode: "out-in" } },
        [
          _c(
            _vm.currDialog,
            _vm._b(
              {
                tag: "component",
                attrs: {
                  messageTypeList: _vm.messageType,
                  agentList: _vm.getAgentList,
                  attachmentType: _vm.attachmentType,
                  i18nPrefix: _vm.i18nPrefix,
                },
                on: {
                  toggle: function ($event) {
                    _vm.currDialog = undefined
                  },
                  create: _vm.create,
                },
              },
              "component",
              _vm.dialogData,
              false
            )
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }