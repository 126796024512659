<template>
  <div>
    <div class="container-view">
      <button class="search-btn main-style-btn" @click="search()">{{ $t("default.search") }}</button>
      <div class="header">
        <h5>{{ $t("Permission." + $route.meta.permissionName) }}</h5>
      </div>
      <div class="main">
        <div class="input-block mb-5">
          <label>
                {{ $t("default.gamePlatform") }}
            <select v-model="searchForm.gamePlatform">
              <option :value="0 * 1">{{ $t("default.all") }}</option>
              <option v-for="list of getGamePlatforms" :key="list.id" :value="list.id">
                {{ $t(list.name) }}
              </option>
            </select>
          </label>
          <label>
            {{ $t("default.startTime") }}
            <date-picker v-model="startTime" format="YYYY-MM-DD HH:mm" value-type="format"
              type="datetime"></date-picker>
          </label>
          <label>
            {{ $t("default.endTime") }}
            <date-picker v-model="endTime" format="YYYY-MM-DD HH:mm" value-type="format" type="datetime"></date-picker>
          </label>
        </div>
        <div class="advance-block">
          <QuickSearch @changeTime="changeTime"></QuickSearch>
          <button class="search-pc-btn main-style-btn px-5" @click="search()">{{ $t("default.search") }}</button>
        </div>
      </div>
    </div>
    <div class="container-view">
      <div class="table-block">
        <table>
          <thead>
            <tr>
              <th>{{ $t("default.gamePlatform") }}</th>
              <th>{{ $t("default.casinoChips") }}</th>
              <th>{{ $t("default.processAccount") }}</th>
              <th>{{ $t("default.createTime") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="!allData || !allData.length">
              <td colspan="4">{{ $t("default.noData") }}</td>
            </tr>
            <tr v-for="(list) of allData" :key="list.id">
              <td>
                {{
                  GLOBAL.i18nCheckName(getGamePlatforms.find(
                    (data) => list.gamePlatform === data.id
                  ))
                }}
              </td>
              <td>{{ list.amount }}</td>
              <td>{{ list.updateAccount }}</td>
              <td>{{ list.createTime }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <serverPagination v-if="allData" :page="page" @ServerPageUtils="search" @changeTake="changeTake" />
    </div>
  </div>
</template>

<script>
import { searchModel } from "./model";
import {
  getCasinoChipsRecord,
} from "@/api/operationManagement/casinoChipsRecord";
import { transToday, joinT } from "@/utils/transDateUtils";
import { mapGetters } from "vuex";
import serverPagination from "@/layout/components/serverPagination";
import handleServerPageUtils from "@/layout/mixins/handleServerPageUtils";
import QuickSearch from "@/layout/components/QuickSearch";

export default {
  name: "CasinoChipsRecord",
  components: {
    QuickSearch,
    serverPagination,
  },
  data() {
    return {
      searchForm: _.cloneDeep(searchModel),
      startTime: transToday("from"),
      endTime: transToday("to"),
    };
  },
  mixins: [handleServerPageUtils],
  computed: {
    ...mapGetters(["getGamePlatforms"]),
  },
  created() {
  },
  methods: {
    search(page = false) {
      this.searchForm.startTime = this.startTime ? joinT(this.startTime + this.GLOBAL.startSecond + this.GLOBAL.timeZone) : undefined;
      this.searchForm.endTime = this.endTime ? joinT(this.endTime + this.GLOBAL.endSecond + this.GLOBAL.timeZone) : undefined;

      this.searchForm.page.take = this.take * 1;
      this.searchForm.page.skip = this.take * (page ? page - 1 : 0);
      getCasinoChipsRecord(this.searchForm).then((res) => {
        this.allData = res.data.list;
        this.page = res.data.page;
      });
    },
    changeTime(time) {
      this.startTime = time.startTime;
      this.endTime = time.endTime;
      this.search();
    }
  },
};
</script>

<style lang="scss" scoped>
.small-td {
  width: 5%;
}

.middle-td {
  width: 10%;
}

.btn-td {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.url-td {
  // width: 20%;
  white-space: unset !important;

  span {
    margin: auto 5px;
  }
}
</style>