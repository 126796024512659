<template>
  <div>
    <div class="container-view">
      <button class="search-btn main-style-btn" @click="search()">{{ $t("default.search") }}</button>
      <div class="header">
        <h5>{{ $t("title." + $route.meta.title) }}</h5>
        <div class="float-right">
          <button class="create-btn main-style-btn" @click="handleDialog(undefined, 'Create')"
            :disabled="!getUserinfo.permissions.includes(140101)">
            {{ $t("default.create") }}
          </button>
        </div>
      </div>
      <div class="main">
        <div class="input-block mb-5">
          <label>
            {{ $t(`${i18nPrefix}gameCategory`) }}
            <select v-model="searchForm.gamesCategory">
              <option :value="0 * 1">{{ $t("default.all") }}</option>
              <option v-for="list of gamesCategorys" :key="list.id" :value="list.id">
                {{ $t(list.name) }}
              </option>
            </select>
          </label>
          <label>
            {{ $t(`${i18nPrefix}gameName`) }}
            <select v-model="searchForm.gamePlatformId">
              <option :value="0 * 1">{{ $t("default.all") }}</option>
              <option v-for="list of gamePlatforms" :key="list.id" :value="list.id">
                {{ $t(list.name) }}
              </option>
            </select>
          </label>
          <label>
            {{ $t(`${i18nPrefix}isHot`) }}
            <select v-model="searchForm.isHot">
              <option :value="undefined">{{ $t("default.all") }}</option>
              <option :value="true">{{ $t("default.active") }}</option>
              <option :value="false">{{ $t("default.inactive") }}</option>
            </select>
          </label>
          <label>
            {{ $t(`${i18nPrefix}isNew`) }}
            <select v-model="searchForm.isNew">
              <option :value="undefined">{{ $t("default.all") }}</option>
              <option :value="true">{{ $t("default.active") }}</option>
              <option :value="false">{{ $t("default.inactive") }}</option>
            </select>
          </label>
        </div>
        <div class="advance-block">
          <button class="search-pc-btn main-style-btn px-5" @click="search()">{{ $t("default.search") }}</button>
        </div>
      </div>
    </div>
    <div class="container-view">
      <div class="table-block">
        <table>
          <thead>
            <tr>
              <th>
                <div class="btn-td">
                  <input type="checkbox" v-if="tableList" v-model="checkAllBtn" @click="checkAll()">
                  <button class="delete-btn" v-if="tableList" @click="handleDelete(ids)"
                    :disabled="!getUserinfo.permissions.includes(140103)">{{ $t("default.delete") }}</button>
                </div>
              </th>
              <th>{{ $t(`${i18nPrefix}gamePlatform`) }}</th>
              <th>{{ $t("default.multiples") }}</th>
              <th>{{ $t(`${i18nPrefix}gameCategory`) }}</th>
              <th>{{ $t(`${i18nPrefix}sort`) }}</th>
              <th>{{ $t(`${i18nPrefix}depositLimit`) }}</th>
              <th v-show="getUserinfo.pointWallet">{{ $t(`${i18nPrefix}isPointBlock`) }}</th>
              <th>{{ $t(`${i18nPrefix}isHot`) }}</th>
              <th>{{ $t(`${i18nPrefix}isNew`) }}</th>
              <th>{{ $t(`${i18nPrefix}status`) }}</th>
              <th>{{ $t(`${i18nPrefix}preview`) }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="!tableList || !tableList.length">
              <td colspan="15">{{ $t("default.noData") }}</td>
            </tr>
            <tr v-for="(list) of tableList" :key="list.id">
              <td class="small-td">
                <div class="btn-td">
                  <input class="mr-1" type="checkbox" v-model="ids" :value="list.id">
                  <button class="table-btn main-style-btn" @click="handleDialog({ list: list }, 'Edit')"
                    :disabled="!getUserinfo.permissions.includes(140102)">
                    {{ $t("default.edit") }}
                  </button>
                </div>
              </td>
              <td class="small-td">
                {{
                  GLOBAL.i18nCheckName(gamePlatforms.find(
                    (data) => list.gamePlatform === data.id
                  ))
                }}
              </td>
              <td class="small-td">{{ list.multiples }}</td>
              <td class="small-td">
                {{
                  GLOBAL.i18nCheckName(gamesCategorys.find(
                    (data) => list.gamesCategory === data.id
                  ))
                }}
              </td>
              <td class="small-td">{{ list.sortId }}</td>
              <td class="small-td">{{ list.depositLimit }}</td>
              <td class="small-td" v-show="getUserinfo.pointWallet">{{ list.isPointBlock ? $t("default.active") : $t("default.inactive") }}</td>
              <td class="small-td">
                <label>
                  <div class="slide-block" style="margin: auto" :class="{ 'slide-block-on': list.isHot }">
                    <div class="slide" @click="toggleSlide" :class="{ 'slide-on': list.isHot }">
                      <label for="isHot" style="display: none"></label>
                      <input type="checkbox" id="isHot" v-model="list.isHot" @click="updataOnce(list, 'isHot')"
                        style="display: none" />
                    </div>
                  </div>
                </label>
              </td>
              <td class="small-td">
                <label>
                  <div class="slide-block" style="margin: auto" :class="{ 'slide-block-on': list.isNew }">
                    <div class="slide" @click="toggleSlide" :class="{ 'slide-on': list.isNew }">
                      <label for="isNew" style="display: none"></label>
                      <input type="checkbox" id="isNew" v-model="list.isNew" @click="updataOnce(list, 'isNew')"
                        style="display: none" />
                    </div>
                  </div>
                </label>
              </td>
              <td class="small-td"><label>
                  <div class="slide-block" style="margin: auto"
                    :class="{ 'slide-block-on': list.status == '1' ? true : false }">
                    <div class="slide" @click="toggleSlide" :class="{ 'slide-on': list.status == '1' ? true : false }">
                      <label for="status" style="display: none"></label>
                      <input type="checkbox" id="status" v-model="list.status" @click="updataOnce(list)"
                        style="display: none" />
                    </div>
                  </div>
                </label></td>
              <td class="url-td">
                <span v-show="list.faviconUpUrl"
                  @click="handleDialog({ image: list.faviconUpUrl + '?v=' + new Date().getTime() }, 'Photo')"
                  :title="list.faviconUpUrl">faviconUp</span>
                <span v-show="list.faviconDownUrl"
                  @click="handleDialog({ image: list.faviconDownUrl + '?v=' + new Date().getTime() }, 'Photo')"
                  :title="list.faviconDownUrl">faviconDown</span>
                <span v-show="list.imageUrl"
                  @click="handleDialog({ image: list.imageUrl + '?v=' + new Date().getTime() }, 'Photo')"
                  :title="list.imageUrl">image</span>
                <span v-show="list.mobileImageUrl"
                  @click="handleDialog({ image: list.mobileImageUrl + '?v=' + new Date().getTime() }, 'Photo')"
                  :title="list.mobileImageUrl">mobileImage</span>
                <span v-show="list.mainPageImageUrl"
                  @click="handleDialog({ image: list.mainPageImageUrl + '?v=' + new Date().getTime() }, 'Photo')"
                  :title="list.mainPageImageUrl">mainPage</span>
                <span v-show="list.mobileMainPageImageUrl"
                  @click="handleDialog({ image: list.mobileMainPageImageUrl + '?v=' + new Date().getTime() }, 'Photo')"
                  :title="list.mobileMainPageImageUrl">mobileMainPage</span>
                <span v-show="list.backgroundImgUrl"
                  @click="handleDialog({ image: list.backgroundImgUrl + '?v=' + new Date().getTime() }, 'Photo')"
                  :title="list.backgroundImgUrl">background</span>
                <span v-show="list.backgroundImgMobileUrl"
                  @click="handleDialog({ image: list.backgroundImgMobileUrl + '?v=' + new Date().getTime() }, 'Photo')"
                  :title="list.backgroundImgMobileUrl">backgroundImgMobile</span>
                <span v-show="list.appImageUrl"
                  @click="handleDialog({ image: list.appImageUrl + '?v=' + new Date().getTime() }, 'Photo')"
                  :title="list.appImageUrl">appImageUrl</span>
                <span v-show="list.appFaviconUpUrl"
                  @click="handleDialog({ image: list.appFaviconUpUrl + '?v=' + new Date().getTime() }, 'Photo')"
                  :title="list.appFaviconUpUrl">appFaviconUpUrl</span>
                <span v-show="list.homePageImgUrl"
                  @click="handleDialog({ image: list.homePageImgUrl + '?v=' + new Date().getTime() }, 'Photo')"
                  :title="list.homePageImgUrl">homePageImgUrl</span>
                <span v-show="list.homePageImgMobileUrl"
                  @click="handleDialog({ image: list.homePageImgMobileUrl + '?v=' + new Date().getTime() }, 'Photo')"
                  :title="list.homePageImgMobileUrl">homePageImgMobileUrl</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <Pagination v-if="totalCount > 0" :totalCount="totalCount" @handlePage="handlePage" />
    </div>

    <transition name="fade" mode="out-in">
      <component :is="currDialog" v-bind="dialogData" :gamePlatforms=gamePlatforms :gamesCategorys=gamesCategorys
        :platformVersions=platformVersions :pointWallet = getUserinfo.pointWallet
        @toggle="currDialog = undefined" @edit="edit" @create="create"></component>
    </transition>

    <transition name="fade" mode="out-in">
      <Alert v-if="alertMsg" :message="alertMsg" @toggle="alertMsg = undefined" @doIt="deleteGamesCategoryInfo" />
    </transition>
  </div>
</template>

<script>
import { searchModel } from "./model";
import {
  SelectMenu,
  getGamesCategoryInfoList,
  createGamesCategoryInfo,
  deleteGamesCategoryInfo,
  updateGamesCategoryInfo,
} from "@/api/operationManagement/GamesCategoryInfo";
import { mapGetters } from "vuex";
import Edit from "./dialog/edit";
import Create from "./dialog/create";
import Photo from "@/layout/components/photo";
import Alert from "@/layout/components/Alert";
import Pagination from "@/layout/components/Pagination";
import handlePageUtils from "@/layout/mixins/handlePageUtils";
import handleDialog from "@/layout/mixins/handleDialog";
import { toggleStyle } from "@/utils/slideUtils";
export default {
  name: "GamesCategoryInfo",
  components: {
    Edit,
    Photo,
    Alert,
    Create,
    Pagination,
  },
  data() {
    return {
      searchForm: _.cloneDeep(searchModel),
      checkAllBtn: false,
      alertMsg: undefined,
      ids: [],
      gamePlatforms: [],
      gamesCategorys: [],
      platformVersions: [],
      i18nPrefix: "operationManagement.gamesCategoryInfo."
    };
  },
  mixins: [handlePageUtils, handleDialog],
  computed: {
    ...mapGetters(["getAuditStatusList", "getUserinfo", "getGamePlatforms", "getGamesCategorys"]),
  },
  created() {
    this.gamePlatforms = this.getGamePlatforms
    this.gamesCategorys = this.getGamesCategorys
    SelectMenu({}).then((res) => {
      this.platformVersions = res.data.platformVersions;
    })
  },
  watch: {
    ids() {
      this.checkAllBtn = (this.ids.length === this.tableList.length) ? true : false;
    },
  },
  methods: {
    toggleSlide: toggleStyle,
    search() {
      getGamesCategoryInfoList(this.searchForm).then((res) => {
        this.allData = res.data;
        this.currDialog = undefined;
      });
    },
    create(data) {
      createGamesCategoryInfo(data).then((res) => {
        if (res) this.search();
      });
    },
    edit(data) {
      updateGamesCategoryInfo(data).then((res) => {
        if (res) this.search();
      });
    },
    updataOnce(data, k) {
      if (k === 'isHot') {
        data.isHot = !data.isHot;
      } else if (k === 'isNew') {
        data.isNew = !data.isNew;
      } else {
        data.status = data.status == '1' ? '2' : '1';
      };
      data.PlatformVersion = this.platformVersions.find(
        (Versions) => Versions.name === data.version
      )?.id
      let formData = new FormData();
      Object.entries(data).forEach(([key, value]) => {
        formData.append(key, value);
      });
      updateGamesCategoryInfo(formData).then((res) => {
        if (res) this.search();
      });
    },
    checkAll() {
      this.ids = this.checkAllBtn ? [] : this.tableList.map(data => data.id);
    },
    handleDelete() {
      this.alertMsg = "確定刪除 ?";
    },
    deleteGamesCategoryInfo() {
      this.alertMsg = "";
      deleteGamesCategoryInfo({ ids: this.ids }).then(res => {
        if (res) this.search();
      })
      this.ids = [];
    },
  },
};
</script>

<style lang="scss" scoped>
.small-td {
  width: 5%;
}

.middle-td {
  width: 10%;
}

.btn-td {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.url-td {
  // width: 20%;
  white-space: unset !important;

  span {
    margin: auto 5px;
  }
}
</style>