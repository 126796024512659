var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "container-view" }, [
        _c("div", { staticClass: "header" }, [
          _c("h4", [
            _vm._v(
              _vm._s(_vm.$t("Permission." + _vm.$route.meta.permissionName))
            ),
          ]),
          _c(
            "button",
            {
              staticClass: "create-btn main-style-btn",
              attrs: {
                disabled:
                  !_vm.$store.getters.getUserinfo.permissions.includes(200202),
              },
              on: {
                click: function ($event) {
                  return _vm.handleDialog("", "create")
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("default.create")))]
          ),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "container-view" },
        [
          _c("div", { staticClass: "table-block" }, [
            _c("table", [
              _c("thead", [
                _c("tr", [
                  _c("th"),
                  _c("th", [_vm._v(_vm._s(_vm.$t("default.title")))]),
                  _c("th", [_vm._v(_vm._s(_vm.$t("default.createTime")))]),
                  _c("th", [_vm._v(_vm._s(_vm.$t("default.creator")))]),
                  _c("th", [_vm._v(_vm._s(_vm.$t("default.processTime")))]),
                  _c("th", [_vm._v(_vm._s(_vm.$t("default.processAccount")))]),
                ]),
              ]),
              _c(
                "tbody",
                [
                  !_vm.allData || _vm.allData.length === 0
                    ? _c("tr", [
                        _c("td", { attrs: { colspan: "999" } }, [
                          _vm._v(_vm._s(_vm.$t("default.noData"))),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._l(_vm.allData, function (list) {
                    return _c("tr", [
                      _c("td", [
                        _c(
                          "button",
                          {
                            staticClass: "table-btn main-style-btn",
                            attrs: {
                              disabled:
                                !_vm.$store.getters.getUserinfo.permissions.includes(
                                  200203
                                ),
                            },
                            on: {
                              click: function ($event) {
                                return _vm.handleDialog(list, "edit")
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("default.edit")))]
                        ),
                        _c(
                          "button",
                          {
                            staticClass: "delete-btn",
                            attrs: {
                              disabled:
                                !_vm.$store.getters.getUserinfo.permissions.includes(
                                  200204
                                ),
                            },
                            on: {
                              click: function ($event) {
                                return _vm.handleDeleteTemplate(list.id)
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("default.delete")) + " ")]
                        ),
                      ]),
                      _c("td", [
                        _c(
                          "div",
                          {
                            staticClass: "tdColor",
                            style: {
                              backgroundColor: list.backgroundColor,
                              color: list.titleColor,
                            },
                          },
                          [_vm._v(" " + _vm._s(list.title) + " ")]
                        ),
                      ]),
                      _c("td", [_vm._v(_vm._s(list.createdDateTime))]),
                      _c("td", [_vm._v(_vm._s(list.creatorName))]),
                      _c("td", [_vm._v(_vm._s(list.updatedDateTime))]),
                      _c("td", [_vm._v(_vm._s(list.updaterName))]),
                    ])
                  }),
                ],
                2
              ),
            ]),
          ]),
          _vm.allData
            ? _c("serverPagination", {
                attrs: { page: _vm.page },
                on: { ServerPageUtils: _vm.search, changeTake: _vm.changeTake },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm.alertMsg
        ? _c("Alert", {
            attrs: { message: _vm.alertMsg },
            on: {
              toggle: function ($event) {
                _vm.alertMsg = undefined
              },
              doIt: _vm.deleteFunction,
            },
          })
        : _vm._e(),
      _c(
        "transition",
        { attrs: { name: "fade", mode: "out-in" } },
        [
          _c(
            _vm.currDialog,
            _vm._b(
              {
                tag: "component",
                attrs: { i18nPrefix: _vm.i18nPrefix },
                on: {
                  toggle: function ($event) {
                    _vm.currDialog = undefined
                  },
                  edit: _vm.editTemplate,
                  create: _vm.createTemplate,
                },
              },
              "component",
              _vm.dialogData,
              false
            )
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }