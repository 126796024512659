<template>
  <div class="dialog-mask" @mousedown.self="$emit('toggle')">
    <div class="dialog-container">
      <div class="dialog-block">
        <div class="header">
          <h4>{{ $t("default.member") }}</h4>
        </div>
        <div class="divider"></div>
        <main>
          <div class="table-block">
            <table>
              <thead>
                <tr>
                  <th>{{ $t("default.member") }}</th>
                  <th>{{ $t("member.member-management.name") }}</th>
                  <th>{{ $t("default.total_deposit") }}</th>
                  <th>{{ $t("default.total_withdrawl") }}</th>
                  <th>{{ $t("default.total_profit") }}</th>
                  <th>{{ $t(`${i18nPrefix}dialog.gamePlatform`) }}</th>
                  <th>{{ $t("default.status") }}</th>
                  <th>{{ $t(`${i18nPrefix}dialog.referralCode`) }}</th>
                  <th>{{ $t(`${i18nPrefix}dialog.createTime`) }}</th>
                  <th>{{ $t("member.member-management.lastEventTime") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="!allData || allData.length === 0">
                  <td colspan="6">{{ $t("default.noData") }}</td>
                </tr>
                <tr v-for="(list, index) of tableList" :key="`${list.createTime}_${list.balance}_${index}`">
                    <td>{{ list.account }}</td>
                    <td>{{ list.name }}</td>
                    <td>{{ list.totalDepositAmount }}</td>
                    <td>{{ list.totalWithDrawalAmount }}</td>
                    <td>{{ list.totalAmount }}</td>
                    <td>{{ list.balance }}</td>
                    <td>{{ list.status === 1 ? $t("default.active") : $t("default.inactive") }}</td>
                    <td>{{ list.referralCode }}</td>
                    <td>{{ list.createTime }}</td>
                    <td>{{ list.eventTime }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <Pagination
            v-if="totalCount > 0"
            :totalCount="totalCount"
            @handlePage="handlePage"
          />
        </main>
      </div>
    </div>
  </div>
</template>
<script>
import Pagination from "@/layout/components/Pagination";
import handlePageUtils from "@/layout/mixins/handlePageUtils";
import { agentMemberList } from "@/api/agent-management/agent";

export default {
  name: "Member",
  props: ["id","i18nPrefix"],
  data() {
    return {};
  },
  mixins: [handlePageUtils],
  components: {
    Pagination,
  },
  created() {
      agentMemberList({ agentId: this.id }).then((res) => {
          this.allData = res.data;
      });
  },
};
</script>
<style lang="scss" scoped>
.dialog-container {
    width: 50%;
}
</style>