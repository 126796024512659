<template>
  <div>
    <div class="agent-block">
      <div class="container-view">
        <div class="header">
          <h5>{{ $t("title." + $route.meta.title) }}</h5>
          <div class="float-right">
            <button class="confirm-btn main-style-btn" @click="handleDialog({ agentId: agentId }, 'Create')"
              :disabled="!getUserinfo.permissions.includes(20101)">
              {{ $t("default.create") + " " + $t("default.agent") }}
            </button>
          </div>
        </div>
        <div class="main advance-block">
          <div class="input-block">
            <div class="input-block-multiselect" v-if="level0.length > 0">
              {{ $t("default.mainAgent") }}
              <multiselect v-model="agentData0" label="account" track-by="id" :options="level0" :allow-empty="false"
                :preselectFirst="true" :clear-on-select="false" :preserve-search="true"
                :custom-label="GLOBAL.nameWithLang"></multiselect>
            </div>
            <div class="input-block-multiselect" v-if="level1.length > 0">
              {{ $t("default.levelNumber", { input: 1 }) }}
              <multiselect v-model="agentData1" label="account" track-by="id" :options="level1" :allow-empty="false"
                :preselectFirst="true" :clear-on-select="false" :preserve-search="true"
                :custom-label="GLOBAL.nameWithLang"></multiselect>
            </div>
            <div class="input-block-multiselect" v-if="level2.length > 0">
              {{ $t("default.levelNumber", { input: 2 }) }}
              <multiselect v-model="agentData2" label="account" track-by="id" :options="level2" :allow-empty="false"
                :preselectFirst="true" :clear-on-select="false" :preserve-search="true"
                :custom-label="GLOBAL.nameWithLang"></multiselect>
            </div>
            <div class="input-block-multiselect" v-if="level3.length > 0">
              {{ $t("default.levelNumber", { input: 3 }) }}
              <multiselect v-model="agentData3" label="account" track-by="id" :options="level3" :allow-empty="false"
                :preselectFirst="true" :clear-on-select="false" :preserve-search="true"
                :custom-label="GLOBAL.nameWithLang"></multiselect>
            </div>
          </div>
        </div>
      </div>
      <div class="container-view">
        <main>
          <div class="table-block">
            <table class="KoreanStyle">
              <thead>
                <tr>
                  <th v-for="list of tableHead" :key="list.name">{{ list.name }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="!tableList || tableList.length === 0">
                  <td colspan="999">{{ $t("default.noData") }}</td>
                </tr>
                <tr v-for="(list, index) of tableList" :key="list.id">
                  <td>
                    <button class="table-btn main-style-btn" @click="handleDialog(list, 'Edit')"
                      :disabled="!getUserinfo.permissions.includes(20102)">
                      {{ $t("default.edit") }}
                    </button>
                  </td>
                  <td>{{ list.id }}</td>
                  <td>
                    {{
                      list.parentLevelNumber !== -1
                      ? $t(
                        "default." +
                        (list.parentLevelNumber
                          ? `levelNumber`
                          : "mainAgent"),
                        {
                          input: list.parentLevelNumber,
                        }
                      ) +
                      " / " +
                      list.parentName
                      : $t("default.noData")
                    }}
                  </td>
                  <td>
                    {{
                      $t(
                        "default." +
                        (list.levelNumber ? `levelNumber` : "mainAgent"),
                        { input: list.levelNumber }
                      )
                    }}
                  </td>
                  <td>{{ list.account }}</td>
                  <td>{{ list.name }}</td>
                  <td>{{ list.nickName }}</td>
                  <td>
                    <span v-if="list.bankInfo.length > 0">
                      {{ list.bankInfo[0]?.bankName }}
                    </span>
                    <button v-else class="table-btn main-style-btn"
                      @click="handleDialog({ memberId: list.id }, 'CreateBank')">
                      {{ $t("member.member-management.createBank") }}
                    </button>
                  </td>
                  <td>
                    {{ list.bankInfo[0]?.bankAccountNumber }}
                  </td>
                  <td>
                    <button class="normal-btn" @click="handleDialog({ id: list.id }, 'Member')">{{
                      $t("default.member") }}</button>
                  </td>
                  <td>{{ list.totalRebateAmount }}</td>
                  <td>{{ list.createTime }}</td>
                  <td>
                    <button @click="getProcess(list.id)" class="table-btn main-style-btn">
                      {{ $t("default.processAccount") }}
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <Pagination v-if="totalCount > 0" :totalCount="totalCount" @handlePage="handlePage" />
        </main>
      </div>
    </div>
    <transition name="fade" mode="out-in">
      <component :is="currDialog" :agentId="agentId" :agentDamain="agentDamain" :permissionsData="permissionsData"
        :i18nPrefix="i18nPrefix" v-bind="dialogData" @toggle="currDialog = undefined" @reload="getAgentList" @create="create"
        @edit="edit"></component>
    </transition>
    <Process v-if="processData" :processTitle="processTitle" :processData="processData" @toggle="processData = undefined">
      <template v-slot:oldValue_Status="props">{{
        props.Status === 1 ? $t("default.yes") : $t("default.no")
      }}</template>
      <template v-slot:newValue_Status="props">{{
        props.Status === 1 ? $t("default.yes") : $t("default.no")
      }}</template>
      <template v-slot:oldValue_CommissionCalculationCycle="props">
        {{
          GLOBAL.i18nCheckName(
            GLOBAL.settlement.find(
              (data) => props.CommissionCalculationCycle === data.id
            )
          )
        }}
      </template>
      <template v-slot:newValue_CommissionCalculationCycle="props">
        {{
          GLOBAL.i18nCheckName(
            GLOBAL.settlement.find(
              (data) => props.CommissionCalculationCycle === data.id
            )
          )
        }}
      </template>
    </Process>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Create from "./dialog/create";
import Edit from "./dialog/edit";
import Member from "./dialog/member";
import CreateBank from "./dialog/create_bank.vue";
import handleDialog from "@/layout/mixins/handleDialog";
import Pagination from "@/layout/components/Pagination";
import handlePageUtils from "@/layout/mixins/handlePageUtils";
import {
  editAgent,
  createAgent,
  getSelectMenu,
  getParentPermissions,
  getAgentProcess,
  getAllList,
} from "@/api/agent-management/agent";
import Multiselect from "vue-multiselect";
import Process from "@/layout/components/Process";

export default {
  name: "AgentManagementAll",
  data() {
    return {
      dialogData: undefined,
      agentId: undefined,
      agentDamain: undefined,
      permissionsList: [],
      searchData: [],
      agentData0: undefined,
      agentData1: undefined,
      agentData2: undefined,
      agentData3: undefined,
      level0: [],
      level1: [],
      level2: [],
      level3: [],
      permissionsData: {
        permissionsTitle: [],
        firstData: [],
        secData: [],
      },
      copyText: "",
      processData: undefined,
      i18nPrefix: "agent.agent-management.",
    };
  },
  components: {
    Create,
    Edit,
    Multiselect,
    Pagination,
    Process,
    Member,
    CreateBank,
  },
  mixins: [handleDialog, handlePageUtils],
  computed: {
    ...mapGetters([
      "userName",
      "getUserinfo",
      "getAgentListAddDefault",
      "getGamesCategorys",
    ]),
    processTitle() {
      return [
        {
          key: "Status",
          name: this.$t(`${this.i18nPrefix}status`),
        },
        {
          key: "Domain",
          name: this.$t(`${this.i18nPrefix}domain`),
        },
        {
          key: "CommissionCalculationCycle",
          name: this.$t(`${this.i18nPrefix}dialog.CommissionCalculationCycle`),
        },
      ];
    },
    tableHead() {
      return [
        {
          name: "",
        },
        {
          name: this.$t(`default.sort`),
        },
        {
          name: this.$t(`${this.i18nPrefix}upperAgent`),
        },
        {
          name: this.$t(`${this.i18nPrefix}level`),
        },
        {
          name: this.$t(`${this.i18nPrefix}agent_account`),
        },
        {
          name: this.$t(`${this.i18nPrefix}agent_name`),
        },
        {
          name: this.$t("default.nickName"),
        },
        {
          name: this.$t(`${this.i18nPrefix}agent_bank`),
        },
        {
          name: this.$t(`default.bankAccountNumber`),
        },
        {
          name: this.$t(`default.member`),
        },
        {
          name: this.$t(`default.rebateAmount`),
        },
        {
          name: this.$t(`${this.i18nPrefix}createTime`),
        },
        {
          name: this.$t(`default.processAccount`),
        },
      ];
    },
  },
  async created() {
    this.agentId = this.getUserinfo.parentId;
    await this.getAgentList();
    await getSelectMenu().then((res) => (this.agentDamain = res.data.domain));
  },
  watch: {
    agentData0: {
      handler(value) {
        if (!value) return;
        if (value?.id == 0) {
          let lv1 = [];
          this.getAgentListAddDefault.forEach((item) => {
            if (item.levelNumber == 1) {
              lv1.push(item);
            }
          });
          if (lv1?.length > 0) lv1.unshift({ id: 0, account: "default.all" });
          this.level1 = [...lv1];

          let lv2 = [];
          this.getAgentListAddDefault.forEach((item) => {
            if (item.levelNumber == 2) {
              lv2.push(item);
            }
          });
          if (lv2?.length > 0) lv2.unshift({ id: 0, account: "default.all" });
          this.level2 = [...lv2];

          let lv3 = [];
          this.getAgentListAddDefault.forEach((item) => {
            if (item.levelNumber == 3) {
              lv3.push(item);
            }
          });
          if (lv3?.length > 0) lv3.unshift({ id: 0, account: "default.all" });
          this.level3 = [...lv3];

          this.agentData1 = this.level1[0];
          this.agentData2 = this.level2[0];
          this.agentData3 = this.level3[0];

          this.allData = this.searchData;
          return;
        }

        let lv1 = [];
        this.getAgentListAddDefault.forEach((item) => {
          if (item.parentId == value.id) {
            lv1.push(item);
          }
        });

        let lv2 = [];
        this.getAgentListAddDefault.forEach((item) => {
          lv1.forEach((item1) => {
            if (item.parentId == item1.id) {
              lv2.push(item);
            }
          });
        });

        let lv3 = [];
        this.getAgentListAddDefault.forEach((item) => {
          lv2.forEach((item2) => {
            if (item.parentId == item2.id) {
              lv3.push(item);
            }
          });
        });

        if (lv1.length > 0) lv1.unshift({ id: 0, account: "default.all" });
        this.agentData1 = lv1[0];
        this.level1 = [...lv1];
        if (lv2.length > 0) lv2.unshift({ id: 0, account: "default.all" });
        this.agentData2 = lv2[0];
        this.level2 = [...lv2];
        if (lv3.length > 0) lv3.unshift({ id: 0, account: "default.all" });
        this.agentData3 = lv3[0];
        this.level3 = [...lv3];

        this.allData = this.findAllRelated(value.id, this.searchData);
      },
      immediate: true,
    },
    agentData1: {
      handler(value) {
        if (!value) return;
        if (value?.id == 0) {
          this.allData =
            !this.level0?.length || this.agentData0?.id == 0
              ? this.searchData
              : this.findAllRelated(this.agentData0?.id, this.searchData);

          let lv2 = [];
          this.allData.forEach((item) => {
            if (item?.levelNumber == 2) {
              lv2.push(item);
            }
          });
          if (lv2.length > 0) lv2.unshift({ id: 0, account: "default.all" });
          this.level2 = [...lv2];
          this.agentData2 = lv2[0];

          let lv3 = [];
          this.allData.forEach((item) => {
            if (item.levelNumber == 3) {
              lv3.push(item);
            }
          });
          if (lv3.length > 0) lv3.unshift({ id: 0, account: "default.all" });
          this.level3 = [...lv3];
          this.agentData3 = lv3[0];

          return;
        }

        let lv2 = [];
        this.getAgentListAddDefault.forEach((item) => {
          if (item.parentId == value.id) {
            lv2.push(item);
          }
        });
        let lv3 = [];
        this.getAgentListAddDefault.forEach((item) => {
          lv2.forEach((item2) => {
            if (item.parentId == item2.id) {
              lv3.push(item);
            }
          });
        });

        if (lv2.length > 0) lv2.unshift({ id: 0, account: "default.all" });
        this.agentData2 = lv2[0];
        this.level2 = [...lv2];
        if (lv3.length > 0) lv3.unshift({ id: 0, account: "default.all" });
        this.agentData3 = lv3[0];
        this.level3 = [...lv3];

        this.allData = this.findAllRelated(value.id, this.searchData);
      },
      immediate: true,
    },
    agentData2: {
      handler(value) {
        if (!value) return;
        if (value?.id == 0) {
          if (!this.level1?.length || this.agentData1?.id == 0) {
            this.allData =
            !this.level0?.length || this.agentData0?.id == 0
                ? this.searchData
                : this.findAllRelated(this.agentData0?.id, this.searchData);
          } else {
            this.allData = this.findAllRelated(
              this.agentData1?.id,
              this.searchData
            );
          }

          let levelData =
            this.agentData0?.id == 0
              ? this.searchData
              : this.findAllRelated(this.agentData0?.id, this.searchData);
          let lv1 = [];
          levelData.forEach((item) => {
            if (item.levelNumber == 1) {
              lv1.push(item);
            }
          });
          if (lv1.length > 0) lv1.unshift({ id: 0, account: "default.all" });
          this.level1 = [...lv1];

          let lv3 = [];
          this.allData.forEach((item) => {
            if (item.levelNumber === 3) {
              lv3.push(item);
            }
          });
          if (lv3.length > 0) lv3.unshift({ id: 0, account: "default.all" });
          this.level3 = [...lv3];
          this.agentData3 = lv3[0];

          return;
        }

        let lv1 = [];
        this.getAgentListAddDefault.forEach((item) => {
          if (item.id == value.parentId) {
            lv1.push(item);
          }
        });

        let lv3 = [];
        this.getAgentListAddDefault.forEach((item) => {
          if (item.parentId == value.id) {
            lv3.push(item);
          }
        });

        if (lv1.length > 0) lv1.unshift({ id: 0, account: "default.all" });
        this.level1 = [...lv1];
        if (lv3.length > 0) lv3.unshift({ id: 0, account: "default.all" });
        this.agentData3 = lv3[0];
        this.level3 = [...lv3];

        this.allData = this.findAllRelated(value.id, this.searchData);
      },
      immediate: true,
    },
    agentData3: {
      handler(value) {
        if (!value) return;
        if (value?.id == 0) {
          if (!this.level2?.length || this.agentData2?.id == 0) {
            if (!this.level1?.length || this.agentData1?.id == 0) {
              this.allData =
              !this.level0?.length || this.agentData0?.id == 0
                  ? this.searchData
                  : this.findAllRelated(this.agentData0?.id, this.searchData);
            } else {
              this.allData = this.findAllRelated(
                this.agentData1?.id,
                this.searchData
              );
            }
          } else {
            this.allData = this.findAllRelated(
              this.agentData2?.id,
              this.searchData
            );
          }

          let levelData = [];
          if (this.agentData2?.id == 0 && this.agentData1?.id == 0) {
            levelData =
              this.agentData0?.id == 0
                ? this.searchData
                : this.findAllRelated(this.agentData0?.id, this.searchData);
          } else {
            levelData = this.findAllRelated(
              this.agentData1?.id,
              this.searchData
            );
          }
          let lv1 = [];
          levelData.forEach((item) => {
            if (item.levelNumber == 1) {
              lv1.push(item);
            }
          });
          if (lv1.length > 0) lv1.unshift({ id: 0, account: "default.all" });
          this.level1 = [...lv1];

          let lv2 = [];
          levelData.forEach((item) => {
            if (item.levelNumber == 2) {
              lv2.push(item);
            }
          });
          if (lv2.length > 0) lv2.unshift({ id: 0, account: "default.all" });
          this.level2 = [...lv2];

          return;
        }

        let lv2 = [];
        this.getAgentListAddDefault.forEach((item) => {
          if (item.id == value.parentId) {
            lv2.push(item);
          }
        });

        let lv1 = [];
        this.getAgentListAddDefault.forEach((item) => {
          lv2.forEach((item2) => {
            if (item.id == item2.parentId) {
              lv1.push(item);
            }
          });
        });

        if (lv1.length > 0) lv1.unshift({ id: 0, account: "default.all" });
        this.level1 = [...lv1];
        if (lv2.length > 0) lv2.unshift({ id: 0, account: "default.all" });
        this.level2 = [...lv2];

        this.allData = this.findAllRelated(value.id, this.searchData);
      },
      immediate: true,
    },
  },
  methods: {
    async getAgentList() {
      this.currDialog = undefined;

      await getAllList({
        agentId: this.agentId,
      }).then((res) => {
        this.allData = [...res.data];
        this.searchData = [...res.data];

        this.level0 = [];
        this.level1 = [];
        this.level2 = [];
        this.level3 = [];
        res.data.forEach((item) => {
          if (item.levelNumber === 0) {
            this.level0.push(item);
          } else if (item.levelNumber === 1) {
            this.level1.push(item);
          } else if (item.levelNumber === 2) {
            this.level2.push(item);
          } else if (item.levelNumber === 3) {
            this.level3.push(item);
          }
        });

        if (this.level0.length > 0)
          this.level0.unshift({ id: 0, account: "default.all" });
        // if (this.level1.length > 0)
        //   this.level1.unshift({ id: 0, account: "default.all" });
        // if (this.level2.length > 0)
        //   this.level2.unshift({ id: 0, account: "default.all" });
        // if (this.level3.length > 0)
        //   this.level3.unshift({ id: 0, account: "default.all" });
      });

      await getParentPermissions({ parentId: this.agentId }).then((res) => {
        this.permissionsList = res.data.permissions;
        this.permissionsData.permissionsTitle = this.permissionsList.filter(
          (data) => data.id % 10000 === 0
        );
        this.permissionsData.firstData =
          this.permissionsData.permissionsTitle.map((data) =>
            this.permissionsList.filter(
              (firstdata) =>
                parseInt(data.id / 10000) === parseInt(firstdata.id / 10000) &&
                (data.id - firstdata.id) % 100 === 0 &&
                firstdata.id % 10000 !== 0
            )
          );
        this.permissionsData.secData = this.permissionsData.firstData.map(
          (firstdata) =>
            firstdata.map((data) =>
              this.permissionsList.filter(
                (secData) =>
                  parseInt(data.id / 100) === parseInt(secData.id / 100) &&
                  data.id !== secData.id
              )
            )
        );
      });
    },
    create(data) {
      createAgent(data).then((res) => {
        if (res) this.getAgentList();
      });
    },
    edit(data) {
      editAgent(data).then((res) => {
        if (res) this.getAgentList();
      });
    },
    createAgentBank(data) { },
    getProcess(id) {
      getAgentProcess({ id }).then((res) => {
        this.processData = res.data;
      });
    },
    findAllRelated(id, data) {
      let result = new Set();

      const findChildren = (id) => {
        data.forEach((item) => {
          if (item.parentId == id && !result.has(item.id)) {
            result.add(item.id);
            findChildren(item.id); // 遞歸查找子項
          }
        });
      };

      result.add(id);
      findChildren(id);

      // 將 ID 轉換回原始對象
      return Array.from(result).map((id) =>
        data.find((item) => item.id === id)
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@media screen and (max-width: 1280px) {
  .agent-block {
    flex-direction: column;

    //.tree-block {
    //  width: 95%;
    //}
    .content-block {
      width: 100%;
    }
  }
}

.agent {
  color: #cd7b00;
  text-decoration: underline !important;
  font-weight: bold;
  cursor: pointer;
}
</style>
