<template>
  <div class="dialog-mask" @mousedown.self="$emit('toggle')">
    <div class="dialog-container">
      <div class="dialog-block">
        <main>
          <div class="divider-v2-area" style="display: block">
            <div class="divider-v2-text divider-v2-left">{{ $t(`${i18nPrefix}memberInfo`) }}</div>
            <div class="Basic">
              <div>
                <label>
                  <h1>{{ $t("default.title") }}</h1>
                  <p>{{ list.title }}</p>
                </label>
                <label>
                  <h1>{{ $t(`${i18nPrefix}count`) }}</h1>
                  <p>{{ list.count }}</p>
                </label>
              </div>
              <div>
                <label>
                  <h1>{{ $t("default.member") }}</h1>
                  <p>{{ list.memberAccount }}</p>
                </label>
                <label>
                  <h1>{{ $t(`${i18nPrefix}createDateTime`) }}</h1>
                  <p>{{ list.createDateTime }}</p>
                </label>
              </div>
              <div>
                <label style="justify-content: space-evenly !important;">
                  <h1>{{ $t(`${i18nPrefix}agentName`) }}</h1>
                  <p>{{ list.agentName }}</p>
                </label>
                <label style="justify-content: space-evenly !important;">
                  <h1>{{ $t("default.domain") }}</h1>
                  <p>{{ list.domain }}</p>
                </label>
                <label style="justify-content: space-evenly !important;">
                  <h1>{{ $t(`${i18nPrefix}memberCreateTime`) }}</h1>
                  <p>{{ list.memberCreateTime }}</p>
                </label>
              </div>
              <div class="content">
                <label class="content-label">
                  <h1>{{ $t(`${i18nPrefix}content`) }}</h1>
                  <p>
                    {{ list.content }}
                  </p>
                </label>
              </div>
            </div>
            <div class="gameResultDiv">
              <table v-for="list of gameResult" class="gameResultTable">
                <!-- 體育表 -->
                <template v-if="list.gamesCategory == 1">
                  <thead>
                    <tr>
                      <th>{{ $t(`${i18nPrefix}LeagueName`) }}</th>
                      <th>{{ $t(`${i18nPrefix}MatchDate`) }}</th>
                      <th>{{ $t(`${i18nPrefix}HomeTeam`) }}</th>
                      <th></th>
                      <th>{{ $t(`${i18nPrefix}AwayTeam`) }}</th>
                      <th>{{ $t(`${i18nPrefix}Score`) }}</th>
                      <th>{{ $t(`${i18nPrefix}Result`) }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="gResult of list.gameResult.Records">
                      <td>{{ gResult.LeagueName }}</td>
                      <td>{{ gResult.MatchDate }}</td>
                      <td>
                        <div class="gameResult">
                          <span>{{ gResult.HomeTeam?.Name }}</span>
                          <span>{{ gResult.HomeTeam?.Odds }}</span>
                        </div>
                      </td>
                      <td>{{ gResult.Draw?.Odds === 0 ? "VS" : gResult.Draw?.Odds }}</td>
                      <td>
                        <div class="gameResult">
                          <span>{{ gResult.AwayTeam?.Name }}</span>
                          <span>{{ gResult.AwayTeam?.Odds }}</span>
                        </div>
                      </td>
                      <td>{{ gResult.Score }}</td>
                      <td>{{ $t("CustomServiceMessage.GameResult." + gResult.Result) }}</td>
                    </tr>
                    <tr>
                      <td colspan="2">{{ $t(`${i18nPrefix}BetTime`) + "：" + list.gameResult.BetTime }}</td>
                      <td colspan="4">
                        <div class="gameResult">
                          <span>{{ $t(`${i18nPrefix}BetAmount`) + "：" + list.gameResult.BetAmount }}</span>
                          <span>{{ $t(`${i18nPrefix}Odds`) + "：" + list.gameResult.Odds }}</span>
                          <span>{{ $t(`${i18nPrefix}ExpectedWinnings`) + "：" + list.gameResult.ExpectedWinnings
                          }}</span>
                          <span>{{ $t(`${i18nPrefix}ActualWinnings`) + "：" + list.gameResult.ActualWinnings }}</span>
                        </div>
                      </td>
                      <td>{{ $t("CustomServiceMessage.GameResult." + list.gameResult.Result) }}</td>
                    </tr>
                  </tbody>
                </template>
                <!-- 小遊戲表 -->
                <template v-else>
                  <thead>
                    <tr>
                      <th>{{ $t(`${i18nPrefix}GameName`) }}</th>
                      <th>{{ $t(`${i18nPrefix}BetTime`) }}</th>
                      <th>{{ $t(`${i18nPrefix}Round`) }}</th>
                      <th>{{ $t(`${i18nPrefix}BetOption`) }}</th>
                      <th>{{ $t(`${i18nPrefix}Result`) }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="gResult of list.gameResult.Records">
                      <td>{{ gResult.GameName }}</td>
                      <td>{{ gResult.BetTime }}</td>
                      <td>{{ gResult.Round }}</td>
                      <td>
                        <div class="gameResult">
                          <span>{{ gResult.BetOption?.Name }}</span>
                          <span>{{ gResult.BetOption?.Odds }}</span>
                        </div>
                      </td>
                      <td>{{ $t("CustomServiceMessage.GameResult." + gResult.Result) }}</td>
                    </tr>
                    <tr>
                      <td colspan="2">{{ $t(`${i18nPrefix}DrawTime`) + "：" + list.gameResult.DrawTime }}</td>
                      <td colspan="2">
                        <div class="gameResult">
                          <span>{{ $t(`${i18nPrefix}BetAmount`) + "：" + list.gameResult.BetAmount }}</span>
                          <span>{{ $t(`${i18nPrefix}Odds`) + "：" + list.gameResult.Odds }}</span>
                          <span>{{ $t(`${i18nPrefix}ExpectedWinnings`) + "：" + list.gameResult.ExpectedWinnings
                          }}</span>
                          <span>{{ $t(`${i18nPrefix}ActualWinnings`) + "：" + list.gameResult.ActualWinnings }}</span>
                        </div>
                      </td>
                      <td>{{ $t("CustomServiceMessage.GameResult." + list.gameResult.Result) }}</td>
                    </tr>
                  </tbody>
                </template>
              </table>
            </div>
          </div>
          <label class="w-100">
            <h1>{{ $t(`${i18nPrefix}reply`) }}</h1>
            <ckeditor v-model="replyData" :editor-url="this.GLOBAL.CKEditorUrl"></ckeditor>
          </label>
          <div class="advance-block">
            <button class="normal-btn" @click="$emit('reply', { messageId: list.id, content: replyData })"
              v-show="!list.isDelete">
              {{ $t("default.confirm") }}
            </button>
          </div>
          <div class="replyRecord">
            <h1>{{ $t(`${i18nPrefix}replyRecord`) }}</h1>
            <div class="replyRecordDiv" v-for="record of userRecord" :key="record.id"
              :class="{ 'bg-secondary': record.isDelete }">
              <div class="recordDiv">
                <div class="infoDiv">
                  <h1>{{ $t("default.processAccount") }}</h1>
                  <p>{{ record.userName }}</p>
                </div>
                <div class="infoDiv">
                  <h1>{{ $t(`${i18nPrefix}replyTime`) }}</h1>
                  <p>{{ record.createTime }}</p>
                </div>
              </div>
              <div class="userContentDiv">
                <p v-html="record.content"></p>
                <div style="display: flex; justify-content: flex-end; width: 100%;" v-show="!record.isDelete">
                  <button class="delete-btn" @click="handleDeleteUserMsg(record.id)"
                    :disabled="!$store.getters.getUserinfo.permissions.includes(200104)">
                    {{ $t("default.delete") }}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <!-- 回覆範例 -->
          <div class="tempTable">
            <h1>{{ $t(`${i18nPrefix}replyTemp`) }}</h1>
            <table>
              <tr>
                <th>{{ $t(`${i18nPrefix}tempTitle`) }}</th>
                <th></th>
              </tr>
              <tr v-for="temp of templateList">
                <td>
                  <div :style="{ backgroundColor: temp.backgroundColor, color: temp.titleColor }">{{ temp.title }}</div>
                </td>
                <td> <button class="normal-btn" @click="replyData = temp.content">{{ $t(`${i18nPrefix}useTemp`)
                }}</button></td>
              </tr>
            </table>
          </div>
        </main>
      </div>
    </div>
    <Alert v-if="alertMsg" :message="alertMsg" @toggle="alertMsg = undefined" @doIt="deleteFunction" />
  </div>
</template>

<script>
import { messageDetail, deleteReply } from "@/api/customService/customServiceMessage";
import { messageTemplateList } from "@/api/customService/customServiceMessageTemplate";
import Alert from "@/layout/components/Alert";

export default {
  name: "Edit",
  props: ["list", "i18nPrefix"],
  data() {
    return {
      replyData: undefined,
      templateList: [],
      userRecord: [],
      gameResult: [],
      alertMsg: undefined,
      deleteData: undefined,
    }
  },
  components: {
    Alert,
  },
  created() {
    this.search();
    messageTemplateList({ isDetele: false }).then(res => this.templateList = res.data)
  },
  methods: {
    search() {
      messageDetail({ messageId: this.list.id }).then(res => {
        this.userRecord = res.data.details;
        this.gameResult = res.data.gameResult;
      })
    },
    handleDeleteUserMsg(id) {
      this.alertMsg = this.$t("default.delMsg");
      this.deleteData = { id };
    },
    deleteFunction() {
      this.alertMsg = "";
      deleteReply(this.deleteData).then(res => {
        if (res) this.search();
      });
    },
  }
}
</script>

<style lang="scss" scoped>
.dialog-container {
  width: 70% !important;
  height: auto !important;

  h1 {
    font-size: larger;
  }

  .Basic {
    margin: 25px 10px 10px 10px;

    div {
      display: flex;
      justify-content: space-around;
      width: 100%;

      label {
        width: 35% !important;
        display: flex;
        justify-content: space-between;
      }
    }
  }

  .content {
    .content-label {
      width: 85% !important;
      justify-content: flex-start !important;

      h1 {
        width: 15%;
      }

      p {
        white-space: pre-line;
      }
    }
  }

  .gameResultDiv {
    display: flex;
    flex-direction: column;
    padding: 10px;

    .gameResultTable {
      border: 2px solid #000;
      text-align: center;
      margin-bottom: 5px;

      th {
        border: 2px solid #000;
        background-color: #bd6060;
        padding: 5px 15px;
        white-space: nowrap;
        margin-left: auto;
        margin-right: auto;
      }

      td {
        border: 2px solid #000;
        padding: 5px;
        margin-left: auto;
        margin-right: auto;
      }

      .gameResult {
        display: flex;
        justify-content: space-between;
        gap: 10px;
      }
    }
  }

  .replyRecord {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;

    .replyRecordDiv {
      width: 90%;
      margin: auto;

      .recordDiv {
        display: flex;
        justify-content: space-around;
        border-style: solid;
        border-width: 1px;
        padding: 10px;

        .infoDiv {
          width: 40%;
          display: flex;
          justify-content: space-around;
          margin-top: 15px;
        }
      }

      .userContentDiv {
        border-style: solid;
        border-width: 0px 1px 1px 1px;
        padding: 10px;
        padding-top: 20px;

        p {
          word-break: break-all;
        }
      }
    }
  }

  .tempTable {
    width: 100%;

    table {
      width: 50%;
      margin: auto;
      text-align: center;

      td {
        padding: 5px;

        div {
          margin: 0 2px;
          border: 2px solid #000;
          border-radius: 10px;
          padding: 5px;
        }
      }
    }
  }
}
</style>