<template>
  <div>
    <div class="container-view">
      <button class="search-btn main-style-btn" @click="search()">{{ $t("default.search") }}</button>
      <div class="header">
        <h5>{{ $t("title." + $route.meta.title) }}</h5>
      </div>
      <div class="main">
        <div class="input-block">
          <label>
            {{ $t("default.member") }}
            <input type="text" v-model="searchForm.account" />
          </label>
          <label>
            {{ $t(`${i18nPrefix}startTime`) }}
            <date-picker v-model="startTime" format="YYYY-MM-DD HH:mm" value-type="format"
              type="datetime"></date-picker>
          </label>
          <label>
            {{ $t(`${i18nPrefix}endTime`) }}
            <date-picker v-model="endTime" format="YYYY-MM-DD HH:mm" value-type="format" type="datetime"></date-picker>
          </label>
          <label>
            {{ $t(`${i18nPrefix}transactionType`) }}
            <select v-model="searchForm.isSettled">
              <option :value="true">{{ $t(`${i18nPrefix}isSettled`) }}</option>
              <option :value="false">{{ $t(`${i18nPrefix}unSettle`) }}</option>
            </select>
          </label>
        </div>
        <div class="advance-block">
          <QuickSearch @changeTime="changeTime"></QuickSearch>
          <button class="search-pc-btn main-style-btn px-5" @click="search()">{{ $t("default.search") }}</button>
        </div>
      </div>
    </div>
    <div class="container-view">
      <div class="total-block-v2" v-if="!$store.getters.getUserinfo.koreanStyleInterface">
        <div class="total-block-v2-item">
          <div class="total-block-v2-item-left">{{ $t(`${i18nPrefix}totalBetAmount`) }}:</div>
          <div class="total-block-v2-item-right">{{ totalBetAmount }}</div>
        </div>
        <div class="total-block-v2-item">
          <div class="total-block-v2-item-left">{{ $t(`${i18nPrefix}totalBetAmountLimit`) }}:</div>
          <div class="total-block-v2-item-right">{{ totalBetAmountLimit }}</div>
        </div>
      </div>
      <div class="table-block">
        <table>
          <thead>
            <tr>
              <th v-if="$store.getters.getUserinfo.koreanStyleInterface">{{ $t("default.sort") }}</th>
              <th></th>
              <th v-if="!$store.getters.getUserinfo.koreanStyleInterface">{{ $t(`${i18nPrefix}transferType`) }}</th>
              <th v-if="!$store.getters.getUserinfo.koreanStyleInterface">{{ $t(`${i18nPrefix}name`) }}</th>
              <th v-if="!$store.getters.getUserinfo.koreanStyleInterface">{{ $t("default.remark") }}</th>
              <th>{{ $t("default.member") }}</th>
              <th v-if="$store.getters.getUserinfo.koreanStyleInterface">{{ $t("default.nickName") }}</th>
              <th v-if="$store.getters.getUserinfo.koreanStyleInterface">{{ $t(`${i18nPrefix}transferType`) }}</th>
              <th>{{ $t("default.amount") }}</th>
              <th>{{ $t(`${i18nPrefix}betAmountLimit`) }}</th>
              <th v-if="!$store.getters.getUserinfo.koreanStyleInterface">{{ $t(`${i18nPrefix}accumulatedAmount`) }}</th>
              <th v-if="$store.getters.getUserinfo.koreanStyleInterface">{{ $t(`${i18nPrefix}totalAmount`) }}</th>
              <th v-if="$store.getters.getUserinfo.koreanStyleInterface">IP</th>
              <th>{{ $t(`${i18nPrefix}createTime`) }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="!allData || !allData.length">
              <td colspan="999">{{ $t("default.noData") }}</td>
            </tr>
            <tr v-for="(list, index) of allData" :key="index">
              <td v-if="$store.getters.getUserinfo.koreanStyleInterface">{{ list.id }}</td>
              <td>
                <button class="edit-btn" @click="handleUpdate(list.id)" v-show="!list.isSettled"
                  :disabled="!$store.getters.getUserinfo.permissions.includes(10901)">{{ $t("default.settle")
                  }}</button>
              </td>
              <td v-if="!$store.getters.getUserinfo.koreanStyleInterface">{{ GLOBAL.i18nCheckName(transferType.find(data => data.id === list.type)) }}</td>
              <td v-if="!$store.getters.getUserinfo.koreanStyleInterface">{{ list.name }}</td>
              <td v-if="!$store.getters.getUserinfo.koreanStyleInterface">{{ list.remark }}</td>
              <td>
                <span class="memberSpan"
                  @click="handleDialog({ list: { id: list.member.id, account: list.member.account, toggle: true } }, 'Detail')">{{
                  list.member.account }}
                </span>
              </td>
              <td v-if="$store.getters.getUserinfo.koreanStyleInterface">{{ list.member.nickName }}</td>
              <td v-if="$store.getters.getUserinfo.koreanStyleInterface">{{ GLOBAL.i18nCheckName(transferType.find(data => data.id === list.type)) }}</td>
              <td>{{ list.amount }}</td>
              <td v-if="!$store.getters.getUserinfo.koreanStyleInterface">{{ list.betAmountLimit }}</td>
              <td>{{ list.accumulatedAmount }}</td>
              <td v-if="$store.getters.getUserinfo.koreanStyleInterface">{{ list.totalAmount }}</td>
              <td v-if="$store.getters.getUserinfo.koreanStyleInterface">{{ list.ip }}</td>
              <td>{{ list.createTime }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <serverPagination v-if="allData" :page="page" @ServerPageUtils="search" @changeTake="changeTake">
      </serverPagination>
    </div>
    <transition name="fade" mode="out-in">
      <Alert v-if="alertMsg" :message="alertMsg" @toggle="alertMsg = undefined" @doIt="remove" />
      <component :is="currDialog" v-bind="dialogData" @toggle="currDialog = undefined"></component>
    </transition>
  </div>
</template>

<script>
import { searchModel } from "./model";
import {
  betAmountLimitList,
  betAmountLimitTotal,
  updateBetAmountLimit,
  getSelectMenu
} from "@/api/member/betAmountLimit";
import { checkValue } from "@/utils/checkData";
import { joinT } from "@/utils/transDateUtils";
import QuickSearch from "@/layout/components/QuickSearch";
import serverPagination from "@/layout/components/serverPagination";
import handleServerPageUtils from "@/layout/mixins/handleServerPageUtils";
import { toggleStyle } from "@/utils/slideUtils";
import Alert from "@/layout/components/Alert";
import handleDialog from "@/layout/mixins/handleDialog";
import Detail from "../member-management/dialog/detail";

export default {
  name: "BetAmountLimit",
  data() {
    return {
      searchForm: _.cloneDeep(searchModel),
      startTime: undefined,
      endTime: undefined,
      transferType: [],
      totalBetAmount: undefined,
      totalBetAmountLimit: undefined,
      alertMsg: undefined,
      deleteData: {},
      i18nPrefix: "member.betAmountLimit."
    };
  },
  mixins: [handleServerPageUtils, handleDialog],
  components: {
    Detail,
    Alert,
    QuickSearch,
    serverPagination
  },
  async created() {
    await getSelectMenu().then(res => {
      this.transferType = res.data.memberWithdrawalValidBetLimitType.map(data => {
        return {
          id: data.id,
          name: "MemberWithdrawalValidBetLimitType." + data.name
        };
      });
    });
    if (this.$route.query?.memberAccount) {
      this.searchForm.account = this.$route.query?.memberAccount;
      this.search();
    }
  },
  methods: {
    toggleSlide: toggleStyle,
    async search(page = false) {
      if (!checkValue(this.searchForm.account, this.$t("default.member")))
        return;
      this.searchForm.startTime = this.startTime
        ? joinT(this.startTime + this.GLOBAL.startSecond + this.GLOBAL.timeZone)
        : undefined;
      this.searchForm.endTime = this.endTime
        ? joinT(this.endTime + this.GLOBAL.startSecond + this.GLOBAL.timeZone)
        : undefined;
      this.searchForm.page.take = this.take * 1;
      this.searchForm.page.skip = this.take * (page ? page - 1 : 0);
      await betAmountLimitList(this.searchForm).then(res => {
        this.allData = res.data.list;
        this.page = res.data.page;
      });
      await betAmountLimitTotal(this.searchForm).then(res => {
        this.totalBetAmount = res.data.totalBetAmount;
        this.totalBetAmountLimit = res.data.totalBetAmountLimit;
      });
    },
    handleUpdate(id) {
      this.alertMsg = this.$t("default.checkMsg");
      this.deleteData = { id };
    },
    remove() {
      this.alertMsg = undefined;
      updateBetAmountLimit(this.deleteData).then(res => {
        if (res) this.search();
      });
    },
    changeTime(time) {
      this.startTime = time.startTime;
      this.endTime = time.endTime;
      this.search();
    }
  }
};
</script>

<style lang="scss" scoped></style>