var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "login-container" },
    [
      _c("img", { attrs: { src: require("./assets/logo.png") } }),
      _c("div", { staticClass: "login-block" }, [
        _c("span", { staticClass: "title" }, [
          _vm._v(_vm._s(_vm.$t("login.KR9title"))),
        ]),
        _c("div", { staticClass: "line" }),
        _c("div", { staticClass: "lang" }, [
          _c(
            "div",
            {
              staticClass: "dropdown-div",
              on: {
                click: function ($event) {
                  _vm.drop = !_vm.drop
                },
              },
            },
            [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.GLOBAL.langes.find((data) => data.key === _vm.lang)
                      .value
                  )
                ),
              ]),
              _c("i", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.drop,
                    expression: "!drop",
                  },
                ],
                staticClass: "fas fa-chevron-down",
              }),
              _c("i", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.drop,
                    expression: "drop",
                  },
                ],
                staticClass: "fas fa-chevron-up",
              }),
            ]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.drop,
                  expression: "drop",
                },
              ],
              staticClass: "dropdown",
            },
            _vm._l(_vm.GLOBAL.langes, function (lang) {
              return _c(
                "span",
                {
                  key: lang.key,
                  on: {
                    click: function ($event) {
                      return _vm.i18nSet(lang.key)
                    },
                  },
                },
                [_vm._v(_vm._s(lang.value))]
              )
            }),
            0
          ),
        ]),
        _c("div", { staticClass: "input-block" }, [
          _c("label", [
            _vm._v(" " + _vm._s(_vm.$t("login.account")) + " "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.userInfo.account,
                  expression: "userInfo.account",
                },
              ],
              ref: "account",
              class: { required: !_vm.accountReq },
              attrs: { type: "text" },
              domProps: { value: _vm.userInfo.account },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.userInfo, "account", $event.target.value)
                },
              },
            }),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.accountReq,
                    expression: "!accountReq",
                  },
                ],
              },
              [_vm._v(_vm._s(_vm.$t("login.required")))]
            ),
          ]),
          _c("label", [
            _vm._v(" " + _vm._s(_vm.$t("login.password")) + " "),
            _vm.passwordType === "checkbox"
              ? _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.userInfo.password,
                      expression: "userInfo.password",
                    },
                  ],
                  key: _vm.passwordType,
                  ref: "password",
                  class: { required: !_vm.passwordReq },
                  attrs: { type: "checkbox" },
                  domProps: {
                    checked: Array.isArray(_vm.userInfo.password)
                      ? _vm._i(_vm.userInfo.password, null) > -1
                      : _vm.userInfo.password,
                  },
                  on: {
                    change: function ($event) {
                      var $$a = _vm.userInfo.password,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(
                              _vm.userInfo,
                              "password",
                              $$a.concat([$$v])
                            )
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.userInfo,
                              "password",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(_vm.userInfo, "password", $$c)
                      }
                    },
                  },
                })
              : _vm.passwordType === "radio"
              ? _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.userInfo.password,
                      expression: "userInfo.password",
                    },
                  ],
                  key: _vm.passwordType,
                  ref: "password",
                  class: { required: !_vm.passwordReq },
                  attrs: { type: "radio" },
                  domProps: { checked: _vm._q(_vm.userInfo.password, null) },
                  on: {
                    change: function ($event) {
                      return _vm.$set(_vm.userInfo, "password", null)
                    },
                  },
                })
              : _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.userInfo.password,
                      expression: "userInfo.password",
                    },
                  ],
                  key: _vm.passwordType,
                  ref: "password",
                  class: { required: !_vm.passwordReq },
                  attrs: { type: _vm.passwordType },
                  domProps: { value: _vm.userInfo.password },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.userInfo, "password", $event.target.value)
                    },
                  },
                }),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.passwordReq,
                    expression: "!passwordReq",
                  },
                ],
              },
              [_vm._v(_vm._s(_vm.$t("login.required")))]
            ),
            _c("i", {
              staticClass: "fa",
              class:
                _vm.passwordType === "password" ? "fa-eye" : "fa-eye-slash",
              on: { click: _vm.showPwd },
            }),
          ]),
          _c("label", [
            _vm._v(" " + _vm._s(_vm.$t("login.companyCode")) + " "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.userInfo.companyCode,
                  expression: "userInfo.companyCode",
                },
              ],
              ref: "companyCode",
              class: {
                required: !_vm.companyCodeReq,
              },
              attrs: { type: "text" },
              domProps: { value: _vm.userInfo.companyCode },
              on: {
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return _vm.verify.apply(null, arguments)
                },
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.userInfo, "companyCode", $event.target.value)
                },
              },
            }),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.companyCodeReq,
                    expression: "!companyCodeReq",
                  },
                ],
              },
              [_vm._v(_vm._s(_vm.$t("login.required")))]
            ),
          ]),
        ]),
        _c("label", { staticClass: "labelBox" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.remeber,
                expression: "remeber",
              },
            ],
            staticClass: "box",
            attrs: { type: "checkbox" },
            domProps: {
              checked: Array.isArray(_vm.remeber)
                ? _vm._i(_vm.remeber, null) > -1
                : _vm.remeber,
            },
            on: {
              change: function ($event) {
                var $$a = _vm.remeber,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = null,
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 && (_vm.remeber = $$a.concat([$$v]))
                  } else {
                    $$i > -1 &&
                      (_vm.remeber = $$a
                        .slice(0, $$i)
                        .concat($$a.slice($$i + 1)))
                  }
                } else {
                  _vm.remeber = $$c
                }
              },
            },
          }),
          _c("span", [_vm._v(_vm._s(_vm.$t("login.remember")))]),
        ]),
        _c("div", { staticClass: "button-block" }, [
          _c(
            "button",
            {
              attrs: { disabled: !_vm.validPass || _vm.isLoading },
              on: { click: _vm.verify },
            },
            [
              !_vm.isLoading
                ? _c("span", [_vm._v(_vm._s(_vm.$t("login.login")))])
                : _c("i", { staticClass: "fa fa-spinner" }),
            ]
          ),
        ]),
      ]),
      _c(
        "transition",
        { attrs: { name: "fade", mode: "out-in" } },
        [
          _c(
            _vm.currDialog,
            _vm._b(
              {
                tag: "component",
                on: { toggle: _vm.cancel, twoFactorAutn: _vm.twoFactorAutn },
              },
              "component",
              _vm.dialogData,
              false
            )
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }