var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "dialog-mask",
      on: {
        mousedown: function ($event) {
          if ($event.target !== $event.currentTarget) return null
          return _vm.$emit("toggle")
        },
      },
    },
    [
      _c("div", { staticClass: "dialog-container edit-member" }, [
        _c("div", { staticClass: "dialog-block" }, [
          _c("div", { staticClass: "header" }, [
            _c("h4", [_vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}pointWallet`)))]),
          ]),
          _c("div", { staticClass: "divider" }),
          _c("main", [
            _c("div", { staticClass: "input-block" }, [
              _c("label", [
                _vm._v(" " + _vm._s(_vm.$t(`${_vm.i18nPrefix}account`)) + " "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.editModel.memberAccount,
                      expression: "editModel.memberAccount",
                    },
                  ],
                  attrs: { type: "text", disabled: "" },
                  domProps: { value: _vm.editModel.memberAccount },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.editModel,
                        "memberAccount",
                        $event.target.value
                      )
                    },
                  },
                }),
              ]),
              _c("div", [
                _c("label", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          `${_vm.i18nPrefix}dialog.balance-adjustment.bonusReceive`
                        )
                      ) +
                      " "
                  ),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.point,
                        expression: "point",
                      },
                    ],
                    attrs: { type: "text", disabled: "" },
                    domProps: { value: _vm.point },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.point = $event.target.value
                      },
                    },
                  }),
                ]),
                _c("label", [
                  _vm._v(" " + _vm._s(_vm.$t("default.point")) + " "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.number",
                        value: _vm.editModel.point,
                        expression: "editModel.point",
                        modifiers: { number: true },
                      },
                    ],
                    attrs: { type: "number" },
                    domProps: { value: _vm.editModel.point },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.editModel,
                          "point",
                          _vm._n($event.target.value)
                        )
                      },
                      blur: function ($event) {
                        return _vm.$forceUpdate()
                      },
                    },
                  }),
                ]),
                _c("label", [
                  _vm._v(" " + _vm._s(_vm.$t("default.remark")) + " "),
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.editModel.remark,
                        expression: "editModel.remark",
                      },
                    ],
                    attrs: { cols: "30", rows: "6" },
                    domProps: { value: _vm.editModel.remark },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.editModel, "remark", $event.target.value)
                      },
                    },
                  }),
                ]),
                _c("label", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(`${_vm.i18nPrefix}dialog.point.adjustment`)
                      ) +
                      " "
                  ),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.editModel.type,
                          expression: "editModel.type",
                        },
                      ],
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.editModel,
                            "type",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                      },
                    },
                    [
                      _c("option", { domProps: { value: 1 * 1 } }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              `${_vm.i18nPrefix}dialog.point.adjustmentType1`
                            )
                          )
                        ),
                      ]),
                      _c("option", { domProps: { value: 2 * 1 } }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              `${_vm.i18nPrefix}dialog.point.adjustmentType2`
                            )
                          )
                        ),
                      ]),
                    ]
                  ),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "advance-block" }, [
              _c(
                "button",
                {
                  staticClass: "confirm-btn main-style-btn",
                  on: {
                    click: function ($event) {
                      return _vm.$emit("point", _vm.editModel)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("default.confirm")))]
              ),
              _c(
                "button",
                {
                  staticClass: "normal-btn-v2 main-style-btn",
                  on: {
                    click: function ($event) {
                      return _vm.$emit("toggle")
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("default.cancel")))]
              ),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }