<template>
  <div>
    <div class="container-view">
      <button class="search-btn main-style-btn" @click="search()">{{ $t("default.search") }}</button>
      <div class="header">
        {{ $t("Permission." + $route.meta.permissionName) }}
      </div>
      <div class="main">
        <div class="input-block">
          <label>
            {{ $t("default.member") }}
            <input type="text" v-model="searchForm.account" />
          </label>
          <label>
            {{ $t("default.startTime") }}
            <date-picker v-model="startTime" format="YYYY-MM-DD HH:mm" value-type="format"
              type="datetime"></date-picker>
          </label>
          <label>
            {{ $t("default.endTime") }}
            <date-picker v-model="endTime" format="YYYY-MM-DD HH:mm" value-type="format"
              type="datetime"></date-picker>
          </label>
          <label>
            {{ $t("default.status") }}
            <select v-model="searchForm.status">
              <option :value="undefined">{{ $t("default.all") }}</option>
              <option v-for="list of auditStatuses" :key="list.id" :value="list.id">{{ list.name }}</option>
            </select>
          </label>
        </div>
        <div class="advance-block">
          <button class="search-pc-btn main-style-btn px-5" @click="search()">{{ $t("default.search") }}</button>
        </div>
      </div>
    </div>
    <div class="container-view">
      <div class="table-block">
        <table class="KoreanStyle">
          <thead>
            <tr>
              <th></th>
              <th>{{ $t("default.sort") }}</th>
              <th>{{ $t("default.agent") }}</th>
              <th>{{ $t("default.status") }}</th>
              <th>{{ $t("default.member") }}</th>
              <th>{{ $t("default.nickName") }}</th>
              <th>{{ $t("member.member-management.name") }}</th>
              <th>{{ $t("member.member-management.phone") }}</th>
              <th>{{ $t("default.bankName") }}</th>
              <th>{{ $t("default.bankAccountNumber") }}</th>
              <th>{{ $t("default.createTime") }}</th>
              <th>{{ $t("default.processAccount") }}</th>
              <th>{{ $t("member.member-management.ip") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="!allData || !allData.length">
              <td colspan="999">{{ $t("default.noData") }}</td>
            </tr>
            <tr v-for="(list, index) of allData" :key="list.id">
              <td>
                <button @click="handleDialog(list, 'audit')" class="table-btn main-style-btn"
                  :disabled="!getUserinfo.permissions.includes(14002)">
                  {{ list.status === 1 ? $t('default.audit') : $t('default.edit') }}
                </button>
              </td>
              <td>{{ list.id }}</td>
              <td>
                {{ GLOBAL.i18nCheckName(getAgentList.find((data) => list.agentId == data.id)) }}
              </td>
              <td>
                {{ GLOBAL.i18nCheckName(auditStatuses.find((data) => list.status == data.id)) }}
              </td>
              <td>
                <span class="memberSpan"
                  @click="handleDialog({ list: { id: list.member.id, account: list.member.account, toggle: true } }, 'Detail')">{{
                  list.member.account }}
                </span>
              </td>
              <td>{{ list.nickname }}</td>
              <td>{{ list.name }}</td>
              <td>{{ list.phoneNumber }}</td>
              <td>{{ list.bankName }}</td>
              <td>{{ list.bankAccountNumber }}</td>
              <td>{{ list.createTime }}</td>
              <td>{{ list.updateUser }}</td>
              <td>{{ list.ip }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <serverPagination v-if="allData" :page="page" @ServerPageUtils="search" @changeTake="changeTake" />
    </div>

    <transition name="fade" mode="out-in">
      <component :is="currDialog" :statusList="auditStatuses" :i18nPrefix="i18nPrefix" v-bind="dialogData" @toggle="currDialog = undefined"
        @audit="audit"></component>
    </transition>
  </div>
</template>

<script>
import { searchModel } from "./model";
import {
  getMemberRegistrationList,
  updateMemberRegistration
} from "@/api/member/registration";
import { mapGetters } from "vuex";
import Audit from "./dialog/audit";
import serverPagination from "@/layout/components/serverPagination";
import handleServerPageUtils from "@/layout/mixins/handleServerPageUtils";
import handleDialog from "@/layout/mixins/handleDialog";
import Detail from "@/views/member/member-management/dialog/detail";
import { joinT } from "@/utils/transDateUtils";

export default {
  name: "MemberRegistration",
  components: {
    Audit,
    Detail,
    serverPagination,
  },
  data() {
    return {
      searchForm: _.cloneDeep(searchModel),
      processData: undefined,
      startTime: undefined,
      endTime: undefined,
      i18nPrefix: "member.member-withdraw."
    };
  },
  mixins: [handleServerPageUtils, handleDialog],
  computed: {
    ...mapGetters([
      "getAuditStatusList",
      "getUserinfo",
      "getPaymentList",
      "getGamePlatforms",
      "getAgentList"
    ]),
    auditStatuses() {
      return [
        {
          id: 1,
          name: this.$t("default.underReview")
        },
        {
          id: 2,
          name: this.$t("default.accept")
        },
        {
          id: 3,
          name: this.$t("default.reject")
        }
      ];
    }
  },
  created() {
    this.search();
  },
  methods: {
    async search(page = false) {

      this.searchForm.startTime =
        this.startTime && this.startTime?.length !== 0
          ? joinT(
            this.startTime +
            this.GLOBAL.startSecond +
            this.GLOBAL.timeZone
          )
          : undefined;
      this.searchForm.endTime =
        this.endTime && this.endTime?.length !== 0
          ? joinT(
            this.endTime + this.GLOBAL.endSecond + this.GLOBAL.timeZone
          )
          : undefined;

      this.searchForm.page.take = this.take * 1;
      this.searchForm.page.skip = this.take * (page ? page - 1 : 0);
      await getMemberRegistrationList(this.searchForm).then(res => {
        this.allData = res.data.list;
        this.page = res.data.page
        this.closeDialog();
      });
    },
    audit(data) {
      updateMemberRegistration(data).then(res => {
        if (res) this.search();
      });
    }
  }
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
.copyBtn {
  font-size: 1rem !important;
}

.copyBtn {
  transition: 0.5s;

  &:hover {
    color: #c700c7;
  }
}

.copyed {
  color: #c700c7;
}
</style>