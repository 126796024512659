<template>
  <table>
    <thead>
      <tr>
        <th>{{ $t("default.member") }}</th>
        <th>{{ $t("default.point") }}</th>
        <th>{{ $t(`${i18nPrefix}preTradePoint`) }}</th>
        <th>{{ $t(`${i18nPrefix}afterTradePoint`) }}</th>
        <th>{{ $t(`${i18nPrefix}pointExchangeRate`) }}</th>
        <th>{{ $t("default.remark") }}</th>
        <th>{{ $t("default.processAccount") }}</th>
        <th>{{ $t("default.createTime") }}</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>{{ pointForm.memberAccount }}</td>
        <td :class="fontColor(pointForm.point)">{{ pointForm.point }}</td>
        <td>{{ pointForm.preTradePoint }}</td>
        <td>{{ pointForm.afterTradePoint }}</td>
        <td>{{ pointForm.pointExchangeRate }}</td>
        <td>{{ pointForm.remark }}</td>
        <td>{{ pointForm.auditAccount }}</td>
        <td>{{ pointForm.createTime }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { transferModel } from "../model";

export default {
  name: "pointForm",
  props: {
    detailsData: {},
    digital: {},
  },
  data() {
    return {
      pointForm: _.cloneDeep(transferModel),
      i18nPrefix: "report.memberPointRecord."
    };
  },
  watch: {
    detailsData() {
      this.pointForm = {
        memberAccount: this.detailsData.memberAccount,
        point: this.digital.format(this.detailsData.point),
        preTradePoint: this.digital.format(this.detailsData.preTradePoint),
        afterTradePoint: this.digital.format(this.detailsData.afterTradePoint),
        pointExchangeRate: this.digital.format(this.detailsData.pointExchangeRate),
        remark: this.detailsData.remark,
        auditAccount: this.detailsData.auditAccount,
        createTime: this.detailsData.createTime
      };
    },
  },
  methods: {
    fontColor(value) {
      return value < 0 ? "red" : "green";
    }
  },
};
</script>

<style lang="scss" scoped></style>
