<template>
  <div>
    <component :is="getUserinfo.koreanStyleInterface ? 'agentManagementAll' : 'agentManagement'"></component>
  </div>
</template>

<script>
import agentManagement from "./agent-management";
import agentManagementAll from "./agent-managementAll";
import { mapGetters } from "vuex";

export default {
  name: "index",
  data() {
    return {
    };
  },
  components: {
    agentManagement,
    agentManagementAll
  },
  computed: {
    ...mapGetters(["getUserinfo"])
  },
  methods: {}
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped></style>