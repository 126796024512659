var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "container-view" }, [
        _c("div", { staticClass: "header" }, [
          _c("h5", [_vm._v(_vm._s(_vm.$t("title." + _vm.$route.meta.title)))]),
        ]),
        _c("div", { staticClass: "divider" }),
        _c("div", { staticClass: "wrap" }, [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.getUserinfo.permissions.includes(62001),
                  expression: "getUserinfo.permissions.includes(62001)",
                },
              ],
              staticClass: "section",
            },
            [
              _c("div", { staticClass: "header" }, [
                _c("h3", [
                  _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}updateDomain`))),
                ]),
                _c(
                  "button",
                  {
                    staticClass: "table-btn main-style-btn",
                    attrs: {
                      disabled:
                        !_vm.getUserinfo.permissions.includes(62001) ||
                        _vm.checkDomain,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.domainSetting(_vm.newDomain)
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("default.edit")))]
                ),
              ]),
              _c("label", [
                _vm._v(" " + _vm._s(_vm.$t(`${_vm.i18nPrefix}domain`)) + " "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.domain,
                      expression: "domain",
                    },
                  ],
                  attrs: { type: "text", disabled: "" },
                  domProps: { value: _vm.domain },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.domain = $event.target.value
                    },
                  },
                }),
              ]),
              _c("label", [
                _vm._v(
                  " " + _vm._s(_vm.$t(`${_vm.i18nPrefix}newDomain`)) + " "
                ),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.newDomain,
                      expression: "newDomain",
                    },
                  ],
                  attrs: { type: "text" },
                  domProps: { value: _vm.newDomain },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.newDomain = $event.target.value
                    },
                  },
                }),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.checkDomain,
                        expression: "checkDomain",
                      },
                    ],
                  },
                  [_vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}checkDomain`)))]
                ),
              ]),
            ]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.getUserinfo.permissions.includes(62001),
                  expression: "getUserinfo.permissions.includes(62001)",
                },
              ],
              staticClass: "section",
            },
            [
              _c("div", { staticClass: "header" }, [
                _c("h3", [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        `${_vm.i18nPrefix}googleAuthenticatorFeatureToggle`
                      )
                    )
                  ),
                ]),
                _c(
                  "button",
                  {
                    staticClass: "table-btn main-style-btn",
                    attrs: {
                      disabled: !_vm.getUserinfo.permissions.includes(62001),
                    },
                    on: {
                      click: function ($event) {
                        return _vm.googleAuthenticatorFeatureToggleSetting(
                          _vm.googleAuthenticatorFeatureToggle === 1
                        )
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("default.edit")))]
                ),
              ]),
              _c("label", [
                _vm._v(" " + _vm._s(_vm.$t("default.status")) + " "),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.googleAuthenticatorFeatureToggle,
                        expression: "googleAuthenticatorFeatureToggle",
                      },
                    ],
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.googleAuthenticatorFeatureToggle = $event.target
                          .multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      },
                    },
                  },
                  _vm._l(_vm.getStatusList, function (list) {
                    return _c(
                      "option",
                      { key: list.id, domProps: { value: list.id } },
                      [_vm._v(_vm._s(_vm.$t(list.name)))]
                    )
                  }),
                  0
                ),
              ]),
            ]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.getUserinfo.permissions.includes(62001),
                  expression: "getUserinfo.permissions.includes(62001)",
                },
              ],
              staticClass: "section",
            },
            [
              _c("div", { staticClass: "header" }, [
                _c("h3", [
                  _vm._v(
                    _vm._s(
                      _vm.$t(`${_vm.i18nPrefix}broadcastMemberWinThreshold`)
                    )
                  ),
                ]),
                _c(
                  "button",
                  {
                    staticClass: "table-btn main-style-btn",
                    attrs: {
                      disabled: !_vm.getUserinfo.permissions.includes(62001),
                    },
                    on: {
                      click: function ($event) {
                        return _vm.updateBroadcastMemberWinThreshold(
                          _vm.broadcastMemberWinThreshold
                        )
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("default.edit")))]
                ),
              ]),
              _c("label", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(`${_vm.i18nPrefix}broadcastMemberWinThreshold`)
                    ) +
                    " "
                ),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.broadcastMemberWinThreshold,
                      expression: "broadcastMemberWinThreshold",
                    },
                  ],
                  attrs: { type: "text" },
                  domProps: { value: _vm.broadcastMemberWinThreshold },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.broadcastMemberWinThreshold = $event.target.value
                    },
                  },
                }),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.checkDomain,
                        expression: "checkDomain",
                      },
                    ],
                  },
                  [_vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}checkDomain`)))]
                ),
              ]),
            ]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.getUserinfo.permissions.includes(62001),
                  expression: "getUserinfo.permissions.includes(62001)",
                },
              ],
              staticClass: "section",
            },
            [
              _c("div", { staticClass: "header" }, [
                _c("h3", [
                  _vm._v(
                    _vm._s(_vm.$t(`${_vm.i18nPrefix}registerSNSFeatureToggle`))
                  ),
                ]),
                _c(
                  "button",
                  {
                    staticClass: "table-btn main-style-btn",
                    attrs: {
                      disabled: !_vm.getUserinfo.permissions.includes(62001),
                    },
                    on: {
                      click: function ($event) {
                        return _vm.updateRegisterSNSFeatureToggle()
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("default.edit")))]
                ),
              ]),
              _c("label", [
                _vm._v(" " + _vm._s(_vm.$t("default.status")) + " "),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.registerSNSFeatureToggle,
                        expression: "registerSNSFeatureToggle",
                      },
                    ],
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.registerSNSFeatureToggle = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      },
                    },
                  },
                  [
                    _c("option", { domProps: { value: true } }, [
                      _vm._v(_vm._s(_vm.$t("default.active"))),
                    ]),
                    _c("option", { domProps: { value: false } }, [
                      _vm._v(_vm._s(_vm.$t("default.inactive"))),
                    ]),
                  ]
                ),
              ]),
            ]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.getUserinfo.permissions.includes(62007),
                  expression: "getUserinfo.permissions.includes(62007)",
                },
              ],
              staticClass: "section",
            },
            [
              _c("div", { staticClass: "header" }, [
                _c("h3", [
                  _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}pointWallet`))),
                ]),
                _c(
                  "button",
                  {
                    staticClass: "table-btn main-style-btn",
                    attrs: {
                      disabled: !_vm.getUserinfo.permissions.includes(62007),
                    },
                    on: {
                      click: function ($event) {
                        return _vm.updatePointWallet()
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("default.edit")))]
                ),
              ]),
              _c("label", [
                _vm._v(" " + _vm._s(_vm.$t("default.status")) + " "),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.pointWallet,
                        expression: "pointWallet",
                      },
                    ],
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.pointWallet = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      },
                    },
                  },
                  [
                    _c("option", { domProps: { value: true } }, [
                      _vm._v(_vm._s(_vm.$t("default.active"))),
                    ]),
                    _c("option", { domProps: { value: false } }, [
                      _vm._v(_vm._s(_vm.$t("default.inactive"))),
                    ]),
                  ]
                ),
              ]),
            ]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.getUserinfo.permissions.includes(62008),
                  expression: "getUserinfo.permissions.includes(62008)",
                },
              ],
              staticClass: "section",
            },
            [
              _c("div", { staticClass: "header" }, [
                _c("h3", [
                  _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}bindingCvsCard`))),
                ]),
                _c(
                  "button",
                  {
                    staticClass: "table-btn main-style-btn",
                    attrs: {
                      disabled: !_vm.getUserinfo.permissions.includes(62008),
                    },
                    on: {
                      click: function ($event) {
                        return _vm.updateBindingCvsCard()
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("default.edit")))]
                ),
              ]),
              _c("label", [
                _vm._v(" " + _vm._s(_vm.$t("default.status")) + " "),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.bindingCvsCard,
                        expression: "bindingCvsCard",
                      },
                    ],
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.bindingCvsCard = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      },
                    },
                  },
                  [
                    _c("option", { domProps: { value: true } }, [
                      _vm._v(_vm._s(_vm.$t("default.active"))),
                    ]),
                    _c("option", { domProps: { value: false } }, [
                      _vm._v(_vm._s(_vm.$t("default.inactive"))),
                    ]),
                  ]
                ),
              ]),
            ]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.getUserinfo.permissions.includes(62006),
                  expression: "getUserinfo.permissions.includes(62006)",
                },
              ],
              staticClass: "section",
            },
            [
              _c("div", { staticClass: "header" }, [
                _c("h3", [
                  _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}downloadPassword`))),
                ]),
                _c(
                  "button",
                  {
                    staticClass: "table-btn main-style-btn",
                    on: {
                      click: function ($event) {
                        return _vm.updateExportReportPasswordFeatureToggle()
                      },
                    },
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("default.edit")))]
                ),
              ]),
              _c("label", [
                _vm._v(" " + _vm._s(_vm.$t("default.status")) + " "),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.exportReportPasswordFeatureToggle,
                        expression: "exportReportPasswordFeatureToggle",
                      },
                    ],
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.exportReportPasswordFeatureToggle = $event.target
                          .multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      },
                    },
                  },
                  [
                    _c("option", { domProps: { value: true } }, [
                      _vm._v(_vm._s(_vm.$t("default.active"))),
                    ]),
                    _c("option", { domProps: { value: false } }, [
                      _vm._v(_vm._s(_vm.$t("default.inactive"))),
                    ]),
                  ]
                ),
              ]),
              _c("label", [
                _vm._v(
                  " " +
                    _vm._s(
                      !_vm.reportFirstPassword
                        ? _vm.$t(`${_vm.i18nPrefix}oldPassword`)
                        : _vm.$t(`${_vm.i18nPrefix}newPassword`)
                    ) +
                    " "
                ),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.oldPassword,
                      expression: "oldPassword",
                    },
                  ],
                  attrs: { type: "password" },
                  domProps: { value: _vm.oldPassword },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.oldPassword = $event.target.value
                    },
                  },
                }),
              ]),
              _c(
                "label",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        this.exportReportPasswordFeatureToggle &&
                        !_vm.reportFirstPassword,
                      expression:
                        "this.exportReportPasswordFeatureToggle && !reportFirstPassword",
                    },
                  ],
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm.$t(`${_vm.i18nPrefix}newPassword`)) + " "
                  ),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.newPassword,
                        expression: "newPassword",
                      },
                    ],
                    attrs: { type: "password" },
                    domProps: { value: _vm.newPassword },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.newPassword = $event.target.value
                      },
                    },
                  }),
                ]
              ),
              _c(
                "label",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        this.exportReportPasswordFeatureToggle &&
                        !_vm.reportFirstPassword,
                      expression:
                        "this.exportReportPasswordFeatureToggle && !reportFirstPassword",
                    },
                  ],
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm.$t(`${_vm.i18nPrefix}newPassword2`)) + " "
                  ),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.newPassword2,
                        expression: "newPassword2",
                      },
                    ],
                    attrs: { type: "password" },
                    domProps: { value: _vm.newPassword2 },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.newPassword2 = $event.target.value
                      },
                    },
                  }),
                ]
              ),
            ]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.getUserinfo.permissions.includes(62011),
                  expression: "getUserinfo.permissions.includes(62011)",
                },
              ],
              staticClass: "section",
            },
            [
              _c("div", { staticClass: "header" }, [
                _c("h3", [
                  _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}welcomeMessage`))),
                ]),
                _c(
                  "button",
                  {
                    staticClass: "table-btn main-style-btn",
                    attrs: {
                      disabled: !_vm.getUserinfo.permissions.includes(62011),
                    },
                    on: {
                      click: function ($event) {
                        return _vm.updateWelcomeMessage()
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("default.edit")))]
                ),
              ]),
              _c("label", [
                _vm._v(" " + _vm._s(_vm.$t("default.status")) + " "),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.welcomeMessage,
                        expression: "welcomeMessage",
                      },
                    ],
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.welcomeMessage = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      },
                    },
                  },
                  [
                    _c("option", { domProps: { value: true } }, [
                      _vm._v(_vm._s(_vm.$t("default.active"))),
                    ]),
                    _c("option", { domProps: { value: false } }, [
                      _vm._v(_vm._s(_vm.$t("default.inactive"))),
                    ]),
                  ]
                ),
              ]),
            ]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.getUserinfo.permissions.includes(62001),
                  expression: "getUserinfo.permissions.includes(62001)",
                },
              ],
              staticClass: "section status",
            },
            [
              _c("div", { staticClass: "header" }, [
                _c("h3", [
                  _vm._v(
                    _vm._s(_vm.$t(`${_vm.i18nPrefix}memberDefaultGateway`))
                  ),
                ]),
                _c(
                  "button",
                  {
                    staticClass: "table-btn main-style-btn",
                    attrs: {
                      disabled: !_vm.getUserinfo.permissions.includes(62001),
                    },
                    on: {
                      click: function ($event) {
                        return _vm.handleUpdate()
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("default.edit")))]
                ),
              ]),
              _c(
                "div",
                { staticClass: "status-div" },
                _vm._l(_vm.memberDefaultGateway, function (list) {
                  return _c(
                    "div",
                    { key: list.paymentGatewayId, staticClass: "toogle" },
                    [
                      _c("label", [
                        _c("span", { staticClass: "span-row" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: list.isSync,
                                expression: "list.isSync",
                              },
                            ],
                            attrs: { type: "checkbox", disabled: !list.enable },
                            domProps: {
                              checked: Array.isArray(list.isSync)
                                ? _vm._i(list.isSync, null) > -1
                                : list.isSync,
                            },
                            on: {
                              change: function ($event) {
                                var $$a = list.isSync,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        list,
                                        "isSync",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        list,
                                        "isSync",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(list, "isSync", $$c)
                                }
                              },
                            },
                          }),
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t(`${_vm.i18nPrefix}isSync`)) +
                              " "
                          ),
                        ]),
                      ]),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("DepositTypeList." + list.paymentGatewayId)
                          ) +
                          " "
                      ),
                      _c("label", [
                        _c(
                          "div",
                          {
                            staticClass: "slide-block",
                            class: { "slide-block-on": list.enable },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "slide",
                                class: { "slide-on": list.enable },
                              },
                              [
                                _c("label", {
                                  attrs: {
                                    for:
                                      "paymentGatewayId" +
                                      list.paymentGatewayId,
                                  },
                                }),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: list.enable,
                                      expression: "list.enable",
                                    },
                                  ],
                                  attrs: {
                                    type: "checkbox",
                                    id:
                                      "paymentGatewayId" +
                                      list.paymentGatewayId,
                                  },
                                  domProps: {
                                    checked: Array.isArray(list.enable)
                                      ? _vm._i(list.enable, null) > -1
                                      : list.enable,
                                  },
                                  on: {
                                    click: function ($event) {
                                      list.isSync = false
                                    },
                                    change: function ($event) {
                                      var $$a = list.enable,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              list,
                                              "enable",
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              list,
                                              "enable",
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(list, "enable", $$c)
                                      }
                                    },
                                  },
                                }),
                              ]
                            ),
                          ]
                        ),
                      ]),
                    ]
                  )
                }),
                0
              ),
            ]
          ),
        ]),
      ]),
      _c(
        "transition",
        { attrs: { name: "fade", mode: "out-in" } },
        [
          _vm.alertMsg
            ? _c("Alert", {
                attrs: { message: _vm.alertMsg },
                on: {
                  toggle: function ($event) {
                    _vm.alertMsg = undefined
                  },
                  doIt: _vm.updateMemberDefaultGateway,
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }