<template>
  <div>
    <div class="container-view">
      <button class="normal-btn search-btn" @click="search()">{{ $t("default.search") }}</button>
      <div class="header">
        <h5>{{ $t('Permission.' + $route.meta.permissionName) }}</h5>
        <div class="float-right">
          <button class="normal-btn download-btn" v-show="false" @click="download">{{ $t('default.download') }}</button>
        </div>
      </div>
      <div class="divider"></div>
      <div class="main">
        <div class="input-block">
          <label class="input-block-multiselect">
            <div class="input-block-check">
              <span style="margin-right: 10px;">{{ $t("default.agent") }}</span>
            </div>
            <multiselect v-model="agentData" label="name" track-by="id" :options="getAgentListAddDefault"
              :allow-empty="false" :preselectFirst="true" :clear-on-select="false" :preserve-search="true"
              :custom-label="GLOBAL.nameWithLang"></multiselect>
          </label>
          <label>
            {{ $t("default.member") }}
            <input type="text" v-model="searchForm.memberAccount" />
          </label>
          <label>
            {{ $t("default.startTime") }}
            <date-picker v-model="startTime" format="YYYY-MM-DD" value-type="format" type="datetime"
              :disabled-date="GLOBAL.ontSeason"></date-picker>
          </label>
          <label>
            {{ $t("default.endTime") }}
            <date-picker v-model="endTime" format="YYYY-MM-DD" value-type="format" type="datetime"
              :disabled-date="GLOBAL.ontSeason"></date-picker>
          </label>
        </div>
        <div class="advance-block">
          <QuickSearch ref="quickSearch" @changeTime="changeTime" :controlType="true"></QuickSearch>
          <button class="search-pc-btn main-style-btn px-5" @click="search()">{{ $t("default.search") }}</button>
        </div>
      </div>
    </div>
    <div class="container-view">
      <div class="total-block">
        <div class="total-block-item shadow-sm px-3 py-4 bg-white rounded mb-1 mx-1">
          <div class="total-block-item-left">{{ $t(`${this.i18nPrefix}entityCount`) }}:</div>
          <div class="total-block-item-right">{{ digital.format(total.entityCount) }}</div>
        </div>
        <div class="total-block-item shadow-sm px-3 py-4 bg-white rounded mb-1 mx-1">
          <div class="total-block-item-left">{{ $t(`${this.i18nPrefix}discountAmount`) }}:</div>
          <div class="total-block-item-right">{{ digital.format(total.discountAmount) }}</div>
        </div>
      </div>
      <div class="table-block">
        <TreeTable ref="treeTable" :list="allData" :column="tableColumn" :bookmarkColumn="bookmarkColumn"
          :notShowChildren="true" @sort="dataSort" @bookmark="bookmark">
        </TreeTable>
        <serverPagination v-if="allData" :page="page" @ServerPageUtils="searchPage" @changeTake="changeTake">
        </serverPagination>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getList,
  getTotal,
  selectMenu
} from "@/api/statistical-report/campaignDiscountStatistical";
import { searchModel } from "./model";
import { transTodayDate, joinT, transNow } from "@/utils/transDateUtils";
import { mapGetters } from "vuex";
import QuickSearch from "@/layout/components/QuickSearch";
import serverPagination from "@/layout/components/serverPagination";
import handleServerPageUtils from "@/layout/mixins/handleServerPageUtils";
import TreeTable from "@/layout/components/TreeTable";
import Multiselect from "vue-multiselect";
import { download } from "@/utils/download";

export default {
  name: "CampaignDiscountStatistical",
  data() {
    return {
      searchForm: _.cloneDeep(searchModel),
      startTime: transTodayDate(),
      endTime: transTodayDate(),
      agentData: [],
      discountList: [],
      i18nPrefix: "statistical-report.campaignDiscountStatistical.",
      total: {
        discountCount: 0,
        discountAmount: 0,
        entityCount: 0
      }
    };
  },
  watch: {},
  components: {
    QuickSearch,
    serverPagination,
    TreeTable,
    Multiselect
  },
  computed: {
    ...mapGetters(["getAgentListAddDefault", "getDiscountTypeListAddDefault"]),
    tableColumn() {
      return [
        {
          key: "name",
          name: this.$t("default.agent"),
          tableHeadAlign: true,
          align: "left"
        },
        {
          key: "totalEntityCount",
          name: this.$t(`${this.i18nPrefix}totalEntityCount`)
        },
        {
          key: "totalDiscountCount",
          name: this.$t(`${this.i18nPrefix}totalDiscountCount`)
        },
        {
          key: "totalDiscountAmount",
          name: this.$t(`${this.i18nPrefix}totalDiscountAmount`)
        },
        {
          key: "selfEntityCount",
          name: this.$t(`${this.i18nPrefix}selfEntityCount`)
        },
        {
          key: "selfDiscountCount",
          name: this.$t(`${this.i18nPrefix}selfDiscountCount`)
        },
        {
          key: "selfDiscountAmount",
          name: this.$t(`${this.i18nPrefix}selfDiscountAmount`)
        }
      ];
    },
    bookmarkColumn() {
      return [{
        key: this.searchForm.type,
        name: "bookmark"
      }].concat(this.discountList)
    },
  },
  mixins: [handleServerPageUtils],
  created() {
    selectMenu().then(res => {
      this.discountList = res.data
        .map(data => { return { key: data.id, name: "CampaignDiscountStatistical." + data.name } })
    })
  },
  methods: {
    async search() {
      //時間參數
      this.searchForm.startTime = this.startTime
        ? joinT(this.startTime + this.GLOBAL.startHour + this.GLOBAL.timeZone)
        : undefined;
      this.searchForm.endTime = this.endTime
        ? joinT(this.endTime + this.GLOBAL.endHour + this.GLOBAL.timeZone)
        : undefined;

      this.searchForm.agentId = this.agentData.id;
      this.searchPage();

      getTotal(this.searchForm).then(res => {
        this.total = {
          discountCount: res.data.totalDiscountCount,
          discountAmount: res.data.totalDiscountAmount,
          entityCount: res.data.totalEntityCount
        };
      });
    },
    searchPage(page = false) {
      this.searchForm.page.take = this.take * 1;
      this.searchForm.page.skip = this.take * (page ? page - 1 : 0);

      getList(this.searchForm).then(res => {
        this.allData = res.data?.list;
        this.page = res.data.page;
      });
    },
    changeTime(time) {
      this.startTime = time.startTime;
      this.endTime = time.endTime;
      this.search();
    },
    bookmark(data, agentId = false) {
      if (agentId) this.searchForm.agentId = agentId;
      this.allData = undefined;
      this.searchForm.type = data;
      this.search();
    },
    download() {
      //時間參數
      this.searchForm.startTime = this.startTime
        ? joinT(this.startTime + this.GLOBAL.startHour + this.GLOBAL.timeZone)
        : undefined;
      this.searchForm.endTime = this.endTime
        ? joinT(this.endTime + this.GLOBAL.endHour + this.GLOBAL.timeZone)
        : undefined;

      this.searchForm.agentId = this.agentData.id;
      let body = { ...this.searchForm };
      body.page.take = 0;
      body.page.skip = 0;

      getExcel(body).then(res =>
        download(res, transNow('onlyNum') + "-AgentDiscountStatistical.xlsx")
      );
    }
  }
};
</script>
