<template>
  <div>
    <div class="container-view">
      <div class="header">
        <h5>{{ $t("title." + $route.meta.title) }}</h5>
        <div class="float-right">
          <button
            class="create-btn main-style-btn"
            @click="handleDialog(undefined, 'Create')"
            :disabled="
              !getUserinfo.permissions.includes(60201)
            "
          >
            {{ $t("default.create") }}
          </button>
        </div>
      </div>
      <main>
        <div class="table-block">
          <table>
            <thead>
              <tr>
                <th></th>
                <th>{{ $t(`${i18nPrefix}type`) }}</th>
                <th>{{ $t(`${i18nPrefix}title`) }}</th>
                <th>{{ $t(`${i18nPrefix}announcement(zh-tw)`) }}</th>
                <th>{{ $t(`${i18nPrefix}sort`) }}</th>
                <th>{{ $t(`${i18nPrefix}active`) }}</th>
                <th>{{ $t(`${i18nPrefix}isBroadcast`) }}</th>
                <th>{{ $t(`${i18nPrefix}createDate`) }}</th>
                <th>{{ $t(`${i18nPrefix}creator`) }}</th>
                <th>{{ $t(`${i18nPrefix}updateDate`) }}</th>
                <th>{{ $t(`${i18nPrefix}editor`) }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="list of tableList" :key="list.createTime">
                <td>
                  <button
                    class="normal-btn mr-1"
                    @click="handleDialog(list, 'Edit')"
                    :disabled="!getUserinfo.permissions.includes(60202)"
                  >
                    {{ $t("default.edit") }}
                  </button>
                  <button
                    class="delete-btn"
                    @click="handleDelete(list)"
                    :disabled="!getUserinfo.permissions.includes(60203)"
                  >
                    {{ $t("default.delete") }}
                  </button>
                </td>
                <td>
                  {{
                    GLOBAL.i18nCheckName(getAnnouncementTypes.find((data) => data.id === list.announcementType))
                  }}
                </td>
                <td>{{ (list.title.length > 10) ? list.title.substring(0,10) + " ..." : list.title }}</td>
                <td>{{ (list.content.length > 10) ? list.content.substring(0,10) + " ..." : list.content }}</td>
                <td>{{ list.sortId }}</td>
                <td>{{ list.status === 1 ? $t("default.active") : $t("default.inactive") }}</td>
                <td>{{ list.isBroadcast ? $t("default.active") : $t("default.inactive") }}</td>
                <td>{{ list.createTime }}</td>
                <td>{{ list.createAccount }}</td>
                <td>{{ list.updateTime }}</td>
                <td>{{ list.updateAccount }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </main>

      <Pagination
        v-if="totalCount > 0"
        :totalCount="totalCount"
        @handlePage="handlePage"
      />

      <component
        :is="currDialog"
        :announcementTypes="getAnnouncementTypes"
        v-bind="dialogData"
        @toggle="currDialog = undefined"
        @create="create"
        @edit="edit"
      ></component>

      <!-- 確認彈出視窗 -->
      <transition name="fade" mode="out-in">
        <Confirm
          v-if="getShowConfirm"
          @toggle="TOGGLE_CONFIRM"
          @delete="deleteAnnouncement"
        />
      </transition>
    </div>
  </div>
</template>

<script>
import Pagination from "@/layout/components/Pagination";
import handlePageUtils from "@/layout/mixins/handlePageUtils";
import handleDialog from "@/layout/mixins/handleDialog";
import {
  announcementList,
  createAnnouncement,
  editAnnouncement,
  deleteAnnouncement,
} from "@/api/features/announcement";
import Create from "./dialog/create.vue";
import Edit from "./dialog/edit.vue";
import Confirm from "@/layout/components/Confirm";
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "Announcement",
  data() {
    return {
      i18nPrefix: "features.announcement."
    };
  },
  components: {
    Create,
    Edit,
    Confirm,
    Pagination,
  },
  mixins: [handlePageUtils, handleDialog],
  computed: {
    ...mapGetters(["getShowConfirm", "getUserinfo", "getAnnouncementTypes"]),
  },
  created() {
    this.getAnnouncementList();
  },
  methods: {
    ...mapMutations({
      TOGGLE_CONFIRM: "confirm/TOGGLE_CONFIRM",
      RESET_CONFIRM: "confirm/RESET_CONFIRM",
    }),
    getAnnouncementList() {
      announcementList().then((res) => {
        this.allData = res.data;
      });
      this.RESET_CONFIRM();
      this.RESET_DIALOG();
    },
    create(data) {
      data.sortId *= 1;
      createAnnouncement(data).then((res) => {
        if(res) this.getAnnouncementList();
      });
    },
    edit(data) {
      data.sortId *= 1;
      editAnnouncement(data).then((res) => {
        if(res) this.getAnnouncementList();
      });
    },
    handleDelete(data) {
      this.TOGGLE_CONFIRM();
      this.deleteData = data;
    },
    deleteAnnouncement() {
      deleteAnnouncement({ id: this.deleteData.id }).then((res) => {
        if(res) this.getAnnouncementList();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>