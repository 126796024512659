var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "dialog-mask",
      on: {
        mousedown: function ($event) {
          if ($event.target !== $event.currentTarget) return null
          return _vm.$emit("toggle")
        },
      },
    },
    [
      _c("div", { staticClass: "dialog-container" }, [
        _c("div", { staticClass: "dialog-block" }, [
          _c("div", { staticClass: "item" }, [
            _c("p", [_vm._v(_vm._s(_vm.$t("default.total_rebate")))]),
            _c("p", [_vm._v(_vm._s(_vm.profitDetail.totalRebate))]),
          ]),
          _c("div", { staticClass: "item" }, [
            _c("p", [_vm._v(_vm._s(_vm.$t("default.total_withdrawl")))]),
            _c("p", [_vm._v(_vm._s(_vm.profitDetail.totalWithdrawl))]),
          ]),
          _c("div", { staticClass: "item" }, [
            _c("p", [_vm._v(_vm._s(_vm.$t("default.total_profit")))]),
            _c("p", [
              _vm._v(
                _vm._s(
                  _vm.profitDetail.totalRebate - _vm.profitDetail.totalWithdrawl
                )
              ),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }