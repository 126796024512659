<template>
  <div>
    <div class="container-view">
      <button class="search-btn main-style-btn" @click="search()">{{ $t("default.search") }}</button>
      <div class="header">
        <h5>{{ $t("title." + $route.meta.title) }}</h5>
      </div>
      <div class="divider"></div>
    </div>
    <div class="container-view">
      <div class="table-block">
        <table>
          <thead>
            <tr>
              <th></th>
              <th>{{ $t("default.processAccount") }}</th>
              <th>{{ $t("default.processTime") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="!allData || !allData.length">
              <td colspan="18">{{ $t("default.noData") }}</td>
            </tr>
            <tr v-for="(list, index) of allData" :key="index">
              <td>
                <button class="table-btn main-style-btn" @click="handleDialog(list, 'Count')">{{ $t("default.detail")
                  }}</button>
              </td>
              <td>{{ list.userAccount }}</td>
              <td>{{ list.createTime }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <serverPagination v-if="allData" :page="page" @ServerPageUtils="search" @changeTake="changeTake" />
    </div>

    <transition name="fade" mode="out-in">
      <component :i18nPrefix="i18nPrefix" :is="currDialog" v-bind="dialogData" @toggle="currDialog = undefined"></component>
    </transition>
  </div>
</template>

<script>
import {
  getBatchCreateMember,
} from "@/api/features/batchCreateMember";
import { searchModel } from "./model";
import Count from "./dialog/count";
import serverPagination from "@/layout/components/serverPagination";
import handleServerPageUtils from "@/layout/mixins/handleServerPageUtils";
import handleDialog from "@/layout/mixins/handleDialog";

export default {
  name: "Search",
  data() {
    return {
      searchForm: _.cloneDeep(searchModel),
      i18nPrefix: "member.member-deposit-store."
    };
  },
  components: {
    Count,
    serverPagination,
  },
  mixins: [handleServerPageUtils, handleDialog],
  computed: {
  },
  async created() {
    this.search();
  },
  methods: {
    search(page = false) {
      this.searchForm.page.take = this.take * 1;
      this.searchForm.page.skip = this.take * (page ? page - 1 : 0);
      getBatchCreateMember(this.searchForm).then(res => {
        this.closeDialog();
        this.allData = res.data.list;
        this.page = res.data.page;
      });
    },
  }
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss" scoped></style>
