var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "d-flex form-column align-items-center h-full",
      staticStyle: { width: "90%" },
    },
    [
      _c("div", { staticClass: "table-block-white-v2" }, [
        _c("table", [
          _c("thead", [
            _c("tr", [
              _c(
                "th",
                { staticStyle: { width: "100%" }, attrs: { colspan: "3" } },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$t(`${_vm.i18nPrefix}dialog.detail.memberRegistry`)
                    )
                  ),
                ]
              ),
            ]),
          ]),
          _c("tbody", [
            _c("tr", [
              _c("td", [
                _vm._v(
                  " " + _vm._s(_vm.$t(`${_vm.i18nPrefix}createFrom`)) + " "
                ),
              ]),
              _c("td", [_vm._v(" " + _vm._s(_vm.list.creator) + " ")]),
              _c("td"),
            ]),
            _c("tr", [
              _c("td", [
                _vm._v(
                  " " + _vm._s(_vm.$t(`${_vm.i18nPrefix}agentFrom`)) + " "
                ),
              ]),
              _c("td", [_vm._v(" " + _vm._s(_vm.list.agentAccount) + " ")]),
              _c("td", [
                _c(
                  "button",
                  {
                    staticClass: "edit-btn",
                    attrs: { disabled: _vm.editChooice !== undefined },
                    on: {
                      click: function ($event) {
                        return _vm.handleDialog(_vm.memberData, "Agent")
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("default.edit")))]
                ),
              ]),
            ]),
            _c("tr", [
              _c("td", [
                _vm._v(" " + _vm._s(_vm.$t("default.createTime")) + " "),
              ]),
              _c("td", [_vm._v(" " + _vm._s(_vm.list.createTime) + " ")]),
              _c("td"),
            ]),
            _c("tr", [
              _c("td", [
                _vm._v(
                  " " + _vm._s(_vm.$t(`${_vm.i18nPrefix}lastLoginTime`)) + " "
                ),
              ]),
              _c("td", [_vm._v(" " + _vm._s(_vm.list.lastLoginTime) + " ")]),
              _c("td"),
            ]),
            _c("tr", [
              _c("td", [
                _vm._v(
                  " " + _vm._s(_vm.$t(`${_vm.i18nPrefix}lastEventTime`)) + " "
                ),
              ]),
              _c("td", [_vm._v(" " + _vm._s(_vm.list.lastEventTime) + " ")]),
              _c("td"),
            ]),
            _c("tr", [
              _c("td", [
                _vm._v(" " + _vm._s(_vm.$t(`${_vm.i18nPrefix}ip`)) + " "),
              ]),
              _c("td", [_vm._v(" " + _vm._s(_vm.list.lastLoginIp) + " ")]),
              _c("td"),
            ]),
            _c("tr", [
              _c("td", [
                _vm._v(" " + _vm._s(_vm.$t(`${_vm.i18nPrefix}history`)) + " "),
              ]),
              _c("td", [
                _c(
                  "button",
                  {
                    staticClass: "table-btn main-style-btn mt-1",
                    on: {
                      click: function ($event) {
                        return _vm.handleDialog(_vm.memberData, "LoginList")
                      },
                    },
                  },
                  [
                    _vm._v(
                      " " + _vm._s(_vm.$t(`${_vm.i18nPrefix}loginList`)) + " "
                    ),
                  ]
                ),
              ]),
              _c("td"),
            ]),
          ]),
        ]),
      ]),
      _c(
        "transition",
        { attrs: { name: "fade", mode: "out-in" } },
        [
          _c(
            _vm.currDialog,
            _vm._b(
              {
                tag: "component",
                attrs: { i18nPrefix: _vm.i18nPrefix },
                on: {
                  agent: _vm.agent,
                  toggle: function ($event) {
                    _vm.currDialog = undefined
                  },
                },
              },
              "component",
              _vm.dialogData,
              false
            )
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }