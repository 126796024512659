<template>
  <div class="dialog-mask" @mousedown.self="$emit('toggle')">
    <div class="dialog-container">
      <div class="dialog-block">
        <div class="item">
            <p>{{ $t("default.total_rebate") }}</p>
            <p>{{ profitDetail.totalRebate }}</p>
        </div>
        <div class="item">
            <p>{{ $t("default.total_withdrawl") }}</p>
            <p>{{ profitDetail.totalWithdrawl }}</p>
        </div>
        <div class="item">
            <p>{{ $t("default.total_profit") }}</p>
            <p>{{ profitDetail.totalRebate - profitDetail.totalWithdrawl }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "ProfitDetail",
  props: ["i18nPrefix", "profitDetail"],
  data() {
    return {};
  },
  mounted() {
    console.log(this.profitDetail);
  },
};
</script>
<style lang="scss" scoped>
.item {
    display: flex;
    width: 200px;
    border-bottom: 1px solid gray;
    margin-bottom: 10px;
    &:last-child {
        border: none;
        margin-bottom: 0;
    }
    p {
        width: 50%;
        text-align: center;
        margin-bottom: 4px;
        font-size: 16px;
        font-weight: bold;
        padding: 10px 0;
    }
}
</style>
