<template>
  <div class="dialog-mask" @mousedown.self="$emit('toggle')">
    <div class="dialog-container">
      <div class="dialog-block">
        <div class="header">
          <h4>{{ $t("Permission." + $route.meta.permissionName) }}</h4>
        </div>
        <div class="divider"></div>
        <main>
          <div class="input-block">
            <label>
              {{ $t("default.member") }}
              <input type="text" v-model="account" disabled />
            </label>
            <label>
              {{ $t("default.createTime") }}
              <input type="text" v-model="createTime" disabled />
            </label>
          </div>
          <div class="advance-block">
            <button class="normal-btn" @click.self="edit(2)" v-if="status !== 2">{{ $t("default.accept") }}</button>
            <button class="delete-btn" @click.self="edit(3)" v-if="status !== 3">{{ $t("default.reject") }}</button>
           </div>
        </main>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "Audit",
  props: [
    "id",
    "status",
    "account",
    "createTime",
  ],
  data() {
    return {
      editModel: {
        id: this.id,
        status: undefined,
      },
    }
  },
  components: {
  },
  methods: {
    edit(Status) {
      this.editModel.status = Status;
      this.$emit("audit", this.editModel);
    },
  }
};
</script>
<style lang="scss" scoped>
.dialog-block {
  .input-block {
    label {
      width: 60%;
      margin: 15px auto;
    }
    textarea {
      height: 100px;
    }
  }
}
</style>