<template>
  <div>
    <div class="container-view">
      <button class="search-btn main-style-btn" @click="search()">{{ $t("default.search") }}</button>
      <div class="header">
        <h5>{{ $t("title." + $route.meta.title) }}</h5>
      </div>
      <div class="main">
        <div class="input-block">
          <label>
            <div class="input-block-check">
              <span style="margin-right: 10px;">{{ $t("default.member") }}</span>
              <input id="isFuzzySearch" type="checkbox" v-model="searchForm.isFuzzySearch" />
              <label for="isFuzzySearch">{{ $t("default.isFuzzySearch") }}</label>
            </div>
            <input type="text" v-model="searchForm.memberAccount" />
          </label>
          <label>
            {{ $t(`${i18nPrefix}fromPlatform`) }}
            <select v-model="searchForm.fromPlatform" @change="from(searchForm.fromPlatform)">
              <option v-for="list of gamePlatformList" :key="list.id" :value="list.id">
                {{ $t(list.name) }}
              </option>
            </select>
          </label>
          <label>
            {{ $t(`${i18nPrefix}toPlatform`) }}
            <select v-model="searchForm.toPlatform" @change="to(searchForm.toPlatform)">
              <option v-for="list of gamePlatformList" :key="list.id" :value="list.id">
                {{ $t(list.name) }}
              </option>
            </select>
          </label>
          <label>
            {{ $t(`${i18nPrefix}filterZeroValue`) }}&lt;=0
            <select v-model="searchForm.filterZeroValue">
              <option :value="true">{{ $t("default.yes") }}</option>
              <option :value="false">{{ $t("default.no") }}</option>
            </select>
          </label>
          <label>
            {{ $t(`${i18nPrefix}startTime`) }}
            <date-picker v-model="startTime" format="YYYY-MM-DD HH:mm" value-type="format"
              type="datetime"></date-picker>
          </label>
          <label>
            {{ $t(`${i18nPrefix}endTime`) }}
            <date-picker v-model="endTime" format="YYYY-MM-DD HH:mm" value-type="format" type="datetime"></date-picker>
          </label>
        </div>
        <div class="advance-block">
          <QuickSearch @changeTime="changeTime"></QuickSearch>
          <button class="search-pc-btn main-style-btn px-5" @click="search()">{{ $t("default.search") }}</button>
        </div>
      </div>
    </div>

    <div class="container-view">
      <div class="table-block">
        <table>
          <thead>
            <tr>
              <th v-if="$store.getters.getUserinfo.koreanStyleInterface">{{ $t("default.sort") }}</th>
              <th>{{ $t(`${i18nPrefix}transferTime`) }}</th>
              <th>{{ $t("default.member") }}</th>
              <th v-if="$store.getters.getUserinfo.koreanStyleInterface">{{ $t("default.nickName") }}</th>
              <th>{{ $t(`${i18nPrefix}from`) }}</th>
              <th v-if="!$store.getters.getUserinfo.koreanStyleInterface">{{ $t(`${i18nPrefix}multiples`) }}</th>
              <th>{{ $t(`${i18nPrefix}fromPreTradeBalance`) }}</th>
              <th>{{ $t(`${i18nPrefix}fromAfterTradeBalance`) }}</th>
              <th>{{ $t("default.amount") }}</th>
              <th>{{ $t(`${i18nPrefix}to`) }}</th>
              <th>{{ $t(`${i18nPrefix}toPreTradeBalance`) }}</th>
              <th>{{ $t(`${i18nPrefix}toAfterTradeBalance`) }}</th>
              <th>{{ $t("default.status") }}</th>
              <th>{{ $t(`${i18nPrefix}errorCode`) }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="!allData || !allData.length">
              <td colspan="12">{{ $t("default.noData") }}</td>
            </tr>
            <tr v-for="list of allData" :key="list.id">
              <td v-if="$store.getters.getUserinfo.koreanStyleInterface">{{ list.id }}</td>
              <td>{{ list.transferTime }}</td>
              <td>
                <span class="memberSpan"
                  @click="handleDialog({ list: { id: list.member.id, account: list.member.account, toggle: true } }, 'Detail')">{{
                  list.member.account }}
                </span>
              </td>
              <td v-if="$store.getters.getUserinfo.koreanStyleInterface">{{ list.member.nickName }}</td>
              <td>{{ $t("GamePlatform." + list.fromPlatform) }}</td>
              <td v-if="!$store.getters.getUserinfo.koreanStyleInterface">{{ list.multiples }}</td>
              <td class="start">{{ list.fromPreTradeBalance }}</td>
              <td class="end">{{ list.fromAfterTradeBalance }}</td>
              <td :style="{ color: list.amount >= 0 ? 'green' : 'red' }">{{ list.amount }}</td>
              <td>{{ $t("GamePlatform." + list.toPlatform) }}</td>
              <td class="start">{{ list.toPreTradeBalance }}</td>
              <td class="end">{{ list.toAfterTradeBalance }}</td>
              <td>{{ GLOBAL.i18nCheckName(orderStatusList.find((data) => data.id === list.orderStatus)) }}</td>
              <td>{{ GLOBAL.i18nCheckName(geterrorCodeList.find((data) => data.id === list.errorCode)) }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <serverPagination v-if="allData" :page="page" @ServerPageUtils="search" @changeTake="changeTake" />
    </div>
    <transition name="fade" mode="out-in">
      <component :is="currDialog" v-bind="dialogData" @toggle="currDialog = undefined"></component>
    </transition>
  </div>
</template>

<script>
import {
  gameTransferReportList,
  selectMenu,
} from "@/api/report/member-transfer";
import { searchModel } from "./model";
import { mapGetters } from "vuex";
import serverPagination from "@/layout/components/serverPagination";
import handleServerPageUtils from "@/layout/mixins/handleServerPageUtils";
import { transToday, joinT } from "@/utils/transDateUtils";
import QuickSearch from "@/layout/components/QuickSearch";
import handleDialog from "@/layout/mixins/handleDialog";
import Detail from "@/views/member/member-management/dialog/detail";


export default {
  name: "MemberTransfer",
  data() {
    return {
      searchForm: _.cloneDeep(searchModel),
      startTime: transToday("from"),
      endTime: transToday("to"),
      gamePlatformList: undefined,
      orderStatusList: [
        {
          id: 1,
          name: "report.member-transfer.undone"
        },
        {
          id: 2,
          name: "report.member-transfer.success"
        },
        {
          id: 3,
          name: "report.member-transfer.fail"
        }
      ],
      i18nPrefix: "report.member-transfer."
    };
  },
  components: {
    Detail,
    serverPagination,
    QuickSearch,

  },
  mixins: [handleServerPageUtils, handleDialog],
  computed: {
    ...mapGetters(["getDefaultSelect", "geterrorCodeList"]),
  },
  created() {
    selectMenu().then((res) => {
      this.gamePlatformList = [this.getDefaultSelect,
      ...res.data.platforms.map(data => { return { id: data.id, name: "GamePlatform." + data.name } })];
    });
  },
  methods: {
    search(page = false) {
      this.searchForm.startTime = this.startTime ? joinT(this.startTime + this.GLOBAL.startSecond + this.GLOBAL.timeZone) : undefined;
      this.searchForm.endTime = this.endTime ? joinT(this.endTime + this.GLOBAL.endSecond + this.GLOBAL.timeZone) : undefined;

      this.searchForm.page.take = this.take * 1;
      this.searchForm.page.skip = this.take * (page ? page - 1 : 0);
      gameTransferReportList(this.searchForm).then((res) => {
        this.allData = res.data.list;
        this.page = res.data.page;
      });
    },
    from(e) {
      if (e !== 999 && e !== 0) {
        this.searchForm.toPlatform = 999;
      }
    },
    to(e) {
      if (e !== 999 && e !== 0) {
        this.searchForm.fromPlatform = 999;
      }
    },
    changeTime(time) {
      this.startTime = time.startTime;
      this.endTime = time.endTime;
      this.search();
    }
  },
};
</script>

<style lang="scss" scoped>
.start {
  background-color: #EDFDD8;
}

.end {
  background-color: #d8eefd;
}
</style>