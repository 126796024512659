<template>
  <div>
    <div class="agent-block">
      <div class="container-view">
        <button class="search-btn main-style-btn" @click="passAgentId(agentData.id)">{{ $t("default.search") }}</button>
        <div class="header">
          <h5>{{ $t("title." + $route.meta.title) }}</h5>
          <div class="float-right">
            <button class="confirm-btn main-style-btn" @click="handleDialog({ agentId: agentId }, 'Create')" :disabled="!getUserinfo.permissions.includes(20101)
              ">{{ $t("default.create") + " " + $t("default.agent") }}</button>
          </div>
        </div>
        <div class="main advance-block">
          <div class="input-block">
            <div class="input-block-multiselect">
              {{ $t("default.agent") }}
              <multiselect v-model="agentData" label="name" track-by="id" :options="getAgentListAddDefault"
                :allow-empty="false" :preselectFirst="true" :clear-on-select="false" :preserve-search="true"
                :custom-label="GLOBAL.nameWithLang"></multiselect>
            </div>
          </div>
          <button class="search-pc-btn main-style-btn px-5" @click="passAgentId(agentData.id)">{{ $t("default.search") }}</button>
        </div>
      </div>
      <div class="container-view">
        <main>
          <div class="total-block-v2">
            <div class="total-block-v2-item">
              <div class="total-block-v2-item-left">{{ $t("default.totalDataCount") }}:</div>
              <div class="total-block-v2-item-right">{{ page.total }}</div>
            </div>
          </div>
          <div class="table-block">
            <table>
              <thead>
                <tr>
                  <th>
                    <button class="normal-btn" v-show="agentId !== getUserinfo.parentId"
                      @click="passAgentId(backData)">{{ $t(`${i18nPrefix}back`) }}</button>
                    <button class="normal-btn" v-if="haveMember" @click="handleDialog({ id: agentId }, 'Member')">{{
                      $t("default.member") }}</button>
                  </th>
                  <th>{{ $t(`${i18nPrefix}upperAgent`) }}</th>
                  <th>{{ $t(`${i18nPrefix}level`) }}</th>
                  <th>{{ $t(`${i18nPrefix}account`) }}</th>
                  <th>{{ $t(`${i18nPrefix}name`) }}</th>
                  <th>{{ $t(`${i18nPrefix}promotionLink`) }}</th>
                  <th>{{ $t(`${i18nPrefix}domain`) }}</th>
                  <th>{{ $t(`${i18nPrefix}parameter`) }}</th>
                  <th>{{ $t(`${i18nPrefix}balance`) }}</th>
                  <th>{{ $t(`${i18nPrefix}createTime`) }}</th>
                  <th>{{ $t(`${i18nPrefix}status`) }}</th>
                  <th>{{ $t("default.processAccount") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="!allData || allData.length === 0">
                  <td colspan="12">{{ $t("default.noData") }}</td>
                </tr>
                <tr v-for="list of allData" :key="list.id">
                  <td>
                    <button class="table-btn main-style-btn" @click="handleDialog(list, 'Edit')" 
                    :disabled="!getUserinfo.permissions.includes(20102)">
                      {{ $t("default.edit") }}</button>
                  </td>
                  <td>
                    {{ list.parentLevelNumber !== -1
                      ? $t("default." + (list.parentLevelNumber ? `levelNumber` : "mainAgent"), {
                        input:
                          list.parentLevelNumber
                      }) + " / " + list.parentName
                      : $t("default.noData") }}
                  </td>
                  <td>{{ $t("default." + (list.levelNumber ? `levelNumber` : "mainAgent"), { input: list.levelNumber }
                  ) }}</td>
                  <td class="agent" @click="passAgentId(list.id)">{{ list.account }}</td>
                  <td>{{ list.name }}</td>
                  <td>{{ list.promotionLink }}</td>
                  <td>
                    <div class="d-flex justify-content-center">
                      {{ list.domain }}
                      <div class="d-flex ml-2">
                        <div class="table-icon mr-2" @click="copyDomain('https://' + list.domain)">
                          <img src="@/assets/icon/copy.svg">
                        </div>
                        <div class="table-icon" @click="downloadQRCode(list.subDomain)">
                          <img src="@/assets/icon/download.svg">
                        </div>
                      </div>
                    </div>

                    <qrcode v-show="false" background="#fff" :cls="'qrcode' + list.subDomain"
                      :value="'https://' + list.domain"></qrcode>
                    <template v-show="!list.isGeneralAgent">
                      <div class="d-flex justify-content-center">
                        {{ agentDamain + '?code=' + list.account }}
                        <div class="d-flex ml-2">
                          <div class="table-icon mr-2" @click="copyDomain('https://' + agentDamain + '?code=' + list.account)">
                            <img src="@/assets/icon/copy.svg">
                          </div>
                          <div class="table-icon" @click="downloadQRCode(list.subDomain)">
                            <img src="@/assets/icon/download.svg">
                          </div>
                        </div>
                      </div>
                      <qrcode v-show="false" background="#fff" :cls="'qrcode' + list.subDomain"
                        :value="'https://' + agentDamain + '?code=' + list.account"></qrcode>
                    </template>
                  </td>
                  <td>
                    <div class="d-flex justify-content-center">
                      {{ '?code=' + list.account }}
                      <div class="table-icon ml-2" @click="copyDomain('?code=' + list.account)">
                        <img src="@/assets/icon/copy.svg">
                      </div>
                    </div>
                  </td>
                  <td>{{ list.balance }}</td>
                  <td>{{ list.createTime }}</td>
                  <td>{{ list.status === 1 ? $t("default.yes") : $t("default.no") }}</td>
                  <td>
                    <button @click="getProcess(list.id)" class="table-btn main-style-btn">{{ $t("default.processAccount") }}</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <serverPagination v-if="allData" :page="page" @ServerPageUtils="getAgentList" @changeTake="changeTake" />
        </main>
      </div>
    </div>
    <transition name="fade" mode="out-in">
      <component :is="currDialog" :agentId="agentId" :agentDamain="agentDamain" :permissionsData="permissionsData"
        :i18nPrefix="i18nPrefix" v-bind="dialogData" @toggle="closeDialog()" @reload="getAgentList"
        @create="create" @edit="edit"></component>
    </transition>
    <Process v-if="processData" :processTitle="processTitle" :processData="processData" @toggle="processData = undefined">
      <template v-slot:oldValue_Status="props">{{ props.Status === 1 ? $t("default.yes") : $t("default.no") }}</template>
      <template v-slot:newValue_Status="props">{{ props.Status === 1 ? $t("default.yes") : $t("default.no") }}</template>
      <template v-slot:oldValue_CommissionCalculationCycle="props">
        {{ GLOBAL.i18nCheckName(
          GLOBAL.settlement.find(
            (data) => props.CommissionCalculationCycle === data.id
          )) }}
      </template>
      <template v-slot:newValue_CommissionCalculationCycle="props">
        {{ GLOBAL.i18nCheckName(
          GLOBAL.settlement.find(
            (data) => props.CommissionCalculationCycle === data.id
          )) }}
      </template>
    </Process>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Create from "./dialog/create";
import Edit from "./dialog/edit";
import Member from "./dialog/member";
import handleDialog from "@/layout/mixins/handleDialog";
import serverPagination from "@/layout/components/serverPagination";
import handleServerPageUtils from "@/layout/mixins/handleServerPageUtils";
import {
  editAgent,
  createAgent,
  getSelectMenu,
  getParentPermissions,
  getAgentProcess,
  getList
} from "@/api/agent-management/agent";
import Multiselect from "vue-multiselect";
import Message from "@/layout/components/Message";
import Qrcode from "v-qrcode/src/index";
import Process from "@/layout/components/Process";
import { downloadCanvas } from "@/utils/download";

export default {
  name: "AgentManagement",
  data() {
    return {
      agentList: undefined,
      haveMember: undefined,
      dialogData: undefined,
      agentUserName: undefined,
      agentId: undefined,
      backData: {},
      agentData: undefined,
      agentDamain: undefined,
      permissionsList: [],
      permissionsData: {
        permissionsTitle: [],
        firstData: [],
        secData: []
      },
      copyText: "",
      processData: undefined,
      i18nPrefix: "agent.agent-management."
    };
  },
  components: {
    Create,
    Edit,
    Multiselect,
    serverPagination,
    Qrcode,
    Process,
    // TreeNode,
    Member
  },
  mixins: [handleDialog, handleServerPageUtils],
  computed: {
    ...mapGetters([
      "userName",
      "getUserinfo",
      "getAgentListAddDefault",
      "getGamesCategorys"
    ]),
    processTitle() {
      return [
        {
          key: "Status",
          name: this.$t(`${this.i18nPrefix}status`)
        },
        {
          key: "Domain",
          name: this.$t(`${this.i18nPrefix}domain`)
        },
        {
          key: "CommissionCalculationCycle",
          name: this.$t(`${this.i18nPrefix}dialog.CommissionCalculationCycle`)
        }
      ];
    }
  },
  async created() {
    this.agentId = this.getUserinfo.parentId;
    await this.getAgentList();
    await getSelectMenu().then(res => (this.agentDamain = res.data.domain));
  },
  methods: {
    async getAgentList(page = this.page.current) {
      this.closeDialog();

      await getList({
        agentId: this.agentId, page: {
          "take": this.take * 1,
          "skip": this.take * (page ? page - 1 : 0)
        },
      }).then(res => {
        this.haveMember = res.data.haveMember;
        this.allData = res.data.result.list;
        this.page = res.data.result.page;
      });

      await getParentPermissions({ parentId: this.agentId }).then(res => {
        this.permissionsList = res.data.permissions;
        this.permissionsData.permissionsTitle = this.permissionsList.filter(
          data => data.id % 10000 === 0
        );
        this.permissionsData.firstData = this.permissionsData.permissionsTitle.map(
          data =>
            this.permissionsList.filter(
              firstdata =>
                parseInt(data.id / 10000) === parseInt(firstdata.id / 10000) &&
                (data.id - firstdata.id) % 100 === 0 &&
                firstdata.id % 10000 !== 0
            )
        );
        this.permissionsData.secData = this.permissionsData.firstData.map(
          firstdata =>
            firstdata.map(data =>
              this.permissionsList.filter(
                secData =>
                  parseInt(data.id / 100) === parseInt(secData.id / 100) &&
                  data.id !== secData.id
              )
            )
        );
      });
    },
    create(data) {
      createAgent(data).then(res => {
        if (res) this.getAgentList();
      });
    },
    edit(data) {
      editAgent(data).then(res => {
        if (res) this.getAgentList();
      });
    },
    getProcess(id) {
      getAgentProcess({ id }).then(res => {
        this.processData = res.data;
      });
    },
    passAgentId(go) {
      this.agentId =
        this.getUserinfo.role == 2 && go === 0 ? this.getUserinfo.parentId : go;
      this.getAgentList(1);
      this.backData = this.getAgentListAddDefault.find(
        data => data.id === go
      )?.parentId;
    },
    async copyDomain(domain) {
      navigator.clipboard.writeText(domain);
      this.copyText = await navigator.clipboard.readText();
      Message.copy(`${domain} ` + this.$t("default.isCopy"), 1500);
    },
    downloadQRCode(d) {
      let myImg = document.querySelector(".qrcode" + d + " canvas");
      downloadCanvas(myImg.toDataURL(), "QRCode_" + d + ".jpg");
    }
  }
};
</script>


<style lang="scss" scoped>
@media screen and (max-width: 1280px) {
  .agent-block {
    flex-direction: column;

    //.tree-block {
    //  width: 95%;
    //}
    .content-block {
      width: 100%;
    }
  }
}

.agent {
  color: #CD7B00;
  text-decoration: underline !important;
  font-weight: bold;
  cursor: pointer;
}
</style>