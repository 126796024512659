var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "dialog-mask",
      on: {
        mousedown: function ($event) {
          if ($event.target !== $event.currentTarget) return null
          return _vm.$emit("toggle")
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "dialog-container edit-member" },
        [
          _c("div", { staticClass: "dialog-block" }, [
            _c("div", { staticClass: "header" }, [
              _c("h4", [_vm._v(_vm._s(_vm.$t("default.onlineMemberAmount")))]),
            ]),
            _c("div", { staticClass: "divider" }),
            _c("div", { staticClass: "container-view" }, [
              _c("div", { staticClass: "table-block" }, [
                _c("table", [
                  _c("thead", [
                    _c("tr", [
                      _c("th", [_vm._v("id")]),
                      _c("th", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "report-various.manager-record.lastLoginTime"
                            )
                          )
                        ),
                      ]),
                      _c("th", [_vm._v(_vm._s(_vm.$t("default.member")))]),
                      _c("th", [
                        _vm._v(_vm._s(_vm.$t("member.member-management.name"))),
                      ]),
                      _c("th", [
                        _vm._v(
                          _vm._s(
                            _vm.$t("report-various.member-login-record.browser")
                          )
                        ),
                      ]),
                      _c("th", [
                        _vm._v(
                          _vm._s(_vm.$t("member.member-management.device"))
                        ),
                      ]),
                      _c("th", [
                        _vm._v(_vm._s(_vm.$t("member.member-management.ip"))),
                      ]),
                      _c("th", [_vm._v(_vm._s(_vm.$t("liveData.ipCountry")))]),
                      _c("th", [
                        _vm._v(_vm._s(_vm.$t("member.member-management.kick"))),
                      ]),
                    ]),
                  ]),
                  _c(
                    "tbody",
                    [
                      !_vm.allData || !_vm.allData.length
                        ? _c("tr", [
                            _c("td", { attrs: { colspan: "999" } }, [
                              _vm._v(_vm._s(_vm.$t("default.noData"))),
                            ]),
                          ])
                        : _vm._e(),
                      _vm._l(_vm.allData, function (list, index) {
                        return _c("tr", { key: index }, [
                          _c("td", [_vm._v(_vm._s(list.memberId))]),
                          _c("td", [_vm._v(_vm._s(list.lastLoginTime))]),
                          _c("td", [_vm._v(_vm._s(list.memberAccount))]),
                          _c("td", [_vm._v(_vm._s(list.memberName))]),
                          _c("td", [_vm._v(_vm._s(list.browser))]),
                          _c("td", [
                            _vm._v(_vm._s(_vm.$t("DeviceType." + list.device))),
                          ]),
                          _c("td", [_vm._v(_vm._s(list.lastLoginIp))]),
                          _c("td", [_vm._v(_vm._s(list.ipCountry))]),
                          _c("td", [
                            _c(
                              "button",
                              {
                                staticClass: "kick-btn",
                                on: {
                                  click: function ($event) {
                                    return _vm.kick(list.memberId)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("member.member-management.kick")
                                  )
                                ),
                              ]
                            ),
                          ]),
                        ])
                      }),
                    ],
                    2
                  ),
                ]),
              ]),
            ]),
          ]),
          _vm.allData
            ? _c("serverPagination", {
                attrs: { page: _vm.page },
                on: { ServerPageUtils: _vm.search, changeTake: _vm.changeTake },
              })
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }