<template>
  <div>
    <div class="container-view">
      <div class="header">
        <h5>{{ $t("title." + $route.meta.title) }}</h5>
        <div class="float-right">
          <button class="create-btn main-style-btn" @click="handleDialog(undefined, 'Create')" 
          :disabled="!getUserinfo.permissions.includes(61101)">
            {{ $t("default.create") }}{{ $t("title.group") }}
          </button>
        </div>
      </div>
      <main>
        <div class="table-block">
          <table>
            <thead>
              <tr>
                <th></th>
                <th>{{ $t(`${i18nPrefix}groupName`) }}</th>
                <th>{{ $t(`${i18nPrefix}stage`) }}</th>
                <th>{{ $t(`${i18nPrefix}numberOfMember`) }}</th>
                <th>{{ $t(`${i18nPrefix}bankCount`) }}</th>
                <th>{{ $t(`${i18nPrefix}active`) }}</th>
                <th>{{ $t(`${i18nPrefix}creator`) }}</th>
                <th>{{ $t(`${i18nPrefix}createDate`) }}</th>
                <th>{{ $t(`${i18nPrefix}editor`) }}</th>
                <th>{{ $t(`${i18nPrefix}updateDate`) }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for=" group of tableList " :key="group.id">
                <td>
                  <button class="table-btn main-style-btn" @click="handleDialog(group, 'Edit')" :disabled="!getUserinfo.permissions.includes(61102)
          ">
                    {{ $t("default.edit") }}
                  </button>
                </td>
                <td>{{ group.name }}</td>
                <td>{{ starLevelList.find(data => data.id === group.starLevelSettingId).star }} {{ $t("default.star") }}
                </td>
                <td>{{ group.memberCount }}</td>
                <td>{{ group.agentBankCount }}</td>
                <td>{{ group.status === 1 ? $t("default.active") : $t("default.inactive") }}</td>
                <td>{{ group.createAccount }}</td>
                <td>{{ group.createTime }}</td>
                <td>{{ group.updateAccount }}</td>
                <td>{{ group.updateTime }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </main>

      <Pagination v-if="totalCount > 0" :totalCount="totalCount" @handlePage="handlePage" />
    </div>

    <transition name="fade" mode="out-in">
      <component :is="currDialog" :starLevelList="starLevelList" v-bind="dialogData" @toggle="currDialog = undefined"
        @create="create" @edit="edit"></component>
    </transition>
  </div>
</template>

<script>
import { groupSelectMenu, groupList, createGroup, editGroup } from "@/api/features/group";
import Message from "@/layout/components/Message";
import Create from "./dialog/create";
import Edit from "./dialog/edit";
import Pagination from "@/layout/components/Pagination";
import handlePageUtils from "@/layout/mixins/handlePageUtils";
import handleDialog from "@/layout/mixins/handleDialog";
import { mapGetters } from 'vuex';

export default {
  name: "GroupMember",
  data() {
    return {
      tableData: undefined,
      starLevelList: [],
      i18nPrefix: "features.group."
    }
  },
  components: {
    Create,
    Edit,
    Pagination,
  },
  mixins: [handlePageUtils, handleDialog],
  computed: {
    ...mapGetters(["getUserinfo"])
  },
  created() {
    groupSelectMenu().then(res => {
      this.starLevelList = res.data.starLevelList;
    })
    this.getGroupList();
  },
  methods: {
    getGroupList() {
      groupList().then((res) => {
        this.tableData = this.allData = res.data;
        this.RESET_DIALOG();
        this.dialogData = undefined;
      });
    },
    create(data) {
      createGroup(data).then((res) => {
        if (res) this.getGroupList();
      });
    },
    edit(data) {
      editGroup({
        id: data.id,
        name: data.name,
        status: parseInt(data.status),
        starLevelSettingId: parseInt(data.starLevelSettingId),
      }).then((res) => {
        if (res) this.getGroupList();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.advance-block {
  select {
    width: 200px;
    margin-left: 15px;
  }
}
</style>