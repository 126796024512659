<template>
  <div>
    <div class="container-view">
      <button class="search-btn main-style-btn" @click="search()">{{ $t("default.search") }}</button>
      <div class="header">
        <h5>{{ $t("title." + $route.meta.title) }}</h5>
        <div class="float-right">
          <button class="create-btn main-style-btn" @click="handleDialog(undefined, 'Create')"
            :disabled="!getUserinfo.permissions.includes(140101)">
            {{ $t("default.create") }}
          </button>
        </div>
      </div>
      <div class="main">
        <div class="input-block mb-5">
          <label>
                {{ $t("default.gamePlatform") }}
            <select v-model="searchForm.gamePlatform">
              <option :value="0 * 1">{{ $t("default.all") }}</option>
              <option v-for="list of getGamePlatforms" :key="list.id" :value="list.id">
                {{ $t(list.name) }}
              </option>
            </select>
          </label>
        </div>
        <div class="advance-block">
          <button class="search-pc-btn main-style-btn px-5" @click="search()">{{ $t("default.search") }}</button>
        </div>
      </div>
    </div>
    <div class="container-view">
      <div class="table-block">
        <table>
          <thead>
            <tr>
              <th></th>
              <th>{{ $t("default.gamePlatform") }}</th>
              <th>{{ $t("default.nowCsinoChips") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="!allData || !allData.length">
              <td colspan="3">{{ $t("default.noData") }}</td>
            </tr>
            <tr v-for="(list) of allData" :key="list.id">
              <td class="small-td">
                <div class="btn-td">
                  <button class="table-btn main-style-btn" @click="handleDialog(list, 'Edit')"
                    :disabled="!getUserinfo.permissions.includes(140102)">
                    {{ $t("default.edit") }}
                  </button>
                </div>
              </td>
              <td>
                {{
                  GLOBAL.i18nCheckName(getGamePlatforms.find(
                    (data) => list.gamePlatform === data.id
                  ))
                }}
              </td>
              <td>{{ list.amount }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <serverPagination v-if="allData" :page="page" @ServerPageUtils="search" @changeTake="changeTake" />
    </div>

    <transition name="fade" mode="out-in">
      <component :is="currDialog" v-bind="dialogData" :getGamePlatforms=getGamePlatforms
         @toggle="currDialog = undefined" @edit="edit" @create="create"></component>
    </transition>
  </div>
</template>

<script>
import { searchModel } from "./model";
import {
  getCasinoChips,
  createCasinoChips,
  updateCasinoChips,
} from "@/api/operationManagement/casinoChips";
import { mapGetters } from "vuex";
import Edit from "./dialog/edit";
import Create from "./dialog/create";
import serverPagination from "@/layout/components/serverPagination";
import handleServerPageUtils from "@/layout/mixins/handleServerPageUtils";
import handleDialog from "@/layout/mixins/handleDialog";
export default {
  name: "CasinoChips",
  components: {
    Edit,
    Create,
    serverPagination,
  },
  data() {
    return {
      searchForm: _.cloneDeep(searchModel),
      i18nPrefix: "operationManagement.gamesCategoryInfo."
    };
  },
  mixins: [handleServerPageUtils, handleDialog],
  computed: {
    ...mapGetters(["getUserinfo", "getGamePlatforms"]),
  },
  created() {
  },
  methods: {
    search(page = false) {
      this.closeDialog();
      this.searchForm.page.take = this.take * 1;
      this.searchForm.page.skip = this.take * (page ? page - 1 : 0);
      getCasinoChips(this.searchForm).then((res) => {
        console.log(res.data.list);
        this.allData = res.data.list;
        this.page = res.data.page;
      });
    },
    create(data) {
      createCasinoChips(data).then((res) => {
        if (res) this.search();
      });
    },
    edit(data) {
      updateCasinoChips(data).then((res) => {
        if (res) this.search();
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.small-td {
  width: 5%;
}

.middle-td {
  width: 10%;
}

.btn-td {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.url-td {
  // width: 20%;
  white-space: unset !important;

  span {
    margin: auto 5px;
  }
}
</style>