var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "container-view" }, [
        _c(
          "button",
          {
            staticClass: "search-btn main-style-btn",
            on: {
              click: function ($event) {
                return _vm.search()
              },
            },
          },
          [_vm._v(_vm._s(_vm.$t("default.search")))]
        ),
        _c("div", { staticClass: "header" }, [
          _c("h5", [_vm._v(_vm._s(_vm.$t("title." + _vm.$route.meta.title)))]),
        ]),
        _c("div", { staticClass: "main" }, [
          _c("div", { staticClass: "input-block" }, [
            _c("label", [
              _vm._v(" " + _vm._s(_vm.$t("default.member")) + " "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.searchForm.memberAccount,
                    expression: "searchForm.memberAccount",
                  },
                ],
                attrs: { type: "text" },
                domProps: { value: _vm.searchForm.memberAccount },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(
                      _vm.searchForm,
                      "memberAccount",
                      $event.target.value
                    )
                  },
                },
              }),
            ]),
            _c("label", [
              _vm._v(
                " " + _vm._s(_vm.$t(`${_vm.i18nPrefix}gameOrderNumber`)) + " "
              ),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.searchForm.gameOrderNumber,
                    expression: "searchForm.gameOrderNumber",
                  },
                ],
                attrs: { type: "text" },
                domProps: { value: _vm.searchForm.gameOrderNumber },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(
                      _vm.searchForm,
                      "gameOrderNumber",
                      $event.target.value
                    )
                  },
                },
              }),
            ]),
            _c("label", [
              _vm._v(
                " " + _vm._s(_vm.$t(`${_vm.i18nPrefix}gamePlatform`)) + " "
              ),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchForm.gamePlatform,
                      expression: "searchForm.gamePlatform",
                    },
                  ],
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.searchForm,
                        "gamePlatform",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    },
                  },
                },
                _vm._l(_vm.getGamePlatformsAddDefault, function (list) {
                  return _c("option", { domProps: { value: list.id } }, [
                    _vm._v(_vm._s(_vm.$t(list.name))),
                  ])
                }),
                0
              ),
            ]),
            _c("label", [
              _vm._v(" " + _vm._s(_vm.$t("default.agent")) + " "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchForm.agentId,
                      expression: "searchForm.agentId",
                    },
                  ],
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.searchForm,
                        "agentId",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    },
                  },
                },
                _vm._l(_vm.getAgentListAddDefault, function (list) {
                  return _c(
                    "option",
                    { key: list.id, domProps: { value: list.id } },
                    [_vm._v(_vm._s(_vm.$t(list.account)))]
                  )
                }),
                0
              ),
            ]),
            _c(
              "label",
              [
                _vm._v(
                  " " + _vm._s(_vm.$t(`${_vm.i18nPrefix}startTime`)) + " "
                ),
                _c("date-picker", {
                  attrs: {
                    format: "YYYY-MM-DD HH:mm:ss",
                    "value-type": "format",
                    type: "datetime",
                    "disabled-date": _vm.GLOBAL.ontSeason,
                  },
                  model: {
                    value: _vm.startTime,
                    callback: function ($$v) {
                      _vm.startTime = $$v
                    },
                    expression: "startTime",
                  },
                }),
              ],
              1
            ),
            _c(
              "label",
              [
                _vm._v(" " + _vm._s(_vm.$t(`${_vm.i18nPrefix}endTime`)) + " "),
                _c("date-picker", {
                  attrs: {
                    format: "YYYY-MM-DD HH:mm:ss",
                    "value-type": "format",
                    type: "datetime",
                    "disabled-date": _vm.GLOBAL.ontSeason,
                  },
                  model: {
                    value: _vm.endTime,
                    callback: function ($$v) {
                      _vm.endTime = $$v
                    },
                    expression: "endTime",
                  },
                }),
              ],
              1
            ),
          ]),
          _c(
            "div",
            { staticClass: "advance-block" },
            [
              _c("QuickSearch", { on: { changeTime: _vm.changeTime } }),
              _c(
                "button",
                {
                  staticClass: "search-pc-btn main-style-btn px-5",
                  on: {
                    click: function ($event) {
                      return _vm.search()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("default.search")))]
              ),
            ],
            1
          ),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "container-view" },
        [
          _c("main", [
            _c("div", { staticClass: "table-block" }, [
              _c("table", [
                _c("thead", [
                  _c("tr", [
                    _vm.allPlus && _vm.allData
                      ? _c(
                          "th",
                          [
                            _c("font-awesome-icon", {
                              staticClass: "plus",
                              attrs: { icon: ["fas", "plus-circle"] },
                              on: {
                                click: function ($event) {
                                  return _vm.allPlusBoolean(false)
                                },
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    !_vm.allPlus
                      ? _c(
                          "th",
                          [
                            _c("font-awesome-icon", {
                              staticClass: "plus",
                              attrs: { icon: ["fas", "minus-circle"] },
                              on: {
                                click: function ($event) {
                                  return _vm.allPlusBoolean(true)
                                },
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c("th", [_vm._v(_vm._s(_vm.$t("default.member")))]),
                    _c(
                      "th",
                      {
                        staticClass: "sort",
                        on: {
                          click: function ($event) {
                            return _vm.dataSort("betTime", _vm.sort)
                          },
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.$t(`${_vm.i18nPrefix}betTime`)) +
                            " " +
                            _vm._s(_vm.direction("betTime"))
                        ),
                      ]
                    ),
                    _c("th", [
                      _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}gamePlatform`))),
                    ]),
                    _c("th", [
                      _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}gamesCategory`))),
                    ]),
                    _c("th", [
                      _vm._v(
                        _vm._s(_vm.$t(`${_vm.i18nPrefix}gameOrderNumber`))
                      ),
                    ]),
                    _c("th", [
                      _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}remark`))),
                    ]),
                    _c("th", [
                      _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}betAmount`))),
                    ]),
                    _c("th", [
                      _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}validBetAmount`))),
                    ]),
                    _c(
                      "th",
                      {
                        staticClass: "sort",
                        on: {
                          click: function ($event) {
                            return _vm.dataSort("winLose", _vm.sort)
                          },
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.$t(`${_vm.i18nPrefix}winLose`)) +
                            " " +
                            _vm._s(_vm.direction("winLose"))
                        ),
                      ]
                    ),
                  ]),
                ]),
                _c(
                  "tbody",
                  [
                    !_vm.allData || _vm.allData.length === 0
                      ? _c("tr", [
                          _c("td", { attrs: { colspan: "9" } }, [
                            _vm._v(_vm._s(_vm.$t("default.noData"))),
                          ]),
                        ])
                      : _vm._e(),
                    _vm._l(_vm.allData, function (list, index) {
                      return [
                        _c(
                          "tr",
                          { key: list.betTime + index, staticClass: "top" },
                          [
                            _vm.plus[index]
                              ? _c(
                                  "td",
                                  [
                                    _c("font-awesome-icon", {
                                      staticClass: "plus",
                                      attrs: {
                                        icon: ["fas", "plus-circle"],
                                        "data-toggle": "collapse",
                                        "data-target": `#a${list.memberAccount}${index}`,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.plusBoolean(index)
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            !_vm.plus[index]
                              ? _c(
                                  "td",
                                  [
                                    _c("font-awesome-icon", {
                                      staticClass: "plus",
                                      attrs: {
                                        icon: ["fas", "minus-circle"],
                                        "data-toggle": "collapse",
                                        "data-target": `#a${list.memberAccount}${index}`,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.plusBoolean(index)
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c("td", [
                              _c(
                                "span",
                                {
                                  staticClass: "memberSpan",
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleDialog(
                                        {
                                          list: {
                                            id: list.member.id,
                                            account: list.member.account,
                                            toggle: true,
                                          },
                                        },
                                        "Detail"
                                      )
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(list.member.account) + " ")]
                              ),
                            ]),
                            _c("td", [_vm._v(_vm._s(list.betTime))]),
                            _c("td", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.GLOBAL.i18nCheckName(
                                      _vm.getGamePlatformsAddDefault.find(
                                        (data) => data.id === list.gamePlatform
                                      )
                                    )
                                  ) +
                                  " "
                              ),
                            ]),
                            _c("td", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.GLOBAL.i18nCheckName(
                                      _vm.getGamesCategorys.find(
                                        (data) => list.gamesCategory === data.id
                                      )
                                    )
                                  ) +
                                  " "
                              ),
                            ]),
                            _c("td", [_vm._v(_vm._s(list.gameOrderNumber))]),
                            _c("td", [_vm._v(_vm._s(list.remark))]),
                            _c("td", [
                              _vm._v(
                                _vm._s(_vm.digital.format(list.betAmount))
                              ),
                            ]),
                            _c("td", [
                              _vm._v(
                                _vm._s(_vm.digital.format(list.validBetAmount))
                              ),
                            ]),
                            _c("td", { class: _vm.fontColor(list.winLose) }, [
                              _vm._v(_vm._s(_vm.digital.format(list.winLose))),
                            ]),
                          ]
                        ),
                        _c(
                          "tr",
                          {
                            key: index,
                            staticClass: "detail collapse collapseAll",
                            attrs: { id: `a${list.memberAccount}${index}` },
                          },
                          [
                            _c("td", { attrs: { colspan: "2" } }, [
                              _vm._v(
                                _vm._s(_vm.$t(`${_vm.i18nPrefix}betInfo`)) +
                                  _vm._s(_vm.InfoCount(list.betInfo)) +
                                  _vm._s(_vm.$t(`${_vm.i18nPrefix}source`))
                              ),
                            ]),
                            _c("td", { attrs: { colspan: "8" } }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.InfoData(list.betInfo, list.gameResult)
                                  ) +
                                  " " +
                                  _vm._s(list.gameResult) +
                                  " "
                              ),
                            ]),
                          ]
                        ),
                      ]
                    }),
                    !(!_vm.allData || _vm.allData.length === 0)
                      ? _c("tr", [
                          _c("td", { attrs: { colspan: "6" } }),
                          _c("td", [_vm._v("小計")]),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                _vm.digital.format(_vm.subTotal.totalBetAmount)
                              )
                            ),
                          ]),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                _vm.digital.format(
                                  _vm.subTotal.totalValidBetAmount
                                )
                              )
                            ),
                          ]),
                          _c(
                            "td",
                            { class: _vm.fontColor(_vm.subTotal.totalWinLose) },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.digital.format(_vm.subTotal.totalWinLose)
                                )
                              ),
                            ]
                          ),
                        ])
                      : _vm._e(),
                    !(!_vm.allData || _vm.allData.length === 0)
                      ? _c("tr", [
                          _c("td", { attrs: { colspan: "6" } }),
                          _c("td", [_vm._v(_vm._s(_vm.$t("default.total")))]),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                _vm.digital.format(_vm.totalData.totalBetAmount)
                              )
                            ),
                          ]),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                _vm.digital.format(
                                  _vm.totalData.totalValidBetAmount
                                )
                              )
                            ),
                          ]),
                          _c(
                            "td",
                            {
                              class: _vm.fontColor(_vm.totalData.totalWinLose),
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.digital.format(_vm.totalData.totalWinLose)
                                )
                              ),
                            ]
                          ),
                        ])
                      : _vm._e(),
                  ],
                  2
                ),
              ]),
            ]),
          ]),
          _vm.allData
            ? _c("serverPagination", {
                attrs: { page: _vm.page },
                on: { ServerPageUtils: _vm.search, changeTake: _vm.changeTake },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "transition",
        { attrs: { name: "fade", mode: "out-in" } },
        [
          _c(
            _vm.currDialog,
            _vm._b(
              {
                tag: "component",
                on: {
                  toggle: function ($event) {
                    _vm.currDialog = undefined
                  },
                },
              },
              "component",
              _vm.dialogData,
              false
            )
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }