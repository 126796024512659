<template>
  <div class="dialog-mask" @mousedown.self="$emit('toggle')">
    <div class="dialog-container edit-member">
      <div class="dialog-block">
        <div class="header">
          <h4>{{ $t("default.onlineMemberAmount") }}</h4>
        </div>
        <div class="divider"></div>
        <div class="container-view">
          <div class="table-block">
            <table>
              <thead>
                <tr>
                  <th>id</th>
                  <th>{{ $t("report-various.manager-record.lastLoginTime") }}</th>
                  <th>{{ $t("default.member") }}</th>
                  <th>{{ $t("member.member-management.name") }}</th>
                  <th>{{ $t("report-various.member-login-record.browser") }}</th>
                  <th>{{ $t("member.member-management.device") }}</th>
                  <th>{{ $t("member.member-management.ip") }}</th>
                  <th>{{ $t("liveData.ipCountry") }}</th>
                  <th>{{ $t("member.member-management.kick") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="!allData || !allData.length">
                  <td colspan="999">{{ $t("default.noData") }}</td>
                </tr>
                <tr v-for="(list, index) of allData" :key="index">
                  <td>{{ list.memberId }}</td>
                  <td>{{ list.lastLoginTime }}</td>
                  <td>{{ list.memberAccount }}</td>
                  <td>{{ list.memberName }}</td>
                  <td>{{ list.browser }}</td>
                  <td>{{ $t("DeviceType." + list.device) }}</td>
                  <td>{{ list.lastLoginIp }}</td>
                  <td>{{ list.ipCountry }}</td>
                  <td><button class="kick-btn" @click="kick(list.memberId)">{{ $t("member.member-management.kick") }}</button></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <serverPagination v-if="allData" :page="page" @ServerPageUtils="search" @changeTake="changeTake" />
    </div>
  </div>
</template>

<script>
import { getOnlineDetail } from "@/api/liveData";
import { kickMember } from "@/api/member/member";
import serverPagination from "@/layout/components/serverPagination";
import handleServerPageUtils from "@/layout/mixins/handleServerPageUtils";

export default {
  name: "OnlineMemberList",
  props: [],
  data() {
    return {
    };
  },
  components: {
    serverPagination
  },
  mixins: [handleServerPageUtils],
  created() {
    this.search()
  },
  methods: {
    search(page = false) {
      getOnlineDetail({
        page: {
          "take": this.take * 1,
          "skip": this.take * (page ? page - 1 : 0)
        },
      }).then(res => {
        this.allData = res.data.list;
        this.page = res.data.page;
      });
    },
    kick(id) {
      kickMember({ id: id }).then(res => {
        if (res) this.search();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog-container {
  width: 80%;

  .container-view {
    border-radius: 10px !important;
    padding: 25px !important;
  }

  .table-block {
    padding-bottom: 0px !important;
  }

  .kick-btn {
    background-color: #f44236;
    color: #fff5e5 !important;
    font-size: 14px;
    border: none;
    border-radius: 5px;
    width: 50px;
    height: 25px;
  }
}
</style>