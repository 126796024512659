<template>
  <div>
    <div class="container-view">
      <!-- <button class="search-btn main-style-btn" @click="search()">{{ $t("default.search") }}</button> -->
      <div class="header">
        <h5>{{ $t("title." + $route.meta.title) }}</h5>
        <div class="float-right">
          <button class="create-btn main-style-btn" @click="handleDialog(undefined, 'Create')"
            :disabled="!getUserinfo.permissions.includes(62601)">
            {{ $t(`${i18nPrefix}sendMail`) }}
          </button>
        </div>
      </div>
      <div class="main">
        <!-- <div class="input-block">
          <label>
            {{ $t("default.member") }}
            <input type="text" v-model="searchForm.memberAccount" />
          </label>
          <label>
            {{ $t("default.title") }}
            <input type="text" v-model="searchForm.title" />
          </label>
          <label>
            {{ $t("default.startTime") }}
            <date-picker v-model="startTime" format="YYYY-MM-DD HH:mm" value-type="format"
              type="datetime"></date-picker>
          </label>
          <label>
            {{ $t("default.endTime") }}
            <date-picker v-model="endTime" format="YYYY-MM-DD HH:mm" value-type="format" type="datetime"></date-picker>
          </label>>
          <label>
            {{ $t("default.status") }}
            <select v-model="searchForm.isRead">
              <option :value="undefined">{{ $t("default.all") }}</option>
              <option :value="false">{{ $t(`${i18nPrefix}notRead`) }}</option>
              <option :value="true">{{ $t(`${i18nPrefix}read`) }}</option>
            </select>
          </label>
        </div> -->
        <!-- <div class="advance-block"> -->
          <!-- <QuickSearch @changeTime="changeTime"></QuickSearch> -->
          <!-- <button class="search-pc-btn main-style-btn px-5" @click="search()">{{ $t("default.search") }}</button> -->
        <!-- </div> -->
      </div>
    </div>
    <div class="container-view">
      <div class="table-block">
        <table>
          <thead>
            <tr>
              <th>{{ $t("default.detail") }}</th>
              <th>{{ $t("default.createTime") }}</th>
              <!-- <th>{{ $t("default.member") }}</th>
              <th>{{ $t(`${i18nPrefix}messageType`) }}</th>
              <th>{{ $t(`${i18nPrefix}title`) }}</th>
              <th>{{ $t(`${i18nPrefix}message`) }}</th>
              <th>{{ $t(`${i18nPrefix}updateTime`) }}</th> -->
              <th>{{ $t("default.admin") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="!allData || !allData.length">
              <td colspan="3">{{ $t("default.noData") }}</td>
            </tr>
            <tr v-for="list of allData" :key="list.id">
              <!-- <td>{{ list.isRead ? $t(`${i18nPrefix}read`) : $t(`${i18nPrefix}notRead`) }}</td>
              <td>{{ list.memberAccount }}</td>
              <td>{{ GLOBAL.i18nCheckName(messageType.find(data => data.id === list.type)) }}</td>
              <td>{{ list.title }}</td>
              <td><button class="table-btn main-style-btn"
                  @click="handleDialog({ message: list.message }, 'viewMessage')">
                  {{ $t(`${i18nPrefix}detail`) }}
                </button></td> -->
              <td>
                <button class="table-btn main-style-btn" @click="handleDialog(list, 'Count')">{{ $t("default.detail")
                  }}</button>
              </td>
              <td>{{ list.createTime }}</td>
              <td>{{ list.userAccount }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <serverPagination v-if="allData" :page="page" @ServerPageUtils="search" @changeTake="changeTake" />
    </div>
    <transition name="fade" mode="out-in">
      <component :messageTypeList="messageType" :agentList="getAgentList" :attachmentType="attachmentType"
        :is="currDialog" :i18nPrefix="i18nPrefix" v-bind="dialogData" @toggle="currDialog = undefined" @create="create">
      </component>
    </transition>
  </div>
</template>

<script>
import {
  getselectMenu,
  getMessageList
} from "@/api/features/batchSend";
import serverPagination from "@/layout/components/serverPagination";
import handleServerPageUtils from "@/layout/mixins/handleServerPageUtils";
import handleDialog from "@/layout/mixins/handleDialog";
import Create from "./dialog/create.vue";
import Count from "./dialog/count";
import viewMessage from "./dialog/message.vue";
import { searchModel } from "./model";
import { mapGetters } from "vuex";

export default {
  name: "BatchSend",
  data() {
    return {
      searchForm: _.cloneDeep(searchModel),
      attachmentType: [],
      messageType: [
        {
          id: 1,
          name: 'features.memberPrivateMessage.mail'
        },
        // {
        //   id: 2,
        //   name: 'features.memberPrivateMessage.sns'
        // },
      ],
      i18nPrefix: "features.memberPrivateMessage."
    };
  },
  components: {
    serverPagination,
    Create,
    Count,
    viewMessage,
  },
  computed: {
    ...mapGetters(["getUserinfo", "getAgentList"]),
  },
  mixins: [handleServerPageUtils, handleDialog],
  created() {
    getselectMenu().then(res => this.attachmentType = res.data.attachmentType);
    this.search();
  },
  methods: {
    search(page = false) {
      this.closeDialog();

      this.searchForm.page.take = this.take * 1;
      this.searchForm.page.skip = this.take * (page ? page - 1 : 0);
      getMessageList(this.searchForm).then((res) => {
        this.allData = res.data.list;
        this.page = res.data.page;
      });
    },
    create() {
      this.search();
    }
  },
};
</script>

<style lang="scss" scoped>
.sort {
  cursor: pointer;
}
</style>