<template>
  <div>
    <div class="container-view">
      <button class="search-btn main-style-btn" @click="search()">
        {{ $t("default.search") }}
      </button>
      <div class="header">
        <h5>{{ $t("title." + $route.meta.title) }}</h5>
        <div class="float-right">
          <button class="download-btn main-style-btn" @click="download">
            <img class="icon" src="@/assets/download.svg" />
            {{ $t("default.download") }}
          </button>
        </div>
      </div>
      <div class="main">
        <div class="input-block">
          <label class="input-block-multiselect">
            <div class="input-block-check">
              <span style="margin-right: 10px">{{ $t("default.agent") }}</span>
            </div>
            <multiselect
              v-model="agentData"
              label="name"
              track-by="id"
              :options="getAgentListAddDefault"
              :allow-empty="false"
              :preselectFirst="true"
              :clear-on-select="false"
              :preserve-search="true"
              :custom-label="GLOBAL.nameWithLang"
            ></multiselect>
          </label>
          <label>
            {{ $t("default.member") }}
            <input type="text" v-model="searchForm.memberAccount" />
          </label>
          <label>
            {{ $t("default.startTime") }}
            <date-picker
              v-model="startTime"
              format="YYYY-MM-DD"
              value-type="format"
              type="datetime"
              :disabled-date="GLOBAL.ontSeason"
            ></date-picker>
          </label>
          <label>
            {{ $t("default.endTime") }}
            <date-picker
              v-model="endTime"
              format="YYYY-MM-DD"
              value-type="format"
              type="datetime"
              :disabled-date="GLOBAL.ontSeason"
            ></date-picker>
          </label>
          <label>
            {{ $t(`${i18nPrefix}gamesCategory`) }}
            <select v-model="searchForm.gamesCategory">
              <option
                v-for="list of getGamesCategorysAddDefault"
                :key="list.id"
                :value="list.id"
              >
                {{ $t(list.name) }}
              </option>
            </select>
          </label>
        </div>
        <div class="advance-block">
          <QuickSearch
            ref="quickSearch"
            @changeTime="changeTime"
            :controlType="true"
          ></QuickSearch>
          <button class="search-pc-btn main-style-btn px-5" @click="search()">
            {{ $t("default.search") }}
          </button>
        </div>
      </div>
    </div>
    <div class="container-view">
      <div
        class="total-block-v2 row-4"
        v-if="!getUserinfo.koreanStyleInterface"
      >
        <div class="total-block-v2-item" style="align-items: center">
          <div class="total-block-v2-item-left">
            {{ $t(`${i18nPrefix}betCount`) }}:
          </div>
          <div class="total-block-v2-item-right">
            {{ digital.format(total.totalBetCount ? total.totalBetCount : 0) }}
          </div>
        </div>
        <div class="total-block-v2-item" style="align-items: center">
          <div class="total-block-v2-item-left">
            {{ $t(`${i18nPrefix}validBetAmount`) }}:
          </div>
          <div class="total-block-v2-item-right">
            {{
              digital.format(
                total.totalValidBetAmount ? total.totalValidBetAmount : 0
              )
            }}
          </div>
        </div>
        <div class="total-block-v2-item" style="align-items: center">
          <div class="total-block-v2-item-left">
            {{ $t(`${i18nPrefix}winLose`) }}:
          </div>
          <div class="total-block-v2-item-right">
            {{ digital.format(total.totalWinLose ? total.totalWinLose : 0) }}
          </div>
        </div>
        <div
          class="total-block-v2-item"
          style="flex-wrap: wrap; align-items: center"
        >
          <div class="agent-count">
            <div class="total-block-v2-item-left">
              {{ $t(`${i18nPrefix}agentParentCount`) }}:
            </div>
            <div class="total-block-v2-item-right">
              {{
                digital.format(
                  total.totalAgentParentCount ? total.totalAgentParentCount : 0
                )
              }}
            </div>
          </div>
          <div class="agent-count">
            <div class="total-block-v2-item-left">
              {{ $t(`${i18nPrefix}agentChildCount`) }}:
            </div>
            <div class="total-block-v2-item-right">
              {{
                digital.format(
                  total.totalAgentChildCount ? total.totalAgentChildCount : 0
                )
              }}
            </div>
          </div>
        </div>
      </div>
      <div class="table-block">
        <TreeTable
          ref="treeTable"
          :list="allData"
          :column="tableColumn"
          :bookmarkColumn="bookmarkColumn"
          :notShowChildren="true"
          @sort="dataSort"
          @bookmark="bookmark"
        >
          <template v-slot:column_name="props">
            {{
              searchForm.type != 3
                ? searchForm.type != 1
                  ? props.name
                  : `${props.name} (${GLOBAL.i18nCheckName(
                      getAgentListAddDefault.find(
                        (data) => data.account == props.name
                      )
                    )})`
                : GLOBAL.i18nCheckName(
                    getGamesCategorysAddDefault.find(
                      (data) => data.id == props.name
                    )
                  )
            }}
          </template>
          <template v-slot:column_totalAgentRebateAmount="props">
            <span role="button" @click="handleRebate(props.id)">{{
              digital.format(props.totalAgentRebateAmount)
            }}</span>
          </template>
          <template v-slot:column_action="props">
            <button class="normal-btn" @click="bookmark(2, props.id)">
              {{ $t("default.member") }}
            </button>
            <button class="normal-btn ml-2" @click="bookmark(3, props.id)">
              {{ $t(`${i18nPrefix}game`) }}
            </button>
          </template>
        </TreeTable>
        <!-- <serverPagination
          v-if="allData"
          :page="page"
          @ServerPageUtils="searchPage"
          @changeTake="changeTake"
        ></serverPagination>-->
      </div>
    </div>
    <transition name="fade" mode="out-in">
      <component
        :is="currDialog"
        :i18nPrefix="i18nPrefix"
        v-bind="dialogData"
        @toggle="currDialog = undefined"
      ></component>
    </transition>
  </div>
</template>

<script>
import {
  getList,
  getTotal,
  getExcel,
} from "@/api/statistical-report/agentStatisticalAnalysis";
import Rebate from "./dialog/rebate";
import { searchModel } from "./model";
import { transTodayDate, joinT, transNow } from "@/utils/transDateUtils";
import { mapGetters } from "vuex";
import QuickSearch from "@/layout/components/QuickSearch";
// import serverPagination from "@/layout/components/serverPagination";
import handleServerPageUtils from "@/layout/mixins/handleServerPageUtils";
import handleDialog from "@/layout/mixins/handleDialog";
import TreeTable from "@/layout/components/TreeTable";
import Multiselect from "vue-multiselect";
import { download } from "@/utils/download";

export default {
  name: "AgentStatisticalAnalysis",
  data() {
    return {
      searchForm: _.cloneDeep(searchModel),
      startTime: transTodayDate(),
      endTime: transTodayDate(),
      agentData: [],
      i18nPrefix: "statistical-report.agentStatisticalAnalysis.",
      total: {},
    };
  },
  watch: {},
  components: {
    QuickSearch,
    // serverPagination,
    Rebate,
    TreeTable,
    Multiselect,
  },
  computed: {
    ...mapGetters([
      "getAgentListAddDefault",
      "getGamesCategorysAddDefault",
      "getUserinfo",
    ]),
    tableColumn() {
      switch (this.searchForm.type) {
        case 1:
          const kr_case1 = [
            {
              key: "id",
              name: this.$t("default.sort"),
            },
            {
              key: "name",
              name: this.$t("default.agent"),
              tableHeadAlign: true,
            },
            {
              key: "totalBetCount",
              name: this.$t(`${this.i18nPrefix}totalBetCount`),
            },
            {
              key: "totalValidBetAmount",
              name: this.$t(`${this.i18nPrefix}totalValidBetAmount`),
            },
            {
              key: "totalProfit",
              name: this.$t(`${this.i18nPrefix}profit`),
            },
            {
              key: "commissionRate",
              name: this.$t(`${this.i18nPrefix}commission_proportion`),
            },
            {
              key: "action",
              name: "",
            },
          ];
          const other_case1 = [
            {
              key: "name",
              name: this.$t("default.agent"),
              tableHeadAlign: true,
              align: "left",
            },
            {
              key: "totalBetCount",
              name: this.$t(`${this.i18nPrefix}totalBetCount`),
            },
            {
              key: "totalValidBetAmount",
              name: this.$t(`${this.i18nPrefix}totalValidBetAmount`),
            },
            {
              key: "totalWinLose",
              name: this.$t(`${this.i18nPrefix}totalWinLose`),
            },
            {
              key: "totalWinLoseWithoutLottery",
              name: this.$t(`${this.i18nPrefix}totalWinLoseWithoutLottery`),
            },
            {
              key: "selfBetCount",
              name: this.$t(`${this.i18nPrefix}selfBetCount`),
            },
            {
              key: "selfValidBetAmount",
              name: this.$t(`${this.i18nPrefix}selfValidBetAmount`),
            },
            {
              key: "selfWinLose",
              name: this.$t(`${this.i18nPrefix}selfWinLose`),
            },
            {
              key: "selfWinLoseWithoutLottery",
              name: this.$t(`${this.i18nPrefix}selfWinLoseWithoutLottery`),
            },
            {
              key: "totalAgentRebateAmount",
              name: this.$t(`${this.i18nPrefix}totalRebateAmount`),
            },
            {
              key: "action",
              name: "",
            },
          ];
          return this.getUserinfo.koreanStyleInterface ? kr_case1 : other_case1;
        case 2:
          const kr_case2 = [
            {
              key: "id",
              name: this.$t("default.sort"),
            },
            {
              key: "agentAccount",
              name: this.$t("default.agent"),
            },
            {
              key: "selfBetCount",
              name: this.$t(`${this.i18nPrefix}selfBetCount`),
            },
            {
              key: "selfValidBetAmount",
              name: this.$t(`${this.i18nPrefix}selfValidBetAmount`),
            },
            {
              key: "selfWinLose",
              name: this.$t(`${this.i18nPrefix}selfWinLose`),
            },
          ];
          const other_case2 = [
            {
              key: "name",
              name: this.$t("default.member"),
            },
            {
              key: "totalBetCount",
              name: this.$t(`${this.i18nPrefix}totalBetCount`),
            },
            {
              key: "totalValidBetAmount",
              name: this.$t(`${this.i18nPrefix}totalValidBetAmount`),
            },
            {
              key: "totalWinLose",
              name: this.$t(`${this.i18nPrefix}totalWinLose`),
            },
            {
              key: "selfBetCount",
              name: this.$t(`${this.i18nPrefix}selfBetCount`),
            },
            {
              key: "selfValidBetAmount",
              name: this.$t(`${this.i18nPrefix}selfValidBetAmount`),
            },
            {
              key: "selfWinLose",
              name: this.$t(`${this.i18nPrefix}selfWinLose`),
            },
            {
              key: "totalRebateAmount",
              name: this.$t(`${this.i18nPrefix}totalRebateAmount`),
            },
          ];
          return this.getUserinfo.koreanStyleInterface ? kr_case2 : other_case2;
        case 3:
          return [
            {
              key: "name",
              name: this.$t(`${this.i18nPrefix}game`),
            },
            {
              key: "selfBetCount",
              name: this.$t(`${this.i18nPrefix}selfBetCount`),
            },
            {
              key: "selfValidBetAmount",
              name: this.$t(`${this.i18nPrefix}selfValidBetAmount`),
            },
            {
              key: "selfWinLose",
              name: this.$t(`${this.i18nPrefix}selfWinLose`),
            },
          ];
      }
    },
    bookmarkColumn() {
      const allData = [
        {
          key: this.searchForm.type,
          name: "bookmark",
        },
        {
          key: 1,
          name: this.$t("default.agent"),
        },
        {
          key: 2,
          name: this.$t("default.member"),
        },
        {
          key: 3,
          name: this.$t(`${this.i18nPrefix}game`),
        },
      ];
      if (this.getUserinfo.koreanStyleInterface) allData.pop();
      return allData;
    },
  },
  mixins: [handleServerPageUtils, handleDialog],
  created() {
    // this.$nextTick(function() {
    //   setTimeout(() => {
    //     getList(this.searchForm).then(res => {
    //       console.log(res);
    //     });
    //   }, 200);
    // });
  },
  methods: {
    async search() {
      //時間參數
      this.searchForm.startTime = this.startTime
        ? joinT(this.startTime + this.GLOBAL.startHour + this.GLOBAL.timeZone)
        : undefined;
      this.searchForm.endTime = this.endTime
        ? joinT(this.endTime + this.GLOBAL.endHour + this.GLOBAL.timeZone)
        : undefined;

      this.searchForm.agentId = this.agentData.id;
      this.searchPage();

      getTotal(this.searchForm).then((res) => {
        this.total = res.data;
      });
    },
    searchPage(page = false) {
      this.searchForm.page.take = this.take * 1;
      this.searchForm.page.skip = this.take * (page ? page - 1 : 0);

      getList(this.searchForm).then((res) => {
        this.allData = res.data?.list;
        this.page = res.data.page;
      });
    },
    changeTime(time) {
      this.startTime = time.startTime;
      this.endTime = time.endTime;
      this.search();
    },
    bookmark(data, agentId = false) {
      if (agentId) this.searchForm.agentId = agentId;
      this.allData = undefined;
      this.searchForm.type = data;
      this.search();
    },
    handleRebate(id) {
      let rebateForm = _.cloneDeep(this.searchForm);
      rebateForm.agentId = id;

      this.handleDialog({ rebateForm: rebateForm }, "Rebate");
    },
    download() {
      //時間參數
      this.searchForm.startTime = this.startTime
        ? joinT(this.startTime + this.GLOBAL.startHour + this.GLOBAL.timeZone)
        : undefined;
      this.searchForm.endTime = this.endTime
        ? joinT(this.endTime + this.GLOBAL.endHour + this.GLOBAL.timeZone)
        : undefined;

      this.searchForm.agentId = this.agentData.id;
      let body = { ...this.searchForm };
      let now = transNow("onlyNum");
      body.page.take = 0;
      body.page.skip = 0;

      getExcel(body).then((res) =>
        download(res, `${now}-AgentStatisticalAnalysis.xlsx`)
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.agent-count {
  display: flex;
  width: 100%;
}
</style>
