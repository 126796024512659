
export let searchModel = {
  "agentId": undefined,
  "account": undefined,
  "startTime": undefined,
  "endTime": undefined,
  "status": 0,
    page:  {
        "take": 10,
        "skip": 0
    }
}