var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "container-view" }, [
      _c(
        "button",
        {
          staticClass: "search-btn main-style-btn",
          on: {
            click: function ($event) {
              return _vm.search()
            },
          },
        },
        [_vm._v(_vm._s(_vm.$t("default.search")))]
      ),
      _c("div", { staticClass: "header" }, [
        _c("h5", [_vm._v(_vm._s(_vm.$t("title." + _vm.$route.meta.title)))]),
      ]),
      _c("div", { staticClass: "main" }, [
        _c("div", { staticClass: "input-block" }, [
          _c(
            "div",
            { staticClass: "input-block-multiselect" },
            [
              _vm._v(" " + _vm._s(_vm.$t("default.agent")) + " "),
              _c("multiselect", {
                attrs: {
                  label: "name",
                  "track-by": "id",
                  options: _vm.getAgentListAddDefault,
                  "allow-empty": false,
                  preselectFirst: true,
                  "clear-on-select": false,
                  "preserve-search": true,
                  "custom-label": _vm.GLOBAL.nameWithLang,
                },
                model: {
                  value: _vm.agentData,
                  callback: function ($$v) {
                    _vm.agentData = $$v
                  },
                  expression: "agentData",
                },
              }),
            ],
            1
          ),
        ]),
        _c(
          "div",
          { staticClass: "advance-block" },
          [
            _c("QuickSearch", { on: { changeTime: _vm.changeTime } }),
            _c(
              "button",
              {
                staticClass: "search-pc-btn main-style-btn px-5",
                on: {
                  click: function ($event) {
                    return _vm.search()
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("default.search")))]
            ),
          ],
          1
        ),
      ]),
    ]),
    _c("div", { staticClass: "container-view" }, [
      _c(
        "div",
        {
          staticClass: "main",
          staticStyle: { display: "flex", "flex-direction": "column" },
        },
        [
          _c(
            "div",
            {
              staticClass: "form-box w-100",
              staticStyle: { "justify-content": "center !important" },
            },
            [
              _c(
                "div",
                { staticClass: "total totalTopBox form-box-half bg-white" },
                [
                  _c(
                    "div",
                    { staticClass: "form-box-half form-column total-top" },
                    [
                      _c("div", { staticClass: "total-center" }, [
                        _c("div", { staticClass: "total-top-title" }, [
                          _vm._v(
                            _vm._s(_vm.$t(`${_vm.i18nPrefix}totalProfitAmount`))
                          ),
                        ]),
                        _c("div", { staticClass: "total-top-num" }, [
                          _vm._v(
                            _vm._s(
                              _vm.digital.format(_vm.data.totalProfitAmount)
                            )
                          ),
                        ]),
                      ]),
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "form-box-half form-column total-top" },
                    [
                      _c("div", { staticClass: "total-center" }, [
                        _c("div", { staticClass: "total-top-title" }, [
                          _vm._v(
                            _vm._s(_vm.$t(`${_vm.i18nPrefix}totalRebateAmount`))
                          ),
                        ]),
                        _c("div", { staticClass: "total-top-num" }, [
                          _vm._v(
                            _vm._s(
                              _vm.digital.format(_vm.data.totalRebateAmount)
                            )
                          ),
                        ]),
                      ]),
                    ]
                  ),
                ]
              ),
            ]
          ),
          _c("div", { staticClass: "form-box mt-2" }, [
            _c("div", { staticClass: "form-box-half gap-20" }, [
              _c(
                "div",
                { staticClass: "total totalTopBox form-box bg-white" },
                [
                  _c(
                    "div",
                    { staticClass: "form-box-half form-column total-top" },
                    [
                      _c("div", { staticClass: "total-center" }, [
                        _c("div", { staticClass: "total-top-title" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t(`${_vm.i18nPrefix}totalCommisionAmount`)
                            )
                          ),
                        ]),
                        _c("div", { staticClass: "total-top-num" }, [
                          _vm._v(
                            _vm._s(
                              _vm.digital.format(_vm.data.totalCommisionAmount)
                            )
                          ),
                        ]),
                      ]),
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "form-box-half form-column total-top" },
                    [
                      _c("div", { staticClass: "total-center" }, [
                        _c("div", { staticClass: "total-top-title" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t(`${_vm.i18nPrefix}totalDiscountAmount`)
                            )
                          ),
                        ]),
                        _c("div", { staticClass: "total-top-num" }, [
                          _vm._v(
                            _vm._s(
                              _vm.digital.format(_vm.data.totalDiscountAmount)
                            )
                          ),
                        ]),
                      ]),
                    ]
                  ),
                ]
              ),
            ]),
            _c("div", { staticClass: "form-box-half gap-20" }, [
              _c(
                "div",
                { staticClass: "total totalTopBox form-box bg-white" },
                [
                  _c(
                    "div",
                    { staticClass: "form-box-half form-column total-top" },
                    [
                      _c("div", { staticClass: "total-center" }, [
                        _c("div", { staticClass: "total-top-title" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t(`${_vm.i18nPrefix}totalRegisterCount`)
                            )
                          ),
                        ]),
                        _c("div", { staticClass: "total-top-num" }, [
                          _vm._v(
                            _vm._s(
                              _vm.digital.format(_vm.data.totalRegisterCount)
                            )
                          ),
                        ]),
                      ]),
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "form-box-half form-column total-top" },
                    [
                      _c("div", { staticClass: "total-center" }, [
                        _c("div", { staticClass: "total-top-title" }, [
                          _vm._v(
                            _vm._s(_vm.$t(`${_vm.i18nPrefix}totalActiveCount`))
                          ),
                        ]),
                        _c("div", { staticClass: "total-top-num" }, [
                          _vm._v(
                            _vm._s(
                              _vm.digital.format(_vm.data.totalActiveCount)
                            )
                          ),
                        ]),
                      ]),
                    ]
                  ),
                ]
              ),
            ]),
          ]),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }