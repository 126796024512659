const getters = {
    sidebar: state => state.app.sidebar,
    loading: state => state.app.loading,
    agentInfo: state => state.app.agentInfo,
    timeZone: state => state.user.timeZone,
    navHeight: state => state.user.navHeight,
    getPage: state => state.app.page,
    token: state => state.user.token,
    remeberData: state => state.user.remeberData,
    isRemeber: state => state.user.isRemeber,
    memberCount: state => state.user.memberCount,
    lang: state => state.user.lang,
    userName: state => {
        let name = [...state.user.account];
        name[0] = name[0].toUpperCase();
        return name.join("");
    },
    getUserinfo: state => state.user.userInfo,
    getSelectedAgentId: state => state.user.selectedAgentId,
    getCompanyCode: state => state.user.companyCode,
    getUserDropDown: state => state.user.userDropDown,
    getShowConfirm: state => state.confirm.showConfirm,
    getCurrDialog: state => state.dialog.currentDialogComponent,
    getWeekList: state => state.select_menu.week,
    getStatusList: state => state.select_menu.statusList,
    getStatusListAddDefault: state => [state.select_menu.none, ...getters.getStatusList(state) || []],
    getAuditStatusList: state => state.select_menu.auditStatus,
    getAuditStatusListAddDefault: state => [state.select_menu.none, ...getters.getAuditStatusList(state) || []],
    getDiscountAuditStatus: state => state.select_menu.discountAuditStatus,
    getDiscountAuditStatusAddDefault: state => [state.select_menu.none, ...getters.getDiscountAuditStatus(state) || []],
    getPaymentStatusList: state => state.select_menu.paymentStatus,
    getPaymentStatusListAddDefault: state => [state.select_menu.none, ...getters.getPaymentStatusList(state) || []],
    getBankList: state => state.select_menu.bankList,
    getBankListAddDefault: state => [state.select_menu.none, ...getters.getBankList(state) || []],
    getBankGroupList: state => state.select_menu.bankGroupList,
    getBankGroupListAddDefault: state => [state.select_menu.none, ...getters.getBankGroupList(state) || []],
    getLevelGroupsList: state => state.select_menu.levelGroupsList,
    getPaymentList: state => state.select_menu.paymentList,
    getPaymentListAddDefault: state => [state.select_menu.none, ...getters.getPaymentList(state) || []],
    getAgentList: state => state.select_menu.agentList,
    getAgentListAddDefault: state => [state.select_menu.none, ...getters.getAgentList(state) || []],
    geterrorCodeList: state => state.select_menu.errorCode,
    getDeviceList: state => state.select_menu.deviceList,
    getDeviceType: state => state.select_menu.deviceType,
    getMail: state => state.user.haveMail,
    getMailData: state => state.user.mailData,
    getMailTypeList: state => state.select_menu.mailTypeList,
    getDiscountTypeList: state => state.select_menu.discountTypes,
    getDiscountTypeListAddDefault: state => [state.select_menu.none, ...getters.getDiscountTypeList(state) || []],
    getBankTypeList: state => state.select_menu.bankTypes,
    getDefaultSelect: state => state.select_menu.none,
    getShowCount: state => state.app.showCount,
    getMailLastId: state => state.user.mailLastId,
    getNotification: state => state.user.notification,
    getGamePlatforms: state => state.select_menu.gamePlatforms,
    getGamePlatformsAddDefault: state => [state.select_menu.none, ...getters.getGamePlatforms(state) || []],
    getGamesCategorys: state => state.select_menu.gamesCategorys,
    getGamesCategorysAddDefault: state => [state.select_menu.none, ...getters.getGamesCategorys(state) || []],
    getStars: state => state.select_menu.stars,
    tagList: state => state.select_menu.tagList,
    getAgentWithdrawalInfoList: state => state.select_menu.agentWithdrawalInfoList,
    getCompanyDepositSettingsList: state => state.select_menu.companyDepositSettingsList,
    getAnnouncementTypes: state => state.select_menu.announcementTypes,
    getRebateGamePlatformList: state => state.select_menu.rebateGamePlatformList,
    getGameCategoryAndPlatform: state => state.select_menu.gameCategoryAndPlatform,
    getLoginType: state =>state.select_menu.loginType
};

export default getters;
