
export let searchModel = {
    account: undefined,
    nickName: undefined,
    bankGroupId: 0,
    startCreateTime: undefined,
    endCreateTime: undefined,
    referralCode: undefined,
    bankId: 0,
    bankAccountName: undefined,
    bankAccountNumber: undefined,
    agentId: 0,
    tagId: 0,
    phoneNumber: undefined,
    name: undefined,
    socialId: undefined,
    star: undefined,
    ip: undefined,
    email: undefined,
    isFuzzySearch: false,
    totalWinning: 0,  // 0不拘 1 大→小(DESC) 2小→大(ASC)
    mainWallet: 0,   // 0不拘 1 大→小(DESC) 2小→大(ASC)
    pointsWallet: 0,   //0不拘 1 大→小(DESC) 2小→大(ASC)
    page: {
        "take": 10,
        "skip": 0
    },
}