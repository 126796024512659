var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "dialog-mask",
      on: {
        mousedown: function ($event) {
          if ($event.target !== $event.currentTarget) return null
          return _vm.$emit(_vm.list.toggle ? "toggle" : "detailToggle")
        },
      },
    },
    [
      _c("div", { staticClass: "dialog-container" }, [
        _c("div", { staticClass: "dialog-block" }, [
          _c("div", { staticClass: "header" }, [
            _c("h4", [
              _vm._v(
                _vm._s(
                  _vm.$t("default.member") + " 【" + _vm.list.account + "】"
                )
              ),
            ]),
          ]),
          _c("div", { staticClass: "divider" }),
          _c("main", [
            _c("div", { staticClass: "input-block" }, [
              _c(
                "div",
                {},
                [
                  _c("MemberIdentity", {
                    attrs: {
                      list: _vm.detailData.memberIdentity,
                      chooice: _vm.chooice,
                      i18nPrefix: _vm.i18nPrefix,
                    },
                    on: { editting: _vm.editting, reload: _vm.load },
                  }),
                ],
                1
              ),
              _c(
                "div",
                {},
                [
                  _c("MemberMonetary", {
                    attrs: {
                      list: _vm.detailData.memberMonetary,
                      chooice: _vm.chooice,
                      memberData: _vm.memberData,
                      totalGameBalance: _vm.totalGameBalance,
                      i18nPrefix: _vm.i18nPrefix,
                    },
                    on: { editting: _vm.editting, reload: _vm.load },
                  }),
                ],
                1
              ),
              _c(
                "div",
                {},
                [
                  _c("MemberRegistry", {
                    attrs: {
                      list: _vm.detailData.memberRegistry,
                      chooice: _vm.chooice,
                      memberData: _vm.memberData,
                      i18nPrefix: _vm.i18nPrefix,
                    },
                    on: { editting: _vm.editting, reload: _vm.load },
                  }),
                ],
                1
              ),
              _c(
                "div",
                {},
                [
                  _c("MemberActivity", {
                    attrs: {
                      list: _vm.detailData.memberActivity,
                      chooice: _vm.chooice,
                      memberData: _vm.memberData,
                      i18nPrefix: _vm.i18nPrefix,
                    },
                    on: { editting: _vm.editting, reload: _vm.load },
                  }),
                ],
                1
              ),
              _vm.getUserinfo.koreanStyleInterface
                ? _c(
                    "div",
                    {},
                    [
                      _c("MemberFeature", {
                        attrs: {
                          list: _vm.detailData.memberFeature,
                          chooice: _vm.chooice,
                          memberData: _vm.memberData,
                          i18nPrefix: _vm.i18nPrefix,
                        },
                        on: { editting: _vm.editting, reload: _vm.load },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c("div", { staticClass: "w-100" }, [
                _c(
                  "div",
                  {
                    staticClass: "divider-v2-area",
                    staticStyle: { display: "block" },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "divider-v2-text divider-v2-left" },
                      [_vm._v(_vm._s(_vm.$t("default.remark")))]
                    ),
                    _c("div", { staticClass: "textarea-Div" }, [
                      _c(
                        "button",
                        {
                          staticClass: "confirm-btn",
                          attrs: { disabled: _vm.chooice !== "remark" },
                          on: {
                            click: function ($event) {
                              return _vm.addNow()
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("default.now")) + " ")]
                      ),
                      _c("textarea", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.remark,
                            expression: "remark",
                          },
                        ],
                        attrs: {
                          id: "myTextarea",
                          rows: "5",
                          disabled: _vm.chooice !== "remark",
                        },
                        domProps: { value: _vm.remark },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.remark = $event.target.value
                          },
                        },
                      }),
                      _c("div", { staticClass: "btnDiv" }, [
                        _c(
                          "button",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.chooice !== "remark",
                                expression: "chooice !== 'remark'",
                              },
                            ],
                            staticClass: "edit-btn",
                            attrs: { disabled: _vm.chooice !== undefined },
                            on: {
                              click: function ($event) {
                                _vm.chooice = "remark"
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("default.edit")) + " ")]
                        ),
                        _c(
                          "button",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.chooice == "remark",
                                expression: "chooice == 'remark'",
                              },
                            ],
                            staticClass: "normal-btn",
                            on: {
                              click: function ($event) {
                                return _vm.updateRemark()
                              },
                            },
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.$t("default.confirm")) + " "
                            ),
                          ]
                        ),
                        _c(
                          "button",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.chooice == "remark",
                                expression: "chooice == 'remark'",
                              },
                            ],
                            staticClass: "delete-btn",
                            on: {
                              click: function ($event) {
                                return _vm.cancel("remark")
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("default.cancel")) + " ")]
                        ),
                      ]),
                    ]),
                  ]
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }