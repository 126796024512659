var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "dialog-mask",
      on: {
        mousedown: function ($event) {
          if ($event.target !== $event.currentTarget) return null
          return _vm.$emit("toggle")
        },
      },
    },
    [
      _c("div", { staticClass: "dialog-container edit-member" }, [
        _c("div", { staticClass: "dialog-block" }, [
          _c("div", { staticClass: "header" }, [
            _c("h4", [
              _vm._v(
                _vm._s(_vm.$t("default.edit")) +
                  " " +
                  _vm._s(_vm.$t("title." + _vm.$route.meta.title))
              ),
            ]),
          ]),
          _c("div", { staticClass: "divider" }),
          _c("main", [
            _c("div", { staticClass: "input-block" }, [
              _c("label", [
                _vm._v(" " + _vm._s(_vm.$t("default.gamePlatform")) + " "),
                _c("input", {
                  attrs: { type: "text", disabled: "" },
                  domProps: {
                    value: _vm.GLOBAL.i18nCheckName(
                      _vm.getGamePlatforms.find(
                        (data) => _vm.gamePlatform === data.id
                      )
                    ),
                  },
                }),
              ]),
              _c("div", [
                _c("label", [
                  _vm._v(" " + _vm._s(_vm.$t("default.nowCsinoChips")) + " "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.amount,
                        expression: "amount",
                      },
                    ],
                    attrs: { type: "text", disabled: "" },
                    domProps: { value: _vm.amount },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.amount = $event.target.value
                      },
                    },
                  }),
                ]),
                _c("label", [
                  _vm._v(" " + _vm._s(_vm.$t("default.addCasinoChips")) + " "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.number",
                        value: _vm.editModel.amount,
                        expression: "editModel.amount",
                        modifiers: { number: true },
                      },
                    ],
                    attrs: { type: "number" },
                    domProps: { value: _vm.editModel.amount },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.editModel,
                          "amount",
                          _vm._n($event.target.value)
                        )
                      },
                      blur: function ($event) {
                        return _vm.$forceUpdate()
                      },
                    },
                  }),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "advance-block" }, [
              _c(
                "button",
                {
                  staticClass: "confirm-btn main-style-btn",
                  on: {
                    click: function ($event) {
                      return _vm.$emit("edit", _vm.editModel)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("default.confirm")))]
              ),
              _c(
                "button",
                {
                  staticClass: "normal-btn-v2 main-style-btn",
                  on: {
                    click: function ($event) {
                      return _vm.$emit("toggle")
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("default.cancel")))]
              ),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }