var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "dialog-mask",
      on: {
        mousedown: function ($event) {
          if ($event.target !== $event.currentTarget) return null
          return _vm.$emit("toggle")
        },
      },
    },
    [
      _c("div", { staticClass: "dialog-container" }, [
        _c("div", { staticClass: "dialog-block" }, [
          _c("div", { staticClass: "header" }, [
            _c("h4", [
              _vm._v(
                _vm._s(_vm.$t("Permission." + _vm.$route.meta.permissionName))
              ),
            ]),
          ]),
          _c("div", { staticClass: "divider" }),
          _c("main", [
            _c("div", { staticClass: "input-block" }, [
              _c("label", [
                _vm._v(" " + _vm._s(_vm.$t("default.member")) + " "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.account,
                      expression: "account",
                    },
                  ],
                  attrs: { type: "text", disabled: "" },
                  domProps: { value: _vm.account },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.account = $event.target.value
                    },
                  },
                }),
              ]),
              _c("label", [
                _vm._v(" " + _vm._s(_vm.$t("default.createTime")) + " "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.createTime,
                      expression: "createTime",
                    },
                  ],
                  attrs: { type: "text", disabled: "" },
                  domProps: { value: _vm.createTime },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.createTime = $event.target.value
                    },
                  },
                }),
              ]),
            ]),
            _c("div", { staticClass: "advance-block" }, [
              _vm.status !== 2
                ? _c(
                    "button",
                    {
                      staticClass: "normal-btn",
                      on: {
                        click: function ($event) {
                          if ($event.target !== $event.currentTarget)
                            return null
                          return _vm.edit(2)
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("default.accept")))]
                  )
                : _vm._e(),
              _vm.status !== 3
                ? _c(
                    "button",
                    {
                      staticClass: "delete-btn",
                      on: {
                        click: function ($event) {
                          if ($event.target !== $event.currentTarget)
                            return null
                          return _vm.edit(3)
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("default.reject")))]
                  )
                : _vm._e(),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }