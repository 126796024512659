<template>
  <div>
    <div class="container-view">
      <button class="search-btn main-style-btn" @click="search()">{{ $t("default.search") }}</button>
      <div class="header">
        <h5>{{ $t("title." + $route.meta.title) }}</h5>
      </div>
      <div class="main">
        <div class="input-block">
          <label>
            {{ $t("default.member") }}
            <input type="text" v-model="searchForm.memberAccount" />
          </label>
          <label>
            {{ $t(`${i18nPrefix}riskControlStatus`) }}
            <input type="number" max="100" min="0" v-model.number="searchForm.riskControlStatus" />
          </label>
          <label>
            {{ $t(`${i18nPrefix}startTime`) }}
            <date-picker v-model="startTime" format="YYYY-MM-DD HH:mm" value-type="format" type="datetime"></date-picker>
          </label>
          <label>
            {{ $t(`${i18nPrefix}endTime`) }}
            <date-picker v-model="endTime" format="YYYY-MM-DD HH:mm" value-type="format" type="datetime"></date-picker>
          </label>
        </div>
        <div class="advance-block">
          <QuickSearch @changeTime="changeTime"></QuickSearch>
          <button class="search-pc-btn main-style-btn px-5" @click="search()">{{ $t("default.search") }}</button>
        </div>
      </div>
    </div>
    <div class="container-view">
      <div class="table-block">
        <table>
          <thead>
            <tr>
              <th>{{ $t("default.member") }}</th>
              <th>{{ $t(`${i18nPrefix}status`) }}</th>
              <th>{{ $t(`${i18nPrefix}betTime`) }}</th>
              <th>{{ $t(`${i18nPrefix}winLose`) }}</th>
              <th>{{ $t(`${i18nPrefix}maxWinLoseDaily`) }}</th>
              <th>％</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="!tableList || tableList.length === 0">
              <td colspan="7">{{ $t("default.noData") }}</td>
            </tr>
            <tr v-for="(list, index) of tableList" :key="list.createTime + '_' + index">
              <td>{{ list.memberAccount }}</td>
              <td>{{ list.status === 2 ? $t("default.yes") : $t("default.no") }}</td>
              <td>{{ list.betTime }}</td>
              <td>{{ list.winLose }}</td>
              <td>{{ list.maxWinLoseDaily }}</td>
              <td>{{ list.riskControlStatus }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <Pagination
        v-if="totalCount > 0"
        :totalCount="totalCount"
        @handlePage="handlePage"
      />
    </div>
  </div>
</template>

<script>
import { searchModel } from "./model";
import { riskControlList } from "@/api/features/risk-control";
import handlePageUtils from "@/layout/mixins/handlePageUtils";
import Pagination from "@/layout/components/Pagination";
import { mapGetters } from 'vuex';
import QuickSearch from "@/layout/components/QuickSearch";
import { checkData }  from "@/utils/checkData";
import { joinT } from "@/utils/transDateUtils";

export default {
  name: "RiskControl",
  data() {
    return {
      searchForm: _.cloneDeep(searchModel),
      startTime: undefined,
      endTime: undefined,
      statusList: [
        {
          id: 1,
          name: "features.risk-control.enable"
        },
        {
          id: 2,
          name: "features.risk-control.disable"
        },
        {
          id: 3,
          name: "features.risk-control.systemStop"
        }
      ],
      controlType: true,
      i18nPrefix: "features.risk-control."
    };
  },
  mixins: [handlePageUtils],
  components: {
    Pagination,
    QuickSearch,
  },
  computed: {
    ...mapGetters(["getStatusList"])
  },
  created() {
    if (this.$route.query?.orderId) {
      riskControlList({ id: this.$route.query?.orderId * 1 }).then((res) => {
        this.allData = res.data;
        this.currDialog = undefined;
      });
    }5
  },
  methods: {
    search() {
        this.searchForm.startTime = this.startTime ? joinT(this.startTime + this.GLOBAL.startSecond + this.GLOBAL.timeZone) : undefined;
        this.searchForm.endTime = this.endTime ? joinT(this.endTime + this.GLOBAL.endSecond+ this.GLOBAL.timeZone) : undefined;
      if (checkData(this.searchForm)) {
        riskControlList(this.searchForm).then((res) => {
          this.allData = res.data;
        });
      }
    },
    changeTime(time) {
      this.startTime = time.startTime;
      this.endTime = time.endTime;
      this.search();
    },
  },
};
</script>
<style lang="scss" scoped>
.input-block {
  margin-bottom: 10px;
}
</style>