<template>
  <div>
    <div class="bookmark-box">
      <div class="bookmark-view">
        <div v-for="list of bookmark" :key="list.id" @click="chooice = list.id"
          v-if="!$store.getters.getUserinfo.permissions.includes(list.permissions)"
          :class="{ 'chooice': chooice == list.id }">
          {{ $t(list.name) }}</div>
      </div>
    </div>
    <component :is="chooice"></component>
  </div>
</template>

<script>
import Search from "./search";
import BatchSend from "./batchSend";
import WelcomeMessage from "./welcomeMessage";

export default {
  name: "index",
  data() {
    return {
      i18nPrefix: "features.memberPrivateMessage.",
      chooice: "Search",
      bookmark: [
        {
          id: "batchSend",
          name: "features.memberPrivateMessage.memberPrivateMessageStatus",
          permissions: "62600"
        },
        {
          id: "Search",
          name: "features.memberPrivateMessage.Search",
          permissions: "62600"
        },
        {
          id: "WelcomeMessage",
          name: "features.memberPrivateMessage.welcomeMessage",
          permissions: "62602"
        }
      ]
    };
  },
  components: {
    Search,
    BatchSend,
    WelcomeMessage,
  },
  computed: {
  },
  methods: {}
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped></style>