<template>
  <div class="dialog-mask2">
    <div ref="dialog" class="dialog-container">
      <div class="dialog-header">
        <h4>{{ $t(`default.${editData !== undefined ? 'edit' : 'create'}`) }}</h4>
        <div class="dialog-close" @click="$emit('toggle')">
          <i class="fas fa-times"></i>
        </div>
      </div>
      <div class="dialog-block">
        <main>
          <div class="input-block">
            <div class="divider-v2">
              <!-- 基本設定 -->
              <div class="divider-v2-text divider-v2-left">{{ $t(`${i18nPrefix}dialog.basicSettings`) }}</div>
            </div>
            <div class="form-box-half form-column">
              <div class="form-box">
                <!-- 名稱 -->
                <label>
                  {{ $t(`${i18nPrefix}campaignName`) }}
                  <input type="text" :placeholder="$t(`${i18nPrefix}campaignName`)" v-model="formModel.name" />
                </label>
              </div>
              <div class="form-box">
                <!-- 週期 -->
                <label>
                  <span class="span-row">
                    <input type="checkbox" v-model="ruleSetting.basicInformation.memberCondition.isEnabled" />
                    {{ $t(`${i18nPrefix}dialog.lotteryCountLimit`) }}
                  </span>
                  <div class="w-100">
                    <select class="w-50" :disabled="!ruleSetting.basicInformation.memberCondition.isEnabled"
                      v-model="ruleSetting.basicInformation.memberCondition.periodType">
                      <option v-for="list of periodType" :key="list.id" :value="list.id">{{ $t(list.name) }}</option>
                    </select>
                    <input class="w-50" type="number" min="0" :placeholder="$t(`${i18nPrefix}dialog.times`)"
                      :disabled="!ruleSetting.basicInformation.memberCondition.isEnabled"
                      v-model.number="ruleSetting.basicInformation.memberCondition.lotteryCountLimit" />
                  </div>
                </label>
                <!-- 上限 -->
                <label>
                  <span class="span-row">
                    <input type="checkbox" v-model="ruleSetting.basicInformation.lotteryCondition.isEnabled" />
                    {{ $t(`${i18nPrefix}dialog.totalLotteryCount`) }}
                  </span>
                  <input type="number" min="0" :placeholder="$t(`${i18nPrefix}dialog.times`)"
                    :disabled="!ruleSetting.basicInformation.lotteryCondition.isEnabled"
                    v-model.number="ruleSetting.basicInformation.lotteryCondition.totalLotteryCount" />
                  <span ref="rulesname" class="form-rules"></span>
                </label>
              </div>
            </div>
            <div class="form-box-half">
              <div class="form-box">
                <!-- startTime -->
                <label>
                  <span>{{ $t('default.startTime') }}</span>
                  <date-picker v-model="startTime" format="YYYY-MM-DD HH" value-type="format" type="datetime"
                    :disabled-date="disabledStart"></date-picker>
                  <span ref="rulesstartTime" class="form-rules"></span>
                </label>
                <!-- endTime -->
                <label>
                  <span>{{ $t('default.endTime') }}</span>
                  <date-picker v-model="endTime" format="YYYY-MM-DD HH:mm" value-type="format" type="datetime"
                    :disabled-date="disabledEnd"></date-picker>
                  <span ref="rulesendTime" class="form-rules"></span>
                </label>
              </div>
            </div>
            <div class="divider-v2">
              <div class="divider-v2-text divider-v2-left">{{ $t(`${i18nPrefix}dialog.eligibility`) }}</div>
            </div>
            <div class="form-box-half form-column">
              <div class="form-box">
                <!-- 新註冊會員 -->
                <label class="label-row">
                  <input type="checkbox" v-model="ruleSetting.participateStatus.isNewMember" />
                  {{ $t(`${i18nPrefix}dialog.isNewMember`) }}
                </label>
                <!-- 存款條件 -->
                <label>
                  <span class="span-row">
                    <input type="checkbox" v-model="ruleSetting.participateStatus.depositCondition.isEnabled" />
                    {{ $t(`${i18nPrefix}dialog.depositCondition`) }}
                  </span>
                  <div class="w-100">
                    <select class="w-50" :disabled="!ruleSetting.participateStatus.depositCondition.isEnabled"
                      v-model="ruleSetting.participateStatus.depositCondition.periodType">
                      <option v-for="list of periodType" :key="list.id" :value="list.id">{{ $t(list.name) }}</option>
                    </select>
                    <input class="w-50" type="number" min="0" :placeholder="$t('default.amount')"
                      :disabled="!ruleSetting.participateStatus.depositCondition.isEnabled" @keyup="ruleSetting.participateStatus.depositCondition.amount =
                        GLOBAL.checkValue(ruleSetting.participateStatus.depositCondition.amount, 0, 1, 999999999999)"
                      v-model.number="ruleSetting.participateStatus.depositCondition.amount" />
                  </div>
                </label>
              </div>
              <div class="form-box">
                <!-- 限定代理 -->
                <label>
                  {{ $t(`${i18nPrefix}dialog.includeAgents`) }}
                  <multiselect v-model="agentData" label="name" track-by="id" :placeholder="''" :options="agentList"
                    :multiple="true" :close-on-select="false">
                    <template slot="noOptions">{{ $t("default.noData") }}</template>
                  </multiselect>
                </label>
              </div>
            </div>
            <div class="form-box-half">
              <div class="form-box">
                <!-- 限定會員等級 -->
                <label>
                  {{ $t(`${i18nPrefix}dialog.limitMemberLevel`) }}
                  <multiselect v-model="memberStar" label="name" track-by="star" :placeholder="''" :options="starList"
                    :multiple="true" :close-on-select="false"></multiselect>
                </label>
                <!-- 限定會員標籤 -->
                <label>
                  {{ $t(`${i18nPrefix}dialog.limitMemberTag`) }}
                  <multiselect v-model="memberTag" label="name" track-by="id" :placeholder="''" :options="tagList"
                    :multiple="true" :close-on-select="false">
                    <template slot="noOptions">{{ $t("default.noData") }}</template>
                  </multiselect>
                </label>
              </div>
            </div>
            <div class="divider-v2">
              <div class="divider-v2-text divider-v2-left">{{ $t(`${i18nPrefix}dialog.validBetAmountCondition`) }}</div>
            </div>
            <div class="form-box-half form-column">
              <div class="form-box">
                <!-- 每日押碼量 -->
                <label>
                  <span class="span-row">
                    <input type="checkbox"
                      v-model="ruleSetting.luckyDrawExchangeConditions.validBetAmountCondition.isEnabled" />
                    {{ $t(`${i18nPrefix}dialog.validBetAmount`) }}
                  </span>
                  <div class="w-100">
                    <select class="w-50"
                      :disabled="!ruleSetting.luckyDrawExchangeConditions.validBetAmountCondition.isEnabled"
                      v-model="ruleSetting.luckyDrawExchangeConditions.validBetAmountCondition.periodType">
                      <option v-for="list of periodType" :key="list.id" :value="list.id" v-show="list.id !== 2">{{
                        $t(list.name) }}</option>
                    </select>
                    <input class="w-50" type="number" min="0" :placeholder="$t('default.amount')"
                      :disabled="!ruleSetting.luckyDrawExchangeConditions.validBetAmountCondition.isEnabled"
                      v-model.number="ruleSetting.luckyDrawExchangeConditions.validBetAmountCondition.amount" />
                  </div>
                </label>
                <!-- 存款條件 -->
                <label>
                  <span class="span-row">
                    <input type="checkbox" v-model="ruleSetting.luckyDrawExchangeConditions.depositCondition.isEnabled" />
                    {{ $t(`${i18nPrefix}dialog.depositCondition`) }}
                  </span>
                  <div class="w-100">
                    <select class="w-50" :disabled="!ruleSetting.luckyDrawExchangeConditions.depositCondition.isEnabled"
                      v-model="ruleSetting.luckyDrawExchangeConditions.depositCondition.periodType">
                      <option v-for="list of periodType" :key="list.id" :value="list.id">{{ $t(list.name) }}</option>
                    </select>
                    <input class="w-50" type="number" min="0" :placeholder="$t('default.amount')"
                      :disabled="!ruleSetting.luckyDrawExchangeConditions.depositCondition.isEnabled"
                      v-model.number="ruleSetting.luckyDrawExchangeConditions.depositCondition.amount" />
                  </div>
                </label>
              </div>
            </div>
            <div class="form-box-half">
              <div class="form-box">
                <!-- 每日登入 -->
                <label class="w-50">
                  <span class="span-row">
                    <input type="checkbox"
                      v-model="ruleSetting.luckyDrawExchangeConditions.memberLoginCondition.isEnabled" />
                    <!-- 免費抽獎券 -->
                    {{ $t(`${i18nPrefix}dialog.ticket`) }}
                  </span>
                  <div class="w-100">
                    <select class="w-50"
                      :disabled="!ruleSetting.luckyDrawExchangeConditions.memberLoginCondition.isEnabled"
                      v-model="ruleSetting.luckyDrawExchangeConditions.memberLoginCondition.periodType">
                      <option v-for="list of periodType" :key="list.id" :value="list.id" v-if="list.id !== 2">{{
                        $t(list.name) }}</option>
                    </select>
                    <input class="w-50" type="number" min="0" :placeholder="$t(`${i18nPrefix}dialog.times`)"
                      :disabled="!ruleSetting.luckyDrawExchangeConditions.memberLoginCondition.isEnabled"
                      v-model.number="ruleSetting.luckyDrawExchangeConditions.memberLoginCondition.ticket" />
                  </div>
                </label>
                <label class="w-50"></label>
              </div>
            </div>
            <!-- 平台類型 -->
            <div class="form-box star-box w-100">
              <div class="divider-v2-area" v-for="(list, key) of getGameCategoryAndPlatform" :key="key">
                <div class="divider-v2-text divider-v2-left">{{ GLOBAL.i18nCheckName(getGamesCategorys.find(data =>
                  data.id == key)) }}</div>
                <!-- 各遊戲種類倍率 -->
                <label class="span-row" v-for="gamePlatforms of list" :key="gamePlatforms.id">
                  <input type="checkbox" :value="{ 'category': key * 1, 'pleatform': gamePlatforms.id }"
                    :disabled="!ruleSetting.luckyDrawExchangeConditions.validBetAmountCondition.isEnabled"
                    v-model="ruleSetting.luckyDrawExchangeConditions.validBetAmountCondition.gameConditions" />
                  {{ GLOBAL.i18nCheckName(getGamePlatforms.find(data => data.id == gamePlatforms.id)) }}
                </label>
              </div>
            </div>
            <div class="divider-v2"
              v-show="ruleSetting.luckyDrawExchangeConditions.validBetAmountCondition.turnoverRatio.length">
              <div class="divider-v2-text divider-v2-left">{{ $t(`${i18nPrefix}dialog.turnoverRatio`) }}</div>
            </div>
            <div class="form-box">
              <label class="span-row"
                v-for="rate of ruleSetting.luckyDrawExchangeConditions.validBetAmountCondition.turnoverRatio"
                :key="rate.gameCategory">
                {{ GLOBAL.i18nCheckName(getGamesCategorys.find(data => data.id == rate.gameCategory)) }} 1:
                <input type="text" v-model.number="rate.rate" />
              </label>
            </div>
            <div class="divider-v2-area" style="display: block">
              <div class="divider-v2-text divider-v2-left">{{ $t(`${i18nPrefix}dialog.prizeRate`) }}</div>
              <div class="table-block w-100">
                <div class="add-th">
                  <button class="add-btn" style="background-color: green;margin: 5px;" @click="addData('+')">
                    <span>+</span>
                  </button>
                  <button class="add-btn" @click="addData('-')">
                    <span>-</span>
                  </button>
                </div>
                <!-- 獎品倍率 -->
                <table>
                  <thead>
                    <tr>
                      <th></th>
                      <th>{{ $t(`${i18nPrefix}dialog.isBroadcast`) }}</th>
                      <th>{{ $t(`${i18nPrefix}dialog.prizeType`) }}</th>
                      <th>{{ $t(`${i18nPrefix}dialog.prizeName`) }}</th>
                      <th>{{ $t(`${i18nPrefix}dialog.wageringRate`) }}</th>
                      <th>{{ $t(`${i18nPrefix}dialog.guarantCount`) }}</th>
                      <th>{{ $t(`${i18nPrefix}dialog.prizeCount`) }}</th>
                      <th>{{ $t(`${i18nPrefix}dialog.lotteryCountlimit`) }}</th>
                      <th>{{ $t(`${i18nPrefix}dialog.prizeWeight`) }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(list, index) of ruleSetting.rewordSetting.rewardList" :key="index">
                      <td>{{ index + 1 }}</td>
                      <td>
                        <input type="checkbox" v-model="list.isBroadcast" />
                      </td>
                      <td>
                        <select v-model="list.prizeType">
                          <option v-for="list of prizeType" :key="list.id" :value="list.id">{{ $t(list.name) }}</option>
                        </select>
                      </td>
                      <td>
                        <input type="text" v-model="list.prizeName" />
                      </td>
                      <td>
                        <input type="number" min="0" v-model.number="list.wageringRate"
                          @keyup="list.wageringRate = GLOBAL.checknull(list.wageringRate)" />
                      </td>
                      <td>
                        <input type="number" min="0" v-model.number="list.guarantCount" required="required"
                          @keyup="list.guarantCount = GLOBAL.checknull(list.guarantCount)" />
                      </td>
                      <td>
                        <input type="number" min="0" v-model.number="list.prizeCount" required="required" 
                        @keyup="list.prizeCount =
                          GLOBAL.checkValue(list.prizeCount, 0, 1, 999999999999)" />
                      </td>
                      <td>
                        <input type="number" min="0" v-model.number="list.lotteryCountlimit" required="required"
                          @keyup="list.lotteryCountlimit = GLOBAL.checknull(list.lotteryCountlimit)" />
                      </td>
                      <td>
                        <input type="number" min="0" v-model.number="list.prizeWeight" required="required"
                          @keyup="list.prizeWeight = GLOBAL.checknull(list.prizeWeight)" />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div class="divider-v2">
              <div class="divider-v2-text divider-v2-left">{{ $t(`${i18nPrefix}dialog.luckyDrawStyle`) }}</div>
            </div>
            <!-- 版型 -->
            <label class="luckyDrawStyle" v-for="style of luckyDrawStyle" @click="ruleSetting.luckyDrawStyleId = style.id"
              :key="style.id" :class="{ 'chooiceStyle': ruleSetting.luckyDrawStyleId == style.id }">
              <div>
                <span>
                  <input type="checkbox" class="Checkbox" :id="'check' + style.id"
                    :checked="ruleSetting.luckyDrawStyleId == style.id" />
                  <label :for="'check' + style.id"
                    :class="{ 'chooiceCheck': ruleSetting.luckyDrawStyleId == style.id }"></label>
                </span>
              </div>
              <img :src="style.imageUrl" />
            </label>
            <div class="divider-v2">
              <!-- 前台 -->
              <div class="divider-v2-text divider-v2-left">{{ $t(`${i18nPrefix}dialog.front`) }}</div>
            </div>
            <div class="form-box-half">
              <div class="form-box">
                <!-- 排序 -->
                <label>
                  {{ $t(`default.sort`) }}
                  <input type="number" min="0" :placeholder="$t(`default.sort`)" v-model="formModel.sort" />
                </label>
              </div>
            </div>
            <div class="imgDiv w-100">
              <div class="previewDiv" @click="upload('File')">
                <span v-if="!File">{{ $t('default.clickToUpload') }} Image</span>
                <img v-else :src="File" />
              </div>
              <div class="previewDiv" @click="upload('MobileFile')">
                <span v-if="!MobileFile">{{ $t('default.clickToUpload') }} MobileImage</span>
                <img v-else :src="MobileFile" />
              </div>
              <div class="previewDiv" @click="upload('AppFile')">
                <span v-if="!AppFile">{{ $t('default.clickToUpload') }} AppImage</span>
                <img v-else :src="AppFile" />
              </div>
            </div>
            <input type="file" ref="file" class="custom-file" @change="fileSelect" />
            <div class="form-box w-100">
              <label class="w-100">
                {{ $t(`${i18nPrefix}dialog.copywriting`) }}
                <ckeditor v-model="ruleSetting.html" :editor-url="this.GLOBAL.CKEditorUrl"></ckeditor>
              </label>
            </div>
          </div>
        </main>
      </div>
      <div class="advance-block-release" v-show="detail == undefined">
        <div class="m-auto">
          <button class="normal-btn" @click="submit()">{{ $t("default.confirm") }}</button>
          <button class="delete-btn" @click="$emit('toggle')">{{ $t("default.cancel") }}</button>
        </div>
        <button class="btn-orange" v-show="isCampaign == undefined && editData !== undefined" @click="submit(true)"
          copywriting>{{ $t(`${i18nPrefix}release`) }}</button>
      </div>
    </div>
    <!-- </div> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  getCampaignLuckyDrawStyle,
  createCampaignSetting,
  updateCampaignSetting,
  createCampaignLuckyDraw,
  updateCampaignLuckyDraw
} from "@/api/campaign/luckydraw";
import { emptyFormModel, ruleSetting } from "./model";
import {
  transToday,
  joinT,
  transDateForOrin
} from "@/utils/transDateUtils";
import Multiselect from "vue-multiselect";
import Message from "@/layout/components/Message";

export default {
  name: "Edit",
  props: [
    "detail",
    "isCampaign",
    "editData",
    "starList",
    "tagList",
    "agentList",
    "periodType",
    "prizeType",
    "i18nPrefix"
  ],
  components: {
    Multiselect
  },
  data() {
    return {
      emptyFormModel,
      ruleSetting,
      luckyDrawStyle: [],
      File: undefined,
      MobileFile: undefined,
      AppFile: undefined,
      chooiceImg: undefined,
      isFrist: false, // 判斷是否是修改時欄位注入
      memberTag: [],
      memberStar: [],
      agentData: [],
      formModel: undefined,
      startTime: transToday("from"),
      endTime: transToday("to"),
      editModel: {},
      editModelTmp: {},
      formData: new FormData(),
      rulesError: []
    };
  },
  watch: {
    "formModel.name"(val) {
      this.formModel.name = val.trim();
    },
    "ruleSetting.luckyDrawExchangeConditions.validBetAmountCondition.gameConditions"(
      val
    ) {
      if (this.isFrist) return;
      const seenCategories = new Set();
      // 創建一個用於存儲去重後的 "category" 值的陣列
      const result = [];

      for (const item of val) {
        const category = item["category"];
        // 如果這個 "category" 值之前未出現過，則添加到結果陣列中並記錄到已出現集合中
        if (!seenCategories.has(category)) {
          result.push(category);
          seenCategories.add(category);
        }
      }

      this.ruleSetting.luckyDrawExchangeConditions.validBetAmountCondition.turnoverRatio = this.getGamesCategorys
        .filter(el => result.includes(el.id))
        .map(d => {
          return { gameCategory: d.id, rate: 1 };
        });
      // this.turnoverRatio = this.getGamesCategorys.filter((f) => result.find((fd) => fd == f.id));
    }
  },
  computed: {
    ...mapGetters(["getGamePlatforms", "getUserinfo", "getGamesCategorys", "getGameCategoryAndPlatform"])
  },
  async created() {
    this.formModel = _.cloneDeep(this.emptyFormModel);

    await getCampaignLuckyDrawStyle().then(
      res => (this.luckyDrawStyle = res.data)
    );

    // 處理修改欄位
    this.processEditColumn();

    setTimeout(() => {
      this.isFrist = false;
    }, 200);
  },
  async beforeCreate() { },
  methods: {
    /**
     * 處理修改欄位
     */
    processEditColumn() {
      if (this.editData !== undefined) {
        this.isFrist = true;

        const ruleSetting = JSON.parse(this.editData.ruleSetting);
        for (const [key, value] of Object.entries(this.formModel)) {
          if (this.editData[key] !== undefined) {
            this.formModel[key] = this.editData[key];
          }
        }
        this.startTime = transDateForOrin(this.formModel.startTime);
        this.endTime = transDateForOrin(this.formModel.endTime);
        this.File = ruleSetting.imageUrl;
        this.MobileFile = ruleSetting.mobileImageUrl;
        this.AppFile = ruleSetting.appImageUrl;
        this.formModel.html = this.formModel.html ?? "";
        this.ruleSetting = ruleSetting;

        // 處理多選套件格式
        if (ruleSetting.participateStatus.includeTags.length > 0) {
          this.memberTag = this.tagList.filter(e => {
            return ruleSetting.participateStatus.includeTags.indexOf(e.id) > -1;
          });
        }
        if (ruleSetting.participateStatus.includeLevels.length > 0) {
          this.memberStar = this.starList.filter(e => {
            return (
              ruleSetting.participateStatus.includeLevels.indexOf(e.star) > -1
            );
          });
        }
        if (ruleSetting.participateStatus.includeAgents.length > 0) {
          this.agentData = this.agentList.filter(e => {
            return (
              ruleSetting.participateStatus.includeAgents.indexOf(e.id) > -1
            );
          });
        }
      }
    },
    /**
     * 送出
     */
    async submit(release = false) {
      this.formModel.startTime = this.startTime
        ? joinT(this.startTime + this.GLOBAL.startSecond + this.GLOBAL.timeZone)
        : undefined;
      this.formModel.endTime = this.endTime
        ? joinT(this.endTime + this.GLOBAL.endSecond + this.GLOBAL.timeZone)
        : undefined;

      // 填入 等級＆代理＆會員標籤
      this.ruleSetting.participateStatus.includeTags = [];
      this.ruleSetting.participateStatus.includeLevels = [];
      this.ruleSetting.participateStatus.includeAgents = [];
      if (this.memberTag.length > 0) {
        this.ruleSetting.participateStatus.includeTags = this.memberTag.map(
          e => {
            return e.id;
          }
        );
      }

      if (this.memberStar.length > 0) {
        this.ruleSetting.participateStatus.includeLevels = this.memberStar.map(
          e => {
            return e.star;
          }
        );
      }

      if (this.agentData.length > 0) {
        this.ruleSetting.participateStatus.includeAgents = this.agentData.map(
          e => {
            return e.id;
          }
        );
      }
      this.formModel.ruleSetting = JSON.stringify(this.ruleSetting);

      Object.entries(this.formModel).forEach(([key, value]) => {
        if (value) this.formData.append(key, value);
      });

      let result = {};
      if (release) {
        await createCampaignLuckyDraw(this.formData).then(res => {
          result = res;
        });
      } else if (this.editData === undefined) {
        await createCampaignSetting(this.formData).then(res => {
          result = res;
        });
      } else if (this.isCampaign) {
        await updateCampaignLuckyDraw(this.formData).then(res => {
          result = res;
        });
      } else {
        await updateCampaignSetting(this.formData).then(res => {
          result = res;
        });
      }

      if (result) {
        this.$emit("toggle");
        this.$emit("search");
      }

      return;
    },
    /**
     * 圖片處理
     */
    upload(tag) {
      this.chooiceImg = tag;
      this[tag] = undefined;
      this.formData.delete(tag);
      this.$refs.file.value = "";
      this.$refs.file.click();
    },
    fileSelect(e) {
      if (!e.target.files?.length) return;
      this[this.chooiceImg] = URL.createObjectURL(e.target.files[0]);
      let newFile = new File(
        [e.target.files[0]],
        this.chooiceImg + e.target.files[0].name
      );
      this.formData.append(this.chooiceImg, newFile);
    },
    addData(type) {
      let obj = [
        {
          isBroadcast: false,
          prizeType: 1,
          prizeName: "",
          wageringRate: 1,
          guarantCount: 0,
          prizeCount: 1,
          lotteryCountlimit: 0,
          prizeWeight: 0
        },
        {
          isBroadcast: false,
          prizeType: 1,
          prizeName: "",
          wageringRate: 1,
          guarantCount: 0,
          prizeCount: 1,
          lotteryCountlimit: 0,
          prizeWeight: 0
        }
      ];

      let rewardList = [...this.ruleSetting.rewordSetting.rewardList];
      if (
        type === "+" &&
        this.ruleSetting.rewordSetting.rewardList.length !== 12
      ) {
        this.ruleSetting.rewordSetting.rewardList = rewardList.concat(obj);
      } else if (
        type === "-" &&
        this.ruleSetting.rewordSetting.rewardList.length !== 8
      ) {
        this.ruleSetting.rewordSetting.rewardList.pop();
        this.ruleSetting.rewordSetting.rewardList.pop();
      } else {
        this.ruleSetting.rewordSetting.rewardList;
      }
    },
    disabledStart(date) {
      if (this.endTime) {
        return date > new Date(this.endTime + this.GLOBAL.endSecond)
      };
    },
    disabledEnd(date) {
      if (this.startTime) {
        return date < new Date(this.startTime + this.GLOBAL.endSecond)
      };
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/form.scss";
@import "~@/styles/dialogCloseIcon.scss";
@import "~@/styles/discountSetup.scss";

.luckyDrawStyle {
  position: relative;
  width: 300px;
  height: 400px;
  border-style: outset;
  border-color: gray;
  border-radius: 10px;
  margin: 10px !important;
  cursor: pointer;

  div {
    width: 100%;

    span {
      position: absolute;
      right: 10px;
    }

    .Checkbox {
      display: none;
    }

    .Checkbox+label {
      width: 16px;
      height: 16px;
      border: 1px solid #a6a6a6;
      border-radius: 50%;
      background-color: #dedede;
    }

    .chooiceCheck::after {
      content: "";
      left: 5px;
      top: 5px;
      width: 15px;
      height: 8px;
      border: 2px solid #424242;
      border-top-color: transparent;
      border-right-color: transparent;
      transform: rotate(-60deg);
      -ms-transform: rotate(-80deg);
      -moz-transform: rotate(-60deg);
      -webkit-transform: rotate(-60deg);
    }
  }

  img {
    max-height: 100%;
    max-width: 100%;
    width: auto;
    height: auto;
    margin: auto;
  }
}

.chooiceStyle {
  border-color: rgb(0, 145, 255) !important;
}

.star-box {
  display: grid;
  grid-template-columns: repeat(3, 33%);
}

@media screen and (max-width: 768px) {
  .star-box {
    grid-template-columns: repeat(1, 100%);
  }
}

.imgDiv {
  display: flex;

  .previewDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 33%;
    height: 200px;
    padding: 0.5em;
    background-color: gray;
    border-radius: 10px;
    margin: 20px auto;

    span {
      display: block;
      font-size: 20px;
    }

    img {
      display: block;
      height: auto;
      width: auto;
      max-height: 95%;
      max-width: 95%;
    }
  }
}

.custom-file {
  position: absolute;
  visibility: hidden;
}
</style>