import request from "@/utils/request";
import { getToken } from "@/utils/auth";

export function messageTemplateList(data) {
    return request({
        url: "/CustomServiceMessageTemplate/List",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function messageTemplatePage(data) {
    return request({
        url: "/CustomServiceMessageTemplate/Page",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function createMessageTemplate(data) {
    return request({
        url: "/CustomServiceMessageTemplate/Create",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function updateMessageTemplate(data) {
    return request({
        url: "/CustomServiceMessageTemplate/Update",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function deleteMessageTemplate(data) {
    return request({
        url: "/CustomServiceMessageTemplate/Delete",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}