<template>
  <div class="dialog-mask create-member" @mousedown.self="$emit('toggle')">
    <div class="dialog-container">
      <div class="dialog-block">
        <div class="header">
          <h4>{{ $t("default.create") + $t(`${i18nPrefix}agent_bank`) }}</h4>
        </div>
        <div class="divider"></div>
        <main>
          <div class="input-block">
            <div class="bank-info">
              <label>
                {{ $t("default.bankType") }}
                <select v-model="createModel.bankType">
                  <option
                    v-for="list of getBankTypeList"
                    :key="list.id"
                    :value="list.id"
                  >
                    {{ $t(list.name) }}
                  </option>
                </select>
              </label>
              <div class="multiselectDiv">
                {{ $t(`${i18nPrefix}dialog.bank`) }}
                <multiselect
                  v-model="value"
                  label="name"
                  track-by="id"
                  :custom-label="nameWithLang"
                  :options="getBankList"
                  :allow-empty="false"
                  :preselectFirst="true"
                >
                </multiselect>
              </div>
              <label>
                {{ $t(`${i18nPrefix}dialog.bankAccountNumber`) }}
                <input type="text" v-model="createModel.bankAccountNumber" />
              </label>
              <label>
                {{ $t(`${i18nPrefix}dialog.bankAccountName`) }}
                <input type="text" v-model="createModel.bankAccountName" />
              </label>
              <label>
                {{ $t(`${i18nPrefix}dialog.bankBranch`) }}
                <input type="text" v-model="createModel.bankBranch" />
              </label>
              <label>
                {{ $t("default.bankBranchCode") }}
                <input type="text" v-model="createModel.bankBranchCode" />
              </label>
            </div>
          </div>
          <div class="advance-block">
            <button class="confirm-btn main-style-btn" @click="create">
              {{ $t("default.confirm") }}
            </button>
            <button
              class="normal-btn-v2 main-style-btn"
              @click="$emit('toggle')"
            >
              {{ $t("default.cancel") }}
            </button>
          </div>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Message from "@/layout/components/Message";
import Multiselect from "vue-multiselect";
import { bankCreate } from "@/api/agent-management/agent-bank";

export default {
  name: "CreateBank",
  props: ["memberId", "i18nPrefix"],
  data() {
    return {
      createModel: {
        bankId: undefined,
        bankType: 1,
        bankBranch: undefined,
        bankBranchCode: undefined,
        bankAccountName: undefined,
        bankAccountNumber: undefined,
        province: "",
        city: "",
      },
      value: [],
    };
  },
  components: { Multiselect },
  computed: {
    ...mapGetters(["getBankList", "getBankTypeList"]),
  },
  watch: {
    "createModel.bankAccountNumber": function (val, oldVal) {
      if (/^[0-9]*$/.test(val) === false) {
        this.createModel.bankAccountNumber = oldVal ?? "";
        Message.error(this.$t("default.checkNum"), 1000);
      }
    },
  },
  methods: {
    async create() {
      this.createModel.bankId =
        this.createModel.bankType === 1 || this.createModel.bankType === 2
          ? this.value?.id
          : 0;
      if (
        !this.createModel.bankAccountName ||
        !this.createModel.bankAccountNumber
      ) {
        return Message.error(
          this.$t(`${this.i18nPrefix}dialog.bank.errorMsg`),
          2000
        );
      }
      this.createModel.agentId = this.memberId;
      try {
        await bankCreate(this.createModel);
        this.$emit("reload");
      } catch (e) {
        console.log(e);
      }
    },
    nameWithLang({ name, code }) {
      return `${code}－${name}`;
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
.bank-info {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;
  label {
    width: 45%;
    .disabled {
      background-color: gray;
    }
  }
  .multiselectDiv {
    width: 45%;
    margin: 15px auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
</style>
