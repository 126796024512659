import request from "@/utils/request";
import { getToken } from "@/utils/auth";

export function getList(data) {
    return request({
        url: "/MemberPointRecord/Page",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function selectMenu() {
    return request({
        url: "/MemberPointRecord/selectMenu",
        method: "get",
        headers: { "Authorization": `token ${getToken()}` }
    });
}