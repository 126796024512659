<template>
  <div>
    <div class="wallet-view">
      <div class="info-data">
        <div class="card">
          <div class="card-item">
            <h2>{{ $t(`${i18nPrefix}memberWalletBalance`) }}</h2>
            <a>{{ overviewData.totalMemberBalance }}</a>
          </div>
        </div>
        <div class="card" style="flex: 1">
          <div class="card-item">
            <h2>{{ $t("agent.agent-management.accountFor") }}</h2>
            <a>{{ overviewData.commissionRate }}</a>
          </div>
          <div class="card-item">
            <h2>{{ $t(`${i18nPrefix}total`) }}</h2>
            <a>{{ total }}</a>
          </div>
          <div class="card-item">
            <h2>{{ $t(`default.rebateAmount`) }}</h2>
            <a>{{ overviewData.balance }}</a>
          </div>
          <div class="card-item">
            <h2>{{ $t(`${i18nPrefix}total_settlement_amount`) }}</h2>
            <a>{{ overviewData.rebateAccumulation }}</a>
          </div>
        </div>
      </div>
      <div class="withdraw">
        <div class="title">
          <h5>{{ $t(`${i18nPrefix}withdraw`) }}</h5>
          <div v-if="!getUserinfo.koreanStyleInterface">
            <button class="normal-btn-v2 main-style-btn" @click="handleDialog({ bankData: walletBank }, 'Edit')"
              :disabled="!walletBankList.length || !getUserinfo.permissions.includes(150102)">{{ $t("default.edit") + " "
                + $t(`${i18nPrefix}bankCard`) }}</button>
            <button class="create-btn main-style-btn" @click="handleDialog(undefined, 'Create')"
              :disabled="!getUserinfo.permissions.includes(150101)">{{ $t("default.create") + " " +
                $t(`${i18nPrefix}bankCard`) }}</button>
          </div>
        </div>
        <div class="divider"></div>
        <label class="CardAndAmount">
          <div style="margin: 5px 0">
            {{ $t(`${i18nPrefix}bankCard`) }}
            <button class="delete-btn" v-if="!getUserinfo.koreanStyleInterface" @click="handleDelete(walletBank.id)"
              :disabled="!walletBankList.length || !getUserinfo.permissions.includes(150103)">{{ $t("default.delete") + "
              " + $t(`${i18nPrefix}bankCard`) }}</button>
          </div>
          <select v-model="walletBank" v-if="!getUserinfo.koreanStyleInterface">
            <option v-show="!walletBankList.length" :value="0">{{ $t("default.noData") }}</option>
            <option v-for="list of walletBankList" :value="list" :key="list.id">{{ list.bankName + " / " + (list.province
              ? list.province : "") }}</option>
          </select>
        </label>
        <div class="cardInfo">
          <table>
            <tr v-if="!getUserinfo.koreanStyleInterface">
              <th>{{ $t("default.bankAccountNumber") }}</th>
              <td>{{ walletBank.bankAccountNumber }}</td>
            </tr>
            <tr>
              <th>{{ $t("default.bankAccountName") }}</th>
              <td>{{ walletBank.bankAccountName }}</td>
            </tr>
            <template v-if="!getUserinfo.koreanStyleInterface">
              <tr>
                <th>{{ $t("default.province") }}</th>
                <td>{{ walletBank.province }}</td>
              </tr>
              <tr>
                <th>{{ $t("default.city") }}</th>
                <td>{{ walletBank.city }}</td>
              </tr>
            </template>
            <template v-else>
              <tr>
                <th>{{ $t("features.bank.bankName") }}</th>
                <td>{{ walletBank.bankName }}</td>
              </tr>
              <tr>
                <th>{{ $t("member.member-management.bankAccount") }}</th>
                <td>{{ walletBank.bankAccountNumber }}</td>
              </tr>
            </template>
          </table>
        </div>
        <label class="CardAndAmount">
          {{ $t("default.amount") }}
          <input type="text" v-model="amount" />
          <button class="create-btn main-style-btn mt-1" @click="withdrawal" :disabled="!walletBankList.length ||
            !getUserinfo.permissions.includes(150201)
            ">
            {{ $t(`${i18nPrefix}withdraw`) }}
          </button>
        </label>
      </div>
    </div>
    <div class="container-view container-view-first">
      <button class="search-btn main-style-btn" @click="search()">
        {{ $t("default.search") }}
      </button>
      <div class="header">
        <h5>{{ $t("title." + $route.meta.title) }}</h5>
      </div>
      <div class="main">
        <div class="input-block">
          <label>
            {{ $t(`${i18nPrefix}transferType`) }}
            <select v-model="searchForm.transactionType">
              <option v-for="list of transactionTypeList" :key="list.id" :value="list.id">
                {{ $t(list.name) }}
              </option>
            </select>
          </label>
          <label>
            {{ $t("default.startTime") }}
            <date-picker v-model="startTime" format="YYYY-MM-DD HH:mm:ss" value-type="format"
              type="datetime"></date-picker>
          </label>
          <label>
            {{ $t("default.endTime") }}
            <date-picker v-model="endTime" format="YYYY-MM-DD HH:mm:ss" value-type="format" type="datetime"></date-picker>
          </label>
        </div>
        <div class="advance-block">
          <QuickSearch @changeTime="changeTime"></QuickSearch>
          <button class="search-pc-btn main-style-btn px-5" @click="search()">
            {{ $t("default.search") }}
          </button>
        </div>
      </div>
    </div>
    <div class="container-view">
      <div class="table-block">
        <table>
          <thead>
            <tr>
              <th v-if="!getUserinfo.koreanStyleInterface">{{ $t(`${i18nPrefix}createTime`) }}</th>
              <th v-if="getUserinfo.koreanStyleInterface">{{ $t("default.sort") }}</th>
              <th>{{ $t(`${i18nPrefix}transferType`) }}</th>
              <th>{{ $t("default.agent") }}</th>
              <template v-if="!getUserinfo.koreanStyleInterface">
                <th>{{ $t(`${i18nPrefix}preTradeBalance`) }}</th>
                <th>{{ $t("default.amount") }}</th>
                <th>{{ $t(`${i18nPrefix}afterTradeBalance`) }}</th>
                <th>{{ $t("default.remark") }}</th>
              </template>
              <template v-else>
                <th>{{ $t(`${i18nPrefix}settlement_amount`) }}</th>
                <th>{{ $t(`${i18nPrefix}apply_time`) }}</th>
                <th>{{ $t(`${i18nPrefix}audit_time`) }}</th>
              </template>
            </tr>
          </thead>
          <tbody>
            <tr v-if="!allData || !allData.length">
              <td colspan="999">{{ $t("default.noData") }}</td>
            </tr>
            <tr v-for="(list, index) of allData" :key="index">
              <td v-if="!getUserinfo.koreanStyleInterface">{{ list.createTime }}</td>
              <td v-if="getUserinfo.koreanStyleInterface">{{ list.id }}</td>
              <td>
                {{
                  GLOBAL.i18nCheckName(
                    transactionTypeList.find(
                      (data) => list.transactionType === data.id
                    )
                  )
                }}
              </td>
              <td>{{ list.agentAccount }}</td>
              <template v-if="!getUserinfo.koreanStyleInterface">
                <td>{{ list.preTradeBalance }}</td>
                <td>{{ list.amount }}</td>
                <td>{{ list.afterTradeBalance }}</td>
                <td>{{ list.remark }}</td>
              </template>
              <template v-else>
                <td>{{ list.amount }}</td>
                <td>{{ list.applyTime }}</td>
                <td>{{ list.auditTime }}</td>
              </template>
            </tr>
          </tbody>
        </table>
      </div>
      <serverPagination v-if="allData" :page="page" @ServerPageUtils="search" @changeTake="changeTake"></serverPagination>
      <transition name="fade" mode="out-in">
        <Alert v-if="alertMsg" :message="alertMsg" @toggle="alertMsg = undefined" @doIt="deleteWalletBank" />
      </transition>
      <transition name="fade" mode="out-in">
        <component :is="currDialog" :i18nPrefix="i18nPrefix" :getBankList="getBankList" :getBankTypeList="getBankTypeList"
          v-bind="dialogData" @toggle="currDialog = undefined" @create="create" @update="update"></component>
      </transition>
    </div>
  </div>
</template>

<script>
import { searchModel } from "./model";
import {
  getWalletBankList,
  createWalletBank,
  updateWalletBank,
  deleteWalletBank,
  withdrawal,
  getList,
  getTotal,
  getTransactionType,
  getOverview,
} from "@/api/agent-management/agent-wallet";
import { mapGetters } from "vuex";
import Create from "./dialog/create";
import Edit from "./dialog/edit";
import Message from "@/layout/components/Message";
import Alert from "@/layout/components/Alert";
import serverPagination from "@/layout/components/serverPagination";
import handleServerPageUtils from "@/layout/mixins/handleServerPageUtils";
import handleDialog from "@/layout/mixins/handleDialog";
import QuickSearch from "@/layout/components/QuickSearch";
import { joinT } from "@/utils/transDateUtils";
import { transToday } from "@/utils/transDateUtils";

export default {
  name: "AgentWallet",
  data() {
    return {
      searchForm: _.cloneDeep(searchModel),
      walletBankList: [],
      walletBank: 0,
      startTime: transToday("from") + this.GLOBAL.startSecond,
      endTime: transToday("to") + this.GLOBAL.endSecond,
      amount: "",
      total: 0,
      transactionTypeList: [],
      alertMsg: "",
      deleteData: {},
      i18nPrefix: "agent.agent-wallet.",
      overviewData: {
        rebateRate: 0, // 佔成比例
        balance: 0, // 錢包餘額
        totalMemberBalance: 0, // 會員錢包總額
        rebateAccumulation: 0, // 總結算金額
      },
    };
  },
  mixins: [handleServerPageUtils, handleDialog],
  computed: {
    ...mapGetters(["getBankList", "getUserinfo", "getBankTypeList"]),
  },
  components: {
    Create,
    Edit,
    Alert,
    QuickSearch,
    serverPagination,
  },
  async created() {
    await this.getOverviewData();
    getTransactionType().then((res) => {
      this.searchForm.transactionType = res.data.type[0]?.id;
      this.transactionTypeList = res.data.type.map((data) => {
        return { id: data.id, name: "AgentWalletTransactionType." + data.name };
      });
    });
    getWalletBankList().then((res) => {
      if (res.data.length) {
        this.walletBankList = res.data;
        this.walletBank = this.walletBankList[0];
      }
    });
    this.search();
  },
  watch: {
    amount: function (val, oldVal) {
      if (/^[0-9]*$/.test(val) === false) {
        this.amount = oldVal ?? "";
        Message.error(this.$t("default.checkNum"), 1000);
      }
    },
  },
  methods: {
    async search(page = false) {
      this.searchForm.startTime = this.startTime
        ? joinT(this.startTime + this.GLOBAL.timeZone)
        : undefined;
      this.searchForm.endTime = this.endTime
        ? joinT(this.endTime + this.GLOBAL.timeZone)
        : undefined;
      this.searchForm.page.take = this.take * 1;
      this.searchForm.page.skip = this.take * (page ? page - 1 : 0);
      if (!page) {
        await getTotal(this.searchForm).then((res) => {
          this.total = res.data.total;
        });
      }
      getList(this.searchForm).then((res) => {
        this.allData = res.data.list;
        this.page = res.data.page;
      });
    },
    create(data) {
      createWalletBank(data).then((res) => {
        if (res) this.relord();
      });
    },
    update(data) {
      updateWalletBank(data).then((res) => {
        if (res) this.relord();
      });
    },
    handleDelete(id) {
      this.alertMsg = this.$t("default.delMsg");
      this.deleteData = { id };
    },
    deleteWalletBank() {
      this.alertMsg = "";
      deleteWalletBank(this.deleteData).then((res) => {
        if (res) this.relord();
      });
    },
    relord() {
      this.closeDialog();
      getWalletBankList().then((res) => {
        this.walletBankList = res.data;
        this.walletBank = this.walletBankList[0];
      });
    },
    async withdrawal() {
      let obj = {
        agentBankId: this.walletBank.id,
        amount: this.amount,
      };
      await withdrawal(obj);
      this.getOverviewData();
    },
    changeTime(time) {
      this.startTime = time.startTime + this.GLOBAL.startSecond;
      this.endTime = time.endTime + this.GLOBAL.endSecond;
      this.search();
    },
    async getOverviewData() {
      const result = await getOverview();
      this.overviewData = result.data;
    },
  },
};
</script>

<style lang="scss" scoped>
.wallet-view {
  display: flex;

  .withdraw {
    display: inline-block;
    background-color: #d6e3ea;
    margin: 15px;
    padding: 20px 30px;
    border-radius: 10px;
    width: 50%;

    label {
      display: block;
    }

    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;

      h5 {
        margin: 0;
        font-weight: 600;
      }
    }

    .cardInfo {
      background-color: #f7cbad;
      border-radius: 10px;
      width: 100%;
      margin: 10px auto;
      padding: 10px;
      overflow-y: hidden;
      overflow-x: auto;

      table {
        width: 100%;

        th {
          width: 10em;
          padding: 0.5em;
        }
      }
    }
  }

  .info-data {
    width: 50%;
    display: flex;
    flex-direction: column;

    .card {
      text-align: center;
      width: 100%;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
      margin: 10px 0;
      background-color: rgb(67, 170, 148);
      display: flex;
      align-items: center;
      padding: 20px 0;
      justify-content: center;

      .card-item {
        width: 50%;
        margin: 7px 0;
        display: flex;
        justify-content: space-between;

        h2 {
          margin: auto 0;
        }
      }
    }

    a {
      font-size: 1.7em;
    }
  }

  .divider {
    margin: 5px 0;
    width: 100%;
    height: 2px;
    background-color: rgba(53, 53, 53, 0.6);
  }

  .CardAndAmount {
    display: flex !important;
    flex-direction: column;

    select button {
      width: 100%;
    }
  }
}

.container-view-first {
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  padding: 40px 60px 20px 60px;
}

@media screen and (max-width: 500px) {
  .wallet-view {
    flex-wrap: wrap;

    .withdraw {
      width: 100%;
      margin: 0;

      .title {
        flex-wrap: wrap;
      }

      .CardAndAmount {
        button {
          float: right;
        }
      }
    }

    .card {
      width: 100%;
    }
  }
}
</style>
