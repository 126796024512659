<template>
  <div class="sidebar" :class="{ hide: sidebar }">
    <ul class="sidebar-nav">
      <li class="parent-li" v-for="route of routes" :key="route.path">
        <div class="parent-li-box"
          :class="{ 'parent-li-box-show': chooiceTitle === route.meta.title && chooiceTitle !== 'dashboard' }">
          <template v-if="route.children.length == 0">
            <router-link :to="getUserinfo.koreanStyleInterface ? '/statistical-report/overview' : route.path"
              @click.native="setActive(undefined)">
              <img :src="require(`@/assets/sidebar/icon/${route.name}.svg`)" />
              {{ route.meta.permissionName ? $t('Permission.' + route.meta.permissionName) :
                $t(`title.${route.meta.title}`) }} {{ route.meta.ver || '' }}
            </router-link>
          </template>
          <template v-else>
            <a class="memberSpan" :id="route.path" @click="setActive(route.meta.title)">
              <img :src="require(`@/assets/sidebar/icon/${route.name}.svg`)" />
              {{ route.meta.permissionName ? $t('Permission.' + route.meta.permissionName) : $t("title." +
                route.meta.title) }} {{ route.meta.ver || '' }}
            </a>
            <div class="collapse" :id="route.name" style="width: 100%; cursor: pointer;"
              :class="{ 'show': chooiceTitle === route.meta.title }">
              <ul>
                <li v-for="childRoute of route.children" :key="childRoute.path">
                  <router-link :to="`${route.path}/${childRoute.path}`"
                    @click.native="hideSideBarWhenClick(route.path + '/' + childRoute.path)"
                    v-show="!(getUserinfo.koreanStyleInterface && childRoute.path == 'overview')">
                    <div class="collapse-li-text">
                      {{ childRoute.meta.permissionName ? $t('Permission.' + childRoute.meta.permissionName) :
                        $t("title." + childRoute.meta.title) }} {{ childRoute.meta.ver || '' }}
                    </div>
                    <img src="@/assets/sidebar/select-bg.png" />
                  </router-link>
                </li>
              </ul>
            </div>
          </template>
        </div>
      </li>
      <li class="mb-5">
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "SidebarV2",
  data() {
    return {
      chooiceTitle: undefined,
      windowWidth: 0,
      beforePath: this.$route.path
    };
  },
  watch: {
    windowWidth(value) {
      if (value <= 1280) {
        if (this.sidebar === false) {
          this.$store.commit("app/TOGGLE_SIDEBAR", true);
        }
      }
    },
  },
  computed: {
    ...mapGetters(["sidebar", "getUserinfo", "navHeight"]),
    routes() {
      let routesForAdmin = this.$router.options.routes.filter(
        (route) => !route.meta.hidden
      );
      if (this.getUserinfo.permissions[0] === 99999) {
        return routesForAdmin;
      } else {
        let constRoutes = [];
        routesForAdmin.map((d) => {
          if (
            d.meta.permission &&
            this.getUserinfo.permissions.includes(d.meta.permission)
          ) {
            constRoutes.push(d);
            constRoutes.map((constRoute) => {
              constRoute.children = constRoute.children.filter((cR) => {
                return this.getUserinfo.permissions.includes(
                  cR.meta.permission
                ) && !cR.meta.hidden;
              });
            });
          }
        });
        return constRoutes;
      }
    },
    paddingTop() {
      return this.navHeight + 5 + 'px'
    }
  },
  mounted() {
    // const colActive = document.getElementById(this.$route.matched[0].path);
    // const colShow = document.getElementById(this.$route.matched[0].name);
    // if (colActive) {
    //   if (!colActive.classList?.contains("collapseActive")) {
    //     colActive.classList.add("collapseActive");
    //     colActive.parentNode.classList.add("parent-li-box-show");
    //   }
    //   if (!colShow.classList?.contains("show")) colShow.classList.add("show");
    // }

    const hash = window.location.hash;
    const segments = hash.split('/');
    this.chooiceTitle = (this.getUserinfo.koreanStyleInterface && segments[2] == 'overview') ? undefined : segments[1];

    this.$nextTick(function () {
      window.addEventListener("resize", this.getWindowWidth);
      //Init
      this.getWindowWidth();
    });
  },
  methods: {
    getWindowWidth() {
      this.windowWidth = document.documentElement.clientWidth;
    },
    async setActive(event) {
      this.chooiceTitle = this.chooiceTitle == event ? undefined : event;
      // let classList = event.target.classList;
      // let isActive = classList.contains("collapseActive");

      // let allAccordionItems = document.querySelectorAll(".collapseActive");
      // await allAccordionItems.forEach(item => {
      //   item.classList.remove("collapseActive");
      //   item.parentNode.classList.remove("parent-li-box-show");
      //   let sibling = item.nextSibling;
      //   if (sibling && sibling.classList.contains("show")) {
      //     sibling.classList.remove("show");
      //   }
      // });

      // setTimeout(() => {
      //   if (isActive) {
      //     classList.remove("collapseActive");
      //     event.target.parentNode.classList.remove("parent-li-box-show");
      //     event.target.nextSibling.classList.remove("show");
      //   } else {
      //     classList.add("collapseActive");
      //     event.target.parentNode.classList.add("parent-li-box-show");
      //     event.target.nextSibling.classList.add("show");
      //   }
      // }, 0);
    },
    hideSideBarWhenClick(path) {
      if (path === this.beforePath) {
        this.$router.push({
          path: '/userInfo/empty',
          query: { page: this.beforePath },
        });
      }
      this.beforePath = path;
      if (this.windowWidth < 1280) this.$store.commit("app/TOGGLE_SIDEBAR", true);
    },
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.getWindowWidth);
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/scrollbar.scss";

@media screen and (max-width: 1280px) {
  .sidebar {
    z-index: 2;
  }
}

@media screen and (max-width: 500px) {
  .sidebar {
    // padding-top: 128px !important;
  }
}

.sidebar::-webkit-scrollbar {
  display: none;
  /* Chrome Safari */
}

.sidebar {
  position: fixed;
  overflow: auto;
  padding-top: v-bind(paddingTop);
  min-width: 200px;
  max-width: 200px;
  height: 100%;
  // background-color: #1a2226;
  // box-shadow: 0px 3px 10px 0 rgba(0, 0, 0, 0.4) inset;
  transition-duration: 0.3s;
  transform: translateX(0);
  z-index: 5;
  font-size: 14px;
  -ms-overflow-style: none;
  /* IE 10+ */
  scrollbar-width: none;

  /* Firefox */
  .sidebar-nav {
    .parent-li {
      position: relative;
      align-items: center;
      transition-duration: 0.3s;
      margin-bottom: 10px;

      // border-bottom: 1px solid #c4c4c4;
      &:hover>a {
        //background-color: #d9d9d9;
      }

      .parent-li-box {
        display: flex;
        flex-direction: column;
        background-image: linear-gradient(270deg, #27343A 0%, #1B2428 60.42%);
        border-radius: 0px 16px 8px 0px;
      }

      .parent-li-box-show {
        border-radius: 0px 16px 16px 0px;
      }

      a {
        display: flex;
        align-items: center;
        width: 100%;
        padding: 8px 30px;
        color: #FFCC81;
        font-weight: 500;
        font-family: Noto sans-serif;

        img {
          margin-right: 10px;
        }
      }

      .collapse,
      .collapsing {
        ul {
          li {
            margin: 1% 10%;
            border-bottom: 1px solid #3D4F59;

            a {
              position: relative;
              display: flex;
              justify-content: center;
              // padding-left: 2.5em !important;
              padding: 10px 12px;
              color: #D6E3EA;
              border-radius: 0.35rem;
              text-align: center;
              line-height: 12px;
              min-height: 45px;

              .collapse-li-text {
                z-index: 2;
              }

              img {
                position: absolute;
                display: none;
                width: 110%;
                height: 100%;
                transform: translate(10px, 0px);
              }
            }
          }

          li:last-child {
            border-bottom: 0px;
          }

          li:hover {
            border-radius: 0.35rem;
            transition: 0.2s;

            a {
              color: #27343A !important;
            }

            img {
              display: block !important;
            }
          }
        }
      }
    }
  }
}

.hide {
  transform: translateX(-100%);
}

.collapseActive {

  // box-shadow: 0px 5px 5px 0 rgba(0, 0, 0) inset;
  //background-color: #333;
  &+.fa-angle-down {
    transform: rotate(180deg);
  }
}

.parent-li-box-show {
  .router-link-active {
    color: #27343A !important;

    img {
      display: block !important;
    }
  }
}

@keyframes shake {
  from {
    transform: rotate(10deg);
  }

  to {
    transform: rotate(-10deg);
  }
}

.ag {
  transform: translateX(-100%) !important;
}
</style>