var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "dialog-mask2" }, [
    _c("div", { ref: "dialog", staticClass: "dialog-container" }, [
      _c("div", { staticClass: "dialog-header" }, [
        _c("h4", [
          _vm._v(
            _vm._s(
              _vm.$t(
                `default.${_vm.editData !== undefined ? "edit" : "create"}`
              )
            )
          ),
        ]),
        _c(
          "div",
          {
            staticClass: "dialog-close",
            on: {
              click: function ($event) {
                return _vm.$emit("toggle")
              },
            },
          },
          [_c("i", { staticClass: "fas fa-times" })]
        ),
      ]),
      _c("div", { staticClass: "dialog-block" }, [
        _c("main", [
          _c(
            "div",
            { staticClass: "input-block" },
            [
              _c("div", { staticClass: "divider-v2" }, [
                _c("div", { staticClass: "divider-v2-text divider-v2-left" }, [
                  _vm._v(
                    _vm._s(_vm.$t(`${_vm.i18nPrefix}dialog.basicSettings`))
                  ),
                ]),
              ]),
              _c("div", { staticClass: "form-box-half form-column" }, [
                _c("div", { staticClass: "form-box" }, [
                  _c("label", [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t(`${_vm.i18nPrefix}campaignName`)) +
                        " "
                    ),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.formModel.name,
                          expression: "formModel.name",
                        },
                      ],
                      attrs: {
                        type: "text",
                        placeholder: _vm.$t(`${_vm.i18nPrefix}campaignName`),
                      },
                      domProps: { value: _vm.formModel.name },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.formModel, "name", $event.target.value)
                        },
                      },
                    }),
                  ]),
                ]),
                _c("div", { staticClass: "form-box" }, [
                  _c("label", [
                    _c("span", { staticClass: "span-row" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value:
                              _vm.ruleSetting.basicInformation.memberCondition
                                .isEnabled,
                            expression:
                              "ruleSetting.basicInformation.memberCondition.isEnabled",
                          },
                        ],
                        attrs: { type: "checkbox" },
                        domProps: {
                          checked: Array.isArray(
                            _vm.ruleSetting.basicInformation.memberCondition
                              .isEnabled
                          )
                            ? _vm._i(
                                _vm.ruleSetting.basicInformation.memberCondition
                                  .isEnabled,
                                null
                              ) > -1
                            : _vm.ruleSetting.basicInformation.memberCondition
                                .isEnabled,
                        },
                        on: {
                          change: function ($event) {
                            var $$a =
                                _vm.ruleSetting.basicInformation.memberCondition
                                  .isEnabled,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  _vm.$set(
                                    _vm.ruleSetting.basicInformation
                                      .memberCondition,
                                    "isEnabled",
                                    $$a.concat([$$v])
                                  )
                              } else {
                                $$i > -1 &&
                                  _vm.$set(
                                    _vm.ruleSetting.basicInformation
                                      .memberCondition,
                                    "isEnabled",
                                    $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                  )
                              }
                            } else {
                              _vm.$set(
                                _vm.ruleSetting.basicInformation
                                  .memberCondition,
                                "isEnabled",
                                $$c
                              )
                            }
                          },
                        },
                      }),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(`${_vm.i18nPrefix}dialog.lotteryCountLimit`)
                          ) +
                          " "
                      ),
                    ]),
                    _c("div", { staticClass: "w-100" }, [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value:
                                _vm.ruleSetting.basicInformation.memberCondition
                                  .periodType,
                              expression:
                                "ruleSetting.basicInformation.memberCondition.periodType",
                            },
                          ],
                          staticClass: "w-50",
                          attrs: {
                            disabled:
                              !_vm.ruleSetting.basicInformation.memberCondition
                                .isEnabled,
                          },
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.ruleSetting.basicInformation
                                  .memberCondition,
                                "periodType",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                          },
                        },
                        _vm._l(_vm.periodType, function (list) {
                          return _c(
                            "option",
                            { key: list.id, domProps: { value: list.id } },
                            [_vm._v(_vm._s(_vm.$t(list.name)))]
                          )
                        }),
                        0
                      ),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.number",
                            value:
                              _vm.ruleSetting.basicInformation.memberCondition
                                .lotteryCountLimit,
                            expression:
                              "ruleSetting.basicInformation.memberCondition.lotteryCountLimit",
                            modifiers: { number: true },
                          },
                        ],
                        staticClass: "w-50",
                        attrs: {
                          type: "number",
                          min: "0",
                          placeholder: _vm.$t(`${_vm.i18nPrefix}dialog.times`),
                          disabled:
                            !_vm.ruleSetting.basicInformation.memberCondition
                              .isEnabled,
                        },
                        domProps: {
                          value:
                            _vm.ruleSetting.basicInformation.memberCondition
                              .lotteryCountLimit,
                        },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.ruleSetting.basicInformation.memberCondition,
                              "lotteryCountLimit",
                              _vm._n($event.target.value)
                            )
                          },
                          blur: function ($event) {
                            return _vm.$forceUpdate()
                          },
                        },
                      }),
                    ]),
                  ]),
                  _c("label", [
                    _c("span", { staticClass: "span-row" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value:
                              _vm.ruleSetting.basicInformation.lotteryCondition
                                .isEnabled,
                            expression:
                              "ruleSetting.basicInformation.lotteryCondition.isEnabled",
                          },
                        ],
                        attrs: { type: "checkbox" },
                        domProps: {
                          checked: Array.isArray(
                            _vm.ruleSetting.basicInformation.lotteryCondition
                              .isEnabled
                          )
                            ? _vm._i(
                                _vm.ruleSetting.basicInformation
                                  .lotteryCondition.isEnabled,
                                null
                              ) > -1
                            : _vm.ruleSetting.basicInformation.lotteryCondition
                                .isEnabled,
                        },
                        on: {
                          change: function ($event) {
                            var $$a =
                                _vm.ruleSetting.basicInformation
                                  .lotteryCondition.isEnabled,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  _vm.$set(
                                    _vm.ruleSetting.basicInformation
                                      .lotteryCondition,
                                    "isEnabled",
                                    $$a.concat([$$v])
                                  )
                              } else {
                                $$i > -1 &&
                                  _vm.$set(
                                    _vm.ruleSetting.basicInformation
                                      .lotteryCondition,
                                    "isEnabled",
                                    $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                  )
                              }
                            } else {
                              _vm.$set(
                                _vm.ruleSetting.basicInformation
                                  .lotteryCondition,
                                "isEnabled",
                                $$c
                              )
                            }
                          },
                        },
                      }),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(`${_vm.i18nPrefix}dialog.totalLotteryCount`)
                          ) +
                          " "
                      ),
                    ]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.number",
                          value:
                            _vm.ruleSetting.basicInformation.lotteryCondition
                              .totalLotteryCount,
                          expression:
                            "ruleSetting.basicInformation.lotteryCondition.totalLotteryCount",
                          modifiers: { number: true },
                        },
                      ],
                      attrs: {
                        type: "number",
                        min: "0",
                        placeholder: _vm.$t(`${_vm.i18nPrefix}dialog.times`),
                        disabled:
                          !_vm.ruleSetting.basicInformation.lotteryCondition
                            .isEnabled,
                      },
                      domProps: {
                        value:
                          _vm.ruleSetting.basicInformation.lotteryCondition
                            .totalLotteryCount,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.ruleSetting.basicInformation.lotteryCondition,
                            "totalLotteryCount",
                            _vm._n($event.target.value)
                          )
                        },
                        blur: function ($event) {
                          return _vm.$forceUpdate()
                        },
                      },
                    }),
                    _c("span", { ref: "rulesname", staticClass: "form-rules" }),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "form-box-half" }, [
                _c("div", { staticClass: "form-box" }, [
                  _c(
                    "label",
                    [
                      _c("span", [_vm._v(_vm._s(_vm.$t("default.startTime")))]),
                      _c("date-picker", {
                        attrs: {
                          format: "YYYY-MM-DD HH",
                          "value-type": "format",
                          type: "datetime",
                          "disabled-date": _vm.disabledStart,
                        },
                        model: {
                          value: _vm.startTime,
                          callback: function ($$v) {
                            _vm.startTime = $$v
                          },
                          expression: "startTime",
                        },
                      }),
                      _c("span", {
                        ref: "rulesstartTime",
                        staticClass: "form-rules",
                      }),
                    ],
                    1
                  ),
                  _c(
                    "label",
                    [
                      _c("span", [_vm._v(_vm._s(_vm.$t("default.endTime")))]),
                      _c("date-picker", {
                        attrs: {
                          format: "YYYY-MM-DD HH:mm",
                          "value-type": "format",
                          type: "datetime",
                          "disabled-date": _vm.disabledEnd,
                        },
                        model: {
                          value: _vm.endTime,
                          callback: function ($$v) {
                            _vm.endTime = $$v
                          },
                          expression: "endTime",
                        },
                      }),
                      _c("span", {
                        ref: "rulesendTime",
                        staticClass: "form-rules",
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
              _c("div", { staticClass: "divider-v2" }, [
                _c("div", { staticClass: "divider-v2-text divider-v2-left" }, [
                  _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}dialog.eligibility`))),
                ]),
              ]),
              _c("div", { staticClass: "form-box-half form-column" }, [
                _c("div", { staticClass: "form-box" }, [
                  _c("label", { staticClass: "label-row" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.ruleSetting.participateStatus.isNewMember,
                          expression:
                            "ruleSetting.participateStatus.isNewMember",
                        },
                      ],
                      attrs: { type: "checkbox" },
                      domProps: {
                        checked: Array.isArray(
                          _vm.ruleSetting.participateStatus.isNewMember
                        )
                          ? _vm._i(
                              _vm.ruleSetting.participateStatus.isNewMember,
                              null
                            ) > -1
                          : _vm.ruleSetting.participateStatus.isNewMember,
                      },
                      on: {
                        change: function ($event) {
                          var $$a =
                              _vm.ruleSetting.participateStatus.isNewMember,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.ruleSetting.participateStatus,
                                  "isNewMember",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.ruleSetting.participateStatus,
                                  "isNewMember",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(
                              _vm.ruleSetting.participateStatus,
                              "isNewMember",
                              $$c
                            )
                          }
                        },
                      },
                    }),
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t(`${_vm.i18nPrefix}dialog.isNewMember`)) +
                        " "
                    ),
                  ]),
                  _c("label", [
                    _c("span", { staticClass: "span-row" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value:
                              _vm.ruleSetting.participateStatus.depositCondition
                                .isEnabled,
                            expression:
                              "ruleSetting.participateStatus.depositCondition.isEnabled",
                          },
                        ],
                        attrs: { type: "checkbox" },
                        domProps: {
                          checked: Array.isArray(
                            _vm.ruleSetting.participateStatus.depositCondition
                              .isEnabled
                          )
                            ? _vm._i(
                                _vm.ruleSetting.participateStatus
                                  .depositCondition.isEnabled,
                                null
                              ) > -1
                            : _vm.ruleSetting.participateStatus.depositCondition
                                .isEnabled,
                        },
                        on: {
                          change: function ($event) {
                            var $$a =
                                _vm.ruleSetting.participateStatus
                                  .depositCondition.isEnabled,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  _vm.$set(
                                    _vm.ruleSetting.participateStatus
                                      .depositCondition,
                                    "isEnabled",
                                    $$a.concat([$$v])
                                  )
                              } else {
                                $$i > -1 &&
                                  _vm.$set(
                                    _vm.ruleSetting.participateStatus
                                      .depositCondition,
                                    "isEnabled",
                                    $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                  )
                              }
                            } else {
                              _vm.$set(
                                _vm.ruleSetting.participateStatus
                                  .depositCondition,
                                "isEnabled",
                                $$c
                              )
                            }
                          },
                        },
                      }),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(`${_vm.i18nPrefix}dialog.depositCondition`)
                          ) +
                          " "
                      ),
                    ]),
                    _c("div", { staticClass: "w-100" }, [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value:
                                _vm.ruleSetting.participateStatus
                                  .depositCondition.periodType,
                              expression:
                                "ruleSetting.participateStatus.depositCondition.periodType",
                            },
                          ],
                          staticClass: "w-50",
                          attrs: {
                            disabled:
                              !_vm.ruleSetting.participateStatus
                                .depositCondition.isEnabled,
                          },
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.ruleSetting.participateStatus
                                  .depositCondition,
                                "periodType",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                          },
                        },
                        _vm._l(_vm.periodType, function (list) {
                          return _c(
                            "option",
                            { key: list.id, domProps: { value: list.id } },
                            [_vm._v(_vm._s(_vm.$t(list.name)))]
                          )
                        }),
                        0
                      ),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.number",
                            value:
                              _vm.ruleSetting.participateStatus.depositCondition
                                .amount,
                            expression:
                              "ruleSetting.participateStatus.depositCondition.amount",
                            modifiers: { number: true },
                          },
                        ],
                        staticClass: "w-50",
                        attrs: {
                          type: "number",
                          min: "0",
                          placeholder: _vm.$t("default.amount"),
                          disabled:
                            !_vm.ruleSetting.participateStatus.depositCondition
                              .isEnabled,
                        },
                        domProps: {
                          value:
                            _vm.ruleSetting.participateStatus.depositCondition
                              .amount,
                        },
                        on: {
                          keyup: function ($event) {
                            _vm.ruleSetting.participateStatus.depositCondition.amount =
                              _vm.GLOBAL.checkValue(
                                _vm.ruleSetting.participateStatus
                                  .depositCondition.amount,
                                0,
                                1,
                                999999999999
                              )
                          },
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.ruleSetting.participateStatus
                                .depositCondition,
                              "amount",
                              _vm._n($event.target.value)
                            )
                          },
                          blur: function ($event) {
                            return _vm.$forceUpdate()
                          },
                        },
                      }),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "form-box" }, [
                  _c(
                    "label",
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(`${_vm.i18nPrefix}dialog.includeAgents`)
                          ) +
                          " "
                      ),
                      _c(
                        "multiselect",
                        {
                          attrs: {
                            label: "name",
                            "track-by": "id",
                            placeholder: "",
                            options: _vm.agentList,
                            multiple: true,
                            "close-on-select": false,
                          },
                          model: {
                            value: _vm.agentData,
                            callback: function ($$v) {
                              _vm.agentData = $$v
                            },
                            expression: "agentData",
                          },
                        },
                        [
                          _c("template", { slot: "noOptions" }, [
                            _vm._v(_vm._s(_vm.$t("default.noData"))),
                          ]),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
              _c("div", { staticClass: "form-box-half" }, [
                _c("div", { staticClass: "form-box" }, [
                  _c(
                    "label",
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(`${_vm.i18nPrefix}dialog.limitMemberLevel`)
                          ) +
                          " "
                      ),
                      _c("multiselect", {
                        attrs: {
                          label: "name",
                          "track-by": "star",
                          placeholder: "",
                          options: _vm.starList,
                          multiple: true,
                          "close-on-select": false,
                        },
                        model: {
                          value: _vm.memberStar,
                          callback: function ($$v) {
                            _vm.memberStar = $$v
                          },
                          expression: "memberStar",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "label",
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(`${_vm.i18nPrefix}dialog.limitMemberTag`)
                          ) +
                          " "
                      ),
                      _c(
                        "multiselect",
                        {
                          attrs: {
                            label: "name",
                            "track-by": "id",
                            placeholder: "",
                            options: _vm.tagList,
                            multiple: true,
                            "close-on-select": false,
                          },
                          model: {
                            value: _vm.memberTag,
                            callback: function ($$v) {
                              _vm.memberTag = $$v
                            },
                            expression: "memberTag",
                          },
                        },
                        [
                          _c("template", { slot: "noOptions" }, [
                            _vm._v(_vm._s(_vm.$t("default.noData"))),
                          ]),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
              _c("div", { staticClass: "divider-v2" }, [
                _c("div", { staticClass: "divider-v2-text divider-v2-left" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t(`${_vm.i18nPrefix}dialog.validBetAmountCondition`)
                    )
                  ),
                ]),
              ]),
              _c("div", { staticClass: "form-box-half form-column" }, [
                _c("div", { staticClass: "form-box" }, [
                  _c("label", [
                    _c("span", { staticClass: "span-row" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value:
                              _vm.ruleSetting.luckyDrawExchangeConditions
                                .validBetAmountCondition.isEnabled,
                            expression:
                              "ruleSetting.luckyDrawExchangeConditions.validBetAmountCondition.isEnabled",
                          },
                        ],
                        attrs: { type: "checkbox" },
                        domProps: {
                          checked: Array.isArray(
                            _vm.ruleSetting.luckyDrawExchangeConditions
                              .validBetAmountCondition.isEnabled
                          )
                            ? _vm._i(
                                _vm.ruleSetting.luckyDrawExchangeConditions
                                  .validBetAmountCondition.isEnabled,
                                null
                              ) > -1
                            : _vm.ruleSetting.luckyDrawExchangeConditions
                                .validBetAmountCondition.isEnabled,
                        },
                        on: {
                          change: function ($event) {
                            var $$a =
                                _vm.ruleSetting.luckyDrawExchangeConditions
                                  .validBetAmountCondition.isEnabled,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  _vm.$set(
                                    _vm.ruleSetting.luckyDrawExchangeConditions
                                      .validBetAmountCondition,
                                    "isEnabled",
                                    $$a.concat([$$v])
                                  )
                              } else {
                                $$i > -1 &&
                                  _vm.$set(
                                    _vm.ruleSetting.luckyDrawExchangeConditions
                                      .validBetAmountCondition,
                                    "isEnabled",
                                    $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                  )
                              }
                            } else {
                              _vm.$set(
                                _vm.ruleSetting.luckyDrawExchangeConditions
                                  .validBetAmountCondition,
                                "isEnabled",
                                $$c
                              )
                            }
                          },
                        },
                      }),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(`${_vm.i18nPrefix}dialog.validBetAmount`)
                          ) +
                          " "
                      ),
                    ]),
                    _c("div", { staticClass: "w-100" }, [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value:
                                _vm.ruleSetting.luckyDrawExchangeConditions
                                  .validBetAmountCondition.periodType,
                              expression:
                                "ruleSetting.luckyDrawExchangeConditions.validBetAmountCondition.periodType",
                            },
                          ],
                          staticClass: "w-50",
                          attrs: {
                            disabled:
                              !_vm.ruleSetting.luckyDrawExchangeConditions
                                .validBetAmountCondition.isEnabled,
                          },
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.ruleSetting.luckyDrawExchangeConditions
                                  .validBetAmountCondition,
                                "periodType",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                          },
                        },
                        _vm._l(_vm.periodType, function (list) {
                          return _c(
                            "option",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: list.id !== 2,
                                  expression: "list.id !== 2",
                                },
                              ],
                              key: list.id,
                              domProps: { value: list.id },
                            },
                            [_vm._v(_vm._s(_vm.$t(list.name)))]
                          )
                        }),
                        0
                      ),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.number",
                            value:
                              _vm.ruleSetting.luckyDrawExchangeConditions
                                .validBetAmountCondition.amount,
                            expression:
                              "ruleSetting.luckyDrawExchangeConditions.validBetAmountCondition.amount",
                            modifiers: { number: true },
                          },
                        ],
                        staticClass: "w-50",
                        attrs: {
                          type: "number",
                          min: "0",
                          placeholder: _vm.$t("default.amount"),
                          disabled:
                            !_vm.ruleSetting.luckyDrawExchangeConditions
                              .validBetAmountCondition.isEnabled,
                        },
                        domProps: {
                          value:
                            _vm.ruleSetting.luckyDrawExchangeConditions
                              .validBetAmountCondition.amount,
                        },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.ruleSetting.luckyDrawExchangeConditions
                                .validBetAmountCondition,
                              "amount",
                              _vm._n($event.target.value)
                            )
                          },
                          blur: function ($event) {
                            return _vm.$forceUpdate()
                          },
                        },
                      }),
                    ]),
                  ]),
                  _c("label", [
                    _c("span", { staticClass: "span-row" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value:
                              _vm.ruleSetting.luckyDrawExchangeConditions
                                .depositCondition.isEnabled,
                            expression:
                              "ruleSetting.luckyDrawExchangeConditions.depositCondition.isEnabled",
                          },
                        ],
                        attrs: { type: "checkbox" },
                        domProps: {
                          checked: Array.isArray(
                            _vm.ruleSetting.luckyDrawExchangeConditions
                              .depositCondition.isEnabled
                          )
                            ? _vm._i(
                                _vm.ruleSetting.luckyDrawExchangeConditions
                                  .depositCondition.isEnabled,
                                null
                              ) > -1
                            : _vm.ruleSetting.luckyDrawExchangeConditions
                                .depositCondition.isEnabled,
                        },
                        on: {
                          change: function ($event) {
                            var $$a =
                                _vm.ruleSetting.luckyDrawExchangeConditions
                                  .depositCondition.isEnabled,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  _vm.$set(
                                    _vm.ruleSetting.luckyDrawExchangeConditions
                                      .depositCondition,
                                    "isEnabled",
                                    $$a.concat([$$v])
                                  )
                              } else {
                                $$i > -1 &&
                                  _vm.$set(
                                    _vm.ruleSetting.luckyDrawExchangeConditions
                                      .depositCondition,
                                    "isEnabled",
                                    $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                  )
                              }
                            } else {
                              _vm.$set(
                                _vm.ruleSetting.luckyDrawExchangeConditions
                                  .depositCondition,
                                "isEnabled",
                                $$c
                              )
                            }
                          },
                        },
                      }),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(`${_vm.i18nPrefix}dialog.depositCondition`)
                          ) +
                          " "
                      ),
                    ]),
                    _c("div", { staticClass: "w-100" }, [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value:
                                _vm.ruleSetting.luckyDrawExchangeConditions
                                  .depositCondition.periodType,
                              expression:
                                "ruleSetting.luckyDrawExchangeConditions.depositCondition.periodType",
                            },
                          ],
                          staticClass: "w-50",
                          attrs: {
                            disabled:
                              !_vm.ruleSetting.luckyDrawExchangeConditions
                                .depositCondition.isEnabled,
                          },
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.ruleSetting.luckyDrawExchangeConditions
                                  .depositCondition,
                                "periodType",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                          },
                        },
                        _vm._l(_vm.periodType, function (list) {
                          return _c(
                            "option",
                            { key: list.id, domProps: { value: list.id } },
                            [_vm._v(_vm._s(_vm.$t(list.name)))]
                          )
                        }),
                        0
                      ),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.number",
                            value:
                              _vm.ruleSetting.luckyDrawExchangeConditions
                                .depositCondition.amount,
                            expression:
                              "ruleSetting.luckyDrawExchangeConditions.depositCondition.amount",
                            modifiers: { number: true },
                          },
                        ],
                        staticClass: "w-50",
                        attrs: {
                          type: "number",
                          min: "0",
                          placeholder: _vm.$t("default.amount"),
                          disabled:
                            !_vm.ruleSetting.luckyDrawExchangeConditions
                              .depositCondition.isEnabled,
                        },
                        domProps: {
                          value:
                            _vm.ruleSetting.luckyDrawExchangeConditions
                              .depositCondition.amount,
                        },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.ruleSetting.luckyDrawExchangeConditions
                                .depositCondition,
                              "amount",
                              _vm._n($event.target.value)
                            )
                          },
                          blur: function ($event) {
                            return _vm.$forceUpdate()
                          },
                        },
                      }),
                    ]),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "form-box-half" }, [
                _c("div", { staticClass: "form-box" }, [
                  _c("label", { staticClass: "w-50" }, [
                    _c("span", { staticClass: "span-row" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value:
                              _vm.ruleSetting.luckyDrawExchangeConditions
                                .memberLoginCondition.isEnabled,
                            expression:
                              "ruleSetting.luckyDrawExchangeConditions.memberLoginCondition.isEnabled",
                          },
                        ],
                        attrs: { type: "checkbox" },
                        domProps: {
                          checked: Array.isArray(
                            _vm.ruleSetting.luckyDrawExchangeConditions
                              .memberLoginCondition.isEnabled
                          )
                            ? _vm._i(
                                _vm.ruleSetting.luckyDrawExchangeConditions
                                  .memberLoginCondition.isEnabled,
                                null
                              ) > -1
                            : _vm.ruleSetting.luckyDrawExchangeConditions
                                .memberLoginCondition.isEnabled,
                        },
                        on: {
                          change: function ($event) {
                            var $$a =
                                _vm.ruleSetting.luckyDrawExchangeConditions
                                  .memberLoginCondition.isEnabled,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  _vm.$set(
                                    _vm.ruleSetting.luckyDrawExchangeConditions
                                      .memberLoginCondition,
                                    "isEnabled",
                                    $$a.concat([$$v])
                                  )
                              } else {
                                $$i > -1 &&
                                  _vm.$set(
                                    _vm.ruleSetting.luckyDrawExchangeConditions
                                      .memberLoginCondition,
                                    "isEnabled",
                                    $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                  )
                              }
                            } else {
                              _vm.$set(
                                _vm.ruleSetting.luckyDrawExchangeConditions
                                  .memberLoginCondition,
                                "isEnabled",
                                $$c
                              )
                            }
                          },
                        },
                      }),
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t(`${_vm.i18nPrefix}dialog.ticket`)) +
                          " "
                      ),
                    ]),
                    _c("div", { staticClass: "w-100" }, [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value:
                                _vm.ruleSetting.luckyDrawExchangeConditions
                                  .memberLoginCondition.periodType,
                              expression:
                                "ruleSetting.luckyDrawExchangeConditions.memberLoginCondition.periodType",
                            },
                          ],
                          staticClass: "w-50",
                          attrs: {
                            disabled:
                              !_vm.ruleSetting.luckyDrawExchangeConditions
                                .memberLoginCondition.isEnabled,
                          },
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.ruleSetting.luckyDrawExchangeConditions
                                  .memberLoginCondition,
                                "periodType",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                          },
                        },
                        _vm._l(_vm.periodType, function (list) {
                          return list.id !== 2
                            ? _c(
                                "option",
                                { key: list.id, domProps: { value: list.id } },
                                [_vm._v(_vm._s(_vm.$t(list.name)))]
                              )
                            : _vm._e()
                        }),
                        0
                      ),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.number",
                            value:
                              _vm.ruleSetting.luckyDrawExchangeConditions
                                .memberLoginCondition.ticket,
                            expression:
                              "ruleSetting.luckyDrawExchangeConditions.memberLoginCondition.ticket",
                            modifiers: { number: true },
                          },
                        ],
                        staticClass: "w-50",
                        attrs: {
                          type: "number",
                          min: "0",
                          placeholder: _vm.$t(`${_vm.i18nPrefix}dialog.times`),
                          disabled:
                            !_vm.ruleSetting.luckyDrawExchangeConditions
                              .memberLoginCondition.isEnabled,
                        },
                        domProps: {
                          value:
                            _vm.ruleSetting.luckyDrawExchangeConditions
                              .memberLoginCondition.ticket,
                        },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.ruleSetting.luckyDrawExchangeConditions
                                .memberLoginCondition,
                              "ticket",
                              _vm._n($event.target.value)
                            )
                          },
                          blur: function ($event) {
                            return _vm.$forceUpdate()
                          },
                        },
                      }),
                    ]),
                  ]),
                  _c("label", { staticClass: "w-50" }),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "form-box star-box w-100" },
                _vm._l(_vm.getGameCategoryAndPlatform, function (list, key) {
                  return _c(
                    "div",
                    { key: key, staticClass: "divider-v2-area" },
                    [
                      _c(
                        "div",
                        { staticClass: "divider-v2-text divider-v2-left" },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.GLOBAL.i18nCheckName(
                                _vm.getGamesCategorys.find(
                                  (data) => data.id == key
                                )
                              )
                            )
                          ),
                        ]
                      ),
                      _vm._l(list, function (gamePlatforms) {
                        return _c(
                          "label",
                          { key: gamePlatforms.id, staticClass: "span-row" },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value:
                                    _vm.ruleSetting.luckyDrawExchangeConditions
                                      .validBetAmountCondition.gameConditions,
                                  expression:
                                    "ruleSetting.luckyDrawExchangeConditions.validBetAmountCondition.gameConditions",
                                },
                              ],
                              attrs: {
                                type: "checkbox",
                                disabled:
                                  !_vm.ruleSetting.luckyDrawExchangeConditions
                                    .validBetAmountCondition.isEnabled,
                              },
                              domProps: {
                                value: {
                                  category: key * 1,
                                  pleatform: gamePlatforms.id,
                                },
                                checked: Array.isArray(
                                  _vm.ruleSetting.luckyDrawExchangeConditions
                                    .validBetAmountCondition.gameConditions
                                )
                                  ? _vm._i(
                                      _vm.ruleSetting
                                        .luckyDrawExchangeConditions
                                        .validBetAmountCondition.gameConditions,
                                      {
                                        category: key * 1,
                                        pleatform: gamePlatforms.id,
                                      }
                                    ) > -1
                                  : _vm.ruleSetting.luckyDrawExchangeConditions
                                      .validBetAmountCondition.gameConditions,
                              },
                              on: {
                                change: function ($event) {
                                  var $$a =
                                      _vm.ruleSetting
                                        .luckyDrawExchangeConditions
                                        .validBetAmountCondition.gameConditions,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = {
                                        category: key * 1,
                                        pleatform: gamePlatforms.id,
                                      },
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.ruleSetting
                                            .luckyDrawExchangeConditions
                                            .validBetAmountCondition,
                                          "gameConditions",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.ruleSetting
                                            .luckyDrawExchangeConditions
                                            .validBetAmountCondition,
                                          "gameConditions",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(
                                      _vm.ruleSetting
                                        .luckyDrawExchangeConditions
                                        .validBetAmountCondition,
                                      "gameConditions",
                                      $$c
                                    )
                                  }
                                },
                              },
                            }),
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.GLOBAL.i18nCheckName(
                                    _vm.getGamePlatforms.find(
                                      (data) => data.id == gamePlatforms.id
                                    )
                                  )
                                ) +
                                " "
                            ),
                          ]
                        )
                      }),
                    ],
                    2
                  )
                }),
                0
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        _vm.ruleSetting.luckyDrawExchangeConditions
                          .validBetAmountCondition.turnoverRatio.length,
                      expression:
                        "ruleSetting.luckyDrawExchangeConditions.validBetAmountCondition.turnoverRatio.length",
                    },
                  ],
                  staticClass: "divider-v2",
                },
                [
                  _c(
                    "div",
                    { staticClass: "divider-v2-text divider-v2-left" },
                    [
                      _vm._v(
                        _vm._s(_vm.$t(`${_vm.i18nPrefix}dialog.turnoverRatio`))
                      ),
                    ]
                  ),
                ]
              ),
              _c(
                "div",
                { staticClass: "form-box" },
                _vm._l(
                  _vm.ruleSetting.luckyDrawExchangeConditions
                    .validBetAmountCondition.turnoverRatio,
                  function (rate) {
                    return _c(
                      "label",
                      { key: rate.gameCategory, staticClass: "span-row" },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.GLOBAL.i18nCheckName(
                                _vm.getGamesCategorys.find(
                                  (data) => data.id == rate.gameCategory
                                )
                              )
                            ) +
                            " 1: "
                        ),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.number",
                              value: rate.rate,
                              expression: "rate.rate",
                              modifiers: { number: true },
                            },
                          ],
                          attrs: { type: "text" },
                          domProps: { value: rate.rate },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                rate,
                                "rate",
                                _vm._n($event.target.value)
                              )
                            },
                            blur: function ($event) {
                              return _vm.$forceUpdate()
                            },
                          },
                        }),
                      ]
                    )
                  }
                ),
                0
              ),
              _c(
                "div",
                {
                  staticClass: "divider-v2-area",
                  staticStyle: { display: "block" },
                },
                [
                  _c(
                    "div",
                    { staticClass: "divider-v2-text divider-v2-left" },
                    [
                      _vm._v(
                        _vm._s(_vm.$t(`${_vm.i18nPrefix}dialog.prizeRate`))
                      ),
                    ]
                  ),
                  _c("div", { staticClass: "table-block w-100" }, [
                    _c("div", { staticClass: "add-th" }, [
                      _c(
                        "button",
                        {
                          staticClass: "add-btn",
                          staticStyle: {
                            "background-color": "green",
                            margin: "5px",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.addData("+")
                            },
                          },
                        },
                        [_c("span", [_vm._v("+")])]
                      ),
                      _c(
                        "button",
                        {
                          staticClass: "add-btn",
                          on: {
                            click: function ($event) {
                              return _vm.addData("-")
                            },
                          },
                        },
                        [_c("span", [_vm._v("-")])]
                      ),
                    ]),
                    _c("table", [
                      _c("thead", [
                        _c("tr", [
                          _c("th"),
                          _c("th", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(`${_vm.i18nPrefix}dialog.isBroadcast`)
                              )
                            ),
                          ]),
                          _c("th", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(`${_vm.i18nPrefix}dialog.prizeType`)
                              )
                            ),
                          ]),
                          _c("th", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(`${_vm.i18nPrefix}dialog.prizeName`)
                              )
                            ),
                          ]),
                          _c("th", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(`${_vm.i18nPrefix}dialog.wageringRate`)
                              )
                            ),
                          ]),
                          _c("th", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(`${_vm.i18nPrefix}dialog.guarantCount`)
                              )
                            ),
                          ]),
                          _c("th", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(`${_vm.i18nPrefix}dialog.prizeCount`)
                              )
                            ),
                          ]),
                          _c("th", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  `${_vm.i18nPrefix}dialog.lotteryCountlimit`
                                )
                              )
                            ),
                          ]),
                          _c("th", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(`${_vm.i18nPrefix}dialog.prizeWeight`)
                              )
                            ),
                          ]),
                        ]),
                      ]),
                      _c(
                        "tbody",
                        _vm._l(
                          _vm.ruleSetting.rewordSetting.rewardList,
                          function (list, index) {
                            return _c("tr", { key: index }, [
                              _c("td", [_vm._v(_vm._s(index + 1))]),
                              _c("td", [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: list.isBroadcast,
                                      expression: "list.isBroadcast",
                                    },
                                  ],
                                  attrs: { type: "checkbox" },
                                  domProps: {
                                    checked: Array.isArray(list.isBroadcast)
                                      ? _vm._i(list.isBroadcast, null) > -1
                                      : list.isBroadcast,
                                  },
                                  on: {
                                    change: function ($event) {
                                      var $$a = list.isBroadcast,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              list,
                                              "isBroadcast",
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              list,
                                              "isBroadcast",
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(list, "isBroadcast", $$c)
                                      }
                                    },
                                  },
                                }),
                              ]),
                              _c("td", [
                                _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: list.prizeType,
                                        expression: "list.prizeType",
                                      },
                                    ],
                                    on: {
                                      change: function ($event) {
                                        var $$selectedVal =
                                          Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function (o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function (o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                        _vm.$set(
                                          list,
                                          "prizeType",
                                          $event.target.multiple
                                            ? $$selectedVal
                                            : $$selectedVal[0]
                                        )
                                      },
                                    },
                                  },
                                  _vm._l(_vm.prizeType, function (list) {
                                    return _c(
                                      "option",
                                      {
                                        key: list.id,
                                        domProps: { value: list.id },
                                      },
                                      [_vm._v(_vm._s(_vm.$t(list.name)))]
                                    )
                                  }),
                                  0
                                ),
                              ]),
                              _c("td", [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: list.prizeName,
                                      expression: "list.prizeName",
                                    },
                                  ],
                                  attrs: { type: "text" },
                                  domProps: { value: list.prizeName },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        list,
                                        "prizeName",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                              ]),
                              _c("td", [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model.number",
                                      value: list.wageringRate,
                                      expression: "list.wageringRate",
                                      modifiers: { number: true },
                                    },
                                  ],
                                  attrs: { type: "number", min: "0" },
                                  domProps: { value: list.wageringRate },
                                  on: {
                                    keyup: function ($event) {
                                      list.wageringRate = _vm.GLOBAL.checknull(
                                        list.wageringRate
                                      )
                                    },
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        list,
                                        "wageringRate",
                                        _vm._n($event.target.value)
                                      )
                                    },
                                    blur: function ($event) {
                                      return _vm.$forceUpdate()
                                    },
                                  },
                                }),
                              ]),
                              _c("td", [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model.number",
                                      value: list.guarantCount,
                                      expression: "list.guarantCount",
                                      modifiers: { number: true },
                                    },
                                  ],
                                  attrs: {
                                    type: "number",
                                    min: "0",
                                    required: "required",
                                  },
                                  domProps: { value: list.guarantCount },
                                  on: {
                                    keyup: function ($event) {
                                      list.guarantCount = _vm.GLOBAL.checknull(
                                        list.guarantCount
                                      )
                                    },
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        list,
                                        "guarantCount",
                                        _vm._n($event.target.value)
                                      )
                                    },
                                    blur: function ($event) {
                                      return _vm.$forceUpdate()
                                    },
                                  },
                                }),
                              ]),
                              _c("td", [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model.number",
                                      value: list.prizeCount,
                                      expression: "list.prizeCount",
                                      modifiers: { number: true },
                                    },
                                  ],
                                  attrs: {
                                    type: "number",
                                    min: "0",
                                    required: "required",
                                  },
                                  domProps: { value: list.prizeCount },
                                  on: {
                                    keyup: function ($event) {
                                      list.prizeCount = _vm.GLOBAL.checkValue(
                                        list.prizeCount,
                                        0,
                                        1,
                                        999999999999
                                      )
                                    },
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        list,
                                        "prizeCount",
                                        _vm._n($event.target.value)
                                      )
                                    },
                                    blur: function ($event) {
                                      return _vm.$forceUpdate()
                                    },
                                  },
                                }),
                              ]),
                              _c("td", [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model.number",
                                      value: list.lotteryCountlimit,
                                      expression: "list.lotteryCountlimit",
                                      modifiers: { number: true },
                                    },
                                  ],
                                  attrs: {
                                    type: "number",
                                    min: "0",
                                    required: "required",
                                  },
                                  domProps: { value: list.lotteryCountlimit },
                                  on: {
                                    keyup: function ($event) {
                                      list.lotteryCountlimit =
                                        _vm.GLOBAL.checknull(
                                          list.lotteryCountlimit
                                        )
                                    },
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        list,
                                        "lotteryCountlimit",
                                        _vm._n($event.target.value)
                                      )
                                    },
                                    blur: function ($event) {
                                      return _vm.$forceUpdate()
                                    },
                                  },
                                }),
                              ]),
                              _c("td", [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model.number",
                                      value: list.prizeWeight,
                                      expression: "list.prizeWeight",
                                      modifiers: { number: true },
                                    },
                                  ],
                                  attrs: {
                                    type: "number",
                                    min: "0",
                                    required: "required",
                                  },
                                  domProps: { value: list.prizeWeight },
                                  on: {
                                    keyup: function ($event) {
                                      list.prizeWeight = _vm.GLOBAL.checknull(
                                        list.prizeWeight
                                      )
                                    },
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        list,
                                        "prizeWeight",
                                        _vm._n($event.target.value)
                                      )
                                    },
                                    blur: function ($event) {
                                      return _vm.$forceUpdate()
                                    },
                                  },
                                }),
                              ]),
                            ])
                          }
                        ),
                        0
                      ),
                    ]),
                  ]),
                ]
              ),
              _c("div", { staticClass: "divider-v2" }, [
                _c("div", { staticClass: "divider-v2-text divider-v2-left" }, [
                  _vm._v(
                    _vm._s(_vm.$t(`${_vm.i18nPrefix}dialog.luckyDrawStyle`))
                  ),
                ]),
              ]),
              _vm._l(_vm.luckyDrawStyle, function (style) {
                return _c(
                  "label",
                  {
                    key: style.id,
                    staticClass: "luckyDrawStyle",
                    class: {
                      chooiceStyle:
                        _vm.ruleSetting.luckyDrawStyleId == style.id,
                    },
                    on: {
                      click: function ($event) {
                        _vm.ruleSetting.luckyDrawStyleId = style.id
                      },
                    },
                  },
                  [
                    _c("div", [
                      _c("span", [
                        _c("input", {
                          staticClass: "Checkbox",
                          attrs: { type: "checkbox", id: "check" + style.id },
                          domProps: {
                            checked:
                              _vm.ruleSetting.luckyDrawStyleId == style.id,
                          },
                        }),
                        _c("label", {
                          class: {
                            chooiceCheck:
                              _vm.ruleSetting.luckyDrawStyleId == style.id,
                          },
                          attrs: { for: "check" + style.id },
                        }),
                      ]),
                    ]),
                    _c("img", { attrs: { src: style.imageUrl } }),
                  ]
                )
              }),
              _c("div", { staticClass: "divider-v2" }, [
                _c("div", { staticClass: "divider-v2-text divider-v2-left" }, [
                  _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}dialog.front`))),
                ]),
              ]),
              _c("div", { staticClass: "form-box-half" }, [
                _c("div", { staticClass: "form-box" }, [
                  _c("label", [
                    _vm._v(" " + _vm._s(_vm.$t(`default.sort`)) + " "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.formModel.sort,
                          expression: "formModel.sort",
                        },
                      ],
                      attrs: {
                        type: "number",
                        min: "0",
                        placeholder: _vm.$t(`default.sort`),
                      },
                      domProps: { value: _vm.formModel.sort },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.formModel, "sort", $event.target.value)
                        },
                      },
                    }),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "imgDiv w-100" }, [
                _c(
                  "div",
                  {
                    staticClass: "previewDiv",
                    on: {
                      click: function ($event) {
                        return _vm.upload("File")
                      },
                    },
                  },
                  [
                    !_vm.File
                      ? _c("span", [
                          _vm._v(
                            _vm._s(_vm.$t("default.clickToUpload")) + " Image"
                          ),
                        ])
                      : _c("img", { attrs: { src: _vm.File } }),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "previewDiv",
                    on: {
                      click: function ($event) {
                        return _vm.upload("MobileFile")
                      },
                    },
                  },
                  [
                    !_vm.MobileFile
                      ? _c("span", [
                          _vm._v(
                            _vm._s(_vm.$t("default.clickToUpload")) +
                              " MobileImage"
                          ),
                        ])
                      : _c("img", { attrs: { src: _vm.MobileFile } }),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "previewDiv",
                    on: {
                      click: function ($event) {
                        return _vm.upload("AppFile")
                      },
                    },
                  },
                  [
                    !_vm.AppFile
                      ? _c("span", [
                          _vm._v(
                            _vm._s(_vm.$t("default.clickToUpload")) +
                              " AppImage"
                          ),
                        ])
                      : _c("img", { attrs: { src: _vm.AppFile } }),
                  ]
                ),
              ]),
              _c("input", {
                ref: "file",
                staticClass: "custom-file",
                attrs: { type: "file" },
                on: { change: _vm.fileSelect },
              }),
              _c("div", { staticClass: "form-box w-100" }, [
                _c(
                  "label",
                  { staticClass: "w-100" },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t(`${_vm.i18nPrefix}dialog.copywriting`)) +
                        " "
                    ),
                    _c("ckeditor", {
                      attrs: { "editor-url": this.GLOBAL.CKEditorUrl },
                      model: {
                        value: _vm.ruleSetting.html,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleSetting, "html", $$v)
                        },
                        expression: "ruleSetting.html",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
        ]),
      ]),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.detail == undefined,
              expression: "detail == undefined",
            },
          ],
          staticClass: "advance-block-release",
        },
        [
          _c("div", { staticClass: "m-auto" }, [
            _c(
              "button",
              {
                staticClass: "normal-btn",
                on: {
                  click: function ($event) {
                    return _vm.submit()
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("default.confirm")))]
            ),
            _c(
              "button",
              {
                staticClass: "delete-btn",
                on: {
                  click: function ($event) {
                    return _vm.$emit("toggle")
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("default.cancel")))]
            ),
          ]),
          _c(
            "button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    _vm.isCampaign == undefined && _vm.editData !== undefined,
                  expression:
                    "isCampaign == undefined && editData !== undefined",
                },
              ],
              staticClass: "btn-orange",
              attrs: { copywriting: "" },
              on: {
                click: function ($event) {
                  return _vm.submit(true)
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}release`)))]
          ),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }