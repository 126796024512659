<template>
  <div>
    <div class="container-view">
      <button class="search-btn main-style-btn" @click="search()">{{ $t("default.search") }}</button>
      <div class="header">
        <h5>{{ $t("title." + $route.meta.title) }}</h5>
      </div>
      <div class="main">
        <div class="input-block">
          <label>
            {{ $t("default.member") }}
            <input type="text" v-model="searchForm.memberAccount" />
          </label>
          <label>
            {{ $t("default.admin") }}
            <input type="text" v-model="searchForm.auditAccount" />
          </label>
          <label>
            {{ $t(`${i18nPrefix}startTime`) }}
            <date-picker v-model="startTime" format="YYYY-MM-DD HH:mm" value-type="format" type="datetime"></date-picker>
          </label>
          <label>
            {{ $t(`${i18nPrefix}endTime`) }}
            <date-picker v-model="endTime" format="YYYY-MM-DD HH:mm" value-type="format" type="datetime"></date-picker>
          </label>
        </div>
        <div class="condition-block">
          <multiselect
              v-model="updateType"
              label="name"
              track-by="id"
              :placeholder="$t('default.plzSelect')"
              :custom-label="GLOBAL.i18nCheckName"
              :options="conditionListName"
              :multiple="true"
              :searchable="false"
          ></multiselect>
          <div class="button-block">
            <button class="normal-btn-v2 main-style-btn" @click="updateType = [...conditionListName]">{{ $t("default.selectAll") }}</button>
            <button class="normal-btn-v2 main-style-btn" @click="updateType = []">{{ $t("default.clear") }}</button>
          </div>
        </div>
        <div class="advance-block">
          <QuickSearch @changeTime="changeTime"></QuickSearch>
          <button class="search-pc-btn main-style-btn px-5" @click="search()">{{ $t("default.search") }}</button>
        </div>
      </div>
    </div>

    <div class="container-view">
      <div class="table-block">
        <table>
          <thead>
            <tr>
              <th>{{ $t("default.member") }}</th>
              <th>{{ $t(`${i18nPrefix}updateType`) }}</th>
              <th>{{ $t(`${i18nPrefix}oldValue`) }}</th>
              <th>{{ $t(`${i18nPrefix}newValue`) }}</th>
              <th>{{ $t("default.admin") }}</th>
              <th>{{ $t(`${i18nPrefix}updateTime`) }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="!tableList || tableList.length === 0">
              <td colspan="6">{{ $t("default.noData") }}</td>
            </tr>
            <tr v-for="list of tableList" :key="list.id">
              <td>{{ list.memberAccount }}</td>
              <td>
                {{
                  GLOBAL.i18nCheckName(conditionListName.find((val) => {
                    return val.id === list.updateType;
                  }))
                }}
              </td>
              <td>{{ $t(list.oldValue) }}</td>
              <td>{{ $t(list.newValue) }}</td>
              <td>{{ list.auditAccount }}</td>
              <td>{{ list.updateTime }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <Pagination
        v-if="totalCount > 0"
        :totalCount="totalCount"
        @handlePage="handlePage"
      />
    </div>
  </div>
</template>

<script>
import { updateRecordList , SelectMenu } from "@/api/report-various/member-update-record";
import { searchModel } from "./model";
import { transToday,joinT } from "@/utils/transDateUtils";
import Pagination from "@/layout/components/Pagination";
import handlePageUtils from "@/layout/mixins/handlePageUtils";
import QuickSearch from "@/layout/components/QuickSearch";

import Multiselect from "vue-multiselect";

export default {
  name: "MemberUpdateRecord",
  data() {
    return {
      searchForm: _.cloneDeep(searchModel),
      startTime: transToday("from"),
      endTime: transToday("to"),
      conditionListName: [],
      tempList: [],
      updateType: [],
      i18nPrefix: "report-various.member-update-record."
    };
  },
  mixins: [handlePageUtils],
  components: {
    Pagination,
    QuickSearch,
    Multiselect,
  },
  async created() {
    await SelectMenu().then( res => this.conditionListName = res.data.memberUpdateTypes
                              .map(data => { return { id: data.id , name: "MemberUpdateType."+data.name}}))
  },
  methods: {
    search() {
      this.searchForm.startTime = this.startTime ? joinT(this.startTime + this.GLOBAL.startSecond+ this.GLOBAL.timeZone) : undefined;
      this.searchForm.endTime = this.endTime ? joinT(this.endTime + this.GLOBAL.endSecond+ this.GLOBAL.timeZone) : undefined;
      this.searchForm.updateType = this.updateType.map(
        (value) => value.id
      );
      updateRecordList(this.searchForm).then((res) => {
        this.allData = res.data;
        this.dataSort('updateTime', 'time', true);
      });
    },
    changeTime(time) {
      this.startTime = time.startTime;
      this.endTime = time.endTime;
      this.search();
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
.list-complete-item {
  transition: all 1s;
}
.list-complete-enter, .list-complete-leave-to
/* .list-complete-leave-active for below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(30px);
}
.list-complete-leave-active {
  position: absolute;
}
</style>