<template>
  <div class="dialog-mask" @mousedown.self="$emit('toggle')">
    <div class="dialog-container">
      <div class="dialog-block">
        <div class="header">
          <h4>{{ $t("default.create") }}{{ $t("Permission." + $route.meta.permissionName)  }}</h4>
        </div>
        <div class="divider"></div>
        <main>
          <div class="input-block">
            <label>
              {{ $t("default.sort") }}
              <input type="text" v-model="createModel.sort" />
            </label>
            <label>
              {{ $t("default.redirectUrl") }}
              <input type="text" v-model="createModel.redirectUrl" />
            </label>
            <label>
              {{ $t("default.status") }}
              <select v-model="createModel.status">
                <option :value="1 * 1">{{ $t("default.active") }}</option>
                <option :value="2 * 1">{{ $t("default.inactive") }}</option>
              </select>
            </label>
            <label></label>
            <label class="title">
              {{ $t("default.title") }}
              <textarea v-model="createModel.title" rows="3"></textarea>
            </label>
            <div class="imgDiv">
              <div class="previewDiv" @click="upload('File')">
                <span v-if="!File">{{ $t("default.clickToUpload") }}Image</span>
                <img v-else :src="File" />
              </div>
            </div>
            <input type="file" ref="file" class="custom-file" @change="fileSelect" />
          </div>
          <div class="advance-block">
            <button class="confirm-btn main-style-btn" @click="create()">{{ $t("default.confirm")
              }}</button>
            <button class="normal-btn-v2 main-style-btn" @click="$emit('toggle')">{{ $t("default.cancel") }}</button>
          </div>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Create",
  data() {
    return {
      createModel: {
        status: 1,
        redirectUrl: undefined,
        sort: undefined,
        title: undefined,
        File: undefined
      },
      formData: new FormData(),
      File: undefined,
      chooiceImg: undefined,
    };
  },
  methods: {
    create() {
      Object.entries(this.createModel).forEach(([key, value]) => {
        if (value) this.formData.append(key, value);
      });
      this.$emit('create', this.formData)
    },
    upload(tag) {
      this.chooiceImg = tag;
      this[tag] = undefined;
      this.formData.delete(tag);
      this.$refs.file.value = "";
      this.$refs.file.click();
    },
    fileSelect(e) {
      if (!e.target.files?.length) return;
      this[this.chooiceImg] = URL.createObjectURL(e.target.files[0]);
      let newFile = new File(
        [e.target.files[0]],
        this.chooiceImg + e.target.files[0].name
      );
      this.formData.append(this.chooiceImg, newFile);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/banner.scss";

.input-block {
  label {
    justify-content: flex-start;
    width: 40%;
  }

  .title {
    width: 80% !important;
  }

  .imgDiv {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;

    .previewDiv {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 45%;
      height: 200px;
      padding: 0.5em;
      background-color: gray;
      border-radius: 10px;
      margin: 20px auto;

      span {
        display: block;
        font-size: 20px;
      }

      img {
        display: block;
        height: auto;
        width: auto;
        max-height: 95%;
        max-width: 95%;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .input-block {
    label {
      width: 50%;
    }

    .title {
      width: 100%;
    }

    .CK {
      width: 100%;
    }
  }
}
</style>