<template>
  <div>
    <div class="bookmark-box">
      <div class="bookmark-view">
        <div v-for="list of bookmarkColumn" :key="list.id" @click="bookmark(list.id)"
          :class="{ chooice: bookmarkNum == list.id }">
          {{ list.name }}
        </div>
      </div>
    </div>
    <div>
      <div class="container-view">
        <button v-if="bookmarkNum == 3 || bookmarkNum == 4" class="search-btn main-style-btn" @click="search()">
          {{ $t("default.search") }}
        </button>
        <div class="header">
          <h5>{{ $t(`${i18nPrefix}memberReferral`) }}</h5>
          <div class="float-right">
            <button v-if="bookmarkNum == 1" class="create-btn main-style-btn" @click="handleDialog(undefined, 'Edit')">
              {{ $t("default.create") + " " }}{{ $t(`${i18nPrefix}template`) }}
            </button>
          </div>
        </div>
        <div class="main">
          <div class="input-block" v-if="bookmarkNum == 3 || bookmarkNum == 4">
            <label>
              {{ $t(`${i18nPrefix}date`) }}
              <select v-model="searchForm.campaignListId">
                <option :value="undefined" v-if="bookmarkNum == 3">
                  {{ $t("default.none") }}
                </option>
                <option v-for="d of periodList" :value="d.id" :key="d.id">
                  {{ d.name }}
                </option>
              </select>
            </label>
            <label v-if="bookmarkNum == 4">
              {{ $t("member.member-management.eventTime") }}
              <select v-model="searchForm.settleCycleId">
                <option v-if="!settleCycleList?.length" :value="0 * 1">{{ $t("default.noData") }}</option>
                <option v-for="d of settleCycleList" :value="d.id" :key="d.id">
                  {{ d.name }}
                </option>
              </select>
            </label>
            <label>
              <div class="input-block-check">
                <span style="margin-right: 10px">{{
                  $t("default.member")
                }}</span>
                <input id="isFuzzySearch" type="checkbox" v-model="searchForm.isFuzzySearch" />
                <label for="isFuzzySearch">{{
                  $t("default.isFuzzySearch")
                }}</label>
              </div>
              <input type="text" v-model="searchForm.memberAccount" />
            </label>
            <label v-if="bookmarkNum == 3">
              {{ $t("default.startTime") }}
              <date-picker v-model="startTime" format="YYYY-MM-DD" value-type="format" type="date"></date-picker>
            </label>
            <label v-if="bookmarkNum == 3">
              {{ $t("default.endTime") }}
              <date-picker v-model="endTime" format="YYYY-MM-DD" value-type="format" type="date"></date-picker>
            </label>
            <label v-show="bookmarkNum == 3">
              {{ $t("default.status") }}
              <select v-model="searchForm.discountAuditStatus">
                <option v-for="list of getDiscountAuditStatusAddDefault" :key="list.id" :value="list.id">
                  {{ $t(list.name) }}
                </option>
              </select>
            </label>
          </div>
          <div v-if="bookmarkNum == 3 || bookmarkNum == 4" class="advance-block">
            <button class="search-pc-btn main-style-btn px-5" @click="search()">
              {{ $t("default.search") }}
            </button>
          </div>
        </div>
      </div>
      <div class="container-view">
        <div class="table-block">
          <TreeTable ref="treeTable" :list="allData" :column="tableColumn">
            <!-- 操作按鈕 -->
            <template v-slot:column_CampaignSetting="props">
              <button class="normal-btn" @click="handleDialog({ editData: props }, 'Edit')">
                {{ $t("default.edit") }}
              </button>
              <button class="delete-btn" @click="handleDeleteSetting(props.id)">
                {{ $t("default.delete") }}
              </button>
            </template>
            <template v-slot:column_CampaignLuckyDraw="props">
              <button class="normal-btn" @click="
                handleDialog(
                  {
                    isCampaign: true,
                    detail:
                      props.status == 1 &&
                        getUserinfo.permissions.includes(170502)
                        ? undefined
                        : true,
                    editData: props,
                  },
                  'Edit'
                )
                ">
                {{
                  props.status == 1 && getUserinfo.permissions.includes(170502)
                  ? $t("default.edit")
                  : $t("default.detail")
                }}
              </button>
              <button v-show="props.status !== 3" :class="{
                    'btn-orange': props.status == 1,
                    'delete-btn': props.status == 2,
                  }" @click="
      props.status == 1
        ? release(props, 2)
        : handleDeleteLuckyDraw(props)
      ">
                {{
                  props.status == 1
                  ? $t(`${i18nPrefix}release`)
                  : $t("default.inactive")
                }}
              </button>
            </template>
            <!-- 前台開關 -->
            <template v-slot:column_FrontSwitchEnable="props" v-if="getUserinfo.koreanStyleInterface">
              <label class="slide-block" style="margin: auto" :class="{ 'slide-block-on': props.frontSwitchEnable }"
                v-if="props.status == 2">
                <div class="slide" @click="toggleFrontEnable(props)" :class="{ 'slide-on': props.frontSwitchEnable }">
                  <label for="frontSwitchEnable" style="display: none"></label>
                  <input type="checkbox" id="frontSwitchEnable" v-model="props.frontSwitchEnable" style="display: none" />
                </div>
              </label>
            </template>
            <template v-slot:column_RebateList="props">
              <button class="normal-btn" @click="handleDialog({ id: props.id }, 'detail')">
                {{ $t("default.detail") }}
              </button>
              <button class="normal-btn" @click="handleDialog({ data: props }, 'audit')"
                v-show="props.discountAuditStatus == 1">
                {{ $t("default.audit") }}
              </button>
            </template>
            <!-- 活動期別 -->
            <template v-slot:column_date="props">{{
              `${props.id}- ${props.startTime}-${props.endTime}`
            }}</template>
            <!-- 狀態 -->
            <template v-slot:column_status="props">
              {{
                GLOBAL.i18nCheckName(
                  campaignStatus.find((d) => d.id == props.status)
                )
              }}
            </template>
            <template v-slot:column_discountAuditStatus="props">
              {{
                GLOBAL.i18nCheckName(
                  getDiscountAuditStatusAddDefault.find(
                    (d) => d.id == props.discountAuditStatus
                  )
                )
              }}
            </template>
            <!-- 結算週期 -->
            <template v-slot:column_settleCycle="props">{{
              GLOBAL.i18nCheckName(
                settleCycle.find((d) => d.id == props.settleCycle)
              )
            }}</template>
            <!-- 回饋比例 -->
            <!-- <template v-slot:column_calculationRate="props">{{ props.calculationRate + " %" }}</template> -->
            <!-- 等級加成比例 -->
            <!-- <template v-slot:column_levelBonusRate="props">{{ props.levelBonusRate + " %" }}</template> -->
          </TreeTable>
        </div>
        <serverPagination v-if="bookmarkNum == 3 || bookmarkNum == 4" :page="page" @ServerPageUtils="search"
          @changeTake="changeTake">
        </serverPagination>
      </div>
    </div>
    <transition name="fade" mode="out-in">
      <component :is="currDialog" :i18nPrefix="i18nPrefix" :starList="getStars" :tagList="tagList"
        :agentList="getAgentList" :auditMethod="auditMethod" :periodList="periodList" :GamesCategorys="getGamesCategorys"
        :calculationMethod="calculationMethod" :settleCycle="settleCycle" v-bind="dialogData" @audit="audit"
        @toggle="currDialog = undefined" @search="search">
      </component>
    </transition>
    <Alert v-if="alertMsg" :message="alertMsg" @toggle="alertMsg = undefined" @doIt="deleteFunction" />
  </div>
</template>

<script>
import {
  selectMenu,
  getCampaignSetting,
  deleteCampaignSetting,
  updateMemberReferral,
  getMemberReferralList,
  getRebateList,
  auditRebateList,
  getMemberBettingList,
  getSettleCycle,
  updateFrontSwitch
} from "@/api/campaign/memberReferral";
import { searchModel } from "./model";
import Edit from "./dialog/edit";
import Audit from "./dialog/audit";
import Detail from "./dialog/detail";
import Photo from "@/layout/components/photo";
import Alert from "@/layout/components/Alert";
import handleDialog from "@/layout/mixins/handleDialog";
import TreeTable from "@/layout/components/TreeTable";
import { joinT } from "@/utils/transDateUtils";
import { mapGetters } from "vuex";
import serverPagination from "@/layout/components/serverPagination";
import handleServerPageUtils from "@/layout/mixins/handleServerPageUtils";
import { toggleStyle } from "@/utils/slideUtils";

export default {
  name: "MemberReferral",
  data() {
    return {
      searchForm: _.cloneDeep(searchModel),
      allData: [],
      bookmarkNum: 2,
      auditMethod: [],
      calculationMethod: [],
      settleCycle: [],
      settleCycleList: [],
      campaignStatus: [],
      alertMsg: "",
      periodList: [],
      deleteData: undefined,
      startTime: undefined,
      endTime: undefined,
      total: {
        totalRecipientCount: 0,
        totalRedemptionCount: 0,
        totalPayoutAmount: 0,
        totalRegisterCount: 0,
        totalValidBetAmount: 0,
        totalMemberDepositCount: 0,
        totalDepositCount: 0,
        totalDepositAmount: 0,
      },
      i18nPrefix: "campaign.memberReferral."
    };
  },
  components: {
    Edit,
    Audit,
    Detail,
    Alert,
    Photo,
    TreeTable,
    serverPagination,
  },
  mixins: [handleDialog, handleServerPageUtils],
  computed: {
    ...mapGetters([
      "getUserinfo",
      "getAgentListAddDefault",
      "getStars",
      "tagList",
      "getAgentList",
      "getStatusList",
      "getGamesCategorys",
      "getDiscountAuditStatusAddDefault",
    ]),
    tableColumn() {
      switch (this.bookmarkNum) {
        case 1:
          return [
            {
              key: "CampaignSetting",
              name: "",
              width: 80,
            },
            {
              key: "name",
              name: this.$t(`${this.i18nPrefix}template`),
            },
            {
              key: "createUser",
              name: this.$t(`${this.i18nPrefix}createUser`),
              isText: true,
            },
            {
              key: "createTime",
              name: this.$t(`${this.i18nPrefix}createTime`),
            },
            {
              key: "updateUser",
              name: this.$t(`${this.i18nPrefix}updateUser`),
              isText: true,
            },
            {
              key: "updateTime",
              name: this.$t(`${this.i18nPrefix}updateTime`),
            },
          ];
        case 2:
          const kr_case2 = [
            {
              key: "CampaignLuckyDraw",
              name: "",
              width: 80,
            },
            {
              key: "FrontSwitchEnable",
              name: this.$t(`${this.i18nPrefix}frontSwitchEnable`),
            },
            {
              key: "name",
              name: this.$t(`${this.i18nPrefix}campaignName`),
            },
            {
              key: "date",
              name: this.$t(`${this.i18nPrefix}date`),
            },
            {
              key: "status",
              name: this.$t("default.status"),
            },
            {
              key: "updateUser",
              name: this.$t(`${this.i18nPrefix}updateUser`),
              isText: true,
            },
            {
              key: "updateTime",
              name: this.$t(`${this.i18nPrefix}updateTime`),
            },
          ];
          const other_case2 = [
            {
              key: "CampaignLuckyDraw",
              name: "",
              width: 80,
            },
            {
              key: "name",
              name: this.$t(`${this.i18nPrefix}campaignName`),
            },
            {
              key: "date",
              name: this.$t(`${this.i18nPrefix}date`),
            },
            {
              key: "status",
              name: this.$t("default.status"),
            },
            {
              key: "updateUser",
              name: this.$t(`${this.i18nPrefix}updateUser`),
              isText: true,
            },
            {
              key: "updateTime",
              name: this.$t(`${this.i18nPrefix}updateTime`),
            },
          ];
          return this.getUserinfo.koreanStyleInterface ? kr_case2 : other_case2;
        case 3:
          return [
            {
              key: "RebateList",
              name: "",
              width: 80,
            },
            {
              key: "memberAccount",
              name: this.$t("default.member"),
              isText: true,
            },
            {
              key: "createTime",
              name: this.$t(`${this.i18nPrefix}createTime`),
            },
            {
              key: "discountAuditStatus",
              name: this.$t("default.status"),
            },
            {
              key: "settleCycle",
              name: this.$t(`${this.i18nPrefix}settleCycle`),
            },
            {
              key: "bonusAmount",
              name: this.$t("default.amount"),
            },
            {
              key: "systemRecoveryAmount",
              name: this.$t(`${this.i18nPrefix}systemRecoveryAmount`),
            },
            {
              key: "remark",
              name: this.$t("default.remark"),
            },
          ];
        case 4:
          return [
            {
              key: "parentAccount",
              name: this.$t(`${this.i18nPrefix}parentAccount`),
              isText: true,
            },
            {
              key: "memberAccount",
              name: this.$t("default.member"),
              isText: true,
            },
            {
              key: "createTime",
              name: this.$t(`${this.i18nPrefix}createTime`),
            },
            {
              key: "selfValidBetAmount",
              name: this.$t(`${this.i18nPrefix}selfValidBetAmount`),
            },
            {
              key: "childrenValidBetAmount",
              name: this.$t(`${this.i18nPrefix}childrenValidBetAmount`),
            },
            {
              key: "rebateAmount",
              name: this.$t(`${this.i18nPrefix}rebateAmount`),
            },
          ];
      }
    },
    bookmarkColumn() {
      return [
        {
          id: 1,
          name: this.$t(`${this.i18nPrefix}template`),
        },
        {
          id: 2,
          name: this.$t("default.search"),
        },
        {
          id: 3,
          name: this.$t(`${this.i18nPrefix}issue`),
        },
        {
          id: 4,
          name: this.$t(`${this.i18nPrefix}memberBettingList`),
        },
      ];
    },
  },
  async created() {
    await selectMenu().then((res) => {
      this.auditMethod = res.data.auditMethod.map((data) => {
        return { id: data.id, name: "AuditMethod." + data.name };
      });
      this.settleCycle = res.data.settleCycle.map((data) => {
        return { id: data.id, name: "SettleCycle." + data.name };
      });
      this.calculationMethod = res.data.calculationMethod.map((data) => {
        return { id: data.id, name: "CalculationMethod." + data.name };
      });
      this.campaignStatus = res.data.campaignStatus.map((data) => {
        return { id: data.id, name: "CampaignStatus." + data.name };
      });
    });

    await getMemberReferralList({
      status: 0,
    }).then((res) => {
      this.periodList = res.data.map((d) => {
        return {
          id: d.id,
          name: `${d.id} - ${d.startTime} - ${d.endTime}`,
        };
      });
      this.searchForm.campaignListId = this.periodList[0]?.id;
    });

    if (this.$route.query?.bookmarkNum) {
      this.bookmarkNum = this.$route.query?.bookmarkNum;
      this.memberAccount = this.$route.query?.memberAccount;
    }
    await this.search();
  },
  watch: {
    startTime(val) {
      this.searchForm.campaignListId =
        val !== undefined ? undefined : this.searchForm.campaignListId;
    },
    endTime(val) {
      this.searchForm.campaignListId =
        val !== undefined ? undefined : this.searchForm.campaignListId;
    },
    "searchForm.campaignListId"(val) {
      this.startTime = val !== undefined ? undefined : this.startTime;
      this.endTime = val !== undefined ? undefined : this.endTime;
      // if (this.bookmarkNum == 4) {
      getSettleCycle({ campaignListId: val }).then(res => {
        this.settleCycleList = res.data?.list;
        this.searchForm.settleCycleId = this.settleCycleList[0]?.id ?? 0;
      })
      // }
    },
  },
  methods: {
    async search(page = false) {
      this.closeDialog();
      this.searchForm.page.take = this.take * 1;
      this.searchForm.page.skip = this.take * (page ? page - 1 : 0);
      switch (this.bookmarkNum) {
        case 1:
          return getCampaignSetting({
            discountType: 36,
            discountCategory: 5,
          }).then((res) => {
            this.allData = res.data;
          });
        case 2:
          return getMemberReferralList({
            status: 0,
          }).then((res) => {
            this.allData = res.data;
          });
        case 3:
          this.searchForm.startTime = this.startTime
            ? joinT(
              this.startTime + this.GLOBAL.startHour + this.GLOBAL.timeZone
            )
            : undefined;
          this.searchForm.endTime = this.endTime
            ? joinT(this.endTime + this.GLOBAL.endHour + this.GLOBAL.timeZone)
            : undefined;
          await getRebateList(this.searchForm).then((res) => {
            this.allData = res.data.list;
            this.page = res.data.page;
          });
          return;
        case 4:
          this.searchForm.startTime = this.startTime
            ? joinT(
              this.startTime + this.GLOBAL.startHour + this.GLOBAL.timeZone
            )
            : undefined;
          this.searchForm.endTime = this.endTime
            ? joinT(this.endTime + this.GLOBAL.endHour + this.GLOBAL.timeZone)
            : undefined;
          return getMemberBettingList(this.searchForm).then((res) => {
            this.allData = res.data.list;
            this.page = res.data.page;
          });
      }
    },
    // 發布
    async release(data, status) {
      data.status = status;
      data.startTime = joinT(data.startTime + this.GLOBAL.timeZone);
      data.endTime = joinT(data.endTime + this.GLOBAL.timeZone);

      await updateMemberReferral(data).then((res) => {
        if (res) this.search();
      });
    },
    // 分頁
    bookmark(data) {
      this.allData = undefined;
      this.searchForm.campaignListId =
        data == 4 ? this.periodList[0]?.id : undefined;
      this.bookmarkNum = data;
      this.search();
    },
    // 刪除範本
    handleDeleteSetting(id) {
      this.alertMsg = this.$t("default.delMsg");
      this.deleteData = { id };
    },
    handleDeleteLuckyDraw(data) {
      this.alertMsg = this.$t(`${this.i18nPrefix}alertMsg`);
      this.deleteData = data;
    },
    deleteFunction() {
      this.alertMsg = "";
      if (this.bookmarkNum == 1) {
        deleteCampaignSetting(this.deleteData).then((res) => {
          if (res) this.search();
        });
      } else {
        this.release(this.deleteData, 3);
      }
    },
    audit(data) {
      auditRebateList(data).then((res) => {
        if (res) this.search();
      });
    },
    toggleSlide: toggleStyle,
    async toggleFrontEnable({ id, frontSwitchEnable }) {
      try {
        await updateFrontSwitch({ id, switch: !frontSwitchEnable });
        this.search();
      } catch (e) {
        console.log(e);
      }
    }
  },
};
</script>
