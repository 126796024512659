<template>
  <div class="confirm-mask" @mousedown.self="$emit('toggle')" v-show="toogle">
    <div class="confirm-container">
      <main>
        <label>
          {{ $t("default.enterPlz") + " " + $t("default.exportReportPassword") }}
          <input type="text" v-model="password">
        </label>
        <div class="advance-block">
          <button class="normal-btn" @click="download(password)" :disabled="!password">{{ $t("default.confirm") }}</button>
          <button class="delete-btn" @click="$emit('toggle')">{{ $t("default.cancel") }}</button>
        </div>
      </main>
    </div>
  </div>
</template>

<script>

export default {
  name: "Download",
  props: {
    downloadApi: {
      type: Function,
      required: true
    },
    data: {
      type: Object,
      required: true
    },
    fileName: {
      type: String,
    },
    toogle: {
      type: Boolean,
    }
  },
  data() {
    return {
      password: undefined,
    };
  },
  created() {
    if (!this.toogle) this.download();
  },
  methods: {
    download(pwd = null) {
      this.data.exportReportPassword = pwd
      this.downloadApi(this.data).then(res => {
        if (!res) return
        var FILE = window.URL.createObjectURL(res);
        var docUrl = document.createElement('a');
        docUrl.href = FILE;
        docUrl.setAttribute('download', this.fileName);
        document.body.appendChild(docUrl);
        docUrl.click();
      });
      this.$emit('toggle');
    }
  }
};
</script>

<style lang="scss" scoped>
.confirm-mask {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.384);
  z-index: 999;

  .confirm-container {
    min-width: 30%;
    max-width: 80%;
    background-color: #ffffff;
    border: 2px solid rgba(151, 151, 151, 0.562);
    border-radius: 15px;
    padding: 10px 20px;

    main {
      display: flex;
      flex-direction: column;
      align-items: center;

      label {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 0 5px;
      }
    }

    .advance-block {
      display: flex;
      justify-content: center;
    }
  }
}
</style>