<script>
import tw from "@/lang/tw.json";
import en from "@/lang/en.json";
import th from "@/lang/th.json";
import kr from "@/lang/kr.json";
import jp from "@/lang/jp.json";
import i18n from "@/lang/lang";
import store from "@/store";

export default {
  startHour: "T00:00:00",
  endHour: "T23:59:59",
  startMinute: ":00:00",
  endMinute: ":59:59",
  startSecond: ":00",
  endSecond: ":59",
  timeZone: store.getters.timeZone,
  SEOBlogCategoryType: {
    blog: 1,
    gameBlog: 2,
    blogActivity: 3
  },
  CKEditorUrl: "https://cdn.ckeditor.com/4.22.1/full/ckeditor.js",
  langData: {
    tw,
    en,
    th,
    kr,
    jp
  },
  langes: [
    {
      key: "tw",
      value: "中文"
    },
    {
      key: "en",
      value: "English"
    },
    {
      key: "th",
      value: "ไทย"
    },
    {
      key: "kr",
      value: "한국어"
    },
    {
      key: "jp",
      value: "日本語"
    }
  ],
  settlement: [
    {
      id: 1,
      name: "settlement.daily"
    },
    {
      id: 2,
      name: "settlement.weekly"
    },
    {
      id: 3,
      name: "settlement.monthly"
    }
  ],
  nameWithLang({ name, account }) {
    return i18n.t(name) + "(" + i18n.t(account) + ")";
  },
  i18nCheckName(obj) {
    return i18n.t(obj?.name);
  },
  ontSeason(date) {
    const today = new Date();
    return date < today.getTime() - 94 * 24 * 3600 * 1000 || date > today;
  },
  getNow() {
    const utc = parseInt(store.getters.timeZone.match(/([-+]?\d+)/)[0], 10); // 取得時區時間
    const date = new Date();
    // 取得本地時區偏移值（分鐘為單位）
    const timezoneOffset = date.getTimezoneOffset();
    // 設定目標時區偏移值（分鐘為單位）
    const targetTimezoneOffset = -(60 * utc);
    // 計算本地時區與目標時區的差值（毫秒為單位）
    const timezoneDifference = (targetTimezoneOffset - timezoneOffset) * 60 * 1000;
    const utcDate = new Date(date.getTime() - timezoneDifference);
    return utcDate;
  },
  checknull(value) {
    if (value == "") {
      return 0;
    }
    return value
  },
  checkValue(value, decimal = 2, min = 0, max = 100) {
    if (value < min) return min
    if (value > max) return max

    let valString = value.toString()
    // 限制最多只能输入两位小数
    if (valString.indexOf('.') > -1 && valString.split('.')[1].length > decimal) {
      return parseFloat(valString.substring(0, valString.length - 1));
    }else if (!decimal) {
      return value.replace(/^(0+)|[^\d]+/g,'');
    }

    return value;
  }
};
</script>