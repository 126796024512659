<template>
  <div>
    <div class="container-view">
      <div class="header">
        <h4>{{ $t('Permission.' + $route.meta.permissionName) }}</h4>
      </div>
      <div class="main">
        <div class="input-block">
          <label>
            {{ $t("default.startTime") }}
            <date-picker v-model="startTime" format="YYYY-MM-DD HH:mm" value-type="format" type="datetime"></date-picker>
          </label>
          <label>
            {{ $t("default.endTime") }}
            <date-picker v-model="endTime" format="YYYY-MM-DD HH:mm" value-type="format" type="datetime"></date-picker>
          </label>
          <label>
            {{ $t("default.status") }}
            <select v-model.number="searchForm.status">
              <option :value="0 * 1">{{ $t("default.all") }}</option>
              <option :value="1 * 1">{{ $t("default.notProcessed") }}</option>
              <option :value="2 * 1">{{ $t("default.processed") }}</option>
            </select>
          </label>
        </div>
        <div class="advance-block">
          <button class="search-pc-btn main-style-btn px-5" @click="search()">{{ $t("default.search") }}</button>
        </div>
      </div>
    </div>

    <div class="container-view">
      <div class="table-block">
        <table>
          <thead>
            <tr>
              <th>{{ $t("default.sort") }}</th>
              <th>{{ $t("default.status") }}</th>
              <th>{{ $t("default.member") }}</th>
              <th>{{ $t("member.member-management.phone") }}</th>
              <th>{{ $t("customService.customServiceMessageTemplate.content") }}</th>
              <th>{{ $t("default.createTime") }}</th>
              <th>{{ $t("default.processAccount") }}</th>
              <th>{{ $t("default.processTime") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="!allData || allData.length === 0">
              <td colspan="999">{{ $t("default.noData") }}</td>
            </tr>
            <tr v-for="list of allData">
              <td>{{ list.id }}</td>
              <td>
                {{ list.status == 1 ? '' : $t("default.processed") }}
                <button class="table-btn main-style-btn" @click="updateQuestioning(list.id)" v-if="list.status == 1"
                  :disabled="!getUserinfo.permissions.includes(200301)">{{ $t("default.notProcessed") }}
                </button>
              </td>
              <td>{{ list.name }}</td>
              <td>{{ list.phoneNumber }}</td>
              <td><button class="table-btn main-style-btn"
                  @click="handleDialog({ message: list.content }, 'message')">
                  {{ $t("customService.customServiceMessageTemplate.content")  }}
                </button></td>
              <td>{{ list.createTime }}</td>
              <td>{{ list.updateUser }}</td>
              <td>{{ list.updateTime }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <serverPagination v-if="allData" :page="page" @ServerPageUtils="search" @changeTake="changeTake" />
    </div>
    <transition name="fade" mode="out-in">
      <component :is="currDialog" v-bind="dialogData" @toggle="currDialog = undefined"></component>
    </transition>
  </div>
</template>

<script>
import { questioningList, updateQuestioning } from "@/api/customService/guestQuestioning";
import serverPagination from "@/layout/components/serverPagination";
import handleServerPageUtils from "@/layout/mixins/handleServerPageUtils";
import { searchModel } from "./model";
import { mapGetters } from "vuex";
import { joinT } from "@/utils/transDateUtils";
import handleDialog from "@/layout/mixins/handleDialog";
import message from "./dialog/message.vue";

export default {
  name: "GuestQuestioning",
  data() {
    return {
      searchForm: _.cloneDeep(searchModel),
      startTime: undefined,
      endTime: undefined,
      agentData: [],
      i18nPrefix: "customService.guestQuestioning."
    };
  },
  components: {
    message,
    serverPagination
  },
  mixins: [handleServerPageUtils, handleDialog],
  computed: {
    ...mapGetters([
      "getUserinfo",
    ]),
  },
  created() {
    this.search();
  },
  methods: {
    search(page = false) {
      this.searchForm.startTime = this.startTime
        ? joinT(
          this.startTime +
          this.GLOBAL.startSecond +
          this.GLOBAL.timeZone
        )
        : undefined;
      this.searchForm.endTime = this.endTime
        ? joinT(
          this.endTime + this.GLOBAL.endSecond + this.GLOBAL.timeZone
        )
        : undefined;

      this.searchForm.page.take = this.take * 1;
      this.searchForm.page.skip = this.take * (page ? page - 1 : 0);

      questioningList(this.searchForm).then(res => {
        this.allData = res.data.list;
        this.page = res.data.page;
      });
    },
    updateQuestioning(id) {
      updateQuestioning({ id }).then(res => {
        if (res) this.search();
      });
    },
  }
};
</script>

<style lang="scss" scoped></style>