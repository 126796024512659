var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "dialog-mask",
      on: {
        mousedown: function ($event) {
          if ($event.target !== $event.currentTarget) return null
          return _vm.$emit("toggle")
        },
      },
    },
    [
      _c("div", { staticClass: "dialog-container" }, [
        _c("div", { staticClass: "dialog-block" }, [
          _c("div", { staticClass: "header" }, [
            _c("h4", [_vm._v(_vm._s(_vm.$t("title.agent-info")))]),
            _c(
              "button",
              {
                staticClass: "normal-btn",
                attrs: {
                  disabled:
                    !_vm.agentInfo.subDomain || !!_vm.agentInfo.promotionLink,
                },
                on: { click: _vm.createPromotionLink },
              },
              [
                _vm._v(
                  _vm._s(
                    _vm.$t("default.create") +
                      " " +
                      _vm.$t(`${_vm.i18nPrefix}promotionLink`)
                  )
                ),
              ]
            ),
          ]),
          _c("div", { staticClass: "divider" }),
          _c("main", [
            _c("div", { staticClass: "input-block" }, [
              !_vm.getUserinfo.koreanStyleInterface
                ? _c(
                    "div",
                    { staticClass: "domain" },
                    [
                      _c(
                        "div",
                        { staticClass: "ml-2" },
                        [
                          _c("font-awesome-icon", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.agentInfo.subDomain,
                                expression: "agentInfo.subDomain",
                              },
                            ],
                            staticClass: "copyBtn",
                            attrs: { icon: ["fas", "copy"] },
                            on: {
                              click: function ($event) {
                                return _vm.copyDomain(
                                  "https://" + _vm.agentInfo.domain
                                )
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(
                        " " + _vm._s("https://" + _vm.agentInfo.domain) + " "
                      ),
                      _c("font-awesome-icon", {
                        attrs: { icon: ["fas", "download"] },
                        on: {
                          click: function ($event) {
                            return _vm.downloadQRCode(_vm.agentInfo.account)
                          },
                        },
                      }),
                      _c("qrcode", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: false,
                            expression: "false",
                          },
                        ],
                        attrs: {
                          background: "#fff",
                          cls: "qrcode" + _vm.agentInfo.account,
                          value: "https://" + _vm.agentInfo.domain,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.getUserinfo.koreanStyleInterface
                ? _c(
                    "div",
                    { staticClass: "domain mt-2" },
                    [
                      _c(
                        "div",
                        { staticClass: "ml-2" },
                        [
                          _c("font-awesome-icon", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.agentInfo.subDomain,
                                expression: "agentInfo.subDomain",
                              },
                            ],
                            staticClass: "copyBtn",
                            attrs: { icon: ["fas", "copy"] },
                            on: {
                              click: function ($event) {
                                return _vm.copyDomain(
                                  "https://" +
                                    _vm.agentDamain +
                                    "?code=" +
                                    _vm.agentInfo.subDomain
                                )
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(
                        " " +
                          _vm._s(
                            "https://" +
                              _vm.agentDamain +
                              "?code=" +
                              _vm.agentInfo.subDomain
                          ) +
                          " "
                      ),
                      _c("font-awesome-icon", {
                        attrs: { icon: ["fas", "download"] },
                        on: {
                          click: function ($event) {
                            return _vm.downloadQRCode(_vm.agentInfo.subDomain)
                          },
                        },
                      }),
                      _c("qrcode", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: false,
                            expression: "false",
                          },
                        ],
                        attrs: {
                          background: "#fff",
                          cls: "qrcode" + _vm.agentInfo.subDomain,
                          value:
                            "https://" +
                            _vm.agentDamain +
                            "?code=" +
                            _vm.agentInfo.subDomain,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.getUserinfo.koreanStyleInterface
                ? _c("div", { staticClass: "domain mt-2" }, [
                    _vm._v(
                      " " + _vm._s("?code=" + _vm.agentInfo.subDomain) + " "
                    ),
                    _c(
                      "div",
                      { staticClass: "ml-2" },
                      [
                        _c("font-awesome-icon", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.agentInfo.subDomain,
                              expression: "agentInfo.subDomain",
                            },
                          ],
                          staticClass: "copyBtn",
                          attrs: { icon: ["fas", "copy"] },
                          on: {
                            click: function ($event) {
                              return _vm.copyDomain(
                                "?code=" + _vm.agentInfo.subDomain
                              )
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _c("label", [
                _vm._v(" " + _vm._s(_vm.$t(`${_vm.i18nPrefix}name`)) + " "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.agentInfo.name,
                      expression: "agentInfo.name",
                    },
                  ],
                  attrs: { type: "text", disabled: "" },
                  domProps: { value: _vm.agentInfo.name },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.agentInfo, "name", $event.target.value)
                    },
                  },
                }),
              ]),
              _c("label", [
                _vm._v(" " + _vm._s(_vm.$t(`${_vm.i18nPrefix}account`)) + " "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.agentInfo.account,
                      expression: "agentInfo.account",
                    },
                  ],
                  attrs: { type: "text", disabled: "" },
                  domProps: { value: _vm.agentInfo.account },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.agentInfo, "account", $event.target.value)
                    },
                  },
                }),
              ]),
              _c("label", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        `${_vm.i18nPrefix}dialog.CommissionCalculationCycle`
                      )
                    ) +
                    " "
                ),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.agentInfo.commissionCalculationCycle,
                        expression: "agentInfo.commissionCalculationCycle",
                      },
                    ],
                    attrs: { disabled: "" },
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.agentInfo,
                          "commissionCalculationCycle",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                    },
                  },
                  _vm._l(_vm.GLOBAL.settlement, function (list) {
                    return _c(
                      "option",
                      { key: list.id, domProps: { value: list.id * 1 } },
                      [_vm._v(_vm._s(_vm.$t(list.name)))]
                    )
                  }),
                  0
                ),
              ]),
              _c("label", [
                _vm._v(" " + _vm._s(_vm.$t(`${_vm.i18nPrefix}status`)) + " "),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.agentInfo.status,
                        expression: "agentInfo.status",
                      },
                    ],
                    attrs: { disabled: "" },
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.agentInfo,
                          "status",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                    },
                  },
                  _vm._l(_vm.getStatusList, function (list) {
                    return _c(
                      "option",
                      { key: list.id, domProps: { value: list.id } },
                      [_vm._v(_vm._s(_vm.$t(list.name)))]
                    )
                  }),
                  0
                ),
              ]),
              _c("label", [
                _vm._v(
                  " " + _vm._s(_vm.$t(`${_vm.i18nPrefix}promotionLink`)) + " "
                ),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.agentInfo.promotionLink,
                      expression: "agentInfo.promotionLink",
                    },
                  ],
                  attrs: { type: "text", disabled: "" },
                  domProps: { value: _vm.agentInfo.promotionLink },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.agentInfo,
                        "promotionLink",
                        $event.target.value
                      )
                    },
                  },
                }),
              ]),
              _c("label", [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t(`${_vm.i18nPrefix}discountCommissionRate`)) +
                    " "
                ),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.agentInfo.discountCommissionRate,
                      expression: "agentInfo.discountCommissionRate",
                    },
                  ],
                  attrs: { type: "text", disabled: "" },
                  domProps: { value: _vm.agentInfo.discountCommissionRate },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.agentInfo,
                        "discountCommissionRate",
                        $event.target.value
                      )
                    },
                  },
                }),
              ]),
              _c("div", { staticClass: "input-block" }, [
                _c(
                  "div",
                  { staticClass: "settingList" },
                  [
                    _c("label", [
                      _c("h2", [
                        _vm._v(
                          _vm._s(_vm.$t(`${_vm.i18nPrefix}dialog.rebate`))
                        ),
                      ]),
                    ]),
                    _vm._l(_vm.getGamesCategorys, function (list) {
                      return _c("label", { key: list.id }, [
                        _vm._v(" " + _vm._s(_vm.$t(list.name)) + " % "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.agentInfo.rebateSettingList.find(
                                (data) => data.gamesCategoryId === list.id
                              ).rate,
                              expression:
                                "agentInfo.rebateSettingList.find(data => data.gamesCategoryId === list.id).rate",
                            },
                          ],
                          attrs: { type: "text", disabled: "" },
                          domProps: {
                            value: _vm.agentInfo.rebateSettingList.find(
                              (data) => data.gamesCategoryId === list.id
                            ).rate,
                          },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.agentInfo.rebateSettingList.find(
                                  (data) => data.gamesCategoryId === list.id
                                ),
                                "rate",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ])
                    }),
                  ],
                  2
                ),
                _c(
                  "div",
                  { staticClass: "settingList" },
                  [
                    _c("label", [
                      _c("h2", [
                        _vm._v(
                          _vm._s(_vm.$t(`${_vm.i18nPrefix}dialog.commission`))
                        ),
                      ]),
                    ]),
                    _vm._l(_vm.getGamesCategorys, function (list) {
                      return _c("label", { key: list.id }, [
                        _vm._v(" " + _vm._s(_vm.$t(list.name)) + " % "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.agentInfo.commissionSettingList.find(
                                (data) => data.gamesCategoryId === list.id
                              ).rate,
                              expression:
                                "agentInfo.commissionSettingList.find(data => data.gamesCategoryId === list.id).rate",
                            },
                          ],
                          attrs: { type: "text", disabled: "" },
                          domProps: {
                            value: _vm.agentInfo.commissionSettingList.find(
                              (data) => data.gamesCategoryId === list.id
                            ).rate,
                          },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.agentInfo.commissionSettingList.find(
                                  (data) => data.gamesCategoryId === list.id
                                ),
                                "rate",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ])
                    }),
                  ],
                  2
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }