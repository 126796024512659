var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "dialog-mask",
      on: {
        mousedown: function ($event) {
          if ($event.target !== $event.currentTarget) return null
          return _vm.$emit("toggle")
        },
      },
    },
    [
      _c("div", { staticClass: "dialog-container" }, [
        _c("div", { staticClass: "dialog-block" }, [
          _c("div", { staticClass: "header" }, [
            _c("h4", [_vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}sendMail`)))]),
          ]),
          _c("div", { staticClass: "divider" }),
          _c("main", [
            _c("div", { staticClass: "input-block" }, [
              _c("div", { staticClass: "messageInfo" }, [
                _c("div", [
                  _vm._v(
                    " " + _vm._s(_vm.$t(`${_vm.i18nPrefix}messageType`)) + " "
                  ),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.messageType,
                          expression: "messageType",
                        },
                      ],
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.messageType = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        },
                      },
                    },
                    _vm._l(_vm.messageTypeList, function (list) {
                      return _c(
                        "option",
                        { key: list.id, domProps: { value: list.id } },
                        [_vm._v(" " + _vm._s(_vm.$t(list.name)) + " ")]
                      )
                    }),
                    0
                  ),
                ]),
                _c("label", [
                  _vm._v(" " + _vm._s(_vm.$t("default.member")) + " "),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.memberModel.selectMembers.type,
                          expression: "memberModel.selectMembers.type",
                        },
                      ],
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.memberModel.selectMembers,
                            "type",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                      },
                    },
                    _vm._l(_vm.member, function (list) {
                      return _c(
                        "option",
                        { key: list.id, domProps: { value: list.id } },
                        [_vm._v(" " + _vm._s(_vm.$t(list.name)) + " ")]
                      )
                    }),
                    0
                  ),
                ]),
                _c("label", [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.memberModel.selectMembers.type === 2,
                          expression: "memberModel.selectMembers.type === 2",
                        },
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.memberModel.selectMembers.agentid,
                          expression: "memberModel.selectMembers.agentid",
                        },
                      ],
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.memberModel.selectMembers,
                            "agentid",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                      },
                    },
                    _vm._l(_vm.agentList, function (list) {
                      return _c(
                        "option",
                        { key: list.id, domProps: { value: list.id } },
                        [_vm._v(" " + _vm._s(list.account) + " ")]
                      )
                    }),
                    0
                  ),
                  _c("textarea", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.memberModel.selectMembers.type === 3,
                        expression: "memberModel.selectMembers.type === 3",
                      },
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.members,
                        expression: "members",
                      },
                    ],
                    attrs: {
                      rows: "5",
                      placeholder: _vm.$t(
                        `${_vm.i18nPrefix}dialog.placeholder`
                      ),
                    },
                    domProps: { value: _vm.members },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.members = $event.target.value
                      },
                    },
                  }),
                ]),
              ]),
              _c(
                "div",
                {
                  staticClass: "divider-v2-area",
                  staticStyle: { display: "block" },
                },
                [
                  _c(
                    "div",
                    { staticClass: "divider-v2-text divider-v2-left" },
                    [_vm._v(_vm._s(_vm.$t("default.dispatchStep")))]
                  ),
                  _c("div", { staticClass: "table-block w-100" }, [
                    _c("div", { staticClass: "add-th" }, [
                      _c(
                        "button",
                        {
                          staticClass: "add-btn",
                          staticStyle: {
                            "background-color": "green",
                            margin: "5px",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.addData("+")
                            },
                          },
                        },
                        [_c("span", [_vm._v("+")])]
                      ),
                      _c(
                        "button",
                        {
                          staticClass: "add-btn",
                          on: {
                            click: function ($event) {
                              return _vm.addData("-")
                            },
                          },
                        },
                        [_c("span", [_vm._v("-")])]
                      ),
                    ]),
                    _c("table", [
                      _c("thead", [
                        _c("tr", [
                          _c("th"),
                          _c("th", [
                            _vm._v(
                              _vm._s(
                                _vm.$t("campaign.luckydraw.dialog.prizeType")
                              )
                            ),
                          ]),
                          _c("th", [
                            _vm._v(_vm._s(_vm.$t("default.quantity"))),
                          ]),
                          _c("th", [
                            _vm._v(_vm._s(_vm.$t("default.bettingturnover"))),
                          ]),
                        ]),
                      ]),
                      _c(
                        "tbody",
                        _vm._l(
                          _vm.memberModel.attachments,
                          function (list, index) {
                            return _c("tr", { key: index }, [
                              _c("td", [_vm._v(_vm._s(index + 1))]),
                              _c("td", [
                                _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: list.attachmentType,
                                        expression: "list.attachmentType",
                                      },
                                    ],
                                    on: {
                                      change: function ($event) {
                                        var $$selectedVal =
                                          Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function (o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function (o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                        _vm.$set(
                                          list,
                                          "attachmentType",
                                          $event.target.multiple
                                            ? $$selectedVal
                                            : $$selectedVal[0]
                                        )
                                      },
                                    },
                                  },
                                  _vm._l(_vm.attachmentType, function (list) {
                                    return _c(
                                      "option",
                                      {
                                        key: list.id,
                                        domProps: { value: list.id },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "AttachmentType." + list.name
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    )
                                  }),
                                  0
                                ),
                              ]),
                              _c("td", [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model.number",
                                      value: list.quantity,
                                      expression: "list.quantity",
                                      modifiers: { number: true },
                                    },
                                  ],
                                  attrs: { type: "number", min: "0" },
                                  domProps: { value: list.quantity },
                                  on: {
                                    keyup: function ($event) {
                                      list.quantity = _vm.GLOBAL.checknull(
                                        list.quantity
                                      )
                                    },
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        list,
                                        "quantity",
                                        _vm._n($event.target.value)
                                      )
                                    },
                                    blur: function ($event) {
                                      return _vm.$forceUpdate()
                                    },
                                  },
                                }),
                              ]),
                              _c("td", [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model.number",
                                      value: list.bettingturnover,
                                      expression: "list.bettingturnover",
                                      modifiers: { number: true },
                                    },
                                  ],
                                  attrs: {
                                    type: "number",
                                    min: "0",
                                    required: "required",
                                  },
                                  domProps: { value: list.bettingturnover },
                                  on: {
                                    keyup: function ($event) {
                                      list.bettingturnover =
                                        _vm.GLOBAL.checknull(
                                          list.bettingturnover
                                        )
                                    },
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        list,
                                        "bettingturnover",
                                        _vm._n($event.target.value)
                                      )
                                    },
                                    blur: function ($event) {
                                      return _vm.$forceUpdate()
                                    },
                                  },
                                }),
                              ]),
                            ])
                          }
                        ),
                        0
                      ),
                    ]),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.messageType !== 2,
                      expression: "messageType !== 2",
                    },
                  ],
                  staticClass: "message",
                },
                [
                  _c("label", [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t(`${_vm.i18nPrefix}dialog.mailTitle`)) +
                        " "
                    ),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.memberModel.title,
                          expression: "memberModel.title",
                        },
                      ],
                      attrs: { type: "text" },
                      domProps: { value: _vm.memberModel.title },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.memberModel,
                            "title",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c("label", { staticClass: "remark" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t(`${_vm.i18nPrefix}dialog.mailMessage`)) +
                        " "
                    ),
                    _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.memberModel.message,
                          expression: "memberModel.message",
                        },
                      ],
                      attrs: { rows: "12" },
                      domProps: { value: _vm.memberModel.message },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.memberModel,
                            "message",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.messageType !== 1,
                      expression: "messageType !== 1",
                    },
                  ],
                  staticClass: "message",
                },
                [
                  _c("label", [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t(`${_vm.i18nPrefix}dialog.snsTitle`)) +
                        " "
                    ),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.smsModel.title,
                          expression: "smsModel.title",
                        },
                      ],
                      attrs: { type: "text" },
                      domProps: { value: _vm.smsModel.title },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.smsModel, "title", $event.target.value)
                        },
                      },
                    }),
                  ]),
                  _c("label", { staticClass: "remark" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t(`${_vm.i18nPrefix}dialog.snsMessage`)) +
                        " "
                    ),
                    _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.smsModel.message,
                          expression: "smsModel.message",
                        },
                      ],
                      attrs: { rows: "12" },
                      domProps: { value: _vm.smsModel.message },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.smsModel, "message", $event.target.value)
                        },
                      },
                    }),
                  ]),
                ]
              ),
            ]),
            _c("div", { staticClass: "advance-block" }, [
              _c(
                "button",
                { staticClass: "normal-btn", on: { click: _vm.createMessage } },
                [
                  _vm._v(
                    " " + _vm._s(_vm.$t(`${_vm.i18nPrefix}dialog.submit`)) + " "
                  ),
                ]
              ),
              _c(
                "button",
                {
                  staticClass: "delete-btn",
                  on: {
                    click: function ($event) {
                      return _vm.$emit("toggle")
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("default.cancel")))]
              ),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }