<template>
  <div>
    <div class="container-view">
      <div class="header">
        <h5>{{ $t("Permission." + $route.meta.permissionName) }}</h5>
        <div class="float-right">
          <button class="create-btn main-style-btn" @click="handleDialog(undefined, 'Create')" :disabled="!getUserinfo.permissions.includes(62501)
          ">
            {{ $t("default.create") }}
          </button>
        </div>
      </div>
      <main>
        <div class="table-block">
          <table>
            <thead>
              <tr>
                <th></th>
                <th>{{ $t("default.title") }}</th>
                <th>{{ $t("default.sort") }}</th>
                <th>{{ $t("default.status") }}</th>
                <th>image</th>
                <th>{{ $t("default.createTime") }}</th>
                <th>{{ $t("default.creator") }}</th>
                <th>{{ $t("default.processTime") }}</th>
                <th>{{ $t("default.processAccount") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="list of allData">
                <td>
                  <button class="normal-btn mr-1" @click="handleDialog({ list: list }, 'Edit')"
                    :disabled="!getUserinfo.permissions.includes(62502)">
                    {{ $t("default.edit") }}
                  </button>
                  <button class="delete-btn" @click="handleDelete(list.id)"
                    :disabled="!getUserinfo.permissions.includes(62503)">
                    {{ $t("default.delete") }}
                  </button>
                </td>
                <td>{{ (list.title.length > 10) ? list.title.substring(0, 10) + " ..." : list.title }}</td>
                <td>{{ list.sort }}</td>
                <td>{{ list.status === 1 ? $t("default.active") : $t("default.inactive") }}</td>
                <td class="url-td"><span v-show="list.image" @click="handleDialog({ image: list.image }, 'Photo')"
                    :title="list.image">Image</span></td>
                <td>{{ list.createTime }}</td>
                <td>{{ list.createAccount }}</td>
                <td>{{ list.updateTime }}</td>
                <td>{{ list.updateAccount }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </main>

      <serverPagination v-if="allData" :page="page" @ServerPageUtils="search" @changeTake="changeTake" />

      <component :is="currDialog" v-bind="dialogData" @toggle="currDialog = undefined" @create="create" @edit="edit">
      </component>

      <!-- 確認彈出視窗 -->
      <transition name="fade" mode="out-in">
        <Alert v-if="alertMsg" :message="alertMsg" @toggle="alertMsg = undefined" @doIt="deletePopupAnnouncement" />
      </transition>
    </div>
  </div>
</template>

<script>
import serverPagination from "@/layout/components/serverPagination";
import handleServerPageUtils from "@/layout/mixins/handleServerPageUtils";
import handleDialog from "@/layout/mixins/handleDialog";
import {
  popupAnnouncementList,
  createPopupAnnouncement,
  editPopupAnnouncement,
  deletePopupAnnouncement,
} from "@/api/features/popupAnnouncement";
import Create from "./dialog/create.vue";
import Edit from "./dialog/edit.vue";
import Photo from "@/layout/components/photo";
import Alert from "@/layout/components/Alert";
import Confirm from "@/layout/components/Confirm";
import { mapGetters } from "vuex";

export default {
  name: "PopupPopupAnnouncement",
  data() {
    return {
      searchForm: {
        page: {
          "take": 10,
          "skip": 0
        }
      },
      alertMsg: "",
      deleteData: {},
      i18nPrefix: "features.announcement."
    };
  },
  components: {
    Create,
    Edit,
    Photo,
    Alert,
    Confirm,
    serverPagination,
  },
  mixins: [handleServerPageUtils, handleDialog],
  computed: {
    ...mapGetters(["getUserinfo"]),
  },
  created() {
    this.search();
  },
  methods: {
    search(page = false) {
      this.searchForm.page.take = this.take * 1;
      this.searchForm.page.skip = this.take * (page ? page - 1 : 0);
      popupAnnouncementList(this.searchForm).then((res) => {
        this.allData = res.data.list;
        this.page = res.data.page
        this.closeDialog();
      });
    },
    create(data) {
      createPopupAnnouncement(data).then((res) => {
        if (res) this.search();
      });
    },
    edit(data) {
      data.sortId *= 1;
      editPopupAnnouncement(data).then((res) => {
        if (res) this.search();
      });
    },
    handleDelete(id) {
      this.alertMsg = this.$t("default.delMsg");
      this.deleteData = { id };
    },
    deletePopupAnnouncement() {
      this.alertMsg = undefined;
      deletePopupAnnouncement(this.deleteData).then((res) => {
        if (res) this.search();
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>