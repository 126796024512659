<template>
  <div>
    <div class="container-view">
      <div class="header">
        <h5>{{ $t("title." + $route.meta.title) }}</h5>
        <div class="float-right">
          <button
            class="create-btn main-style-btn"
            @click="handleDialog(undefined, 'Create')"
            :disabled="
              !getUserinfo.permissions.includes(61301)
            "
          >
            {{ $t("default.create") }}
          </button>
        </div>
      </div>
      <main>
        <div class="table-block">
          <table>
            <thead>
              <tr>
                <th></th>
                <th>{{ $t(`${i18nPrefix}status`) }}</th>
                <th>{{ $t(`${i18nPrefix}game`) }}</th>
                <th>{{ $t(`${i18nPrefix}day`) }}</th>
                <th>{{ $t(`${i18nPrefix}timeFrom`) }}</th>
                <th>{{ $t(`${i18nPrefix}timeTo`) }}</th>
                <th>{{ $t(`${i18nPrefix}updateAccount`) }}</th>
                <th>{{ $t(`${i18nPrefix}updateTime`) }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="!tableList || !tableList.length">
                <td colspan="8">{{ $t("default.noData") }}</td>
              </tr>
              <tr v-for="list of tableList" :key="list.id">
                <td>
                  <button
                    class="normal-btn"
                    @click="handleDialog(list, 'Edit')"
                    :disabled="!getUserinfo.permissions.includes(61302)
                    "
                  >
                    {{ $t("default.edit") }}
                  </button>
                  <button
                    class="delete-btn"
                    @click="handleDelete(list)"
                    :disabled="!getUserinfo.permissions.includes(61303)
                    "
                  >
                    {{ $t("default.delete") }}
                  </button>
                </td>
                <td>{{ list.status === 1 ? $t("default.active") : $t("default.inactive") }}</td>
                <td>{{ GLOBAL.i18nCheckName(gameList.find((data) => data.id === list.gamePlatform)) }}</td>
                <td>{{ GLOBAL.i18nCheckName(getWeekList.find((data) => list.dayOfWeek === data.id)) }}</td>
                <td>{{ list.timeFrom }}</td>
                <td>{{ list.timeTo }}</td>
                <td>{{ list.updateAccount }}</td>
                <td>{{ list.updateTime }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </main>

      <Pagination
        v-if="totalCount > 0"
        :totalCount="totalCount"
        @handlePage="handlePage"
      />
    </div>

    <transition name="fade" mode="out-in">
      <component
        :is="currDialog"
        :i18nPrefix="i18nPrefix"
        :gameList="gameList"
        v-bind="dialogData"
        @toggle="currDialog = undefined"
        @create="create"
        @edit="edit"
      >
      </component>
    </transition>

    <transition name="fade" mode="out-in">
      <Confirm
        v-if="getShowConfirm"
        @toggle="TOGGLE_CONFIRM"
        @delete="deleteMaintain"
      />
    </transition>
  </div>
</template>

<script>
import {
  gameMaintainList,
  createGameMaintain,
  editGameMaintain,
  deleteGameMaintain,
  gameList
} from "@/api/features/game-maintain";
import { mapGetters, mapMutations } from "vuex";
import Create from "./dialog/create";
import Edit from "./dialog/edit";
import Confirm from "@/layout/components/Confirm";
import { transDayOfWeek } from "@/utils/transDateUtils";
import Pagination from "@/layout/components/Pagination";
import handlePageUtils from "@/layout/mixins/handlePageUtils";
import handleDialog from "@/layout/mixins/handleDialog";

export default {
  name: "BalanceAdjustment",
  data() {
    return {
      gameList: [],
      deleteData: undefined,
      i18nPrefix: "features.game-maintain."
    };
  },
  components: {
    Create,
    Edit,
    Confirm,
    Pagination,
  },
  mixins: [handlePageUtils, handleDialog],
  computed: {
    ...mapGetters(["getShowConfirm", "getUserinfo", "getWeekList"]),
  },
  created() {
    this.getMaintainList();
    gameList().then((res) => {
      this.gameList = res.data.gamePlatforms
      .map(data => { return { id: data.id , name: "GamePlatform."+data.name}})
    });
  },
  methods: {
    ...mapMutations({
      TOGGLE_CONFIRM: "confirm/TOGGLE_CONFIRM",
      RESET_CONFRIM: "confirm/RESET_CONFIRM",
    }),
    transDayOfWeek: transDayOfWeek,
    getMaintainList() {
      gameMaintainList().then((res) => {
        this.allData = res.data;
        this.RESET_DIALOG();
        this.RESET_CONFRIM();
        this.dialogData = undefined;
      });
    },
    create(data) {
      createGameMaintain(data).then((res) => {
        if(res) this.getMaintainList();
      });
    },
    edit(data) {
      editGameMaintain({
        id: data.id,
        status: data.status,
        gamePlatform: data.gamePlatform,
        dayOfWeek: data.dayOfWeek,
        timeFrom: data.timeFrom,
        timeTo: data.timeTo,
      }).then((res) => {
        if(res) this.getMaintainList();
      });
    },
    handleDelete(data) {
      this.TOGGLE_CONFIRM();
      this.deleteData = data;
    },
    deleteMaintain() {
      deleteGameMaintain({ id: this.deleteData.id })
        .then((res) => {
          this.deleteData = undefined;
          if(res) this.getMaintainList();
        })
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
