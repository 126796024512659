<template>
  <!-- 會員限制行為 -->
  <div class="d-flex form-column align-items-center h-full" style="width: 90%;">
    <div class="table-block-white-v2">
      <table>
        <thead>
          <tr>
            <th colspan="2" style="width: 100%;">{{ $t(`${i18nPrefix}dialog.detail.memberFeature`) }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in list">
            <td>
              {{ $t("MemberFeature." + item.name) }}
            </td>
            <td>
              <div style="display: flex; justify-content: center; align-items: center;">
                <span>{{ $t(`${i18nPrefix}dialog.detail.ban`) }}</span>
                <div class="slide-block" style="margin: 2px 10px;" 
                  @click="item.isEnable = (editChooice !== 'disableFeature') ? item.isEnable : !item.isEnable"
                  :class="{ 'slide-block-on': item.isEnable }">
                  <div class="slide" :class="{ 'slide-on': item.isEnable }">
                    <label style="display: none"></label>
                    <input type="checkbox" v-model="item.isEnable" style="display: none" />
                  </div>
                </div>
                <span>{{ $t(`${i18nPrefix}dialog.detail.allow`) }}</span>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="2" style="width: 100%;">
              <button class="edit-btn" :disabled="editChooice !== undefined" @click="edit('disableFeature')"
                v-show="editChooice !== 'disableFeature'">{{ $t("default.edit") }}
              </button>
              <div v-show="editChooice == 'disableFeature'">
                <button class="normal-btn" @click="confirm('disableFeature')">
                  {{ $t("default.confirm") }}
                </button>
                <button class="delete-btn" @click="cancel('disableFeature')">
                  {{ $t("default.cancel") }}
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { updateFeature } from "@/api/member/member";

export default {
  name: "MemberFeature",
  props: ['list', "memberData", 'chooice', 'i18nPrefix'],
  data() {
    return {
      editChooice: undefined,
      disableFeature: this.list,
    };
  },
  watch: {
    'chooice'(v) {
      this.editChooice = v;
    }
  },
  components: {
  },
  computed: {
  },
  created() {
  },
  methods: {
    edit(k) {
      this.editChooice = k;
      this.disableFeature = this.list;

      this.$emit('editting', k);
    },
    async confirm() {
      let result = false;
      await updateFeature({ memberId: this.memberData.id, disableFeature: this.list }).then(res => {
        if (res) result = true;
      });

      if (result) this.init();
    },
    cancel(k) {
      this.editChooice = undefined;
      this.list = this.disableFeature;

      this.$emit('editting', undefined);
    },
    init() {
      this.editChooice = undefined;

      this.$emit('editting', undefined);
      this.$emit('reload');
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/form.scss";

tr {
  td:first-of-type {
    width: 25%;
  }

  td:nth-of-type(2) {
    width: 50%;
  }

  td:last-of-type {
    width: 25%;
  }
}

.h-full {
  height: 100%;
}
</style>