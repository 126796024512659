import { render, staticRenderFns } from "./welcomeMessage.vue?vue&type=template&id=962b120c&scoped=true"
import script from "./welcomeMessage.vue?vue&type=script&lang=js"
export * from "./welcomeMessage.vue?vue&type=script&lang=js"
import style0 from "./welcomeMessage.vue?vue&type=style&index=0&id=962b120c&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "962b120c",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('962b120c')) {
      api.createRecord('962b120c', component.options)
    } else {
      api.reload('962b120c', component.options)
    }
    module.hot.accept("./welcomeMessage.vue?vue&type=template&id=962b120c&scoped=true", function () {
      api.rerender('962b120c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/features/batchSend/welcomeMessage.vue"
export default component.exports