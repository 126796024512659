<template>
  <div class="dialog-mask" @mousedown.self="$emit('toggle')">
    <div class="dialog-container">
      <div class="dialog-block">
        <div class="header">
          <h4>{{ $t("customService.customServiceMessageTemplate.content") }}</h4>
        </div>
        <main>
          <div class="input-block">
              <label>
                <textarea v-model="message" rows="12"></textarea>
              </label>
            </div>
        </main>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "viewMessage",
  props: ['message'],
};
</script>
<style lang="scss" scoped>
.dialog-container {
  width: 50%;
  label {
    width: 100%!important;
  }
}
</style>