<template>
  <div class="dialog-mask" @mousedown.self="$emit(list.toggle ? 'toggle' : 'detailToggle')">
    <div class="dialog-container">
      <div class="dialog-block">
        <div class="header">
          <h4>{{ $t("default.member") + " 【" + list.account + "】" }}</h4>
        </div>
        <div class="divider"></div>
        <main>
          <div class="input-block">
            <div class="">
              <MemberIdentity :list="detailData.memberIdentity" :chooice="chooice" @editting="editting"
                :i18nPrefix="i18nPrefix" @reload="load">
              </MemberIdentity>
            </div>
            <div class="">
              <MemberMonetary :list="detailData.memberMonetary" :chooice="chooice" :memberData="memberData"
                :totalGameBalance="totalGameBalance" @editting="editting" :i18nPrefix="i18nPrefix" @reload="load">
              </MemberMonetary>
            </div>
            <div class="">
              <MemberRegistry :list="detailData.memberRegistry" :chooice="chooice" :memberData="memberData"
                @editting="editting" :i18nPrefix="i18nPrefix" @reload="load">
              </MemberRegistry>
            </div>
            <div class="">
              <MemberActivity :list="detailData.memberActivity" :chooice="chooice" :memberData="memberData"
                @editting="editting" :i18nPrefix="i18nPrefix" @reload="load">
              </MemberActivity>
            </div>
            <div class="" v-if="getUserinfo.koreanStyleInterface">
              <MemberFeature :list="detailData.memberFeature" :chooice="chooice" :memberData="memberData"
                @editting="editting" :i18nPrefix="i18nPrefix" @reload="load">
              </MemberFeature>
            </div>
            <div class="w-100">
              <div class="divider-v2-area" style="display: block">
                <div class="divider-v2-text divider-v2-left">{{ $t("default.remark") }}</div>
                <div class="textarea-Div">
                  <button class="confirm-btn" @click="addNow()" :disabled="chooice !== 'remark'">
                    {{ $t("default.now") }}
                  </button>
                  <textarea id="myTextarea" rows="5" v-model="remark" :disabled="chooice !== 'remark'"></textarea>
                  <div class="btnDiv">
                    <button class="edit-btn" :disabled="chooice !== undefined" @click="chooice = 'remark'"
                      v-show="chooice !== 'remark'">
                      {{ $t("default.edit") }}
                    </button>

                    <button class="normal-btn" @click="updateRemark()" v-show="chooice == 'remark'">
                      {{ $t("default.confirm") }}
                    </button>
                    <button class="delete-btn" @click="cancel('remark')" v-show="chooice == 'remark'">
                      {{ $t("default.cancel") }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
import { memberDetail, memberGameWalletList, updateRemark } from "@/api/member/member";
import MemberIdentity from "./detail/memberIdentity";
import MemberRegistry from "./detail/memberRegistry";
import MemberMonetary from "./detail/memberMonetary";
import MemberActivity from "./detail/memberActivity";
import MemberFeature from "./detail/memberFeature";
import { mapGetters } from "vuex";

export default {
  name: "Detail",
  props: [
    "list",
  ],
  components: {
    MemberIdentity,
    MemberRegistry,
    MemberMonetary,
    MemberActivity,
    MemberFeature,
  },
  data() {
    return {
      detailData: {
        memberActivity: [],
        memberIdentity: [],
        memberMonetary: [],
        memberRegistry: [],
        memberFeature: []
      },
      remark: undefined,
      chooice: undefined,
      memberData: [],
      totalGameBalance: 0,
      i18nPrefix: "member.member-management."
    };
  },
  created() {
    this.load();
  },
  computed: {
    ...mapGetters(["getUserinfo"])
  },
  methods: {
    async load() {
      await memberDetail({ id: this.list.id }).then(res => {
        this.detailData = res.data;

        this.memberData = {
          ...this.detailData.memberIdentity,
          ...this.detailData.memberRegistry,
          ...this.detailData.memberMonetary,
          ...this.detailData.memberActivity,
        };

        let status = this.detailData?.memberIdentity?.status;
        this.detailData.memberIdentity.status = status == 1 ? true : false;

        let isNew = this.detailData?.memberIdentity?.isNew;
        this.detailData.memberIdentity.isNew = isNew == 1 ? true : false;

        let enableSeamless = this.detailData?.memberMonetary?.enableSeamless;
        this.detailData.memberMonetary.enableSeamless = enableSeamless == 1 ? true : false;

        memberGameWalletList({ MemberId: this.list.id }).then(res => {
          this.totalGameBalance = res.data.totalGameBalance;
        });

        this.remark = this.detailData.remark;
      })
    },
    editting(data) {
      this.chooice = data;
    },
    cancel(k) {
      switch (k) {
        case 'remark':
          this.chooice = undefined;
          this.remark = this.detailData.remark;
          break;
      }
    },
    addNow() {
      var textarea = document.getElementById('myTextarea');
      var now = new Date();
      var timeString = " " + now.getFullYear() + '-' + (now.getMonth() + 1) + '-' + now.getDate() + " " +
        now.getHours() + ':' + now.getMinutes() + ':' + now.getSeconds() + " ";

      var startPos = textarea.selectionStart;
      var endPos = textarea.selectionEnd;

      var textBefore = textarea.value.substring(0, startPos);
      var textAfter = textarea.value.substring(endPos, textarea.value.length);

      this.remark = textBefore + timeString + textAfter;

      var newCursorPos = startPos + timeString.length;
      textarea.setSelectionRange(newCursorPos, newCursorPos);
      textarea.focus();
    },
    updateRemark() {
      this.chooice = 'addNow';
      updateRemark({
        id: this.list.id,
        remark: this.remark
      }).then(res => {
        if (res) {
          this.chooice = undefined;
          this.load();
        }
      });
    }
  },
};
</script>
<style lang="scss" scoped>
.dialog-container {
  width: 90%;

  .input-block {
    display: flex;

    div {
      width: 50%;
      margin: 0 auto;
      padding: 10px 0;
    }
  }
}

.divider-v2-left {
  padding: unset !important;
  transform: translateY(-100%) !important;
  width: 5% !important;
  background-color: #ffffff !important;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.textarea-Div {
  margin-top: 15px !important;
  width: 80% !important;
  display: flex;
  flex-direction: column;
  align-items: center;

  textarea {
    width: 100%;
  }

  .btnDiv {
    width: auto !important;
  }
}

@media screen and (max-width: 768px) {
  .input-block {
    div {
      width: 100% !important;
    }
  }
}
</style>