import request from "@/utils/request";
import { getToken } from "@/utils/auth";

export function popupAnnouncementList(data) {
    return request({
        url: "/PopupAnnouncement/page",
        method: "post",
        headers: { "Authorization": `token ${getToken()}` },
        data
    });
}
export function createPopupAnnouncement(data) {
    return request({
        url: "/PopupAnnouncement/create",
        method: "post",
        headers: { "Authorization": `token ${getToken()}` },
        data
    });
}
export function editPopupAnnouncement(data) {
    return request({
        url: "/PopupAnnouncement/update",
        method: "post",
        headers: { "Authorization": `token ${getToken()}` },
        data
    });
}
export function deletePopupAnnouncement(data) {
    return request({
        url: "/PopupAnnouncement/delete",
        method: "post",
        headers: { "Authorization": `token ${getToken()}` },
        data
    });
}