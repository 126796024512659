var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "dialog-mask",
      on: {
        mousedown: function ($event) {
          if ($event.target !== $event.currentTarget) return null
          return _vm.$emit("toggle")
        },
      },
    },
    [
      _c("div", { staticClass: "dialog-container" }, [
        _c("div", { staticClass: "dialog-block" }, [
          _c("main", [
            _c(
              "div",
              {
                staticClass: "divider-v2-area",
                staticStyle: { display: "block" },
              },
              [
                _c("div", { staticClass: "divider-v2-text divider-v2-left" }, [
                  _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}memberInfo`))),
                ]),
                _c("div", { staticClass: "Basic" }, [
                  _c("div", [
                    _c("label", [
                      _c("h1", [_vm._v(_vm._s(_vm.$t("default.title")))]),
                      _c("p", [_vm._v(_vm._s(_vm.list.title))]),
                    ]),
                    _c("label", [
                      _c("h1", [
                        _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}count`))),
                      ]),
                      _c("p", [_vm._v(_vm._s(_vm.list.count))]),
                    ]),
                  ]),
                  _c("div", [
                    _c("label", [
                      _c("h1", [_vm._v(_vm._s(_vm.$t("default.member")))]),
                      _c("p", [_vm._v(_vm._s(_vm.list.memberAccount))]),
                    ]),
                    _c("label", [
                      _c("h1", [
                        _vm._v(
                          _vm._s(_vm.$t(`${_vm.i18nPrefix}createDateTime`))
                        ),
                      ]),
                      _c("p", [_vm._v(_vm._s(_vm.list.createDateTime))]),
                    ]),
                  ]),
                  _c("div", [
                    _c(
                      "label",
                      {
                        staticStyle: {
                          "justify-content": "space-evenly !important",
                        },
                      },
                      [
                        _c("h1", [
                          _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}agentName`))),
                        ]),
                        _c("p", [_vm._v(_vm._s(_vm.list.agentName))]),
                      ]
                    ),
                    _c(
                      "label",
                      {
                        staticStyle: {
                          "justify-content": "space-evenly !important",
                        },
                      },
                      [
                        _c("h1", [_vm._v(_vm._s(_vm.$t("default.domain")))]),
                        _c("p", [_vm._v(_vm._s(_vm.list.domain))]),
                      ]
                    ),
                    _c(
                      "label",
                      {
                        staticStyle: {
                          "justify-content": "space-evenly !important",
                        },
                      },
                      [
                        _c("h1", [
                          _vm._v(
                            _vm._s(_vm.$t(`${_vm.i18nPrefix}memberCreateTime`))
                          ),
                        ]),
                        _c("p", [_vm._v(_vm._s(_vm.list.memberCreateTime))]),
                      ]
                    ),
                  ]),
                  _c("div", { staticClass: "content" }, [
                    _c("label", { staticClass: "content-label" }, [
                      _c("h1", [
                        _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}content`))),
                      ]),
                      _c("p", [_vm._v(" " + _vm._s(_vm.list.content) + " ")]),
                    ]),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "gameResultDiv" },
                  _vm._l(_vm.gameResult, function (list) {
                    return _c(
                      "table",
                      { staticClass: "gameResultTable" },
                      [
                        list.gamesCategory == 1
                          ? [
                              _c("thead", [
                                _c("tr", [
                                  _c("th", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(`${_vm.i18nPrefix}LeagueName`)
                                      )
                                    ),
                                  ]),
                                  _c("th", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(`${_vm.i18nPrefix}MatchDate`)
                                      )
                                    ),
                                  ]),
                                  _c("th", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(`${_vm.i18nPrefix}HomeTeam`)
                                      )
                                    ),
                                  ]),
                                  _c("th"),
                                  _c("th", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(`${_vm.i18nPrefix}AwayTeam`)
                                      )
                                    ),
                                  ]),
                                  _c("th", [
                                    _vm._v(
                                      _vm._s(_vm.$t(`${_vm.i18nPrefix}Score`))
                                    ),
                                  ]),
                                  _c("th", [
                                    _vm._v(
                                      _vm._s(_vm.$t(`${_vm.i18nPrefix}Result`))
                                    ),
                                  ]),
                                ]),
                              ]),
                              _c(
                                "tbody",
                                [
                                  _vm._l(
                                    list.gameResult.Records,
                                    function (gResult) {
                                      return _c("tr", [
                                        _c("td", [
                                          _vm._v(_vm._s(gResult.LeagueName)),
                                        ]),
                                        _c("td", [
                                          _vm._v(_vm._s(gResult.MatchDate)),
                                        ]),
                                        _c("td", [
                                          _c(
                                            "div",
                                            { staticClass: "gameResult" },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(gResult.HomeTeam?.Name)
                                                ),
                                              ]),
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(gResult.HomeTeam?.Odds)
                                                ),
                                              ]),
                                            ]
                                          ),
                                        ]),
                                        _c("td", [
                                          _vm._v(
                                            _vm._s(
                                              gResult.Draw?.Odds === 0
                                                ? "VS"
                                                : gResult.Draw?.Odds
                                            )
                                          ),
                                        ]),
                                        _c("td", [
                                          _c(
                                            "div",
                                            { staticClass: "gameResult" },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(gResult.AwayTeam?.Name)
                                                ),
                                              ]),
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(gResult.AwayTeam?.Odds)
                                                ),
                                              ]),
                                            ]
                                          ),
                                        ]),
                                        _c("td", [
                                          _vm._v(_vm._s(gResult.Score)),
                                        ]),
                                        _c("td", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "CustomServiceMessage.GameResult." +
                                                  gResult.Result
                                              )
                                            )
                                          ),
                                        ]),
                                      ])
                                    }
                                  ),
                                  _c("tr", [
                                    _c("td", { attrs: { colspan: "2" } }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(`${_vm.i18nPrefix}BetTime`) +
                                            "：" +
                                            list.gameResult.BetTime
                                        )
                                      ),
                                    ]),
                                    _c("td", { attrs: { colspan: "4" } }, [
                                      _c("div", { staticClass: "gameResult" }, [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                `${_vm.i18nPrefix}BetAmount`
                                              ) +
                                                "：" +
                                                list.gameResult.BetAmount
                                            )
                                          ),
                                        ]),
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(`${_vm.i18nPrefix}Odds`) +
                                                "：" +
                                                list.gameResult.Odds
                                            )
                                          ),
                                        ]),
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                `${_vm.i18nPrefix}ExpectedWinnings`
                                              ) +
                                                "：" +
                                                list.gameResult.ExpectedWinnings
                                            )
                                          ),
                                        ]),
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                `${_vm.i18nPrefix}ActualWinnings`
                                              ) +
                                                "：" +
                                                list.gameResult.ActualWinnings
                                            )
                                          ),
                                        ]),
                                      ]),
                                    ]),
                                    _c("td", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "CustomServiceMessage.GameResult." +
                                              list.gameResult.Result
                                          )
                                        )
                                      ),
                                    ]),
                                  ]),
                                ],
                                2
                              ),
                            ]
                          : [
                              _c("thead", [
                                _c("tr", [
                                  _c("th", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(`${_vm.i18nPrefix}GameName`)
                                      )
                                    ),
                                  ]),
                                  _c("th", [
                                    _vm._v(
                                      _vm._s(_vm.$t(`${_vm.i18nPrefix}BetTime`))
                                    ),
                                  ]),
                                  _c("th", [
                                    _vm._v(
                                      _vm._s(_vm.$t(`${_vm.i18nPrefix}Round`))
                                    ),
                                  ]),
                                  _c("th", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(`${_vm.i18nPrefix}BetOption`)
                                      )
                                    ),
                                  ]),
                                  _c("th", [
                                    _vm._v(
                                      _vm._s(_vm.$t(`${_vm.i18nPrefix}Result`))
                                    ),
                                  ]),
                                ]),
                              ]),
                              _c(
                                "tbody",
                                [
                                  _vm._l(
                                    list.gameResult.Records,
                                    function (gResult) {
                                      return _c("tr", [
                                        _c("td", [
                                          _vm._v(_vm._s(gResult.GameName)),
                                        ]),
                                        _c("td", [
                                          _vm._v(_vm._s(gResult.BetTime)),
                                        ]),
                                        _c("td", [
                                          _vm._v(_vm._s(gResult.Round)),
                                        ]),
                                        _c("td", [
                                          _c(
                                            "div",
                                            { staticClass: "gameResult" },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    gResult.BetOption?.Name
                                                  )
                                                ),
                                              ]),
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    gResult.BetOption?.Odds
                                                  )
                                                ),
                                              ]),
                                            ]
                                          ),
                                        ]),
                                        _c("td", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "CustomServiceMessage.GameResult." +
                                                  gResult.Result
                                              )
                                            )
                                          ),
                                        ]),
                                      ])
                                    }
                                  ),
                                  _c("tr", [
                                    _c("td", { attrs: { colspan: "2" } }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(`${_vm.i18nPrefix}DrawTime`) +
                                            "：" +
                                            list.gameResult.DrawTime
                                        )
                                      ),
                                    ]),
                                    _c("td", { attrs: { colspan: "2" } }, [
                                      _c("div", { staticClass: "gameResult" }, [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                `${_vm.i18nPrefix}BetAmount`
                                              ) +
                                                "：" +
                                                list.gameResult.BetAmount
                                            )
                                          ),
                                        ]),
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(`${_vm.i18nPrefix}Odds`) +
                                                "：" +
                                                list.gameResult.Odds
                                            )
                                          ),
                                        ]),
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                `${_vm.i18nPrefix}ExpectedWinnings`
                                              ) +
                                                "：" +
                                                list.gameResult.ExpectedWinnings
                                            )
                                          ),
                                        ]),
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                `${_vm.i18nPrefix}ActualWinnings`
                                              ) +
                                                "：" +
                                                list.gameResult.ActualWinnings
                                            )
                                          ),
                                        ]),
                                      ]),
                                    ]),
                                    _c("td", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "CustomServiceMessage.GameResult." +
                                              list.gameResult.Result
                                          )
                                        )
                                      ),
                                    ]),
                                  ]),
                                ],
                                2
                              ),
                            ],
                      ],
                      2
                    )
                  }),
                  0
                ),
              ]
            ),
            _c(
              "label",
              { staticClass: "w-100" },
              [
                _c("h1", [_vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}reply`)))]),
                _c("ckeditor", {
                  attrs: { "editor-url": this.GLOBAL.CKEditorUrl },
                  model: {
                    value: _vm.replyData,
                    callback: function ($$v) {
                      _vm.replyData = $$v
                    },
                    expression: "replyData",
                  },
                }),
              ],
              1
            ),
            _c("div", { staticClass: "advance-block" }, [
              _c(
                "button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.list.isDelete,
                      expression: "!list.isDelete",
                    },
                  ],
                  staticClass: "normal-btn",
                  on: {
                    click: function ($event) {
                      return _vm.$emit("reply", {
                        messageId: _vm.list.id,
                        content: _vm.replyData,
                      })
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("default.confirm")) + " ")]
              ),
            ]),
            _c(
              "div",
              { staticClass: "replyRecord" },
              [
                _c("h1", [
                  _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}replyRecord`))),
                ]),
                _vm._l(_vm.userRecord, function (record) {
                  return _c(
                    "div",
                    {
                      key: record.id,
                      staticClass: "replyRecordDiv",
                      class: { "bg-secondary": record.isDelete },
                    },
                    [
                      _c("div", { staticClass: "recordDiv" }, [
                        _c("div", { staticClass: "infoDiv" }, [
                          _c("h1", [
                            _vm._v(_vm._s(_vm.$t("default.processAccount"))),
                          ]),
                          _c("p", [_vm._v(_vm._s(record.userName))]),
                        ]),
                        _c("div", { staticClass: "infoDiv" }, [
                          _c("h1", [
                            _vm._v(
                              _vm._s(_vm.$t(`${_vm.i18nPrefix}replyTime`))
                            ),
                          ]),
                          _c("p", [_vm._v(_vm._s(record.createTime))]),
                        ]),
                      ]),
                      _c("div", { staticClass: "userContentDiv" }, [
                        _c("p", {
                          domProps: { innerHTML: _vm._s(record.content) },
                        }),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !record.isDelete,
                                expression: "!record.isDelete",
                              },
                            ],
                            staticStyle: {
                              display: "flex",
                              "justify-content": "flex-end",
                              width: "100%",
                            },
                          },
                          [
                            _c(
                              "button",
                              {
                                staticClass: "delete-btn",
                                attrs: {
                                  disabled:
                                    !_vm.$store.getters.getUserinfo.permissions.includes(
                                      200104
                                    ),
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleDeleteUserMsg(record.id)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("default.delete")) + " "
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]),
                    ]
                  )
                }),
              ],
              2
            ),
            _c("div", { staticClass: "tempTable" }, [
              _c("h1", [_vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}replyTemp`)))]),
              _c(
                "table",
                [
                  _c("tr", [
                    _c("th", [
                      _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}tempTitle`))),
                    ]),
                    _c("th"),
                  ]),
                  _vm._l(_vm.templateList, function (temp) {
                    return _c("tr", [
                      _c("td", [
                        _c(
                          "div",
                          {
                            style: {
                              backgroundColor: temp.backgroundColor,
                              color: temp.titleColor,
                            },
                          },
                          [_vm._v(_vm._s(temp.title))]
                        ),
                      ]),
                      _c("td", [
                        _c(
                          "button",
                          {
                            staticClass: "normal-btn",
                            on: {
                              click: function ($event) {
                                _vm.replyData = temp.content
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}useTemp`)))]
                        ),
                      ]),
                    ])
                  }),
                ],
                2
              ),
            ]),
          ]),
        ]),
      ]),
      _vm.alertMsg
        ? _c("Alert", {
            attrs: { message: _vm.alertMsg },
            on: {
              toggle: function ($event) {
                _vm.alertMsg = undefined
              },
              doIt: _vm.deleteFunction,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }