<template>
  <div>
    <div class="container-view">
      <button class="search-btn main-style-btn" @click="search()">{{ $t("default.search") }}</button>
      <div class="header">
        <h5>{{ $t("title." + $route.meta.title) }}</h5>
      </div>
      <div class="main">
        <div class="input-block">
          <label>
            {{ $t("default.member") }}
            <input type="text" v-model="searchForm.memberAccount" />
          </label>
          <label>
            {{ $t("default.amount") }}
            <input type="text" v-model="searchForm.applyAmount" />
          </label>
          <label>
            {{ $t(`${i18nPrefix}cryptoInfo`) }}
            <select v-model="searchForm.cryptoInfoId">
              <option :value="undefined">{{ $t("default.all") }}</option>
              <option v-for="list of cryptoInfoList" :key="list.id" :value="list.id">{{ list.name }}</option>
            </select>
          </label>
          <label>
            {{ $t("default.orderNumber") }}
            <input type="text" v-model="searchForm.orderNumber" />
          </label>
          <label>
            {{ $t(`${i18nPrefix}submitStartTime`) }}
            <date-picker v-model="submitStartTime" format="YYYY-MM-DD HH:mm" value-type="format"
              type="datetime"></date-picker>
          </label>
          <label>
            {{ $t(`${i18nPrefix}submitEndTime`) }}
            <date-picker v-model="submitEndTime" format="YYYY-MM-DD HH:mm" value-type="format"
              type="datetime"></date-picker>
          </label>
          <label>
            {{ $t("default.auditTimeFrom") }}
            <date-picker v-model="auditStartTime" format="YYYY-MM-DD HH:mm" value-type="format"
              type="datetime"></date-picker>
          </label>
          <label>
            {{ $t("default.auditTimeTo") }}
            <date-picker v-model="auditEndTime" format="YYYY-MM-DD HH:mm" value-type="format"
              type="datetime"></date-picker>
          </label>
        </div>
        <div class="divider-h-2"></div>
        <!-- <div class="condition-block">
          <multiselect v-model="auditStatuses" label="name" track-by="id" :placeholder="$t('default.plzSelect')"
            :options="getAuditStatusList" :custom-label="GLOBAL.i18nCheckName" :multiple="true" :searchable="false">
          </multiselect>
          <div class="button-block">
            <button @click="auditStatuses = [...getAuditStatusList]">{{ $t("default.selectAll") }}</button>
            <button @click="auditStatuses = []">{{ $t("default.clear") }}</button>
          </div>
        </div> -->
        <MultiSelectBox :selectData.sync="auditStatuses" :list="getAuditStatusList" :multiple="true" />
        <div class="advance-block">
          <QuickSearch @changeTime="changeTime"></QuickSearch>
          <button class="search-pc-btn main-style-btn px-5" @click="search()">{{ $t("default.search") }}</button>
        </div>
      </div>
    </div>
    <div class="container-view">
      <div class="total-block-v2 row-4">
        <div class="total-block-v2-item">
          <div class="total-block-v2-item-left">{{ $t("default.totalDataCount") }}:</div>
          <div class="total-block-v2-item-right">{{ digital.format(total.totalCount) }}</div>
        </div>
        <div class="total-block-v2-item">
          <div class="total-block-v2-item-left">{{ $t("default.totalMemberCount") }}:</div>
          <div class="total-block-v2-item-right">{{ digital.format(total.totalMemberCount) }}</div>
        </div>
        <div class="total-block-v2-item">
          <div class="total-block-v2-item-left">{{ $t("default.totalReceiveAmount") }}:</div>
          <div class="total-block-v2-item-right">{{ digital.format(total.totalReceiveAmount) }}</div>
        </div>
        <div class="total-block-v2-item">
          <div class="total-block-v2-item-left">{{ $t("default.totalAmount") }}:</div>
          <div class="total-block-v2-item-right">{{ digital.format(total.totalApplyAmount) }}</div>
        </div>
      </div>
      <div class="table-block">
        <table>
          <thead>
            <tr>
              <th></th>
              <th>{{ $t("default.member") }}</th>
              <th>{{ $t("default.tag") }}</th>
              <th>{{ $t("default.orderNumber") }}</th>
              <th>{{ $t(`${i18nPrefix}submitTime`) }}</th>
              <th>{{ $t(`${i18nPrefix}cryptoInfo`) }}</th>
              <th>{{ $t("default.exchangeRate") }}</th>
              <th>{{ $t("default.receiveAmount") }}</th>
              <th>{{ $t("default.amount") }}</th>
              <th>{{ $t(`${i18nPrefix}imageUrl`) }}</th>
              <th>{{ $t("default.status") }}</th>
              <th>{{ $t("default.remark") }}</th>
              <th>{{ $t("default.admin") }}</th>
              <th>{{ $t(`${i18nPrefix}auditTime`) }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="!tableList || !tableList.length">
              <td colspan="14">{{ $t("default.noData") }}</td>
            </tr>
            <tr v-for="list of tableList" :key="list.id">
              <td>
                <button class="table-btn main-style-btn" @click="handleDialog(list, 'Audit')" :disabled="!getUserinfo.permissions.includes(10203)
                  ">{{ (list.auditStatus === 1 || list.auditStatus === 5) ? $t(`${i18nPrefix}audit`) :
    $t(`${i18nPrefix}editRemark`) }}</button>
              </td>
              <td>{{ list.memberAccount }}</td>
              <td :class="{ 'tags-td': Array.isArray(list.tags) }">
                <Tags v-if="list.tags" :tags="list.tags" :tagList="tagList"></Tags>
              </td>
              <td>{{ list.orderNumber }}</td>
              <td>{{ list.submitTime }}</td>
              <td>{{ list.cryptoInfoName }}</td>
              <td>{{ list.exchangeRate }}</td>
              <td>{{ list.receiveAmount }}</td>
              <td>{{ list.applyAmount }}</td>
              <td class="url-td">
                <span v-show="list.imageUrl" @click="handleDialog({ image: list.imageUrl }, 'Photo')"
                  :title="list.imageUrl">{{ $t(`${i18nPrefix}imageUrl`) }}</span>
              </td>
              <td>
                {{
                  GLOBAL.i18nCheckName(getAuditStatusList.find(
                    (data) => data.id === list.auditStatus
                  ))
                }}
              </td>
              <td>{{ list.remark }}</td>
              <td>{{ list.auditAccount }}</td>
              <td>{{ list.auditTime }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <Pagination v-if="totalCount > 0" :totalCount="totalCount" @handlePage="handlePage" />
    </div>

    <transition name="fade" mode="out-in">
      <component :i18nPrefix="i18nPrefix" :is="currDialog" v-bind="dialogData" @toggle="currDialog = undefined"
        @audit="audit"></component>
    </transition>
  </div>
</template>

<script>
import Audit from "./dialog/audit";
import {
  depositCryptoList,
  auditDepositCrypto,
  getDepositCryptoTotal,
  SelectMenu
} from "@/api/member/deposit";
import { searchModel } from "./model";
import { mapGetters } from "vuex";
import Tags from "@/layout/components/tags";
import Pagination from "@/layout/components/Pagination";
import handlePageUtils from "@/layout/mixins/handlePageUtils";
import handleDialog from "@/layout/mixins/handleDialog";
import QuickSearch from "@/layout/components/QuickSearch";
import MultiSelectBox from "@/layout/components/MultiSelectBox";
import { checkData } from "@/utils/checkData";
import { joinT } from "@/utils/transDateUtils";
import Multiselect from "vue-multiselect";
import Photo from "@/layout/components/photo";

export default {
  name: "MemberDepositCrypto",
  data() {
    return {
      searchForm: _.cloneDeep(searchModel),
      submitStartTime: undefined,
      submitEndTime: undefined,
      auditStartTime: undefined,
      auditEndTime: undefined,
      auditStatuses: [],
      total: {
        totalCount: 0,
        totalMemberCount: 0,
        totalApplyAmount: 0,
        totalReceiveAmount: 0
      },
      cryptoInfoList: [],
      i18nPrefix: "member.member-deposit."
    };
  },
  components: {
    Audit,
    Tags,
    Photo,
    Pagination,
    QuickSearch,
    Multiselect,
    MultiSelectBox
  },
  mixins: [handlePageUtils, handleDialog],
  computed: {
    ...mapGetters([
      "getBankListAddDefault",
      "getAuditStatusList",
      "tagList",
      "getUserinfo"
    ])
  },
  async created() {
    await SelectMenu().then(res => {
      this.cryptoInfoList = res.data;
    });
    this.auditStatuses = [
      this.getAuditStatusList[0],
      this.getAuditStatusList[4]
    ];
    // if (this.$route.query?.permissionId) {
    //   await readPrivateMessageByType({ permission: this.$route.query.permissionId * 1 });
    // }else {
    //   this.checkOrderID();
    //   return
    // }
    this.search();
  },
  methods: {
    search() {
      this.searchForm.submitStartTime = this.submitStartTime
        ? joinT(
          this.submitStartTime +
          this.GLOBAL.startSecond +
          this.GLOBAL.timeZone
        )
        : undefined;
      this.searchForm.submitEndTime = this.submitEndTime
        ? joinT(
          this.submitEndTime + this.GLOBAL.endSecond + this.GLOBAL.timeZone
        )
        : undefined;
      this.searchForm.auditStartTime = this.auditStartTime
        ? joinT(
          this.auditStartTime + this.GLOBAL.startSecond + this.GLOBAL.timeZone
        )
        : undefined;
      this.searchForm.auditEndTime = this.auditEndTime
        ? joinT(
          this.auditEndTime + this.GLOBAL.endSecond + this.GLOBAL.timeZone
        )
        : undefined;
      this.searchForm.auditStatuses = this.auditStatuses.map(
        data => data.id * 1
      );

      this.searchForm.applyAmount = this.searchForm.applyAmount * 1;
      if (checkData(this.searchForm)) {
        depositCryptoList(this.searchForm).then(res => {
          this.allData = res.data;
          this.currDialog = undefined;
          this.dataSort("submitTime", "time", true);
        });
        getDepositCryptoTotal(this.searchForm).then(
          res => (this.total = res.data)
        );
      }
    },
    audit(data) {
      const obj = {
        id: data.id,
        auditStatus: data.auditStatus,
        remark: data.remark
      };
      auditDepositCrypto(obj).then(res => {
        if (res) this.search();
      });
    },
    changeTime(time) {
      this.submitStartTime = time.startTime;
      this.submitEndTime = time.endTime;
      this.search();
    }
  }
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped></style>