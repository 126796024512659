<template>
  <div>
    <div class="container-view">
      <div class="header">
        <h4>{{ $t('Permission.' + $route.meta.permissionName) }}</h4>
      </div>
      <div class="main">
        <div class="input-block">
          <label>
            {{ $t("default.member") }}
            <input type="text" v-model="searchForm.account" />
          </label>
          <div class="input-block-multiselect">
            {{ $t("default.agent") }}
            <multiselect v-model="agentData" label="name" track-by="id" :options="getAgentListAddDefault"
              :allow-empty="false" :preselectFirst="true" :clear-on-select="false" :preserve-search="true"
              :custom-label="GLOBAL.nameWithLang"></multiselect>
          </div>
          <label>
            {{ $t("default.startTime") }}
            <date-picker v-model="startTime" format="YYYY-MM-DD HH:mm" value-type="format"
              type="datetime"></date-picker>
          </label>
          <label>
            {{ $t("default.endTime") }}
            <date-picker v-model="endTime" format="YYYY-MM-DD HH:mm" value-type="format" type="datetime"></date-picker>
          </label>
          <label>
            {{ $t("default.status") }}
            <select v-model.number="searchForm.status">
              <option :value="0 * 1">{{ $t("default.all") }}</option>
              <option v-for="list of statusList" :key="list.id" :value="list.id">{{ $t(list.name) }}</option>
            </select>
          </label>
        </div>
        <div class="advance-block">
          <button class="search-pc-btn main-style-btn px-5" @click="search()">{{ $t("default.search") }}</button>
        </div>
      </div>
    </div>

    <div class="container-view">
      <div class="table-block">
        <table>
          <thead>
            <tr>
              <th>{{ $t(`${i18nPrefix}reply`) }}</th>
              <th>{{ $t("default.processAccount") }}</th>
              <th>ID</th>
              <th>{{ $t("default.title") }}</th>
              <th>{{ $t("default.member") }}</th>
              <th>{{ $t("default.domain") }}</th>
              <th>{{ $t("member.member-management.ip") }}</th>
              <th>{{ $t(`${i18nPrefix}agentName`) }}</th>
              <th>{{ $t(`${i18nPrefix}createDateTime`) }}</th>
              <th>{{ $t("default.delete") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="!allData || allData.length === 0">
              <td colspan="999">{{ $t("default.noData") }}</td>
            </tr>
            <tr v-for="list of allData" :class="{ 'bg-secondary': list.isDelete }">
              <td>
                <button class="table-btn main-style-btn" @click="handleDialog({ list }, 'edit')"
                  :disabled="!getUserinfo.permissions.includes(200203)">{{ $t(`${i18nPrefix}reply`) }}
                </button>
              </td>
              <td>{{ list.user }}</td>
              <td>{{ list.id }}</td>
              <td>{{ list.title + "(" + list.count + ")" }}</td>
              <td>
                <span class="memberSpan"
                  @click="handleDialog({ list: { id: list.member.id, account: list.member.account, toggle: true } }, 'Detail')">{{
                  list.member.account }}
                </span>
              </td>
              <td>{{ list.domain }}</td>
              <td>{{ list.ip }}</td>
              <td>{{ list.agentName }}</td>
              <td>{{ list.createDateTime }}</td>
              <td><button class="delete-btn" @click="handleDeleteMemberMsg(list.id)" v-show="!list.isDelete"
                  :disabled="!getUserinfo.permissions.includes(200102)">
                  {{ $t("default.delete") }}
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <serverPagination v-if="allData" :page="page" @ServerPageUtils="search" @changeTake="changeTake" />
    </div>
    <Alert v-if="alertMsg" :message="alertMsg" @toggle="alertMsg = undefined" @doIt="deleteFunction" />
    <transition name="fade" mode="out-in">
      <component :is="currDialog" :i18nPrefix="i18nPrefix" v-bind="dialogData" @toggle="currDialog = undefined"
        @reply="replyMsg"></component>
    </transition>
  </div>
</template>

<script>
import { selectMenu, messageList, deleteMessage, createReply } from "@/api/customService/customServiceMessage";
import serverPagination from "@/layout/components/serverPagination";
import handleServerPageUtils from "@/layout/mixins/handleServerPageUtils";
import handleDialog from "@/layout/mixins/handleDialog";
import Edit from "./dialog/edit.vue";
import { searchModel } from "./model";
import Alert from "@/layout/components/Alert";
import { mapGetters } from "vuex";
import Multiselect from "vue-multiselect";
import { joinT } from "@/utils/transDateUtils";
import Detail from "@/views/member/member-management/dialog/detail";

export default {
  name: "CustomServiceMessage",
  data() {
    return {
      searchForm: _.cloneDeep(searchModel),
      startTime: undefined,
      endTime: undefined,
      alertMsg: undefined,
      deleteData: undefined,
      statusList: [],
      agentData: [],
      i18nPrefix: "customService.customServiceMessage."
    };
  },
  components: {
    Edit,
    Alert,
    Detail,
    Multiselect,
    serverPagination
  },
  mixins: [handleServerPageUtils, handleDialog],
  computed: {
    ...mapGetters([
      "getAgentListAddDefault",
      "getUserinfo",
    ]),
  },
  created() {
    selectMenu().then(res => {
      this.statusList = res.data
        .map(data => { return { id: data.id, name: "CustomServiceMessage.Status." + data.name } })
    })
    this.search();
  },
  methods: {
    search(page = false) {
      this.searchForm.startTime = this.startTime
        ? joinT(
          this.startTime +
          this.GLOBAL.startSecond +
          this.GLOBAL.timeZone
        )
        : undefined;
      this.searchForm.endTime = this.endTime
        ? joinT(
          this.endTime + this.GLOBAL.endSecond + this.GLOBAL.timeZone
        )
        : undefined;

      this.searchForm.agentId = this.agentData.id;

      this.searchForm.page.take = this.take * 1;
      this.searchForm.page.skip = this.take * (page ? page - 1 : 0);

      this.closeDialog();
      messageList(this.searchForm).then(res => {
        this.allData = res.data.list;
        this.page = res.data.page;
      });
    },
    replyMsg(data) {
      createReply(data).then(res => {
        if (res) this.search();
      });
    },
    handleDeleteMemberMsg(id) {
      this.alertMsg = this.$t("default.delMsg");
      this.deleteData = { id };
    },
    deleteFunction() {
      this.alertMsg = "";
      deleteMessage(this.deleteData).then(res => {
        if (res) this.search();
      });
    },
  }
};
</script>

<style lang="scss" scoped>
</style>