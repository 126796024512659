export let searchModel = {
    campaignListId: undefined,                 // 活動id
    settleCycleId: undefined,
    startTime: undefined,         // 開始時間
    endTime: undefined,            // 結束時間
    memberAccount: "", // 會員
    isFuzzySearch: false, // 模糊搜尋
    discountAuditStatus: 0, // 優惠狀態
    page:  {
        "take": 10,
        "skip": 0
    }
}