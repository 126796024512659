<template>
  <div>
    <div class="container-view">
      <div class="header">
        <h5>{{ $t("title." + $route.meta.title) }}</h5>
      </div>
      <div class="divider"></div>
      <div class="wrap">
        <div class="section" v-show="getUserinfo.permissions.includes(62001)">
          <div class="header">
            <h3>{{ $t(`${i18nPrefix}updateDomain`) }}</h3>
            <button class="table-btn main-style-btn" @click="domainSetting(newDomain)"
              :disabled="!getUserinfo.permissions.includes(62001) || checkDomain">{{ $t("default.edit") }}</button>
          </div>
          <label>
            {{ $t(`${i18nPrefix}domain`) }}
            <input type="text" v-model="domain" disabled />
          </label>
          <label>
            {{ $t(`${i18nPrefix}newDomain`) }}
            <input type="text" v-model="newDomain" />
            <span v-show="checkDomain">{{ $t(`${i18nPrefix}checkDomain`) }}</span>
          </label>
        </div>
        <div class="section" v-show="getUserinfo.permissions.includes(62001)">
          <div class="header">
            <h3>{{ $t(`${i18nPrefix}googleAuthenticatorFeatureToggle`) }}</h3>
            <button class="table-btn main-style-btn"
              @click="googleAuthenticatorFeatureToggleSetting(googleAuthenticatorFeatureToggle === 1)"
              :disabled="!getUserinfo.permissions.includes(62001)">{{ $t("default.edit") }}</button>
          </div>
          <label>
            {{ $t("default.status") }}
            <select v-model="googleAuthenticatorFeatureToggle">
              <option v-for="list of getStatusList" :key="list.id" :value="list.id">{{ $t(list.name) }}</option>
            </select>
          </label>
        </div>
        <div class="section" v-show="getUserinfo.permissions.includes(62001)">
          <div class="header">
            <h3>{{ $t(`${i18nPrefix}broadcastMemberWinThreshold`) }}</h3>
            <button class="table-btn main-style-btn"
              @click="updateBroadcastMemberWinThreshold(broadcastMemberWinThreshold)"
              :disabled="!getUserinfo.permissions.includes(62001)">{{ $t("default.edit") }}</button>
          </div>
          <label>
            {{ $t(`${i18nPrefix}broadcastMemberWinThreshold`) }}
            <input type="text" v-model="broadcastMemberWinThreshold" />
            <span v-show="checkDomain">{{ $t(`${i18nPrefix}checkDomain`) }}</span>
          </label>
        </div>
        <div class="section" v-show="getUserinfo.permissions.includes(62001)">
          <div class="header">
            <h3>{{ $t(`${i18nPrefix}registerSNSFeatureToggle`) }}</h3>
            <button class="table-btn main-style-btn" @click="updateRegisterSNSFeatureToggle()"
              :disabled="!getUserinfo.permissions.includes(62001)">{{ $t("default.edit") }}</button>
          </div>
          <label>
            {{ $t("default.status") }}
            <select v-model="registerSNSFeatureToggle">
              <option :value="true">{{ $t("default.active") }}</option>
              <option :value="false">{{ $t("default.inactive") }}</option>
            </select>
          </label>
        </div>
        <div class="section" v-show="getUserinfo.permissions.includes(62007)">
          <div class="header">
            <h3>{{ $t(`${i18nPrefix}pointWallet`) }}</h3>
            <button class="table-btn main-style-btn" @click="updatePointWallet()"
              :disabled="!getUserinfo.permissions.includes(62007)">{{ $t("default.edit") }}</button>
          </div>
          <label>
            {{ $t("default.status") }}
            <select v-model="pointWallet">
              <option :value="true">{{ $t("default.active") }}</option>
              <option :value="false">{{ $t("default.inactive") }}</option>
            </select>
          </label>
        </div>
        <div class="section" v-show="getUserinfo.permissions.includes(62008)">
          <div class="header">
            <h3>{{ $t(`${i18nPrefix}bindingCvsCard`) }}</h3>
            <button class="table-btn main-style-btn" @click="updateBindingCvsCard()"
              :disabled="!getUserinfo.permissions.includes(62008)">{{ $t("default.edit") }}</button>
          </div>
          <label>
            {{ $t("default.status") }}
            <select v-model="bindingCvsCard">
              <option :value="true">{{ $t("default.active") }}</option>
              <option :value="false">{{ $t("default.inactive") }}</option>
            </select>
          </label>
        </div>
        <div class="section" v-show="getUserinfo.permissions.includes(62006)">
          <div class="header">
            <h3>{{ $t(`${i18nPrefix}downloadPassword`) }}</h3>
            <button class="table-btn main-style-btn" @click="updateExportReportPasswordFeatureToggle()">
              {{ $t("default.edit") }}</button>
          </div>
          <label>
            {{ $t("default.status") }}
            <select v-model="exportReportPasswordFeatureToggle">
              <option :value="true">{{ $t("default.active") }}</option>
              <option :value="false">{{ $t("default.inactive") }}</option>
            </select>
          </label>
          <label>
            {{ !reportFirstPassword
          ? $t(`${i18nPrefix}oldPassword`)
          : $t(`${i18nPrefix}newPassword`) }}
            <input type="password" v-model="oldPassword" />
          </label>
          <label v-show="this.exportReportPasswordFeatureToggle && !reportFirstPassword">
            {{ $t(`${i18nPrefix}newPassword`) }}
            <input type="password" v-model="newPassword" />
          </label>
          <label v-show="this.exportReportPasswordFeatureToggle && !reportFirstPassword">
            {{ $t(`${i18nPrefix}newPassword2`) }}
            <input type="password" v-model="newPassword2" />
          </label>
        </div>
        <div class="section" v-show="getUserinfo.permissions.includes(62011)">
          <div class="header">
            <h3>{{ $t(`${i18nPrefix}welcomeMessage`) }}</h3>
            <button class="table-btn main-style-btn" @click="updateWelcomeMessage()"
              :disabled="!getUserinfo.permissions.includes(62011)">{{ $t("default.edit") }}</button>
          </div>
          <label>
            {{ $t("default.status") }}
            <select v-model="welcomeMessage">
              <option :value="true">{{ $t("default.active") }}</option>
              <option :value="false">{{ $t("default.inactive") }}</option>
            </select>
          </label>
        </div>
        <div class="section status" v-show="getUserinfo.permissions.includes(62001)">
          <div class="header">
            <h3>{{ $t(`${i18nPrefix}memberDefaultGateway`) }}</h3>
            <button class="table-btn main-style-btn" @click="handleUpdate()"
              :disabled="!getUserinfo.permissions.includes(62001)">{{
          $t("default.edit") }}</button>
          </div>
          <div class="status-div">
            <div v-for="list of memberDefaultGateway" :key="list.paymentGatewayId" class="toogle">
              <label>
                <span class="span-row">
                  <input type="checkbox" v-model="list.isSync" :disabled="!list.enable" />
                  {{ $t(`${i18nPrefix}isSync`) }}
                </span>
              </label>
              {{ $t("DepositTypeList." + list.paymentGatewayId) }}
              <label>
                <div class="slide-block" :class="{ 'slide-block-on': list.enable }">
                  <div class="slide" :class="{ 'slide-on': list.enable }">
                    <label :for="'paymentGatewayId' + list.paymentGatewayId"></label>
                    <input type="checkbox" :id="'paymentGatewayId' + list.paymentGatewayId" v-model="list.enable"
                      @click="list.isSync = false" />
                  </div>
                </div>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <transition name="fade" mode="out-in">
      <Alert v-if="alertMsg" :message="alertMsg" @toggle="alertMsg = undefined" @doIt="updateMemberDefaultGateway" />
    </transition>
  </div>
</template>

<script>
import {
  getCompanyInfo,
  updateDomain,
  updateGoogleAuthenticatorFeatureToggle,
  updateBroadcastMemberWinThreshold,
  updateMemberDefaultGateway,
  updateRegisterSNSFeatureToggle,
  updateExportReportPasswordFeatureToggle,
  updatePointWallet,
  updateBindingCvsCard,
  updateWelcomeMessage
} from "@/api/features/companySettings";
import { checkValue } from "@/utils/checkData";
import { mapGetters } from "vuex";
import Alert from "@/layout/components/Alert";
import Message from "@/layout/components/Message";

export default {
  name: "CompanySettings",
  data() {
    return {
      domain: undefined,
      newDomain: undefined,
      checkDomain: false,
      googleAuthenticatorFeatureToggle: false,
      broadcastMemberWinThreshold: undefined,
      registerSNSFeatureToggle: false,
      exportReportPasswordFeatureToggle: undefined,
      reportFirstPassword: true,
      oldPassword: undefined,
      newPassword: undefined,
      newPassword2: undefined,
      pointWallet: undefined,
      bindingCvsCard: undefined,
      memberDefaultGateway: {},
      welcomeMessage: false,
      alertMsg: undefined,
      i18nPrefix: "features.companySettings."
    };
  },
  components: {
    Alert
  },
  computed: {
    ...mapGetters(["getUserinfo", "getStatusList"])
  },
  watch: {
    newDomain(val) {
      this.checkDomain = new RegExp(/^https?|\/|:|\..*\./i).test(val);
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.newDomain = undefined;
      getCompanyInfo().then(res => {
        this.domain = res.data.domain;
        this.googleAuthenticatorFeatureToggle = res.data
          .googleAuthenticatorFeatureToggle
          ? 1
          : 2;

        this.broadcastMemberWinThreshold = res.data.broadcastMemberWinThreshold;
        this.memberDefaultGateway = res.data.memberDefaultGateway;
        this.registerSNSFeatureToggle = res.data.registerSNSFeatureToggle;
        this.exportReportPasswordFeatureToggle = res.data.exportReportPasswordFeatureToggle;
        this.reportFirstPassword = res.data.reportFirstPassword;
        this.pointWallet = res.data.pointWallet;
        this.bindingCvsCard = res.data.bindingCvsCard;
        this.welcomeMessage = res.data.welcomeMessage;
      });
    },
    domainSetting(data) {
      if (!checkValue(data, this.$t(`${this.i18nPrefix}newDomain`))) return;
      updateDomain({ newDomain: data }).then(res => {
        if (res) this.init();
      });
    },
    googleAuthenticatorFeatureToggleSetting(data) {
      updateGoogleAuthenticatorFeatureToggle({
        googleAuthenticatorFeatureToggle: data
      }).then(res => {
        if (res) this.init();
      });
    },
    updateBroadcastMemberWinThreshold(data) {
      updateBroadcastMemberWinThreshold({
        broadcastMemberWinThreshold: data
      }).then(res => {
        if (res) this.init();
      });
    },
    handleUpdate() {
      this.alertMsg = this.$t(`${this.i18nPrefix}updateMsg`);
    },
    updateMemberDefaultGateway() {
      this.alertMsg = undefined;
      this.memberDefaultGateway.forEach(function (gateway) {
        if (typeof gateway.isSync === 'undefined') {
          gateway.isSync = false;
        }
      });
      updateMemberDefaultGateway({
        memberDefaultGateways: this.memberDefaultGateway
      }).then(res => {
        if (res) {
          this.init();
        }
      });
    },
    updateRegisterSNSFeatureToggle() {
      updateRegisterSNSFeatureToggle({
        registerSNSFeatureToggle: this.registerSNSFeatureToggle
      }).then(res => {
        if (res) {
          this.init();
        }
      });
    },
    updateExportReportPasswordFeatureToggle() {
      if (!this.oldPassword) {
        Message.error(
          this.$t("default.enterPlz") + " " + this.$t(`${this.i18nPrefix}oldPassword`),
          1000
        );
        return;
      }
      if (this.exportReportPasswordFeatureToggle && !this.reportFirstPassword && !this.newPassword) {
        Message.error(
          this.$t("default.enterPlz") + " " + this.$t(`${this.i18nPrefix}newPassword`),
          1000
        );
        return;
      }
      if (this.exportReportPasswordFeatureToggle && !this.reportFirstPassword && !this.newPassword2) {
        Message.error(
          this.$t("default.enterPlz") + " " + this.$t(`${this.i18nPrefix}newPassword2`),
          1000
        );
        return;
      }
      if (this.exportReportPasswordFeatureToggle && !this.reportFirstPassword && (this.newPassword !== this.newPassword2)) {
        Message.error(
          this.$t("default.enterPlz") + " " + this.$t(`${this.i18nPrefix}downloadPasswordMsg`),
          1000
        );
        return;
      }
      updateExportReportPasswordFeatureToggle({
        exportReportPasswordFeatureToggle: this.exportReportPasswordFeatureToggle,
        oldPassword: this.oldPassword,
        newPassword: this.newPassword
      }).then(res => {
        if (res) {
          this.init();
          this.oldPassword = undefined;
          this.newPassword = undefined;
          this.newPassword2 = undefined;
        }
      });
    },
    updatePointWallet() {
      updatePointWallet({
        pointWallet: this.pointWallet
      }).then(res => {
        if (res) {
          this.init();
        }
      });
    },
    updateBindingCvsCard() {
      updateBindingCvsCard({
        bindingCvsCard: this.bindingCvsCard
      }).then(res => {
        if (res) {
          this.init();
        }
      });
    },
    updateWelcomeMessage() {
      updateWelcomeMessage({ welcomeMessage: this.welcomeMessage }).then(res => {
        if (res) this.init();
      })
    }
  }
};
</script>

<style lang="scss" scoped>
h3 {
  margin-bottom: 0 !important;
}

.wrap {
  display: grid;
  grid-template-columns: repeat(3, 33%);
  gap: 10px;
  justify-content: flex-start;
}

.section {
  display: inline-table;
  border-radius: 8px;
  border: 1.5px solid #7E96A3;
  background: #FFF;
  padding: 30px;

  label {
    display: flex;
    flex-direction: column;
    width: 100%;
    // margin: 15px 15px;
    position: relative;

    span {
      position: absolute;
      color: rgb(255, 0, 0, 0.8) !important;
      width: 100%;
      font-size: 12px;
      font-weight: bold;
      bottom: -20px;
      animation: fadeDown 0.2s linear;
    }
  }

  .header {
    justify-content: space-between !important;
    align-items: center;

    h3 {
      margin-bottom: 0 !important;
    }

    button {
      white-space: nowrap;
    }
  }
}

.toogle {
  display: flex;
  flex-direction: column;
  width: 180px;
  margin: 15px 15px;
  position: relative;

  .span-row {
    display: flex;
    flex-direction: row !important;
    justify-content: flex-start !important;
    align-items: center !important;
    margin: 0;
    padding: 0;

    input {
      margin-right: 5px;
    }
  }
}

.status {
  width: 100%;

  .status-div {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
  }
}

@media screen and (max-width: 768px) {
  .section {
    padding: 10px;

    .header {
      margin: 10px 0 !important;

      h3 {
        font-size: 20px;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .wrap {
    grid-template-columns: repeat(1, 100%);
    flex-direction: column;

    .section {
      width: 100% !important;
      margin: 5px;
    }
  }
}
</style>