<template>
  <div>
    <div class="container-view">
      <button class="search-btn main-style-btn" @click="search()">
        {{ $t("default.search") }}
      </button>
      <div class="header">
        <h5>{{ $t("title." + $route.meta.title) }}</h5>
        <div class="float-right">
          <button class="download-btn main-style-btn" @click="download">
            <img class="icon" src="@/assets/download.svg" />
            {{ $t("default.download") }}
          </button>
        </div>
      </div>
      <div class="main">
        <div class="input-block">
          <label>
            <div class="input-block-check">
              <span style="margin-right: 10px">{{
                $t(`${i18nPrefix}memberAccount`)
              }}</span>
              <input
                class="pc-block"
                id="isFuzzySearch"
                type="checkbox"
                v-model="searchForm.isFuzzySearch"
              />
              <label class="pc-block" for="isFuzzySearch">{{
                $t(`${i18nPrefix}isFuzzySearch`)
              }}</label>
            </div>
            <input type="text" v-model="searchForm.memberAccount" />
          </label>
          <div class="mobile-block">
            <div class="input-block-check">
              <input
                id="isFuzzySearch"
                type="checkbox"
                v-model="searchForm.isFuzzySearch"
              />
              <label for="isFuzzySearch">{{
                $t(`${i18nPrefix}isFuzzySearch`)
              }}</label>
            </div>
          </div>
          <label class="input-block-multiselect">
            <div class="input-block-check">
              <span style="margin-right: 10px">{{ $t("default.agent") }}</span>
              <input
                class="pc-block"
                id="isIncludeSubAgent"
                type="checkbox"
                v-model="searchForm.isIncludeSubAgent"
              />
              <label class="pc-block" for="isIncludeSubAgent">{{
                $t("default.isIncludeSubAgent")
              }}</label>
            </div>
            <multiselect
              v-model="agentData"
              label="name"
              track-by="id"
              :options="getAgentListAddDefault"
              :allow-empty="false"
              :preselectFirst="true"
              :clear-on-select="false"
              :preserve-search="true"
              :custom-label="GLOBAL.nameWithLang"
            ></multiselect>
          </label>
          <div class="mobile-block">
            <div class="input-block-check">
              <input
                id="isIncludeSubAgent"
                type="checkbox"
                v-model="searchForm.isIncludeSubAgent"
              />
              <label for="isIncludeSubAgent">{{
                $t("default.isIncludeSubAgent")
              }}</label>
            </div>
          </div>
          <label>
            {{ $t("default.startTime") }}
            <date-picker
              v-model="startTime"
              format="YYYY-MM-DD HH:mm:ss"
              value-type="format"
              type="datetime"
              :disabled-date="GLOBAL.ontSeason"
            ></date-picker>
          </label>
          <label>
            {{ $t("default.endTime") }}
            <date-picker
              v-model="endTime"
              format="YYYY-MM-DD HH:mm:ss"
              value-type="format"
              type="datetime"
              :disabled-date="GLOBAL.ontSeason"
            ></date-picker>
          </label>
          <label>
            {{ $t(`${i18nPrefix}dimension`) }}
            <select v-model="searchForm.dimension">
              <option
                v-for="list of dimensionList"
                :key="list.id"
                :value="list.id"
              >
                {{ $t(list.name) }}
              </option>
            </select>
          </label>
        </div>
        <div class="advance-block">
          <QuickSearch ref="quickSearch" @changeTime="changeTime"></QuickSearch>
          <button class="search-pc-btn main-style-btn px-5" @click="search()">
            {{ $t("default.search") }}
          </button>
        </div>
      </div>
    </div>
    <div class="container-view">
      <div class="table-block">
        <TreeTable
          ref="treeTable"
          :list="list"
          :column="tableColumn"
          :header="tableHeaderColumn"
        >
          <template v-slot:column_game="props">{{
            getGameName(props)
          }}</template>
          <template v-slot:column_rtp="props">{{ props.rtp + "%" }}</template>
        </TreeTable>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getList,
  getExcel,
} from "@/api/statistical-report/gameStatisticalAnalysis";
import { searchModel, dimensionList } from "./model";
import { transToday, joinT, transNow } from "@/utils/transDateUtils";
import { mapGetters } from "vuex";
import QuickSearch from "@/layout/components/QuickSearch";
import TreeTable from "@/layout/components/TreeTable";
import Multiselect from "vue-multiselect";
import handlePageUtils from "@/layout/mixins/handlePageUtils";
import { download } from "@/utils/download";

export default {
  name: "GameStatisticalAnalysis",
  data() {
    return {
      dimensionList,
      list: [],
      agentData: [],
      searchForm: _.cloneDeep(searchModel),
      startTime: transToday("from") + this.GLOBAL.startSecond,
      endTime: transToday("to") + this.GLOBAL.endSecond,
      i18nPrefix: "statistical-report.gameStatisticalAnalysis.",
      digital: new Intl.NumberFormat(),
      total: {},
    };
  },
  components: {
    QuickSearch,
    TreeTable,
    Multiselect,
  },
  computed: {
    ...mapGetters([
      "getAgentListAddDefault",
      "getGamePlatforms",
      "getGamesCategorys",
      "getUserinfo",
    ]),
    tableColumn() {
      const kr = [
        {
          key: "gamesCategory",
          name: this.$t("default.sort"),
        },
        {
          key: "game",
          name: this.$t(`default.gamesCategory`),
        },
        {
          key: "rtp",
          name: this.$t(`${this.i18nPrefix}rtp`),
          sort: "true",
        },
        {
          key: "memberBetCount",
          name: this.$t(`${this.i18nPrefix}memberBetCount`),
        },
        {
          key: "totalBetCount",
          name: this.$t(`${this.i18nPrefix}totalBetCount`),
        },
        {
          key: "totalValidBetAmount",
          name: this.$t(`${this.i18nPrefix}totalValidBetAmount`),
        },
        {
          key: "totalBetAmount",
          name: this.$t(`${this.i18nPrefix}totalBetAmount`),
        },
        {
          key: "totalPayoutAmount",
          name: this.$t(`${this.i18nPrefix}totalPayoutAmount`),
        },
        {
          key: "totalWinLose",
          name: this.$t(`${this.i18nPrefix}totalWinLose_kr`),
        },
      ];
      const other = [
        {
          key: "game",
          name: "",
        },
        {
          key: "multiples",
          name: this.$t("default.multiples"),
        },
        {
          key: "rtp",
          name: this.$t(`${this.i18nPrefix}rtp`),
          sort: "true",
        },
        {
          key: "memberBetCount",
          name: this.$t(`${this.i18nPrefix}memberBetCount`),
        },
        {
          key: "totalBetCount",
          name: this.$t(`${this.i18nPrefix}totalBetCount`),
        },
        {
          key: "totalValidBetAmount",
          name: this.$t(`${this.i18nPrefix}totalValidBetAmount`),
        },
        {
          key: "totalBetAmount",
          name: this.$t(`${this.i18nPrefix}totalBetAmount`),
        },
        {
          key: "totalPayoutAmount",
          name: this.$t(`${this.i18nPrefix}totalPayoutAmount`),
        },
        {
          key: "totalWinLose",
          name: this.$t(`${this.i18nPrefix}totalWinLose`),
        },
        {
          key: "totalCompanyWinAmount",
          name: this.$t(`${this.i18nPrefix}totalMemberLoseAmount`),
        },
      ];
      return this.getUserinfo.koreanStyleInterface ? kr : other;
    },
    tableHeaderColumn() {
      const kr = [
        {
          key: "total",
          column: [
            {
              key: "title",
              align: "left",
              value: this.$t(`${this.i18nPrefix}total`),
            },
            { key: "id" },
            { key: "rtp", value: this.total.rtp + "%" },
            { key: "memberBetCount", value: this.total.memberBetCount },
            { key: "totalBetCount", value: this.total.totalBetCount },
            {
              key: "totalValidBetAmount",
              value: this.total.totalValidBetAmount,
            },
            { key: "totalBetAmount", value: this.total.totalBetAmount },
            { key: "totalPayoutAmount", value: this.total.totalPayoutAmount },
            { key: "totalWinLose", value: this.total.totalWinLose },
          ],
        },
      ];
      const other = [
        {
          key: "total",
          column: [
            {
              key: "title",
              align: "left",
              value: this.$t(`${this.i18nPrefix}total`),
            },
            { key: "multiples", value: "" },
            { key: "rtp", value: this.total.rtp + "%" },
            { key: "memberBetCount", value: this.total.memberBetCount },
            { key: "totalBetCount", value: this.total.totalBetCount },
            {
              key: "totalValidBetAmount",
              value: this.total.totalValidBetAmount,
            },
            { key: "totalBetAmount", value: this.total.totalBetAmount },
            { key: "totalPayoutAmount", value: this.total.totalPayoutAmount },
            { key: "totalWinLose", value: this.total.totalWinLose },
            {
              key: "totalCompanyWinAmount",
              value: this.total.totalCompanyWinAmount,
            },
          ],
        },
      ];
      return this.getUserinfo.koreanStyleInterface ? kr : other;
    },
  },
  mixins: [handlePageUtils],
  created() {
    this.$nextTick(function () {
      setTimeout(() => {
        this.$refs.quickSearch.changeDate("today");
      }, 200);
    });
  },
  methods: {
    async search() {
      //時間參數
      this.searchForm.startTime = this.startTime
        ? joinT(this.startTime + this.GLOBAL.timeZone)
        : undefined;
      this.searchForm.endTime = this.endTime
        ? joinT(this.endTime + this.GLOBAL.timeZone)
        : undefined;

      this.searchForm.agentId = this.agentData.id;

      getList(this.searchForm).then((res) => {
        this.list = res.data?.list;
        this.total = res.data?.total;
      });
    },
    changeTime(time) {
      this.startTime = time.startTime + this.GLOBAL.startSecond;
      this.endTime = time.endTime + this.GLOBAL.endSecond;
      this.search();
    },
    getGameName(data) {
      let name =
        (data.gamePlatform ? this.getGamePlatformName(data.gamePlatform) : "") +
        (data.gamesCategory && data.gamePlatform ? " + " : "") +
        (data.gamesCategory
          ? this.getGamesCategoryName(data.gamesCategory)
          : "");

      return name;
    },
    getGamePlatformName(id) {
      return this.GLOBAL.i18nCheckName(
        this.getGamePlatforms.find((data) => data.id === id)
      );
    },
    getGamesCategoryName(id) {
      return this.GLOBAL.i18nCheckName(
        this.getGamesCategorys.find((data) => data.id === id)
      );
    },
    download() {
      //時間參數
      this.searchForm.startTime = this.startTime
        ? joinT(this.startTime + this.GLOBAL.timeZone)
        : undefined;
      this.searchForm.endTime = this.endTime
        ? joinT(this.endTime + this.GLOBAL.timeZone)
        : undefined;

      this.searchForm.agentId = this.agentData.id;

      getExcel(this.searchForm).then((res) =>
        download(res, transNow("onlyNum") + "-GameStatisticalAnalysis.xlsx")
      );
    },
  },
};
</script>
