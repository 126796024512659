var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        _vm.getUserinfo.koreanStyleInterface
          ? "agentManagementAll"
          : "agentManagement",
        { tag: "component" }
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }