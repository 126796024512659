<template>
  <div class="navbarDiv" ref="navbarDiv">
    <div class="navbar-block" ref="nav">
      <div class="hamburger-box" :class="{ hide: !sidebar }" @click="toggleSidebar">
        <div class="hamburger"></div>
      </div>
      <div class="title">
        <p>
          <router-link :to="'/dashboard'">
            <span>{{ getUserinfo.name }}</span>
            <span>{{ $t("default.backManagement") }}</span>
          </router-link>
        </p>
      </div>
      <div class="user">
        <template v-if="!getUserinfo.koreanStyleInterface">
          <div class="new" v-show="haveMail && getUserinfo.permissions.includes(80000)">
            <div class="bell">
              <img class="bell-img" src="@/assets/navbar/bell.svg" @click.stop="toggleNewItem" />
            </div>
            <div class="new-item" :class="[notification ? 'active' : '']" ref="newItem">
              <router-link to="/member/member-deposit?permissionId=10200"
                v-show="getMailData.find((data) => data.permissionId === 10200)" @click.native="
        readMessage('/member/member-deposit?permissionId=10200')
        ">
                <img src="@/assets/deposit.png" />
              </router-link>
              <router-link to="/member/member-deposit-store?permissionId=10300"
                v-show="getMailData.find((data) => data.permissionId === 10300)" @click.native="
        readMessage('/member/member-deposit-store?permissionId=10300')
        ">
                <img src="@/assets/store.png" />
              </router-link>
              <router-link to="/member/member-withdraw?permissionId=10400"
                v-show="getMailData.find((data) => data.permissionId === 10400)" @click.native="
        readMessage('/member/member-withdraw?permissionId=10400')
        ">
                <img src="@/assets/member-withdraw.png" />
              </router-link>
              <router-link to="/member/member-discount?permissionId=10500"
                v-show="getMailData.find((data) => data.permissionId === 10500)" @click.native="
        readMessage('/member/member-discount?permissionId=10500')
        ">
                <img src="@/assets/bonus.png" />
              </router-link>
              <router-link to="/member/member-withdraw?permissionId=10106"
                v-show="getMailData.find((data) => data.permissionId === 10106)" @click.native="
        readMessage('/member/member-withdraw?permissionId=10106')
        ">
                <img src="@/assets/member-withdraw.png" />
              </router-link>
              <router-link to="/userInfo/mail-box?permissionId=61000"
                v-show="getMailData.find((data) => data.permissionId === 61000)">
                <img src="@/assets/risk.png" />
              </router-link>
              <router-link to="/userInfo/mail-box?permissionId=120200"
                v-show="getMailData.find((data) => data.permissionId === 120200)">
                <img src="@/assets/blackList.png" />
              </router-link>
              <router-link to="/userInfo/mail-box?permissionId=10109"
                v-show="getMailData.find((data) => data.permissionId === 10109)">
                <img src="@/assets/bankPic.png" />
              </router-link>
              <router-link to="/agent/agent-withdrew?permissionId=20501"
                v-show="getMailData.find((data) => data.permissionId === 20501)">
                <img src="@/assets/agent-withdraw.png" />
              </router-link>
              <router-link to="/agent/agent-bankCard-inquiry?permissionId=20303"
                v-show="getMailData.find((data) => data.permissionId === 20303)">
                <img src="@/assets/deleteAgentCard.png" />
              </router-link>
              <router-link to="/member/Kyc" v-show="getMailData.find((data) => data.permissionId === 13000)">
                <img src="@/assets/KYC.png" />
              </router-link>
              <router-link to="/member/member-registration"
                v-show="getMailData.find((data) => data.permissionId === 14001)">
                <img src="@/assets/member-registration.png" />
              </router-link>
            </div>
          </div>
        </template>
        <!-- 韓國站icon -->
        <template v-else>
          <div class="new" v-show="haveMail && getUserinfo.permissions.includes(80000)">
            <div class="bell" v-if="getWidth < 700">
              <img class="bell-img" src="@/assets/navbar/bell.svg" @click.stop="toggleNewItem" />
            </div>
            <div class="new-item-k" :class="[notification ? 'active' : '', getWidth < 700 ? 'new-item' : '']"
              ref="newItem">
              <router-link to="/member/member-deposit?permissionId=10200"
                v-show="getMailData.find((data) => data.permissionId === 10200)" @click.native="
        readMessage('/member/member-deposit?permissionId=10200')
        ">
                <img src="@/assets/korean/member-deposit.png" />
                <span>{{ getMailData.filter((data) => data.permissionId === 10200)?.length }}</span>
              </router-link>
              <router-link to="/member/member-withdraw?permissionId=10400"
                v-show="getMailData.find((data) => data.permissionId === 10400)" @click.native="
        readMessage('/member/member-withdraw?permissionId=10400')
        ">
                <img src="@/assets/korean/member-withdraw.png" />
                <span>{{ getMailData.filter((data) => data.permissionId === 10400)?.length }}</span>
              </router-link>
              <router-link to="/member/member-discount?permissionId=10500"
                v-show="getMailData.find((data) => data.permissionId === 10500)" @click.native="
        readMessage('/member/member-discount?permissionId=10500')
        ">
                <img src="@/assets/korean/member-discount.png" />
                <span>{{ getMailData.filter((data) => data.permissionId === 10500)?.length }}</span>
              </router-link>
              <router-link to="/member/member-withdraw?permissionId=10106"
                v-show="getMailData.find((data) => data.permissionId === 10106)" @click.native="
        readMessage('/member/member-withdraw?permissionId=10106')
        ">
                <img src="@/assets/korean/member-withdraw.png" />
                <span>{{ getMailData.filter((data) => data.permissionId === 10106)?.length }}</span>
              </router-link>
              <router-link to="/userInfo/mail-box?permissionId=61000"
                v-show="getMailData.find((data) => data.permissionId === 61000)">
                <img src="@/assets/risk.png" />
                <span>{{ getMailData.filter((data) => data.permissionId === 61000)?.length }}</span>
              </router-link>
              <router-link to="/userInfo/mail-box?permissionId=120200"
                v-show="getMailData.find((data) => data.permissionId === 120200)">
                <img src="@/assets/blackList.png" />
                <span>{{ getMailData.filter((data) => data.permissionId === 120200)?.length }}</span>
              </router-link>
              <router-link to="/userInfo/mail-box?permissionId=10109"
                v-show="getMailData.find((data) => data.permissionId === 10109)">
                <img src="@/assets/korean/bankPic.png" />
                <span>{{ getMailData.filter((data) => data.permissionId === 10109)?.length }}</span>
              </router-link>
              <router-link to="/member/member-registration"
                v-show="getMailData.find((data) => data.permissionId === 14001)">
                <img src="@/assets/korean/member-registration.png" />
                <span>{{ getMailData.filter((data) => data.permissionId === 14001)?.length }}</span>
              </router-link>
              <router-link to="/customService/customServiceMessage"
                v-show="getMailData.find((data) => data.permissionId === 200100)">
                <img src="@/assets/korean/customServiceMessage.png" />
                <span>{{ getMailData.filter((data) => data.permissionId === 200100)?.length }}</span>
              </router-link>
            </div>
          </div>
        </template>
        <SelectStyleV1 :list="GLOBAL.langes" :default="lang" :now.sync="nowLang"
          style="margin-right: 10px; color: #3D4F59" />
        <div class="user-account" @click.stop="toggleUserDropDown">
          {{ $store.getters.userName }}
        </div>
        <transition name="fade" mode="out-in">
          <UserDropDown v-show="drop"></UserDropDown>
        </transition>
        <transition name="fade" mode="out-in">
          <OnlineMemberList :is="onlineMember" @toggle="onlineMember = false"></OnlineMemberList>
        </transition>
      </div>
    </div>
    <div class="liveData" ref="liveData" @click="toogleLiveData()" @mousedown="handleMouseDown"
      @mouseleave="handleMouseLeave" @mouseup="handleMouseUp" @mousemove="handleMouseMove"
      v-if="!(getUserinfo.koreanStyleInterface && getUserinfo.role === 2)">
      <div class="labels">
        <label v-if="operatingData.onlineMemberCount !== undefined" @click="onlineMember = 'OnlineMemberList'">{{
        $t("liveData.onlineMemberCount") + "：" +
        operatingData.onlineMemberCount }}</label>
        <label v-if="operatingData.totalMonthDeposit !== undefined">{{ $t("liveData.totalMonthDeposit") + "：" +
        operatingData.totalMonthDeposit }}</label>
        <label v-if="operatingData.totalMonthWithdrawal !== undefined">{{ $t("liveData.totalMonthWithdrawal") + "：" +
        operatingData.totalMonthWithdrawal }}</label>
        <label v-if="operatingData.totalDayDeposit !== undefined">{{ $t("liveData.totalDayDeposit") + "：" +
        operatingData.totalDayDeposit }}</label>
        <label v-if="operatingData.totalDayWithdrawal !== undefined">{{ $t("liveData.totalDayWithdrawal") + "：" +
        operatingData.totalDayWithdrawal }}</label>
        <label v-if="operatingData.totalMemberWalletAmount !== undefined">{{ $t("liveData.totalMemberWalletAmount") +
        "："
        + operatingData.totalMemberWalletAmount }}</label>
        <label v-show="casinoBalanceData.casinoBalance !== undefined">{{ $t("liveData.casinoBalance") + "：" +
        casinoBalanceData.casinoBalance }}</label>
        <label v-show="casinoBalanceData.sportsBetAmount !== undefined">{{ $t("liveData.sportsBetAmount") + "：" +
        casinoBalanceData.sportsBetAmount }}</label>
        <label v-show="casinoBalanceData.sportsBalance !== undefined">{{ $t("liveData.sportsBalance") + "：" +
        casinoBalanceData.sportsBalance }}</label>
        <label v-show="casinoBalanceData.liveCasinoBalance !== undefined">{{ $t("liveData.liveCasinoBalance") + "：" +
        casinoBalanceData.liveCasinoBalance }}</label>
      </div>
    </div>
  </div>
</template>

<script>
import { getLiveData } from "@/api/liveData";
import { mapGetters, mapMutations } from "vuex";
import OnlineMemberList from "./onlineMemberList";
import UserDropDown from "./UserDropDown";
import { getServerLang } from "@/api/user";
import SelectStyleV1 from "./selectStyleV1";

export default {
  name: "Navbar",
  components: {
    UserDropDown,
    OnlineMemberList,
    SelectStyleV1,
  },
  data() {
    return {
      width: window.innerWidth,
      nowLang: "",
      platformVersion: "",
      expanded: false,
      isDown: false,
      startX: 0,
      scrollLeft: 0,
      isDragging: false,
      onlineMember: false,
      operatingData: [],
      casinoBalanceData: [],
    };
  },
  created() {
    document.title = this.getUserinfo.name;
    this.platformVersion = process.env.VUE_APP_platformVersion;
    this.getLiveData();
  },
  computed: {
    ...mapGetters([
      "token",
      "sidebar",
      "getUserDropDown",
      "getMail",
      "getUserinfo",
      "getMailData",
      "lang",
      "getNotification",
    ]),
    drop: {
      get() {
        return this.getUserDropDown;
      },
      set(value) {
        this.SET_USERDROPDOWN(value);
      },
    },
    haveMail: {
      get() {
        return this.getMail;
      },
      set(value) {
        this.SET_MAIL(value);
      },
    },
    notification: {
      get() {
        return this.getNotification;
      },
      set(value) {
        this.SET_NOTIFICATION(value);
      },
    },
    getWidth() {
      return this.width;
    }
  },
  watch: {
    nowLang: {
      handler(e) {
        this.i18nSet(e);
      },
    },
  },
  mounted() {
    if (this.$refs.liveData) {
      this.$refs.liveData.classList.toggle('expanded', this.expanded);
    };
    setInterval(() => {
      this.getLiveData()
    }, 5 * 1000);

    setTimeout(() => {
      this.$nextTick(function () {
        window.addEventListener("resize", this.getNavHeight);
        this.getNavHeight();
      });
    }, 1000);

    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.getNavHeight);
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    ...mapMutations({
      SET_USERDROPDOWN: "user/SET_USERDROPDOWN",
      SET_NAVHEIGHT: "user/SET_NAVHEIGHT",
      SET_MAIL: "user/SET_MAIL",
      SET_NOTIFICATION: "user/SET_NOTIFICATION",
    }),
    toggleSidebar() {
      this.$store.dispatch("app/toggleSidebar");
    },
    toggleUserDropDown() {
      this.drop = !this.drop;
    },
    readMessage(path) {
      this.$router.push({
        path: "/userInfo/empty",
        query: { page: path },
      });
    },
    async i18nSet(lang) {
      await this.$store.commit("user/SET_LANG", lang);
      await getServerLang(lang).then((res) => {
        this.$i18n.setLocaleMessage(
          "localeLang",
          Object.assign([], this.GLOBAL.langData[lang], res)
        )
      });
    },
    toggleNewItem() {
      this.notification = !this.notification;
    },
    getLiveData() {
      getLiveData().then(res => {
        this.operatingData = res.data?.operatingData ?? {
          "onlineMemberCount": undefined,
          "totalMonthDeposit": undefined,
          "totalMonthWithdrawal": undefined,
          "totalDayDeposit": undefined,
          "totalDayWithdrawal": undefined,
          "totalMemberWalletAmount": undefined,
        };
        this.casinoBalanceData = res.data?.casinoBalanceData ?? {
          "casinoBalance": undefined,
          "sportsBetAmount": undefined,
          "sportsBalance": undefined,
          "liveCasinoBalance": undefined,
        };
      })
    },
    toogleLiveData() {
      if (this.isDragging || this.onlineMember) {
        return;
      }

      this.expanded = !this.expanded;
      if (this.$refs.liveData) {
        this.$refs.liveData.classList.toggle('expanded', this.expanded);
      }
      this.$nextTick(this.getNavHeight);
    },
    handleMouseDown(e) {
      this.isDown = true;
      this.isDragging = false;
      this.startX = e.pageX - this.$refs.liveData.offsetLeft;
      this.scrollLeft = this.$refs.liveData.scrollLeft;
    },
    handleMouseLeave() {
      this.isDown = false;
    },
    handleMouseUp() {
      this.isDown = false;
      setTimeout(() => {
        this.isDragging = false;
      }, 0);
    },
    handleMouseMove(e) {
      if (!this.isDown) return;
      e.preventDefault();
      const x = e.pageX - this.$refs.liveData.offsetLeft;
      const walk = (x - this.startX) * 3;
      if (Math.abs(x - this.startX) > 5) { // 如果移動距離大於五 表示正在拖曳 停止展開
        this.isDragging = true;
      }
      this.$refs.liveData.scrollLeft = this.scrollLeft - walk;
    },
    getNavHeight() {
      this.SET_NAVHEIGHT(this.$refs.navbarDiv.offsetHeight);
    },
    handleResize() {
      this.width = window.innerWidth;
      return this.width < 700 ? '-5px' : '-15px'
    }
  },
};
</script>

<style lang="scss" scoped>
.hide {
  .hamburger:before {
    transform: rotate(150deg);
    width: 65% !important;
    left: -1px;
    margin-top: 15%;
  }

  .hamburger:after {
    transform: rotate(-150deg);
    width: 65% !important;
    left: -1px;
    margin-bottom: 11%;
  }
}

.navbarDiv {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 11;

  .navbar-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    top: 0;
    height: 70px;
    width: 100%;
    background-color: #749AAF;
    padding: 12px 16px;
    //box-shadow: 0px 3px 10px 0 rgba(0, 0, 0, 0.3);
    z-index: 10;

    .hamburger-box {
      position: relative;
      width: 24px;
      height: 54px;
      display: inline-block;
      margin-right: 20px;
      cursor: pointer;

      .hamburger {
        position: absolute;
        top: 50%;
        width: 100%;
        height: 2px;
        background-color: #D6E3EA;
        border-radius: 100px;
        transition-duration: 0.3s;
      }

      .hamburger:after {
        content: "";
        display: block;
        position: absolute;
        bottom: -7px;
        width: 100%;
        height: 2px;
        background-color: #D6E3EA;
        border-radius: 100px;
        transition-duration: 0.3s;
      }

      .hamburger:before {
        content: "";
        display: block;
        position: absolute;
        top: -7.15px;
        width: 100%;
        height: 2px;
        background-color: #D6E3EA;
        border-radius: 100px;
        transition-duration: 0.3s;
      }
    }

    .title {
      display: flex;
      align-items: center;
      margin-right: auto;

      p {
        margin: 0;
        font-size: 20px;
        font-weight: 600;

        span {
          color: #fff;
        }

        span:first-child {
          margin-right: 10px;
        }
      }
    }

    .logo {
      margin-right: auto;

      img {
        width: 85%;
      }
    }

    .user {
      display: flex;
      align-items: center;

      button {
        transition: 0.2s;
      }

      button:hover {
        background-color: #fff;
        border: 1px solid #000;
        color: #000 !important;
      }

      .new {
        position: relative;
        margin-right: 5px;

        .fa-bell {
          color: white;
          font-size: 20px;
          animation: shiny 1s linear infinite alternate;
          cursor: pointer;
        }

        .bell {
          display: flex;
          background-color: #fff;
          border-radius: 999px;
          width: 30px;
          height: 30px;
          justify-content: center;

          .bell-img {
            width: 16px;
            animation: rotate 0.2s linear infinite;
          }
        }

        .new-item {
          position: absolute;
          top: 105%;
          right: 0;
          display: flex;
          align-items: center;
          gap: 0 5px;
          padding: 5px 10px;
          background-color: rgba(0, 0, 0, 0.529);
          border: 2px solid #000;
          border-radius: 3px;
          transform-origin: top;
          transform: scaleY(0);
          transition-duration: 300ms;
          overflow: auto;

          &-k a {
            margin: 5px;
            position: relative;

            img {
              width: 40px;
            }

            span {
              background: linear-gradient(180deg, #FFCC81 0%, #CD7B00 100%);
              color: #000;
              border-radius: 999px;
              border: 1px solid #FFCC81;
              width: 20px;
              height: 20px;
              position: absolute;
              right: -5px;
              bottom: v-bind(handleResize());
              text-align: center;
              font-size: 13px;
            }
          }
        }

        .active {
          transform: scaleY(100%);
        }
      }

      .user-account {
        display: flex;
        justify-content: center;
        background-color: #fff;
        border-radius: 30px;
        padding: 5px 15px;
        color: #3D4F59;
        border: 2px solid #CD7B00;
        cursor: pointer;
      }
    }
  }

  .liveData {
    width: 100%;
    // margin: 10px auto auto auto;
    overflow-x: auto;
    /* 启用水平滚动 */
    -ms-overflow-style: none;
    /* 隐藏 IE 和 Edge 的滚动条 */
    scrollbar-width: none;
    /* 隐藏 Firefox 的滚动条 */
    white-space: nowrap;
    text-overflow: ellipsis;
    max-height: 3em;
    background-color: #3D4F59 !important;
    cursor: pointer;
    padding-top: 2px;

    label {
      background-color: #81ffdd;
      border-radius: 50px;
      color: #000;
      padding: 5px 15px;
      font-size: 15px;
      font-weight: bold;
      margin: 5px;
      cursor: pointer;
    }

    label:first-child {
      z-index: 12;
    }
  }

  .liveData::-webkit-scrollbar {
    display: none;
    /* 隐藏 Chrome、Safari 和 Opera 的滚动条 */
  }

  .liveData.expanded {
    white-space: normal;
    max-height: 1000px;
  }
}

@keyframes shiny {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes rotate {
  0% {
    transform: rotateZ(0deg);
  }

  25% {
    transform: rotateZ(20deg);
  }

  50% {
    transform: rotateZ(0deg);
  }

  75% {
    transform: rotateZ(-20deg);
  }

  100% {
    transform: rotateZ(0deg);
  }
}

@media screen and (max-width: 992px) {
  .navbar-block {
    .title {
      p {
        span:last-child {
          display: none;
        }
      }
    }

    .logo {
      display: none;
    }
  }
}

@media screen and (max-width: 650px) {
  .new-item {
    flex-wrap: wrap;
    right: 50% !important;
    transform: translateX(50%) scaleY(0) !important;
  }

  .active {
    transform: translateX(50%) scaleY(100%) !important;
  }
}

@media screen and (max-width: 500px) {
  .navbar-block {

    // padding: 12px 0;
    .user {
      top: 30px;
      // .new {
      //   display: flex;
      //   position: absolute;
      //   bottom: -40px;
      //   right: -30px !important;
      //   svg {
      //     margin: 0 10px;
      //     width: 20px !important;
      //     height: 20px !important;
      //     color: #fff;
      //   }
      // }
    }

    .title {
      display: none;

      span {
        line-height: 35px;
        font-size: 20px;
      }
    }
  }
}

.btn {
  color: #000 !important;
  border-color: #000 !important;

  &:hover {
    color: #ffffff !important;
    background-color: #6c757d !important;
  }
}

.choose {
  color: #ffffff !important;
  background-color: #6c757d !important;
}
</style>