var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "d-flex form-column align-items-center h-full",
      staticStyle: { width: "90%" },
    },
    [
      _c("div", { staticClass: "table-block-white-v2" }, [
        _c("table", [
          _c("thead", [
            _c("tr", [
              _c(
                "th",
                { staticStyle: { width: "100%" }, attrs: { colspan: "3" } },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$t(`${_vm.i18nPrefix}dialog.detail.memberActivity`)
                    )
                  ),
                ]
              ),
            ]),
          ]),
          _c("tbody", [
            _c("tr", [
              _c("td", [
                _vm._v(
                  " " + _vm._s(_vm.$t(`${_vm.i18nPrefix}recommend`)) + " "
                ),
              ]),
              _c("td", [
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.editChooice !== "referralCode",
                        expression: "editChooice !== 'referralCode'",
                      },
                    ],
                  },
                  [_vm._v(_vm._s(_vm.list.referralCode))]
                ),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.referralCode,
                      expression: "referralCode",
                    },
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.editChooice == "referralCode",
                      expression: "editChooice == 'referralCode'",
                    },
                  ],
                  attrs: { type: "text" },
                  domProps: { value: _vm.referralCode },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.referralCode = $event.target.value
                    },
                  },
                }),
              ]),
              _c("td", [
                _c(
                  "button",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.editChooice !== "referralCode",
                        expression: "editChooice !== 'referralCode'",
                      },
                    ],
                    staticClass: "edit-btn",
                    attrs: { disabled: _vm.editChooice !== undefined },
                    on: {
                      click: function ($event) {
                        return _vm.edit("referralCode")
                      },
                    },
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("default.edit")) + " ")]
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.editChooice == "referralCode",
                        expression: "editChooice == 'referralCode'",
                      },
                    ],
                  },
                  [
                    _c(
                      "button",
                      {
                        staticClass: "normal-btn",
                        on: {
                          click: function ($event) {
                            return _vm.confirm("referralCode")
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("default.confirm")) + " ")]
                    ),
                    _c(
                      "button",
                      {
                        staticClass: "delete-btn",
                        on: {
                          click: function ($event) {
                            return _vm.cancel("referralCode")
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("default.cancel")) + " ")]
                    ),
                  ]
                ),
              ]),
            ]),
            _c("tr", [
              _c("td", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(`${_vm.i18nPrefix}dialog.detail.referralCount`)
                    ) +
                    " "
                ),
              ]),
              _c("td", [_vm._v(" " + _vm._s(_vm.list.referralCount) + " ")]),
              _c("td"),
            ]),
            _c("tr", [
              _c("td", [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t(`${_vm.i18nPrefix}dialog.detail.referrer`)) +
                    " "
                ),
              ]),
              _c("td", [_vm._v(" " + _vm._s(_vm.list.referrer) + " ")]),
              _c("td"),
            ]),
            _c("tr", [
              _c("td", [_vm._v(" " + _vm._s(_vm.$t("title.luckydraw")) + " ")]),
              _c(
                "td",
                [
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to: {
                          path: "/campaign/luckydraw",
                          query: { bookmarkNum: 5 },
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("title.luckydraw")) + " ")]
                  ),
                ],
                1
              ),
              _c("td"),
            ]),
            _c("tr", [
              _c("td", [
                _vm._v(" " + _vm._s(_vm.$t("title.memberSignIn")) + " "),
              ]),
              _c(
                "td",
                [
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to: {
                          path: "/campaign/memberSignIn",
                          query: {
                            memberAccount: _vm.memberData.memberName,
                            bookmarkNum: 3,
                          },
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("title.memberSignIn")) + " ")]
                  ),
                ],
                1
              ),
              _c("td"),
            ]),
            _c("tr", [
              _c("td", [
                _vm._v(" " + _vm._s(_vm.$t("title.memberReferral")) + " "),
              ]),
              _c(
                "td",
                [
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to: {
                          path: "/campaign/memberReferral",
                          query: {
                            memberAccount: _vm.memberData.memberName,
                            bookmarkNum: 4,
                          },
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("title.memberReferral")) + " ")]
                  ),
                ],
                1
              ),
              _c("td"),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }