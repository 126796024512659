<template>
  <div>
    <!-- 1. 查詢條件"項目": Id=0 選擇所有 id =1, 主頁，"狀態": Id=0, 選擇所有, id =1
    啟用, id=2 關閉, "客戶端語言": id=1, 繁中 
    2. 創建及修改頁面的排序只能填數字
    3. 原來創建修改頁面的頁面綁定欄位可以選擇綁定優惠或頁面，改分兩個欄位去選擇,
    優惠綁定的選單由後端api提供, 預設值為無綁定id=0 4.頁面綁定欄位定義：id=0
    無綁定, id=1 體育，id=2視訊，id=3電子遊戲，id=4捕魚，id=5 賓果彩票，id=6
    棋牌，id=7電子競技，id=8優惠，id=9直播，id=10 App -->
    <div class="container-view">
      <button class="search-btn main-style-btn" @click="search()">{{ $t("default.search") }}</button>
<div class="header">
        <h5>{{ $t("title." + $route.meta.title) }}</h5>
        <div class="float-right">
          <button
            class="create-btn main-style-btn"
            @click="handleDialog(undefined, 'Create')"
            :disabled="
              !getUserinfo.permissions.includes(60701)
            "
          >
            {{ $t("default.create") }}
          </button>
        </div>
      </div>
      <div class="main">
        <div class="input-block">
          <label>
            {{ $t(`${i18nPrefix}subject`) }}
            <select v-model="searchForm.subject">
              <option v-for="list of subjects" :key="list.id" :value="list.id">
                {{ $t(list.name) }}
              </option>
            </select>
          </label>
          <label>
            {{ $t("default.status") }}
            <select v-model="searchForm.status">
              <option
                v-for="list of getStatusListAddDefault"
                :key="list.id"
                :value="list.id"
              >
                {{ $t(list.name) }}
              </option>
            </select>
          </label>
          <label>
            {{ $t(`${i18nPrefix}description`) }}
            <input type="text" v-model="searchForm.description"/>
          </label>
          <label>
            {{ $t(`${i18nPrefix}lang`) }}
            <select v-model="searchForm.lan">
              <option v-for="list of languages" :key="list.id" :value="list.lang">
                {{ $t(list.name) }}
              </option>
            </select>
          </label>
        </div>
      </div>
      <div class="advance-block">
        <button class="search-pc-btn main-style-btn px-5" @click="search()">{{ $t("default.search") }}</button>
      </div>
    </div>
    <div class="container-view">
      <div class="table-block">
        <table>
          <thead>
            <tr>
              <th></th>
              <th>{{ $t(`${i18nPrefix}subject`) }}</th>
              <th>{{ $t(`${i18nPrefix}lang`) }}</th>
              <th>{{ $t(`${i18nPrefix}description`) }}</th>
              <th>{{ $t("default.sort") }}</th>
              <th>{{ $t("default.status") }}</th>
              <th>{{ $t(`${i18nPrefix}createAccount`) }}</th>
              <th>{{ $t(`${i18nPrefix}createTime`) }}</th>
              <th>{{ $t(`${i18nPrefix}updateAccount`) }}</th>
              <th>{{ $t(`${i18nPrefix}updateTime`) }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="!tableList || tableList.length === 0">
              <td colspan="10">{{ $t("default.noData") }}</td>
            </tr>
            <tr v-for="list of tableList" :key="list.id">
              <td>
                <button
                  class="table-btn main-style-btn"
                  @click="handleDialog( list , 'Edit')"
                  :disabled="!getUserinfo.permissions.includes(60702)"
                >
                  {{ $t("default.edit") }}
                </button>
              </td>
              <td>{{ GLOBAL.i18nCheckName(subjects.find((data) => data.id === list.pageType)) }}</td>
              <td>{{ GLOBAL.i18nCheckName(languages.find((data) => list.language === data.lang)) }}</td>
              <td>{{ list.description }}</td>
              <td>{{ list.order }}</td>
              <td>{{ list.status === 1 ? $t("default.active") : $t("default.inactive") }}</td>
              <td>{{ list.createAccount }}</td>
              <td>{{ list.createTime }}</td>
              <td>{{ list.updateAccount }}</td>
              <td>{{ list.updateTime }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <Pagination
        v-if="totalCount > 0"
        :totalCount="totalCount"
        @handlePage="handlePage"
      />
    </div>
    <transition name="fade" mode="out-in">
      <component
        :is="currDialog"
        :i18nPrefix="i18nPrefix"
        v-bind="dialogData"
        @toggle="currDialog = undefined"
        @create="create"
        @edit="edit"
      ></component>
    </transition>
  </div>
</template>

<script>
import { searchModel } from "./model";
import Create from "./dialog/create";
import Edit from "./dialog/edit";
import Pagination from "@/layout/components/Pagination";
import handlePageUtils from "@/layout/mixins/handlePageUtils";
import handleDialog from "@/layout/mixins/handleDialog";
import { mapGetters } from "vuex";
import {
  bannerList,
  createBanner,
  updateBanner,
} from "@/api/features/banner";
import Message from "@/layout/components/Message";

export default {
  name: "Banner",
  data() {
    return {
      searchForm: _.cloneDeep(searchModel),
      subjects: [
        {
          id: 0,
          name: "features.banner.all",
        },
        {
          id: 1,
          name: "features.banner.home",
        },
      ],
      languages: [
        {
          id: 1,
          name: "features.banner.chinese",
          lang: "zh-TW"
        },
      ],
      i18nPrefix: "features.banner."
    };
  },
  mixins: [handlePageUtils, handleDialog],
  components: {
    Create,
    Edit,
    Pagination,
  },
  computed: {
    ...mapGetters(["getStatusListAddDefault", "getUserinfo"]),
  },
  created() {
    this.search();
  },
  methods: {
    search() {
      this.RESET_DIALOG();
      bannerList(this.searchForm).then((res) => {
        this.allData = res.data;
        this.dataSort('updateTime','time', true)
      });
    },
    async create(data) {
      await createBanner(data).then((res) => {
        if(res) this.search();
      });
    },
    async edit(data) {
      await updateBanner(data).then((res) => {
        if(res) this.search();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>