<template>
  <div class="app-main" :class="{ hide: sidebar }" :style="{ top: navbarTop }">
    <transition name="fade" mode="out-in">
      <router-view></router-view>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "AppMain",
  computed: {
    ...mapGetters(["sidebar", "navHeight"]),
    navbarTop() {
      return (this.navHeight - 22) + 'px!important';
    },
  },
  // watch: {
  //   navHeight(value) {
  //     this.navbarTop = (value - 23) + 'px';
  //     console.log(this.navbarTop);
  //   }
  // }
};
</script>

<style lang="scss" scoped>
.app-main {
  position: relative;
  height: 100%;
  // top: v-bind(navbarTop);
  left: 10%;
  transition-duration: 0.3s;
  width: calc(100% - 10%);
  min-height: calc(100vh - 70px);
  margin-bottom: 30px;
  padding: 30px;
}

@media screen and (max-width: 768px) {
  .app-main {
    padding: 15px 4px;
  }
}

@media screen and (min-width: 1000px) and (max-width: 1500px) {
  .app-main {
    left: 15%;
    width: calc(100% - 15%);
  }
}

@media screen and (max-width: 1000px) {
  .app-main {
    left: 0;
    width: 100%;
  }
}

.hide {
  left: 0;
  width: 100%;
}
</style>