import request from "@/utils/request";
import { getToken } from "@/utils/auth";

export function getCasinoChips(data) {
    return request({
        url: "/CasinoChips/Page",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function createCasinoChips(data) {
    return request({
        url: "/CasinoChips/Create",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function updateCasinoChips(data) {
    return request({
        url: "/CasinoChips/Update",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}
