import { login, logout, info } from "@/api/user";
import { getCompanyInfo } from "@/api/features/companySettings";
import { getToken, removeToken } from "@/utils/auth";
import Message from "@/layout/components/Message";
import store from "@/store";

const state = {
    token: getToken(),
    account: undefined,
    companyCode: undefined,
    userInfo: undefined,
    userDropDown: false,
    haveMail: false,
    mailLastId: 0,
    mailData: [],
    isRemeber: false,
    remeberData: {},
    memberCount: 0,
    lang: 'en',
    notification: false,
    timeZone: '+08:00',
    navHeight: 130,
}
const mutations = {
    SET_INFO(state, payload) {
        state.token = payload.token;
        state.account = payload.account;
        state.companyCode = payload.companyCode;
    },
    SET_USERINFO(state, payload) {
        state.userInfo = payload;
    },
    async RESET_INFO(state) {
        state.token = undefined;
        state.account = undefined;
        state.userInfo = undefined;
        await removeToken();
    },
    SET_USERDROPDOWN(state, payload) {
        state.userDropDown = payload;
    },
    CLOSE_USERDROPDOWN(state) {
        state.userDropDown = false
    },
    SET_MAIL(state, payload) {
        state.haveMail = payload;
    },
    SET_MAIL_LAST_ID(state, payload) {
        state.mailLastId = payload;
    },
    SET_MAIL_DATA(state, payload) {
        state.mailData = payload;
    },
    SET_REMEBER(state, payload) {
        state.isRemeber = payload;
    },
    SET_REMEBERDATA(state, payload) {
        state.remeberData = payload;
    },
    SET_MEMBERCOUNT(state, payload) {
        state.memberCount = payload;
    },
    SET_LANG(state, payload) {
        state.lang = payload;
    },
    SET_NOTIFICATION(state, payload) {
        state.notification = payload;
    },
    SET_TIMEZONE(state, payload) {
        state.timeZone = payload;
    },
    SET_NAVHEIGHT(state, payload) {
        state.navHeight = payload;
    }
}
const actions = {
    login({ commit }, userInfo) {
        const { account, password, companyCode } = userInfo;
        return new Promise((resolve, reject) => {
            login({ account: account.trim(), password, companyCode })
                .then(async (response) => {
                    commit("SET_REMEBERDATA", { account });
                    if (response) {
                        // const { data } = response;
                        // commit("SET_INFO", { token: data.token, account: account, companyCode: companyCode });
                        // setToken(data.token);
                        // await store.dispatch("user/info");
                        // commit("SET_MAIL_LAST_ID", 0);
                        resolve(response);
                    }
                    resolve(false);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    async info({ commit }) {
        info().then(async (res) => {
            await getCompanyInfo().then(Info => {
                commit("SET_USERINFO", Object.assign({}, res.data, Info.data))
                let timeZone = Info.data.timeZone
                commit("SET_TIMEZONE", (Math.sign(timeZone) > -1 ? '+' : '-') + ((timeZone < 10 && timeZone > -10) ? '0' + Math.abs(timeZone).toString() : Math.abs(timeZone)) + ':00')
            })
            // let permissions = InfoData.permissions.sort((a, b) => a - b).filter(data => data % 10000 === 0)[0]; //權限由小到大排序
            // let childrenPermission = InfoData.permissions.sort((a, b) => a - b).filter(data => data % permissions % 100 === 0 && data % permissions !== 0)[0];
            // var path = router.options.routes.find(x => x.meta.permission === permissions && x.meta.permission % 10000 === 0).path;
            // var childrenPath = router.options.routes.find(x => x.meta.permission === permissions).children
            //     .find(children => children.meta.permission === childrenPermission).path;
            // await store.dispatch("select_menu/getAllSelect", res.data);
            // router.push(path + "/" + childrenPath);
            location.reload()
        });
    },
    async logout({ commit }) {
        await logout().then(Message.success("登出成功", 1000));
        await commit("RESET_INFO");
        location.reload();
    },
    async companyInfo({ commit }) {
        info().then(async (res) => {
            await getCompanyInfo().then(Info => {
                commit("SET_USERINFO", Object.assign({}, res.data, Info.data))
            })
        });
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}