<template>
  <!-- 會員註冊資訊 -->
  <div class="d-flex form-column align-items-center h-full" style="width: 90%;">
    <div class="table-block-white-v2">
      <table>
        <thead>
          <tr>
            <th colspan="3" style="width: 100%;">{{ $t(`${i18nPrefix}dialog.detail.memberRegistry`) }}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              {{ $t(`${i18nPrefix}createFrom`) }}
            </td>
            <td>
              {{ list.creator }}
            </td>
            <td>
            </td>
          </tr>
          <tr>
            <td>
              {{ $t(`${i18nPrefix}agentFrom`) }}
            </td>
            <td>
              {{ list.agentAccount }}
            </td>
            <td>
              <button class="edit-btn" :disabled="editChooice !== undefined"
                @click="handleDialog(memberData, 'Agent')">{{
                $t("default.edit") }}</button>
            </td>
          </tr>
          <tr>
            <td>
              {{ $t("default.createTime") }}
            </td>
            <td>
              {{ list.createTime }}
            </td>
            <td>
            </td>
          </tr>
          <tr>
            <td>
              {{ $t(`${i18nPrefix}lastLoginTime`) }}
            </td>
            <td>
              {{ list.lastLoginTime }}
            </td>
            <td>
            </td>
          </tr>
          <tr>
            <td>
              {{ $t(`${i18nPrefix}lastEventTime`) }}
            </td>
            <td>
              {{ list.lastEventTime }}
            </td>
            <td>
            </td>
          </tr>
          <tr>
            <td>
              {{ $t(`${i18nPrefix}ip`) }}
            </td>
            <td>
              {{ list.lastLoginIp }}
            </td>
            <td>
            </td>
          </tr>
          <tr>
            <td>
              {{ $t(`${i18nPrefix}history`) }}
            </td>
            <td>
              <button class="table-btn main-style-btn mt-1" @click="handleDialog(memberData, 'LoginList')">
                {{ $t(`${i18nPrefix}loginList`) }}
              </button>
            </td>
            <td>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <transition name="fade" mode="out-in">
      <component :i18nPrefix="i18nPrefix" :is="currDialog" v-bind="dialogData" @agent="agent"
        @toggle="currDialog = undefined"></component>
    </transition>
  </div>
</template>

<script>
import { editAgent } from "@/api/member/member";
import Agent from "../agent";
import LoginList from "../loginList/loginList";

export default {
  name: "MemberRegistry",
  props: ['list', 'memberData', 'chooice', 'i18nPrefix'],
  data() {
    return {
      currDialog: undefined,
      dialogData: undefined,
      editChooice: undefined
    };
  },
  watch: {
    'chooice'(v) {
      this.editChooice = v;
    }
  },
  components: {
    Agent,
    LoginList
  },
  computed: {
  },
  created() {
  },
  methods: {
    handleDialog(list, dialog) {
      this.currDialog = dialog;
      this.dialogData = list;
    },
    init() {
      this.currDialog = undefined;
      this.dialogData = undefined;
      this.$emit('reload');
    },
    agent(data) {
      editAgent(data).then(res => {
        if (res) this.init();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/form.scss";

tr {
  td:first-of-type {
    width: 25%;
  }

  td:nth-of-type(2) {
    width: 50%;
  }

  td:last-of-type {
    width: 25%;
  }
}

.h-full {
  height: 100%;
}
</style>