<template>
    <!-- 會員身份資訊 -->
  <div class="d-flex form-column align-items-center h-full" style="width: 90%;">
    <div class="table-block-white-v2">
      <table>
        <thead>
          <tr>
            <th colspan="3" style="width: 100%;">{{ $t(`${i18nPrefix}dialog.detail.memberIdentity`) }}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              {{ $t("default.member") }}
            </td>
            <td>
              <span>{{ list.account }}</span>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>
              {{ $t(`${i18nPrefix}name`) }}
            </td>
            <td>
              <span v-show="editChooice !== 'memberName'">{{ list.memberName }}</span>
              <input type="text" v-model="memberName" v-show="editChooice == 'memberName'">
            </td>
            <td>
              <button class="edit-btn" :disabled="editChooice !== undefined" @click="edit('memberName')"
                v-show="editChooice !== 'memberName' && getUserinfo.permissions.includes(10102)">
                {{ $t("default.edit") }}
              </button>
              <div v-show="editChooice == 'memberName'">
                <button class="normal-btn" @click="confirm('memberName')">
                  {{ $t("default.confirm") }}
                </button>
                <button class="delete-btn" @click="cancel('memberName')">
                  {{ $t("default.cancel") }}
                </button>
              </div>
            </td>
          </tr>
          <tr v-if="getUserinfo.koreanStyleInterface">
            <td>
              {{ $t("default.nickName") }}
            </td>
            <td>
              <span v-show="editChooice !== 'nickName'">{{ list.nickName }}</span>
              <input type="text" v-model="nickName" v-show="editChooice == 'nickName'">
            </td>
            <td>
              <button class="edit-btn" :disabled="editChooice !== undefined" @click="edit('nickName')"
                v-show="editChooice !== 'nickName' && getUserinfo.permissions.includes(10102)">
                {{ $t("default.edit") }}
              </button>
              <div v-show="editChooice == 'nickName'">
                <button class="normal-btn" @click="confirm('nickName')">
                  {{ $t("default.confirm") }}
                </button>
                <button class="delete-btn" @click="cancel('nickName')">
                  {{ $t("default.cancel") }}
                </button>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              {{ $t(`${i18nPrefix}dialog.password`) }}
            </td>
            <td>
              <input type="text" v-model="password" v-show="editChooice == 'password'">
            </td>
            <td>
              <button class="edit-btn" :disabled="editChooice !== undefined" @click="edit('password')"
                v-show="editChooice !== 'password' && getUserinfo.permissions.includes(10102)">
                {{ $t("default.edit") }}
              </button>
              <div v-show="editChooice == 'password'">
                <button class="normal-btn" @click="confirm('password')">
                  {{ $t("default.confirm") }}
                </button>
                <button class="delete-btn" @click="cancel('password')">
                  {{ $t("default.cancel") }}
                </button>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              {{ $t(`${i18nPrefix}dialog.withdrawalPassword`) }}
            </td>
            <td>
              <input type="text" v-model="withdrawalPassword" v-show="editChooice == 'withdrawalPassword'">
            </td>
            <td>
              <button class="edit-btn" :disabled="editChooice !== undefined" @click="edit('withdrawalPassword')"
                v-show="editChooice !== 'withdrawalPassword' && getUserinfo.permissions.includes(10102)">
                {{ $t("default.edit") }}
              </button>
              <div v-show="editChooice == 'withdrawalPassword'">
                <button class="normal-btn" @click="confirm('withdrawalPassword')">
                  {{ $t("default.confirm") }}
                </button>
                <button class="delete-btn" @click="cancel('withdrawalPassword')">
                  {{ $t("default.cancel") }}
                </button>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              {{ $t(`${i18nPrefix}mail`) }}
            </td>
            <td>
              <span v-show="editChooice !== 'email'">{{ list.email }}</span>
              <input type="text" v-model="email" v-show="editChooice == 'email'">
            </td>
            <td>
              <button class="edit-btn" :disabled="editChooice !== undefined" @click="edit('email')"
                v-show="editChooice !== 'email' && getUserinfo.permissions.includes(10102)">
                {{ $t("default.edit") }}
              </button>
              <div v-show="editChooice == 'email'">
                <button class="normal-btn" @click="confirm('email')">
                  {{ $t("default.confirm") }}
                </button>
                <button class="delete-btn" @click="cancel('email')">
                  {{ $t("default.cancel") }}
                </button>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              {{ $t(`${i18nPrefix}line`) }}
            </td>
            <td>
              <span v-show="editChooice !== 'socialId'">{{ list.socialId }}</span>
              <input type="text" v-model="socialId" v-show="editChooice == 'socialId'">
            </td>
            <td>
              <button class="edit-btn" :disabled="editChooice !== undefined" @click="edit('socialId')"
                v-show="editChooice !== 'socialId' && getUserinfo.permissions.includes(10102)">
                {{ $t("default.edit") }}
              </button>
              <div v-show="editChooice == 'socialId'">
                <button class="normal-btn" @click="confirm('socialId')">
                  {{ $t("default.confirm") }}
                </button>
                <button class="delete-btn" @click="cancel('socialId')">
                  {{ $t("default.cancel") }}
                </button>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              {{ $t("default.status") }}
            </td>
            <td>
              <div class="slide-block" style="margin: auto"
                @click="list.status = (editChooice !== 'status') ? list.status : !list.status"
                :class="{ 'slide-block-on': list.status }">
                <div class="slide" :class="{ 'slide-on': list.status }">
                  <label style="display: none"></label>
                  <input type="checkbox" v-model="list.status" style="display: none" />
                </div>
              </div>
            </td>
            <td>
              <button class="edit-btn" :disabled="editChooice !== undefined" @click="edit('status')"
                v-show="editChooice !== 'status' && getUserinfo.permissions.includes(10102)">{{ $t("default.edit")
                }}</button>
              <div v-show="editChooice == 'status'">
                <button class="normal-btn" @click="confirm('status')">
                  {{ $t("default.confirm") }}
                </button>
                <button class="delete-btn" @click="cancel('status')">
                  {{ $t("default.cancel") }}
                </button>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              {{ $t(`${i18nPrefix}new`) }}
            </td>
            <td>
              <div class="slide-block" style="margin: auto"
                @click="list.isNew = (editChooice !== 'isNew') ? list.isNew : !list.isNew"
                :class="{ 'slide-block-on': list.isNew }">
                <div class="slide" :class="{ 'slide-on': list.isNew }">
                  <label style="display: none"></label>
                  <input type="checkbox" v-model="list.isNew" style="display: none" />
                </div>
              </div>
            </td>
            <td>
              <button class="edit-btn" :disabled="editChooice !== undefined" @click="edit('isNew')"
                v-show="editChooice !== 'isNew' && getUserinfo.permissions.includes(10102)">{{ $t("default.edit")
                }}</button>
              <div v-show="editChooice == 'isNew'">
                <button class="normal-btn" @click="confirm('isNew')">
                  {{ $t("default.confirm") }}
                </button>
                <button class="delete-btn" @click="cancel('isNew')">
                  {{ $t("default.cancel") }}
                </button>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              {{ $t("default.tag") }}
            </td>
            <td>
              <Tags v-if="list.tags" :tags="list.tags" :tagList="tagList" :isColumn="true"></Tags>
            </td>
            <td>
              <button class="edit-btn" :disabled="editChooice !== undefined"
                @click="handleDialog({ id: list.id, Tags: tagList, haveTag: list.tags }, 'EditTag')"
                v-show="getUserinfo.permissions.includes(10113)">{{ $t("default.edit") }}</button>
            </td>
          </tr>
          <tr>
            <td>
              {{ $t(`${i18nPrefix}bank`) }}
            </td>
            <td class="banks-td">
              <div>
                <div class="banks-div">
                  <div class="banks-div-item" v-for="(bankDetail, index) of list.banks" :key="index">
                    <button class="bank-btn"
                      :class="bankDetail.verifyStatus === 1 ? 'bank-btn-verify' : 'bank-btn-close'"
                      @click="handleDialog({ bankDetail: bankDetail, id: list.id }, 'EditBank')">
                      {{ bankDetail.bankType !== 9 ? bankDetail.bankName ? bankDetail.bankName :
                        GLOBAL.i18nCheckName(getBankTypeList.find((data) => {
                          return data.id === bankDetail.bankType
                        })) : bankDetail.walletName
                      }}
                      {{ (bankDetail.bankBranch ? "(" + bankDetail.bankBranch + ")" : "") }}<br>
                      {{ (bankDetail.bankType !== 9 ? bankDetail.bankAccountName : bankDetail.currencyName)
                      }}<br>
                      {{ bankDetail.bankAccountNumber }}
                    </button>
                  </div>
                </div>
              </div>
            </td>
            <td>
              <button class="table-btn main-style-btn" @click="handleDialog(list, 'CreateBank')"
                    v-show="getUserinfo.permissions.includes(10104)">{{ $t(`${i18nPrefix}createBank`) }}</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <transition name="fade" mode="out-in">
      <component :stars="getStars" :i18nPrefix="i18nPrefix" :is="currDialog" v-bind="dialogData" @editTag="editTag"
        @editBank="editBank" @createBank="createBank" @deleteBank="deleteBank" @toggle="currDialog = undefined">
      </component>
    </transition>
  </div>
</template>

<script>
import {
  updateName,
  updateNickName,
  updatePassword,
  updateWithdrawalPassword,
  updateSocialid,
  updateEmail,
  addTag,
  deleteTag,
  updateStatus,
  updateIsNew,
  createBank,
  editBank,
  deleteBank,
} from "@/api/member/member";
import Tags from "@/layout/components/tags";
import EditTag from "../editTag";
import CreateBank from "../bank/create-bank";
import EditBank from "../bank/edit-bank";
import { mapGetters } from "vuex";

export default {
  name: "MemberIdentity",
  props: ['list', 'chooice', 'i18nPrefix'],
  data() {
    return {
      memberName: undefined,
      nickName: undefined,
      password: undefined,
      withdrawalPassword: undefined,
      email: undefined,
      socialId: undefined,
      status: undefined,
      isNew: undefined,
      editChooice: undefined,
      currDialog: undefined,
      dialogData: undefined
    };
  },
  watch: {
    'chooice'(v) {
      this.editChooice = v;
    },
    "nickName"(val, oldVal) {
      var reg = /[^\w\s\u3131-\uD79D]/g;
      if (reg.test(val) == true || val?.length > 50) {
        this.editModel.nickName = (oldVal ?? '');
      }
    },
  },
  components: {
    Tags,
    EditTag,
    CreateBank,
    EditBank,
  },
  computed: {
    ...mapGetters(["getStars", "tagList", "getBankTypeList", "getUserinfo"]),
  },
  created() {
  },
  methods: {
    edit(k) {
      this.editChooice = k;

      switch (k) {
        case 'memberName':
          this.memberName = this.list.memberName;
          break;
        case 'nickName':
          this.nickName = this.list.nickName;
          break;
        case 'email':
          this.email = this.list.email;
          break;
        case 'socialId':
          this.socialId = this.list.socialId;
          break;
        case 'status':
          this.status = this.list.status;
          break;
        case 'isNew':
          this.isNew = this.list.isNew;
          break;
      }

      this.$emit('editting', k);
    },
    async confirm(k) {
      let result = false;
      switch (k) {
        case 'memberName':
          await updateName({ id: this.list.id, name: this.memberName }).then(res => {
            if (res) result = true;
          });
          break;
          case 'nickName':
        await updateNickName({ id: this.list.id, nickName: this.nickName }).then(res => {
            if (res) result = true;
          });
          break;
        case 'password':
          await updatePassword({ id: this.list.id, password: this.password }).then(res => {
            if (res) result = true;
          });
          break;
        case 'withdrawalPassword':
          await updateWithdrawalPassword({ id: this.list.id, withdrawalPassword: this.withdrawalPassword }).then(res => {
            if (res) result = true;
          });
          break;
        case 'email':
          await updateEmail({ id: this.list.id, email: this.email }).then(res => {
            if (res) result = true;
          });
          break;
        case 'socialId':
          await updateSocialid({ id: this.list.id, socialId: this.socialId }).then(res => {
            if (res) result = true;
          });
          break;
        case 'status':
          await updateStatus({ id: this.list.id, status: this.list.status ? 1 : 2 }).then(res => {
            if (res) result = true;
          });
          break;
        case 'isNew':
          await updateIsNew({ id: this.list.id, isNew: this.list.isNew ? 1 : 2 }).then(res => {
            if (res) result = true;
          });
          break;
      }

      if (result) this.init();
    },
    cancel(k) {
      this.editChooice = undefined;

      switch (k) {
        case 'memberName':
          this.memberName = undefined;
          break;
        case 'nickName':
          this.nickName = undefined;
          break;
        case 'password':
          this.password = undefined;
          break;
        case 'withdrawalPassword':
          this.withdrawalPassword = undefined;
          break;
        case 'email':
          this.email = undefined;
          break;
        case 'socialId':
          this.socialId = undefined;
          break;
        case 'status':
          this.list.status = this.status;
          break;
        case 'isNew':
          this.list.isNew = this.isNew;
          break;
      }

      this.$emit('editting', undefined);
    },
    init() {
      this.editChooice = undefined;
      this.currDialog = undefined;
      this.dialogData = undefined;

      this.$emit('editting', undefined);
      this.$emit('reload');
    },
    handleDialog(list, dialog) {
      this.currDialog = dialog;
      this.dialogData = list;
    },
    editTag(data) {
      if (data.type === "add") {
        addTag(data.data).then(res => {
          if (res) this.init();
        });
        return;
      }

      deleteTag(data.data).then(res => {
        if (res) this.init();
      });
    },
    createBank(data) {
      createBank(data).then(res => {
        if (res) this.init();
      });
    },
    editBank(data) {
      editBank(data).then(res => {
        if (res) this.init();
      });
    },
    deleteBank(data) {
      deleteBank({ id: data }).then(res => {
        if (res) this.init();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/form.scss";

input {
  text-align: center !important;
}

tr {
  td:first-of-type {
    width: 25%;
  }

  td:nth-of-type(2) {
    width: 50%;
  }

  td:last-of-type {
    width: 25%;
  }
}

.tag-column {
  flex-direction: row !important;
  justify-content: center !important;

  span {
    width: 30%;
  }
}

.banks-td {
  // width: 10%;
  max-width: 350px;

  .banks-div {
    width: 100%;
    display: flex;
    flex-direction: column;
    max-height: 110px;
    overflow-y: auto;
    margin: auto;

    .banks-div-item {
      display: flex;
      align-items: center;
      justify-content: space-between;

      button {
        width: 100%;
        color: #fff;
        // border: 1px solid #e4e4e4;

        // &:hover {
        //   background-color: #e4e4e4;
        // }
      }

      .bank-btn-verify {
        border-radius: 4px;
        border: 1px solid #638110;
        background-color: #779C0C;
      }

      .bank-btn-close {
        border-radius: 4px;
        border: 1px solid #950900;
        background-color: #950900;
      }
    }
  }
}

.h-full {
  height: 100%;
}
</style>