<template>
  <div>
    <div class="bookmark-box">
      <div class="bookmark-view">
        <div
          v-for="list of bookmarkColumn"
          :key="list.id"
          @click="bookmark(list.id)"
          :class="{ 'chooice': bookmarkNum == list.id}"
        >{{ list.name }}</div>
      </div>
    </div>
    <div>
      <div class="container-view">
        <button
          v-if="bookmarkNum == 4 || bookmarkNum == 5"
          class="search-btn main-style-btn"
          @click="search()"
        >{{ $t("default.search") }}</button>
        <div class="header">
          <h5>{{ $t(`${i18nPrefix}luckydraw`) }}</h5>
          <div class="float-right">
            <button
              v-if="bookmarkNum == 1"
              class="create-btn main-style-btn"
              @click="handleDialog(undefined, 'Edit')"
            >{{ $t("default.create") + " " }}{{ $t(`${i18nPrefix}template`) }}</button>
            <button
              v-else-if="bookmarkNum == 3"
              class="create-btn main-style-btn"
              @click="handleDialog(undefined, 'Style')"
            >{{ $t("default.create") + " " }}{{ $t(`${i18nPrefix}style`) }}</button>
            <button
              v-else-if="bookmarkNum == 4"
              class="create-btn main-style-btn"
              @click="handleDialog(undefined, 'Ticket')"
            >{{ $t(`${i18nPrefix}ticket`) }}</button>
          </div>
        </div>
        <div class="main">
          <div class="input-block" v-show="bookmarkNum == 4 || bookmarkNum == 5">
            <label v-show="bookmarkNum == 4">
              {{ $t("default.member") }}
              <input type="text" v-model="searchForm.memberAccount" />
            </label>
            <label v-show="bookmarkNum == 4">
              {{ $t('default.startTime') }}
              <date-picker v-model="startTime" format="YYYY-MM-DD" value-type="format" type="date"></date-picker>
            </label>
            <label v-show="bookmarkNum == 4">
              {{ $t('default.endTime') }}
              <date-picker v-model="endTime" format="YYYY-MM-DD" value-type="format" type="date"></date-picker>
            </label>
            <label v-show="bookmarkNum == 4"></label>
            <label>
              {{ $t(`${i18nPrefix}date`) }}
              <select v-model="searchForm.id">
                <option :value="undefined" v-show="bookmarkNum == 4">{{ $t('default.none') }}</option>
                <option v-for="d of periodList" :value="d.id" :key="d.id">{{ d.name }}</option>
              </select>
            </label>
          </div>
          <div v-if="bookmarkNum == 4 || bookmarkNum == 5" class="advance-block">
            <button
              class="search-pc-btn main-style-btn px-5" 
              @click="search()"
            >
              {{ $t("default.search") }}
            </button>
          </div>
        </div>
      </div>
      <div class="container-view">
        <div class="total-block-v2 row-4" v-show="bookmarkNum == 5">
          <div class="total-block-v2-item">
            <div class="total-block-v2-item-left">{{ $t(`${i18nPrefix}total.totalRecipientCount`) }}:</div>
            <div class="total-block-v2-item-right">{{ total.totalRecipientCount }}</div>
          </div>
          <div class="total-block-v2-item">
            <div class="total-block-v2-item-left">{{ $t(`${i18nPrefix}total.totalRedemptionCount`) }}:</div>
            <div class="total-block-v2-item-right">{{ total.totalRedemptionCount }}</div>
          </div>
          <div class="total-block-v2-item">
            <div class="total-block-v2-item-left">{{ $t(`${i18nPrefix}total.totalPayoutAmount`) }}:</div>
            <div class="total-block-v2-item-right">{{ total.totalPayoutAmount }}</div>
          </div>
          <div class="total-block-v2-item">
            <div class="total-block-v2-item-left">{{ $t(`${i18nPrefix}total.totalRegisterCount`) }}:</div>
            <div class="total-block-v2-item-right">{{ total.totalRegisterCount }}</div>
          </div>
          <div class="total-block-v2-item">
            <div class="total-block-v2-item-left">{{ $t(`${i18nPrefix}total.totalValidBetAmount`) }}:</div>
            <div class="total-block-v2-item-right">{{ total.totalValidBetAmount }}</div>
          </div>
          <div class="total-block-v2-item">
            <div class="total-block-v2-item-left">{{ $t(`${i18nPrefix}total.totalMemberDepositCount`) }}:</div>
            <div class="total-block-v2-item-right">{{ total.totalMemberDepositCount }}</div>
          </div>
          <div class="total-block-v2-item">
            <div class="total-block-v2-item-left">{{ $t(`${i18nPrefix}total.totalDepositCount`) }}:</div>
            <div class="total-block-v2-item-right">{{ total.totalDepositCount }}</div>
          </div>
          <div class="total-block-v2-item">
            <div class="total-block-v2-item-left">{{ $t(`${i18nPrefix}total.totalDepositAmount`) }}:</div>
            <div class="total-block-v2-item-right">{{ total.totalDepositAmount }}</div>
          </div>
        </div>
        <div class="table-block">
          <TreeTable ref="treeTable" :list="allData" :column="tableColumn">
            <!-- 操作按鈕 -->
            <template v-slot:column_CampaignSetting="props">
              <button
                class="normal-btn"
                @click="handleDialog({ editData: props }, 'Edit')"
              >{{ $t("default.edit") }}</button>
              <button
                class="delete-btn"
                @click="handleDeleteSetting(props.id)"
              >{{ $t("default.delete") }}</button>
            </template>
            <template v-slot:column_CampaignLuckyDraw="props">
              <button
                class="normal-btn"
                @click="handleDialog({ isCampaign: true , detail: props.status == 1 ? undefined : true, editData: props }, 'Edit')"
                :disabled="!getUserinfo.permissions.includes(170102)"
              >{{ props.status == 1 && new Date(props.endTime +  $store.getters.timeZone) >  GLOBAL.getNow() ? $t("default.edit") : $t("default.detail") }}</button>
              <button 
                v-show="props.status !== 3 && new Date(props.endTime +  $store.getters.timeZone) >  GLOBAL.getNow()"
                :class="{ 'btn-orange': props.status == 1, 'delete-btn': props.status == 2 }"
                @click="props.status == 1 ? release(props, 2) : handleDeleteLuckyDraw(props)"
              >{{ props.status == 1 ? $t(`${i18nPrefix}release`) : $t('default.inactive') }}</button>
            </template>
            <template v-slot:column_CampaignLuckyDrawStyle="props">
              <button
                class="normal-btn"
                @click="handleDialog({ editData: props }, 'Style')"
              >{{ $t("default.edit") }}</button>
            </template>
            <!-- 活動期別 -->
            <template
              v-slot:column_date="props"
            >{{ `${props.id}- ${props.startTime}-${props.endTime}` }}</template>
            <!-- 狀態 -->
            <template v-slot:column_status="props">
              {{ bookmarkNum == 2
              ? new Date(props.endTime + $store.getters.timeZone) > GLOBAL.getNow() ? GLOBAL.i18nCheckName(luckyDrawStatus.find( d => d.id == props.status))
              : GLOBAL.i18nCheckName(getStatusList[1])
              : GLOBAL.i18nCheckName(getStatusList.find( d => d.id == props.status)) }}
            </template>
            <!-- 補發期別 -->
            <template
              v-slot:column_campaignLuckyDrawListId="props"
            >{{ GLOBAL.i18nCheckName(periodList.find( d => d.id == props.campaignLuckyDrawListId)) }}</template>
            <!-- 查詢操作記錄 -->
            <template v-slot:column_updateUser="props">
              {{ bookmarkNum !== 2 ? props.updateUser : '' }}
              <button
                @click="getProcess(props.id)"
                class="table-btn main-style-btn"
                v-show="bookmarkNum == 2"
              >{{ $t("default.processAccount") }}</button>
            </template>
            <!-- 樣式圖片 -->
            <template v-slot:column_imageUrl="props">
              <span
                class="example-tag"
                v-show="props.imageUrl"
                @click="handleDialog({image: props.imageUrl + v}, 'Photo')"
                :title="props.imageUrl"
              >image</span>
            </template>
          </TreeTable>
        </div>
        <serverPagination
          v-if="bookmarkNum == 4 || bookmarkNum == 5"
          :page="page"
          @ServerPageUtils="search"
          @changeTake="changeTake"
        ></serverPagination>
      </div>
    </div>
    <transition name="fade" mode="out-in">
      <component
        :is="currDialog"
        :i18nPrefix="i18nPrefix"
        :starList="getStars"
        :tagList="tagList"
        :agentList="getAgentList"
        :periodType="periodType"
        :prizeType="prizeType"
        :periodList="periodList"
        v-bind="dialogData"
        @stylebookmark="bookmarkNum = 3"
        @toggle="currDialog = undefined"
        @search="search"
      ></component>
    </transition>
    <Alert
      v-if="alertMsg"
      :message="alertMsg"
      @toggle="alertMsg = undefined"
      @doIt="deleteFunction"
    />
    <Process
      v-if="processData"
      :processTitle="processTitle"
      :processData="processData"
      @toggle="processData = undefined"
    >
      <template v-slot:oldValue_Status="props">
        {{ GLOBAL.i18nCheckName(
        luckyDrawStatus.find(
        (data) => props.Status === data.id
        )) }}
      </template>
      <template v-slot:newValue_Status="props">
        {{ GLOBAL.i18nCheckName(
        luckyDrawStatus.find(
        (data) => props.Status === data.id
        )) }}
      </template>
    </Process>
  </div>
</template>

<script>
import {
  selectMenu,
  getCampaignSetting,
  deleteCampaignSetting,
  getCampaignLuckyDrawList,
  getCampaignLuckyDrawProcess,
  updateCampaignLuckyDraw,
  getCampaignLuckyDrawStyle,
  getCampaignLuckyDrawTicket,
  getReport,
  reportTotal
} from "@/api/campaign/luckydraw";
import { searchModel } from "./model";
import Edit from "./dialog/edit";
import Style from "./dialog/style";
import Ticket from "./dialog/ticket";
import Photo from "@/layout/components/photo";
import Alert from "@/layout/components/Alert";
import handleDialog from "@/layout/mixins/handleDialog";
import Process from "@/layout/components/Process";
import { joinT } from "@/utils/transDateUtils";
import TreeTable from "@/layout/components/TreeTable";
import { mapGetters } from "vuex";
import serverPagination from "@/layout/components/serverPagination";
import handleServerPageUtils from "@/layout/mixins/handleServerPageUtils";

export default {
  name: "LuckyDraw",
  data() {
    return {
      searchForm: _.cloneDeep(searchModel),
      allData: [],
      bookmarkNum: 1,
      periodType: [],
      prizeType: [],
      luckyDrawStatus: {},
      alertMsg: "",
      periodList: [],
      deleteData: undefined,
      processData: undefined,
      startTime: undefined,
      endTime: undefined,
      total: {
        totalRecipientCount: 0,
        totalRedemptionCount: 0,
        totalPayoutAmount: 0,
        totalRegisterCount: 0,
        totalValidBetAmount: 0,
        totalMemberDepositCount: 0,
        totalDepositCount: 0,
        totalDepositAmount: 0
      },
      v: "?v=" + new Date().getTime(),
      i18nPrefix: "campaign.luckydraw."
    };
  },
  components: {
    Edit,
    Style,
    Ticket,
    Alert,
    Photo,
    Process,
    TreeTable,
    serverPagination
  },
  mixins: [handleDialog, handleServerPageUtils],
  computed: {
    ...mapGetters([
      "getUserinfo",
      "getAgentListAddDefault",
      "getStars",
      "tagList",
      "getAgentList",
      "getStatusList"
    ]),
    tableColumn() {
      switch (this.bookmarkNum) {
        case 1:
          return [
            {
              key: "CampaignSetting",
              name: "",
              width: 80
            },
            {
              key: "name",
              name: this.$t(`${this.i18nPrefix}template`)
            },
            {
              key: "createUser",
              name: this.$t(`${this.i18nPrefix}createUser`),
              isText: true
            },
            {
              key: "createTime",
              name: this.$t(`${this.i18nPrefix}createTime`)
            },
            {
              key: "updateUser",
              name: this.$t(`${this.i18nPrefix}updateUser`),
              isText: true
            },
            {
              key: "updateTime",
              name: this.$t(`${this.i18nPrefix}updateTime`)
            }
          ];
        case 2:
          return [
            {
              key: "CampaignLuckyDraw",
              name: "",
              width: 80
            },
            {
              key: "name",
              name: this.$t(`${this.i18nPrefix}campaignName`)
            },
            {
              key: "date",
              name: this.$t(`${this.i18nPrefix}date`)
            },
            {
              key: "status",
              name: this.$t("default.status")
            },
            {
              key: "updateUser",
              name: this.$t(`${this.i18nPrefix}updateUser`),
              isText: true
            },
            {
              key: "updateTime",
              name: this.$t(`${this.i18nPrefix}updateTime`)
            }
          ];
        case 3:
          return [
            {
              key: "CampaignLuckyDrawStyle",
              name: "",
              width: 80
            },
            {
              key: "name",
              name: this.$t(`${this.i18nPrefix}style`)
            },
            {
              key: "imageUrl",
              name: "Image"
            },
            {
              key: "status",
              name: this.$t("default.status")
            },
            {
              key: "createUser",
              name: this.$t(`${this.i18nPrefix}createUser`),
              isText: true
            },
            {
              key: "createTime",
              name: this.$t(`${this.i18nPrefix}createTime`)
            },
            {
              key: "updateUser",
              name: this.$t(`${this.i18nPrefix}updateUser`),
              isText: true
            },
            {
              key: "updateTime",
              name: this.$t(`${this.i18nPrefix}updateTime`)
            }
          ];
        case 4:
          return [
            {
              key: "memberAccount",
              name: this.$t("default.member"),
              isText: true
            },
            {
              key: "campaignLuckyDrawListId",
              name: this.$t(`${this.i18nPrefix}date`)
            },
            {
              key: "currentTicketCount",
              name: this.$t(`${this.i18nPrefix}totalTicketCount`)
            },
            {
              key: "totalTicketCount",
              name: this.$t(`${this.i18nPrefix}currentTicketCount`)
            },
            {
              key: "remark",
              name: this.$t("default.remark")
            },
            {
              key: "createUser",
              name: this.$t(`${this.i18nPrefix}createUser`)
            },
            {
              key: "createTime",
              name: this.$t(`${this.i18nPrefix}createTime`)
            }
          ];
        case 5:
          return [
            {
              key: "memberAccount",
              name: this.$t("default.member"),
              isText: true
            },
            {
              key: "createTime",
              name: this.$t(`${this.i18nPrefix}createTime`)
            },
            {
              key: "prizeName",
              name: this.$t(`${this.i18nPrefix}dialog.prizeName`)
            },
            {
              key: "wageringRate",
              name: this.$t(`${this.i18nPrefix}dialog.wageringRate`)
            }
          ];
      }
    },
    bookmarkColumn() {
      return [
        {
          id: 1,
          name: this.$t(`${this.i18nPrefix}template`)
        },
        {
          id: 2,
          name: this.$t("default.search")
        },
        {
          id: 3,
          name: this.$t(`${this.i18nPrefix}style`)
        },
        {
          id: 4,
          name: this.$t(`${this.i18nPrefix}currentTicket`)
        },
        {
          id: 5,
          name: this.$t(`${this.i18nPrefix}effectAnalysis`)
        }
      ];
    },
    processTitle() {
      return [
        {
          key: "Status",
          name: this.$t("default.status")
        }
      ];
    }
  },
  async created() {
    await selectMenu().then(res => {
      this.periodType = res.data.periodType.map(data => {
        return { id: data.id, name: "PeriodType." + data.name };
      });
      this.prizeType = res.data.prizeType.map(data => {
        return { id: data.id, name: "PrizeType." + data.name };
      });
      this.luckyDrawStatus = res.data.status.map(data => {
        return { id: data.id, name: "LuckyDrawStatus." + data.name };
      });
    });
    await getCampaignLuckyDrawList({
      status: 0
    }).then(res => {
      this.periodList = res.data.map(d => {
        return {
          id: d.id,
          name: `${d.id} - ${d.startTime} - ${d.endTime}`
        };
      });
      this.searchForm.id = this.periodList[0]?.id;
    });
    if (this.$route.query?.bookmarkNum) {
      this.bookmarkNum = this.$route.query?.bookmarkNum;
    }
    await this.search();
  },
  watch: {
    startTime(val) {
      this.searchForm.id = val !== undefined ? undefined : this.searchForm.id;
    },
    endTime(val) {
      this.searchForm.id = val !== undefined ? undefined : this.searchForm.id;
    },
    "searchForm.id"(val) {
      this.startTime = val !== undefined ? undefined : this.startTime;
      this.endTime = val !== undefined ? undefined : this.endTime;
    },
    bookmarkNum(val) {
      if (val == 5) {
        this.searchForm.id = this.periodList[0]?.id;
      }
    }
  },
  methods: {
    async search(page = false) {
      this.closeDialog();
      this.searchForm.page.take = this.take * 1;
      this.searchForm.page.skip = this.take * (page ? page - 1 : 0);
      switch (this.bookmarkNum) {
        case 1:
          return getCampaignSetting({
            discountType: 34,
            discountCategory: 4
          }).then(res => {
            this.allData = res.data;
          });
        case 2:
          return getCampaignLuckyDrawList({
            status: 0
          }).then(res => {
            this.allData = res.data;
          });
        case 3:
          return getCampaignLuckyDrawStyle().then(res => {
            this.allData = res.data;
          });
        case 4:
          this.searchForm.startTime = this.startTime
            ? joinT(
                this.startTime + this.GLOBAL.startHour + this.GLOBAL.timeZone
              )
            : undefined;
          this.searchForm.endTime = this.endTime
            ? joinT(this.endTime + this.GLOBAL.endHour + this.GLOBAL.timeZone)
            : undefined;
          await getCampaignLuckyDrawTicket(this.searchForm).then(res => {
            this.allData = res.data.list;
            this.page = res.data.page;
          });
          return;
        case 5:
          if (!page) {
            await reportTotal(this.searchForm).then(res => {
              this.total = res.data;
            });
          }
          await getReport(this.searchForm).then(res => {
            this.allData = res.data.list;
            this.page = res.data.page;
          });
          return;
      }
    },
    // 發布
    async release(data, status) {
      let formData = new FormData();

      data.status = status;
      Object.entries(data).forEach(([key, value]) => {
        if (value) formData.append(key, value);
      });

      await updateCampaignLuckyDraw(formData).then(res => {
        if (res) this.search();
      });
    },
    // 分頁
    bookmark(data) {
      this.allData = undefined;
      this.bookmarkNum = data;
      if (data == 5) return;
      this.search();
    },
    // 刪除範本
    handleDeleteSetting(id) {
      this.alertMsg = this.$t("default.delMsg");
      this.deleteData = { id };
    },
    handleDeleteLuckyDraw(data) {
      this.alertMsg = this.$t(`${this.i18nPrefix}alertMsg`);
      this.deleteData = data;
    },
    deleteFunction() {
      this.alertMsg = "";
      if (this.bookmarkNum == 1) {
        deleteCampaignSetting(this.deleteData).then(res => {
          if (res) this.search();
        });
      } else {
        this.release(this.deleteData, 3);
      }
    },
    // 取得操作紀錄
    getProcess(id) {
      getCampaignLuckyDrawProcess({ id }).then(res => {
        this.processData = res.data;
      });
    }
  }
};
</script>
