<template>
  <div>
    <div class="bookmark-box">
      <div class="bookmark-view">
        <div v-for="list of bookmarkColumn" :key="list.id" @click="bookmark(list.id)"
          :class="{ 'chooice': bookmarkNum == list.id }">{{ list.name }}</div>
      </div>
    </div>
    <div>
      <div class="container-view">
        <button v-if="bookmarkNum == 3" class="search-btn main-style-btn" @click="search()">{{
          $t("default.search") }}</button>
        <div class="header">
          <h5>{{ $t(`${i18nPrefix}memberSignIn`) }}</h5>
          <div class="float-right">
            <button v-if="bookmarkNum == 1" class="create-btn main-style-btn" @click="handleDialog(undefined, 'Edit')">{{
              $t("default.create") + " " }}{{ $t(`${i18nPrefix}template`) }}</button>
          </div>
        </div>
        <div class="main">
          <div class="input-block" v-show="bookmarkNum == 3">
            <label>
              {{ $t(`${i18nPrefix}date`) }}
              <select v-model="searchForm.id">
                <option :value="undefined">{{ $t('default.none') }}</option>
                <option v-for="d of signInPeriodList" :value="d.id" :key="d.id">{{ d.name }}</option>
              </select>
            </label>
            <label>
              <div class="input-block-check">
                <span style="margin-right: 10px;">{{ $t("default.member") }}</span>
                <input id="isFuzzySearch" type="checkbox" v-model="searchForm.isFuzzySearch" />
                <label for="isFuzzySearch">{{ $t("default.isFuzzySearch") }}</label>
              </div>
              <input type="text" v-model="searchForm.memberAccount" />
            </label>
            <label>
              簽到類型
              <select v-model="searchForm.signInType">
                <option :value="undefined">{{ $t('default.all') }}</option>
                <option v-for="d of signInType" :value="d.id" :key="d.id">{{ $t(d.name) }}</option>
              </select>
            </label>
            <label>
              累積簽到日
              <input type="number" v-model.number="searchForm.accumulatedSignInDay" />
            </label>
          </div>
          <div v-if="bookmarkNum == 3" class="advance-block">
            <button
              class="search-pc-btn main-style-btn px-5" 
              @click="search()"
            >
              {{ $t("default.search") }}
            </button>
          </div>
        </div>
      </div>
      <div class="container-view">
        <div class="table-block">
          <TreeTable ref="treeTable" :list="allData" :column="tableColumn">
            <!-- 操作按鈕 -->
            <template v-slot:column_CampaignSetting="props">
              <button class="normal-btn" @click="handleDialog({ editData: props }, 'Edit')">{{ $t("default.edit")
              }}</button>
              <button class="delete-btn" @click="handleDeleteSetting(props.id)">{{ $t("default.delete") }}</button>
            </template>
            <template v-slot:column_CampaignLuckyDraw="props">
              <button class="normal-btn"
                @click="handleDialog({ isCampaign: true, detail: props.status !== 3 && getUserinfo.permissions.includes(171002) ? undefined : true, editData: props }, 'Edit')">{{
                  props.status !== 3 && getUserinfo.permissions.includes(171002) ? $t("default.edit") : $t("default.detail")
                }}</button>
              <button v-show="props.status !== 3"
                :class="{ 'btn-orange': props.status == 1, 'delete-btn': props.status == 2 }"
                @click="props.status == 1 ? release(props, 2) : handleDeleteLuckyDraw(props)">{{ props.status == 1 ?
                  $t(`${i18nPrefix}release`) : $t('default.inactive') }}</button>
            </template>
            <!-- 活動期別 -->
            <template v-slot:column_date="props">{{ `${props.id}- ${props.startTime}-${props.endTime}` }}</template>
            <template v-slot:column_campaignLuckyDrawListId="props">{{ GLOBAL.i18nCheckName(periodList.find(d => d.id ==
              props.campaignLuckyDrawListId)) }}</template>
            <!-- 狀態 -->
            <template v-slot:column_status="props">
              {{ GLOBAL.i18nCheckName(memberSignInStatus.find(d => d.id == props.status)) }}
            </template>
            <!-- 狀態 -->
            <template v-slot:column_star="props">
              {{ props.star + $t("default.star") }}
            </template>
            <!-- 簽到類型 -->
            <template v-slot:column_signInType="props">{{ GLOBAL.i18nCheckName(signInType.find(d => d.id ==
              props.signInType)) }}</template>
            <!-- 獎勵類型 -->
            <template v-slot:column_rewardType="props">{{ GLOBAL.i18nCheckName(rewardType.find(d => d.id ==
              props.rewardType)) }}</template>
          </TreeTable>
        </div>
        <serverPagination v-if="bookmarkNum == 2 || bookmarkNum == 3" :page="page"
          @ServerPageUtils="search" @changeTake="changeTake">
        </serverPagination>
      </div>
    </div>
    <transition name="fade" mode="out-in">
      <component :is="currDialog" :i18nPrefix="i18nPrefix" :starList="getStars" :tagList="tagList"
        :agentList="getAgentList" :taskType="taskType" :periodList="periodList" :rewardType="rewardType"
        :current="this.page.current" :signInType="signInType" v-bind="dialogData" @audit="audit"
        @toggle="currDialog = undefined" @search="search">
      </component>
    </transition>
    <Alert v-if="alertMsg" :message="alertMsg" @toggle="alertMsg = undefined" @doIt="deleteFunction" />
  </div>
</template>

<script>
import {
  selectMenu,
  getCampaignSetting,
  deleteCampaignSetting,
  updateMemberSignIn,
  getMemberSignInList,
  getSignInRecordList
} from "@/api/campaign/memberSignIn";
import { getCampaignLuckyDrawList } from "@/api/campaign/luckydraw";
import { searchModel } from "./model";
import Edit from "./dialog/edit";
import Audit from "./dialog/audit";
import Detail from "./dialog/detail";
import Photo from "@/layout/components/photo";
import Alert from "@/layout/components/Alert";
import handleDialog from "@/layout/mixins/handleDialog";
import TreeTable from "@/layout/components/TreeTable";
import { joinT } from "@/utils/transDateUtils";
import { mapGetters } from "vuex";
import serverPagination from "@/layout/components/serverPagination";
import handleServerPageUtils from "@/layout/mixins/handleServerPageUtils";

export default {
  name: "MemberSignIn",
  data() {
    return {
      searchForm: _.cloneDeep(searchModel),
      allData: [],
      bookmarkNum: 1,
      taskType: [],
      rewardType: [],
      signInType: [],
      memberSignInStatus: [],
      alertMsg: "",
      periodList: [],
      signInPeriodList: [],
      deleteData: undefined,
      startTime: undefined,
      endTime: undefined,
      total: {
        totalRecipientCount: 0,
        totalRedemptionCount: 0,
        totalPayoutAmount: 0,
        totalRegisterCount: 0,
        totalValidBetAmount: 0,
        totalMemberDepositCount: 0,
        totalDepositCount: 0,
        totalDepositAmount: 0
      },
      i18nPrefix: "campaign.memberSignIn."
    };
  },
  components: {
    Edit,
    Audit,
    Detail,
    Alert,
    Photo,
    TreeTable,
    serverPagination
  },
  mixins: [handleDialog, handleServerPageUtils],
  computed: {
    ...mapGetters([
      "getUserinfo",
      "getStars",
      "tagList",
      "getAgentList",
      "getStatusList",
    ]),
    tableColumn() {
      switch (this.bookmarkNum) {
        case 1:
          return [
            {
              key: "CampaignSetting",
              name: "",
              width: 80
            },
            {
              key: "name",
              name: this.$t(`${this.i18nPrefix}template`)
            },
            {
              key: "createUser",
              name: this.$t(`${this.i18nPrefix}createUser`),
              isText: true
            },
            {
              key: "createTime",
              name: this.$t(`${this.i18nPrefix}createTime`)
            },
            {
              key: "updateUser",
              name: this.$t(`${this.i18nPrefix}updateUser`),
              isText: true
            },
            {
              key: "updateTime",
              name: this.$t(`${this.i18nPrefix}updateTime`)
            }
          ];
        case 2:
          return [
            {
              key: "CampaignLuckyDraw",
              name: "",
              width: 80
            },
            {
              key: "name",
              name: this.$t(`${this.i18nPrefix}campaignName`)
            },
            {
              key: "date",
              name: this.$t(`${this.i18nPrefix}date`)
            },
            {
              key: "status",
              name: this.$t("default.status")
            },
            {
              key: "updateUser",
              name: this.$t(`${this.i18nPrefix}updateUser`),
              isText: true
            },
            {
              key: "updateTime",
              name: this.$t(`${this.i18nPrefix}updateTime`)
            }
          ];
        case 3:
          return [
            {
              key: "memberAccount",
              name: this.$t("default.member"),
              isText: true
            },
            {
              key: "star",
              name: this.$t("default.star")
            },
            {
              key: "receiveTime",
              name: this.$t(`${this.i18nPrefix}receiveTime`),
            },
            {
              key: "signInType",
              name: this.$t(`${this.i18nPrefix}signInType`),
            },
            {
              key: "rewardType",
              name: this.$t(`${this.i18nPrefix}rewardType`),
            },
            {
              key: "rewardCount",
              name: this.$t(`${this.i18nPrefix}rewardCount`),
            },
            {
              key: "campaignLuckyDrawListId",
              name: this.$t(`${this.i18nPrefix}campaignLuckyDrawListId`),
            },
            {
              key: "wageringRate",
              name: this.$t(`${this.i18nPrefix}wageringRate`),
            },
            {
              key: "accumulatedSignInDay",
              name: this.$t(`${this.i18nPrefix}accumulatedSignInDay`),
            }
          ];
      }
    },
    bookmarkColumn() {
      return [
        {
          id: 1,
          name: this.$t(`${this.i18nPrefix}template`)
        },
        {
          id: 2,
          name: this.$t("default.search")
        },
        {
          id: 3,
          name: this.$t(`${this.i18nPrefix}signInRecord`),
        },
        // {
        //   id: 4,
        //   name: this.$t(`${this.i18nPrefix}signInReport`),
        // },
        // {
        //   id: 5,
        //   name: this.$t(`${this.i18nPrefix}signInComparisonReport`),
        // }
      ];
    },
  },
  async created() {
    await selectMenu().then(res => {
      this.taskType = res.data.taskType.map(data => {
        return { id: data.id, name: "TaskType." + data.name };
      });
      this.signInType = res.data.signInType.map(data => {
        return { id: data.id, name: "SignInType." + data.name };
      });
      this.rewardType = res.data.rewardType.map(data => {
        return { id: data.id, name: "RewardType." + data.name };
      });
      this.memberSignInStatus = res.data.status.map(data => {
        return { id: data.id, name: "MemberSignInStatus." + data.name };
      });
      this.signInPeriodList = res.data.eventList.map(d => {
        return {
          id: d.id,
          name: `${d.id} - ${d.startTime} - ${d.endTime}`
        };
      });
    });

    await getCampaignLuckyDrawList({
      status: 0
    }).then(res => {
      this.periodList = res.data.map(d => {
        return {
          id: d.id,
          name: `${d.id} - ${d.startTime} - ${d.endTime}`
        };
      });
    });

    if (this.$route.query?.bookmarkNum) {
      this.bookmarkNum = this.$route.query?.bookmarkNum;
      this.memberAccount = this.$route.query?.memberAccount;
    }
    await this.search();
  },
  watch: {
    startTime(val) {
      this.searchForm.campaignListId = val !== undefined ? undefined : this.searchForm.campaignListId;
    },
    endTime(val) {
      this.searchForm.campaignListId = val !== undefined ? undefined : this.searchForm.campaignListId;
    },
    "searchForm.campaignListId"(val) {
      this.startTime = val !== undefined ? undefined : this.startTime;
      this.endTime = val !== undefined ? undefined : this.endTime;
    },
  },
  methods: {
    async search(page = false) {
      this.closeDialog();
      this.searchForm.page.take = this.take * 1;
      this.searchForm.page.skip = this.take * (page ? page - 1 : 0);
      this.allData = [];
      switch (this.bookmarkNum) {
        case 1:
          return getCampaignSetting({
            discountType: 37,
            discountCategory: 6
          }).then(res => {
            this.allData = res.data;
          });
        case 2:
          return getMemberSignInList(this.searchForm).then(res => {
            this.allData = res.data.list;
            this.page = res.data.page;
          });
        case 3:
          this.searchForm.startTime = this.startTime
            ? joinT(
              this.startTime + this.GLOBAL.startHour + this.GLOBAL.timeZone
            )
            : undefined;
          this.searchForm.endTime = this.endTime
            ? joinT(this.endTime + this.GLOBAL.endHour + this.GLOBAL.timeZone)
            : undefined;
          await getSignInRecordList(this.searchForm).then(res => {
            this.allData = res.data.list;
            this.page = res.data.page;
          });
          return;
      }
    },
    // 發布
    async release(data, status) {
      let formData = new FormData();

      data.status = status;
      Object.entries(data).forEach(([key, value]) => {
        if (value) formData.append(key, value);
      });

      await updateMemberSignIn(formData).then(res => {
        if (res) this.search();
      });
    },
    // 分頁
    bookmark(data) {
      this.allData = undefined;
      this.bookmarkNum = data;
      this.search();
    },
    // 刪除範本
    handleDeleteSetting(id) {
      this.alertMsg = this.$t("default.delMsg");
      this.deleteData = { id };
    },
    handleDeleteLuckyDraw(data) {
      this.alertMsg = this.$t(`${this.i18nPrefix}alertMsg`);
      this.deleteData = data;
    },
    deleteFunction() {
      this.alertMsg = "";
      if (this.bookmarkNum == 1) {
        deleteCampaignSetting(this.deleteData).then(res => {
          if (res) this.search();
        });
      } else {
        this.release(this.deleteData, 3);
      }
    },
    audit(data) {
      auditRebateList(data).then(res => {
        if (res) this.search();
      });
    }
  }
};
</script>
