<template>
  <div class="login-container">
    <img src="./assets/logo.png" />
    <div class="login-block">
      <span class="title">{{ $t("login.KR9title") }}</span>
      <div class="line"></div>
      <div class="lang">
        <div class="dropdown-div" @click="drop = !drop">
          <span>{{ GLOBAL.langes.find(data => data.key === lang).value }}</span>
          <i class="fas fa-chevron-down" v-show="!drop"></i>
          <i class="fas fa-chevron-up" v-show="drop"></i>
        </div>
        <div class="dropdown" v-show="drop">
          <span v-for="lang of GLOBAL.langes" @click="i18nSet(lang.key)" :key="lang.key">{{ lang.value }}</span>
        </div>
      </div>
      <div class="input-block">
        <label>
          {{ $t("login.account") }}
          <input type="text" ref="account" v-model="userInfo.account" :class="{ required: !accountReq }" />
          <span v-show="!accountReq">{{ $t("login.required") }}</span>
        </label>
        <label>
          {{ $t("login.password") }}
          <input :key="passwordType" :type="passwordType" v-model="userInfo.password" :class="{ required: !passwordReq }"
            ref="password" />
          <span v-show="!passwordReq">{{ $t("login.required") }}</span>
          <i @click="showPwd" class="fa" :class="passwordType === 'password' ? 'fa-eye' : 'fa-eye-slash'"></i>
        </label>
        <label>
          {{ $t("login.companyCode") }}
          <input type="text" ref="companyCode" v-model="userInfo.companyCode" :class="{
            required: !companyCodeReq,
          }" @keyup.enter="verify" />
          <span v-show="!companyCodeReq">{{ $t("login.required") }}</span>
        </label>
      </div>
      <label class="labelBox">
        <input class="box" type="checkbox" v-model="remeber" />
        <span>{{ $t("login.remember") }}</span>
      </label>
      <div class="button-block">
        <button @click="verify" :disabled="!validPass || isLoading">
          <span v-if="!isLoading">{{ $t("login.login") }}</span>
          <i v-else class="fa fa-spinner"></i>
        </button>
      </div>
    </div>
    <transition name="fade" mode="out-in">
      <component :is="currDialog" v-bind="dialogData" @toggle="cancel" @twoFactorAutn="twoFactorAutn"></component>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { setInterval, clearInterval } from "timers";
import { getServerLang, twoFactorAuthVerify } from "@/api/user";
import { setToken, getToken } from "@/utils/auth";
import TwoFactor from "../verify/twoFactor-auth";
import handleDialog from "@/layout/mixins/handleDialog";

export default {
  name: "Login",
  components: { TwoFactor },
  data() {
    return {
      userInfo: {
        account: "",
        password: "",
        companyCode: ""
      },
      passwordType: "password",
      loginData: undefined,
      isLoading: false,
      accountReq: true,
      passwordReq: true,
      companyCodeReq: true,
      validPass: false,
      remeber: false,
      timer: undefined,
      drop: false,
      token: undefined
    };
  },
  mixins: [handleDialog],
  created() {
    document.title = this.$t("login.title2");
    this.token = getToken();
    this.i18nSet(this.lang, true);
    if (this.token) {
      if (this.getUserinfo.permissions.includes(180000)) {
        if (this.getUserinfo.koreanStyleInterface) {
          // 轉址到 Dashboard
          this.$router.push("/statistical-report/overview");
          return
        }
        // 轉址到 Dashboard
        this.$router.push("/dashboard");
        return
      }
      let permissions = this.getUserinfo.permissions
        .sort((a, b) => a - b)
        .filter(data => data % 10000 === 0)[0]; //權限由小到大排序
      let childrenPermission = this.getUserinfo.permissions
        .sort((a, b) => a - b)
        .filter(
          data => (data % permissions) % 100 === 0 && data % permissions !== 0
        )[0];
      var path = this.$router.options.routes.find(
        x =>
          x.meta.permission === permissions && x.meta.permission % 10000 === 0
      ).path;
      var childrenPath = this.$router.options.routes
        .find(x => x.meta.permission === permissions)
        .children.find(
          children => children.meta.permission === childrenPermission
        ).path;
      this.$router.push(path + "/" + childrenPath);
    }
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  watch: {
    "userInfo.account"(value) {
      this.userInfo.account = value.trim();
      this.accountReq = !!value;
    },
    "userInfo.password"(value) {
      this.userInfo.password = value.trim();
      this.passwordReq = !!value;
    },
    "userInfo.companyCode"(value) {
      this.userInfo.companyCode = value.trim();
      this.companyCodeReq = !!value;
    },
    userInfo: {
      handler(value) {
        return this.validatorForm(value)
          ? (this.validPass = true)
          : (this.validPass = false);
      },
      deep: true
    },
    remeber(value) {
      this.$store.commit("user/SET_REMEBER", value);
    }
  },
  computed: {
    ...mapGetters(["remeberData", "isRemeber", "getUserinfo", "lang"])
  },
  mounted() {
    this.remeber = this.isRemeber;
    if (this.remeber) {
      this.userInfo.account = this.remeberData.account;
    }
    if (this.$route.query?.code)
      this.userInfo.companyCode = this.$route.query?.code;
  },
  methods: {
    async verify() {
      if (this.isLoading) return;
      this.isLoading = true;
      await this.$store
        .dispatch("user/login", this.userInfo, this.remeber)
        .then(res => {
          this.loginData = res.data;
          if (this.loginData?.isEnableGoogleAuthenticator) {
            this.handleDialog(
              {
                verifyData: this.loginData.authenticatorSetupInfo,
                verificationId: this.loginData.verificationId
              },
              "TwoFactor"
            );
            return;
          }
          if (res) this.login();
          this.isLoading = res;
        });
    },
    async twoFactorAutn(data) {
      this.closeDialog();
      await twoFactorAuthVerify(data).then(res => {
        if (res) {
          this.login(res?.data?.token);
        } else {
          this.isLoading = false;
        }
      });
    },
    login(token) {
      if (token) this.loginData.token = token;
      this.$store.commit("user/SET_INFO", {
        token: this.loginData.token,
        account: this.userInfo.account,
        companyCode: this.userInfo.companyCode
      });
      setToken(this.loginData.token);
      this.$store.dispatch("user/info");
      this.$store.commit("user/SET_MAIL_LAST_ID", 0);
    },
    cancel() {
      this.closeDialog();
      this.isLoading = false;
    },
    showPwd() {
      this.passwordType === "password"
        ? (this.passwordType = "")
        : (this.passwordType = "password");
      this.$nextTick(() => {
        this.$refs.password.focus();
      });
    },
    validatorForm(userInfo) {
      return userInfo.account && userInfo.password && userInfo.companyCode;
    },
    i18nSet(lang, created) {
      if (!created) this.drop = !this.drop;
      this.$store.commit("user/SET_LANG", lang);
      getServerLang(lang).then(res => {
        this.$i18n.setLocaleMessage(
          "localeLang",
          Object.assign([], this.GLOBAL.langData[lang], res)
        );
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.login-container {
  min-height: 100vh;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background-image: url("./assets/loginBg.png");

  img {
    width: 800px;
    height: 300px;
  }

  .login-block {
    width: 360px;
    height: 480px;
    padding: 20px 20px 27px;
    border-radius: 10px;
    box-shadow: 0px 2px 6px 0 rgba(0, 0, 0, 0.2);
    border: solid 2px #3f3f3f;
    background-color: #11121a;

    .title {
      display: block;
      width: 100%;
      height: 19px;
      text-align: center;
      font-size: 20px;
      letter-spacing: 1px;
      color: #ffffff;
    }

    .line {
      width: 320px;
      height: 1px;
      margin: 16px 0 19px;
      background-color: #dcdcdc;
    }

    .lang {
      display: flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer;
      position: relative;

      .dropdown-div {
        width: 100%;
        padding: 5px 0;
        display: flex;
        align-items: center;
        justify-content: center;

        span {
          font-size: 16px;
          color: #fff;
          margin-right: 10px;
        }

        i {
          color: #a0a0a0;
        }
      }

      .dropdown {
        width: 100px;
        border-radius: 4px;
        top: 30px;
        border: solid 1px #dcdcdc;
        background-color: #fff;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: absolute;
        z-index: 10;

        span {
          font-size: 16px;
          display: block;
          text-align: center;
          width: 100%;
          color: #a0a0a0;

          &:not(:last-child) {
            border-bottom: 1px solid #dcdcdc;
          }
        }
      }
    }

    .input-block {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;

      label {
        color: #ababab;
        position: relative;
        display: flex;
        flex-direction: column;
        margin-bottom: 0px !important;

        input {
          width: 320px;
          height: 36px;
          margin: 4px 0 20px;
          border-radius: 4px;
          border: solid 1px #dcdcdc;
          background-color: #f7f7f7;

          &:focus {
            border-radius: 7px;
            border: 1px solid rgba(0, 81, 255, 0.6);
            outline: 2px solid rgba(0, 81, 255, 0.6);
          }
        }

        .fa {
          position: absolute;
          top: 45%;
          right: 5%;
          cursor: pointer;
        }

        .required {
          border: 2px solid rgb(255, 0, 0, 0.7) !important;
          transition-duration: 0.2s;

          &~span {
            position: absolute;
            left: 20em;
            bottom: 2.8em;
            display: inline-block;
            color: rgb(255, 0, 0, 0.8) !important;
            width: 100%;
            font-size: 10px;
            font-weight: bold;
            animation: fadeDown 0.2s linear;
          }
        }
      }
    }

    .button-block {
      button {
        width: 320px;
        height: 36px;
        border-radius: 4px;
        background-color: #fd8900;
        border: 1px solid #fd8900;

        &:hover {
          color: #ecee6c !important;
        }

        span {
          width: 36px;
          height: 15px;
          font-size: 16px;
          font-weight: 300;
          letter-spacing: 0.8px;
          color: #fff;
          transition-duration: 0.3s;
        }

        .fa-spinner {
          animation: spin linear 1.5s infinite;
        }
      }

      button[disabled] {
        background-color: #6a6572;
        cursor: not-allowed;
        border: none;

        &:hover {
          background-color: #6a6572;
          transform: scale(1);

          span {
            color: #ecee6c !important;
          }
        }
      }
    }
  }
}

@keyframes fadeDown {
  from {
    transform: translateY(-5px);
  }

  to {
    transform: translateY(0);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 800px) {
  .login-container {
    flex-direction: column;
    justify-content: flex-start;
    background-image: url("./assets/loginBg-m.png");

    img {
      content: url("./assets/logo-m.png");
      width: 265px;
      height: 105px;
      margin: 70px 0;
    }
  }
}

.labelBox {
  width: 100% !important;
  margin-bottom: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .box {
    width: 16px;
    height: 16px;
    border-radius: 4px;
    border: solid 1px #dcdcdc;
    background-color: #fff;
  }

  span {
    width: 66px;
    margin-left: 20px;
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 0.8px;
    text-align: left;
    color: #ababab;
  }
}
</style>