<template>
  <div class="dialog-mask" @mousedown.self="$emit('toggle')">
    <div class="dialog-container edit-member">
      <div class="dialog-block">
        <div class="header">
          <h4>{{ $t("default.create") }} {{ $t("title." + $route.meta.title) }}</h4>
        </div>
        <div class="divider"></div>
        <main>
          <div class="input-block">
            <label>
                {{ $t("default.gamePlatform") }}
              <select v-model="createModel.gamePlatform">
                <option v-for="list of getGamePlatforms" :key="list.id" :value="list.id">
                  {{ $t(list.name) }}
                </option>
              </select>
            </label>
            <div>
              <label>
                {{ $t("default.casinoChips") }}
                <input type="number" v-model.number="createModel.amount" />
              </label>
            </div>
          </div>
          <div class="advance-block">
            <button class="confirm-btn main-style-btn" @click="$emit('create', createModel);">{{ $t("default.confirm")
              }}</button>
            <button class="normal-btn-v2 main-style-btn" @click="$emit('toggle')">{{ $t("default.cancel")}}</button>
          </div>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Create",
  props: ["getGamePlatforms", "i18nPrefix"],
  data() {
    return {
      createModel: {
        gamePlatform: undefined,
        amount: 0,
      }
    };
  },
  created() {
    this.createModel.gamePlatform = this.getGamePlatforms[0]?.id
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
.dialog-container {
  width: 20%;

  .input-block {
    div {
      width: 100%;
    }

    label {
      width: 100%;
    }
  }
}
</style>