<template>
  <div class="dialog-mask create-member" @mousedown.self="$emit('toggle')">
    <div class="dialog-container">
      <div class="dialog-block">
        <div class="header">
          <h4>{{ $t("default.create") + " " + $t(`${i18nPrefix}dialog.memberData`) }}</h4>
        </div>
        <div class="divider"></div>
        <main>
          <div class="input-block">
            <div class="member-info">
              <label>
                {{ $t(`${i18nPrefix}account`) }}
                <input
                  type="text"
                  :placeholder="$t(`${i18nPrefix}dialog.accountPlaceholder`)"
                  v-model="createModel.account"
                  :class="{ require: !accountReq }"
                />
              </label>
              <label>
                {{ $t(`${i18nPrefix}dialog.password`) }}
                <input
                  type="text"
                  :placeholder="$t(`${i18nPrefix}dialog.passwordPlaceholder`)"
                  v-model="createModel.password"
                  :class="{ require: !passwordReq }"
                />
              </label>
              <label>
                {{ $t(`${i18nPrefix}name`) }}
                <input
                  type="text"
                  v-model="createModel.name"
                  :class="{ require: !nameReq }"
                />
              </label>
              <label v-if="getUserinfo.koreanStyleInterface">
                {{ $t("default.nickName") }}
                <input
                  type="text"
                  v-model="createModel.nickName"
                />
              </label>
              <label>
                {{ $t(`${i18nPrefix}recommend`) }}
                <input type="text" v-model="createModel.referralCode" />
              </label>
              <label>
                {{ $t(`${i18nPrefix}group`) }}
                <select v-model="createModel.bankGroupId">
                  <option
                    v-for="list of getBankGroupList"
                    :key="list.id"
                    :value="list.id"
                  >
                    {{ list.name }}
                  </option>
                </select>
              </label>
              <label>
                {{ $t("default.agent") }}
                <select v-model="createModel.agentId">
                  <option
                    v-for="list of getAgentList"
                    :key="list.id"
                    :value="list.id"
                  >
                    {{ list.account }}
                  </option>
                </select>
              </label>
              <label>
                {{ $t(`${i18nPrefix}dialog.levelNumber`) }}
                <input type="number" v-model="createModel.levelNumber"/>
              </label>
              <label style="text-align: center">
                {{ $t(`${i18nPrefix}seamless`) }}
                <div
                  class="slide-block"
                  style="margin: auto"
                  :class="{ 'slide-block-on': createModel.enableSeamless }"
                >
                  <div
                    class="slide"
                    @click="toggleSlide"
                    :class="{ 'slide-on': createModel.enableSeamless }"
                  >
                    <label for="enableSeamless" style="display: none"></label>
                    <input
                      type="checkbox"
                      id="enableSeamless"
                      v-model="createModel.enableSeamless"
                      style="display: none"
                    />
                  </div>
                </div>
              </label>
              <label></label>
            </div>
            <div class="contact-info">
              <h4>{{ $t(`${i18nPrefix}dialog.connectData`) }}</h4>
              <div class="divider"></div>
              <label>
                {{ $t(`${i18nPrefix}phone`) }}
                <input
                  type="text"
                  :placeholder="$t(`${i18nPrefix}dialog.connectPlaceholder`)"
                  v-model="createModel.phoneNumber"
                  :class="{ require: !phoneNumberReq }"
                />
              </label>
              <label>
                {{ $t(`${i18nPrefix}line`) }}
                <input
                  type="text"
                  :placeholder="$t(`${i18nPrefix}dialog.connectPlaceholder`)"
                  v-model="createModel.socialId"
                  :class="{ require: !socialIdReq }"
                />
              </label>
              <label>
                {{ $t(`${i18nPrefix}mail`) }}
                <input type="text" v-model="createModel.email"/>
              </label>
            </div>
          </div>
          <div class="advance-block">
            <button
              class="confirm-btn main-style-btn"
              @click="create"
              :disabled="!validatorForm"
            >
              {{ $t("default.confirm") }}
            </button>
            <button class="normal-btn-v2 main-style-btn" @click="$emit('toggle')">{{ $t("default.cancel") }}</button>
          </div>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
import Message from "@/layout/components/Message";
import { toggleStyle } from "@/utils/slideUtils";
import { mapGetters } from "vuex";

export default {
  name: "Create",
  props: ["i18nPrefix"],
  data() {
    return {
      createModel: {
        account: "",
        name: "",
        nickName: "",
        password: "",
        referralCode: undefined,
        agentId: undefined,
        enableSeamless: true,
        phoneNumber: "",
        socialId: "",
        bankGroupId: undefined,
        levelNumber: 1,
        email: undefined,
        verifyStatus: false,
      },
      accountReq: true,
      passwordReq: true,
      nameReq: true,
      phoneNumberReq: true,
      socialIdReq: true,
    };
  },
  watch: {
    "createModel.account": function (value, oldValue) {
      let val = this.checkInfo(value, oldValue);
      val = val.length > 10 ? oldValue : val;
      this.createModel.account = val.trim();
      this.accountReq = !!val;
    },
    "createModel.password": function (value, oldValue) {
      let val = this.checkInfo(value, oldValue);
      val = val.length > 13 ? oldValue : val;
      this.createModel.password = val.trim();
      this.passwordReq = !!val;
    },
    "createModel.name": function (value) {
      this.createModel.name = value.trim();
      this.nameReq = !!value;
    },
    "createModel.phoneNumber": function (value) {
      this.createModel.phoneNumber = value.trim();
      this.phoneNumberReq = !!value;
    },
    "createModel.socialId": function (value) {
      this.createModel.socialId = value.trim();
      this.socialIdReq = !!value;
    },
    "createModel.nickName"(val, oldVal) {
      var reg = /[^\w\s\u3131-\uD79D]/g;
      if (reg.test(val) == true || val?.length > 50) {
        this.editModel.nickName = (oldVal ?? '');
      }
    },
  },
  computed: {
    ...mapGetters(["getUserinfo", "getBankGroupList", "getCompanyCode", "getAgentList"]),
    validatorForm() {
      return (
        this.createModel.name &&
        this.createModel.account.length >= 4 &&
        this.createModel.password.length >= 6 &&
        this.createModel.phoneNumber.length >= 3 &&
        this.createModel.socialId.length >= 3
      );
    },
  },
  created() {
    this.createModel.bankGroupId = this.getBankGroupList[0].id;
    this.createModel.agentId = this.getAgentList[0].id;
  },
  methods: {
    toggleSlide: toggleStyle,
    create() {
      let data = _.cloneDeep(this.createModel);
      for (let i in data) {
        if (i === "isVip" || i === "verifyStatus" || i === "enableSeamless") {
          if (data[i]) data[i] = 1;
          else data[i] = 2;
        }
      }
      data.levelNumber = this.createModel.levelNumber * 1;
      this.$emit("create", data);
    },
    checkInfo(val, oldVal){
      if(/^[a-zA-Z0-9]*$/.test(val) === false) Message.error(this.$t("default.warningMessage"), 2000);
      return /^[a-zA-Z0-9]*$/.test(val) === false ? oldVal : val;
    }
  },
};
</script>

<style lang="scss" scoped>
.require {
  border-color: red;
}
main {
  .input-block {
    .member-info,
    .contact-info {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
    }
    .member-info {
      gap: 10px;
      label {
        width: 30%;
      }
    }
    .contact-info {
      label {
        width: 30%;
      }
    }
  }
}
.dialog-container {
  width: 50%;
}
@media screen and (max-width: 960px) {
  .input-block {
    label {
      width: calc(50% - 10px) !important;
    }
    .more-info {
      width: 100% !important;
    }
  }
}
@media screen and (max-width: 768px) {
  .member-info {
    flex-direction: column;
    width: 90%;
    label {
      width: 100% !important;
    }
  }
  .risk-block {
    flex-direction: column;
    width: 100%;
    label {
      width: 100% !important;
    }
  }
}
</style>