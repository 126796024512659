var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "container-view" }, [
        _c(
          "button",
          {
            staticClass: "search-btn main-style-btn",
            on: {
              click: function ($event) {
                return _vm.search()
              },
            },
          },
          [_vm._v(_vm._s(_vm.$t("default.search")))]
        ),
        _c("div", { staticClass: "header" }, [
          _c("h5", [_vm._v(_vm._s(_vm.$t("title." + _vm.$route.meta.title)))]),
          _c("div", { staticClass: "float-right" }, [
            _c(
              "button",
              {
                staticClass: "create-btn main-style-btn",
                attrs: {
                  disabled: !_vm.getUserinfo.permissions.includes(140101),
                },
                on: {
                  click: function ($event) {
                    return _vm.handleDialog(undefined, "Create")
                  },
                },
              },
              [_vm._v(" " + _vm._s(_vm.$t("default.create")) + " ")]
            ),
          ]),
        ]),
        _c("div", { staticClass: "main" }, [
          _c("div", { staticClass: "input-block mb-5" }, [
            _c("label", [
              _vm._v(" " + _vm._s(_vm.$t("default.gamePlatform")) + " "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchForm.gamePlatform,
                      expression: "searchForm.gamePlatform",
                    },
                  ],
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.searchForm,
                        "gamePlatform",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    },
                  },
                },
                [
                  _c("option", { domProps: { value: 0 * 1 } }, [
                    _vm._v(_vm._s(_vm.$t("default.all"))),
                  ]),
                  _vm._l(_vm.getGamePlatforms, function (list) {
                    return _c(
                      "option",
                      { key: list.id, domProps: { value: list.id } },
                      [_vm._v(" " + _vm._s(_vm.$t(list.name)) + " ")]
                    )
                  }),
                ],
                2
              ),
            ]),
          ]),
          _c("div", { staticClass: "advance-block" }, [
            _c(
              "button",
              {
                staticClass: "search-pc-btn main-style-btn px-5",
                on: {
                  click: function ($event) {
                    return _vm.search()
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("default.search")))]
            ),
          ]),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "container-view" },
        [
          _c("div", { staticClass: "table-block" }, [
            _c("table", [
              _c("thead", [
                _c("tr", [
                  _c("th"),
                  _c("th", [_vm._v(_vm._s(_vm.$t("default.gamePlatform")))]),
                  _c("th", [_vm._v(_vm._s(_vm.$t("default.nowCsinoChips")))]),
                ]),
              ]),
              _c(
                "tbody",
                [
                  !_vm.allData || !_vm.allData.length
                    ? _c("tr", [
                        _c("td", { attrs: { colspan: "3" } }, [
                          _vm._v(_vm._s(_vm.$t("default.noData"))),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._l(_vm.allData, function (list) {
                    return _c("tr", { key: list.id }, [
                      _c("td", { staticClass: "small-td" }, [
                        _c("div", { staticClass: "btn-td" }, [
                          _c(
                            "button",
                            {
                              staticClass: "table-btn main-style-btn",
                              attrs: {
                                disabled:
                                  !_vm.getUserinfo.permissions.includes(140102),
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.handleDialog(list, "Edit")
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("default.edit")) + " ")]
                          ),
                        ]),
                      ]),
                      _c("td", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.GLOBAL.i18nCheckName(
                                _vm.getGamePlatforms.find(
                                  (data) => list.gamePlatform === data.id
                                )
                              )
                            ) +
                            " "
                        ),
                      ]),
                      _c("td", [_vm._v(_vm._s(list.amount))]),
                    ])
                  }),
                ],
                2
              ),
            ]),
          ]),
          _vm.allData
            ? _c("serverPagination", {
                attrs: { page: _vm.page },
                on: { ServerPageUtils: _vm.search, changeTake: _vm.changeTake },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "transition",
        { attrs: { name: "fade", mode: "out-in" } },
        [
          _c(
            _vm.currDialog,
            _vm._b(
              {
                tag: "component",
                attrs: { getGamePlatforms: _vm.getGamePlatforms },
                on: {
                  toggle: function ($event) {
                    _vm.currDialog = undefined
                  },
                  edit: _vm.edit,
                  create: _vm.create,
                },
              },
              "component",
              _vm.dialogData,
              false
            )
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }