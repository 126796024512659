<template>
  <div class="dialog-mask" @mousedown.self="$emit('toggle')">
    <div class="dialog-container edit-member">
      <div class="dialog-block">
        <div class="header">
          <h4>{{ $t(`${i18nPrefix}wallet`) }}</h4>
        </div>
        <div class="divider"></div>
        <main>
          <div class="input-block">
            <label>
              {{ $t(`${i18nPrefix}account`) }}
              <input type="text" v-model="account" disabled />
            </label>
            <div v-show="adjustmentType !== 4">
              <label>
                {{ $t(`${i18nPrefix}dialog.balance-adjustment.balance`) }}
                <input type="text" v-model="balance" disabled />
              </label>
              <label>
                {{ $t("default.amount") }}
                <input type="text" v-model="amount" />
              </label>
              <label>
                {{ $t("default.remark") }}
                <textarea cols="30" rows="6" v-model="remark"></textarea>
              </label>
            </div>
            <label>
              {{ $t(`${i18nPrefix}dialog.balance-adjustment.adjustment`) }}
              <select v-model="adjustmentType">
                <option :value="1 * 1">{{ $t(`${i18nPrefix}dialog.balance-adjustment.adjustmentType1`) }}</option>
                <option :value="2 * 1">{{ $t(`${i18nPrefix}dialog.balance-adjustment.adjustmentType2`) }}</option>
                <option :value="3 * 1">{{ $t(`${i18nPrefix}dialog.balance-adjustment.adjustmentType3`) }}</option>
              </select>
            </label>
            <div v-show="adjustmentType === 3">
              <label>
                {{ $t(`${i18nPrefix}dialog.balance-adjustment.platformBank`) }}
                <select v-model="companyBankInfoId">
                  <option v-for="list of companyBankList" :key="list.id" :value="list.id">{{ list.bankAccountName }}
                  </option>
                </select>
              </label>
              <label>
                {{ $t(`${i18nPrefix}accountBank`) }}
                <select v-model="memberBankInfoId">
                  <option v-for="list of bankData" :key="list.memberBankInfoId" 
                  :value="list.memberBankInfoId">{{ list.bankName }}</option>
                </select>
              </label>
            </div>
          </div>
          <div class="advance-block">
            <button class="confirm-btn main-style-btn" @click="submit">{{ $t("default.confirm")}}</button>
            <button class="normal-btn-v2 main-style-btn" @click="$emit('toggle')">{{ $t("default.cancel")}}</button>
          </div>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
import Message from "@/layout/components/Message";
import { companyBankList } from "@/api/member/member";

export default {
  name: "BalanceAdjustment",
  props: ["id", "account", "balance", "banks", "bankGroupId", "i18nPrefix"],
  data() {
    return {
      amount: 0,
      remark: "",
      adjustmentType: 1,
      memberBankInfoId: this.banks.find((data) => data)?.memberBankInfoId || 0,
      companyBankInfoId: undefined,
      companyBankList: [],
      bankData: _.cloneDeep(this.banks),
      redPacket: {
        id: this.id,
        bonusReceive: undefined,
        totalBetAmountLimit: undefined,
      }
    };
  },
  created() {
    if (!this.banks.length) this.bankData = [{ memberBankInfoId: 0, bankName: this.$t("default.none") }];
    companyBankList({ bankGroupId: this.bankGroupId }).then((res) => {
      this.companyBankList = res.data;
      this.companyBankInfoId = this.companyBankList[0].id;
    });
  },
  methods: {
    submit() {
      let form = {
        memberId: this.id,
        memberAccount: this.memberName,
        amount: this.amount * 1,
        remark: this.remark,
        adjustmentType: this.adjustmentType,
        generalDepositData: {
          memberBankInfoId: this.memberBankInfoId,
          companyBankInfoId: this.companyBankInfoId,
        }
      };
      if (form.amount <= 0) {
        Message.error(this.$t("default.checkNum"), 1000);
        this.amount = 0;
        return
      }
      if (!form.remark.trim().length) {
        Message.error(this.$t("default.checkInput", { input: this.$t("default.remark") }), 1000);
        return
      }
      this.$emit("balanceAdjust", form);
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog-container {
  width: auto !important;

  .input-block {
    div {
      width: 100%;
    }

    label {
      width: 100%;
    }
  }
}
</style>