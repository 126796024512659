export let searchModel = {
    submitStartTime: undefined,
    submitEndTime: undefined,
    auditStartTime: undefined,
    auditEndTime: undefined,
    auditStatuses: [],
    memberAccount: undefined,
    amount: undefined,
    cryptoInfoId: undefined,
    cvsInfoId: undefined,
    applyAmount: undefined,
    orderNumber: undefined,
    page:  {
        "take": 10,
        "skip": 0
    },
    sort: [
        {
            "column": undefined,
            "order": undefined
        }
    ]
}