var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "container-view" }, [
      _c("div", { staticClass: "header" }, [
        _c("h5", [_vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}welcomeMessage`)))]),
        _c("div", { staticClass: "float-right" }, [
          _c(
            "button",
            {
              staticClass: "create-btn main-style-btn",
              attrs: { disabled: !_vm.getUserinfo.permissions.includes(62603) },
              on: {
                click: function ($event) {
                  return _vm.updateWelcomeMessage()
                },
              },
            },
            [_vm._v(" " + _vm._s(_vm.$t("default.edit")) + " ")]
          ),
        ]),
      ]),
      _c("div", { staticClass: "main" }),
    ]),
    _c("div", { staticClass: "container-view" }, [
      _c("div", { staticClass: "welcomeDiv" }, [
        _c("label", [
          _vm._v(" " + _vm._s(_vm.$t("default.title")) + " "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.title,
                expression: "title",
              },
            ],
            attrs: { type: "text" },
            domProps: { value: _vm.title },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.title = $event.target.value
              },
            },
          }),
        ]),
        _c(
          "label",
          { staticClass: "w-100" },
          [
            _vm._v(
              " " + _vm._s(_vm.$t(`${_vm.i18nPrefix}welcomeMessage`)) + " "
            ),
            _c("ckeditor", {
              attrs: { "editor-url": this.GLOBAL.CKEditorUrl },
              model: {
                value: _vm.content,
                callback: function ($$v) {
                  _vm.content = $$v
                },
                expression: "content",
              },
            }),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }