<template>
  <div>
    <div class="container-view">
      <button class="search-btn main-style-btn" @click="search()">{{ $t("default.search") }}</button>
      <div class="header">
        <h5>{{ $t("title." + $route.meta.title) }}</h5>
        <div class="float-right">
          <button class="download-btn main-style-btn" @click="download">
            <img class="icon" src="@/assets/download.svg" />
            {{ $t('default.download') }}
          </button>
          <button class="create-btn main-style-btn" @click="handleDialog(undefined, 'Create')"
            :disabled="!getUserinfo.permissions.includes(61601)">
            {{ $t(`${i18nPrefix}sendMail`) }}
          </button>
        </div>
      </div>
      <div class="main">
        <div class="input-block">
          <label>
            {{ $t("default.member") }}
            <input type="text" v-model="searchForm.memberAccount" />
          </label>
          <label>
            {{ $t("default.admin") }}
            <input type="text" v-model="searchForm.userAccount" />
          </label>
          <label>
            {{ $t(`${i18nPrefix}title`) }}
            <input type="text" v-model="searchForm.title" />
          </label>
          <label>
            {{ $t(`${i18nPrefix}type`) }}
            <select v-model="searchForm.type">
              <option :value="0 * 1">{{ $t("default.all") }}</option>
              <option v-for="list of messageType" :key="list.id" :value="list.id * 1">
                {{ $t(list.name) }}
              </option>
            </select>
          </label>
          <label>
            {{ $t(`${i18nPrefix}startTime`) }}
            <date-picker v-model="startTime" format="YYYY-MM-DD HH:mm" value-type="format" type="datetime"></date-picker>
          </label>
          <label>
            {{ $t(`${i18nPrefix}endTime`) }}
            <date-picker v-model="endTime" format="YYYY-MM-DD HH:mm" value-type="format" type="datetime"></date-picker>
          </label>
          <label>
            {{ $t("default.status") }}
            <select v-model="searchForm.IsRead">
              <option :value="undefined">{{ $t("default.all") }}</option>
              <option :value="false">{{ $t(`${i18nPrefix}notRead`) }}</option>
              <option :value="true">{{ $t(`${i18nPrefix}read`) }}</option>
            </select>
          </label>
        </div>
        <div class="advance-block">
          <QuickSearch @changeTime="changeTime"></QuickSearch>
          <button class="search-pc-btn main-style-btn px-5" @click="search()">{{ $t("default.search") }}</button>
        </div>
      </div>
    </div>
    <div class="container-view">
      <div class="table-block">
        <table>
          <thead>
            <tr>
              <th>{{ $t("default.status") }}</th>
              <th>{{ $t("default.member") }}</th>
              <th>{{ $t(`${i18nPrefix}messageType`) }}</th>
              <th>{{ $t(`${i18nPrefix}title`) }}</th>
              <th>{{ $t(`${i18nPrefix}message`) }}</th>
              <th>{{ $t(`${i18nPrefix}updateTime`) }}</th>
              <th>{{ $t("default.admin") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="!tableList || !tableList.length">
              <td colspan="7">{{ $t("default.noData") }}</td>
            </tr>
            <tr v-for="list of tableList" :key="list.id">
              <td>{{ list.isRead ? $t(`${i18nPrefix}read`) : $t(`${i18nPrefix}notRead`) }}</td>
              <td>{{ list.memberAccount }}</td>
              <td>{{ GLOBAL.i18nCheckName(messageType.find(data => data.id === list.type)) }}</td>
              <td>{{ list.title }}</td>
              <td><button class="table-btn main-style-btn" @click="handleDialog({ message: list.message }, 'viewMessage')">
                  {{ $t(`${i18nPrefix}detail`) }}
                </button></td>
              <td>{{ list.updateTime }}</td>
              <td>{{ list.userAccount }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <Pagination v-if="totalCount > 0" :totalCount="totalCount" @handlePage="handlePage" />
    </div>
    <transition name="fade" mode="out-in">
      <component :messageTypeList="messageType" :agentList="getAgentList" :is="currDialog" :i18nPrefix="i18nPrefix"
        v-bind="dialogData" @toggle="currDialog = undefined" @create="create"></component>
    </transition>
  </div>
</template>

<script>
import {
  getMessageList,
  getExcel,
} from "@/api/features/memberPrivateMessage";
import Pagination from "@/layout/components/Pagination";
import handlePageUtils from "@/layout/mixins/handlePageUtils";
import handleDialog from "@/layout/mixins/handleDialog";
import Create from "./dialog/create.vue";
import viewMessage from "./dialog/message.vue";
import { searchModel } from "./model";
import { mapGetters } from "vuex";
import QuickSearch from "@/layout/components/QuickSearch";
import { joinT } from "@/utils/transDateUtils";
import { download } from "@/utils/download";

export default {
  name: "MemberPrivateMessage",
  data() {
    return {
      searchForm: _.cloneDeep(searchModel),
      startTime: undefined,
      endTime: undefined,
      sort: true,
      messageType: [
        {
          id: 1,
          name: 'features.memberPrivateMessage.mail'
        },
        // {
        //   id: 2,
        //   name: 'features.memberPrivateMessage.sns'
        // },
      ],
      i18nPrefix: "features.memberPrivateMessage."
    };
  },
  components: {
    Pagination,
    Create,
    viewMessage,
    QuickSearch,
  },
  computed: {
    ...mapGetters(["getUserinfo", "getAgentList"]),
  },
  mixins: [handlePageUtils, handleDialog],
  methods: {
    search() {
      this.searchForm.startTime = this.startTime ? joinT(this.startTime + this.GLOBAL.startSecond + this.GLOBAL.timeZone) : undefined;
      this.searchForm.endTime = this.endTime ? joinT(this.endTime + this.GLOBAL.endSecond + this.GLOBAL.timeZone) : undefined;
      getMessageList(this.searchForm).then((res) => {
        this.allData = res.data;
        this.currDialog = undefined;
        this.dataSort('updateTime', 'time', true);
      });
    },
    create(data) {
      discountCreate(data).then((res) => {
        this.searchForm = _.cloneDeep(searchModel);
        if (res) this.init();
      });
    },
    init() {
      this.RESET_DIALOG();
      this.currDialog = undefined;
      if (checkData(this.searchForm, false, true)) this.search();
    },
    changeTime(time) {
      this.startTime = time.startTime;
      this.endTime = time.endTime;
      this.search();
    },
    download() {
      getExcel(this.searchForm).then(res =>
        download(res, this.startTime + "memberPrivateMessage.xlsx")
      );
    }
  },
};
</script>

<style lang="scss" scoped>
.sort {
  cursor: pointer;
}
</style>