import request from "@/utils/request";
import { getToken } from "@/utils/auth";

export function getCompanyInfo() {
    return request({
        url: "CompanySettings/Info",
        method: "get",
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function updateDomain(data) {
    return request({
        url: "CompanySettings/UpdateDomain",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function updateGoogleAuthenticatorFeatureToggle(data) {
    return request({
        url: "CompanySettings/UpdateGoogleAuthenticatorFeatureToggle",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function updateBroadcastMemberWinThreshold(data) {
    return request({
        url: "CompanySettings/UpdateBroadcastMemberWinThreshold",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function updateMemberDefaultGateway(data) {
    return request({
        url: "CompanySettings/NewUpdateMemberDefaultGateway",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function updateRegisterSNSFeatureToggle(data) {
    return request({
        url: "CompanySettings/UpdateRegisterSNSFeatureToggle",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function updateExportReportPasswordFeatureToggle(data) {
    return request({
        url: "CompanySettings/UpdateExportReportPasswordFeatureToggle",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function updatePointWallet(data) {
    return request({
        url: "CompanySettings/UpdatePointWallet",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function updateBindingCvsCard(data) {
    return request({
        url: "CompanySettings/UpdateBindingCvsCard",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function updateWelcomeMessage(data) {
    return request({
        url: "CompanySettings/UpdateWelcomeMessage",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}