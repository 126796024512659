<template>
  <!-- 會員活動資訊 -->
  <div class="d-flex form-column align-items-center h-full" style="width: 90%;">
    <div class="table-block-white-v2">
      <table>
        <thead>
          <tr>
            <th colspan="3" style="width: 100%;">{{ $t(`${i18nPrefix}dialog.detail.memberActivity`) }}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              {{ $t(`${i18nPrefix}recommend`) }}
            </td>
            <td>
              <span v-show="editChooice !== 'referralCode'">{{ list.referralCode }}</span>
              <input type="text" v-model="referralCode" v-show="editChooice == 'referralCode'">
            </td>
            <td>
              <button class="edit-btn" :disabled="editChooice !== undefined" @click="edit('referralCode')"
                v-show="editChooice !== 'referralCode'">
                {{ $t("default.edit") }}
              </button>
              <div v-show="editChooice == 'referralCode'">
                <button class="normal-btn" @click="confirm('referralCode')">
                  {{ $t("default.confirm") }}
                </button>
                <button class="delete-btn" @click="cancel('referralCode')">
                  {{ $t("default.cancel") }}
                </button>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              {{ $t(`${i18nPrefix}dialog.detail.referralCount`) }}
            </td>
            <td>
              {{ list.referralCount }}
            </td>
            <td>
            </td>
          </tr>
          <tr>
            <td>
              {{ $t(`${i18nPrefix}dialog.detail.referrer`) }}
            </td>
            <td>
              {{ list.referrer }}
            </td>
            <td>
            </td>
          </tr>
          <tr>
            <td>
              {{ $t("title.luckydraw") }}
            </td>
            <td>
              <router-link :to="{
                path: '/campaign/luckydraw',
                query: { bookmarkNum: 5 }
              }">
                {{ $t("title.luckydraw") }}
              </router-link>
            </td>
            <td>
            </td>
          </tr>
          <tr>
            <td>
              {{ $t("title.memberSignIn") }}
            </td>
            <td>
              <router-link :to="{
                path: '/campaign/memberSignIn',
                query: { memberAccount: memberData.memberName, bookmarkNum: 3 }
              }">
                {{ $t("title.memberSignIn") }}
              </router-link>
            </td>
            <td>
            </td>
          </tr>
          <tr>
            <td>
              {{ $t("title.memberReferral") }}
            </td>
            <td>
              <router-link :to="{
                path: '/campaign/memberReferral',
                query: { memberAccount: memberData.memberName, bookmarkNum: 4 }
              }">
                {{ $t("title.memberReferral") }}
              </router-link>
            </td>
            <td>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { updateReferralCode } from "@/api/member/member";

export default {
  name: "MemberActivity",
  props: ['list', "memberData", 'chooice', 'i18nPrefix'],
  data() {
    return {
      editChooice: undefined,
      referralCode: undefined,
    };
  },
  watch: {
    'chooice'(v) {
      this.editChooice = v;
    }
  },
  components: {
  },
  computed: {
  },
  created() {
  },
  methods: {
    edit(k) {
      this.editChooice = k;

      switch (k) {
        case 'referralCode':
          this.referralCode = this.list.referralCode;
      }

      this.$emit('editting', k);
    },
    async confirm(k) {
      let result = false;
      switch (k) {
        case 'referralCode':
          await updateReferralCode({ id: this.memberData.id, referralCode: this.referralCode }).then(res => {
            if (res) result = true;
          });
          break;
      }

      if (result) this.init();
    },
    cancel(k) {
      this.editChooice = undefined;

      switch (k) {
        case 'referralCode':
          this.referralCode = undefined;
          break;
      }

      this.$emit('editting', undefined);
    },
    init() {
      this.editChooice = undefined;

      this.$emit('editting', undefined);
      this.$emit('reload');
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/form.scss";

tr {
  td:first-of-type {
    width: 25%;
  }

  td:nth-of-type(2) {
    width: 50%;
  }

  td:last-of-type {
    width: 25%;
  }
}

.h-full {
  height: 100%;
}
</style>