<template>
  <div>
    <div class="container-view">
      <div class="top-block">
        <div class="card-box">
          <div class="title">
            {{ $t(`${i18nTablePrefix}accountFor`) }}
          </div>
          <div class="count-box">
            <div class="count-row">
              <button class="btn-pending">
                {{ $t(`${i18nPrefix}auditStatus.1`) }}
              </button>
            </div>
            <div class="count-row">
              <div class="count">{{ rebateCount.pendingAudit }}</div>
            </div>
            <div class="count-row">
              <button class="confirm-btn main-style-btn px-4" :class="{
                'btn-disabled':
                  !$store.getters.getUserinfo.permissions.includes(20601),
              }" @click="
  alertMsg = $store.getters.getUserinfo.permissions.includes(
    20601
  )
    ? 'auditAll'
    : ''
  ">
                {{ $t(`${i18nPrefix}auditAll`) }}
              </button>
            </div>
          </div>
          <div class="count-box">
            <div class="count-row">
              <button class="btn-red">
                {{ $t(`${i18nPrefix}auditStatus.2`) }}
              </button>
            </div>
            <div class="count-row">
              <div class="count">{{ rebateCount.pendingPayout }}</div>
            </div>
            <div class="count-row">
              <button class="confirm-btn main-style-btn px-4" :class="{
                'btn-disabled':
                  !$store.getters.getUserinfo.permissions.includes(20602),
              }" @click="
  alertMsg = $store.getters.getUserinfo.permissions.includes(
    20602
  )
    ? 'payoutAll'
    : ''
  ">
                {{ $t(`${i18nPrefix}payoutAll`) }}
              </button>
            </div>
          </div>
        </div>
        <div class="search-block" v-if="getUserinfo.koreanStyleInterface">
          <div class="input-container">
            <p>{{ $t("member.member-deposit.submitStartTime") }}</p>
            <date-picker v-model="timeField.startTime" format="YYYY-MM-DD HH:mm" value-type="format"
              type="datetime"></date-picker>
          </div>
          <div class="input-container">
            <p>{{ $t("member.member-deposit.submitEndTime") }}</p>
            <date-picker v-model="timeField.endTime" format="YYYY-MM-DD HH:mm" value-type="format"
              type="datetime"></date-picker>
          </div>
          <button class="confirm-btn main-style-btn" @click="search">
            {{ $t("default.search") }}
          </button>
        </div>
      </div>
    </div>
    <div class="container-view">
      <div class="header">
        <h5>{{ $t("title." + $route.meta.title) }}</h5>
      </div>
      <main>
        <div class="table-block">
          <TreeTable ref="treeTable" :list="allData" :column="tableColumn">
            <template v-slot:column_checkoutMethod="props">
              <button class="btn btn-orange">
                {{
                  GLOBAL.i18nCheckName(
                    GLOBAL.settlement.find(
                      (data) => props.commissionCalculationCycle === data.id
                    )
                  )
                }}
              </button>
            </template>
            <template v-slot:column_totalProfit="props">
              <button class="table-btn main-style-btn" @click="showProfitDetail(props)">
                {{ $t(`default.detail`) }}
              </button>
            </template>
            <template v-slot:column_history="props">
              <button class="download-btn main-style-btn" @click="showTree(props)">
                {{ $t(`${i18nPrefix}pendingAuditRebate`) }}
              </button>
            </template>
            <template v-slot:column_divider="props">
              <div class="d-flex justify-content-center">
                <div class="table-divider"></div>
              </div>
            </template>
            <template v-slot:column_pendingAuditRebate="props">
              <div v-if="props.undoneRebates" class="table-row">
                <button v-for="item of props.undoneRebates" :key="item.id" class="btn btn-grey mr-1" :class="{
                  'btn-grey': item.auditStatus === 1,
                  'btn-red': item.auditStatus === 2,
                }" @click="
  showEdit(item.auditStatus, props.id, props.account, item.id)
  ">
                  {{ `${item.createTime}` }}
                  <br />
                  {{ $t(`${i18nPrefix}auditStatus.${item.auditStatus}`) }}
                </button>
              </div>
            </template>
          </TreeTable>
          <serverPagination v-if="allData" :page="page" @ServerPageUtils="getList" @changeTake="changeTake">
          </serverPagination>
        </div>
      </main>
    </div>
    <transition name="fade" mode="out-in">
      <component :is="currDialog" :lang="lang" :i18nPrefix="i18nPrefix" :profitDetail="profitDetail" v-bind="dialogData"
        @toggle="currDialog = undefined" @getList="select"></component>
    </transition>
    <!-- 確認彈出視窗 -->
    <transition name="fade" mode="out-in">
      <Alert v-if="alertMsg === 'auditAll'" :message="$t(`${i18nPrefix}auditAllMsg`)" @toggle="alertMsg = undefined"
        @doIt="runAuditAll" />
    </transition>
    <!-- 確認彈出視窗 -->
    <transition name="fade" mode="out-in">
      <Alert v-if="alertMsg === 'payoutAll'" :message="$t(`${i18nPrefix}payoutAllMsg`)" @toggle="alertMsg = undefined"
        @doIt="runPayoutAll" />
    </transition>
  </div>
</template>

<script>
import Alert from "@/layout/components/Alert";
import { mapGetters, mapMutations } from "vuex";
import {
  getList,
  getCount,
  auditAll,
  payoutAll,
} from "@/api/agent-management/agent-rebateCalcV2";
import { searchModel } from "./model";
import TreeHistory from "./dialog/treeHistory.vue";
import TreeAudit from "./dialog/treeAudit.vue";
import TreePayout from "./dialog/treePayout.vue";
import ProfitDetail from "./dialog/ProfitDetail.vue";
import serverPagination from "@/layout/components/serverPagination";
import handleServerPageUtils from "@/layout/mixins/handleServerPageUtils";
import handleDialog from "@/layout/mixins/handleDialog";
import TreeTable from "@/layout/components/TreeTable";
import { joinT, transToday } from "@/utils/transDateUtils";

export default {
  name: "AgentRebateCalc",
  data() {
    return {
      searchForm: _.cloneDeep(searchModel),
      timeField: {
        startTime: transToday("from"),
        endTime: transToday("to"),
      },
      starLevelList: undefined,
      i18nPrefix: "agent.agent-rebateCalc.",
      i18nTablePrefix: "agent.agent-management.",
      agentId: undefined,
      alertMsg: undefined,
      rebateCount: {
        pendingAudit: 0,
        pendingPayout: 0,
      },
      profitDetail: undefined
    };
  },
  components: {
    Alert,
    TreeHistory,
    TreeAudit,
    TreePayout,
    serverPagination,
    TreeTable,
    ProfitDetail
  },
  computed: {
    ...mapGetters(["getUserinfo", "lang"]),
    tableColumn() {
      const kr = [
        {
          key: "id",
          name: this.$t(`default.sort`),
          width: 100,
        },
        {
          key: "account",
          name: this.$t(`${this.i18nTablePrefix}agent_account`),
          isText: true,
        },
        {
          key: "checkoutMethod",
          name: this.$t(`${this.i18nTablePrefix}checkout_method`),
          width: 120,
        },
        {
          key: "totalProfit",
          name: this.$t("default.total_profit"),
        },
        {
          key: "history",
          name: this.$t("member.member-management.history"),
        },
        {
          key: "divider",
          width: 3,
        },
        {
          key: "pendingAuditRebate",
          name: this.$t(`${this.i18nTablePrefix}accountFor`),
          align: "left",
        },
      ];
      const other = [
        {
          key: "id",
          name: "ID",
          width: 100,
        },
        {
          key: "account",
          name: this.$t(`${this.i18nTablePrefix}account`),
          align: "left",
          isText: true,
        },
        {
          key: "checkoutMethod",
          name: "checkout method",
          width: 120,
        },
        {
          key: "history",
          name: this.$t("member.member-management.history"),
        },
        {
          key: "divider",
          width: 3,
        },
        {
          key: "pendingAuditRebate",
          name: this.$t(`${this.i18nTablePrefix}accountFor`),
          align: "left",
        },
      ];
      return this.getUserinfo.koreanStyleInterface ? kr : other;
    },
  },
  mixins: [handleServerPageUtils, handleDialog],
  async created() {
    this.agentId = this.getUserinfo.parentId;
    await this.select();
  },
  methods: {
    ...mapMutations({
      TOGGLE_CONFIRM: "confirm/TOGGLE_CONFIRM",
      RESET_CONFIRM: "confirm/RESET_CONFIRM",
    }),
    async select() {
      this.getList(this.page.current);
      this.getCount();
    },
    getCount() {
      getCount().then((res) => {
        this.rebateCount.pendingAudit = res.data.pendingAuditRebate;
        this.rebateCount.pendingPayout = res.data.pendingPayoutRebate;
      });
    },
    getList(page = false) {
      if (this.getUserinfo.koreanStyleInterface) {
        this.searchForm.startTime = joinT(
          this.timeField.startTime +
          this.GLOBAL.startSecond +
          this.GLOBAL.timeZone
        );
        this.searchForm.endTime = joinT(
          this.timeField.endTime + this.GLOBAL.endSecond + this.GLOBAL.timeZone
        );
      }
      this.searchForm.page.take = this.take * 1;
      this.searchForm.page.skip = this.take * (page ? page - 1 : 0);

      getList(this.searchForm).then((res) => {
        this.allData = res.data.list;
        this.page = res.data.page;
      });
    },
    async search() {
      await this.getList();
    },
    showTree(data) {
      this.handleDialog(
        { agentId: data.id, mainAccount: data.account },
        "TreeHistory"
      );
    },
    showEdit(auditStatus, agentId, account, rebateId) {
      let currDialog;
      if (auditStatus === 1) {
        currDialog = "TreeAudit";
      } else if (auditStatus === 2) {
        currDialog = "TreePayout";
      } else {
        return true;
      }

      this.handleDialog(
        { agentId: agentId, mainAccount: account, rebateId: rebateId },
        currDialog
      );
    },
    showProfitDetail(data) {
      this.profitDetail = data.profitDetail;
      this.handleDialog(null, "ProfitDetail")
    },
    runAuditAll() {
      this.alertMsg = undefined;
      auditAll().then((res) => {
        this.select();
      });
    },
    runPayoutAll() {
      this.alertMsg = undefined;
      payoutAll().then((res) => {
        this.select();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/button.scss";

.top-block {
  display: flex;
  justify-content: space-between;

  .card-box {
    width: 49.5%;
    background-color: #fff;
    border: 2px solid #7e96a3;
    border-radius: 8px;
    padding: 10px 30px;
    display: inline-block;

    .title {
      color: #3d4f59;
      font-size: 24px;
      font-weight: 600;
    }

    .count-box {
      padding: 10px 0;
      display: flex;
      justify-content: space-between;

      .count-row {
        display: flex;
        justify-content: space-between;
        margin-bottom: 3px;

        .count {
          color: #3d4f59;
          font-size: 20px;
          font-weight: 600;
        }
      }
    }
  }

  .search-block {
    align-self: flex-start;
    display: flex;
    width: 49.5%;
    align-items: flex-end;

    .input-container {
      width: 45%;
      margin-right: 5px;

      p {
        margin-bottom: 0px;
      }
    }

    button {
      width: 10%;
    }
  }
}

main {
  label {
    justify-content: flex-start;
    width: 20%;
  }
}

.agent {
  color: blue;
  text-decoration: underline !important;
  font-weight: bold;
  cursor: pointer;
}

.table-divider {
  background-color: #7a7a7a;
  height: 50px;
  width: 3px;
}

@media screen and (max-width: 1280px) {
  .input-block {
    label {
      width: 45% !important;
      margin: 5px auto;
    }

    .deleteBox {
      width: 10%;
    }
  }
}

@media screen and (max-width: 768px) {
  .card-box {
    width: 100%;
  }
}
</style>
