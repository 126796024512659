<template>
  <div>
    <div class="container-view">
      <button class="search-btn main-style-btn" @click="search(false, true)">{{ $t("default.search") }}</button>
      <div class="header" style="justify-content: flex-start;">
        <h5 style="margin-right: 5px;">{{ $t("title." + $route.meta.title) }}</h5>
        <img class="switch-btn memberSpan" v-if="!searchBar" src="@/assets/switchOn.png" @click="searchBar = true">
        <img class="switch-btn memberSpan" v-else src="@/assets/switchOff.png" @click="searchBar = false">
        <div class="float-right">
          <button class="download-btn main-style-btn" v-if="!(getUserinfo.koreanStyleInterface && getUserinfo.role === 2)"
            :disabled="!getUserinfo.permissions.includes(10220)"
            @click="handleDialog({ fileName: startCreateTime + 'MemberReport.xlsx', data: searchForm, toogle: getUserinfo.exportReportPasswordFeatureToggle, downloadApi: getMemberExcel }, 'Download')">
            <img class="icon" src="@/assets/download.svg" />
            {{ $t('default.download') }}
          </button>
          <button class="create-btn main-style-btn" @click="handleDialog(undefined, 'Create')"
            v-if="!(getUserinfo.koreanStyleInterface && getUserinfo.role === 2)"
            :disabled="!getUserinfo.permissions.includes(10101)">{{ $t("default.create") }}
          </button>
        </div>
      </div>
      <!-- <div class="divider"></div> -->
      <div class="main">
        <!-- <div
          style="right: 35px !important; position: absolute; float: right !important; height: 30px; width: 30px; top: 90px;">
          <button class="w-100" v-if="!searchBar" @click="searchBar = true" style="background-color: orange;">▼</button>
          <button class="w-100" v-else @click="searchBar = false" style="background-color: orange;">▲</button>
        </div> -->
        <div class="input-block">
          <label>
            <div class="input-block-check">
              <span style="margin-right: 10px;">{{ $t(`${i18nPrefix}account`) }}</span>
              <input id="isFuzzySearch" type="checkbox" v-model="searchForm.isFuzzySearch" />
              <label for="isFuzzySearch">{{ $t("default.isFuzzySearch") }}</label>
            </div>
            <input type="text" v-model="searchForm.account" />
          </label>
          <label>
            {{ $t(`${i18nPrefix}startRegisterTime`) }}
            <date-picker v-model="startCreateTime" format="YYYY-MM-DD HH:mm" value-type="format"
              type="datetime"></date-picker>
          </label>
          <label>
            {{ $t(`${i18nPrefix}endRegisterTime`) }}
            <date-picker v-model="endCreateTime" format="YYYY-MM-DD HH:mm" value-type="format"
              type="datetime"></date-picker>
          </label>
          <div class="input-block-multiselect">
            {{ $t("default.agent") }}
            <multiselect v-model="agentData" label="name" track-by="id"
              :placeholder="$t(`${i18nPrefix}dialog.bank.choose`)" :options="getAgentListAddDefault" :allow-empty="false"
              :preselectFirst="true" :clear-on-select="false" :preserve-search="true" :custom-label="nameWithLang"
              :option-height="36">
            </multiselect>
          </div>
          <template v-if="searchBar">
            <label v-if="getUserinfo.koreanStyleInterface">
              {{ $t("default.nickName") }}
              <input type="text" v-model="searchForm.nickName" />
            </label>
            <label>
              {{ $t(`${i18nPrefix}bank`) }}
              <select v-model="searchForm.bankId">
                <option v-for="list of getBankListAddDefault" :key="list.id" :value="list.id">{{ $t(list.name) }}
                </option>
              </select>
            </label>
            <label>
              {{ $t(`${i18nPrefix}bankName`) }}
              <input type="text" v-model="searchForm.bankAccountName" />
            </label>
            <label>
              {{ $t(`${i18nPrefix}bankAccount`) }}
              <input type="text" v-model="searchForm.bankAccountNumber" />
            </label>
            <label>
              {{ $t(`${i18nPrefix}recommend`) }}
              <input type="text" v-model="searchForm.referralCode" />
            </label>
            <label>
              {{ $t("default.tag") }}
              <select v-model="searchForm.tagId">
                <option :value="0 * 1">{{ $t("default.all") }}</option>
                <option v-for="list of tagList" :key="list.id" :value="list.id * 1">{{ list.name }}</option>
              </select>
            </label>
            <template v-if="!getUserinfo.koreanStyleInterface">
              <label>
                {{ $t(`${i18nPrefix}group`) }}
                <select v-model="searchForm.bankGroupId">
                  <option v-for="list of getBankGroupListAddDefault" :key="list.id" :value="list.id">{{ $t(list.name) }}
                  </option>
                </select>
              </label>
              <label>
                {{ $t(`${i18nPrefix}mail`) }}
                <input type="text" v-model="searchForm.email" />
              </label>
            </template>
            <label>
              {{ $t(`${i18nPrefix}ip`) }}
              <input type="text" v-model="searchForm.ip" />
            </label>
            <template v-if="getUserinfo.koreanStyleInterface">
              <label>
                {{ $t(`${i18nPrefix}wallet`) }}
                <select v-model="searchForm.mainWallet">
                  <option value="0">{{ $t("default.none") }}</option>
                  <option value="1">{{ $t("default.pointBig") }}</option>
                  <option value="2">{{ $t("default.pointSmall") }}</option>
                </select>
              </label>
              <label>
                {{ $t(`${i18nPrefix}pointWallet`) }}
                <select v-model="searchForm.pointsWallet">
                  <option value="0">{{ $t("default.none") }}</option>
                  <option value="1">{{ $t("default.pointBig") }}</option>
                  <option value="2">{{ $t("default.pointSmall") }}</option>>
                </select>
              </label>
              <label>
                {{ $t(`${this.i18nPrefix}dialog.historyList.totalWinLose`) }}
                <select v-model="searchForm.totalWinning">
                  <option value="0">{{ $t("default.none") }}</option>
                  <option value="1">{{ $t("default.pointBig") }}</option>
                  <option value="2">{{ $t("default.pointSmall") }}</option>
                </select>
              </label>
            </template>
          </template>
          <template v-if="getUserinfo.permissions.includes(10107) && searchBar">
            <label>
              {{ $t(`${i18nPrefix}phone`) }}
              <input type="text" v-model="searchForm.phoneNumber" />
            </label>
            <label>
              {{ $t(`${i18nPrefix}name`) }}
              <input type="text" v-model="searchForm.name" />
            </label>
            <label v-if="!getUserinfo.koreanStyleInterface">
              {{ $t(`${i18nPrefix}line`) }}
              <input type="text" v-model="searchForm.socialId" />
            </label>
            <label>
              {{ $t(`${i18nPrefix}starLevel`) }}
              <input type="text" v-model="searchForm.star" />
            </label>
          </template>
        </div>
        <div class="advance-block">
          <QuickSearch v-if="!getUserinfo.koreanStyleInterface" @changeTime="changeTime"></QuickSearch>
          <button class="search-pc-btn main-style-btn px-5" @click="search(false, true)">{{ $t("default.search")
          }}</button>
        </div>
      </div>
    </div>
    <div class="container-view">
      <div class="total-block-v2">
        <div class="total-block-v2-item" v-if="!getUserinfo.koreanStyleInterface">
          <div class="total-block-v2-item-left">{{ $t("default.totalDataCount") }}:</div>
          <div class="total-block-v2-item-right">{{ page.total ?? 0 }}</div>
        </div>
        <template v-if="false">
          <div class="total-block-v2-item">
            <div class="total-block-v2-item-left">{{ $t(`${i18nPrefix}inReviewCount`) }}:</div>
            <div class="total-block-v2-item-right">{{ statusCountData.inReviewCount ?? 0 }}</div>
          </div>
          <div class="total-block-v2-item">
            <div class="total-block-v2-item-left">{{ $t(`${i18nPrefix}approvedCount`) }}:</div>
            <div class="total-block-v2-item-right">{{ statusCountData.approvedCount ?? 0 }}</div>
          </div>
          <div class="total-block-v2-item">
            <div class="total-block-v2-item-left">{{ $t(`${i18nPrefix}rejectedCount`) }}:</div>
            <div class="total-block-v2-item-right">{{ statusCountData.rejectedCount ?? 0 }}</div>
          </div>
        </template>
      </div>
      <div class="table-block">
        <table :class="{ KoreanStyle: getUserinfo.koreanStyleInterface }">
          <thead>
            <tr>
              <!-- <th
                class="mw-2words sort"
                @click="dataSort('star', 'number')"
              >{{ $t(`${i18nPrefix}account`) }} {{ direction("star") }}</th>-->
              <th v-if="getUserinfo.koreanStyleInterface">{{ $t("default.sort") }}</th>
              <th class="mw-3words">{{ $t(`${i18nPrefix}agentFrom`) }}</th>
              <th>{{ $t("default.tag") }}</th>
              <th class="mw-2words">{{ $t(`${i18nPrefix}account`) }}</th>
              <th class="mw-2words" v-if="getUserinfo.koreanStyleInterface">{{ $t("default.nickName") }}</th>
              <th>{{ $t(`${i18nPrefix}accountBank`) }}</th>
              <th v-if="getUserinfo.koreanStyleInterface">{{ $t("default.bankAccountNumber") }}</th>
              <th v-if="getUserinfo.bindingCvsCard">{{ $t("default.store") }}</th>
              <th>{{ $t("default.detail") }}</th>
              <th class="mw-3words">{{ $t(`${i18nPrefix}wallet`) }}</th>
              <th class="mw-3words" v-if="getUserinfo.pointWallet">{{ $t(`${i18nPrefix}pointWallet`) }}</th>
              <th style="min-width: 150px !important;">{{ $t("default.status") }}</th>
              <th v-if="getUserinfo.koreanStyleInterface">{{ $t(`${i18nPrefix}ip`) }}</th>
              <th v-if="getUserinfo.koreanStyleInterface">{{ $t(`${i18nPrefix}device`) }}</th>
              <th v-if="!getUserinfo.koreanStyleInterface">{{ $t(`${i18nPrefix}recommend`) }}</th>
              <th v-if="!getUserinfo.koreanStyleInterface">{{ $t(`${i18nPrefix}createFrom`) }}</th>
              <th>{{ $t(`${i18nPrefix}createTime`) }}</th>
              <th v-if="!getUserinfo.pointWallet" class="mw-3words">{{ $t(`${i18nPrefix}history`) }}</th>
              <th v-if="getUserinfo.koreanStyleInterface">{{ $t("default.total_deposit") }}</th>
              <th v-if="getUserinfo.koreanStyleInterface">{{ $t("default.total_withdrawl") }}</th>
              <th v-if="getUserinfo.koreanStyleInterface">{{ $t("default.total_profit") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="!allData || !allData.length">
              <td colspan="999">{{ $t("default.noData") }}</td>
            </tr>
            <tr v-for="(list, index) of allData" :key="index">
              <td v-if="getUserinfo.koreanStyleInterface">{{ list.id }}</td>
              <td>
                <div class="block-between">
                  <div class="block-between-box">
                    <div class="d-flex flex-column">
                      <span>{{ list.agentAccount }}</span>
                      <span>
                        {{ GLOBAL.i18nCheckName(getAgentListAddDefault.find(data => data.account == list.agentAccount))
                        }}
                      </span>
                    </div>
                    <div class="table-icon" @click="handleDialog(list, 'Agent')">
                      <img src="@/assets/icon/edit.svg">
                    </div>
                  </div>
                </div>
              </td>
              <td>
                <div>
                  <button class="table-btn main-style-btn w-100"
                    @click="handleDialog({ id: list.id, Tags: tagList, haveTag: list.tags }, 'EditTag')"
                    v-show="getUserinfo.permissions.includes(10113)">
                    {{ $t(`${i18nPrefix}editTag`) }}
                  </button>
                  <Tags v-if="list.tags" :tags="list.tags" :tagList="tagList" :isColumn="true"></Tags>
                </div>
              </td>
              <td>
                <div class="block-between">
                  <!-- 階級 -->
                  <div class="block-between-box">
                    {{ list.star }} {{ $t("default.star") }}
                    <div class="table-icon" @click="handleDialog(list, 'level')">
                      <img src="@/assets/icon/edit.svg">
                    </div>
                  </div>
                  <!-- 暱稱 -->
                  {{ list.memberName }}
                  <!-- 帳號 -->
                  <div class="block-between-box">
                    {{ list.account }}
                    <div class="d-flex">
                      <div class="table-icon mr-1" @click="copyAccount(list.account)">
                        <img src="@/assets/icon/copy.svg">
                      </div>
                      <div class="table-icon" @click="handleDialog(list, 'Edit')"
                        v-if="!(!getUserinfo.permissions.includes(10102) || !getUserinfo.permissions.includes(10107))">
                        <img src="@/assets/icon/edit.svg">
                      </div>
                    </div>
                  </div>
                  <button class="table-btn main-style-btn w-100" @click="handleDialog(list, 'Remark')">{{
                    $t("default.remark") }}</button>
                </div>
              </td>
              <td v-if="getUserinfo.koreanStyleInterface">{{ list.nickName }}</td>
              <!-- 銀行 -->
              <td class="banks-td">
                <div v-if="!getUserinfo.koreanStyleInterface">
                  <button class="table-btn main-style-btn" @click="handleDialog(list, 'CreateBank')"
                    v-show="getUserinfo.permissions.includes(10104)">{{ $t(`${i18nPrefix}createBank`) }}</button>
                  <div class="banks-div">
                    <div class="banks-div-item" v-for="(bankDetail, index) of list.banks" :key="index">
                      <button class="bank-btn"
                        :class="bankDetail.verifyStatus === 1 ? 'bank-btn-verify' : 'bank-btn-close'"
                        @click="handleDialog({ bankDetail: bankDetail, id: list.id }, 'EditBank')">
                        {{ bankDetail.bankType !== 9 ? bankDetail.bankName ? bankDetail.bankName :
                          GLOBAL.i18nCheckName(getBankTypeList.find((data) => {
                            return data.id === bankDetail.bankType
                          })) : bankDetail.walletName }}
                        {{ (bankDetail.bankBranch ? "(" + bankDetail.bankBranch + ")" : "") + "\n" }}
                        <!-- <span class="StatusTrue" v-if="bankDetail.verifyStatus === 1">{{ $t("default.isVerify") }}</span>
                        <span class="StatusFalse" v-else>{{ $t("default.noVerify") }}</span> -->
                        {{ (bankDetail.bankType !== 9 ? bankDetail.bankAccountName : bankDetail.currencyName) + "\n" }}
                        {{ bankDetail.bankAccountNumber }}
                      </button>
                      <div class="table-icon mr-1" @click="copyBankInfo(bankDetail.bankName ? bankDetail.bankName : GLOBAL.i18nCheckName(getBankTypeList.find((data) => {
                        return data.id === bankDetail.bankType;
                      })), bankDetail.bankAccountName, bankDetail.bankAccountNumber)">
                        <img src="@/assets/icon/copy.svg">
                      </div>
                    </div>
                  </div>
                </div>
                <div v-else>
                  <button class="table-btn main-style-btn" @click="handleDialog(list, 'CreateBank')"
                    v-show="getUserinfo.permissions.includes(10104) && list.banks?.length === 0">{{
                      $t(`${i18nPrefix}createBank`) }}</button>
                  <span @click="handleDialog({ bankDetail: list.banks[0], id: list.id }, 'EditBank')">
                    {{ list.banks[0]?.bankName }}
                  </span>
                </div>
              </td>
              <td v-if="getUserinfo.koreanStyleInterface">
                {{ list.banks[0]?.bankAccountNumber }}
              </td>
              <!-- 超商 -->
              <td class="banks-td" v-show="getUserinfo.bindingCvsCard">
                <div clas="text-left">
                  <button class="table-btn main-style-btn" @click="handleDialog(list, 'CreateCvs')"
                    v-show="getUserinfo.permissions.includes(10119)">{{ $t("default.create") }}</button>
                  <div class="banks-div">
                    <div class="banks-div-item" v-for="(CvsDetail, index) of list.cvsInfo" :key="index">
                      <button class="bank-btn bank-btn-verify" @click="
                        handleDialog(
                          { CvsDetail: CvsDetail },
                          'EditCvs'
                        )
                        " :disabled="!getUserinfo.permissions.includes(10120)">
                        {{ CvsDetail.name + " " + CvsDetail.city + "(" + CvsDetail.district + ")" + "\n" }}
                        {{ CvsDetail.code + " " + CvsDetail.storeName }}
                      </button>
                    </div>
                  </div>
                </div>
              </td>
              <td>
                <button class="table-btn main-style-btn" @click="handleDialog({ list: list }, 'Detail')">{{
                  $t("default.detail") }}</button>
              </td>
              <td style="cursor: pointer">
                <div @click="getUserinfo.permissions.includes(10114) ? handleDialog(list, 'BalanceAdjustment') : ''">{{
                  list.balance }}</div>

                <button class="table-btn main-style-btn" @click="handleDialog(list, 'GameWallet')" style=""
                  :disabled="!getUserinfo.permissions.includes(10115)">{{ $t(`${i18nPrefix}gameWallet`) }}</button>
                <br />
                <button class="table-btn main-style-btn" @click="handleDialog(list, 'RedPacket')" style=""
                  :disabled="!getUserinfo.permissions.includes(10117)">
                  {{ $t(`${i18nPrefix}dialog.balance-adjustment.adjustmentType4`) }}
                </button>
              </td>
              <td style="cursor: pointer" v-show="getUserinfo.pointWallet">
                <div @click="getUserinfo.permissions.includes(10118) ? handleDialog(list, 'pointWallet') : ''">{{
                  list.point }}</div>
              </td>
              <td class="status-td">
                <span v-if="getUserinfo.koreanStyleInterface">{{ GLOBAL.i18nCheckName(auditStatuses.find((data) =>
                  list.memberRegistrationReviewStatus == data.id)) }}</span>
                <div @click="getUserinfo.permissions.includes(10102) ? handleDialog(list, 'status') : ''">
                  <span>{{ list.status === 1 ? $t("default.active") : $t("default.inactive") }}</span>
                  <span v-if="list.isNew === 1">{{ $t(`${i18nPrefix}new`) }}</span>
                  <span v-if="list.enableSeamless === 1">{{ $t(`${i18nPrefix}seamless`) }}</span>
                  <span v-for="gateWays of list.paymentGateways" :key="gateWays.paymentGatewayId"
                    v-show="gateWays.enable">{{ $t("DepositTypeList." + gateWays.paymentGatewayId) }}</span>
                </div>
                <span v-if="list.isLogin" class="islogin">{{ $t(`${i18nPrefix}islogin`) }}</span>
                <button v-if="list.isLogin" class="kick-btn" @click="
                  getUserinfo.permissions.includes(10102) ? kick(list.id) : ''
                  ">{{ $t(`${i18nPrefix}kick`) }}</button>
              </td>
              <td v-if="!getUserinfo.koreanStyleInterface">{{ list.referralCode }}</td>
              <td v-if="!getUserinfo.koreanStyleInterface">{{ list.creator }}</td>
              <td v-if="getUserinfo.koreanStyleInterface">{{ list.lastLoginIp }}</td>
              <td v-if="getUserinfo.koreanStyleInterface">{{ list.device }}</td>
              <td>
                {{ list.createTime }}
              </td>
              <td v-if="!getUserinfo.koreanStyleInterface">
                <button class="table-btn main-style-btn w-100"
                  @click="handleDialog({ history: list.history }, 'HistoryList')">{{
                    $t("default.detail") }}</button>
                <br />
              </td>
              <td v-if="getUserinfo.koreanStyleInterface">{{ list.totalAtmTotalPayment }}</td>
              <td v-if="getUserinfo.koreanStyleInterface">{{ list.memberWithdrawalListAccount }}</td>
              <td v-if="getUserinfo.koreanStyleInterface">{{ list.totalProfit }}</td>
              <span v-if="list.memberRegistrationReviewStatus !== 2 && getUserinfo.koreanStyleInterface" class="blockDiv">
              {{ noticeMsg }}</span>
            </tr>
          </tbody>
        </table>
      </div>
      <serverPagination v-if="allData" :page="page" @ServerPageUtils="search" @changeTake="changeTake" />
    </div>

    <transition name="fade" mode="out-in">
      <component :stars="getStars" :gamePlatforms="getGamePlatforms" :getUserinfo="getUserinfo" :i18nPrefix="i18nPrefix"
        :is="currDialog" v-bind="dialogData" @toggle="currDialog = undefined" @editBank="editBank"
        @createBank="createBank" @createCvs="createCvs" @editCvs="editCvs" @deleteCvs="deleteCvs" @create="create"
        @edit="edit" @status="status" @agent="agent" @balanceAdjust="balanceAdjust" @redPacket="redPacket"
        @deleteBank="deleteBank" @updateWallet="init" @editTag="editTag" @updateRemark="updateRemark" @point="point"
        @updateLevel="updateLevel" @detailToggle="init"></component>
    </transition>
  </div>
</template>

<script>
import { searchModel } from "./model";
import {
  memberList,
  createMember,
  editMember,
  statusMember,
  createBank,
  editBank,
  deleteBank,
  createCvs,
  editCvs,
  deleteCvs,
  editAgent,
  kickMember,
  addTag,
  deleteTag,
  sendRedPacket,
  addMemberPoint,
  reduceMemberPoint,
  getMemberExcel,
  updateLevel,
  updateRemark,
  statusCount
} from "@/api/member/member";
import { createMemberBalanceAdjustment } from "@/api/member/member-balance-adjustment";
import Create from "./dialog/create";
import Edit from "./dialog/edit";
import EditTag from "./dialog/editTag";
import Remark from "./dialog/remark";
import Level from "./dialog/level";
import Status from "./dialog/status";
import Detail from "./dialog/detail";
import Agent from "./dialog/agent";
import CreateBank from "./dialog/bank/create-bank";
import EditBank from "./dialog/bank/edit-bank";
import CreateCvs from "./dialog/Cvs/createCvs";
import EditCvs from "./dialog/Cvs/editCvs";
import HistoryList from "./dialog/historyList";
import OperateList from "./dialog/operateList";
import LoginList from "./dialog/loginList/loginList";
import BalanceAdjustment from "./dialog/balance-adjustment.vue";
import RedPacket from "./dialog/redPacket.vue";
import PointWallet from "./dialog/pointWallet.vue";
import GameWallet from "./dialog/game-wallet/game-wallet.vue";
import Message from "@/layout/components/Message";
import Download from "@/layout/components/Download";
import Tags from "@/layout/components/tags";
import { mapGetters } from "vuex";
import serverPagination from "@/layout/components/serverPagination";
import handleServerPageUtils from "@/layout/mixins/handleServerPageUtils";
import handleDialog from "@/layout/mixins/handleDialog";
import { checkData } from "@/utils/checkData";
import QuickSearch from "@/layout/components/QuickSearch";
import { joinT, transToday } from "@/utils/transDateUtils";
import Multiselect from "vue-multiselect";

export default {
  name: "MemberManagement",
  components: {
    Tags,
    Create,
    Edit,
    EditTag,
    Status,
    Detail,
    Agent,
    Remark,
    Level,
    CreateBank,
    EditBank,
    CreateCvs,
    EditCvs,
    BalanceAdjustment,
    RedPacket,
    PointWallet,
    GameWallet,
    serverPagination,
    QuickSearch,
    HistoryList,
    LoginList,
    OperateList,
    Download,
    Multiselect
  },
  mixins: [handleServerPageUtils, handleDialog],
  data() {
    return {
      searchForm: _.cloneDeep(searchModel),
      startCreateTime: undefined,
      endCreateTime: undefined,
      copyText: "",
      agentData: [],
      searchBar: false,
      statusCountData: [],
      i18nPrefix: "member.member-management."
    };
  },
  computed: {
    ...mapGetters([
      "getBankListAddDefault",
      "getBankGroupListAddDefault",
      "getAgentListAddDefault",
      "getUserinfo",
      "getBankTypeList",
      "getStars",
      "tagList",
      "getGamePlatforms"
    ]),
    auditStatuses() {
      return [
        {
          id: 1,
          name: this.$t("default.underReview")
        },
        {
          id: 2,
          name: this.$t("default.accept")
        },
        {
          id: 3,
          name: this.$t("default.reject")
        }
      ];
    },
    noticeMsg() {
      return this.$t("default.noticeMsg");
    }
  },
  created() {
    this.checkOrderID();
    // this.search();
    if (this.getUserinfo.koreanStyleInterface) {
      this.firstSearch();
    }
  },
  watch: {
    "searchForm.phoneNumber": function (val, oldVal) {
      if (/^[0-9]*$/.test(val) === false) {
        this.searchForm.phoneNumber = oldVal ?? "";
        Message.error(this.$t("default.checkNum"), 1000);
      }
    },
    "searchForm.star": function (val, oldVal) {
      if (/^[0-9]*$/.test(val) === false && val !== undefined) {
        this.searchForm.star = oldVal ?? "";
        Message.error(this.$t("default.checkNum"), 1000);
      }
    }
  },
  methods: {
    getMemberExcel,
    search(page = false, IsMatchAccountExactly = false) {
      if (IsMatchAccountExactly && this.$route.query?.orderId)
        this.$router.push({ path: this.$route.path });
      if (this.checkOrderID()) return;
      this.searchForm.startCreateTime =
        this.startCreateTime && this.startCreateTime?.length !== 0
          ? joinT(
            this.startCreateTime +
            this.GLOBAL.startSecond +
            this.GLOBAL.timeZone
          )
          : undefined;
      this.searchForm.endCreateTime =
        this.endCreateTime && this.endCreateTime?.length !== 0
          ? joinT(
            this.endCreateTime + this.GLOBAL.endSecond + this.GLOBAL.timeZone
          )
          : undefined;
      this.searchForm.star =
        this.searchForm.star || this.searchForm.star === 0
          ? this.searchForm.star * 1
          : undefined;
      this.searchForm.agentId = this.agentData.id;
      this.searchForm.page.take = this.take * 1;
      this.searchForm.page.skip = this.take * (page ? page - 1 : 0);
      if (checkData(this.searchForm)) {
        memberList(this.searchForm).then(res => {
          this.allData = res.data.list;
          this.page = res.data.page;
          this.getStatusCount();
        });
      }
    },
    create(data) {
      createMember(data).then(res => {
        if (res) this.init();
      });
    },
    edit(data) {
      data.winLoseDaily = parseInt(data.winLoseDaily);
      data.maxWinLoseDaily = parseInt(data.maxWinLoseDaily);
      editMember(data).then(res => {
        if (res) this.init();
      });
    },
    status(data) {
      statusMember(data).then(res => {
        if (res) this.init();
      });
    },
    createBank(data) {
      createBank(data).then(res => {
        if (res) this.init();
      });
    },
    editBank(data) {
      editBank(data).then(res => {
        if (res) this.init();
      });
    },
    deleteBank(data) {
      deleteBank({ id: data }).then(res => {
        if (res) this.init();
      });
    },
    createCvs(data) {
      createCvs(data).then(res => {
        if (res) this.init();
      });
    },
    editCvs(data) {
      editCvs(data).then(res => {
        if (res) this.init();
      });
    },
    deleteCvs(data) {
      deleteCvs({ id: data }).then(res => {
        if (res) this.init();
      });
    },
    agent(data) {
      editAgent(data).then(res => {
        if (res) this.init();
      });
    },
    balanceAdjust(data) {
      createMemberBalanceAdjustment(data).then(res => {
        if (res) this.init();
      });
    },
    redPacket(data) {
      sendRedPacket(data).then(res => {
        if (res) this.init();
      });
    },
    point(data) {
      if (data.type == 1) {
        addMemberPoint(data).then(res => {
          if (res) this.init();
        });
        return;
      }
      reduceMemberPoint(data).then(res => {
        if (res) this.init();
      });
    },
    init() {
      this.dialogData = undefined;
      this.RESET_DIALOG();
      if (checkData(this.searchForm, false, true)) this.search(this.page.current);
    },
    changeTime(time) {
      this.startCreateTime = time.startTime;
      this.endCreateTime = time.endTime;
      this.search(false, true);
    },
    kick(id) {
      kickMember({ Id: id }).then(res => {
        if (res) this.init();
      });
    },
    checkOrderID() {
      if (this.$route.query?.orderId) {
        memberList({ id: this.$route.query?.orderId * 1 }).then(res => {
          this.allData = res.data.list;
          this.page = res.data.page;
          this.currDialog = undefined;
        });
        return true;
      }
      return false;
    },
    async copyAccount(account) {
      navigator.clipboard.writeText(account);
      this.copyText = await navigator.clipboard.readText();
      Message.copy(`${account} ` + this.$t("default.isCopy"), 1500);
    },
    copyBankInfo(name, account, accountNumber) {
      const text = `${name} ${account} ${accountNumber}`;
      navigator.clipboard.writeText(text);
      Message.copy(`${text} ` + this.$t("default.isCopy"), 1500);
    },
    editTag(data) {
      if (data.type === "add") {
        addTag(data.data).then(res => {
          if (res) this.init();
        });
        return;
      }

      deleteTag(data.data).then(res => {
        if (res) this.init();
      });
    },
    updateRemark(data) {
      updateRemark(data).then(res => {
        if (res) this.init();
      });
    },
    nameWithLang({ name }) {
      return this.$t(name);
    },
    updateLevel(data) {
      updateLevel(data).then(res => {
        if (res) this.init();
      });
    },
    firstSearch() {
      this.searchForm.startCreateTime = joinT(transToday('from') + this.GLOBAL.startSecond + this.GLOBAL.timeZone);
      this.searchForm.endCreateTime = joinT(transToday('to') + this.GLOBAL.endSecond + this.GLOBAL.timeZone);
      this.searchForm.page.take = this.take * 1;
      this.searchForm.page.skip = 0;
      if (checkData(this.searchForm)) {
        memberList(this.searchForm).then(res => {
          this.allData = res.data.list;
          this.page = res.data.page;
          this.getStatusCount();
        });
      }
    },
    getStatusCount() {
      return
      statusCount(this.searchForm).then(res => this.statusCountData = res.data)
    },
  }
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss" scoped>
main {
  .input-block {
    h4 {
      margin-top: 40px;
      display: block;
    }
  }
}

.banks-td {
  width: 10%;
  max-width: 350px;

  .banks-div {
    // width: 100%;
    display: flex;
    flex-direction: column;
    max-height: 110px;
    overflow-y: auto;
    // margin: 3% 0;

    .banks-div-item {
      display: flex;
      align-items: center;
      justify-content: space-between;

      button {
        width: 90%;
        color: #fff;
        // border: 1px solid #e4e4e4;

        // &:hover {
        //   background-color: #e4e4e4;
        // }
      }

      .bank-btn-verify {
        border-radius: 4px;
        border: 1px solid #638110;
        background-color: #779C0C;
      }

      .bank-btn-close {
        border-radius: 4px;
        border: 1px solid #950900;
        background-color: #950900;
      }
    }
  }
}

.status-td {
  cursor: pointer;
  width: 10%;
  white-space: unset !important;

  div:first-child {
    overflow-y: auto;
    max-height: 120px;
  }

  span {
    display: inline-block;
    border-radius: 20px;
    border: 1px solid rgba(116, 154, 175, 0.75);
    color: #3D4F59;
    width: auto;
    margin: 5px 2px;
    font-size: 12px;
    padding: 2px 8px;
  }
}

//@media screen and (max-width: 960px) {
//  .input-block {
//    .more-info {
//      width: 100% !important;
//    }
//  }
//}

.bank-btn {
  white-space: pre-line;
  // margin: 2px;
  // padding: 2px;
  border-radius: 5px;
  background-color: transparent;
  border: 1px solid #fff;
  transition: 0.2s;
  cursor: pointer;

  .StatusFalse {
    display: inline;
    font-size: 14px;
    color: #fff;
    background-color: red;
    border: 1px solid #fff;
    border-radius: 5px;
    width: 50px;
    margin: 5px auto;
  }

  .StatusTrue {
    display: inline;
    font-size: 14px;
    color: #fff;
    background-color: Green;
    border: 1px solid #fff;
    border-radius: 5px;
    width: 50px;
    margin: 5px auto;
  }

  &:hover {
    background-color: #fff;
    color: #000;
  }

  &:disabled {
    cursor: not-allowed;
  }

  &:hover .StatusFalse {
    background-color: rgba(255, 0, 0, 0.479);
    color: white !important;
  }

  &:hover .StatusTrue {
    background-color: rgb(14, 185, 14);
    color: white !important;
  }
}

.kick-btn {
  background-color: #f44236;
  color: #fff5e5 !important;
  font-size: 14px;
  border: none;
  border-radius: 5px;
  width: 50px;
  height: 25px;
}

.islogin {
  font-size: 14px;
  color: rgb(18, 228, 35);
}

.agentBtn,
.copyBtn {
  font-size: 1rem !important;
}

.copyBtn {
  transition: 0.5s;

  &:hover {
    color: #c700c7;
  }
}

.copyed {
  color: #c700c7;
}

.switch-btn {
  width: 37.5px;
  height: 37.5px;
}

.blockDiv {
  z-index: 3;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  font-weight: bold;
  pointer-events: all;
}
</style>