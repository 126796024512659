export let searchModel = {
    memberStatus: 1,          // 會員帳號
    sortType: 1,       // 會員帳號模糊搜尋
    agentId: 0,                 // 代理
    isIncludeSubAgent: false,   // 是否包含下線代理
    startTime: undefined,
    endTime: undefined
}

const i18nPrefix = 'statistical-report.memberRank.sortTypeList.';
export let sortTypeList = [
    {
        id: 1,
        name: `${i18nPrefix}1`
    },
    {
        id: 2,
        name: `${i18nPrefix}2`
    },
    {
        id: 3,
        name: `${i18nPrefix}3`
    },
    {
        id: 4,
        name: `${i18nPrefix}4`
    },
    {
        id: 5,
        name: `${i18nPrefix}5`
    },
    {
        id: 6,
        name: "default.totalBetCount"
    }
]