<template>
  <div class="dialog-mask" @mousedown.self="$emit('toggle')">
    <div class="dialog-container">
      <div class="dialog-block">
        <main>
          <div class="input-block">
            <label>
              {{ $t("default.title") }}
              <input type="text" v-model="editModel.title" />
            </label>
            <label>
              {{ $t("default.sort") }}
              <input type="number" v-model.number="editModel.sortOrder" />
            </label>
            <label>
              {{ $t(`${i18nPrefix}titleColor`) }}
              <input class="color" type="color" v-model="editModel.titleColor" />
            </label>
            <label>
              {{ $t(`${i18nPrefix}backgroundColor`) }}
              <input class="color" type="color" v-model="editModel.backgroundColor" />
            </label>
          </div>
          <label class="w-100">
            {{ $t(`${i18nPrefix}content`) }}
            <ckeditor v-model="editModel.content" :editor-url="this.GLOBAL.CKEditorUrl"></ckeditor>
          </label>
          <div class="advance-block">
            <button class="normal-btn" @click="$emit('edit', editModel)">{{ $t("default.confirm") }}</button>
            <button class="delete-btn" @click="$emit('toggle')">{{ $t("default.cancel") }}</button>
          </div>
        </main>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "Edit",
  props: ["id", "title", "sortOrder", "content", "titleColor", "backgroundColor", "isBoldFont", "i18nPrefix"],
  data() {
    return {
      editModel: {
        id: this.id,
        title: this.title,
        backgroundColor: this.backgroundColor,
        titleColor: this.titleColor,
        sortOrder: this.sortOrder,
        content: this.content
      },
    }
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
.dialog-container {
  width: 60% !important;
  height: auto !important;

  .input-block {
    display: grid;
    grid-template-columns: repeat(2, 50%);
    grid-gap: 1em;

    .color {
      padding: 0 10px !important;
    }

    label {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      align-items: center !important;

      input {
        width: 80%;
      }
    }
  }
}
</style>