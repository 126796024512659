<template>
  <div>
    <div class="container-view">
      <button class="search-btn main-style-btn" @click="search()">下載</button>
      <div class="header">
        <h5>{{ $t("title." + $route.meta.title) }}</h5>
      </div>
      <div class="divider"></div>
      <div class="input-block">
        <label>
          起始日期(計算三個月)
          <date-picker v-model="startTime"
            format="YYYY-MM-DD"
            value-type="format"
            type="month"></date-picker>
        </label>
        <label>
          儲值狀態
          <select v-model="searchForm.haveDeposit">
            <option :value="false">未儲值</option>
            <option :value="true">已儲值</option>
          </select>
        </label>
      </div>
      <div class="advance-block">
        <button class="search-pc-btn main-style-btn px-5" @click="search()">{{ $t("default.search") }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  registeredMemberExcel
} from "@/api/accountingReport/registeredMemberExcel";
import { searchModel } from "./model";
import { mapGetters } from "vuex";
import { download } from "@/utils/download";

export default {
  name: "RegisteredMemberExcel",
  data() {
    return {
      searchForm: _.cloneDeep(searchModel),
      startTime: undefined,
    };
  },
  computed: {
    ...mapGetters([
      "getBankListAddDefault",
      "getAuditStatusList",
      "getUserinfo",
    ]),
  },
  methods: {
    search() {
      this.searchForm.startTime = this.startTime ? this.startTime + this.GLOBAL.startHour + this.GLOBAL.timeZone : undefined;
      registeredMemberExcel(this.searchForm).then(res => download(res, this.startTime + '_RegisteredMemberReport.xlsx'));
    },
  },
};
</script>

<style lang="scss" scoped>
.input-block {
  display: flex;
  label {
    display: flex;
    margin: 0 10px;
    flex-direction: column;
    select {
      height: 34px;
    }
  }
}
</style>