<template>
  <div>
    <div class="container-view">
      <button class="search-btn main-style-btn" @click="search()">{{ $t("default.search") }}</button>
      <div class="header">
        <h5>{{ $t("title." + $route.meta.title) }}</h5>
      </div>
      <div class="main">
        <div class="input-block">
          <label>
            {{ $t("default.member") }}
            <input type="text" v-model="searchForm.memberAccount" />
          </label>
          <label>
            {{ $t("default.admin") }}
            <select v-model="searchForm.auditAccountId">
              <option v-for="list of auditAccountList" :key="list.id" :value="list.id">{{ $t(list.name) }}</option>
            </select>
          </label>
          <label>
            {{ $t(`${i18nPrefix}startTime`) }}
            <date-picker v-model="startTime" format="YYYY-MM-DD HH:mm" value-type="format" type="datetime"></date-picker>
          </label>
          <label>
            {{ $t(`${i18nPrefix}endTime`) }}
            <date-picker v-model="endTime" format="YYYY-MM-DD HH:mm" value-type="format" type="datetime"></date-picker>
          </label>
          <!-- <label>
            狀態
            <select v-model="searchForm.auditStatus">
              <option
                v-for="list of getAuditStatusListAddDefault"
                :key="list.id"
                :value="list.id"
              >
                {{ list.name }}
              </option>
            </select>
          </label>-->
          <label>
            {{ $t(`${i18nPrefix}adjustmentType`) }}
            <select v-model="searchForm.adjustmentType">
              <option v-for="list of adjustmentTypeList" :key="list.id" :value="list.id * 1">{{ $t(list.name) }}
              </option>
            </select>
          </label>
          <label>
            {{ $t("default.tag") }}
            <select v-model="searchForm.tagId">
              <option :value="0 * 1">{{ $t("default.all") }}</option>
              <option v-for="list of tagList" :key="list.id" :value="list.id * 1">{{ list.name }}</option>
            </select>
          </label>
        </div>
        <div class="advance-block">
          <QuickSearch @changeTime="changeTime"></QuickSearch>
          <button class="search-pc-btn main-style-btn px-5" @click="search()">{{ $t("default.search") }}</button>
        </div>
      </div>
    </div>
    <div class="container-view">
      <div class="table-block">
        <table>
          <thead>
            <tr>
              <th v-if="$store.getters.getUserinfo.koreanStyleInterface">{{ $t("default.sort") }}</th>
              <th>{{ $t("default.member") }}</th>
              <th v-if="$store.getters.getUserinfo.koreanStyleInterface">{{ $t("default.nickName") }}</th>
              <th v-if="$store.getters.getUserinfo.koreanStyleInterface">{{ $t("default.star") }}</th>
              <th v-if="!$store.getters.getUserinfo.koreanStyleInterface">{{ $t("default.tag") }}</th>
              <th>{{ $t(`${i18nPrefix}adjustmentType`) }}</th>
              <th v-if="$store.getters.getUserinfo.koreanStyleInterface">{{ $t("default.amount") }}</th>
              <th>{{ $t(`${i18nPrefix}preTradeBalance`) }}</th>
              <th v-if="!$store.getters.getUserinfo.koreanStyleInterface">{{ $t("default.amount") }}</th>
              <th>{{ $t(`${i18nPrefix}afterTradeBalance`) }}</th>
              <!-- <th>狀態</th> -->
              <th v-if="!$store.getters.getUserinfo.koreanStyleInterface">{{ $t("default.remark") }}</th>
              <th>{{ $t("default.admin") }}</th>
              <th>{{ $t(`${i18nPrefix}auditTime`) }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="!allData || !allData.length">
              <td colspan="999">{{ $t("default.noData") }}</td>
            </tr>
            <tr v-for="list of allData" :key="list.id">
              <td v-if="$store.getters.getUserinfo.koreanStyleInterface">{{ list.id }}</td>
              <td>
                <span class="memberSpan"
                  @click="handleDialog({ list: { id: list.member.id, account: list.member.account, toggle: true } }, 'Detail')">{{
                    list.member.account }}
                </span>
              </td>
              <td v-if="$store.getters.getUserinfo.koreanStyleInterface">{{ list.member.nickName }}</td>
              <td v-if="$store.getters.getUserinfo.koreanStyleInterface">{{ list.member.star }}</td>
              <td :class="{ 'tags-td': Array.isArray(list.tags) }"
                v-if="!$store.getters.getUserinfo.koreanStyleInterface">
                <Tags v-if="list.tags" :tags="list.tags" :tagList="tagList"></Tags>
              </td>
              <td>
                {{
                  GLOBAL.i18nCheckName(adjustmentTypeList.find(
                    (data) => data.id === list.adjustmentType
                  ))
                }}
              </td>
              <td :class="fontColor(list.amount)" v-if="$store.getters.getUserinfo.koreanStyleInterface">{{ list.amount }}</td>
              <td>{{ list.preTradeBalance }}</td>
              <td :class="fontColor(list.amount)" v-if="!$store.getters.getUserinfo.koreanStyleInterface">{{ list.amount }}</td>
              <td>{{ list.afterTradeBalance }}</td>
              <!-- <td>{{ getAuditStatusListAddDefault.find((data) => data.id === list.auditStatus).name }}</td> -->
              <td v-if="!$store.getters.getUserinfo.koreanStyleInterface">{{ list.remark }}</td>
              <td>{{ list.auditAccount }}</td>
              <td>{{ list.auditTime }}</td>
            </tr>
            <tr v-if="allData">
              <td :colspan="$store.getters.getUserinfo.koreanStyleInterface ? 4 : 3"></td>
              <td>{{ $t("default.total") }}</td>
              <td :class="fontColor(totalAmount)">{{ digital.format(totalAmount) }}</td>
              <td colspan="4"></td>
            </tr>
          </tbody>
        </table>
      </div>
      <serverPagination v-if="allData" :page="page" @ServerPageUtils="search" @changeTake="changeTake" />
    </div>
    <transition name="fade" mode="out-in">
      <component :is="currDialog" v-bind="dialogData" @toggle="currDialog = undefined"></component>
    </transition>
  </div>
</template>

<script>
import {
  selectMenu,
  memberBalanceAdjustmentReportList
} from "@/api/report/member-balance-adjustment";
import { searchModel } from "./model";
import Tags from "@/layout/components/tags";
import { mapGetters } from "vuex";
import serverPagination from "@/layout/components/serverPagination";
import handleServerPageUtils from "@/layout/mixins/handleServerPageUtils";
import { transToday, joinT } from "@/utils/transDateUtils";
import QuickSearch from "@/layout/components/QuickSearch";
import handleDialog from "@/layout/mixins/handleDialog";
import Detail from "@/views/member/member-management/dialog/detail";


export default {
  name: "BalanceAdjustmentReport",
  components: {
    Tags,
    Detail,
    serverPagination,
    QuickSearch
  },
  mixins: [handleServerPageUtils, handleDialog],
  data() {
    return {
      searchForm: _.cloneDeep(searchModel),
      startTime: transToday("from"),
      endTime: transToday("to"),
      auditAccountList: [],
      adjustmentTypeList: [
        {
          id: 0,
          name: "default.all"
        },
        {
          id: 1,
          name: "report.balance-adjustment-report.balanceAdjustmentAdd"
        },
        {
          id: 2,
          name: "report.balance-adjustment-report.balanceAdjustmentReduce"
        },
        {
          id: 3,
          name: "report.balance-adjustment-report.deposit"
        }
      ],
      totalAmount: 0,
      i18nPrefix: "report.balance-adjustment-report."
    };
  },
  computed: {
    ...mapGetters([
      "getAuditStatusListAddDefault",
      "tagList",
      "getDefaultSelect"
    ])
  },
  created() {
    selectMenu().then(res => {
      this.auditAccountList = [
        this.getDefaultSelect,
        ...res.data.auditAccounts
      ];
    });
  },
  methods: {
    search(page = false) {
      this.searchForm.startTime = this.startTime
        ? joinT(this.startTime + this.GLOBAL.startSecond + this.GLOBAL.timeZone)
        : undefined;
      this.searchForm.endTime = this.endTime
        ? joinT(this.endTime + this.GLOBAL.endSecond + this.GLOBAL.timeZone)
        : undefined;

      this.searchForm.page.take = this.take * 1;
      this.searchForm.page.skip = this.take * (page ? page - 1 : 0);
      memberBalanceAdjustmentReportList(this.searchForm).then(res => {
        this.allData = res.data.records.list;
        this.page = res.data.records.page;
        this.totalAmount = res.data.totalAmount;
      });
    },
    changeTime(time) {
      this.startTime = time.startTime;
      this.endTime = time.endTime;
      this.search();
    },
    fontColor(value) {
      return value < 0 ? "red" : "green";
    }
  }
};
</script>

<style lang="scss" scoped></style>