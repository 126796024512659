<template>
  <div>
    <div class="container-view">
      <!-- <button class="search-btn main-style-btn" @click="search()">{{ $t("default.search") }}</button> -->
      <div class="header">
        <h5>{{ $t(`${i18nPrefix}welcomeMessage`) }}</h5>
        <div class="float-right">
          <button class="create-btn main-style-btn" @click="updateWelcomeMessage()"
            :disabled="!getUserinfo.permissions.includes(62603)">
            {{ $t("default.edit") }}
          </button>
        </div>
      </div>
      <div class="main">

      </div>
    </div>
    <div class="container-view">
      <div class="welcomeDiv">
        <label>
          {{ $t("default.title") }}
          <input type="text" v-model="title">
        </label>
        <label class="w-100">
          {{ $t(`${i18nPrefix}welcomeMessage`) }}
          <ckeditor v-model="content" :editor-url="this.GLOBAL.CKEditorUrl"></ckeditor>
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import { getWelcomeMessage, updateWelcomeMessage } from "@/api/features/batchSend";
import { mapGetters } from "vuex";

export default {
  name: "WelcomeMessage",
  data() {
    return {
      title: undefined,
      content: undefined,
      i18nPrefix: "features.memberPrivateMessage."
    };
  },
  components: {
  },
  computed: {
    ...mapGetters(["getUserinfo"]),
  },
  mixins: [],
  created() {
    this.search();
  },
  methods: {
    search() {
      getWelcomeMessage({}).then((res) => {
        this.title = res.data.title;
        this.content = res.data.content;
      });
    },
    updateWelcomeMessage() {
      updateWelcomeMessage({ title: this.title, content: this.content }).then(res => {
        if (res) this.search;
      })
    }
  },
};
</script>

<style lang="scss" scoped>
.welcomeDiv {
  display: flex;
  flex-direction: column;
  align-items: center;

  label {
    width: 50%;
    display: flex;
    flex-direction: column;
  }
}
</style>