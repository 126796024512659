<template>
  <div class="dialog-mask" @mousedown.self="$emit('toggle')">
    <div class="dialog-container">
      <div class="dialog-block">
        <div class="header">
          <h4>{{ $t("default.edit") + " " + $t("default.agent") }}</h4>
          <div class="header-btn">
            <button class="normal-btn" @click="createPromotionLink" :disabled="!(!!subDomain) || !!promotionLink">{{
              $t("default.create") + " " + $t(`${i18nPrefix}promotionLink`) }}</button>
            <button class="normal-btn" @click="handleRemove">{{ $t(`${i18nPrefix}dialog.remove`) }}</button>
          </div>
        </div>
        <div class="divider"></div>
        <main>
          <div class="input-block up-input">
            <div class="domain" v-if="!getUserinfo.koreanStyleInterface">
              <label class="checkbox-row m-0">
                <input type="checkbox" v-model="editModel.isSelfDomain">{{ $t(`${i18nPrefix}dialog.isSelfDomain`) }}
              </label>
              <template v-if="isGeneralAgent"><span>https://{{ agentDamain }}</span></template>
              <template v-else>
                <span>
                  https://
                  <input :class="{ 'selfDomain': editModel.isSelfDomain }" type="text" v-model="editModel.subDomain" />
                  {{ editModel.isSelfDomain ? '' : '.' + agentDamain }}
                </span>
              </template>
            </div>
            <label>
              {{ $t(`${i18nPrefix}name`) }}
              <input type="text" v-model="editModel.name" />
            </label>
            <label>
              {{ $t(`${i18nPrefix}account`) }}
              <input type="text" v-model="editModel.account" disabled />
            </label>
            <label>
              {{ $t(`${i18nPrefix}dialog.password`) }}
              <input type="text" v-model="editModel.password" />
            </label>
            <label>
              {{ $t(`${i18nPrefix}nickname`) }}
              <input type="text" v-model="editModel.nickName" />
            </label>
            <label>
              {{ $t(`${i18nPrefix}dialog.CommissionCalculationCycle`) }}
              <select v-model="editModel.commissionCalculationCycle">
                <option v-for="list of GLOBAL.settlement" :key="list.id" :value="list.id * 1">{{ $t(list.name) }}</option>
              </select>
            </label>
            <label>
              {{ $t(`${i18nPrefix}status`) }}
              <select v-model="editModel.status">
                <option v-for="list of getStatusList" :key="list.id" :value="list.id * 1">{{ $t(list.name) }}</option>
              </select>
            </label>
            <label>
              {{ $t(`${i18nPrefix}phoneNumber`) }}
              <input type="text" v-model="editModel.phoneNumber" />
            </label>
            <label>
              {{ $t(`${i18nPrefix}promotionLink`) }}
              <input type="text" v-model="promotionLink" disabled />
            </label>
            <label>
              {{ $t(`${i18nPrefix}discountCommissionRate`) }}
              <input type="number" v-model="editModel.discountCommissionRate"
                @keyup="editModel.discountCommissionRate = GLOBAL.checkValue(editModel.discountCommissionRate, 2)" />
            </label>
            <label v-show="getUserinfo.permissions.includes(20103)">
              {{ $t(`${i18nPrefix}proxyAgent`) }}
              <select v-model="editModel.proxyAgentId">
                <option :value="null * 1">{{ $t("default.none") }}</option>
                <option v-for="list of proxyAgentList" :key="list.id" :value="list.id">{{ $t(list.account) }}</option>
              </select>
            </label>
            <label>
              {{ $t(`${i18nPrefix}dialog.referralCode`) }}
              <input type="text" v-model="editModel.referralCode" />
            </label>
            <label>
              {{ $t(`${i18nPrefix}commissionSettingType`) }}
              <select v-model="editModel.commissionSettingType">
                <option :value="1 * 1">{{ $t(`${i18nPrefix}sum`) }}</option>
                <option :value="2 * 1">{{ $t(`${i18nPrefix}basisGametype`) }}</option>
              </select>
            </label>
            <label></label>
            <label v-show="!getUserinfo.permissions.includes(20103)"></label>
            <label class="remark">
              {{ $t("default.remark") }}
              <textarea v-model="editModel.remark"></textarea>
            </label>
          </div>
          <div class="divider-h-2"></div>
          <div class="permission-block">
            <p>{{ $t(`${i18nPrefix}dialog.permissions`) }}</p>
            <div class="permission-box">
              <div class="permission-list" v-for="(list, index) of getPermissionsTitle" :key="list.id">
                <ul>
                  <li>
                    <label>
                      <input name="checkBox" :id="'main' + list.name" type="checkbox" :value="list.id"
                        v-model="editModel.permissions" @click="checkAll('main' + list.name)" />
                      {{ $t("Permission." + list.name) }}
                    </label>
                  </li>
                  <ul>
                    <template v-for="(firstTree, firstIndex) of firstData[index]">
                      <li :key="firstTree.id">
                        <label>
                          <input name="checkBox" :id="firstTree.name" :class="['main' + list.name, 'parent' + list.name]"
                            type="checkbox" v-model="editModel.permissions" :value="firstTree.id"
                            @click="checkAll(firstTree.name, 'main' + list.name)" />
                          {{ $t("Permission." + firstTree.name) }}
                        </label>
                      </li>
                      <ul>
                        <li v-for="(secTree, secIndex) of secData[index][firstIndex]" :key="secData.id">
                          <label>
                            {{
                              secIndex === secData[index][firstIndex].length - 1
                              ? "└"
                              : "├"
                            }}
                            <input name="checkBox"
                              :class="['main' + list.name, firstTree.name, 'parent' + firstTree.name]" type="checkbox"
                              v-model="editModel.permissions" :value="secTree.id"
                              @click="checkAll(false, firstTree.name, 'main' + list.name)" />
                            {{ $t("Permission." + secTree.name) }}
                          </label>
                        </li>
                      </ul>
                    </template>
                  </ul>
                </ul>
              </div>
            </div>
          </div>
          <div class="input-block rebate-input">
            <div class="settingList">
              <label class="isUpdateChildren">
                <h2>{{ $t(`${i18nPrefix}dialog.rebate`) }}</h2>
                {{ $t(`${i18nPrefix}dialog.isUpdateChildren`) }}
                <div class="slide-block" style="margin: auto" :class="{ 'slide-block-on': editModel.isUpdateRebate }">
                  <div class="slide" @click="toggleSlide" :class="{ 'slide-on': editModel.isUpdateRebate }">
                    <label for="isUpdateRebate" style="display: none"></label>
                    <input type="checkbox" id="isUpdateRebate" v-model="editModel.isUpdateRebate" style="display: none" />
                  </div>
                </div>
              </label>
              <label v-if="editModel.commissionSettingType === 1">
                {{ $t(`${i18nPrefix}sum`) }} %
                <input type="number" v-model.number="sum" @keyup="sum =
                  GLOBAL.checkValue(sum, 2)" />
              </label>
              <label v-else v-for="list of getGamesCategorys" :key="list.id">
                {{ $t(list.name) }} %
                <input type="number"
                  v-model.number="editModel.rebateSettingList.find(data => data.gamesCategoryId === list.id).rate"
                  @keyup="editModel.rebateSettingList.find(data => data.gamesCategoryId === list.id).rate =
                    GLOBAL.checkValue(editModel.rebateSettingList.find(data => data.gamesCategoryId === list.id).rate, 2)" />
              </label>
            </div>
            <div class="settingList">
              <label class="isUpdateChildren">
                <h2>{{ $t(`${i18nPrefix}dialog.commission`) }}</h2>
                {{ $t(`${i18nPrefix}dialog.isUpdateChildren`) }}
                <div class="slide-block" style="margin: auto" :class="{ 'slide-block-on': editModel.isUpdateCommission }">
                  <div class="slide" @click="toggleSlide" :class="{ 'slide-on': editModel.isUpdateCommission }">
                    <label for="isUpdateCommission" style="display: none"></label>
                    <input type="checkbox" id="isUpdateCommission" v-model="editModel.isUpdateCommission"
                      style="display: none" />
                  </div>
                </div>
              </label>
              <label v-for="list of getGamesCategorys" :key="list.id">
                {{ $t(list.name) }} %
                <input type="number"
                  v-model.number="editModel.commissionSettingList.find(data => data.gamesCategoryId === list.id).rate"
                  @keyup="editModel.commissionSettingList.find(data => data.gamesCategoryId === list.id).rate =
                    GLOBAL.checkValue(editModel.commissionSettingList.find(data => data.gamesCategoryId === list.id).rate, 2)" />
              </label>
            </div>
          </div>
          <div class="advance-block">
            <button class="confirm-btn main-style-btn" @click="submit">{{ $t("default.confirm") }}</button>
            <button class="normal-btn-v2 main-style-btn" @mousedown.self="$emit('toggle')">{{ $t("default.cancel")
            }}</button>
          </div>
        </main>
      </div>
    </div>
    <transition name="fade" mode="out-in">
      <Alert v-if="alertMsg" :message="alertMsg" @toggle="alertMsg = undefined" @doIt="remove" />
    </transition>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { toggleStyle } from "@/utils/slideUtils";
import {
  removeVerificationInfo,
  createAgentPromotionLink
} from "@/api/agent-management/agent";
import Alert from "@/layout/components/Alert";

export default {
  name: "Edit",
  props: [
    "id",
    "name",
    "promotionLink",
    "account",
    "nickName",
    "phoneNumber",
    "accountFor",
    "status",
    "permissions",
    "isSelfDomain",
    "subDomain",
    "agentDamain",
    "isGeneralAgent",
    "isUpdateRebate",
    "isUpdateCommission",
    "permissionsData",
    "proxyAgentId",
    "commissionSettingType",
    "referralCode",
    "commissionCalculationCycle",
    "rebateSettingList",
    "commissionSettingList",
    "discountCommissionRate",
    "remark",
    "i18nPrefix"
  ],
  data() {
    return {
      editModel: {
        agentId: this.id,
        name: this.name,
        account: this.account,
        password: "",
        nickName: this.nickName,
        phoneNumber: this.phoneNumber,
        accountFor: this.accountFor,
        isSelfDomain: this.isSelfDomain,
        subDomain: this.subDomain,
        status: this.status,
        permissions: this.permissions,
        isUpdateRebate: false,
        isUpdateCommission: false,
        proxyAgentId: this.proxyAgentId * 1,
        commissionSettingType: this.commissionSettingType,
        referralCode: this.referralCode,
        commissionCalculationCycle: this.commissionCalculationCycle ?? 1,
        rebateSettingList: this.rebateSettingList,
        commissionSettingList: this.commissionSettingList,
        discountCommissionRate: this.discountCommissionRate,
        remark: this.remark,
      },
      accountForList: [],
      getPermissionsTitle: this.permissionsData.permissionsTitle,
      firstData: this.permissionsData.firstData,
      secData: this.permissionsData.secData,
      proxyAgentList: [],
      sum: this.rebateSettingList[0].rate,
      alertMsg: undefined,
    };
  },
  watch: {
    'editModel.subDomain'(val, oldVal) {
      this.editModel.subDomain = (val.trim().length > 25) ? oldVal.trim() : val.trim()
    },
  },
  computed: {
    ...mapGetters([
      "getUserinfo",
      "getStatusList",
      "getAgentList",
      "getGamesCategorys"
    ])
  },
  components: {
    Alert
  },
  created() {
    this.calcAccountFor();
    this.proxyAgentList = this.getAgentList.filter(data => data.id !== this.id);
  },
  methods: {
    toggleSlide: toggleStyle,
    submit() {
      this.editModel.permissions = [];
      document
        .querySelectorAll('input[name="checkBox"]:checked')
        .forEach(checkbox => {
          this.editModel.permissions.push(checkbox.value * 1);
        });

      if (this.editModel.commissionSettingType === 1) {
        this.editModel.rebateSettingList = this.editModel.rebateSettingList.map(d => { return { rate: this.sum, gamesCategoryId: d.gamesCategoryId } })
      }
      this.$emit("edit", this.editModel);
    },
    calcAccountFor() {
      for (let i = 0; i <= 100; i++) {
        this.accountForList.push(i);
      }
      return this.accountForList;
    },
    createPromotionLink() {
      createAgentPromotionLink({ agentId: this.id }).then(res => {
        if (res) this.$emit("reload");
      });
    },
    handleRemove() {
      this.alertMsg = this.$t(`${this.i18nPrefix}dialog.alertMsg`);
    },
    remove() {
      this.alertMsg = undefined;
      removeVerificationInfo({ id: this.id });
    },
    checkAll(type = false, parentType = false, main = false) {
      if (type) {
        let checkElements = document.getElementsByClassName(type);
        for (var i = 0; i < checkElements.length; i++)
          checkElements[i].checked = document.getElementById(type).checked
            ? true
            : false;
      }
      if (parentType) {
        document.getElementById(parentType).checked = true;
        if (type) {
          let checkMainElements = Object.values(
            document.getElementsByClassName(parentType)
          );
          document.getElementById(parentType).checked = checkMainElements.find(
            x => x.checked
          )
            ? true
            : false;
        }
        if (main) document.getElementById(main).checked = true;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
@import "./style.scss";

.dialog-container {
  width: 70%;

  .dialog-block {
    .header {
      display: flex;
      justify-content: space-between;
    }

    .input-block {
      .domain {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-size: 20px;

        input {
          text-align: center;
          font-size: 15px;
          padding: 0;
        }

        .selfDomain {
          width: 70%;
        }
      }

      align-items: flex-start;

      label {
        width: 30%;
        margin: 15px auto;
      }

      .isUpdateChildren {
        display: flex;
        align-items: center;
      }

      .settingList {
        width: 50%;

        label {
          width: 30%;
        }
      }

      .remark {
        width: 97%;
      }
    }
  }
}

.permission-list {
  input {
    height: inherit;
    padding: initial;
  }

  ul {
    margin-top: 5px;
    margin-left: 20px;
  }
}

.permission-box {
  justify-content: left !important;
}

@media screen and (max-width: 768px) {
  .header {
    .header-btn {
      display: flex;

      button {
        flex-shrink: 0;
      }
    }
  }

  .up-input {
    label {
      width: 50% !important;
    }
  }

  .rebate-input {
    label {
      width: 100% !important;
    }
  }

  .domain {
    font-size: 14px !important;
    flex-direction: column !important;
    align-items: flex-start !important;
    justify-content: center !important;

    label {
      width: 100%;
    }

    span {
      display: flex;
      align-items: center;
      white-space: nowrap;
      width: 100%;

      input {
        width: 30%;
      }
    }
  }
}
</style>