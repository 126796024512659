<template>
  <div>
    <div class="container-view">
      <button class="search-btn main-style-btn" @click="search()">{{ $t("default.search") }}</button>
      <div class="header">
        <h5>{{ $t("title." + $route.meta.title) }}</h5>
        <button class="download-btn main-style-btn" @click="download" v-show="getUserinfo.permissions.includes(130201)">
          下載
        </button>
      </div>
      <div class="main">
        <div class="input-block">
          <label>
            會員
            <input type="text" v-model="searchForm.memberAccount" />
          </label>
          <label>
            代理線
            <select v-model="searchForm.agentId">
              <option
                v-for="list of getAgentListAddDefault"
                :key="list.id"
                :value="list.id"
              >
                {{ $t(list.name) }}
              </option>
            </select>
          </label>
          <label>
            統計年度
            <date-picker v-model="searchForm.year" type="year" value-type="format" format="YYYY"></date-picker>
          </label>
        </div>
        <div class="divider-h-2"></div>
        <!-- <div class="condition-block">
          <multiselect
              v-model="yearlyReportTypes"
              label="name"
              track-by="id"
              :placeholder="$t('default.plzSelect')"
              :options="yearlyReportType"
              :multiple="true"
              :searchable="false"
          ></multiselect>
          <div class="button-block">
            <button @click="yearlyReportTypes = [...yearlyReportType]">{{ $t("default.selectAll") }}</button>
            <button @click="yearlyReportTypes = []">{{ $t("default.clear") }}</button>
          </div>
        </div> -->
        <MultiSelectBox :selectData.sync="yearlyReportTypes" :list="yearlyReportType" :multiple="true" />
        <div class="advance-block">
          <button class="search-pc-btn main-style-btn px-5" @click="search()">{{ $t("default.search") }}</button>
        </div>
      </div>
    </div>
    <div class="container-view">
      <div class="table-block">
        <table>
          <thead>
            <tr>
              <th>會員</th>
              <th>會員階級</th>
              <th>類型</th>
              <th>一月</th>
              <th>二月</th>
              <th>三月</th>
              <th>四月</th>
              <th>五月</th>
              <th>六月</th>
              <th>七月</th>
              <th>八月</th>
              <th>九月</th>
              <th>十月</th>
              <th>十一月</th>
              <th>十二月</th>
              <th>共計</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="!tableList || !tableList.length">
              <td colspan="16">{{ $t("default.noData") }}</td>
            </tr>
            <tr v-for="(list, index) of tableList" :key="index">
              <td>{{ list.memberAccount }}</td>
              <td>{{ list.memberStarLevel }} 階</td>
              <td>{{ yearlyReportType.find(data => data.id === list.yearlyReportType).name }}</td>
              <td>{{ list.sumMonth1 }}</td>
              <td>{{ list.sumMonth2 }}</td>
              <td>{{ list.sumMonth3 }}</td>
              <td>{{ list.sumMonth4 }}</td>
              <td>{{ list.sumMonth5 }}</td>
              <td>{{ list.sumMonth6 }}</td>
              <td>{{ list.sumMonth7 }}</td>
              <td>{{ list.sumMonth8 }}</td>
              <td>{{ list.sumMonth9 }}</td>
              <td>{{ list.sumMonth10 }}</td>
              <td>{{ list.sumMonth11 }}</td>
              <td>{{ list.sumMonth12 }}</td>
              <td>{{ list.sumYear }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <Pagination
        v-if="totalCount > 0"
        :totalCount="totalCount"
        @handlePage="handlePage"
      />
    </div>
  </div>
</template>

<script>
import { downloadYearReportList, getYearReportList } from "@/api/report/annualstatistics-report";
import { searchModel } from "./model";
import { mapGetters } from "vuex";
import Pagination from "@/layout/components/Pagination";
import handlePageUtils from "@/layout/mixins/handlePageUtils";
import handleDialog from "@/layout/mixins/handleDialog";
import Multiselect from "vue-multiselect";
import { download } from "@/utils/download";
import MultiSelectBox from "@/layout/components/MultiSelectBox";

export default {
  name: "AnnualStatisticsReport",
  data() {
    return {
      searchForm: _.cloneDeep(searchModel),
      yearlyReportTypes: [],
      yearlyReportType: [
        {
          id: 1,
          name: "總輸贏",
        },
        {
          id: 2,
          name: "總流水",
        },
        {
          id: 3,
          name: "總存款",
        },
        {
          id: 4,
          name: "總提款",
        },
        {
          id: 5,
          name: "總優惠",
        },
      ],
    };
  },
  components: {
    Pagination,
    Multiselect,
    MultiSelectBox
  },
  mixins: [handlePageUtils, handleDialog],
  computed: {
    ...mapGetters([
      "getAgentListAddDefault",
      "getUserinfo",
    ]),
  },
  methods: {
    search() {
      this.searchForm.yearlyReportTypes = this.yearlyReportTypes.map(
          (data) => data.id * 1
      );
      getYearReportList(this.searchForm).then((res) => {
        this.allData = res.data;
      });
    },
    download() {
      downloadYearReportList(this.searchForm).then(res => download(res, this.searchForm.year + 'annualstatisticsReport.xlsx'));
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
</style>