<template>
  <div class="drop-down-block">
    <ul>
      <li v-if="!(getUserinfo.koreanStyleInterface && getUserinfo.role === 2)">
        <router-link to="/userInfo/reset-password">{{ $t("title.reset-password") }}</router-link>
      </li>
      <li v-if="getUserinfo.permissions.includes(150000)">
        <router-link to="/userInfo/agent-wallet">{{ $t("title.agent-wallet") }}</router-link>
      </li>
      <li v-if="getUserinfo.permissions.includes(150300)">
        <a  @click="AgentInfo()">{{ $t("title.agent-info") }}</a>
      </li>
      <li>
        <a href="javascript:void(0)" @click="logout">{{ $t("default.logout") }}</a>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "UserDropDown",
  computed: {
    ...mapGetters(["getUserinfo"]),
  },
  methods: {
    logout() {
      this.$store.dispatch("user/logout");
    },
    AgentInfo() {
      this.$store.commit("app/SET_AGENTINFO", "AgentInfo");
    }
  },
};
</script>

<style lang="scss" scoped>
.drop-down-block {
  position: absolute;
  top: 45px;
  right: 0px;
  ul {
    width: max-content;
    margin-bottom: 0;
    background-color: rgba(33, 43, 70, 0.84);
    border: 2px solid #fff;
    border-radius: 5px;
    padding: 10px;
    font-size: 0;
    li {
      cursor: pointer;
      font-size: 18px;
      text-align: center;
      margin: 5px 0;
      a {
        padding: 10px;
        color: #fff !important;
      }
    }
    
  }
}


</style>