var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("table", [
    _c("thead", [
      _c("tr", [
        _c("th", [_vm._v(_vm._s(_vm.$t("default.member")))]),
        _c("th", [_vm._v(_vm._s(_vm.$t("default.point")))]),
        _c("th", [_vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}preTradePoint`)))]),
        _c("th", [_vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}afterTradePoint`)))]),
        _c("th", [
          _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}pointExchangeRate`))),
        ]),
        _c("th", [_vm._v(_vm._s(_vm.$t("default.remark")))]),
        _c("th", [_vm._v(_vm._s(_vm.$t("default.processAccount")))]),
        _c("th", [_vm._v(_vm._s(_vm.$t("default.createTime")))]),
      ]),
    ]),
    _c("tbody", [
      _c("tr", [
        _c("td", [_vm._v(_vm._s(_vm.pointForm.memberAccount))]),
        _c("td", { class: _vm.fontColor(_vm.pointForm.point) }, [
          _vm._v(_vm._s(_vm.pointForm.point)),
        ]),
        _c("td", [_vm._v(_vm._s(_vm.pointForm.preTradePoint))]),
        _c("td", [_vm._v(_vm._s(_vm.pointForm.afterTradePoint))]),
        _c("td", [_vm._v(_vm._s(_vm.pointForm.pointExchangeRate))]),
        _c("td", [_vm._v(_vm._s(_vm.pointForm.remark))]),
        _c("td", [_vm._v(_vm._s(_vm.pointForm.auditAccount))]),
        _c("td", [_vm._v(_vm._s(_vm.pointForm.createTime))]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }