<template>
  <div class="dialog-mask create-member" @mousedown.self="$emit('toggle')">
    <div class="dialog-container">
      <div class="dialog-block">
        <div class="header">
          <h4>
            {{ $t("default.edit") }}{{ $t(`title.gamesCategoryInfo`) }}
          </h4>
        </div>
        <div class="divider"></div>
        <main>
          <div class="input-block">
            <label>
              {{ $t(`${i18nPrefix}dialog.frontend`) }}
              <select v-model="editModel.PlatformVersion">
                <option
                  v-for="list of platformVersions"
                  :key="list.id"
                  :value="list.id * 1"
                >
                  {{ list.name }}
                </option>
              </select>
            </label>
            <label>
              {{ $t(`${i18nPrefix}gameCategory`) }}
              <select v-model="editModel.GamesCategory">
                <option
                  v-for="list of gamesCategorys"
                  :key="list.id"
                  :value="list.id * 1"
                >
                  {{ $t(list.name) }}
                </option>
              </select>
            </label>
            <label>
              {{ $t(`${i18nPrefix}gamePlatform`) }}
              <select v-model="editModel.GamePlatform">
                <option
                  v-for="list of gamePlatforms"
                  :key="list.id"
                  :value="list.id * 1"
                >
                  {{ $t(list.name) }}
                </option>
              </select>
            </label>
            <label>
              {{ $t(`${i18nPrefix}sort`) }}
              <input type="text" v-model="editModel.SortId" />
            </label>
            <label>
              {{ $t(`${i18nPrefix}status`) }}
              <select v-model="editModel.Status">
                <option :value="1 * 1">{{ $t("default.active") }}</option>
                <option :value="2 * 1">{{ $t("default.inactive") }}</option>
              </select>
            </label>
            <label>
              {{ $t(`${i18nPrefix}dialog.haveLobby`) }}
              <select v-model="editModel.HaveLobby">
                <option :value="true">{{ $t("default.active") }}</option>
                <option :value="false">{{ $t("default.inactive") }}</option>
              </select>
            </label>
            <label>
              {{
                $t(
                  `${i18nPrefix}dialog.isImageFullpage`
                )
              }}
              <select v-model="editModel.IsFull">
                <option :value="true">{{ $t("default.active") }}</option>
                <option :value="false">{{ $t("default.inactive") }}</option>
              </select>
            </label>
            <label>
              {{
                $t(
                  `${i18nPrefix}dialog.isMobileImageFullpage`
                )
              }}
              <select v-model="editModel.MobileIsFull">
                <option :value="true">{{ $t("default.active") }}</option>
                <option :value="false">{{ $t("default.inactive") }}</option>
              </select>
            </label>
            <label>
              {{
                $t(
                  `${i18nPrefix}dialog.isMainPageImageFullpage`
                )
              }}
              <select v-model="editModel.MainPageIsFull">
                <option :value="true">{{ $t("default.active") }}</option>
                <option :value="false">{{ $t("default.inactive") }}</option>
              </select>
            </label>
            <label>
              {{
                $t(
                  `${i18nPrefix}dialog.isMobileMainPageImageIsFullpage`
                )
              }}
              <select v-model="editModel.MobileMainPageIsFull">
                <option :value="true">{{ $t("default.active") }}</option>
                <option :value="false">{{ $t("default.inactive") }}</option>
              </select>
            </label>
            <label>
              {{ $t(`${i18nPrefix}dialog.multiples`) }}
              <input type="text" v-model="editModel.Multiples" />
            </label>
            <label>
              {{ $t(`${i18nPrefix}depositLimit`) }}
              <input type="text" v-model.number="editModel.depositLimit" />
            </label>
            <label v-show="pointWallet">
              {{
                $t(
                  `${i18nPrefix}isPointBlock`
                )
              }}
              <select v-model="editModel.isPointBlock">
                <option :value="true">{{ $t("default.active") }}</option>
                <option :value="false">{{ $t("default.inactive") }}</option>
              </select>
            </label>
            <label class="title">
              {{ $t(`${i18nPrefix}dialog.title`) }}
              <input type="text" v-model="editModel.Title" />
            </label>
          </div>
          <div class="textarea-div">
            {{ $t(`${i18nPrefix}dialog.copywriting`) }}
            <textarea
              v-model="editModel.Content"
              rows="5"
            ></textarea>
          </div>
          <div class="imgDiv">
            <div class="previewDiv" @click="upload('FaviconUp')">
              <div v-if="!FaviconUp" class="upload">
                <img class="icon" src="@/assets/icon/upload.svg" />
                <span>{{ $t(`${i18nPrefix}dialog.clickToUpload`) }}FaviconUp</span>
              </div>
              <img v-else :src="FaviconUp" />
            </div>
            <div class="previewDiv" @click="upload('FaviconDown')">
              <div v-if="!FaviconDown" class="upload">
                <img class="icon" src="@/assets/icon/upload.svg" />
                <span>{{ $t(`${i18nPrefix}dialog.clickToUpload`) }}FaviconDown</span>
              </div>
              <img v-else :src="FaviconDown" />
            </div>
            <div class="previewDiv" @click="upload('Image')">
              <div v-if="!Image" class="upload">
                <img class="icon" src="@/assets/icon/upload.svg" />
                <span>{{ $t(`${i18nPrefix}dialog.clickToUpload`) }}Image</span>
              </div>
              <img v-else :src="Image" />
            </div>
            <div class="previewDiv" @click="upload('MobileImage')">
              <div v-if="!MobileImage" class="upload">
                <img class="icon" src="@/assets/icon/upload.svg" />
                <span>{{ $t(`${i18nPrefix}dialog.clickToUpload`) }}MobileImage</span>
              </div>
              <img v-else :src="MobileImage" />
            </div>
            <div class="previewDiv" @click="upload('MainPageImage')">
              <div v-if="!MainPageImage" class="upload">
                <img class="icon" src="@/assets/icon/upload.svg" />
                <span>{{ $t(`${i18nPrefix}dialog.clickToUpload`) }}MainPageImage</span>
              </div>
              <img v-else :src="MainPageImage" />
            </div>
            <div class="previewDiv" @click="upload('MobileMainPageImage')">
              <div v-if="!MobileMainPageImage" class="upload">
                <img class="icon" src="@/assets/icon/upload.svg" />
                <span>{{ $t(`${i18nPrefix}dialog.clickToUpload`) }}MobileMainPageImage</span>
              </div>
              <img v-else :src="MobileMainPageImage" />
            </div>
            <div class="previewDiv" @click="upload('BackgroundImage')">
              <div v-if="!BackgroundImage" class="upload">
                <img class="icon" src="@/assets/icon/upload.svg" />
                <span>{{ $t(`${i18nPrefix}dialog.clickToUpload`) }}BackgroundImage</span>
              </div>
              <img v-else :src="BackgroundImage" />
            </div>
            <div class="previewDiv" @click="upload('BackgroundImgMobile')">
              <div v-if="!BackgroundImgMobile" class="upload">
                <img class="icon" src="@/assets/icon/upload.svg" />
                <span>{{ $t(`${i18nPrefix}dialog.clickToUpload`) }}BackgroundImgMobile</span>
              </div>
              <img v-else :src="BackgroundImgMobile" />
            </div>
            <div class="previewDiv" @click="upload('AppImage')">
              <div v-if="!AppImage" class="upload">
                <img class="icon" src="@/assets/icon/upload.svg" />
                <span>{{ $t(`${i18nPrefix}dialog.clickToUpload`) }}AppImage</span>
              </div>
              <img v-else :src="AppImage" />
            </div>
            <div class="previewDiv" @click="upload('AppFaviconUp')">
              <div v-if="!AppFaviconUp" class="upload">
                <img class="icon" src="@/assets/icon/upload.svg" />
                <span>{{ $t(`${i18nPrefix}dialog.clickToUpload`) }}AppFaviconUp</span>
              </div>
              <img v-else :src="AppFaviconUp" />
            </div>
            <div class="previewDiv" @click="upload('HomePageImg')">
              <div v-if="!HomePageImg" class="upload">
                <img class="icon" src="@/assets/icon/upload.svg" />
                <span>{{ $t(`${i18nPrefix}dialog.clickToUpload`) }}HomePageImg</span>
              </div>
              <img v-else :src="HomePageImg" />
            </div>
            <div class="previewDiv" @click="upload('HomePageImgMobile')">
              <div v-if="!HomePageImgMobile" class="upload">
                <img class="icon" src="@/assets/icon/upload.svg" />
                <span>{{ $t(`${i18nPrefix}dialog.clickToUpload`) }}HomePageImgMobile</span>
              </div>
              <img v-else :src="HomePageImgMobile" />
            </div>
          </div>
          <input
            type="file"
            ref="file"
            class="custom-file"
            @change="fileSelect"
          />
          <div class="advance-block">
            <button class="confirm-btn main-style-btn" @click="create">{{ $t("default.confirm") }}</button>
            <button class="normal-btn-v2 main-style-btn" @click="$emit('toggle')">{{ $t("default.cancel") }}</button>
          </div>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
import Message from "@/layout/components/Message";

export default {
  name: "Edit",
  props: [
    "companys",
    "gamePlatforms",
    "gamesCategorys",
    "platformVersions",
    "pointWallet",
    "list",
  ],
  data() {
    return {
      editModel: {
        id: this.list.id,
        Status: this.list.status,
        CompnayCode: this.list.companyCode,
        PlatformVersion: this.platformVersions.find(
          (data) => data.name === this.list.version
        )?.id,
        GamesCategory: this.list.gamesCategory,
        GamePlatform: this.list.gamePlatform,
        SortId: this.list.sortId,
        HaveLobby: this.list.haveLobby,
        Multiples: this.list.multiples,
        depositLimit: this.list.depositLimit,
        isPointBlock: this.pointWallet ? this.list.isPointBlock : undefined,
        Title: this.list.title,
        Content: this.list.content,
        BackgroundImage: this.list.backgroundImgUrl,
        BackgroundImgMobile: this.list.backgroundImgMobileUrl,
        FaviconUp: this.list.faviconUpUrl,
        FaviconDown: this.list.faviconDownUrl,
        IsFull: this.list.isFull,
        isHot: this.list.isHot,
        Image: this.list.imageUrl,
        MobileIsFull: this.list.mobileIsFull,
        MobileImage: this.list.mobileImageUrl,
        MainPageIsFull: this.list.mainPageIsFull,
        MainPageImage: this.list.mainPageImageUrl,
        MobileMainPageIsFull: this.list.mobileMainPageIsFull,
        MobileMainPageImage: this.list.mobileMainPageImageUrl,
        AppImage: this.list.appImageUrl,
        AppFaviconUp: this.list.appFaviconUpUrl,
        HomePageImg: this.list.homePageImgUrl,
        HomePageImgMobile: this.list.homePageImgMobileUrl,
      },
      formData: new FormData(),
      FaviconUp: this.list.faviconUpUrl ? this.list.faviconUpUrl + '?v=' + new Date().getTime() : '',
      FaviconDown: this.list.faviconDownUrl ? this.list.faviconDownUrl + '?v=' + new Date().getTime() : '',
      Image: this.list.imageUrl ? this.list.imageUrl + '?v=' + new Date().getTime() : '',
      MobileImage: this.list.mobileImageUrl ? this.list.mobileImageUrl + '?v=' + new Date().getTime() : '',
      MainPageImage: this.list.mainPageImageUrl ? this.list.mainPageImageUrl + '?v=' + new Date().getTime() : '',
      MobileMainPageImage: this.list.mobileMainPageImageUrl ? this.list.mobileMainPageImageUrl + '?v=' + new Date().getTime() : '',
      BackgroundImage: this.list.backgroundImgUrl ? this.list.backgroundImgUrl + '?v=' + new Date().getTime() : '',
      BackgroundImgMobile: this.list.backgroundImgMobileUrl ? this.list.backgroundImgMobileUrl + '?v=' + new Date().getTime() : '',
      AppImage: this.list.appImageUrl ? this.list.appImageUrl + '?v=' + new Date().getTime() : '',
      AppFaviconUp: this.list.appFaviconUpUrl ? this.list.appFaviconUpUrl + '?v=' + new Date().getTime() : '',
      HomePageImg: this.list.homePageImgUrl ? this.list.homePageImgUrl + '?v=' + new Date().getTime() : '',
      HomePageImgMobile: this.list.homePageImgMobileUrl ? this.list.homePageImgMobileUrl + '?v=' + new Date().getTime() : '',
      chooiceImg: undefined,
      i18nPrefix: "operationManagement.gamesCategoryInfo."
    };
  },
  methods: {
    create() {
      Object.entries(this.editModel).forEach(([key, value]) => {
        if(value !== undefined) this.formData.append(key, value);
      });
      this.$emit("edit", this.formData);
    },
    upload(tag) {
      this.chooiceImg = tag;
      this[tag] = "";
      this.editModel[tag] = "";
      this.formData.delete(tag);
      this.$refs.file.value = "";
      this.$refs.file.click();
    },
    fileSelect(e) {
      if (!e.target.files?.length) return;
      this[this.chooiceImg] = URL.createObjectURL(e.target.files[0]);
      let newFile = new File(
        [e.target.files[0]],
        this.chooiceImg + e.target.files[0].name
      );
      this.formData.append(this.chooiceImg, newFile);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/banner.scss";
.textarea-div {
  display: flex;
  flex-direction: column;
  padding: 0px 3px;
}
.imgDiv {
  display: flex;
  flex-wrap: wrap;
  .previewDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 45%;
    height: 200px;
    // padding: 0.5em;
    background-color: gray;
    border-radius: 10px;
    margin: 20px auto;
    span {
      display: block;
      font-size: 20px;
    }
    img {
      display: block;
      height: auto;
      width: auto;
      max-height: 95%;
      max-width: 95%;
    }
    .upload {
      display: flex;
      width: 100%;
      height: 100%;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: #749AAF;
      border: 1px solid #537081;
      border-radius: 10px;
      color: #fff;
      .icon {
        width: 60px;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .input-block {
    label {
      width: 50% !important;
    }
    .title {
      width: 100% !important;
    }
  }
  .imgDiv {
    .previewDiv {
      width: 100%;
      margin: 5px auto;
    }
  }
}
</style>