var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "dialog-mask",
      on: {
        mousedown: function ($event) {
          if ($event.target !== $event.currentTarget) return null
          return _vm.$emit("toggle")
        },
      },
    },
    [
      _c("div", { staticClass: "dialog-container" }, [
        _c("div", { staticClass: "dialog-block" }, [
          _c("div", { staticClass: "header" }, [
            _c("h4", [_vm._v(_vm._s(_vm.$t("default.detail")))]),
          ]),
          _c("div", { staticClass: "divider" }),
          _c("main", [
            _c("div", { staticClass: "table-block" }, [
              _c("table", [
                _c("thead", [
                  _c("tr", [
                    _c("th", [
                      _vm._v(_vm._s(_vm.$t("default.totalDataCount"))),
                    ]),
                    _c("th", [
                      _vm._v(_vm._s(_vm.$t("default.completedCount"))),
                    ]),
                    _c("th", [_vm._v(_vm._s(_vm.$t("default.success")))]),
                    _c("th", [_vm._v(_vm._s(_vm.$t("default.fail")))]),
                  ]),
                ]),
                _c("tbody", [
                  _c("tr", [
                    _c("td", [_vm._v(_vm._s(_vm.data.count))]),
                    _c("td", [_vm._v(_vm._s(_vm.data.completedCount))]),
                    _c("td", [_vm._v(_vm._s(_vm.data.successCount))]),
                    _c("td", [_vm._v(_vm._s(_vm.data.failedCount))]),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }