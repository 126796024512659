<template>
  <div>
    <div class="container-view">
      <button class="search-btn main-style-btn" @click="search()">{{ $t("default.search") }}</button>
      <div class="header">
        <h5>{{ $t("title." + $route.meta.title) }}</h5>
      </div>
      <div class="divider"></div>
      <div class="main">
        <div class="input-block">
          <label>
            {{ $t("default.member") }}
            <input type="text" v-model="searchForm.memberAccount" />
          </label>
          <label>
            {{ $t("default.title") }}
            <input type="text" v-model="searchForm.title" />
          </label>
          <label>
            {{ $t("default.startTime") }}
            <date-picker v-model="startTime" format="YYYY-MM-DD HH:mm" value-type="format"
              type="datetime"></date-picker>
          </label>
          <label>
            {{ $t("default.endTime") }}
            <date-picker v-model="endTime" format="YYYY-MM-DD HH:mm" value-type="format" type="datetime"></date-picker>
          </label>
          <label>
            {{ $t("default.status") }}
            <select v-model="searchForm.isRead">
              <option :value="undefined">{{ $t("default.all") }}</option>
              <option :value="false">{{ $t(`${i18nPrefix}notRead`) }}</option>
              <option :value="true">{{ $t(`${i18nPrefix}read`) }}</option>
            </select>
          </label>
          <label>
            {{ $t(`${i18nPrefix}attachmentStatus`) }}
            <select v-model="searchForm.attachmentStatus">
              <option v-for="list of attachmentStatus" :key="list.id" :value="list.id * 1">
                {{ $t(list.name) }}
              </option>
            </select>
          </label>
          <label>
            {{ $t(`${i18nPrefix}memberPrivateMessageStatus`) }}
            <select v-model="searchForm.memberPrivateMessageStatus">
              <option v-for="list of memberPrivateMessageStatus" :key="list.id" :value="list.id * 1">
                {{ $t(list.name) }}
              </option>
            </select>
          </label>
        </div>
        <div class="advance-block">
          <button class="search-pc-btn main-style-btn px-5" @click="search()">{{ $t("default.search") }}</button>
        </div>
      </div>
    </div>
    <div class="container-view">
      <div class="table-block">
        <table>
          <thead>
            <tr>
              <th>
                <button v-show="!checkBatchDelete && !(!allData || !allData.length)" class="normal-btn main-style-btn"
                  @click="checkBatchDelete = true">{{ $t("default.batchDelete") }}</button>
                <div v-show="checkBatchDelete">
                  <button class="normal-btn" @click="handleDelete()">
                    {{ $t("default.delete") }}
                  </button>
                  <button class="delete-btn" @click="handleDelete('cancel')">
                    {{ $t("default.cancel") }}
                  </button>
                </div>
              </th>
              <th>{{ $t("default.member") }}</th>
              <th>{{ $t("default.title") }}</th>
              <th>{{ $t("default.status") }}</th>
              <th>{{ $t(`${i18nPrefix}attachmentStatus`) }}</th>
              <th>{{ $t(`${i18nPrefix}message`) }}</th>
              <th>{{ $t(`${i18nPrefix}memberPrivateMessageStatus`) }}</th>
              <th>{{ $t(`${i18nPrefix}sendTime`) }}</th>
              <th>{{ $t(`${i18nPrefix}reaadTime`) }}</th>
              <th>{{ $t("default.processAccount") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="!allData || !allData.length">
              <td colspan="99">{{ $t("default.noData") }}</td>
            </tr>
            <tr v-for="(list, index) of allData" :key="index">
              <td>
                <button class="delete-btn" v-if="list.status == 1 && !checkBatchDelete"
                  @click="handleDelete(list.id)">{{ $t("default.delete") }}</button>
                <input class="checkAll" v-if="list.status == 1 && checkBatchDelete" :id="list.id"
                  type="checkbox" v-model="deleteData" :value="list.id" />
              </td>
              <td>{{ list.memberAccount }}</td>
              <td>{{ list.title }}</td>
              <td>{{ list.isRead ? $t(`${i18nPrefix}read`) : $t(`${i18nPrefix}notRead`) }}</td>
              <td>{{ GLOBAL.i18nCheckName(attachmentStatus.find(data => data.id === list.attachmentStatus)) }}</td>
              <td><button class="table-btn main-style-btn" @click="handleDialog({ message: list.message }, 'viewMessage')">
                  {{ $t(`${i18nPrefix}detail`) }}
                </button></td>
              <td>{{ GLOBAL.i18nCheckName(memberPrivateMessageStatus.find(data => data.id === list.status)) }}</td>
              <td>{{ list.sendTime }}</td>
              <td>{{ list.readTime }}</td>
              <td>{{ list.userAccount }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <serverPagination v-if="allData" :page="page" @ServerPageUtils="search" @changeTake="changeTake" />
    </div>
    <transition name="fade" mode="out-in">
      <component :is="currDialog" :i18nPrefix="i18nPrefix"
        v-bind="dialogData" @toggle="currDialog = undefined"></component>
    </transition>
  </div>
</template>

<script>
import {
  getmemberMessageList,
  getselectMenu,
  recycleMessage,
} from "@/api/features/batchSend";
import { searchModel } from "./model";
import { joinT,transToday } from "@/utils/transDateUtils";
import viewMessage from "./dialog/message.vue";
import handleDialog from "@/layout/mixins/handleDialog";
import serverPagination from "@/layout/components/serverPagination";
import handleServerPageUtils from "@/layout/mixins/handleServerPageUtils";

export default {
  name: "Search",
  data() {
    return {
      searchForm: _.cloneDeep(searchModel),
      startTime: transToday('from'),
      endTime: transToday('to'),
      checkBatchDelete: false,
      deleteData: [],
      memberPrivateMessageStatus: [],
      attachmentStatus: [],
      i18nPrefix: "features.memberPrivateMessage."
    };
  },
  components: {
    viewMessage,
    serverPagination,
  },
  mixins: [handleDialog, handleServerPageUtils],
  computed: {
  },
  async created() {
    getselectMenu().then(res => {
      this.attachmentStatus = res.data.attachmentStatus
          .map(data => { return { id: parseInt(data.id), name: "AttachmentStatus." + data.name } });
      this.memberPrivateMessageStatus = res.data.memberPrivateMessageStatus
          .map(data => { return { id: parseInt(data.id), name: "MemberPrivateMessageStatus." + data.name } });
    })
  },
  methods: {
    search(page = false) {
      this.searchForm.startTime = this.startTime ? joinT(this.startTime + this.GLOBAL.startSecond + this.GLOBAL.timeZone) : undefined;
      this.searchForm.endTime = this.endTime ? joinT(this.endTime + this.GLOBAL.endSecond + this.GLOBAL.timeZone) : undefined;

      this.searchForm.page.take = this.take * 1;
      this.searchForm.page.skip = this.take * (page ? page - 1 : 0);
      getmemberMessageList(this.searchForm).then(res => {
        this.allData = res.data.list;
        this.page = res.data.page;
      });
    },
    handleDelete(id) {
      this.checkBatchDelete = false;

      if(id == 'cancel') {
          this.deleteData = [];
          return
      }

      if (id) {
        this.deleteData = [id]
      }

      recycleMessage({ id: this.deleteData }).then(res => {
        if (res) {
          this.search();
          this.deleteData = [];
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped></style>
