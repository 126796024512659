<template>
  <div class="dialog-mask" @mousedown.self="$emit('toggle')">
    <div class="dialog-container edit-member">
      <div class="dialog-block">
        <div class="header">
          <h4>{{ $t("default.edit") }} {{ $t("title." + $route.meta.title) }}</h4>
        </div>
        <div class="divider"></div>
        <main>
          <div class="input-block">
            <label>
                {{ $t("default.gamePlatform") }}
              <input type="text" :value="GLOBAL.i18nCheckName(getGamePlatforms.find(
                    (data) => gamePlatform === data.id
                  ))" disabled />
            </label>
            <div>
              <label>
                {{  $t("default.nowCsinoChips") }}
                <input type="text" v-model="amount" disabled />
              </label>
              <label>
                {{  $t("default.addCasinoChips") }}
                <input type="number" v-model.number="editModel.amount" />
              </label>
            </div>
          </div>
          <div class="advance-block">
            <button class="confirm-btn main-style-btn" @click="$emit('edit', editModel);">{{ $t("default.confirm") }}</button>
            <button class="normal-btn-v2 main-style-btn" @click="$emit('toggle')">{{ $t("default.cancel")}}</button>
          </div>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Edit",
  props: ["id", "gamePlatform", "amount", "getGamePlatforms", "i18nPrefix"],
  data() {
    return {
      editModel: {
        id: this.id,
        amount: 0,
      }
    };
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
.dialog-container {
  width: 20%;

  .input-block {
    div {
      width: 100%;
    }

    label {
      width: 100%;
    }
  }
}
</style>