export let searchModel = {
    submitStartTime: undefined,
    submitEndTime: undefined,
    memberAccount: undefined,
    transactionTypes: undefined,
    agentId: 0,
    isFuzzySearch: false,
    page:  {
        "take": 10,
        "skip": 0
    },
}

export let atmModel = {
    id: undefined,
    createTime: undefined,
    memberAccount: undefined,
    paymentName: undefined,
    gateway: undefined,
    originalAmount: undefined,
    feeRate: undefined,
    amount: undefined,
    paymentStatus: undefined,
}

export let storeModel = {
    id: undefined,
    memberAccount: undefined,
    takeNumberTime: undefined,
    paymentTime: undefined,
    paymentName: undefined,
    orderNumber: undefined,
    storeCode: undefined,
    infoContent: undefined,
    amount: undefined,
    paymentStatus: undefined,
    remark: undefined,
    auditAccount: undefined,
    auditTime: undefined,
}

export let withdrawalModel = {
    id: undefined,
    submitTime: undefined,
    memberAccount: undefined,
    bankName: undefined,
    bankAccountName: undefined,
    bankAccountNumber: undefined,
    amount: undefined,
    auditStatus: undefined,
    remark: undefined,
    auditAccount: undefined,
    auditTime: undefined,
}

export let quotaModel = {
    id: undefined,
    submitTime: undefined,
    memberAccount: undefined,
    adjustmentType: undefined,
    preTradeBalance: undefined,
    amount: undefined,
    afterTradeBalance: undefined,
    remark: undefined,
    auditAccount: undefined,
}

export let discountModel = {
    name: undefined,
    discountType: undefined,
    memberAccount: undefined,
    createTime: undefined,
    bonusReceive: undefined,
    totalBetAmountLimit: undefined,
    BetAmountLimit: undefined,
}

export let transferModel = {
    id: undefined,
    createTime: undefined,
    memberAccount: undefined,
    fromPlatformName: undefined,
    fromPreTradeBalance: undefined,
    fromAfterTradeBalance: undefined,
    amount: undefined,
    toPlatformName: undefined,
    toPreTradeBalance: undefined,
    toAfterTradeBalance: undefined,
    afterAmount: undefined,
    orderStatus: undefined,
}

export let fixeddepositModel = {
    id: undefined,
    submitTime: undefined,
    memberAccount: undefined,
    adjustmentType: undefined,
    preTradeBalance: undefined,
    amount: undefined,
    afterTradeBalance: undefined,
    remark: undefined,
    auditAccount: undefined
} 

export let pointForm = {
    memberAccount: undefined,
    point: undefined,
    preTradePoint: undefined,
    afterTradePoint: undefined,
    pointExchangeRate: undefined,
    remark: undefined,
    auditAccount: undefined,
    createTime: undefined,
}