<template>
  <div>
    <div class="container-view">
      <button class="search-btn main-style-btn" @click="search()">
        {{ $t("default.search") }}
      </button>
      <div class="header">
        <h5>{{ $t("Permission." + $route.meta.permissionName) }}</h5>
      </div>
      <div class="main">
        <div class="input-block">
          <label>
            {{ $t("default.startTime") }}
            <date-picker
              v-model="startTime"
              format="YYYY-MM-DD"
              value-type="format"
              type="datetime"
              :disabled-date="GLOBAL.ontSeason"
            ></date-picker>
          </label>
          <label>
            {{ $t("default.endTime") }}
            <date-picker
              v-model="endTime"
              format="YYYY-MM-DD"
              value-type="format"
              type="datetime"
              :disabled-date="GLOBAL.ontSeason"
            ></date-picker>
          </label>
          <label class="input-block-multiselect">
            <div class="input-block-check">
              <span style="margin-right: 10px">{{ $t("default.agent") }}</span>
              <input
                id="isIncludeSubAgent"
                type="checkbox"
                v-model="searchForm.isIncludeSubAgents"
              />
              <label for="isIncludeSubAgent">{{
                $t("default.isIncludeSubAgent")
              }}</label>
            </div>
            <multiselect
              v-model="agentData"
              label="name"
              track-by="id"
              :options="getAgentListAddDefault"
              :allow-empty="false"
              :preselectFirst="true"
              :clear-on-select="false"
              :preserve-search="true"
              :custom-label="GLOBAL.nameWithLang"
            ></multiselect>
          </label>
          <label>
            {{ $t(`${i18nPrefix}type`) }}
            <select v-model="searchForm.type">
              <option :value="0 * 1">
                {{ $t(`${i18nPrefix}activeRetention`) }}
              </option>
              <option :value="2 * 1">
                {{ $t(`${i18nPrefix}payingUser`) }}
              </option>
              <option :value="1 * 1">
                {{ $t(`${i18nPrefix}nonPayingUser`) }}
              </option>
              <option :value="3 * 1">
                {{ $t(`${i18nPrefix}firstDepositFee`) }}
              </option>
              <option :value="4 * 1">
                {{ $t(`${i18nPrefix}firstDepositActive`) }}
              </option>
            </select>
          </label>
        </div>
        <div class="advance-block">
          <QuickSearch
            @changeTime="changeTime"
            :controlType="true"
          ></QuickSearch>
          <button class="search-pc-btn main-style-btn px-5" @click="search()">
            {{ $t("default.search") }}
          </button>
        </div>
      </div>
    </div>
    <div class="container-view">
      <div class="table-block">
        <TreeTable
          ref="treeTable"
          :showColumnCheckbox="true"
          :list="allData"
          :column="tableColumn"
        ></TreeTable>
      </div>
    </div>
  </div>
</template>

<script>
import { getList } from "@/api/statistical-report/memberRetention";
import { searchModel } from "./model";
import { transTodayDate, joinT } from "@/utils/transDateUtils";
import QuickSearch from "@/layout/components/QuickSearch";
import TreeTable from "@/layout/components/TreeTable";
import { mapGetters } from "vuex";
import Multiselect from "vue-multiselect";

export default {
  name: "MemberRetention",
  data() {
    return {
      allData: [],
      searchForm: _.cloneDeep(searchModel),
      startTime: transTodayDate(),
      endTime: transTodayDate(),
      agentData: [],
      i18nPrefix: "statistical-report.memberRetention.",
      digital: new Intl.NumberFormat(),
    };
  },
  watch: {},
  components: {
    Multiselect,
    QuickSearch,
    TreeTable,
  },
  computed: {
    ...mapGetters(["getAgentListAddDefault", "getUserinfo"]),
    tableColumn() {
      const kr = [
        {
          key: "date",
          name: this.$t(`default.sort`),
        },
        {
          key: "count",
          name: this.$t(`${this.i18nPrefix}loginCount`)
        },
        {
          key: "retention1",
          name: this.$t(`${this.i18nPrefix}retention1`),
        },
        {
          key: "retention3",
          name: this.$t(`${this.i18nPrefix}retention2`),
        },
        {
          key: "retention7",
          name: this.$t(`${this.i18nPrefix}retention6`),
        },
        {
          key: "retention14",
          name: this.$t(`${this.i18nPrefix}retention13`),
        },
        {
          key: "retention30",
          name: this.$t(`${this.i18nPrefix}retention29`),
        },
        {
          key: "retention60",
          name: this.$t(`${this.i18nPrefix}retention59`),
        }
      ];
      const other = [
        {
          key: "date",
          name: this.$t(`${this.i18nPrefix}date`),
        },
        {
          key: "count",
          name:
            this.searchForm.type !== 3 && this.searchForm.type !== 4
              ? this.$t(`${this.i18nPrefix}registerCount`)
              : this.$t(`${this.i18nPrefix}firstCount`),
        },
        {
          key: "retention1",
          name: this.$t(`${this.i18nPrefix}retention1`),
        },
        {
          key: "retention3",
          name: this.$t(`${this.i18nPrefix}retention2`),
        },
        {
          key: "retention7",
          name: this.$t(`${this.i18nPrefix}retention6`),
        },
        {
          key: "retention14",
          name: this.$t(`${this.i18nPrefix}retention13`),
        },
        {
          key: "retention30",
          name: this.$t(`${this.i18nPrefix}retention29`),
        },
        {
          key: "retention60",
          name: this.$t(`${this.i18nPrefix}retention59`),
        },
        {
          key: "retention90",
          name: this.$t(`${this.i18nPrefix}retention89`),
        },
      ];
      return this.getUserinfo.koreanStyleInterface ? kr : other;
    },
  },
  methods: {
    async search() {
      //時間參數
      this.searchForm.startTime = this.startTime
        ? joinT(this.startTime + this.GLOBAL.startHour + this.GLOBAL.timeZone)
        : undefined;
      this.searchForm.endTime = this.endTime
        ? joinT(this.endTime + this.GLOBAL.endHour + this.GLOBAL.timeZone)
        : undefined;

      this.searchForm.agentId = this.agentData.id;

      getList(this.searchForm).then((res) => (this.allData = res.data));
    },
    changeTime(time) {
      this.startTime = time.startTime;
      this.endTime = time.endTime;
      this.search();
    },
  },
};
</script>

<style lang="scss" scoped>
label {
  margin: 0;
}
select {
  height: 40px;
}
</style>
