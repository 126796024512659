import request from "@/utils/request";
import { getToken } from "@/utils/auth";

export function selectMenu() {
    return request({
        url: "/CustomServiceMessage/SelectMenu",
        method: "get",
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function messageList(data) {
    return request({
        url: "/CustomServiceMessage/List",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function messageDetail(data) {
    return request({
        url: "/CustomServiceMessage/Detail",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function deleteMessage(data) {
    return request({
        url: "/CustomServiceMessage/Delete",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function createReply(data) {
    return request({
        url: "/CustomServiceReply/Create",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function deleteReply(data) {
    return request({
        url: "/CustomServiceReply/Delete",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}