var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "dialog-mask",
      on: {
        mousedown: function ($event) {
          if ($event.target !== $event.currentTarget) return null
          return _vm.$emit("toggle")
        },
      },
    },
    [
      _c("div", { staticClass: "dialog-container" }, [
        _c("div", { staticClass: "dialog-block" }, [
          _c("main", [
            _c("div", { staticClass: "input-block" }, [
              _c("label", [
                _vm._v(" " + _vm._s(_vm.$t("default.title")) + " "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.createModel.title,
                      expression: "createModel.title",
                    },
                  ],
                  attrs: { type: "text" },
                  domProps: { value: _vm.createModel.title },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.createModel, "title", $event.target.value)
                    },
                  },
                }),
              ]),
              _c("label", [
                _vm._v(" " + _vm._s(_vm.$t("default.sort")) + " "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.number",
                      value: _vm.createModel.sortOrder,
                      expression: "createModel.sortOrder",
                      modifiers: { number: true },
                    },
                  ],
                  attrs: { type: "number" },
                  domProps: { value: _vm.createModel.sortOrder },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.createModel,
                        "sortOrder",
                        _vm._n($event.target.value)
                      )
                    },
                    blur: function ($event) {
                      return _vm.$forceUpdate()
                    },
                  },
                }),
              ]),
              _c("label", [
                _vm._v(
                  " " + _vm._s(_vm.$t(`${_vm.i18nPrefix}titleColor`)) + " "
                ),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.createModel.titleColor,
                      expression: "createModel.titleColor",
                    },
                  ],
                  staticClass: "color",
                  attrs: { type: "color" },
                  domProps: { value: _vm.createModel.titleColor },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.createModel,
                        "titleColor",
                        $event.target.value
                      )
                    },
                  },
                }),
              ]),
              _c("label", [
                _vm._v(
                  " " + _vm._s(_vm.$t(`${_vm.i18nPrefix}backgroundColor`)) + " "
                ),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.createModel.backgroundColor,
                      expression: "createModel.backgroundColor",
                    },
                  ],
                  staticClass: "color",
                  attrs: { type: "color" },
                  domProps: { value: _vm.createModel.backgroundColor },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.createModel,
                        "backgroundColor",
                        $event.target.value
                      )
                    },
                  },
                }),
              ]),
            ]),
            _c(
              "label",
              { staticClass: "w-100" },
              [
                _vm._v(" " + _vm._s(_vm.$t(`${_vm.i18nPrefix}content`)) + " "),
                _c("ckeditor", {
                  attrs: { "editor-url": this.GLOBAL.CKEditorUrl },
                  model: {
                    value: _vm.createModel.content,
                    callback: function ($$v) {
                      _vm.$set(_vm.createModel, "content", $$v)
                    },
                    expression: "createModel.content",
                  },
                }),
              ],
              1
            ),
            _c("div", { staticClass: "advance-block" }, [
              _c(
                "button",
                {
                  staticClass: "normal-btn",
                  on: {
                    click: function ($event) {
                      return _vm.$emit("create", _vm.createModel)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("default.confirm")))]
              ),
              _c(
                "button",
                {
                  staticClass: "delete-btn",
                  on: {
                    click: function ($event) {
                      return _vm.$emit("toggle")
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("default.cancel")))]
              ),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }